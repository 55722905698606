import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import API from '../../api';

/**
 * Loads the Stripe Provider for Stripe ReactJS
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function StripeProvider({ children }) {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (API.stripeKey) {
      setStripe(loadStripe(API.stripeKey));
    } else {
      const listener = API.on('stripePublishableKey', key => {
        setStripe(loadStripe(key));
      });
      return () => {
        listener.remove();
      };
    }
  }, []);

  return <Elements stripe={stripe}>{children}</Elements>;
}

export default StripeProvider;
