import React from 'react';
import { View, Icon, Input } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { Pressable } from 'react-native';
import { SearchBarStyles as styles } from '../../styles/POSStyles';

function SearchBar({ onChangeText, searchText }) {
  return (
    <Input
      autoFocus
      backgroundColor="#323232"
      borderColor="transparent"
      paddingTop={4}
      paddingBottom={4}
      value={searchText ?? ''}
      variant="rounded"
      style={styles.SearchBar}
      testID="pos-search-item"
      placeholder="Search"
      onChangeText={onChangeText}
      InputLeftElement={
        <View style={styles.SearchIcon}>
          <Icon
            size={6}
            as={Ionicons}
            name="ios-search"
          />
        </View>
      }
      InputRightElement={
        <Pressable
          style={styles.ClearIcon}
          onPress={() => onChangeText('')}
        >
          <Icon
            size={6}
            as={Ionicons}
            name="close-outline"
          />
        </Pressable>
      }
    />
  );
}

export default SearchBar;
