import moment from 'moment';
import Model from './Model';
import UserInfo from './UserInfo';

export default class User extends Model {
  admin_note = '';

  access_token = '';

  customer = null;

  level = null; // [Manager, Owner, Server, Bartender]

  pin_number = null;

  subscriptions = [];

  has_mfa_enabled = false;

  user = null;

  teams = [];

  _field_map = {
    user: user => new UserInfo(user),
  };

  constructor(obj) {
    super();
    this.update(obj);
  }

  static levels = {
    Staff: 0,
    Server: 1,
    Bartender: 1,
    Manager: 2,
    Owner: 3,
    Admin: 4,
  };

  checkLevel(access_level) {
    // if Access Level is Server or Bartender, check level name matches exactly. Otherwise, just compare numerical level.

    if (User.levels[access_level] === 1) {
      return this.level === access_level || User.levels[this.level] > User.levels[access_level];
    }
    return User.levels[this.level] >= User.levels[access_level];
  }

  canRefund() {
    // refund_requires_manager_pin
    return ['Owner', 'Manager', 'Admin'].includes(this.level);
  }

  getName() {
    return [this.user.first_name, this.user.last_name].join(' ');
  }
}
