import _ from 'lodash';
import EventModel from './EventModel';
import hasModifiers from './mixins/hasModifiers.js';
import mix from './mixins/Mixin.js';

export default class MenuItem extends mix(EventModel).with(hasModifiers) {
  menuItemId = null;

  enabled = true;

  description = '';

  image_on_front_page = '';

  image_on_item_page = '';

  name_for_bartender = '';

  name_for_customer = '';

  pretax_cents = 0;

  tax_cents = 0;

  tax_fraction = 0;

  menu_heading_id = null;

  imageUrl = '';

  diet_icons = []; // deprecated

  tags = [];

  display_position = 0;

  customer_id = null;

  integrations = {};

  menu_item_class = '';

  menu_set = [];

  modifierGroups = [];

  _modifier_groups = null;

  special_instruction_config = {};

  in_stock = false;

  most_loved = false;

  skip_ahead_minutes = null;

  primary_type = null;

  sku = null;

  price_in_cents = 0;

  print_method = '';

  printers = [];

  price = null;

  report_category = null;

  price_string = '';

  stations_that_can_fulfill = [];

  name_for_owner = '';

  inventory_item_id = null;

  pre_selected = false;

  // Internal:
  #search_string = '';

  // todo: menuData can be accessed via this.api.getMenu()
  constructor(menuData, data) {
    super();

    Object.defineProperty(this, 'menuData', {
      value: menuData,
    });

    this.update(data);
  }

  update(data) {
    super.update(data);

    this._modifier_groups = null;

    this.#search_string = [
      this.name,
      this.description,
      // this.modifierGroups.reduce((acc, mg) => acc += mg.modifierIds.map(id => this.menuData.modifiersById[id].name).join(','), '')
    ].join(',');
  }

  get name() {
    return this.name_for_bartender || this.name_for_customer;
  }

  get search_string() {
    return this.#search_string;
  }

  get menu_heading() {
    if (this.menu_heading_id) return this.menuData.headingsById[this.menu_heading_id][0];

    return Object.values(this.menuData.headingsById).find(headings =>
      headings.find(h => h.items.find(i => i.menuItemId === this.menuItemId)),
    );
  }

  get menu_names() {
    return this.menus.map(menu => menu?.name);
  }

  get menus() {
    return this.menu_set.map(
      id =>
        this.menuData.menusById[id] || { menuId: id, menuName: '(Disabled Menu)', enabled: false },
    );
  }

  get customer() {
    return this.api._customers[this.menus[0]?.customer?.customer_id];
  }

  get vendor_name() {
    return this.customer?.customer_id !== this.api.main_customer.customer_id
      ? this.customer?.customer_name || 'Vendor'
      : '';
  }
}
