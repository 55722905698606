import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';

export const styles = EStyleSheet.create({
  iconStyle: {
    alignSelf: 'center',
    fontSize: 30,
    color: 'black',
  },
  note: {
    color: '#a7a7a7',
    fontSize: 14,
    marginRight: 10,
  },
  arrow: {},
});
