import React from 'react';
import { Text } from 'native-base';
import { Platform, StyleSheet } from 'react-native';
import { useGlobalize } from 'react-native-globalize';
import Colors from '../constants/Colors';

function TabBarLabel({ labelPath, disabled, tintColor }) {
  const { formatMessage } = useGlobalize();

  const color = disabled ? Colors.darkGray : tintColor;

  const style = StyleSheet.flatten([styles.label, { color }]);

  return (
    <Text
      style={style}
      numberOfLines={2}
      adjustsFontSizeToFit
    >
      {formatMessage(labelPath)}
    </Text>
  );
}

export default TabBarLabel;

const styles = StyleSheet.create({
  label: {
    textAlign: 'center',
    lineHeight: 12,
    ...Platform.select({
      android: { fontSize: 10 },
      web: { fontSize: 11 },
    }),
  },
});
