import { View } from 'react-native';
import { FormattedCurrency } from 'react-native-globalize';
import { Text } from 'native-base';
import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { TextM } from '../../components';
import FuzzyField from '../../components/FuzzyField';
import Colors from '../../constants/Colors';
import OrderItem from '../../components/OrderItem';

/**
 * The <ReceiptItem> is a single row with information on a receipt item listed in the <CartItemCard>. This includes the
 * fraction of this item, the name and modifiers, and the price of this individual item.
 *
 * @param {Object} props
 * @param {ItemPart} props.item - Information about the specific item to render within this ReceiptItem line
 * @param {boolean} props.blurPrice - If true, blurs the price of the Receipt Item
 * @returns {JSX.Element}
 * @constructor
 */
function CheckoutCartItem({ item, blurPrice, ...rest }) {
  const fulfillable = item.item.is_fulfillable;
  return (
    <View
      testID="tipScreenReceiptItem"
      style={styles.receiptItem}
      {...rest}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 5,
        }}
      >
        {/* The fraction to denote how many times this receipt item has been split */}
        <TextM
          testID="tipScreenReceiptItemFraction"
          defaultMessage={item.getFraction()}
          style={[{ width: '10%', fontSize: 14 }, styles.bold]}
        />
        {/* The name of the item */}
        <TextM
          testID="tipScreenReceiptItemTitle"
          defaultMessage={item.label}
          style={[{ flex: 1, fontSize: 16 }, !fulfillable && styles.unfulfillableItem, styles.bold]}
        />
        <View
          testID="tipScreenReceiptItemCost"
          style={{ alignItems: 'flex-end' }}
        >
          <FuzzyField fuzzy={blurPrice}>
            <FormattedCurrency
              style={[{ fontSize: 14 }, styles.bold]}
              value={item.getTotal() / 100}
            />
          </FuzzyField>
        </View>
      </View>
      {/* List any modifiers for the current item */}
      <View
        testID="tipScreenReceiptItemModifiers"
        style={styles.itemModifiers}
      >
        {!fulfillable && (
          <Text style={{ color: Colors.error }}>This item is no longer available</Text>
        )}
        {OrderItem.getModifiers(item.item, null, null, false, item)}
        {OrderItem.getSpecialInstructions(item.item)}
      </View>
    </View>
  );
}

export default CheckoutCartItem;

const styles = EStyleSheet.create({
  receiptItem: {
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 5,
  },
  bold: {
    fontWeight: 'bold',
  },
  unfulfillableItem: {
    textDecorationLine: 'line-through',
  },
  itemModifiers: {
    marginLeft: '10%',
  },
});
