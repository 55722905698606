import React from 'react';
import { Stack, FormControl, Select, Text } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import Required from './Required';

function DropdownField({ field, fieldValue, setFieldValue, valid, setValid, ...rest }) {
  return (
    <Stack
      style={styles.dropdownContainer}
      {...rest}
    >
      <Text>
        {field.name_for_patron} <Required show={field.required_on_handheld} />
      </Text>
      <Select
        selectedValue={fieldValue}
        onValueChange={value => {
          setFieldValue(value);
        }}
      >
        {field.choices.map(choice => (
          <Select.Item
            key={choice.label}
            label={choice.label}
            value={choice.value}
          />
        ))}
      </Select>
    </Stack>
  );
}

export default DropdownField;

const styles = EStyleSheet.create({
  dropdownContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
  },
});
