import React from 'react';
import { Container, List, Text, Input } from 'native-base';
import { Content, ListItem } from '../bbot-component-library';

const SERVERS = [
  {
    name: 'Staging',
    ip: 'https://staging.bbot.menu/',
  },
  {
    name: 'Dev',
    ip: 'https://dev.bbot.menu/',
  },
  {
    name: 'Demo',
    ip: 'https://demo.bbot.menu/',
  },
  {
    name: 'Custom',
    ip: null,
  },
];

export default function ServerSelection() {
  return (
    <Container>
      <Content>
        <List>
          {SERVERS.map(server => (
            <ListItem>
              <Text>{server.name}</Text>
              {!server.name && <Input />}
            </ListItem>
          ))}
        </List>
      </Content>
    </Container>
  );
}
