import React, { Component } from 'react';
import _ from 'lodash';
import {
  View,
  KeyboardAvoidingView,
  ScrollView,
  TouchableWithoutFeedback,
  Dimensions,
  Modal,
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import {
  FormControl,
  Stack,
  Input,
  TextArea,
  Select,
  Button,
  Text,
  Icon,
  Switch,
  Row,
} from 'native-base';
import PropTypes from 'prop-types';
import { FormattedCurrency, useGlobalize } from 'react-native-globalize';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { Body, ListItem, Left, Right } from '../bbot-component-library';
import API from '../api';
import { Loader } from '.';
import { Colors } from '../styles';
import OrderTotals from './Order/OrderTotals.js';
import { checkAccess } from '../helpers/Access';
import Segment, { SegmentButton } from './Segment';

/** @enum {string} */
const RefundType = {
  FULL: 'full',
  PARTIAL: 'partial',
};
/** @enum {string} */
const ItemStatus = {
  REFUNDED: 'refunded',
};
/** @enum {string} */
const RefundMethod = {
  FIXED: '$',
  PERCENTAGE: '%',
};

/**
 * A dictionary mapping the name of errors present in the Refund Reason Modal to the message displayed. An error name is
 * in the dictionary iff the error is present in the Refund Reason Modal. That is, if there are no key value pairs, there
 * is no error in the Refund Reason Modal.
 * @typedef {Map<string, string>} RefundModalErrors
 */

export default class RefundReasonModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    order: PropTypes.object,
    selectedItems: PropTypes.array,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    onSuccess: _.noop,
    onCancel: _.noop,
  };

  constructor(props) {
    super(props);

    this.defaultReasons = ['Out of Stock', 'Guest Request', 'Closing Soon', 'Test Order'];

    this.state = {
      selectedItems: [],
      selectedFees: [],

      refundType: RefundType.FULL,
      refundMethod: RefundMethod.FIXED,
      selectedReason: '',
      reason: '',
      updateStock: false,

      // refund amounts
      salesRefundAmount: 0,
      salesRefundAmountText: '',
      tipRefundAmount: 0,
      tipRefundAmountText: '',
      feeRefundAmount: 0,

      errors: {},
      processing: false,

      showPin: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedItems, visible, order } = this.props;

    // Once the modal is visible pull in the selected items and determine if there are any errors
    if (visible && !prevProps.visible) {
      this.setState(
        {
          selectedFees: [],
          selectedItems: order.items
            .filter(
              item =>
                selectedItems.includes(item.orderitemid) &&
                item.status !== ItemStatus.REFUNDED &&
                item.pretax_cents !== 0,
            )
            .map(item => item.orderitemid),
        },
        () => {
          this._validate();
        },
      );
    }

    if (prevProps.visible && !visible) {
      // Reset the state so that when the modal is opened again it does have any of the previously selected items
      this.setState({
        refundType: RefundType.FULL,
        refundMethod: RefundMethod.FIXED,
        selectedReason: '',
        reason: '',
        updateStock: false,
        salesRefundAmount: 0,
        salesRefundAmountText: '',
        tipRefundAmount: 0,
        tipRefundAmountText: '',
        feeRefundAmount: 0,
      });
    }
  }

  render() {
    const { visible, onCancel, order } = this.props;
    const {
      processing,
      selectedItems,
      selectedFees,
      errors,
      refundType,
      refundMethod,
      salesRefundAmountText,
      tipRefundAmountText,
      updateStock,
      selectedReason,
      reason,
    } = this.state;
    if (!visible || !order) return null;

    const { items } = order;
    const reasons = order.station.possible_refund_reasons.map(o => o.reason);
    const { width } = Dimensions.get('window');

    return (
      <Modal
        visible={visible}
        transparent
        onRequestClose={onCancel}
        statusBarTranslucent
      >
        <TouchableWithoutFeedback onPress={onCancel}>
          <View style={styles.background}>
            <TouchableWithoutFeedback>
              <KeyboardAvoidingView style={[styles.modal, { width: width > 500 ? 500 : width }]}>
                {processing && <Loader />}
                <View style={styles.header}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.headerText}>Refund Items</Text>
                  </View>
                  {API.config.kds_partial_refunds && (
                    <View>
                      <Segment
                        style={{
                          backgroundColor: 'transparent',
                          alignSelf: 'flex-end',
                          paddingBottom: 8,
                        }}
                      >
                        <SegmentButton
                          darkMode
                          display="By Item"
                          selected={refundType === RefundType.FULL}
                          onPress={() => this._setRefundType(RefundType.FULL)}
                        />
                        <SegmentButton
                          darkMode
                          display="By Amount"
                          selected={refundType === RefundType.PARTIAL}
                          onPress={() => this._setRefundType(RefundType.PARTIAL)}
                        />
                      </Segment>
                    </View>
                  )}
                </View>
                <View style={styles.body}>
                  <View style={{ padding: 5, borderBottomWidth: 1, alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 14 }}>Price After Discounts</Text>
                  </View>
                  <ScrollView>
                    <View>
                      {/* List all of the Order Items */}
                      {this._getOrderListItems(items, selectedItems, errors)}
                      {/* List all of the other fees on the receipt */}
                      {refundType === RefundType.FULL &&
                        order.fees_on_my_receipt.map(fee => (
                          <ListItem key={fee.id}>
                            {fee.status !== ItemStatus.REFUNDED ? (
                              <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                                <TouchableWithoutFeedback onPress={() => this._toggleFee(fee.id)}>
                                  <View style={{ height: 35, justifyContent: 'center' }}>
                                    <Icon
                                      as={MaterialIcons}
                                      size={7}
                                      name={
                                        selectedFees.includes(fee.id)
                                          ? 'check-box'
                                          : 'check-box-outline-blank'
                                      }
                                      style={
                                        errors?.selected ? { color: 'red' } : { color: '#000' }
                                      }
                                    />
                                  </View>
                                </TouchableWithoutFeedback>
                              </View>
                            ) : (
                              <View style={{ width: 20, height: 20 }} />
                            )}
                            <Body>
                              <Text
                                style={[
                                  fee.status === ItemStatus.REFUNDED && {
                                    textDecorationLine: 'line-through',
                                  },
                                ]}
                              >
                                {fee.name_for_owner}
                              </Text>
                              {fee.status === ItemStatus.REFUNDED && (
                                <Text style={{ fontSize: 12, color: Colors.darkGray }}>
                                  {_.capitalize(fee.status)}
                                </Text>
                              )}
                            </Body>
                            <Right
                              style={{
                                justifyContent: 'flex-end',
                                textAlign: 'end',
                                paddingRight: 8,
                              }}
                            >
                              <View>
                                <Text
                                  style={[
                                    { fontSize: 14 },
                                    fee.status === ItemStatus.REFUNDED && {
                                      textDecorationLine: 'line-through',
                                    },
                                  ]}
                                >
                                  <FormattedCurrency value={fee.tax_determined_total_cents / 100} />
                                </Text>
                                {!API.main_customer.tax_inclusive_pricing && (
                                  <Text
                                    style={[
                                      { color: Colors.darkGray, fontSize: 12 },
                                      fee.status === ItemStatus.REFUNDED && {
                                        textDecorationLine: 'line-through',
                                      },
                                    ]}
                                  >
                                    Tax: +
                                    <FormattedCurrency value={fee.tax_cents / 100} />
                                  </Text>
                                )}
                              </View>
                            </Right>
                          </ListItem>
                        ))}
                      <ListItem key="totals">
                        <OrderTotals order={order} />
                      </ListItem>
                    </View>
                  </ScrollView>
                  <View>
                    {refundType === RefundType.PARTIAL && [
                      <ListItem
                        key={0}
                        style={{ flexDirection: 'column', padding: 10 }}
                      >
                        <Stack
                          style={{ width: '100%', marginBottom: 15 }}
                          accessible={false}
                        >
                          <Select
                            selectedValue={refundMethod}
                            onValueChange={this._setRefundMethod}
                            mode="dropdown"
                          >
                            <Select.Item
                              label="Fixed Amount"
                              value={RefundMethod.FIXED}
                              key={0}
                            />
                            <Select.Item
                              label="Percentage"
                              value={RefundMethod.PERCENTAGE}
                              key={1}
                            />
                          </Select>
                        </Stack>
                        <Stack
                          style={{ width: '100%', marginBottom: 15 }}
                          accessible={false}
                        >
                          <Input
                            placeholder={refundMethod === RefundMethod.FIXED ? '0.00' : '0'}
                            value={salesRefundAmountText}
                            onChangeText={this._setSalesAmountText}
                            keyboardType="numeric"
                            variant="underlined"
                            InputLeftElement={
                              <FormControl.Label
                                style={{
                                  width: 100,
                                  color: errors?.salesRefundAmount ? 'red' : 'black',
                                }}
                              >
                                Sales{' '}
                                {refundMethod === RefundMethod.FIXED ? (
                                  <CurrencySymbol
                                    style={{ color: errors?.salesRefundAmount ? 'red' : null }}
                                  />
                                ) : (
                                  '%'
                                )}
                              </FormControl.Label>
                            }
                          />
                        </Stack>
                        <Stack
                          style={{ width: '100%', marginBottom: 15 }}
                          accessible={false}
                        >
                          <Input
                            placeholder={refundMethod === RefundMethod.FIXED ? '0.00' : '0'}
                            value={tipRefundAmountText}
                            onChangeText={this._setRefundTipAmount}
                            keyboardType="numeric"
                            style={{ flex: 1 }}
                            variant="underlined"
                            InputLeftElement={
                              <FormControl.Label
                                style={{
                                  width: 75,
                                  color: errors?.tipRefundAmount ? 'red' : 'black',
                                }}
                              >
                                Tip{' '}
                                {refundMethod === RefundMethod.FIXED ? (
                                  <CurrencySymbol
                                    style={{ color: errors?.tipRefundAmount ? 'red' : null }}
                                  />
                                ) : (
                                  '%'
                                )}
                              </FormControl.Label>
                            }
                          />
                        </Stack>
                      </ListItem>,
                    ]}
                    <ListItem itemDivider>
                      <Left style={{ flex: 1 }}>
                        <Text style={{ fontWeight: 'bold' }}>Refund Total</Text>
                      </Left>
                      <Body style={{ flex: 1, alignItems: 'center' }}>
                        <Text note="true">
                          {selectedItems.length + selectedFees.length} item
                          {selectedItems.length + selectedFees.length > 1 ? 's' : ''}
                        </Text>
                      </Body>
                      <Right>
                        <Text style={{ fontSize: 14 }}>
                          <FormattedCurrency value={this._getRefundTotal() / 100} />
                        </Text>
                      </Right>
                    </ListItem>
                  </View>
                  <FormControl style={{ padding: 10 }}>
                    <Stack
                      picker
                      stackedLabel
                      accessible={false}
                    >
                      <FormControl.Label style={{ color: errors?.reason ? 'red' : null }}>
                        Refund Reason
                      </FormControl.Label>
                      <Select
                        selectedValue={selectedReason}
                        mode="dropdown"
                        onValueChange={this._setReason}
                        style={{ width: '100%' }}
                      >
                        <Select.Item
                          label="Refund Reason..."
                          value=""
                        />
                        {reasons.map(reason => (
                          <Select.Item
                            label={reason}
                            value={reason}
                            key={reason}
                          />
                        ))}
                        <Select.Item
                          label="Other..."
                          value="other"
                        />
                      </Select>
                    </Stack>
                    {/* If the refund reason was that it was Out of Stock, give option to 86 item here */}
                    {selectedReason === 'Out of Stock' && (
                      <ListItem onPress={() => this.setState({ updateStock: !updateStock })}>
                        <Left>
                          <Text>86 Item?</Text>
                        </Left>
                        <Right style={{ justifyContent: 'flex-end' }}>
                          <Switch
                            value={updateStock}
                            onChange={() => this.setState({ updateStock: !updateStock })}
                          />
                        </Right>
                      </ListItem>
                    )}
                    {/* If the refund reason was Other, display an input for the custom reason */}
                    {selectedReason === 'other' && (
                      <Stack
                        stackedLabel
                        accessible={false}
                      >
                        <FormControl.Label>Enter reason</FormControl.Label>
                        <TextArea
                          value={reason}
                          rowSpan={2}
                          style={{ flex: 1, width: '100%' }}
                          ref={i => (this._input = i)}
                          selectTextOnFocus
                          onChangeText={text => {
                            this.setState({
                              reason: text,
                            });
                            this._validate();
                          }}
                        />
                      </Stack>
                    )}
                  </FormControl>
                  {!this._isValid() && (
                    <View style={styles.errorMsg}>
                      <Text style={styles.errorMsgText}>{Object.values(errors)[0]}</Text>
                    </View>
                  )}
                </View>
                <View style={styles.footer}>
                  <Button
                    style={{ flex: 1 }}
                    colorScheme="danger"
                    onPress={onCancel}
                  >
                    CANCEL
                  </Button>
                  <Button
                    leftIcon={
                      <Icon
                        as={FontAwesome}
                        name="lock"
                      />
                    }
                    colorScheme="primary"
                    disabled={!this._isValid()}
                    style={{ marginLeft: 15, flex: 1 }}
                    onPress={this._processRefund}
                  >
                    REFUND
                  </Button>
                </View>
              </KeyboardAvoidingView>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  /**
   * Returns an array of <ListItem> components. Each <ListItem> component has three immediate children: The checkbox for
   * the item if it has not already been refunded, the name of the item, and the costs associated for the item. If the
   * item has already been refunded, the name and costs are displayed with a line through them.
   *
   * @param {Object[]} items The list of items and respective information in this order
   * @param {string[]} selectedItems A list of all item ids for the items that have already been selected
   * @param {RefundModalErrors} errors A map of the names of errors present in the modal to their error messages
   * @returns {ListItem[]} An array of <ListItem> components, one for each item in `items`
   * @private
   */
  _getOrderListItems = (items, selectedItems, errors) => {
    const { refundType } = this.state;
    return items.map(item => {
      // For refunded items, many elements will be have a line-through for clarity
      const textDecorationLine = item.status === ItemStatus.REFUNDED ? 'line-through' : 'none';
      const isFreeItem = item.pretax_cents === 0;
      return (
        <ListItem
          testID="refundReasonModal-orderListItem"
          key={item.orderitemid}
        >
          {/* The Leftmost Checkbox (Or no checkbox if the item has been refunded fully) */}
          {item.status !== ItemStatus.REFUNDED &&
          (refundType === RefundType.FULL || !isFreeItem) ? (
            <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
              <TouchableWithoutFeedback
                testID="refundReasonModal-itemCheckbox"
                onPress={() => this._toggleItem(item.orderitemid)}
              >
                <View style={{ height: 35, justifyContent: 'center', marginRight: 10 }}>
                  <Icon
                    as={MaterialIcons}
                    size={7}
                    name={
                      selectedItems.includes(item.orderitemid)
                        ? 'check-box'
                        : 'check-box-outline-blank'
                    }
                    style={errors?.selected ? { color: 'red' } : { color: '#000' }}
                  />
                </View>
              </TouchableWithoutFeedback>
            </View>
          ) : (
            <View style={{ width: 20, height: 20 }} />
          )}
          {/* The Name of the Item (and 'refunded' subtitle) */}
          <Body>
            <Text style={[{ textDecorationLine }]}>{item.itemName}</Text>
            {/* Add the "Refunded" Subtitle */}
            {item.status === ItemStatus.REFUNDED && (
              <Text style={{ fontSize: 12, color: Colors.darkGray }}>
                {_.capitalize(item.status)}
              </Text>
            )}
            {/* Add the "Free" Subtitle */}
            {isFreeItem && (
              <Text style={{ fontSize: 12, color: Colors.darkGray }}>{_.capitalize('Free')}</Text>
            )}
          </Body>
          {/* Right aligned, we have the pretax and tax costs */}
          <Right style={{ justifyContent: 'flex-end', textAlign: 'end', paddingRight: 8 }}>
            <View>
              {/* The current cost of the item, after any existing partial refunds have been applied */}
              <Text
                testID="refundReasonModal-itemPretaxCost"
                style={[{ fontSize: 14, textDecorationLine }]}
              >
                <FormattedCurrency
                  value={
                    (API.main_customer.tax_inclusive_pricing
                      ? item.posttax_cents
                      : item.pretax_cents) / 100
                  }
                />
              </Text>
              {/* The cost of the tax applied to this item if tax-inclusive pricing is not enabled on the customer */}
              {!API.main_customer.tax_inclusive_pricing && (
                <Text
                  testID="refundReasonModal-itemTax"
                  style={[
                    {
                      color: Colors.darkGray,
                      fontSize: 12,
                      textDecorationLine,
                    },
                  ]}
                >
                  Tax: +
                  <FormattedCurrency value={item.tax_cents / 100} />
                </Text>
              )}
            </View>
          </Right>
        </ListItem>
      );
    });
  };

  _setRefundType = type => {
    this.setState(
      {
        refundType: type,
      },
      () => {
        this._validate();
      },
    );
  };

  _getMaxSalesRefund = () => {
    const { order } = this.props;
    const { selectedItems } = this.state;
    return (
      order &&
      _.sumBy(
        order.items.filter(i => selectedItems.includes(i.orderitemid)),
        'pretax_cents',
      )
    );
  };

  _getMaxSalesTaxRefund = () => {
    const { order } = this.props;
    const { selectedItems } = this.state;
    return (
      order &&
      _.sumBy(
        order.items.filter(i => selectedItems.includes(i.orderitemid)),
        'tax_cents',
      )
    );
  };

  _getMaxTipRefund = () => {
    const { order } = this.props;
    const { selectedItems } = this.state;
    return (
      order &&
      _.sumBy(
        order.items.filter(i => selectedItems.includes(i.orderitemid)),
        'tip_in_cents',
      )
    );
  };

  _getMaxFeeRefund = () => {
    const { order } = this.props;
    const { selectedFees } = this.state;
    return order
      ? _.sumBy(
          order.fees_on_my_receipt.filter(fee => selectedFees.includes(fee.id)),
          'pretax_cents',
        )
      : 0;
  };

  _getMaxFeeTaxRefund = () => {
    const { order } = this.props;
    const { selectedFees } = this.state;
    return order
      ? _.sumBy(
          order.fees_on_my_receipt.filter(fee => selectedFees.includes(fee.id)),
          'tax_cents',
        )
      : 0;
  };

  _getTotalMaxRefund = () => {
    const { refundType } = this.state;

    if (refundType === RefundType.FULL) {
      return this._getMaxSalesRefund() + this._getMaxTipRefund() + this._getMaxFeeRefund();
    }
    // Dont include fee refund for partial refunds
    return this._getMaxSalesRefund() + this._getMaxTipRefund();
  };

  _setSalesAmountText = text => {
    const amount = parseFloat(text || 0);

    this.setState(
      {
        salesRefundAmountText: text,
        salesRefundAmount: !isNaN(amount) ? amount : 0,
      },
      () => {
        this._validate();
      },
    );
  };

  _setRefundTipAmount = text => {
    const amount = parseFloat(text || 0);

    this.setState(
      {
        tipRefundAmountText: text,
        tipRefundAmount: !isNaN(amount) ? amount : 0,
      },
      () => {
        this._validate();
      },
    );
  };

  _validate = () => {
    const {
      selectedItems,
      selectedFees,
      salesRefundAmount,
      tipRefundAmount,
      refundType,
      refundMethod,
      selectedReason,
      reason,
    } = this.state;
    const errors = {};

    if (!(selectedItems.length + selectedFees.length)) {
      errors.items = 'You must select at least 1 item to refund.';
    }

    const totalRefundAmount = this._getRefundTotal();

    // Partial Refund
    if (refundType === RefundType.PARTIAL) {
      if (totalRefundAmount <= 0) {
        errors.amount = 'You must refund an amount greater than 0 cents.';
      }
      const maxAmount = this._getTotalMaxRefund();
      if (maxAmount - totalRefundAmount <= 0) {
        errors.amount =
          "Amount can't be 100% or more of item value for partial refunds by amount. Please refund by item for full sales + tip refunds.";
      }

      if (refundMethod === '%') {
        if (isNaN(salesRefundAmount) || salesRefundAmount < 0 || salesRefundAmount >= 100) {
          errors.salesRefundAmount =
            'The percentage off for the sales amount must be greater than or equal to 0% and less than 100%.';
        } else if (isNaN(tipRefundAmount) || tipRefundAmount < 0 || tipRefundAmount > 100) {
          errors.tipRefundAmount =
            'The percentage off for the tip amount must be a number greater than 0% and 100%.';
        }
      } else if (
        isNaN(salesRefundAmount) ||
        salesRefundAmount < 0 ||
        salesRefundAmount * 100 >= this._getMaxSalesRefund()
      ) {
        errors.salesRefundAmount = `The sales amount must be between greater than or equal to $0 and less than $${(
          this._getMaxSalesRefund() / 100
        ).toFixed(2)}`;
      } else if (
        isNaN(tipRefundAmount) ||
        tipRefundAmount < 0 ||
        tipRefundAmount * 100 > this._getMaxTipRefund()
      ) {
        errors.tipRefundAmount = `The tip amount must be between $0 and $${(
          this._getMaxTipRefund() / 100
        ).toFixed(2)}`;
      }
    }

    if (selectedReason === 'other' ? !reason : !selectedReason) {
      errors.reason = 'Refund reason required';
    }

    this.setState({
      errors,
    });
  };

  /**
   * Changes between percent and dollar amount partial refund
   * @param {(RefundMethod | "$" | "%")} val
   * @private
   */
  _setRefundMethod = val => {
    this.setState(
      {
        refundMethod: val,
      },
      () => {
        this._validate();
      },
    );
  };

  _getRefundTotal = () => {
    const { refundMethod, refundType, salesRefundAmount, tipRefundAmount } = this.state;
    const refundSalesAmount = parseFloat(salesRefundAmount || '0');
    const refundTipAmount = parseFloat(tipRefundAmount || '0');

    let refundTotal = 0;

    if (refundType === RefundType.PARTIAL) {
      if (refundMethod === RefundMethod.PERCENTAGE) {
        refundTotal = _.round(
          (this._getMaxSalesRefund() * refundSalesAmount) / 100 +
            (this._getMaxTipRefund() * refundTipAmount) / 100,
          2,
        );
      } else {
        refundTotal = _.round((refundSalesAmount + refundTipAmount) * 100, 2);
      }
    } else {
      refundTotal = _.round(
        this._getMaxSalesRefund() +
          this._getMaxSalesTaxRefund() +
          this._getMaxTipRefund() +
          this._getMaxFeeRefund() +
          this._getMaxFeeTaxRefund(),
        2,
      );
    }

    return refundTotal;
  };

  _isValid = () => {
    const { errors } = this.state;
    return !Object.keys(errors).length;
  };

  _setReason = val => {
    const { updateStock } = this.state;
    this.setState(
      {
        selectedReason: val,
        updateStock: val !== 'Out of Stock' ? false : updateStock,
      },
      () => {
        this._validate();
        if (this._input?.focus) this._input.focus();
        else this._input?._root?.focus();
      },
    );
  };

  _processPartialRefund = async (user_id = null) => {
    const { order, items, onSuccess } = this.props;
    const {
      refundMethod,
      salesRefundAmount,
      tipRefundAmount,
      selectedReason,
      reason,
      selectedItems,
    } = this.state;

    const itemsToRefund = selectedItems;
    let cents_to_add;
    let tip_cents_to_add;
    let fraction_multiplier;
    let tip_fraction_multiplier;
    const refundReason = selectedReason === 'other' ? reason : selectedReason;
    const fee_cents_to_add = 0; // DO NOT allow partial refund of fees/service charges on an order

    if (refundMethod === RefundMethod.PERCENTAGE) {
      cents_to_add = 0;
      tip_cents_to_add = 0;
      fraction_multiplier = 1 - parseFloat(salesRefundAmount) / 100;
      tip_fraction_multiplier = 1 - parseFloat(tipRefundAmount) / 100;
    } else {
      cents_to_add = Math.round(salesRefundAmount * -100); // API expects Integers therefore round
      tip_cents_to_add = Math.round(tipRefundAmount * -100); // API expects Integers therefore round
      fraction_multiplier = 1.0;
      tip_fraction_multiplier = 1.0;
    }

    // TODO: Add fee_cents_to_add to the API call
    const response = await API.addOrderRefund(
      order,
      cents_to_add,
      tip_cents_to_add,
      fraction_multiplier,
      tip_fraction_multiplier,
      refundReason,
      itemsToRefund,
      user_id,
    );
    this.setState({ processing: false });
    if (response.success) {
      onSuccess(items);
    } else {
      this.setState({
        errors: { server: response.error },
      });
    }
  };

  _processRefund = async user => {
    const { order, onSuccess } = this.props;
    const { refundType, selectedItems, selectedFees, selectedReason, reason, updateStock } =
      this.state;
    const hasAccess = await checkAccess('allow_refunds', true);
    if (hasAccess) {
      this.setState({ processing: true });

      if (refundType === RefundType.PARTIAL) return this._processPartialRefund(hasAccess.user.id);
      // else process full refund:

      const itemsToRefund = order.items.filter(item => selectedItems.includes(item.orderitemid));
      const refundReason = selectedReason === 'other' ? reason : selectedReason;
      const response = await API.refundOrderItemsAndFees(
        selectedItems,
        selectedFees,
        hasAccess.user.id,
        refundReason,
      );
      this.setState({ processing: false });

      if (response.error) {
        this.setState({
          errors: { server: response.error },
        });
        return;
      }
      if (selectedReason === 'Out of Stock' && updateStock) {
        const menuItems = itemsToRefund.map(item => item.menuItem);
        API.stockUpdate(menuItems, false);
      }

      onSuccess(itemsToRefund);
    }
  };

  _toggleItem = orderItemId => {
    const { selectedItems } = this.state;
    const newSet = _.xor(selectedItems, [orderItemId]);

    this.setState(
      {
        selectedItems: newSet,
      },
      () => {
        this.setState({
          maxSalesRefund: this._getMaxSalesRefund(),
          maxTipRefund: this._getMaxTipRefund(),
        });
        this._validate();
      },
    );
  };

  _toggleFee = feeId => {
    const { selectedFees } = this.state;
    const newSet = _.xor(selectedFees, [feeId]);

    this.setState(
      {
        selectedFees: newSet,
      },
      () => {
        this.setState({
          maxFeesRefund: this._getMaxFeeRefund(),
        });
        this._validate();
      },
    );
  };
}

function CurrencySymbol(props) {
  const { getCurrencySymbol } = useGlobalize();

  return <Text {...props}>{getCurrencySymbol()}</Text>;
}

// Todo: replace with Global modal styles ie: import {modalStyles} from 'styles/Global'
const styles = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modal: {
    height: '90%',
  },
  body: {
    backgroundColor: 'white',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    paddingLeft: 15,
    backgroundColor: Colors.primary,
    height: 60,
  },
  headerText: {
    color: Colors.light,
    flex: 1,
    fontSize: 16,
  },
  cancelBtn: {
    backgroundColor: Colors.error,
  },
  submitBtn: {
    backgroundColor: Colors.primary,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: Colors.light,
  },
  errorMsg: {
    padding: 5,
    paddingLeft: 10,
    backgroundColor: Colors.errorBackground,
  },
  errorMsgText: {
    color: Colors.errorText,
  },
});
