import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

export default class ScrollButtons extends React.Component {
  render() {
    const { upDisabled, downDisabled, onUpPress, onDownPress } = this.props;
    return (
      <View style={{ width: 50 }}>
        <TouchableWithoutFeedback
          onPress={onUpPress}
          disabled={upDisabled}
        >
          <View
            style={{
              flex: 1,
              borderRadius: 5,
              margin: 4,
              backgroundColor: upDisabled ? '#ececec' : '#ccc',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              as={MaterialIcons}
              name="arrow-drop-up"
              style={{ color: upDisabled ? '#ccc' : 'black' }}
            />
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={onDownPress}
          disabled={downDisabled}
        >
          <View
            style={{
              flex: 1,
              borderRadius: 5,
              margin: 4,
              backgroundColor: downDisabled ? '#ececec' : '#ccc',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              as={MaterialIcons}
              name="arrow-drop-down"
              style={{ color: downDisabled ? '#ccc' : 'black' }}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}
