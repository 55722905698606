import React from 'react';
import TextField from './TextField';
import { formatNumber } from '../../../helpers/HelperFunctions';

function NumberField(props) {
  const { required } = props;

  const validator = value => {
    if (!required && !value) return true;
    return value ? !!value.match(/[\d.-]+$/) : false;
  };

  return (
    <TextField
      {...props}
      validator={validator}
      formatter={formatNumber}
      keyboardType="numeric"
    />
  );
}

export default NumberField;
