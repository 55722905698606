import React from 'react';
import PropTypes from 'prop-types';
import { showMessage } from 'react-native-flash-message';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RefundReasonModal from './RefundReasonModal';
import { CmdButton } from './index';
import IconButton from './IconButton';

export default class RefundIconButton extends React.Component {
  static propTypes = {
    orders: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    onSuccess: PropTypes.func,
  };

  state = {
    showModal: false,
  };

  render() {
    const { showModal } = this.state;
    const { orders, selectedItems, style, iconProps, testID } = this.props;

    const orderId = selectedItems.length && selectedItems[0].orderId;
    const canRefund = selectedItems.length && selectedItems.every(item => item.orderId === orderId);

    let order;

    if (canRefund) {
      order = orders.find(tempOrder => tempOrder.orderId === orderId);
    }

    return (
      <>
        <IconButton
          testID={testID}
          icon="cash-refund"
          iconType={MaterialCommunityIcons}
          style={style}
          disabled={!canRefund}
          disabledMsg="You must select some items and all items must be from the same order"
          onPress={this._startRefund}
          label="order__refund"
          key="refund_btn"
          {...iconProps}
        />
        <RefundReasonModal
          testID="refundReasonModal"
          key="refundreasonmodal"
          order={order}
          selectedItems={selectedItems.map(i => i.orderitemid)}
          visible={showModal}
          onCancel={this._hideModal}
          onSuccess={this._onSuccess}
        />
      </>
    );
  }

  _hideModal = () => this.setState({ showModal: false });

  _onSuccess = () => {
    const { onSuccess, selectedItems } = this.props;
    this.setState({
      showModal: false,
    });
    onSuccess(selectedItems);
    showMessage({
      floating: true,
      position: 'top',
      type: 'success',
      message: 'Refund completed successfully',
    });
  };

  _startRefund = () => {
    this.setState({ showModal: true });
  };
}
