import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import Segment, { SegmentButton } from '../../components/Segment';
import Card from './Card';

/**
 * The <TipsCard> contains the row of buttons to select presets or custom tip amounts, and allows the patron to enter
 * their custom amount or percentage for tip if they choose.
 *
 * The selections will update the <TipScreen> component's state to be accurately displayed in the total cost.
 *
 * @param props
 * @param {CheckModel} props.check Information on the current order, including items and various prices
 * @param {ReactHook<number>} props.setTipAmount The React hook for setting the final dollar amount of the Tip
 * @returns {JSX.Element}
 * @constructor
 */
function FulfillmentMethodCard({ cart, fulfillmentMethod, setFulfillmentMethod, ...rest }) {
  const { possible_fulfillment_methods } = cart.location;

  return (
    <Card
      testID="fulfillmentMethodCard"
      titlePath="title__method"
      title="Method"
      {...rest}
    >
      <Segment
        nativeID="methodSelector"
        style={styles.segmentStyle}
      >
        {possible_fulfillment_methods.map(entry => {
          const { button_name, fulfillment_method: fm_option } = entry;
          return (
            <SegmentButton
              key={fm_option}
              testID={`method_${fm_option}`}
              display={button_name}
              value={fm_option}
              selected={fm_option === fulfillmentMethod}
              onPress={val => {
                cart.fulfillment_method = val;
                cart._needPriceCheck = true; // todo: `getCartPrice({ promo, force })`
                cart.getCartPrice();
                setFulfillmentMethod(val);
              }}
            />
          );
        })}
      </Segment>
    </Card>
  );
}

export default FulfillmentMethodCard;

const styles = EStyleSheet.create({
  segmentStyle: {
    marginTop: 5,
    marginBottom: 10,
  },
});
