import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { View, Dimensions, ActivityIndicator } from 'react-native';
import {
  AlertDialog,
  Button,
  CheckIcon,
  Checkbox,
  FormControl,
  Icon,
  Input,
  Modal,
  Pressable,
  Radio,
  Row,
  Select,
  Stack,
  Text,
  TextArea,
  WarningOutlineIcon,
  Box,
} from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { showMessage } from 'react-native-flash-message';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { FormattedCurrency } from 'react-native-globalize';
import Colors from '../constants/Colors';
import CurrencyInput from './CurrencyInput';
import API from '../api';
import Segment, { SegmentButton } from './Segment';
import { Loader } from './index';
import { checkAccess } from '../helpers/Access';
import Alert from './Alert';

function CreateEditMenuItemModal({
  visible,
  onClose,
  item,
  inventory,
  onInventoryUpdate,
  editMode,
}) {
  const [isVendorItem, setIsVendorItem] = useState(false);
  const [modalHidden, setModalHidden] = useState(false); // Hide Modal when showing PinPad
  const [inventoryAction, setInventoryAction] = useState('set');
  const [inventoryInput, setInventoryInput] = useState('');
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);
  const [processing, setProcessing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingEdit, setIsSavingEdit] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [availableMenus, setAvailableMenus] = useState(Object.values(API.menuData.menusById));
  const [availableMenuHeadings, setAvailableMenuHeadings] = useState([]);
  // Todo: Listen for station changes
  const [availableStations, setAvailableStations] = useState(
    Object.values(API._stations).filter(s => s.customer_id === API.main_customer.customer_id),
  );
  const [itemNamesExpanded, setItemNamesExpanded] = useState(false);

  const cancelRef = React.useRef(null);
  const [itemValues, setItemValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {},
  );
  const [errors, setErrors] = useReducer((state, newState) => {
    if (_.isEmpty(newState)) return {};
    return { ...state, ...newState };
  }, {});

  const areNamesSame = () =>
    itemValues.name_for_customer === itemValues.name_for_bartender &&
    itemValues.name_for_bartender === itemValues.name_for_owner;

  const isMenuItem = (menu_item_class = itemValues.menu_item_class) =>
    ['food', 'drink'].includes(menu_item_class);

  const updateDimensions = ({ window }) => {
    const { width } = window;
    setWindowWidth(width);
  };

  useEffect(() => {
    const menuDataListener = API.on('menu', updateMenuData); // test this whether it should be on 'menu' or 'menuData'
    const listener = Dimensions.addEventListener('change', _.debounce(updateDimensions, 100));
    return () => {
      menuDataListener.remove();
      listener.remove();
    };
  }, []);

  useEffect(() => {
    if (!visible) return;

    const editableKeys = [
      'name_for_customer',
      'name_for_bartender',
      'name_for_owner',
      'description',
      'price_in_cents',
      'most_loved',
      'menu_item_class',
      'menu_set',
      'tax_fraction',
      'menu_heading.id',
      'stations_that_can_fulfill',
    ];
    const initialValues = _.zipObject(
      editableKeys,
      _.map(editableKeys, key => _.get(item, key)),
    );
    setItemValues(initialValues);
    setIsSavingEdit(false);
    setIsSaving(false);
    setErrors({});

    if (!item.menuItemId) {
      // apply defaults when creating a new item:
      setItemValues({
        menu_item_class: 'food',
        price_in_cents: 0,
        tax_fraction: API.main_customer.default_tax_fraction,
        menu_set: [],
        stations_that_can_fulfill: [],
      });
      setItemNamesExpanded(false);
      setIsVendorItem(false);
    } else {
      const is_vendor_item = isMenuItem(item.menu_item_class)
        ? API.menuData.menusById[item.menu_set[0]].customer.customer_id !==
          API.main_customer.customer_id
        : item.menu_heading?.customer_id !== API.main_customer.customer_id;

      const allOriginalNamesAreSame =
        item.name_for_bartender === item.name_for_customer &&
        item.name_for_owner === item.name_for_customer;
      setInventoryInput(API.inventory[item.inventory_item_id]);
      setItemNamesExpanded(!allOriginalNamesAreSame);
      setIsVendorItem(is_vendor_item);
    }
    updateMenuData();
  }, [visible]);

  const updateMenuData = () => {
    if (visible) {
      const customerMenus = API.menuData.menus.filter(
        menu =>
          menu.customer.customer_id ===
          (item.menuItemId ? item.menu_heading.customer_id : API.main_customer.customer_id),
      );
      setAvailableMenus(customerMenus);
      setAvailableMenuHeadings(
        itemValues.menu_item_class === 'addon' || itemValues.menu_item_class === 'mixer'
          ? Object.values(API.menuData.modifierGroupsById)
          : Object.values(API.menuData.headingsById)
              .map(arr => arr[0])
              .flat(1),
      );
    } else {
      setProcessing(false);
    }
  };

  // This should really be in a `useCallback` since it has dependencies
  const submitInventoryChange = async () => {
    let hasAccess = true;
    if (editMode) {
      setModalHidden(true);
      hasAccess = await checkAccess('manage_inventory', true);
      setModalHidden(false);
    }
    if (hasAccess) {
      setProcessing(true);
      try {
        const res = await API.editInventoryItem(item.menuItemId, {
          action: 'quantity',
          quantity_action: inventoryAction,
          quantity_value: inventoryInput,
        });
        if (res?.success) {
          onInventoryUpdate(item.inventory_item_id, res.new_item_inventory);
        } else {
          showMessage({
            message: 'Unable to change inventory count.',
            type: 'warning',
            position: 'top',
          });
        }
      } catch (error) {}
      setProcessing(false);
    }
  };

  const inventoryLabel = () => {
    if (inventory) {
      return `Inventory: ${inventory}`;
    }
    return 'Inventory';
  };

  const submitDisable = async () => {
    setModalHidden(true);
    const hasAccess = await checkAccess('manage_inventory', true);
    setModalHidden(false);
    if (hasAccess) {
      setIsSaving(true);
      try {
        const res = await API.createEditMenuItem(item.menuItemId, {
          enabled: 'false',
        });
        await API.doMenuUpdate();
        if (res.error) {
          showMessage({
            message: res.error || 'Could not disable the item.',
            type: 'error',
            position: 'top',
          });
        } else {
          onClose();
          showMessage({
            message: 'Successfully disabled the item!',
            type: 'success',
            position: 'top',
          });
        }
      } catch (error) {
        console.error(error);
        showMessage({
          message: error.message || 'Could not disable the item.',
          type: 'error',
          position: 'top',
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleToggle = () => {
    if (itemNamesExpanded && !areNamesSame()) {
      Alert.alert('', 'Set all names to be the same as Item Name on Menu?', [
        { text: 'Cancel' },
        {
          text: 'Yes',
          onPress: () => {
            setItemValues({
              name_for_bartender: itemValues.name_for_customer,
              name_for_owner: itemValues.name_for_owner,
            });
            setErrors({ itemNameForBartender: false, itemNameForOwner: false });
            setItemNamesExpanded(!itemNamesExpanded);
          },
        },
      ]);
    } else {
      setItemNamesExpanded(!itemNamesExpanded);
    }
  };

  const submitCreateEdit = async () => {
    if (!isValidMenuItemForm()) return;
    setIsSavingEdit(true);
    let hasAccess = true;
    if (editMode) {
      setModalHidden(true);
      hasAccess = await checkAccess('add_edit_menu_items', true);
      setModalHidden(false);
      setIsSavingEdit(false);
    }
    if (hasAccess) {
      try {
        const properties = { ...itemValues };
        properties.menu_heading_id = itemValues['menu_heading.id'];
        // this works
        properties.pretax_cents = itemValues.price_in_cents;
        if (['mixer', 'addon'].includes(itemValues.menu_item_class))
          delete properties.stations_that_can_fulfill;

        if (!editMode) properties.enabled = true;
        const res = await API.createEditMenuItem(item.menuItemId, properties);

        if (res.error) {
          showMessage({
            message:
              res.error ||
              (editMode ? 'Could not modify this item.' : 'Could not create this item.'),
            type: 'error',
            position: 'top',
          });
        } else {
          if (item.menuItemId) {
            item.update(res.item);
          } else {
            API.handheldPoll(); // trigger immediate poll
          }
          onClose();
          showMessage({
            message: editMode
              ? 'Successfully modified the item!'
              : 'Successfully created the item!',
            type: 'success',
            position: 'top',
          });
        }
      } catch (error) {
        console.error(error);
        showMessage({
          message:
            error || (editMode ? 'Could not modify this item.' : 'Could not create this item.'),
          type: 'error',
          position: 'top',
        });
      } finally {
        setIsSavingEdit(false);
      }
    }
  };

  const isValidMenuItemForm = () => {
    const is_menu_item = isMenuItem();
    const newErrors = {};
    if (!itemValues.menu_item_class) newErrors.menu_item_class = true;
    if (is_menu_item && itemValues.menu_set.length === 0) newErrors.menus = true;
    if (!itemValues['menu_heading.id']) newErrors.menuHeadingId = true;
    if (
      !itemValues.name_for_customer ||
      itemValues.name_for_customer.replace(/\s/g, '').length === 0
    )
      newErrors.itemNameForCustomer = true;
    if (
      !itemValues.name_for_bartender ||
      itemValues.name_for_bartender.replace(/\s/g, '').length === 0
    )
      newErrors.itemNameForBartender = true;
    if (!itemValues.name_for_owner || itemValues.name_for_owner.replace(/\s/g, '').length === 0)
      newErrors.itemNameForOwner = true;
    if (itemValues.tax_fraction < 0 || itemValues.tax_fraction > 1) newErrors.tax_fraction = true;

    if (is_menu_item && !itemValues.stations_that_can_fulfill.length)
      newErrors.stations_that_can_fulfill = true;

    setErrors(newErrors);

    return !Object.values(newErrors).length && !isSavingEdit;
  };

  return (
    <>
      <Modal
        isOpen={visible && !modalHidden}
        onClose={onClose}
        closeOnOverlayClick
        overlayVisible
      >
        <Modal.Content style={[styles.modal, { width: windowWidth < 420 ? '100%' : '80%' }]}>
          {processing && <Loader />}
          <Modal.CloseButton />
          <Modal.Header>{editMode ? 'Item Details' : 'Create Item'}</Modal.Header>
          <Modal.Body style={{ padding: 10 }}>
            {isVendorItem && (
              <View style={{ width: '100%', backgroundColor: 'yellow', padding: 5 }}>
                <Text>{item?.vendor_name} item not editable from this Terminal</Text>
              </View>
            )}
            <FormControl
              mb={2}
              isInvalid={errors.menu_item_class}
              isDisabled={isVendorItem}
            >
              <FormControl.Label>Menu Item Class</FormControl.Label>
              <Radio.Group
                onChange={e => {
                  setItemValues({ menu_item_class: e });
                  setErrors({ menu_item_class: null });
                  setAvailableMenuHeadings(
                    e === 'addon' || e === 'mixer'
                      ? Object.values(API.menuData.modifierGroupsById)
                      : Object.values(API.menuData.headingsById)
                          .map(arr => arr[0])
                          .flat(1),
                  );
                }}
                value={itemValues.menu_item_class || 'food'}
                accessibilityLabel="choose menu class"
              >
                {(['food', 'drink'].includes(itemValues.menu_item_class) || !editMode) && (
                  <>
                    <Radio
                      size="menu"
                      my={0.5}
                      value="food"
                    >
                      Food
                    </Radio>
                    <Radio
                      size="menu"
                      value="drink"
                      my={0.5}
                    >
                      Drink
                    </Radio>
                  </>
                )}
                {(['addon', 'mixer'].includes(itemValues.menu_item_class) || !editMode) && (
                  <>
                    <Radio
                      size="menu"
                      value="addon"
                      my={0.5}
                    >
                      Food Modifier
                    </Radio>
                    <Radio
                      size="menu"
                      value="mixer"
                      my={0.5}
                    >
                      Drink Modifier
                    </Radio>
                  </>
                )}
              </Radio.Group>
              {!!errors.menu_item_class && (
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  Please select the menu item class.
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            {(itemValues.menu_item_class === 'food' || itemValues.menu_item_class === 'drink') && (
              <FormControl
                mb={2}
                isInvalid={errors.menus}
                isDisabled={isVendorItem}
              >
                <FormControl.Label>Menu(s)</FormControl.Label>
                <Checkbox.Group
                  onChange={v => {
                    setItemValues({ menu_set: v || [] });
                    setErrors({ menus: !v?.length });
                  }}
                  value={itemValues.menu_set || []}
                  accessibilityLabel="choose menus"
                >
                  {availableMenus.map(menu => (
                    <Checkbox
                      key={`menu_${menu.menuId}`}
                      value={menu.menuId}
                      colorScheme="info"
                      size="menu"
                    >
                      {menu.menuName}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
                {errors.menus && (
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    At least one menu should be selected.
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            )}

            <FormControl
              isInvalid={errors.menuHeadingId}
              isDisabled={itemValues.menu_item_class === '' || isVendorItem}
            >
              <FormControl.Label>
                {itemValues.menu_item_class === 'addon' || itemValues.menu_item_class === 'mixer'
                  ? 'Modifier Group'
                  : 'Menu Heading'}
              </FormControl.Label>
              <Select
                selectedValue={itemValues['menu_heading.id']}
                minWidth="200"
                accessibilityLabel={item?.menu_heading?.heading_name}
                placeholder={
                  editMode ? item?.menu_heading?.heading_name || 'None Selected' : 'None Selected'
                }
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                mt={1}
                onValueChange={v => {
                  setItemValues({ 'menu_heading.id': v });
                  if (v) setErrors({ menuHeadingId: false });
                }}
                size="md"
              >
                {availableMenuHeadings.map(head => (
                  <Select.Item
                    key={`heading_${head.id}`}
                    label={head.heading_name}
                    value={head.id}
                  />
                ))}
              </Select>
              {errors.menuHeadingId && (
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  You must select a menu heading.
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <Box
              shadow={itemNamesExpanded ? 3 : 0}
              padding={itemNamesExpanded ? 2 : 0}
              marginTop={2}
              backgroundColor={itemNamesExpanded ? '#f5f5f5' : ''}
            >
              <FormControl
                isInvalid={errors.itemNameForCustomer}
                isDisabled={isVendorItem}
              >
                <Pressable
                  onPress={handleToggle}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormControl.Label>
                    {!itemNamesExpanded ? 'Item Name' : 'Item Name on Menu'}
                  </FormControl.Label>
                  {itemNamesExpanded && !areNamesSame() ? (
                    <Icon
                      name="equals"
                      as={FontAwesome5}
                      style={{ color: 'black', fontSize: 16 }}
                    />
                  ) : (
                    <Icon
                      name={itemNamesExpanded ? 'caretdown' : 'caretright'}
                      as={AntDesign}
                      style={{ color: 'black', fontSize: 16 }}
                    />
                  )}
                </Pressable>
                <View style={{ flexDirection: 'row' }}>
                  <Input
                    flex={1}
                    value={itemValues.name_for_customer || ''}
                    onChangeText={text => {
                      if (!itemNamesExpanded) {
                        setItemValues({
                          name_for_bartender: text,
                          name_for_owner: text,
                        });
                        setErrors({ itemNameForBartender: !text, itemNameForOwner: !text });
                      }
                      setItemValues({ name_for_customer: text });
                      setErrors({ itemNameForCustomer: !text });
                    }}
                    size="md"
                  />
                </View>
                {errors.itemNameForCustomer && (
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Item name cannot be empty.
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
              {itemNamesExpanded && (
                <>
                  <FormControl
                    mt={2}
                    isInvalid={errors.itemNameForBartender}
                    isDisabled={isVendorItem}
                  >
                    <FormControl.Label>Item Name on Tablet</FormControl.Label>
                    <Input
                      value={itemValues.name_for_bartender || ''}
                      onChangeText={text => {
                        setItemValues({ name_for_bartender: text });
                        setErrors({ itemNameForBartender: !text });
                      }}
                      size="md"
                    />
                    {errors.itemNameForBartender && (
                      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                        Item name cannot be empty.
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    mt={2}
                    isInvalid={errors.itemNameForOwner}
                    isDisabled={isVendorItem}
                  >
                    <FormControl.Label>Item Name on Reports</FormControl.Label>
                    <Input
                      value={itemValues.name_for_owner || ''}
                      onChangeText={text => {
                        setItemValues({ name_for_owner: text });
                        setErrors({ itemNameForOwner: !text });
                      }}
                      size="md"
                    />
                    {errors.itemNameForOwner && (
                      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                        Item name cannot be empty.
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                </>
              )}
            </Box>
            <FormControl
              mt={2}
              isDisabled={isVendorItem}
            >
              <FormControl.Label>Item Description</FormControl.Label>
              <TextArea
                value={itemValues.description}
                onChangeText={text => setItemValues({ description: text })}
                rowSpan={3}
                size="md"
              />
            </FormControl>
            <FormControl
              mt={2}
              isDisabled={isVendorItem}
            >
              <Row space={2}>
                <FormControl.Label>Most Loved</FormControl.Label>
                <Checkbox
                  value="one"
                  onChange={e => setItemValues({ most_loved: e })}
                  size="sm"
                  defaultIsChecked={itemValues.most_loved}
                  ml={1}
                  mt={1}
                  colorScheme="info"
                  accessibilityLabel="loved"
                />
              </Row>
            </FormControl>
            <FormControl
              mt={2}
              isDisabled={isVendorItem}
            >
              <FormControl.Label>Price</FormControl.Label>
              <CurrencyInput
                onChangeValue={e => {
                  setItemValues({ price_in_cents: Math.round(e * 100) });
                }}
                value={itemValues.price_in_cents / 100}
                disabled={isVendorItem}
              />
              {API.main_customer.tax_inclusive_pricing && (
                <Text style={{ fontSize: 12, fontColor: 'darkgray' }}>
                  (Before tax:{' '}
                  <FormattedCurrency
                    value={
                      (itemValues.price_in_cents -
                        itemValues.tax_fraction * itemValues.price_in_cents) /
                      100
                    }
                  />{' '}
                  )
                </Text>
              )}
            </FormControl>
            <FormControl
              mt={2}
              isInvalid={errors.tax_fraction}
              isDisabled={isVendorItem}
            >
              <FormControl.Label>Tax Fraction</FormControl.Label>
              <View style={styles.taxFraction}>
                <Input
                  InputRightElement={<Text style={{ marginRight: 4 }}>%</Text>}
                  value={`${itemValues.tax_fraction * 100}`}
                  type="number"
                  onChange={e => {
                    const { value } = e.target / 100;
                    setErrors({ tax_fraction: value < 0 || value > 1 });
                    setItemValues({ tax_fraction: value });
                  }}
                  keyboardType="numeric"
                  size="md"
                  textAlign="right"
                  style={styles.taxFractionInput}
                />
              </View>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Tax fraction should be set between 0 ~ 100%.
              </FormControl.ErrorMessage>
            </FormControl>
            {['food', 'drink'].includes(itemValues.menu_item_class) && (
              <FormControl
                mt={2}
                isInvalid={errors.stations_that_can_fulfill}
                isDisabled={isVendorItem}
              >
                <FormControl.Label>Fulfilling Station(s)</FormControl.Label>
                {isVendorItem ? (
                  <Text>
                    {itemValues.stations_that_can_fulfill
                      .map(s => API._stations[s]?.station_name || 'Unknown Station')
                      .join(', ')}
                  </Text>
                ) : (
                  <Checkbox.Group
                    accessibilityLabel="Choose station(s)"
                    value={itemValues.stations_that_can_fulfill}
                    onChange={v => {
                      setItemValues({ stations_that_can_fulfill: v });
                      setErrors({ stations_that_can_fulfill: !v.length });
                    }}
                  >
                    {availableStations.map(station => (
                      <Checkbox
                        key={`station_${station.id}`}
                        value={station.id}
                        colorScheme="info"
                        size="menu"
                      >
                        {station.station_name}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                )}

                {errors.stations_that_can_fulfill && (
                  <FormControl.ErrorMessage>
                    Menu item must be fulfillable at at least one station
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            )}

            {editMode && (
              <FormControl mt={2}>
                <Stack
                  stackedLabel
                  style={{ alignItems: 'flex-start' }}
                >
                  <FormControl.Label>{inventoryLabel()}</FormControl.Label>
                  <View style={{ width: '100%', marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flex: 1 }}>
                        <Segment style={{ width: '100%', paddingVertical: 10 }}>
                          <SegmentButton
                            style={{ padding: 10 }}
                            textStyle={{ fontSize: 15 }}
                            display="SET"
                            value="set"
                            selected={inventoryAction === 'set'}
                            onPress={() => setInventoryAction('set')}
                          />
                          <SegmentButton
                            style={{ padding: 10 }}
                            textStyle={{ fontSize: 15 }}
                            display="ADD"
                            value="add"
                            selected={inventoryAction === 'add'}
                            onPress={() => setInventoryAction('add')}
                          />
                          <SegmentButton
                            style={{ padding: 10 }}
                            textStyle={{ fontSize: 15 }}
                            display="REMOVE"
                            value="remove"
                            selected={inventoryAction === 'remove'}
                            onPress={() => setInventoryAction('remove')}
                          />
                        </Segment>
                      </View>
                      <View style={{ width: 120, borderWidth: 0 }}>
                        <Input
                          variant="ghost"
                          style={styles.inventoryInput}
                          value={inventoryInput || ''}
                          placeholder="Amount"
                          type="number"
                          keyboardType="numeric"
                          onChangeText={text => {
                            text = text.replace(/[^0-9.,]+/, '');
                            setInventoryInput(text?.length > 0 ? parseInt(text) : 0);
                          }}
                        />
                      </View>
                    </View>
                    <View style={styles.inventoryCancelButton}>
                      <Button
                        style={{ height: 40, marginLeft: 10 }}
                        isDisabled={!inventoryInput || isVendorItem}
                        onPress={submitInventoryChange}
                        isLoading={processing}
                        isLoadingText="Processing..."
                      >
                        Update Inventory
                      </Button>
                    </View>
                  </View>
                </Stack>
              </FormControl>
            )}
          </Modal.Body>
          <Modal.Footer>
            <View style={styles.container}>
              <Button
                style={styles.disableButton}
                colorScheme="danger"
                isDisabled={isVendorItem || (editMode ? isSaving : true)}
                onPress={async () => {
                  setModalHidden(true);
                  if (!editMode || (await checkAccess('manage_inventory', true)))
                    setIsAlertOpen(true);
                  setModalHidden(false);
                }}
              >
                Disable
              </Button>
              <Button.Group>
                <Button
                  colorScheme="coolGray"
                  onPress={onClose}
                >
                  Close
                </Button>
                <Button
                  style={styles.saveButton}
                  onPress={submitCreateEdit}
                  isDisabled={isVendorItem || isSavingEdit}
                  isLoading={isSavingEdit}
                  isLoadingText="Saving"
                >
                  Save
                </Button>
              </Button.Group>
            </View>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Disable Menu Item</AlertDialog.Header>
          <AlertDialog.Body>
            {'This will disable and remove this menu item from Terminal. Once disabled, ' +
              'the item can only be enabled through owner panel.\n\nAre you sure to proceed?'}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={() => setIsAlertOpen(false)}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button
                colorScheme="danger"
                onPress={submitDisable}
                isDisabled={isSaving}
              >
                {isSaving ? <ActivityIndicator /> : 'Disable'}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}

export default CreateEditMenuItemModal;

const styles = EStyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modal: {
    borderWidth: 1,
    borderColor: Colors.darkGray,
    width: '80%',
    maxWidth: 600,
    minHeight: 600,
    backgroundColor: Colors.light,
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inventoryInput: {
    borderWidth: 1,
    height: 40,
    marginVertical: 10,
    marginLeft: 20,
    marginRight: 5,
    padding: 5,
    textAlign: 'right',
  },
  inventoryCancelButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 5,
    marginTop: 10,
  },
  updateInventoryButton: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  container: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  disableButton: {},
  saveButton: {
    marginHorizontal: 5,
  },
  taxFraction: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 100,
  },
  taxFractionInput: {
    width: 100,
  },
});
