import React, { Component } from 'react';
import { FlatList, Text, View } from 'react-native';
import Order from './Order';
import { Typography } from '../../styles';

class OrderList extends Component {
  render() {
    const { orders } = this.props;
    return (
      <View style={{ flex: 1 }}>
        {orders ? orders.map(order => this.renderOrder(order)) : this.renderEmptyMessage()}
      </View>
    );
  }

  renderOrder = order => (
    <Order
      key={order.orderId}
      order={order}
      allowSelectItems={false}
    />
  );

  renderEmptyMessage = () => (
    <View style={{ padding: 20, flex: 1 }}>
      <Text style={[Typography.header2, { textAlign: 'center', marginBottom: 20 }]}>Empty</Text>
      <Text style={{ textAlign: 'center' }}>No orders found.</Text>
    </View>
  );
}

export default OrderList;
