import { View, useBreakpointValue } from 'native-base';
import React from 'react';
import { FlatList } from 'react-native';
import { GridStyles as styles } from '../../styles/POSStyles';

const BREAKPOINTS = {
  sm: 1,
  md: 1,
  lg: 2,
  xl: 3,
};

function Grid(props) {
  const { renderItem, data, keyExtractor, breakpoints = BREAKPOINTS } = props;
  const cols = useBreakpointValue(breakpoints);

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      numColumns={cols}
      key={cols}
      data={data}
      renderItem={item => <View style={styles.ItemWrapper}>{renderItem(item)}</View>}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={() => <View style={styles.ItemSeparatorComponent} />}
    />
  );
}

export default Grid;
