import _ from 'lodash';
import moment from 'moment';
import EventModel from './EventModel';
import OrderItem from './OrderItem';
import { StatusIcons } from '../constants/Constants';
import DriverDeliveryJob from './DriverDeliveryJob';
import Patron from './Patron';

export default class Order extends EventModel {
  static get className() {
    return 'Order';
  }

  orderId = null;

  orderNumberToday = 0;

  customer_id = 0;

  bartender_id = null;

  /** @type {OrderItem[]} */
  items = [];

  /** @type {?moment.Moment} */
  time = null;

  /** @type {Dictionary<*>} Maps Required Checkout Info field keys to their values.  */
  extra_checkout_info = {};

  extra_delivery_info = null;

  driver_delivery_jobs = [];

  /** @type {?moment.Moment} */
  user_desired_time = null;

  /** @type {?moment.Moment} */
  last_modified = null;

  /** @type {?moment.Moment} */
  snooze_till = null;

  /** @type {?moment.Moment} */
  time_closed = null;

  /** @type {?Patron} */
  patron = null;

  /** @type {string} TODO - Enumerate the different values that `fulfillment_method` can take */
  fulfillment_method = '';

  mobile = null;

  by_staff = false;

  bartending_station_id = null;

  location_id = null;

  checkout_id = null;

  locationShortCode = '';

  line_items = [];

  promos_pretax_cents_added = 0;

  fee_pretax_cents = 0;

  subtotal_cents = 0;

  get pretax_cents() {
    return this.subtotal_cents + this.fee_pretax_cents;
  }

  fee_cents = 0;

  tax_cents = 0;

  tip_cents = 0;

  total_cents = 0;

  party_tab_ids = [];

  fees_on_my_receipt = [];

  staff_notes = '';

  _field_map = {
    time: t => moment(t),
    user_desired_time: t => t && moment(t),
    last_modified: t => t && moment(t),
    snooze_till: t => t && moment(t),
    time_closed: t => t && moment(t),
    items: items => this.updateItems(items),
    patron: patron => patron && new Patron(patron),
    driver_delivery_jobs: jobs => jobs.map(job => new DriverDeliveryJob(job)),
    fees_on_my_receipt: fees => this.updateOrderFees(fees),
  };

  _cache = true;

  constructor(obj, writeToDB = true) {
    super();
    this.update(obj, writeToDB);
  }

  static SortableFields = {
    last_modified: 'Last Modified',
    time: 'Created (Order #)',
    desired_time: 'Desired Time',
    time_closed: 'Time Closed',
    snooze_till: 'Snooze Until',
  };

  get id() {
    return this.orderId;
  }

  /**
   * @returns {Location}
   */
  get location() {
    return this.api._locations[this.location_id];
  }

  get station() {
    return this.api._stations[this.bartending_station_id];
  }

  /**
   * @returns {string} The name of this order's location, or false if this Order has no Location associated with it
   */
  get location_name() {
    return this.location && this.location.locationName;
  }

  get customer() {
    return this.api._customers[this.customer_id] || {};
  }

  get orderNumber() {
    const prefix = this.customer.order_number_prefix;
    return prefix ? prefix + this.orderNumberToday : this.orderNumberToday;
  }

  get status_sequence() {
    return this.api.status_sequences[this.fulfillment_method];
  }

  /**
   * returns the 'minimum' status in order.items
   */
  get status() {
    const statuses = _.uniq(this.items.map(i => i.status));
    if (statuses.length === 1) return statuses[0];

    const sequence = this.status_sequence;
    // find the first status in the sequence which is in our statuses
    return sequence.find(s => statuses.includes(s));
  }

  get kds_open_status() {
    return this.items.filter(item => !this.api.menuData?.closed_statuses?.includes(item.status));
  }

  get pretty_status() {
    return this.api.menuData?.status_pretty_names?.[this.status] || this.status;
  }

  get kds_open() {
    // need to fix this so that status between
    return (
      !this.api.menuData?.closed_statuses?.includes(this.status) &&
      this.snooze_till.isBefore(moment())
    );
  }

  get kds_future() {
    return !this.time_closed && this.snooze_till.isAfter();
  }

  get is_snoozed() {
    return this.snooze_till.isAfter();
  }

  get kds_closed() {
    return this.api.menuData?.closed_statuses?.includes(this.status) || this.time_closed;
  }

  get checkout_info() {
    return _.orderBy(
      Object.values(this.extra_checkout_info).filter(info => info.show_on_kds),
      ['display_order'],
    );
  }

  get status_icon() {
    return StatusIcons[this.status];
  }

  get desired_time() {
    return this.user_desired_time || this.time;
  }

  update(obj, writeToDB = true, addMissing = true) {
    super.update(obj, writeToDB, addMissing);

    // this.updateItems(obj.items);

    // Update the fees
    if (obj.fees_on_my_receipt) {
      this.fees_on_my_receipt = this.updateOrderFees(obj.fees_on_my_receipt);
    }

    if (this.location) this.location.ordersUpdated(this);
    if (this.station) this.station.ordersUpdated(this);
  }

  /**
   * Returns array of orders with the same checkout_id
   * @returns {Order[]}
   */
  get related_orders() {
    return _.orderBy(
      Object.values(this.api._orders).filter(
        o => o.checkout_id === this.checkout_id && o.orderId !== this.orderId,
      ),
      'orderNumber',
    );
  }

  updateItems = items => {
    for (let i = 0; i < items.length; i++) {
      const itemObj = items[i];
      const exists = this.items.find(item => item.orderitemid === itemObj.orderitemid);
      if (exists && exists instanceof OrderItem) exists.update(itemObj);
      else this.items.push(new OrderItem(this, itemObj));
    }
    return this.items;
  };

  /**
   * Adds necessary info from the customer owned fee configs like the name_for_owner
   * @param fees[]
   * @returns fees[]
   */
  updateOrderFees = fees =>
    fees.map(fee => ({
      ...fee,
      total_cents: fee.pretax_cents + fee.tax_cents,
      name_for_owner:
        this.api._customers[this.customer_id]?.owned_fee_configs[fee.order_fee_config_id]
          ?.name_for_owner,
      tax_determined_total_cents: this.api.main_customer.tax_inclusive_pricing
        ? fee.pretax_cents + fee.tax_cents
        : fee.pretax_cents,
    }));

  setStatus = status => {
    this.items.forEach(i => (i.status = status));
  };

  get grouped_items() {
    const grouped = _.groupBy(this.items, i => i.order_item_hash + i.status);
    return _.orderBy(Object.values(grouped), [
      '[0]progress[0]',
      '[0]menuItem.menu_heading.display_position',
      '[0]menuItem.display_position',
      '[0]menuItem.name_for_bartender',
    ]);
  }

  /** Gets the total number of items and modifiers for an Order
   * Used by KDS Ticket to calculate how tall the ticket should be
   * @returns {number}
   */
  get itemAndModCount() {
    const getNumMods = mods => {
      let count = mods.length;
      mods.forEach(mod => {
        if (mod.mods) count += getNumMods(mod.mods);
      });
      return count;
    };

    let count = 0;
    this.grouped_items.forEach(group => {
      count += 1;
      if (group[0].mods?.length) count += getNumMods(group[0].mods);
    });
    return count;
  }

  getSubTotal = () =>
    this.items.reduce(
      (total, item) => (total += item.status === 'refunded' ? 0 : item.getPretaxTotal()),
      0,
    );

  getTaxTotal = () =>
    this.items.reduce(
      (total, item) => (total += item.status === 'refunded' ? 0 : item.getTaxTotal()),
      0,
    );

  getSubtotalOrTaxInclusiveTotalCents = () =>
    this.api.main_customer.tax_inclusive_pricing
      ? this.getTaxInclusiveTotal()
      : this.subtotal_cents;

  getTaxDeterminedFeeTotal = () =>
    this.api.main_customer.tax_inclusive_pricing ? this.fee_cents : this.fee_pretax_cents;

  getTaxInclusiveTotal = () => this.getSubTotal() + this.getTaxTotal();

  getTipTotal = () =>
    this.items.reduce(
      (total, item) => (total += item.status === 'refunded' ? 0 : item.tip_in_cents),
      0,
    );

  getTotal = () =>
    this.items.reduce(
      (total, item) => (total += item.status === 'refunded' ? 0 : item.getTotal()),
      0,
    );

  nextStatus = () => {
    let { status_sequence, status } = this;
    if (!status_sequence) status_sequence = [];
    const status_index = status_sequence.indexOf(status);
    if (status_index >= 0 && status_index < status_sequence.length - 1) {
      const next_status = status_sequence[status_index + 1];
      return {
        key: next_status,
        value: this.api.menuData.status_pretty_names[next_status],
      };
    }
    if (this.fulfillment_method === 'robot_delivery' && status === 'pleasesend') {
      return {
        key: 'botsent',
        value: this.api.menuData.status_pretty_names.botsent,
      };
    }
    return {
      error: 'Next status not available',
    };
  };

  /**
   * Gets the OrderItems for the current Order who's status is 'before' the nextStatus
   * This is required since we can individually advance Order Item statuses
   * @returns {OrderItem[]} Array of actionable order items
   * @private
   */
  getActionableItems = () => {
    const nextStatus = this.nextStatus();
    const sequence = this.status_sequence;
    const statusIndex = sequence.indexOf(nextStatus.key);
    return this.items.filter(
      item => sequence.indexOf(item.status) < statusIndex && item.status !== 'refunded',
    );
  };
}
