import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import { Icon } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import OrderHelper from '../../helpers/OrderHelper';
import API from '../../api';
import HeaderIconButton from '../HeaderIconButton';

function RecallButton() {
  if (!API.config.kds_show_recall_button) return null;

  const [lastClosed, setLastClosed] = useState(API._lastClosedOrder);

  useEffect(() => {
    const listener = API.on('lastClosedOrder', order => {
      setLastClosed(order);
    });
    return () => {
      listener?.remove();
    };
  }, []);

  const recallOrder = () => {
    if (lastClosed) {
      const { status, status_sequence, items } = lastClosed;
      const prevIndex = status_sequence.indexOf(status) - 1;
      const newStatus = status_sequence[prevIndex];
      OrderHelper.changeItemsState(items, newStatus, 'recall button pressed');
    }
    setLastClosed(null);
  };

  if (!lastClosed) return null;

  return (
    <HeaderIconButton
      icon="undo"
      iconStyle={{ fontSize: 16, height: 'auto' }}
      type={FontAwesome}
      onPress={recallOrder}
      name="Recall"
    />
  );
}

export default RecallButton;
