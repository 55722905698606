import React, { Component } from 'react';
import { View, KeyboardAvoidingView, Modal } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormControl, Stack, Input, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import Alert from './Alert';
import { Right, Title, Content, Header, Footer, FooterTab } from '../bbot-component-library';
import Colors from '../constants/Colors';
import { Order } from '../models';
import { styles as globalStyles } from '../styles/Global';
import API from '../api';
import Loader from './Loader';
import { formatPhoneNumber, validateEmail } from '../helpers/HelperFunctions';
import Segment, { SegmentButton } from './Segment';

const noop = () => {};

export default class SendReceiptModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    doSend: PropTypes.bool,
    order: PropTypes.instanceOf(Order),
    phone: PropTypes.string,
    email: PropTypes.string,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    doSend: true,
    onSave: noop,
    onCancel: noop,
    email: '',
    phone: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      processing: false,
      method: 'email',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { visible, phone, email, order } = this.props;

    if (visible && !prevProps.visible) {
      this.setState({
        phone:
          phone ||
          order?.patron?.mobile ||
          order?.mobile ||
          order?.extra_checkout_info?.phone_number?.value ||
          '',
        email:
          email ||
          order?.patron?.email ||
          order?.email ||
          order?.extra_checkout_info?.email?.value ||
          '',
      });
    }
  }

  render() {
    const { visible, doSend } = this.props;
    const { method, processing } = this.state;

    return (
      <Modal
        visible={visible}
        transparent
        onRequestClose={this._onCancel}
        statusBarTranslucent
      >
        <View style={globalStyles.modalBackground}>
          <KeyboardAvoidingView style={[styles.modal, { minWidth: 400 }]}>
            <Loader shown={processing} />
            <Header
              style={{
                backgroundColor: Colors.primary,
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 15,
              }}
            >
              <Title>Send Receipt</Title>
              <Right style={{ marginRight: 15, alignSelf: 'flex-end' }}>
                <Segment style={{ backgroundColor: 'transparent' }}>
                  <SegmentButton
                    darkMode
                    display="E-Mail"
                    selected={method === 'email'}
                    onPress={() => this.setState({ method: 'email' })}
                  />
                  <SegmentButton
                    darkMode
                    display="SMS"
                    selected={method === 'phone'}
                    onPress={() => this.setState({ method: 'phone' })}
                  />
                </Segment>
              </Right>
            </Header>
            <View style={{ margin: 10 }}>
              <FormControl>
                {method === 'phone' && (
                  <Stack stackedLabel>
                    <FormControl.Label>Phone Number</FormControl.Label>
                    <Input
                      value={this.state.phone}
                      autoFocus
                      variant="underlined"
                      keyboardType="phone-pad"
                      onChangeText={text => this.setState({ phone: text })}
                      onEndEditing={e => {
                        if (this.state.phone) {
                          const phone = formatPhoneNumber(this.state.phone);
                          if (phone === null) {
                            Alert.alert(
                              'Invalid Phone Number',
                              'The given phone number appears to be invalid',
                            );
                          }
                        }
                      }}
                    />
                  </Stack>
                )}
                {method === 'email' && (
                  <Stack stackedLabel>
                    <FormControl.Label>E-Mail Address</FormControl.Label>
                    <Input
                      value={this.state.email}
                      variant="underlined"
                      onChangeText={text => this.setState({ email: text })}
                      onEndEditing={e => {
                        if (this.state.email) {
                          if (!validateEmail(this.state.email)) {
                            Alert.alert(
                              'Invalid E-mail',
                              'The e-mail address appears to be invalid',
                            );
                          }
                        }
                      }}
                      keyboardType="email-address"
                    />
                  </Stack>
                )}
              </FormControl>
              <Footer style={{ backgroundColor: Colors.light, marginTop: 10 }}>
                <FooterTab>
                  <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
                    <Button
                      colorScheme="danger"
                      style={styles.button}
                      onPress={this._onCancel}
                    >
                      <Text style={{ color: '#FFF' }}>CANCEL</Text>
                    </Button>
                    <Button
                      colorScheme="primary"
                      style={styles.button}
                      onPress={this._onSave}
                    >
                      <Text style={{ color: '#FFF' }}>{doSend ? 'SEND' : 'SAVE'}</Text>
                    </Button>
                  </View>
                </FooterTab>
              </Footer>
            </View>
          </KeyboardAvoidingView>
        </View>
      </Modal>
    );
  }

  _onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  _onSave = async () => {
    const { doSend, onSave, order } = this.props;
    let { email, phone, method } = this.state;

    if (method === 'email') phone = '';
    if (method === 'phone') email = '';

    if (doSend && order) {
      this.setState({ processing: true });
      const result = await API.sendReceipt(order, email, phone);
      if (result.errorCode) {
        this.setState({ processing: false });
        Alert.alert('Error', result.errorCode);
        return;
      }
      this.setState({
        processing: false,
        email: '',
        phone: '',
      });
    }

    onSave(phone, email);
  };
}

const styles = EStyleSheet.create({
  modal: {
    borderWidth: 1,
    borderColor: Colors.dark,
    backgroundColor: Colors.light,
    minWidth: 350,
    maxWidth: 450,
  },
  button: {
    marginRight: 20,
  },
});
