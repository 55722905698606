import { createStackNavigator } from 'react-navigation-stack';
import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import StationsScreen from '../screens/StationsScreen';
import StationView from '../screens/StationView';
import { defaultNavigationOptions } from './Defaults';
import TabBarIcon from '../components/TabBarIcon';
import TabBarLabel from '../components/TabBarLabel';

const stationsStack = createStackNavigator(
  {
    StationsScreen,
    StationView,
  },
  {
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__stations"
        />
      ),
      tabBarTestID: 'stations',
      tabBarIcon: props => (
        <TabBarIcon
          {...props}
          name="md-tablet-landscape"
          type={Ionicons}
        />
      ),
      tabBarVisible: navigation.state.index === 0,
    }),
    defaultNavigationOptions,
  },
);

export default stationsStack;
