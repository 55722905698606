import React from 'react';
import { Row } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';

function Footer({ style, children, ...props }) {
  return (
    <Row
      style={[styles.footer, style]}
      justifyContent="center"
      alignItems="center"
      safeAreaBottom
    >
      {children}
    </Row>
  );
}

export default Footer;

const styles = EStyleSheet.create({
  footer: {
    backgroundColor: 'black',
    width: '100%',
  },
});
