import { Icon, IconButton } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { Dimensions, FlatList, View } from 'react-native';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Colors from '../../constants/Colors';
import { Header, SearchBar } from '../../bbot-component-library';
import MenuFilters from './MenuFilters';
import API from '../../api';
import ListMenuItem from './ListMenuItem';
import CreateEditMenuItemModal from '../../components/CreateEditMenuItemModal';
import MenuListHeader from './MenuListHeader';
import MenuListEmpty from './MenuListEmpty';
import { Loader } from '../../components';

const menuItemClasses = {
  all: ['food', 'drink', 'mixer', 'addon'],
  menuitems: ['food', 'drink'],
  modifiers: ['mixer', 'addon'],
};

const WRAP_WIDTH = 650;

export default function MenuManagement({ navigation }) {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [itemTypes, setItemTypes] = useState('all');
  const [showVendorItems, setShowVendorItems] = useState(false);
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [allItems, setAllItems] = useState(API.menuData.itemsAndModifiersByName());
  const [filteredItems, setFilteredItems] = useState(allItems);
  const [modalProps, setModalProps] = useReducer((state, newState) => ({ ...state, ...newState }), {
    showItemModal: false,
    selectedItem: null,
  });
  const [hasVendors, setHasVendors] = useState(allItems.find(i => i.vendor_name));

  const updateTypeFilter = type => {
    setLoading(true);
    setItemTypes(type);
  };

  const updateShowVendorItems = val => {
    setLoading(true);
    setShowVendorItems(val);
  };

  const updateInventoryCounts = async () => {
    try {
      await API.getInventoryDetails();
    } catch (err) {}
  };

  useEffect(() => {
    let inventoryUpdatePoller;

    const menuUpdateListener = API.on('menu', () => {
      const newAllItems = API.menuData.itemsAndModifiersByName();
      setAllItems(newAllItems);
      setHasVendors(newAllItems.find(i => i.vendor_name));
    });

    const startPollingForInventoryUpdates = () => {
      updateInventoryCounts();
      clearInterval(inventoryUpdatePoller);
      inventoryUpdatePoller = setInterval(updateInventoryCounts, 5000);
    };

    // didFocus doesn't get called on initial render:
    startPollingForInventoryUpdates();
    const fl = navigation.addListener('didFocus', () => {
      startPollingForInventoryUpdates();
    });
    const bl = navigation.addListener('didBlur', () => {
      clearInterval(inventoryUpdatePoller);
    });

    return () => {
      menuUpdateListener.remove();
      fl.remove();
      bl.remove();
    };
  }, []);

  const clearSearch = () => {
    setLoading(true);
    setSearchText('');
  };
  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }) => {
      setWidth(width);
    },
    [],
  );
  const onItemPress = item => {
    setModalProps({
      selectedItem: item,
      showItemModal: true,
    });
  };
  const showCreateModal = () => onItemPress({}); // This can't be simplified since we need item to be null not the event object
  const showEditModal = item => onItemPress(item);
  const hideItemModal = () => {
    setModalProps({
      selectedItem: {},
      showItemModal: false,
    });
  };
  const onRefreshMenu = async () => {
    setRefreshing(true);
    await API.doMenuUpdate();
    setAllItems(API.menuData.itemsAndModifiersByName());
    setRefreshing(false);
  };

  const getItemLayout = (data, index) => ({
    length: 50,
    offset: 50 * index,
    index,
  });

  const keyExtractor = item => item.menuItemId;

  const renderItem = useCallback(
    ({ item, index }) => (
      <ListMenuItem
        item={item}
        onPress={showEditModal}
      />
    ),
    [],
  );

  /**
   * This effect hook listens for changes to searchText, itemTypes, or the showVendorItems toggle
   * and updates the filteredItems state accordingly
   */
  useEffect(() => {
    setLoading(true);
    const lcSearchText = searchText.toLowerCase();
    setFilteredItems(
      allItems.filter(
        item =>
          menuItemClasses[itemTypes].includes(item.menu_item_class) &&
          (showVendorItems ? true : !item.vendor_name) &&
          (!searchText || item.name_for_customer.toLowerCase().indexOf(lcSearchText) >= 0),
      ),
    );
  }, [searchText, itemTypes, showVendorItems]);

  /**
   * When filteredItems changes, be sure to setLoading to false
   */
  useEffect(() => {
    setLoading(false);
  }, [filteredItems]);

  return (
    <View
      style={{ backgroundColor: 'white', flex: 1 }}
      onLayout={onLayout}
    >
      {/* === HEADER === */}
      <Header style={{ padding: 4 }}>
        {/* ==== SearchMenu Input ===== */}
        <View style={{ flex: 1, marginRight: 10 }}>
          <SearchBar
            value={searchText}
            onChangeText={setSearchText}
            onClear={clearSearch}
          />
        </View>
        {width >= WRAP_WIDTH && (
          <MenuFilters
            selected={itemTypes}
            onChange={updateTypeFilter}
            hasVendors={hasVendors}
            showVendorItems={showVendorItems}
            onToggleVendorItems={updateShowVendorItems}
          />
        )}
        <IconButton
          icon={
            <Icon
              as={Ionicons}
              name="add-circle-outline"
              color={Colors.highlight}
            />
          }
          _hover={{ bg: 'blue.300:alpha.20' }}
          borderRadius="full"
          onPress={showCreateModal}
        />
      </Header>
      {width < WRAP_WIDTH && (
        <Header style={{ paddingBottom: 4 }}>
          <MenuFilters
            selected={itemTypes}
            onChange={updateTypeFilter}
            hasVendors={hasVendors}
            showVendorItems={showVendorItems}
            onToggleVendorItems={updateShowVendorItems}
          />
        </Header>
      )}
      <View style={{ flex: 1, width: '100%' }}>
        {loading && <Loader />}
        <FlatList
          ListHeaderComponent={<MenuListHeader />}
          data={filteredItems}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          removeClippedSubviews
          maxToRenderPerBatch={20}
          stickyHeaderIndices={[0]}
          windowSize={25}
          initialNumToRender={20}
          refreshing={refreshing}
          ListEmptyComponent={
            <MenuListEmpty
              isEmpty={!allItems.length}
              noResults={!filteredItems.length}
              searchText={searchText}
              onRefresh={onRefreshMenu}
              refreshing={refreshing}
            />
          }
          getItemLayout={getItemLayout}
        />
      </View>
      <CreateEditMenuItemModal
        item={modalProps.selectedItem}
        visible={modalProps.showItemModal}
        editMode={!!modalProps.selectedItem}
        onClose={hideItemModal}
      />
    </View>
  );
}
