import React from 'react';
import { Image } from 'react-native';
import { StatusIcons } from '../constants/Constants';
import Colors from '../constants/Colors';

function StatusIcon({ status, size = 'large', color = Colors.statusIcon, style = {} }) {
  const dimension = size === 'large' ? 32 : 16;
  const defaultStyle = { width: dimension, height: dimension };
  let source = StatusIcons[size][status];
  if (color === Colors.light) {
    source = StatusIcons[size].white[status];
  }

  // At this point, tintColor has no effect on Web. Should convert images to SVG icons
  return (
    <Image
      resizeMode="contain"
      style={[defaultStyle, style]}
      source={source}
      fadeDuration={0}
      tintColor={color}
    />
  );
}

export default StatusIcon;
