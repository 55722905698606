import _ from 'lodash';
import React from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Icon } from 'native-base';
import PropTypes from 'prop-types';
import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import API from '../api';

import Colors from '../constants/Colors';
import StatusIcon from './StatusIcon';

export default class LocationButton extends React.Component {
  _listeners = [];

  componentDidMount() {
    this._mounted = true;
    const { location } = this.props;

    /* TODO:
       Trigger event on location when smart_orders and seated_groups change for the location
     */
    this._listeners = [
      location.on('orders', this._refresh),
      location.on('tabs', this._refresh),
      location.on('update', this._refresh),
      location.on('notice', this._refresh),
      API.config.on('update', this._refresh),
    ];
  }

  componentWillUnmount() {
    this._mounted = false;
    this._listeners?.forEach(l => l.remove());
  }

  _refresh = () => {
    this.forceUpdate();
  };

  // TODO: Refactor this component. Very inefficient
  render() {
    const { location, onPress, onLongPress } = this.props;

    // todo: needsAttention should be a flag on the status?
    const needsAttention = Object.keys(location.item_statuses).includes('humandelivering'); // some other criteria? location.guestWantsService

    const buttonStyle = needsAttention
      ? styles.needsAttention
      : location.seated_group
      ? styles.hasSeatedGroup
      : null;

    const smart_orders = location.smart_orders?.filter(so => so.incart?.length > 0);

    return (
      <View
        style={[styles.container]}
        testID="locationsLocationButtons"
      >
        <TouchableOpacity
          title={`button ${location.locationName}`}
          testID={`location-${location.id}`}
          accessible
          accessibilityLabel={location.locationName}
          onPress={() => {
            onPress(location);
          }}
          delayLongPress={4000}
          onLongPress={() => {
            onLongPress(location);
          }}
        >
          <View style={[styles.button, buttonStyle]}>
            <View style={styles.topRow}>
              <Text
                style={styles.locationName}
                adjustsFontSizeToFit
                minimumFontScale={0.5}
                numberOfLines={1}
                ellipsizeMode="middle"
              >
                {_.capitalize(location.locationName)}
              </Text>
              {!API.config.hide_seated_groups && (
                <>
                  <Icon
                    as={FontAwesome}
                    name="group"
                    style={{ color: 'white', fontSize: 16, marginRight: 5 }}
                  />
                  <Text style={{ color: '#fff' }}>{location.getNumGuests()}</Text>
                </>
              )}
            </View>
            <View style={styles.items}>
              {Object.entries(location.item_statuses).map(status =>
                this._getStatus(status[0], status[1]),
              )}
              {!this._getNumItems() && (
                <FormattedMessage
                  id="common__noOrders"
                  defaultMessage="No Orders"
                  style={{ color: Colors.gray }}
                />
              )}
            </View>
            <View style={styles.bottomRow}>
              {smart_orders.length ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Icon
                      as={MaterialIcons}
                      name="smartphone"
                      style={styles.icon}
                    />
                    <Text style={[styles.whiteText]}>{smart_orders.length}</Text>
                  </View>

                  <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                    <Icon
                      as={FontAwesome}
                      name="cart-arrow-down"
                      style={styles.icon}
                    />
                    <Text style={[styles.whiteText]}>{this._getNumSOItems()} </Text>
                  </View>
                </View>
              ) : null}
              <View style={{ flex: 1 }} />
              {location.open_tabs.length ? (
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                  <Icon
                    as={MaterialCommunityIcons}
                    name="cash-multiple"
                    style={styles.icon}
                  />
                  <Text style={[styles.whiteText]}>{this._getNumTabs()} </Text>
                </View>
              ) : null}
              {location.order_allowed === 'off' && (
                <Icon
                  as={FontAwesome5}
                  name="lock"
                  style={styles.icon}
                />
              )}
              {location.has_service_requests && (
                <Icon
                  as={MaterialCommunityIcons}
                  name="room-service"
                  style={[styles.icon, { color: Colors.primary }]}
                />
              )}
              {/* <FormattedCurrency style={{ color: '#fff', textAlign: 'right', marginRight: 5}}
                     value={location.getTotal() / 100}/> */}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  _getStatus(status, count) {
    const name = API.status_pretty_names[status] || status;
    const dontShowStatuses = []; // for future expansion
    if (dontShowStatuses.includes(status)) return null;

    return (
      <View
        key={status}
        style={styles.status}
      >
        <StatusIcon
          size="small"
          status={status}
          style={[styles.statusIcon]}
        />
        <Text style={styles.statusCount}>{count}</Text>
        <Text
          adjustsFontSizeToFit
          numberOfLines={1}
          style={styles.statusText}
        >
          {name}
        </Text>
      </View>
    );
  }

  _getNumItems() {
    const { location } = this.props;
    return _.sum(Object.values(location.item_statuses));
  }

  _getNumSOItems() {
    const { location } = this.props;
    return location.smart_orders
      .filter(so => so.incart?.length)
      .reduce(
        (total, curr) => (total += curr.incart.reduce((subtot, i) => (subtot += i.qty), 0)),
        0,
      );
  }

  _getNumTabs() {
    const { location } = this.props;
    return location.open_tabs.length;
  }
}

LocationButton.propTypes = {
  location: PropTypes.object,
  onPress: PropTypes.func.isRequired,
  onLongPress: PropTypes.func,
  // item: PropTypes.instanceOf(Location)
};
LocationButton.defaultProps = {
  onPress: () => {},
  onLongPress: () => {},
};

const styles = EStyleSheet.create({
  button: {
    backgroundColor: '#f5f5f5',
    ...(Platform.OS === 'web' ? { boxShadow: '1px 1px 1px 0 rgba(0,0,0,0.3)' } : { elevation: 4 }),
    height: 190,
    borderRadius: 8,
    overflow: 'hidden',
  },
  needsAttention: {
    borderColor: '#b9f3ff',
    backgroundColor: '#e5fff8',
    borderWidth: 2,
  },
  hasSeatedGroup: {
    backgroundColor: '#fff',
  },
  topRow: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#2c2c2c',
    alignItems: 'center',
  },
  locationName: {
    color: '#FFF',
    flex: 1,
  },
  bottomRow: {
    paddingHorizontal: 10,
    height: 35,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#7a7a7a',
  },
  items: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  status: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  statusIcon: {
    width: '10%',
    height: '90%',
    marginLeft: '5%',
    marginRight: '5%',
  },
  icon: {
    fontSize: 16,
    color: 'white',
    marginHorizontal: 3,
  },
  statusCount: {
    marginRight: '5%',
  },
  statusText: {
    flex: 1,
  },
  waiting: {
    // tintColor: '#ffa900', //Colors.waiting_fg,
    opacity: 1,
  },
  making: {
    // tintColor: '#ff0', //Colors.making_fg,
    opacity: 1,
  },
  humandelivering: {
    //  tintColor: Colors.humandelivering_fg,
    opacity: 1,
  },
  humandelivered: {
    // tintColor: Colors.humandelivered_fg,
    opacity: 1,
  },
  itemInvisible: {
    backgroundColor: 'transparent',
    flex: 1,
    margin: 5,
    aspectRatio: 1,
  },
  hasGuests: {
    tintColor: Colors.primary,
    opacity: 1,
  },
  white: {
    tintColor: '#fff',
    opacity: 1,
  },
  whiteText: {
    color: '#fff',
  },
});
