import React from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { MaterialIcons } from '@expo/vector-icons';
import Colors from '../constants/Colors';

// todo: is there somewhere global we can store this instead of defining it here and in ItemButton.js?
const height = 54; // '14.28%'; // should calculate this

function ItemCustomizerButton({
  disabled,
  modifier,
  operation,
  text,
  onPress,
  customText,
  customizeButtonStyle,
  customizeTextStyle,
}) {
  return (
    <TouchableWithoutFeedback
      disabled={disabled}
      accessible
      accessibilityLabel={text}
      onPress={onPress}
      testID="itemCustomizeButton"
    >
      <View style={[styles.rightButtonSplit, customizeButtonStyle]}>
        {/* I wanted to be explicit here but would a ternery be better? */}
        {operation === 'customize' && (
          <Icon
            as={MaterialIcons}
            style={[{ fontSize: 20 }, customizeTextStyle]}
            name="edit"
            color="#DDD"
          />
        )}
        {operation === 'quantity' && (
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={[{ fontSize: 16 }, customizeTextStyle]}
          >
            {modifier?.selected ? modifier?.qty : 0}
          </Text>
        )}
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[{ fontSize: 10 }, customizeTextStyle]}
        >
          {customText}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}
const styles = EStyleSheet.create({
  rightButtonSplit: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: '#EEE',
    borderColor: '#DDD',
    borderWidth: 2,
    paddingHorizontal: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
    flexGrow: 0.2,
    flexShrink: 1,
    flexBasis: 0,
    height,
  },
  selected: {
    borderWidth: 2,
    borderColor: Colors.highlight,
    backgroundColor: Colors.buttonSelected,
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 3,
  },
  errorButton: {
    borderColor: Colors.error,
  },
  errorText: {
    color: Colors.error,
  },
  disabled: {
    backgroundColor: Colors.gray,
  },
  disabledText: {
    color: Colors.darkGray,
  },
});

export default ItemCustomizerButton;
