import _ from 'lodash';
import React, { Component } from 'react';
import { ActivityIndicator, FlatList, StyleSheet, Text, View } from 'react-native';
import API from '../../api';
import { Colors, Typography } from '../../styles';
import ConsumerTab from './ConsumerTab';

class ConsumerTabList extends Component {
  static defaultProps = {
    search: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTabs: [],
      tabs: this._getTabsList(), // Sort by start date
      searchTabs: this._getTabsList(),
      loading: false, // incoming new tabs indicator
      refreshing: false, // refreshing existing tabs
      updated: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this._tabsListener = API.on('tabs', updatedTabs => {
      if (!this._mounted) return;
      const newTabs = this._getTabsList();
      if (
        updatedTabs[0]?._local ||
        !this.state.tabs.length ||
        newTabs.length === this.state.tabs.length
      ) {
        this.setState({
          tabs: newTabs,
          updated: !this.state.updated,
        });
      } else {
        this.setState({ loading: true });
        setTimeout(
          () =>
            this.setState({
              loading: false,
              tabs: newTabs,
              updated: !this.state.updated,
            }),
          2000,
        );
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
    this._tabsListener?.remove();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { search } = this.props;
    search = search.toLowerCase();
    const { updated, tabs } = this.state;
    if (prevProps.search !== this.props.search || updated !== prevState.updated) {
      let searchTabs = tabs;
      if (this.props.search) {
        searchTabs = Object.values(API._tabs).filter(t => t.end_date.isAfter());
      }
      this.setState({
        searchTabs: searchTabs.filter(
          tab =>
            (!!tab.tab_name && tab.tab_name?.toLowerCase()?.includes(search)) ||
            tab.card_last_4?.toLowerCase()?.includes(search),
        ),
      });
    }
  }

  sortTabs = tabList => tabList.sort((a, b) => b.start_date.diff(a.start_date));

  render() {
    const { loading, refreshing, searchTabs } = this.state;
    return (
      <>
        {loading && (
          <View style={[styles.incomingTabAlert]}>
            <ActivityIndicator
              size="small"
              color={Colors.primary}
            />
          </View>
        )}
        <FlatList
          data={searchTabs}
          renderItem={this._renderTab}
          keyExtractor={item => item.id}
          ListEmptyComponent={this.renderEmptyMessage}
          refreshing={refreshing}
          onRefresh={this._pullToRefresh}
        />
      </>
    );
  }

  _getTabsList = () => {
    const { location } = this.props;
    if (!location)
      return _.orderBy(
        _.filter(Object.values(API._tabs), t => t.tab_name),
        ['end_date', 'start_date'],
        ['desc', 'desc'],
      );

    const openTabs = this.sortTabs(location.open_tabs);
    const closedTabs = this.sortTabs(location.closed_tabs);
    const futureTabs = this.sortTabs(location.future_tabs);
    return openTabs.concat(closedTabs, futureTabs);
  };

  _pullToRefresh = async () => {
    const { location } = this.props;
    location._last_poll = null;

    this.setState({ refreshing: true });
    await API.getLocationDetails(location);
    this.setState({
      tabs: this._getTabsList(),
      refreshing: false,
    });
  };

  reSortTabs = () => {
    const { tabs } = this.props;
    this.setState({
      tabs: this.sortTabs(tabs),
    });
  };

  renderEmptyMessage = () => (
    <View style={{ padding: 20, flex: 1 }}>
      <Text style={[Typography.header2, { textAlign: 'center', marginBottom: 20 }]}>Empty</Text>
      <Text style={{ textAlign: 'center' }}>No tabs found.</Text>
    </View>
  );

  _renderTab = ({ item }) => {
    const { location } = this.props;
    return (
      <ConsumerTab
        location={location}
        tab={item}
      />
    );
  };
}

export default ConsumerTabList;

const styles = StyleSheet.create({
  incomingTabAlert: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.gray1,
    borderStyle: 'dashed',
    backgroundColor: Colors.white,
    margin: 10,
    height: 40,
  },
});
