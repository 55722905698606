import React from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import { Buttons, Colors } from '../styles';

function FilterButton({ label, onPress, value, icon, iconType, active = true }) {
  const extraStyle = value === 'All' || !active ? { backgroundColor: Colors.darkGray } : null;
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[Buttons.pillPrimary, extraStyle]}>
        {icon ? (
          <Icon
            name={icon}
            as={iconType}
            color={Colors.white}
            size={14}
            style={{ marginRight: 5 }}
          />
        ) : null}
        <Text style={[Buttons.pillLabelPrimary, { marginRight: 10 }]}>{label}</Text>
        <Text style={{ color: Colors.light }}>{value}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default FilterButton;
