import API from '../api';
import Alert from '../components/Alert';
import PinPadModal from '../components/PinPad/PinPadModal';

let lastUser;
let timeout;

/**
 * Checks to see if the current User has access to perform an action.
 * @param key
 * @param remember
 * @returns {Promise<Promise<unknown> | Promise.Promise>}
 */
export const checkAccess = async (key, remember = false, requirePin = false) => {
  let accessRequired = API.config.permissions[key];

  if (!accessRequired) {
    throw `Unknown access control key '${key}'`;
  }

  /**
   * This function remembers the last pinned in user for 30 seconds, so if we're performing multiple actions
   * in quick succession we don't need to pin/prompt every time.
   * @param user
   */
  const updateTimeout = user => {
    if (remember) {
      lastUser = user;
      clearTimeout(timeout);
      API.trigger('access', user);
      timeout = setTimeout(clearAccess, 30 * 1000);
    }
  };

  // Sets minimum Permission level to Staff to force PINing in
  if (requirePin) {
    clearAccess();
    if (['Allow', 'Prompt'].includes(accessRequired)) {
      accessRequired = 'Staff';
    }
  }

  if (!['Allow', 'Prompt'].includes(accessRequired)) {
    if (lastUser && lastUser.checkLevel(accessRequired)) {
      // We recently pinned in, so allow the action
      accessRequired = 'Allow';
    } else if (!requirePin && API.currUser.checkLevel(accessRequired)) {
      // The pinned in user is has access to perform this action, so just need to prompt:
      accessRequired = 'Prompt';
      updateTimeout(API.currUser);
    }
  } else if (accessRequired === 'Prompt' && lastUser) {
    // This action only requires "Prompt", but we recently agreed, so just allow (handy for repetitive actions):
    accessRequired = 'Allow';
  }

  return new Promise(resolve => {
    switch (accessRequired) {
      case 'Allow':
        updateTimeout(lastUser || API.currUser);
        resolve(lastUser || API.currUser);
        return;
      case 'Prompt':
        Alert.alert('Are you sure?', '', [
          {
            text: 'Cancel',
            style: 'cancel',
            onPress: () => {
              clearAccess();
              resolve(false);
            },
          },
          {
            text: 'Okay',
            onPress: () => {
              updateTimeout(lastUser || API.currUser);
              resolve(lastUser || API.currUser);
            },
          },
        ]);
        break;
      default:
        // if we have a user, use that for the comparison
        if (lastUser && lastUser.checkLevel(accessRequired)) {
          updateTimeout(lastUser);
          resolve(lastUser);
        } else {
          // otherwise:

          PinPadModal.show(
            user => {
              const allowed = user.checkLevel(accessRequired);
              if (allowed) {
                resolve(user);
                // save this user, use for future comparisons
                updateTimeout(user);
              } else {
                resolve(false);
                Alert.alert('Access Denied', 'You do not have access to perform this action');
              }
            },
            () => {
              resolve(false);
            },
          );
        }
    }
  });
};

/** make sure to call this when locking the screen */
export const clearAccess = () => {
  lastUser = null;
  clearTimeout(timeout);
  API.trigger('access', null);
};
