import React, { useEffect, useRef, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { Row } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import { TipSelectionStyle, TipSectionStyles as styles } from '../../../styles/POSStyles';
import API from '../../../api';
import CustomTipSection from './CustomTipSection';

function TipSection({ cartSubtotalCents = 0, onTipSelect }) {
  const [selectedTipPercent, setSelectedTipPercent] = useState(null);
  const [showCustomTipSelection, setShowCustomTipSelection] = useState(false);
  const [customTip, setCustomTip] = useState(null);
  const [showTipSlider, setShowTipSlider] = useState(true);
  const customTipInputRef = useRef(null);

  const {
    TipsSectionContainer,
    TipsCalculationText,
    TipPercentText,
    TipsTextRow,
    TipsText,
    TipsAmountText,
  } = styles;

  const tipPercentages = API.main_customer.app_properties.tipping;
  const tipPercentageChoices = tipPercentages.choices;

  useEffect(() => {
    if (selectedTipPercent === null) {
      setSelectedTipPercent(tipPercentages.default_tip);
    }
  }, [tipPercentages]);

  useEffect(() => {
    onTipSelect(selectedTipPercent * cartSubtotalCents);
  }, [selectedTipPercent]);

  // Hack to get selectTextOnFocus working. NOTE: selectTextOnFocus DOES NOT WORK with autoFocus
  useEffect(() => {
    if (!showTipSlider && customTipInputRef?.current) {
      customTipInputRef.current.focus();
    }
  }, [customTipInputRef, showTipSlider]);

  const getTipSelectionStyle = (tipPercent, isFirst = false, isLast = false, isCustom = false) =>
    TipSelectionStyle(isFirst, isLast, isCustom ? customTip : tipPercent === selectedTipPercent)
      .TipSelection;

  return (
    <View style={TipsSectionContainer}>
      <View style={TipsTextRow}>
        <Text style={TipsText}>Tips</Text>
        <Text style={TipsAmountText}>
          <FormattedCurrency
            style={TipsAmountText}
            value={cartSubtotalCents * 0.01}
          />
          {' + '}
          <FormattedCurrency
            style={TipsAmountText}
            value={selectedTipPercent * cartSubtotalCents * 0.01}
          />{' '}
          Tips
        </Text>
      </View>

      <Row
        space={1}
        marginBottom={10}
      >
        <Pressable
          style={getTipSelectionStyle(0, true, false, false)}
          onPress={() => {
            setSelectedTipPercent(0);
            setCustomTip(null);
            setShowCustomTipSelection(false);
          }}
        >
          <Text style={TipPercentText}>No Tips</Text>
        </Pressable>

        {tipPercentageChoices.map(tipPercentage => (
          <Pressable
            style={getTipSelectionStyle(tipPercentage, false, false, false)}
            onPress={() => {
              setSelectedTipPercent(tipPercentage);
              setCustomTip(null);
              setShowCustomTipSelection(false);
            }}
            key={`tip-${tipPercentage}`}
          >
            <Text style={TipPercentText}>{`${tipPercentage * 100}%`}</Text>
            <Text style={TipsCalculationText}>
              (
              <FormattedCurrency
                style={TipsCalculationText}
                value={cartSubtotalCents * tipPercentage * 0.01}
              />
              )
            </Text>
          </Pressable>
        ))}

        <Pressable
          style={getTipSelectionStyle(-1, false, true, true)}
          onPress={() => setShowCustomTipSelection(true)}
        >
          <Text style={TipPercentText}>Custom</Text>
        </Pressable>
      </Row>

      {showCustomTipSelection && (
        <CustomTipSection
          setShowTipSlider={setShowTipSlider}
          showTipSlider={showTipSlider}
          selectedTipPercent={selectedTipPercent}
          cartSubtotalCents={cartSubtotalCents}
          setCustomTip={setCustomTip}
          setSelectedTipPercent={setSelectedTipPercent}
          customTipInputRef={customTipInputRef}
          customTip={customTip}
        />
      )}
    </View>
  );
}

export default TipSection;
