import React, { useState } from 'react';
import { View } from 'react-native';
import { useImmerReducer } from 'use-immer';
import { POSCheckoutScreenContext } from '../../../contexts/POSContext';
import SendReceipt from './SendReceipt';
import EnterPayment from './EnterPayment';
import PaymentCompleted from './PaymentCompleted';

export const CHECKOUT_SCREEN = {
  ENTER_PAYMENT: 'ENTER_PAYMENT',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  SEND_EMAIL_RECEIPT: 'SEND_EMAIL_RECEIPT',
  SEND_TEXT_RECEIPT: 'SEND_TEXT_RECEIPT',
};

export const PAYMENT_TYPE = {
  CREDIT_CARD: 'Credit Card',
  // MANUAL_CREDIT_CARD_ENTRY: 'Manual Credit Card Entry',
  // The below are not implemented yet in the backend. We'll support them here in the Terminal when they are.
  // CASH: 'Cash',
  // SPLIT_PAYMENT: 'Split Payment',
};

export const CHECKOUT_REDUCER_ACTIONS = {
  SET_CHECKOUT_SCREEN: 'SET_CHECKOUT_SCREEN',
  SET_ORDER_IDS: 'SET_ORDER_IDS',
  SET_RECEIPT_EMAIL: 'SET_RECEIPT_EMAIL',
  SET_RECEIPT_PHONE: 'SET_RECEIPT_PHONE',
};

const checkoutReducer = (draft, action) => {
  const { SET_CHECKOUT_SCREEN, SET_ORDER_IDS, SET_RECEIPT_EMAIL, SET_RECEIPT_PHONE } =
    CHECKOUT_REDUCER_ACTIONS;
  switch (action.type) {
    case SET_CHECKOUT_SCREEN:
      draft.checkoutScreen = action.newCheckoutScreen;
      break;
    case SET_ORDER_IDS:
      draft.orderIds = action.orderIds;
      break;
    case SET_RECEIPT_EMAIL:
      draft.emailReceiptSentTo = action.email;
      break;
    case SET_RECEIPT_PHONE:
      draft.phoneReceiptSentTo = action.phone;
      break;
  }
};

const CHECKOUT_INITIAL_STATE = {
  checkoutScreen: CHECKOUT_SCREEN.ENTER_PAYMENT,
  emailReceiptSentTo: null,
  phoneReceiptSentTo: null,
};

/**
 * There are 3 screens for cehckout: choose payment type, payment completed, send email receipt, send text receipt.
 * @returns Checkout screen component.
 */
function Checkout() {
  const [checkoutState, checkoutDispatch] = useImmerReducer(
    checkoutReducer,
    CHECKOUT_INITIAL_STATE,
  );

  const getCheckoutScreen = () => {
    const { checkoutScreen } = checkoutState;
    switch (checkoutScreen) {
      case CHECKOUT_SCREEN.ENTER_PAYMENT:
        return <EnterPayment />;
      case CHECKOUT_SCREEN.PAYMENT_COMPLETED:
        return <PaymentCompleted />;
      case CHECKOUT_SCREEN.SEND_EMAIL_RECEIPT:
        return <SendReceipt receiptType="email" />;
      case CHECKOUT_SCREEN.SEND_TEXT_RECEIPT:
        return <SendReceipt receiptType="text" />;
    }
  };

  return (
    <POSCheckoutScreenContext.Provider value={{ checkoutState, checkoutDispatch }}>
      <View style={{ height: '100%' }}>{getCheckoutScreen()}</View>
    </POSCheckoutScreenContext.Provider>
  );
}

export default Checkout;
