import React, { useContext } from 'react';
import { View, Pressable } from 'react-native';
import { Icon, Text } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import { Ionicons } from '@expo/vector-icons';
import API from '../../api';

import POSContext from '../../contexts/POSContext';
import { ITEM_SELECTOR_COL_VIEWS, POS_REDUCER_ACTIONS } from '../../screens/POS';

import { CartItemStyles as styles } from '../../styles/POSStyles';

function CartItem({
  cartItem,
  cartItem: { id: cartItemId, name, qty, pretax_cents, mods, menuItemId },
}) {
  const {
    POSValues: { selectedCartItem, selectedMenuItem },
    POSDispatch,
  } = useContext(POSContext);

  const {
    CartItem,
    CartItemTop,
    LeftContainer,
    NameText,
    RightContainer,
    QuantityContainer,
    QtyText,
    QuantityButton,
    PriceText,
    CartItemBody,
  } = styles(cartItemId === selectedCartItem?.id);

  const changeQuantity = amountToAdd => {
    POSDispatch({
      cartItemId,
      menuItemId,
      type: POS_REDUCER_ACTIONS.SET_QTY,
      qty: qty + amountToAdd,
    });

    // If currently editing item and we're removing that item, send back to pos screen
    if (amountToAdd < 0 && menuItemId === selectedMenuItem?.menuItemId && qty === 1) {
      POSDispatch({
        type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
        POSScreen: ITEM_SELECTOR_COL_VIEWS.SELECT_ITEM,
      });
    }
  };

  const onCartItemPress = () => {
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
      itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.EDIT_ITEM,
    });
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_SELECTED_CART_ITEM,
      cartItem,
    });
  };

  const modifiers = Object.values(mods).flat();

  const menuItemModifierGroups = API.menuData.menuItemsById[menuItemId]?.modifierGroups;

  const renderModifiers = (modifiers, recursionLevel) => {
    if (!modifiers.length) return;

    return modifiers.map(({ id, pretax_cents, name_for_bartender, mods = {} }) => (
      <View key={id}>
        <Text
          style={NameText}
          marginLeft={4 * recursionLevel}
        >
          {name_for_bartender}
          {!!pretax_cents && (
            <FormattedCurrency
              value={pretax_cents * 0.01}
              style={PriceText}
            />
          )}
        </Text>
        {Object.values(mods).map(modifiers => renderModifiers(modifiers, recursionLevel + 1))}
      </View>
    ));
  };

  return (
    <Pressable
      style={CartItem}
      onPress={onCartItemPress}
      disabled={menuItemModifierGroups.length === 0}
    >
      <View style={CartItemTop}>
        <View style={LeftContainer}>
          <Text style={NameText}>{name}</Text>
        </View>
        <View style={RightContainer}>
          <View style={QuantityContainer}>
            <Pressable
              style={QuantityButton}
              onPress={() => changeQuantity(-1)}
            >
              <Icon
                size={5}
                as={Ionicons}
                name="remove-outline"
                color="white"
              />
            </Pressable>
            <Text style={QtyText}>{qty}</Text>
            <Pressable
              style={QuantityButton}
              onPress={() => changeQuantity(1)}
            >
              <Icon
                size={5}
                as={Ionicons}
                name="add-outline"
                color="white"
              />
            </Pressable>
          </View>
          <FormattedCurrency
            value={pretax_cents * 0.01}
            style={PriceText}
          />
        </View>
      </View>

      {modifiers.length > 0 && <View style={CartItemBody}>{renderModifiers(modifiers, 0)}</View>}
    </Pressable>
  );
}

export default CartItem;
