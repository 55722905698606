import React, { Component } from 'react';
import { View, Image, BackHandler, Pressable } from 'react-native';
import { Text } from 'native-base';
import { FormattedMessage } from 'react-native-globalize';
import { styles as gStyles } from '../styles/Global';
import Colors from '../constants/Colors';
import { TextM, TextS } from '../components/Text';
import API from '../api';

export default class ThankYouScreen extends Component {
  static navigationOptions = {
    title: 'Thank You',
    headerLeft: () => null,
  };

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this._onTap);
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  render() {
    const imgSource = API.config.post_checkout_image
      ? { uri: API.config.post_checkout_image }
      : require('../assets/images/thank-you.png');

    const card = navigation.getParam('card');

    return (
      <Pressable
        style={{ flex: 1 }}
        accessible
        accessibilityLabel={
          <FormattedMessage
            id="common__thankYou"
            defaultMessage="Thank You"
          />
        }
        onPress={this._onTap}
      >
        <View style={{ flex: 1 }}>
          <View style={[gStyles.centered, { flex: 1 }]}>
            {card?.type === 'tab' && (
              <Text style={{ fontSize: 20 }}>
                JOIN
                {card.tab_name?.toUpperCase()}
              </Text>
            )}
            <Image
              style={{ width: '80%', height: 300 }}
              source={imgSource}
              resizeMode="contain"
            />
            <TextM
              id="backToServer__short"
              defaultMessage="Please pass the device back to your server"
              style={{ marginTop: 50 }}
            />
          </View>
          <View style={[gStyles.centered, { margin: 10 }]}>
            <TextS
              id="success__touchToContinue"
              defaultMessage="(Touch anywhere to continue)"
              style={{ color: Colors.gray }}
            />
          </View>
        </View>
      </Pressable>
    );
  }

  _onTap = () => {
    const { navigation } = this.props;
    const cart = navigation.getParam('cart');
    const location = navigation.getParam('location');
    if (cart._submitted) {
      navigation.navigate('LocationOverview');
    } else {
      navigation.navigate('SplitOrderScreen');
    }
    return true;
  };
}
