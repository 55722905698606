import { Text, View } from 'react-native';
import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormattedMessage } from 'react-native-globalize';

export default class OrderListViewHeader extends React.PureComponent {
  render() {
    return (
      <View style={styles.menuItemsHeader}>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <FormattedMessage
            id="common__uppercase__name"
            style={[styles.headerText, { flex: 1 }]}
          />
          <FormattedMessage
            id="common__uppercase__short__quantity"
            style={[styles.headerText, { textAlign: 'right' }]}
          />
          <FormattedMessage
            id="common__uppercase__each"
            style={[styles.headerText, { width: '16%', textAlign: 'right' }]}
          />
          <FormattedMessage
            id="common__uppercase__total"
            style={[styles.headerText, { width: '18%', textAlign: 'right' }]}
          />
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  menuItemsHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderBottomColor: '#bebebe',
    borderBottomWidth: 2,
    paddingHorizontal: 21,
    paddingVertical: 5,
  },
  headerText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
});
