import * as Colors from './Colors';

export const small = {
  paddingHorizontal: 10,
  paddingVertical: 12,
  width: 75,
};

export const medium = {
  paddingHorizontal: 10,
  paddingVertical: 12,
  width: 150,
};

export const large = {
  paddingHorizontal: 10,
  paddingVertical: 12,
  width: 200,
};

export const secondary = {
  borderWidth: 2,
  borderColor: Colors.gray1,
  backgroundColor: 'rgba(0, 0, 0, 0)',
};

export const rounded = {
  borderRadius: 50,
};

export const smallRounded = {
  ...small,
  ...rounded,
};

export const mediumRounded = {
  ...medium,
  ...rounded,
};

export const largeRounded = {
  ...large,
  ...rounded,
};

export const link = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 0,
  backgroundColor: 'rgba(52, 52, 52, 0)',
};

export const pill = {
  alignItems: 'center',
  borderRadius: 5,
  flexDirection: 'row',
  height: 31,
  justifyContent: 'center',
  marginHorizontal: 8,
  padding: 5,
};

export const pillPrimary = {
  ...pill,
  backgroundColor: Colors.primary,
};

export const pillSecondary = {
  ...pill,
  backgroundColor: 'white',
  borderColor: Colors.primary,
  borderWidth: 2,
};

export const pillLabelPrimary = {
  color: Colors.light,
  fontWeight: 'bold',
};

export const pillLabelSecondary = {
  color: Colors.primary,
  fontWeight: 'bold',
  marginHorizontal: 20,
};
