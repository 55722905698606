import React from 'react';
import { View } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';

function Body({ children, ...props }) {
  const justifyLeft = props.hasOwnProperty('justifyLeft');
  return (
    <View
      style={[styles.body, { alignItems: 'flex-start' }, justifyLeft && styles.justify]}
      {...props}
    >
      {children}
    </View>
  );
}

const styles = EStyleSheet.create({
  body: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
  },
  justifyLeft: {
    flex: -1,
    alignItems: 'flex-start',
  },
});

export default Body;
