import { createStackNavigator } from 'react-navigation-stack';
import { Platform } from 'react-native';
import React from 'react';
import { FontAwesome } from '@expo/vector-icons';
import TipsReportScreen from '../screens/TipsReportScreen';
import TabBarIcon from '../components/TabBarIcon';
import { defaultNavigationOptions } from './Defaults';
import TabBarLabel from '../components/TabBarLabel';

export const TipReportsStack = createStackNavigator(
  { StationTipsScreen: TipsReportScreen },
  {
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__tipsReport"
        />
      ),
      tabBarTestID: 'tips',
      tabBarIcon: props => (
        <TabBarIcon
          {...props}
          type={FontAwesome}
          name="money"
        />
      ),
    }),
    defaultNavigationOptions,
  },
);
