import { Text } from 'native-base';
import { View, StyleSheet } from 'react-native';
import React from 'react';
import { Body, Left, ListItem, Right } from '../../bbot-component-library';

export default function MenuListHeader() {
  return (
    <ListItem
      itemDivider
      style={[styles.header, { borderBottomWidth: 1 }]}
    >
      <Left style={styles.headerLeft} />
      <Body style={styles.headerBody}>
        <Text>Menu Item</Text>
      </Body>
      <Right style={styles.headerRight}>
        <View style={{ flex: 1, alignItems: 'flex-start' }}>
          <Text>Inv. Qty</Text>
        </View>
        <View style={{ alignItems: 'center', width: 65, justifyContent: 'center' }}>
          <Text>In Stock</Text>
        </View>
      </Right>
    </ListItem>
  );
}

const styles = StyleSheet.create({
  header: {
    height: 40, // this is hacky, need a way to calculate proper height
    backgroundColor: '#F4F4F4',
    alignItems: 'center',
  },
  headerLeft: {
    flex: 0,
    width: 35,
  },
  headerBody: {
    flex: 4,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 65,
  },
  headerRight: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 130,
  },
});
