import React, { useState, useContext } from 'react';
import { View } from 'native-base';
import POSContext from '../../contexts/POSContext';
import { onMenuItemPress } from './utils';
import { SelectItemViewStyles as styles } from '../../styles/POSStyles';
import { SelectItemBody, SelectItemHeader } from './SelectItemHeaderAndBody';
import SearchResultsView from './SearchResultsView';

function SelectItemView(props) {
  // Hooks
  const [searchText, setSearchText] = useState(null);

  const {
    POSDispatch,
    POSValues: {
      cart = null,
      selectedMenuHeading: { id, items, heading_name, menu: { menuId } = {} } = {},
    },
  } = useContext(POSContext);

  return (
    <View style={styles.ViewContainer}>
      <SelectItemHeader
        setSearchText={setSearchText}
        headingName={heading_name}
        selectedMenuHeadingId={id}
      />
      {searchText?.length >= 2 ? (
        <SearchResultsView searchText={searchText} />
      ) : (
        <SelectItemBody
          items={items}
          cart={cart}
          id={id}
          onMenuItemPress={onMenuItemPress}
          POSDispatch={POSDispatch}
        />
      )}
    </View>
  );
}

export default SelectItemView;
