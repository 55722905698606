import React from 'react';
import { View, Platform } from 'react-native';
import { Button, Text } from 'native-base';
import RNRestart from 'react-native-restart';

function ErrorScreen({ error, componentStack, resetError }) {
  const msg =
    Platform.OS === 'android'
      ? 'An error has occurred and our support team has been notified.'
      : "Woops! Something went wrong. We'll get it fixed asap, in the meantime please click below to restart the app";
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ marginBottom: 15, marginHorizontal: 15 }}>
        <Text>{msg}</Text>
      </View>
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Button
          colorScheme="danger"
          onPress={() => {
            if (Platform.OS === 'web') window.location.reload();
            else RNRestart.Restart();
          }}
        >
          <Text style={{ color: 'white' }}>Reload App</Text>
        </Button>
      </View>
    </View>
  );
}

export default ErrorScreen;
