import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Button, Icon, Row } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { Pressable, ScrollView } from 'react-native';
import POSContext from '../../contexts/POSContext';
import ModifierGroup from './ModifierGroup';
import SpecialInstructionsInput from './SpecialInstructionsInput';
import { SelectModifierViewStyles as styles } from '../../styles/POSStyles';
import {
  addMenuItem,
  getDefaultModIds,
  returnToSelectItemsScreen,
  validateModifierGroups,
} from './utils';

function SelectModifierView() {
  // Hooks
  const posContext = useContext(POSContext);
  const [selectedModifiersByGroup, setSelectedModifiersByGroup] = useState({}); // maps modifier group id to array of selected modifier
  const [specialInstructions, setSpecialInstructions] = useState('');

  const setSelectedModifiers = (modifierGroupId, modifiers) => {
    setSelectedModifiersByGroup(selectedModsByGroup => ({
      ...selectedModsByGroup,
      [modifierGroupId]: modifiers,
    }));
  };

  const {
    POSDispatch,
    POSValues: {
      selectedMenuItem,
      selectedMenuItem: { menuId, modifier_groups: modifierGroups = [] } = {},
    },
  } = posContext;

  const { Container, Header, HeaderRight, ItemTitle, CancelButton, SaveButton } = styles;

  // Set up initial modifiers
  useEffect(() => {
    modifierGroups.map(modifierGroup => {
      const { id, modifiers } = modifierGroup;
      const defaultModifiers = modifiers.filter(modifier => modifier.is_default_choice);
      setSelectedModifiers(id, defaultModifiers);
    });
  }, [modifierGroups]);

  const returnToSelectItemsView = () => returnToSelectItemsScreen(POSDispatch);

  const saveItem = () => {
    if (validateModifierGroups(modifierGroups, selectedModifiersByGroup)) {
      const selectedModifiers = Object.values(selectedModifiersByGroup).flat();
      addMenuItem(selectedMenuItem, POSDispatch, menuId, selectedModifiers, specialInstructions);
      returnToSelectItemsView();
    }
  };

  return (
    <View style={Container}>
      <View style={Header}>
        <Pressable onPress={returnToSelectItemsView}>
          <Row alignItems="center">
            <Icon
              size={6}
              marginRight={1}
              as={Ionicons}
              name="arrow-back-outline"
              color="white"
            />
            <Text style={ItemTitle}>{selectedMenuItem?.name_for_bartender}</Text>
          </Row>
        </Pressable>

        <Row style={HeaderRight}>
          <Button
            onPress={returnToSelectItemsView}
            style={CancelButton}
          >
            Cancel
          </Button>

          <Button
            onPress={saveItem}
            style={SaveButton}
          >
            Done
          </Button>
        </Row>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        {modifierGroups.map(modifierGroup => (
          <ModifierGroup
            modifierGroup={modifierGroup}
            initialModIds={getDefaultModIds(modifierGroup)}
            key={modifierGroup.id}
            onSelectedModifiersChange={setSelectedModifiers}
          />
        ))}

        <SpecialInstructionsInput onSpecialInstructionsChange={setSpecialInstructions} />
      </ScrollView>
    </View>
  );
}

export default SelectModifierView;
