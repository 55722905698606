import React, { useEffect, useState } from 'react';
import { Stack, Radio, Text, View, Box } from 'native-base';

import { Colors } from '../../../styles';
import Required from './Required';
import { styles as fieldStyles } from './styles';

function RadioField({
  field,
  fieldValue,
  valid,
  setValid,
  setFieldValue,
  highlightError,
  ...rest
}) {
  const [touched, setTouched] = useState(false);
  const validator = val => !!val;

  useEffect(() => {
    if (highlightError) {
      setTouched(true);
      setValid(validator(fieldValue));
    }
  }, [highlightError]);

  useEffect(() => {
    if (valid) setTouched(true);
  }, [valid]);

  const onPress = value => {
    setFieldValue(value || '');
    setValid(true);
    setTouched(true);
  };

  return (
    <Stack
      stackedLabel
      {...rest}
    >
      <Text style={[fieldStyles.fieldLabel, !valid && touched && fieldStyles.invalidFieldLabel]}>
        {field.name_for_patron}
        <Required show={field.required_on_handheld} />
      </Text>
      <Radio.Group
        name="radioField"
        onChange={onPress}
        defaultValue={fieldValue}
        value={fieldValue}
      >
        <View style={[{ width: '100%' }, highlightError && !valid && fieldStyles.errorField]}>
          {field.choices.map(choice => (
            <Box
              style={{ margin: 10 }}
              key={`choice_${choice}`}
            >
              <Radio
                value={choice.label}
                color={Colors.primary}
                selectedColor={Colors.primary}
              >
                {choice.label}
              </Radio>
            </Box>
          ))}
        </View>
      </Radio.Group>
    </Stack>
  );
}

export default RadioField;
