import _ from 'lodash';
import Model from './Model';
import Menu from './Menu';
import MenuItem from './MenuItem';
import ModifierGroup from './ModifierGroup';

export default class MenuData extends Model {
  modifiersById = {};

  modifierGroupsById = {};

  menus = [];

  menusById = {};

  menuItemsById = {};

  menuItemsByInventoryId = {};

  customer = null;

  fulfillment_pretty_names = {};

  status_sequences = {};

  status_pretty_names = {};

  closed_statuses_for_station = [];

  closed_statuses_for_runner = [];

  headingsById = {};

  bot_protected_statuses = ['pleasesend', 'botsent', 'botattable'];

  _field_map = {
    customer: customer => new MenuCustomer(customer),
    modifierGroupsById: groups =>
      _.mapValues(groups, (mg, id) => new ModifierGroup(this, Object.assign(mg, { id }))),
    menuItemsById: menuItems =>
      _.mapValues(
        menuItems,
        (item, itemId) => new MenuItem(this, Object.assign(item, { menuItemId: itemId })),
      ),
    menus: menus => menus.map(menu => new Menu(this, menu)),
  };

  constructor(data) {
    super();
    this.update(data);
  }

  get allow_order_ahead() {
    // todo: should cache this, then clear it when we get a server update on locations or menus;
    return (
      _.some(this.menus, menu => menu.allow_order_ahead) ||
      _.some(this.api._locations, loc => loc.allow_order_ahead === 'on')
    );
  }

  get closed_statuses() {
    return this.closed_statuses_for_station.concat(this.api.config.additional_closed_statuses);
  }

  update(data) {
    super.update(data);

    this.buildItemMap();
  }

  buildItemMap() {
    this.menus.forEach((menu, i) => {
      menu.headings.forEach((heading, j) => {
        if (!this.headingsById[heading.id]) this.headingsById[heading.id] = [];
        this.headingsById[heading.id].push(heading);
      });
    });
  }

  itemsAndModifiersByName() {
    const items = Object.values(this.menuItemsById);
    const modifiers = _.reduce(
      this.modifierGroupsById,
      (acc, obj) => acc.concat(Object.values(obj.modifiers)),
      [],
    );
    return _.sortBy(items.concat(modifiers), o => o.name_for_customer.toLowerCase());
  }

  addMenu = menu => {
    this.menus.push(new Menu(this, menu));
    this.buildItemMap();
  };

  createOrUpdateMenuItemsById(menuItemsById) {
    Object.entries(menuItemsById).forEach(([itemId, itemData]) => {
      if (this.menuItemsById[itemId]) {
        this.menuItemsById[itemId].update(itemData);
      } else {
        this.menuItemsById[itemId] = new MenuItem(this, { menuItemId: itemId, ...itemData });
      }
    });
  }

  createOrUpdateModifierGroupsById(modifierGroupsById) {
    Object.entries(modifierGroupsById).forEach(([groupId, groupData]) => {
      if (this.modifierGroupsById[groupId]) {
        this.modifierGroupsById[groupId].update(groupData);
      } else {
        this.modifierGroupsById[groupId] = new ModifierGroup(this, { id: groupId, ...groupData });
      }
    });
  }

  updateMenuData(newData) {
    const { menus, menuItemsById, modifierGroupsById } = newData;

    this.createOrUpdateMenuItemsById(menuItemsById);
    this.createOrUpdateModifierGroupsById(modifierGroupsById);

    menus?.forEach(menu => {
      if (!menu.enabled) {
        this.api.cache.removeData('menu', 'menuId', menu.menuId);
      }
      if (this.menusById[menu.menuId]) {
        this.menusById[menu.menuId].update(menu);
      } else {
        this.menusById[menu.menuId] = new Menu(this, menu);
      }
    });

    this.menus = Object.values(this.menusById);

    this.buildItemMap();
  }

  toJSON() {
    return {
      customer: this.customer,
      status_sequences: this.status_sequences,
      status_pretty_names: this.status_pretty_names,
      closed_statuses_for_station: this.closed_statuses_for_station,
      closed_statuses_for_runner: this.closed_statuses_for_runner,
      fulfillment_pretty_names: this.fulfillment_pretty_names,
      modifierGroupsById: this.modifierGroupsById,
      menuItemsById: this.menuItemsById,
    };
  }
}

/**
 * Menu Customer vs
 */
class MenuCustomer extends Model {
  customer_id = null;

  app_properties = {};

  customer_name = '';

  diet_icons = [];

  emoji_icons = [];

  fee_configs = [];

  order_number_prefix = '';

  promotions = [];

  banner_url = '';

  logo_url = '';

  desktop_banner_url = '';

  timezone = null;

  has_printers = false;

  constructor(data) {
    super();

    this.update(data);
  }
}
