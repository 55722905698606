import React, { useEffect, useState } from 'react';
import { Text, View } from 'native-base';
import _ from 'lodash';
import { Dimensions, Platform, ScrollView } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../api';
import OrderHelper from '../helpers/OrderHelper';
import KDSItemModifiers from '../components/KDS/Ticket/KDSItemModifiers';

function KDSAllDayView() {
  const COL_WIDTH = 200; // TODO: Make dynamic?

  const [viewWidth, setViewWidth] = useState(Dimensions.get('window').width);
  const [colWidth, setColWidth] = useState(COL_WIDTH);
  const [sorted_items, setSortedItems] = useState([]);
  const [groupingRule, updateGroupingRule] = useState(
    API.handheldDevice.getPreference('kds_all_day_grouping_rule'),
  );

  useEffect(() => {
    refreshData();
    refreshWindow();

    const listeners = [
      Dimensions.addEventListener('change', refreshWindow),
      API.on('orders', () => refreshData()),
      API.handheldDevice.on('update', () => {
        updateGroupingRule(API.handheldDevice.getPreference('kds_all_day_grouping_rule'));
      }),
    ];

    return () => {
      listeners.forEach(l => l?.remove());
    };
  }, []);

  const refreshWindow = () => {
    const windowWidth = Dimensions.get('window').width;
    const numCol = Math.floor(windowWidth / (COL_WIDTH + 15)) || 1;
    setViewWidth(numCol * (COL_WIDTH + 15));
    /*  let vWidth = windowWidth - 50;
      let colWidth = (vWidth / numCol)-20;
      setViewWidth(vWidth);
      setColWidth(colWidth);
      console.log(vWidth, colWidth); */
  };

  const refreshData = () => {
    const config = API.getConfig();

    const kds_station_orders = config.kds_stations.reduce((orders, station) => {
      if (station) return orders.concat(station.orders);
      return orders;
    }, []);

    const orderItems = _.flatten(kds_station_orders.map(order => order.kds_open_status));

    setSortedItems(sort_items(orderItems));
  };

  const sort_items = orderItems => {
    const key = groupingRule ? 'order_item_hash' : 'itemId';

    const items = {};

    orderItems.forEach(item => {
      if (_.has(items, item[key])) {
        items[item[key]].quantity += item.quantity;
      } else {
        items[item[key]] = {
          quantity: item.quantity,
          name: item.itemName,
          mods: groupingRule ? OrderHelper.sortModifiers(item.mods) : [],
          key: item[key],
        };
      }
    });

    const orderFields = groupingRule
      ? [
          ['name', 'quantity'],
          ['asc', 'desc'],
        ]
      : [
          ['quantity', 'name'],
          ['desc', 'asc'],
        ];
    return _.orderBy(items, ...orderFields);
  };

  useEffect(refreshData, [groupingRule]);

  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      {sorted_items.length > 0 ? (
        <ScrollView
          horizontal
          fadingEdgeLength={30}
          snapToInterval={viewWidth}
          snapToAlignment="start"
          decelerationRate={0}
          style={{ width: viewWidth + 40 }}
        >
          <View style={styles.scrollViewBody}>
            {sorted_items.map(item => (
              <AllDayViewItem
                key={item.key}
                menuItem={item}
                colWidth={colWidth}
              />
            ))}
          </View>
        </ScrollView>
      ) : (
        <View>
          <Text style={{ paddingTop: 30, fontSize: 30 }}>No Items in Open Orders</Text>
        </View>
      )}
    </View>
  );
}

function AllDayViewItem({ menuItem, colWidth }) {
  return (
    <View style={[styles.itemView]}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={styles.textQuantity}>{menuItem.quantity}</Text>
        <Text style={{ marginLeft: 3, fontSize: 12 }}>x</Text>
        <Text
          style={styles.textName}
          ellipsizeMode="tail"
          numberOfLines={3}
        >
          {menuItem.name}
        </Text>
      </View>
      {menuItem.mods.length > 0 ? (
        <View style={styles.modsView}>
          <KDSItemModifiers mods={menuItem.mods} />
        </View>
      ) : null}
    </View>
  );
}

export default KDSAllDayView;

const styles = EStyleSheet.create({
  scrollViewBody: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: 10,
    padding: 10,
  },
  itemView: {
    width: 200,
    borderWidth: 2,
    borderRadius: 8,
    margin: 7,
    padding: 4,
    justifyContent: 'center',
    minHeight: 25,
    ...(Platform.OS === 'web' ? { boxShadow: '2px 2px 4px rgba(0,0,0,0.2)' } : { elevation: 4 }),
  },
  textQuantity: {
    minWidth: 30,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  textName: {
    marginLeft: 10,
    flex: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  modsView: {
    marginLeft: 50,
    paddingRight: 5,
    paddingBottom: 5,
  },
});
