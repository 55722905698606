import _ from 'lodash';

import moment from 'moment';
import MenuHeading from './MenuHeading';
import Model from './Model';

export default class Menu extends Model {
  static get className() {
    return 'Menu';
  }

  get id() {
    return this.menuId;
  }

  menuId = null;

  menuName = '';

  allow_order_ahead = false;

  allowed_user_desired_times = [];

  combine_into_id = null;

  customer = '';

  desired_time_widget = null;

  display_position = 1000;

  enabled = false;

  headings = [];

  last_modified = null;

  min_order_ahead_minutes = null;

  max_order_ahead_minutes = null;

  name_for_customer = '';

  use_schedule = false;

  visible_per_schedule = true;

  order_allowed = 'auto';

  show_unfulfillable_items = true;

  show_out_of_stock_items = true;

  timepicker_block_interval = null; // 30 mins  (12, 12:30, 1)

  timepicker_block_width = null; // 60 mins (12-1, 12:30-1:30, 1-2)

  timepicker_title = '';

  _field_map = {
    last_modified: dt => moment(dt),
    headings: headings => {
      const initialIds = this.headings.map(h => h.id);
      const headingIdsToRemove = _.difference(
        initialIds,
        headings.map(h => h.id),
      );
      this.headings = this.headings.filter(h => !headingIdsToRemove.includes(h.id));
      return headings.map(heading => {
        const exists = this.headings.find(h => h.id === heading.id);
        if (exists) {
          exists.update(heading);
          return exists;
        }
        return new MenuHeading(this, heading);
      });
    },
  };

  constructor(menuData, data) {
    super();
    Object.defineProperty(this, 'menuData', { value: menuData });

    this.update(data, true);

    // Update menuData menusById
    this.menuData.menusById[this.menuId] = this;
  }

  get is_available() {
    return this.enabled && this.visible_per_schedule;
  }

  get name() {
    return this.name_for_customer;
  }

  get items() {
    return this.headings.reduce((acc, heading) => acc.concat(heading.items), []);
  }

  _cache = true; // indicates we should cache this model
}
