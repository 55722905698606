import { createStackNavigator } from 'react-navigation-stack';
import React from 'react';
import { Entypo } from '@expo/vector-icons';
import OrderControlScreen from '../screens/OrderControlScreen';
import TabBarIcon from '../components/TabBarIcon';
import { defaultNavigationOptions } from './Defaults';
import TabBarLabel from '../components/TabBarLabel';

export const OrderControlStack = createStackNavigator(
  {
    OrderControlScreen,
  },
  {
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__orderControl"
        />
      ),
      tabBarTestID: 'ordercontrol',
      tabBarIcon: props => (
        <TabBarIcon
          {...props}
          name="switch"
          type={Entypo}
        />
      ),
    }),
    defaultNavigationOptions,
  },
);
