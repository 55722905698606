import React from 'react';
import { Icon, Input, Stack, View } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import ConsumerTabList from '../components/ConsumerTab/ConsumerTabList';
import { Header } from '../bbot-component-library';
import API from '../api';

/**
 * TODO:
 * Fix issue where starting an Order from this view, the Location might not have the details loaded
 */

export default class TabsScreen extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const refresh = navigation.getParam('refresh');
    return {
      title: 'All Tabs',
    };
  };

  state = {
    search: '',
  };

  _onSearchTextChange = text => this.setState({ search: text });

  render() {
    const { config } = API;
    const canCreate =
      config.allow_pay_with_consumer_tab &&
      (config.use_stripe_chip_reader || config.allow_manual_card_entry);
    const { search } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <Header
          searchBar
          style={{ backgroundColor: null, alignItems: 'center' }}
        >
          <Stack>
            <Icon
              as={Ionicons}
              name="search"
            />
            <Input
              placeholder="Search"
              onChangeText={this._onSearchTextChange}
              value={search}
            />
          </Stack>
        </Header>
        <ConsumerTabList search={search} />
      </View>
    );
  }
}
