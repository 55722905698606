import React, { useContext } from 'react';
import { View } from 'native-base';
import { ITEM_SELECTOR_COL_VIEWS } from '../../screens/POS';
import SelectItemView from './SelectItemView';
import SelectModifierView from './SelectModifierView';
import EditItemView from './EditItemView';
import POSContext from '../../contexts/POSContext';
import { ItemSelectorColStyles as styles } from '../../styles/POSStyles';

function ItemSelectorCol() {
  const posContext = useContext(POSContext);

  const { SELECT_ITEM, SELECT_MODIFIERS, EDIT_ITEM } = ITEM_SELECTOR_COL_VIEWS;

  const getView = () => {
    switch (posContext.POSValues.itemSelectorColView) {
      case SELECT_ITEM:
        return <SelectItemView />;
      case SELECT_MODIFIERS:
        return <SelectModifierView />;
      case EDIT_ITEM:
        return <EditItemView />;
      default:
        return <SelectItemView />;
    }
  };

  return <View style={styles.ItemSelectorCol}>{getView()}</View>;
}

export default ItemSelectorCol;
