import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Icon, Text } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormattedMessage } from 'react-native-globalize';
import Colors from '../constants/Colors';

function Label({ defaultMessage, textPath, values, color, textStyle }) {
  if (textPath) {
    return (
      <FormattedMessage
        numberOfLines={1}
        style={[styles.label, { color }, textStyle]}
        id={textPath}
        defaultMessage={defaultMessage}
        values={values}
      />
    );
  }
  return <Text style={[styles.label, { color }, textStyle]}>{defaultMessage}</Text>;
}

function HeaderIconButton({
  textPath,
  formatData,
  name,
  textStyle,
  icon,
  iconStyle,
  onPress,
  type,
  disabled,
  accessibilityLabel,
  testID = 'headerIcon',
  size = 22,
}) {
  // after bringing i18n support 'name' is now a fallback that should never actually be hit unless the key doesn't exist in the language file.
  // once language fallbacks are in place we should be able to remove name entirely. en.json should be the fallback for everything

  const color = disabled ? Colors.darkGray : Colors.light;

  if (disabled) {
    onPress = () => {};
  }

  return (
    <TouchableWithoutFeedback
      testID={testID}
      onPress={onPress}
      accessibile
      accessibilityLabel={accessibilityLabel || name}
    >
      <View style={styles.container}>
        <Icon
          name={icon}
          size={size}
          as={type}
          style={[{ color }, iconStyle]}
        />
        <Label
          textPath={textPath}
          defaultMessage={name}
          values={formatData}
          textStyle={textStyle}
          color={color}
        />
      </View>
    </TouchableWithoutFeedback>
  );
}

export default HeaderIconButton;

const styles = EStyleSheet.create({
  container: { marginHorizontal: 15, alignItems: 'center' },
  label: { fontSize: 12, marginTop: 3 },
});
