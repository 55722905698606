import React, { Component, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Text,
  TouchableWithoutFeedback,
  View,
  ScrollView,
  Button,
  Platform,
  Pressable,
} from 'react-native';
import { Icon } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import Alert from '../../Alert';
import OrderHelper from '../../../helpers/OrderHelper';
import Colors from '../../../constants/Colors';
import ScrollButton from '../../ScrollButton';
import API from '../../../api';
import KDSOrderItem from './KDSOrderItem';
import StatusIcon from '../../StatusIcon';
import OrderCheckoutInfo from '../../Order/OrderCheckoutInfo';
import OrderStaffNotes from '../../Order/OrderStaffNotes';
import { HeaderColors } from '../../../constants/FulfillmentMethods';
import { Loader } from '../../index';

moment.updateLocale('en', {
  relativeTime: {
    s: 'just now',
  },
});

// display time in minutes until 4 hours (240 min)
moment.relativeTimeThreshold('m', 4 * 60);

export default class KDSOrderTicket extends Component {
  static defaultProps = {
    onPress: () => {},
  };

  state = {
    processing: false,
    hasScroll: false,
    showPrev: false,
    showMore: false,
    more: 0,
  };

  _lastScrollOffset = 0;

  _lastModified = null;

  _itemRefs = [];

  constructor(props) {
    super(props);

    this.viewabilityConfig = {
      itemVisiblePercentThreshold: 75,
    };

    this.state.data = this._getOrderItemsData();
  }

  componentDidMount() {
    this._mounted = true;
    const { order } = this.props;
    this._lastModified = order.last_modified;
    order.on('update', this._forceUpdate);
  }

  componentWillUnmount() {
    this._mounted = false;
    const { order } = this.props;
    order.off('update', this._forceUpdate);
  }

  _forceUpdate = () => {
    if (this._mounted) {
      this.setState({
        data: this._getOrderItemsData(),
      });
    }
  };

  _getOrderItemsData = forceShow => {
    const now = moment();
    const { order } = this.props;
    const isOpened = order.status !== 'waiting';
    const isFuture = order.snooze_till > now;

    return forceShow || isOpened || isFuture || API.handheldDevice.preferences.kds_show_waiting
      ? Object.values(order.grouped_items)
      : [];
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const isModified = !nextProps.order.last_modified.isSame(this._lastModified);
    if (isModified) this._lastModified = nextProps.order.last_modified;
    return (
      nextProps.order !== this.props.order ||
      nextProps.columns != this.props.columns ||
      nextProps.height != this.props.height ||
      nextState.processing !== this.state.processing ||
      isModified ||
      !_.isEqual(this.state, nextState)
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { props } = this;
    if (props.order !== prevProps.order) {
      props.order.on('update', this._forceUpdate);
      this._forceUpdate();
      this.setState({ processing: false });
    }
  }

  render() {
    const { order, columns, height, onToggle, isPrevRelated, isNextRelated } = this.props;
    const { processing } = this.state;
    const isOpened = order.status !== 'waiting' && !order.is_snoozed;
    const showWhenWaiting = API.handheldDevice.preferences.kds_show_waiting;
    const advanceAtTop = API.handheldDevice.preferences.kds_status_advance_top;

    const badgeInfo = HeaderColors[order.fulfillment_method] || { color: Colors.darkGray };

    return (
      <>
        {isPrevRelated && (
          <View
            style={[
              styles.relatedLink,
              styles.relatedLinkPrev,
              { backgroundColor: badgeInfo.color },
            ]}
          />
        )}
        {isNextRelated && (
          <View
            style={[
              styles.relatedLink,
              styles.relatedLinkNext,
              { backgroundColor: badgeInfo.color },
            ]}
          />
        )}
        {API.config.kds_group_related_tickets && order.related_orders.length > 0 && (
          <Pressable
            style={styles.collapseButton}
            onPress={() => onToggle(order.checkout_id)}
          >
            <Icon
              as={MaterialCommunityIcons}
              name="arrow-collapse"
              style={{ color: 'white', fontSize: 18 }}
            />
          </Pressable>
        )}
        <View style={[styles.orderTicketContainer, { flex: 1 / columns, height }]}>
          <View style={[styles.orderTicket]}>
            {processing && <Loader shown />}
            <TicketHeader
              fulfillment={badgeInfo}
              onPress={this._onPress}
              order={order}
            />
            {API.handheldDevice.preferences.kds_always_advance_ticket_on_tap && (
              <TicketButtonOverlay onPress={() => this._onPress(true)} />
            )}
            {!order.time_closed && (
              <UserDesiredTime
                time={order.user_desired_time}
                snoozed_till={order.snooze_till}
              />
            )}
            {isOpened && !order.time_closed && advanceAtTop && (
              <View style={{ borderTopWidth: 1 }}>
                {isOpened && (
                  <NextStatusButton
                    order={order}
                    onPress={this._setStatus}
                    style={{ backgroundColor: badgeInfo.color }}
                  />
                )}
              </View>
            )}
            <View
              style={[styles.orderTicketContent]}
              onLayout={this._onContentLayout}
            >
              {!isOpened && !showWhenWaiting && !order.is_snoozed && (
                <WhiteOutOverlay
                  onPress={this._onPress}
                  order={order}
                />
              )}
              {!isOpened && showWhenWaiting && !order.is_snoozed && (
                <TransparentOverlay onPress={this._onPress} />
              )}
              {(isOpened || order.is_snoozed || showWhenWaiting) && (
                <TouchableWithoutFeedback onPress={this._onPress}>
                  <View style={{ flex: 1 }}>
                    <ScrollView
                      contentContainerStyle={{ flexGrow: 1 }}
                      ref={fl => (this._scrollView = fl)}
                      onContentSizeChange={this._onItemsLayout}
                      onScroll={this._onScroll}
                      scrollEventThrottle={16}
                      onTouchStart={this._onTouchStart}
                      onTouchEnd={this._onTouchEnd}
                      nestedScrollEnabled
                      onStartShouldSetResponder={evt => true}
                    >
                      <View
                        style={{
                          borderBottomWidth: 3,
                          borderBottomColor: Colors.gray,
                          paddingBottom: 5,
                        }}
                      >
                        {this.state.data.map(entry => this._renderItem(entry))}
                      </View>
                      {order.staff_notes.length > 0 && (
                        <OrderStaffNotes notes={order.staff_notes} />
                      )}
                      <OrderCheckoutInfo
                        checkoutInfo={order.checkout_info}
                        blockStyle={{ padding: 10 }}
                        labelStyle={{ fontWeight: 'bold', flex: 1 }}
                        valueStyle={{ flex: 2, flexWrap: 'wrap' }}
                      />
                      {isOpened && !order.time_closed && !advanceAtTop && (
                        <View style={{ flex: 1, justifyContent: 'flex-end', marginTop: 10 }}>
                          {isOpened && (
                            <NextStatusButton
                              order={order}
                              onPress={this._setStatus}
                              style={{ backgroundColor: badgeInfo.color }}
                            />
                          )}
                        </View>
                      )}
                      {!isOpened && order.is_snoozed && (
                        <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                          <Button
                            title="UN-SNOOZE"
                            onPress={this._unSnooze}
                          />
                        </View>
                      )}
                    </ScrollView>
                    {!!order.time_closed && (
                      <ClosedOrderInfo
                        order={order}
                        color={badgeInfo.color}
                      />
                    )}
                  </View>
                </TouchableWithoutFeedback>
              )}
              <ScrollButton
                visible={this.state.hasScroll}
                enabled={this.state.showPrev}
                onPress={() => {
                  this._scroll(-1);
                }}
                direction="up"
              />
              <ScrollButton
                visible={this.state.hasScroll}
                enabled={this.state.showMore}
                onPress={() => {
                  this._scroll(1);
                }}
                direction="down"
              />
            </View>
          </View>
        </View>
      </>
    );
  }

  _scroll = dir => {
    const scrollAmount = this._viewHeight - 30;
    const y =
      dir > 0 ? this._lastScrollOffset + scrollAmount : this._lastScrollOffset - scrollAmount;
    this._scrollView?.scrollTo({
      x: 0,
      y,
    });
  };

  _onScroll = event => {
    const { nativeEvent } = event;
    this._lastScrollOffset = nativeEvent.contentOffset.y;
    this._calcVisibleItems(nativeEvent);
  };

  _onTouchStart = event => {
    this._showModal = true;
    // const {setParentScroll} = this.props;
    // setParentScroll(false);
    // somehow prevent
  };

  /**
   * Only one of onTouchEnd or onDragEnd will ever be called:
   */

  _onTouchEnd = event => {
    if (this._showModal) this._onPress();
  };

  _calcVisibleItems = nativeEvent => {
    const offsetY = nativeEvent.contentOffset.y;
    const totalY = nativeEvent.contentSize.height;
    const layoutY = nativeEvent.layoutMeasurement.height;
    /*  This code calculates how many items are currently visible in the scrollview. Can be used to display "x more items"...
        let visible = 0;
        let before = 0;
        let after = 0;
        for(let i=0; i<this._itemRefs.length; i++){
          let item = this._itemRefs[i];
          if(item.yOffset < offsetY) before++;
          else if(item.yOffset >= offsetY && item.yOffset < (offsetY + layoutY)){
            visible++;
          }
          if(item.yOffset > offsetY + layoutY){
            after++;
          }
        } */
    if (this._mounted) {
      this.setState({
        hasScroll: totalY > layoutY,
        showPrev: offsetY !== 0,
        showMore: offsetY < totalY - layoutY,
        // prev: before,
        // more: after,
      });
    }
  };

  _renderItem = items => {
    if (API.handheldDevice.getPreference('kds_ticket_item_appearance') === 'expanded') {
      return items.map((item, idx) => (
        <KDSOrderItem
          key={`${idx}-item.orderitemid`}
          item={item}
          onPress={this._itemPressed}
          showQty={false}
        />
      ));
    }

    const item = items[0];
    return (
      <KDSOrderItem
        key={item.orderitemid}
        item={item}
        items={items}
        ref={this._setRef}
        onPress={this._itemPressed}
        showQty
      />
    );
  };

  _itemPressed = () => {
    this._showModal = false;
  };

  _setRef = (ref, yOffset) => {
    this._itemRefs.push(ref);
  };

  _onContentLayout = event => {
    this._viewHeight = event.nativeEvent.layout.height;
    this._triggerManualScrollButtonUpdate();
  };

  /**
   * Only this gets called when the FlatList Updates (Changing order from waiting -> making)
   */
  _onItemsLayout = (width, height) => {
    this._itemsHeight = height;
    if (this._viewHeight) {
      this._triggerManualScrollButtonUpdate();
    }
  };

  _triggerManualScrollButtonUpdate() {
    const fakeEvent = {
      contentOffset: { y: 0 },
      contentSize: { height: this._itemsHeight },
      layoutMeasurement: { height: this._viewHeight },
    };
    this._calcVisibleItems(fakeEvent);
  }

  _onPress = (forceAdvance = false) => {
    const { order, onPress } = this.props;
    if (this.state.processing) return;

    if (
      (forceAdvance === true && !order.time_closed) ||
      (order.status === 'waiting' && !order.kds_future)
    ) {
      // todo: don't advance if order.snooze_till > now
      const nextStatus = order.nextStatus();
      if (nextStatus.key) this._setStatus(nextStatus.key, true);
      else {
        Alert.alert(
          'Error',
          `Could not determine the next status for fulfillment method '${order.fulfillment_method}'.` +
            "This likely means critical data failed to download. From Settings Screen, press 'Refresh All Data', and if that does not work please contact support",
        );
      }
    } else {
      onPress(order);
    }
  };

  _setStatus = (status, updateData) => {
    const { order, onStatusChange } = this.props;
    this._showModal = false;

    this.setState({
      processing: true,
      data: updateData ? this._getOrderItemsData(true) : this.state.data,
    });

    const items = order.getActionableItems();
    OrderHelper.changeItemsState(items, status).then(response => {
      this._lastModified = order.last_modified;
      if (this._mounted) {
        this.setState({
          processing: false,
        });
      }
    });
  };

  _unSnooze = async () => {
    this._showModal = false;
    const { order } = this.props;
    this.setState({ processing: true });
    const response = await API.snoozeOrder(order, null);
    if (this._mounted) {
      this._lastModified = order.last_modified;
      this.setState({ processing: false });
    }
  };
}

export function TicketHeader({ onPress, order, fulfillment, onToggle }) {
  const isOpened = order.status !== 'waiting';
  const { fulfillment_method } = order;
  const fulfillment_pretty_name =
    API.menuData.fulfillment_pretty_names?.[fulfillment_method] || fulfillment.name;

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
      accessible
      accessibilityLabel={`ticket_for_order_id_${order.orderId}`}
    >
      <View
        style={[
          styles.orderTicketHeader,
          isOpened ? styles.orderTicketHeaderOpened : null,
          { backgroundColor: fulfillment.color },
        ]}
      >
        <View style={styles.headerIcon}>
          <StatusIcon
            status={order.status}
            style={styles.orderStatusIcon}
            color="white"
          />
        </View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View style={styles.headerTop}>
            <View style={styles.headerTopLeft}>
              <Text style={styles.orderTicketOrderNumber}>{`#${order.orderNumber}`}</Text>
              <TicketRelatedOrders orders={order.related_orders} />
            </View>
            <View style={styles.headerTopRight}>
              <TicketOrderTime time={order.time} />
            </View>
          </View>
          <View style={styles.headerBottom}>
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <Text
                ellipsizeMode="tail"
                numberOfLines={1}
                style={styles.locationName}
              >
                {order.location ? order.location.locationName : 'unknown'}
              </Text>
            </View>
            <View style={{ paddingLeft: 5 }}>
              <Text style={styles.fulfillmentMethod}>{fulfillment_pretty_name}</Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

/**
 * NextStatusButton
 * @param props
 * @returns {NextStatusButton}
 */

function NextStatusButton({ order, onPress, style }) {
  const nextStatus = order.nextStatus();
  let disabled = false;
  let button_text = '';
  let next_status = '';
  if (nextStatus.key) {
    next_status = nextStatus.key;
    button_text = `SET ${nextStatus.value}`;
  } else {
    return null;
  }
  if (
    order.fulfillment_method === 'robot_delivery' &&
    // From the Ticket, we want to protect both the current and next status. If you want to make as delivered,
    // use the OrderStatusChanger.
    (API.menuData.bot_protected_statuses.includes(next_status) ||
      API.menuData.bot_protected_statuses.includes(order.status))
  ) {
    button_text = API.menuData.status_pretty_names[order.status];
    style = { backgroundColor: 'grey' };
    disabled = true;
  }
  const actionableItems = order.getActionableItems();

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        onPress(next_status);
      }}
      disabled={disabled}
      accessible
      accessibilityLabel={`advance_order_id_${order.orderId}`}
    >
      <View style={[styles.nextStatusButton, style]}>
        <Text style={styles.nextStatusButtonText}>{button_text}</Text>
        <Text
          style={{
            color: 'white',
            fontWeight: 'normal',
            fontSize: 12,
            paddingLeft: 5,
          }}
        >
          ({actionableItems.length} item
          {actionableItems.length > 1 ? 's' : ''})
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

export function TicketRelatedOrders({ orders }) {
  const [fontSize, setFontSize] = useState(14);

  const onLayout = ({
    nativeEvent: {
      layout: { height },
    },
  }) => {
    setFontSize(height > 2 * fontSize ? 10 : 14);
  };

  if (!orders.length) return null;

  return (
    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
      <Icon
        as={Entypo}
        name="link"
        style={{ paddingHorizontal: 3, color: Colors.light, fontSize: 14 }}
      />
      <Text
        ellipsizeMode="tail"
        numberOfLines={2}
        style={EStyleSheet.flatten([styles.related_orders, { fontSize }])}
        onLayout={onLayout}
      >
        {_.map(orders, 'orderNumber').join(', ')}
      </Text>
    </View>
  );
}

/**
 *
 * @param props
 * @returns {null|JSX.Element}
 * @constructor
 */

export function UserDesiredTime({ time, snoozed_till, color }) {
  const HALF_HOUR_FROM_NOW = moment().add(30, 'minutes');
  if (!time) return null;

  const time_format = time && time.isSame(moment(), 'day') ? '[Today at] LT' : 'MMM D, LT';
  const snooze_format = snoozed_till && snoozed_till.isSame(moment(), 'day') ? 'LT' : 'MMM D, LT';
  const showSnooze = snoozed_till?.isAfter();

  const bgColor =
    color || (time ? (time.isBefore(HALF_HOUR_FROM_NOW) ? '#ee312e' : '#ff9f00') : '#ff9f00');
  const formattedString = time ? `For ${time.format(time_format)}` : 'ASAP';
  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: bgColor }}>
      <Text style={{ color: 'white' }}>{formattedString}</Text>
      {showSnooze && (
        <Text style={{ color: 'yellow' }}>
          Snoozed till
          {snoozed_till.format(snooze_format)}
        </Text>
      )}
    </View>
  );
}

/**
 *
 * @param order
 * @param color
 * @returns {JSX.Element}
 * @constructor
 */
export function ClosedOrderInfo({ order, color }) {
  const { time, user_desired_time, time_closed } = order;

  const time_format = time.isSame(moment(), 'day') ? '[today at] LT' : 'MMM D, LT';
  const desired_format =
    user_desired_time && order.user_desired_time.isSame(order.time, 'day') ? 'LT' : 'MMM D, LT';
  const desiredTimeStr = user_desired_time
    ? order.user_desired_time.format(desired_format)
    : 'ASAP';
  const delivered_format = time_closed.isSame(moment(), 'day') ? '[today at] LT' : 'MMM D, LT';

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.darkGray,
        padding: 3,
      }}
    >
      <Text style={{ color: 'white' }}>
        Placed <Bold>{time.format(time_format)}</Bold> for <Bold>{desiredTimeStr}</Bold>
      </Text>
      <Text style={{ color: 'white' }}>
        {order.pretty_status} <Bold>{order.time_closed.format(delivered_format)}</Bold>
      </Text>
    </View>
  );
}

function Bold({ children }) {
  return <Text style={{ fontWeight: 'bold' }}>{children}</Text>;
}

function WhiteOutOverlay({ onPress, order }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={{ padding: 5, flex: 1 }}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: Colors.darkGray, fontSize: 14 }}>New Order. Tap to View</Text>
          <Text style={{ color: Colors.darkGray }}>
            ({order.items.length} item
            {order.items.length > 1 ? 's' : ''})
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

function TransparentOverlay({ onPress }) {
  return (
    <Pressable
      onPress={onPress}
      style={{
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          transform: [{ rotate: '-30deg' }],
          color: 'white',
          fontSize: 30,
          textShadowColor: 'black',
          textShadowOffset: { width: -1, height: 1 },
          textShadowRadius: 10,
        }}
      >
        New Order!
      </Text>
    </Pressable>
  );
}

function TicketButtonOverlay({ onPress }) {
  return (
    <Pressable
      nativeID="ticketButtonOverlay"
      style={{
        position: 'absolute',
        top: 40,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100000,
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      onPress={onPress}
    />
  );
}

/**
 * Time displayed on the top right corner of the ticket
 */
export class TicketOrderTime extends React.PureComponent {
  componentDidMount() {
    this._mounted = true;
    // update the clock every 30 seconds if the time is less then 1 hour from present
    if (this.props.time > moment().subtract(1, 'hour'))
      this.interval = setInterval(this._forceUpdate, 30000);
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.interval);
  }

  _forceUpdate = () => {
    if (this._mounted) this.forceUpdate();
  };

  render() {
    const { time } = this.props;
    return (
      <View>
        <Text style={{ color: 'white' }}>{time.fromNow(true)}</Text>
      </View>
    );
  }
}

export const styles = EStyleSheet.create({
  orderStatusIcon: {
    tintColor: 'white',
    height: 24,
  },
  orderTicket: {
    flex: 1,
    margin: 10,
    backgroundColor: '#f9f9f9',
    ...(Platform.OS === 'web' ? { boxShadow: '2px 2px 4px rgba(0,0,0,0.2)' } : { elevation: 4 }),
    borderRadius: 8,
    overflow: 'hidden',
  },
  orderTicketContainer: {},

  orderTicketHeader: {
    flexDirection: 'row',
    backgroundColor: Colors.primary,
    padding: 4,
    height: 50,
  },
  headerIcon: {
    width: 35,
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTop: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  headerTopLeft: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  headerTopRight: {
    paddingLeft: 5,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  headerBottom: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  headerRight: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  locationName: {
    color: 'white',
    fontSize: 14,
  },
  fulfillmentMethod: {
    color: '#fff',
    flex: 1,
    fontSize: 12,
  },
  orderTicketHeaderOpened: {
    backgroundColor: Colors.secondary,
  },
  orderTicketOrderNumber: {
    color: Colors.light,
    fontWeight: 'bold',
    fontSize: 16,
  },
  related_orders: {
    color: Colors.light,
    fontSize: 12,
  },
  orderTicketHeaderNumItems: {
    color: Colors.light,
  },
  orderTicketContent: {
    flex: 1,
  },
  nextStatusButton: {
    backgroundColor: Colors.ternary,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 45,
  },
  nextStatusButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlignVertical: 'center',
  },
  collapseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
    width: 27,
    height: 27,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  relatedLink: {
    position: 'absolute',
    top: '50%',
    height: 15,
    width: 11,
  },
  relatedLinkPrev: {
    left: 0,
  },
  relatedLinkNext: {
    right: 0,
  },
});
