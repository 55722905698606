import _ from 'lodash';
import EpsonPrinter from './Printers/EpsonPrinter';
import StarImagePrinter from './Printers/StarImagePrinter';
import {
  trackTerminalPrintRequest,
  trackTerminalPrintSuccess,
  trackTerminalPrintFailure
} from '../segment/tracking-events/printing';

/**
 * PrinterController
 * @author Andrew Schreffler
 * @description Controls the printing at a specific printer from terminal. Printer configs and print queues can be sent
 * to the terminal via handheldPoll. When that happens, a PrinterController is created using the appropriate Printer
 * Driver and processPrintJobs is called each time handheldPoll has a queue of print jobs to send.
 */

export default class PrinterController {
  constructor(printerId, printerConfigDict, API) {
    this.API = API;
    this.printerId = printerId;
    this.completedTaskIds = [];
    this.errorJsonToSend = null;
    this.processingPrintJobs = false;

    this.printerConfigDict = null;
    this.printer = null;
    this.updatePrinterConfigIfNeeded(printerConfigDict);
  }

  updatePrinterConfigIfNeeded = printerConfigDict => {
    if (!_.isEqual(this.printerConfigDict, printerConfigDict)) {
      this.printerConfigDict = printerConfigDict;
      if (printerConfigDict.cups_model) {
        this.printer = new StarImagePrinter(printerConfigDict);
      } else {
        this.printer = new EpsonPrinter(printerConfigDict);
      }
    }
  };

  unknownPrinterErrorMessage = () =>
    `Unknown error when trying to send print task to pi ethernet printer ${this.printer.ip}: ${this.printer.port}`;

  standardTrackingFields = () => ({
    printer_type: this.printerConfigDict.printer_type,
    printer_driver: this.printerConfigDict.driver_profile,
  })

  nextUncompletedTask = printQueue => {
    const taskIdsStillOnServer = Object.keys(printQueue);
    this.completedTaskIds = this.completedTaskIds.filter(task =>
      taskIdsStillOnServer.includes(task),
    );
    const nextTaskId =
      Object.keys(printQueue).find(taskId => !this.completedTaskIds.includes(taskId)) || null;
    const nextTaskDict = nextTaskId ? printQueue[nextTaskId] : null;
    return [nextTaskId, nextTaskDict];
  };

  markTaskCompleted = async taskId => {
    this.completedTaskIds.push(taskId);
    await this.API.updatePrintJobs();
  };

  sendPrintTask = async taskDict => this.printer.sendPrintTask(taskDict);

  processPrintJobs = async printQueue => {
    this.processingPrintJobs = true;
    const [nextTaskId, nextTaskDict] = this.nextUncompletedTask(printQueue);
    if (nextTaskId !== null) {
      try {
        trackTerminalPrintRequest({
          ...this.standardTrackingFields(),
          task_id: nextTaskId,
        });
        const result = await this.sendPrintTask(nextTaskDict);
        if (result.success) {
          trackTerminalPrintSuccess({
            ...this.standardTrackingFields(),
            task_id: nextTaskId,
          });
        }
        if (result.error) {
          trackTerminalPrintFailure({
            ...this.standardTrackingFields(),
            task_id: nextTaskId,
            error: result.error,
          });
          this.errorJsonToSend = { errorMessage: result.error, task: nextTaskDict };
        }
      } catch (error) {
        trackTerminalPrintFailure({
          ...this.standardTrackingFields(),
          task_id: nextTaskId,
          error: this.unknownPrinterErrorMessage(),
        });
        this.errorJsonToSend = {
          errorMessage: this.unknownPrinterErrorMessage(),
          task: nextTaskDict,
        };
      } finally {
        await this.markTaskCompleted(nextTaskId);
        await this.processPrintJobs(printQueue);
      }
    } else {
      this.processingPrintJobs = false;
    }
  };

  startProcessingPrintJobs = printQueue => {
    if (!this.processingPrintJobs) {
      this.processPrintJobs(printQueue);
    }
  };
}
