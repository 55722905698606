import React, { useContext } from 'react';
import { Button, Icon, Row } from 'native-base';
import { View, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { POS_REDUCER_ACTIONS, POS_SCREEN } from '..';
import ApprovePaymentButton from '../../../components/POS/Checkout/ApprovePaymentButton';
import POSContext, { POSCheckoutScreenContext } from '../../../contexts/POSContext';
import { ButtonStyle, PaymentCompletedStyles as styles } from '../../../styles/POSStyles';
import { CHECKOUT_REDUCER_ACTIONS, CHECKOUT_SCREEN } from './Checkout';

function PaymentCompleted() {
  const { POSDispatch } = useContext(POSContext);
  const {
    checkoutState: { emailReceiptSentTo, phoneReceiptSentTo },
    checkoutDispatch,
  } = useContext(POSCheckoutScreenContext);

  const {
    PageContainer,
    PaymentCompletedText,
    ReceiptText,
    CompletedIcon,
    ReceiptDoneIcon,
    ReceiptTypeText,
  } = styles;

  const navigateBackToMenu = () => {
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_POS_SCREEN,
      POSScreen: POS_SCREEN.MENU,
    });
  };

  return (
    <View style={PageContainer}>
      <Icon
        size={10}
        as={Ionicons}
        name="checkmark-circle-outline"
        style={CompletedIcon}
      />

      <Text style={PaymentCompletedText}>Payment Completed</Text>
      <ApprovePaymentButton
        label="New Order"
        onPress={navigateBackToMenu}
      />
      <Text style={ReceiptText}>Send your receipt</Text>
      <Button
        style={ButtonStyle('secondary', 'sm')}
        minW={160}
        marginBottom={30}
        onPress={() =>
          checkoutDispatch({
            type: CHECKOUT_REDUCER_ACTIONS.SET_CHECKOUT_SCREEN,
            newCheckoutScreen: CHECKOUT_SCREEN.SEND_EMAIL_RECEIPT,
          })
        }
      >
        {emailReceiptSentTo ? (
          <Row
            alignContent="center"
            justifyContent="center"
          >
            <Icon
              size={5}
              as={Ionicons}
              name="checkmark-outline"
              style={ReceiptDoneIcon}
            />
            <Text style={ReceiptTypeText}>Email Sent!</Text>
          </Row>
        ) : (
          <Text style={ReceiptTypeText}>Email</Text>
        )}
      </Button>
      <Button
        style={ButtonStyle('secondary', 'sm')}
        minW={160}
        onPress={() =>
          checkoutDispatch({
            type: CHECKOUT_REDUCER_ACTIONS.SET_CHECKOUT_SCREEN,
            newCheckoutScreen: CHECKOUT_SCREEN.SEND_TEXT_RECEIPT,
          })
        }
      >
        {phoneReceiptSentTo ? (
          <Row
            alignContent="center"
            justifyContent="center"
          >
            <Icon
              size={5}
              as={Ionicons}
              name="checkmark-outline"
              style={ReceiptDoneIcon}
            />
            <Text style={ReceiptTypeText}>Text Sent!</Text>
          </Row>
        ) : (
          <Text style={ReceiptTypeText}>Text Message</Text>
        )}
      </Button>
    </View>
  );
}

export default PaymentCompleted;
