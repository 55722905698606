import React, { useState } from 'react';
import { Button, Input, Stack, Text, View } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Pressable } from 'react-native';
import Colors from '../../constants/Colors';

function PromoInput({ cart }) {
  const [promoCode, setPromoCode] = useState(cart.valid_promo_codes[0]);
  const [processing, setProcessing] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onSubmitPromoCode = async () => {
    setProcessing(true);
    const newPromoCode = promoCode?.trim();
    // if (cart.valid_promo_codes.length) await cart.removePromoCode(cart.valid_promo_codes[0]);
    await cart.addPromoCode(newPromoCode);
    setProcessing(false);
  };

  return (
    <View
      testID="tipScreenAddPromo"
      style={styles.container}
    >
      {expanded && (
        <>
          <Stack
            rounded
            style={styles.item}
          >
            <Input
              placeholder="Enter Promo"
              value={promoCode}
              onSubmitEditing={onSubmitPromoCode}
              onChangeText={setPromoCode}
              style={styles.input} // match button height
            />
          </Stack>
          <Button
            onPress={onSubmitPromoCode}
            disabled={processing}
          >
            <Text style={{ color: '#FFF' }}>APPLY</Text>
          </Button>
        </>
      )}
      {!expanded && (
        <Pressable
          testID="tipScreenAddPromo"
          style={{}}
          onPress={() => setExpanded(true)}
        >
          <Text>
            [<Text style={{ color: Colors.primary }}>Add Promo</Text>]
          </Text>
        </Pressable>
      )}
    </View>
  );
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginVertical: 10,
    paddingLeft: '10%',
  },
  item: {
    borderRadius: 4,
    flex: 1,
    marginRight: 20,
    padding: 0,
  },
  input: { height: 43, backgroundColor: 'white', borderRadius: 5 },
});

export default PromoInput;
