import React, { useEffect, useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import IconButton from '../IconButton';
import API from '../../api';
import Alert from '../Alert';

const Label = {
  CLAIM: 'common__claim',
  UNCLAIM: 'common__unClaim',
};

function ClaimButton({ selectedItems, onPress }) {
  const [label, setLabel] = useState('');
  const [canClaim, setCanClaim] = useState(false);

  useEffect(() => {
    const allMyItems = !selectedItems.find(i => i.claimed_by !== API.handheldDevice.name);
    const allUnClaimed = !selectedItems.find(i => i.claimed_by);
    setCanClaim(selectedItems.length && (allMyItems || allUnClaimed));
    setLabel(
      selectedItems.length && !selectedItems.find(i => i.claimed_by !== API.handheldDevice.name)
        ? Label.UNCLAIM
        : Label.CLAIM,
    );
  }, [selectedItems]);

  const claimItems = async () => {
    const unclaim = label === Label.UNCLAIM;
    const result = await API.claimItems(selectedItems, unclaim);
    if (result.warning) {
      Alert.alert('Notice', 'One or more of the items has been claimed by another device.');
    }
    onPress();
  };

  return (
    <IconButton
      icon={`hand-${label === Label.CLAIM ? 'grab' : 'stop'}-o`}
      iconType={FontAwesome}
      label={label}
      onPress={claimItems}
      disabled={!canClaim}
      disabledMsg="No items selected"
    />
  );
}

export default ClaimButton;
