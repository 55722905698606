import _ from 'lodash';
import { ITEM_SELECTOR_COL_VIEWS, POS_REDUCER_ACTIONS } from '../../screens/POS';
import CartModifier from '../../models/CartModifier';
import API from '../../api';

export const addMenuItem = (
  menuItem,
  POSDispatch,
  menuId,
  mods = [],
  specialInstructions = null,
) => {
  POSDispatch({
    menuId,
    mods,
    specialInstructions,
    type: POS_REDUCER_ACTIONS.ADD_ITEM_TO_CART,
    item: menuItem,
  });
};

export const onMenuItemPress = (menuItem, POSDispatch, cart, menuId) => {
  // If the item has modifiers, go to modifiers screen
  if (menuItem.modifierGroups.length) {
    // If the item has no required mods, add it
    if (menuItem.modifierGroups.every(modifierGroup => modifierGroup.min_selected === 0)) {
      addMenuItem(menuItem, cart, POSDispatch, menuId);
    }

    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
      itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.SELECT_MODIFIERS,
    });
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_SELECTED_MENU_ITEM,
      menuItem,
    });
  } else {
    addMenuItem(menuItem, POSDispatch, menuId);
  }
};

/**
 * Ensure that all the modifier group requirements are filled out. Look for min_selected and max_selected.
 */
export const validateModifierGroups = (modifierGroups, selectedModifiersByGroup) =>
  modifierGroups.every(modifierGroup => {
    const { modifiers, min_selected: minSelected, max_selected: maxSelected } = modifierGroup;
    const selectedMods = selectedModifiersByGroup[modifierGroup.id] ?? [];
    if (selectedMods.length < minSelected || selectedMods.length > maxSelected) {
      return false;
    }

    const selectedModIds = selectedMods.map(modifier => modifier.menuItemId);
    const selectedNestedMenuModifiers = modifiers.filter(modifier =>
      selectedModIds.includes(modifier.menuItemId),
    );
    return selectedNestedMenuModifiers.every(selectedNestedMenuMod =>
      validateModifierGroups(
        selectedNestedMenuMod._modifier_groups,
        selectedMods.find(mod => mod.menuItemId === selectedNestedMenuMod.menuItemId)?.mods,
      ),
    );
  });

export const returnToSelectItemsScreen = POSDispatch =>
  POSDispatch({
    type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
    itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.SELECT_ITEM,
  });

/**
 * Removes cart item from the cart
 * @param {*} cartItemId
 * @param {*} POSDispatch
 */
export const removeItem = (cartItemId, POSDispatch) => {
  POSDispatch({
    cartItemId,
    type: POS_REDUCER_ACTIONS.SET_QTY,
    qty: 0,
  });
  returnToSelectItemsScreen(POSDispatch);
  POSDispatch({
    type: POS_REDUCER_ACTIONS.SET_SELECTED_CART_ITEM,
    selectedCartItem: null,
  });
};

/**
 * Used to update modifiers on a CartItem
 * @param {*} cartItemId
 * @param {*} selectedCartItem
 * @param {*} modifierGroups
 * @param {*} selectedModifiersByGroup
 * @param {*} POSDispatch
 */
export const saveItem = (
  cartItemId,
  selectedCartItem,
  modifierGroups,
  selectedModifiersByGroup,
  POSDispatch,
  specialInstructions = null,
) => {
  if (validateModifierGroups(modifierGroups, selectedModifiersByGroup)) {
    const modifiersByGroupEntries = Object.entries(selectedModifiersByGroup);
    const cartModifiersByGroupIdEntries = modifiersByGroupEntries.map(([groupId, modifiers]) => [
      groupId,
      modifiers.map(modifier => new CartModifier(selectedCartItem, modifier)),
    ]);
    const cartModifiersByGroupId = Object.fromEntries(cartModifiersByGroupIdEntries);
    POSDispatch({
      cartItemId,
      specialInstructions,
      type: POS_REDUCER_ACTIONS.EDIT_CART_ITEM,
      mods: cartModifiersByGroupId,
    });
    returnToSelectItemsScreen(POSDispatch);
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_SELECTED_CART_ITEM,
      selectedCartItem: null,
    });
  }
};

/**
 * Used to determine the initial modifier ids for the selected cart item
 * @param {*} selectedCartItemMods
 * @param {*} modifierGroup
 * @returns
 */
export const getInitialModIds = (selectedCartItemMods, modifierGroup) =>
  (!!selectedCartItemMods &&
    selectedCartItemMods[modifierGroup.id]?.map(modifier => modifier.menuItemId)) ??
  [];

// Get the visible menu headings from the selected menu, currently the first menu for the customer
export const mergeMenuHeadings = () => {
  const menus = API.menuData?.menus.filter(m => m.enabled);
  const menuHeadings = menus.map(menu => menu.headings).flat();
  const uniqueMenuHeadings = _.uniqBy(menuHeadings, menuHeading => menuHeading.id);

  // For each menu heading, find the others that share its ID. They could have different items! Combine all those items into a single array, set it for the menu heading.
  uniqueMenuHeadings.forEach(menuHeading => {
    const menuHeadingsSameId = menuHeadings.filter(mh => mh.id === menuHeading.id);
    const menuHeadingItems = menuHeadingsSameId.map(menuHeading => menuHeading.items).flat();
    const uniqueMenuHeadingItems = _.uniqBy(menuHeadingItems, item => item.menuItemId);
    menuHeading.items = uniqueMenuHeadingItems;
  });
  const sortedMenuHeadings = uniqueMenuHeadings.sort((headingA, headingB) =>
    headingA.heading_name.localeCompare(headingB.heading_name),
  );
  return sortedMenuHeadings;
};

export const getModifier = modifierId => {
  const modifiers = API?.menuData?.modifiersById ?? {};
  return modifiers[modifierId];
};

export const getDefaultModIds = modifierGroup =>
  modifierGroup.modifiers
    .filter(modifier => modifier.is_default_choice)
    .map(modifier => modifier.menuItemId);
