import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { Icon } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import Grid from '../../bbot-component-library/POS/Grid';
import POSContext from '../../contexts/POSContext';
import MenuSelection from './MenuSelection';
import { mergeMenuHeadings, onMenuItemPress } from './utils';
import { SearchResultsStyles as styles } from '../../styles/POSStyles';
import EmptyState from '../../bbot-component-library/POS/EmptyState';

function SearchResultsView({ searchText }) {
  const {
    POSDispatch,
    POSValues: { cart = null, selectedMenuHeading: { menu: { menuId } = {} } = {} },
  } = useContext(POSContext);
  const [searchResults, setSearchResults] = useState([]);

  const { HeadingContainer, HeadingName, Body, Container } = styles;
  const menuHeadings = mergeMenuHeadings();

  useEffect(() => {
    // Filter the headings down by item name and heading name
    // Show the heading if it has items that match searchText

    setSearchResults(
      menuHeadings
        .map(menuHeading => ({
          name: menuHeading.heading_name,
          id: menuHeading.id,
          items: menuHeading.items.filter(
            item =>
              menuHeading.heading_name.toLowerCase().includes(searchText.toLowerCase()) ||
              item.name_for_bartender.toLowerCase().includes(searchText.toLowerCase()),
          ),
        }))
        .filter(heading => !!heading.items.length),
    );
  }, [searchText]);

  if (!searchText.length) {
    return null;
  }

  return (
    <View style={Container}>
      {searchResults.length > 0 ? (
        <ScrollView
          style={Body}
          showsVerticalScrollIndicator={false}
        >
          {searchResults.map(heading => (
            <View
              style={HeadingContainer}
              key={heading.id}
            >
              <Text style={HeadingName}>{heading.name}</Text>
              <Grid
                data={heading.items}
                keyExtractor={item => item.menuItemId}
                renderItem={({ item }) => (
                  <MenuSelection
                    key={item.menuItemId}
                    name={item.name_for_bartender}
                    onPress={() => onMenuItemPress(item, POSDispatch, cart, menuId)}
                  />
                )}
              />
            </View>
          ))}
        </ScrollView>
      ) : (
        <EmptyState
          icon={
            <Icon
              size={16}
              marginRight={1}
              as={Ionicons}
              name="moon-outline"
              color="#a3a3a3"
            />
          }
          message={`Sorry, no items were found matching ${searchText}`}
        />
      )}
    </View>
  );
}

export default SearchResultsView;
