import { Cart, CartItem, ItemPart } from '../../models';
import API from '../../api';

/**
 * Updates the cart items whenever we update the cart. This should be refactored to be cleaner soon.
 * @param {Cart} cart
 */
const updateCheckItems = cart => {
  const check = cart.checks[0];
  check.items = [];
  cart.items.forEach((item, i) => {
    item._parts = [];
    check.items.push(
      new ItemPart({
        check,
        item,
        numerator: item.qty,
      }),
    );
  });

  cart.updateTotals();
};

/**
 * Used by reducer to add items to the cart. If the cart does not exist, create a new one.
 * If the item exists in the cart, update the quantity.
 * If the item does not exist in the cart, add a new item.
 * @param {*} draft
 * @param {*} action
 */
export const addItemToCart = (draft, action) => {
  const { cart } = draft;
  const {
    menuId,
    mods = [],
    specialInstructions = null,
    item: {
      menu_heading_id,
      menuItemId,
      name,
      pretax_cents,
      tax_cents,
      tax_fraction,
      modifierGroups,
    },
  } = action;

  draft.lastmodified = new Date();

  if (cart) {
    // There is a new cart, we should adjust the quantity
    const existingItem = cart.itemExists(menuItemId);

    // If menu item already in cart and item has no mods, update the quantity
    if (existingItem && !modifierGroups.length) {
      existingItem.setQuantity(existingItem.qty + 1);
    } else {
      cart.addItem({
        menuId,
        menuItemId,
        menu_heading_id,
        mods,
        name,
        pretax_cents,
        tax_cents,
        tax_fraction,
        special_instructions: specialInstructions,
        customer_id: API.getCustomerId(),
      });
    }
    updateCheckItems(cart);
  } else {
    // There is not a new cart yet, so we should create one
    const customerId = API.getCustomerId();

    const newCart = new Cart({
      location_id: API.config.quick_order_location,
      fulfillment_method: 'patron_pickup',
      customer_id: customerId,
    });
    const cartItem = new CartItem(newCart, {
      name,
      pretax_cents,
      tax_cents,
      menuItemId,
      menu_heading_id,
      tax_fraction,
      mods,
      special_instructions: specialInstructions,
      menuId: draft.selectedMenuHeading.menu.menuId,
      customer_id: customerId,
    });
    newCart.addItem(cartItem);
    draft.cart = newCart;
    updateCheckItems(draft.cart);
  }
};

/**
 * Used by the POS reducer to set the item quantity
 * @param {} draft
 * @param {*} action
 */
export const setItemQty = (draft, action) => {
  const {
    cart,
    cart: { items },
  } = draft;
  const { cartItemId, qty } = action;
  draft.lastmodified = new Date();

  if (qty === 0) {
    cart.items = items.filter(cartItem => cartItem.id !== cartItemId);
  } else {
    const cartItem = items.find(cartItem => cartItem.id === cartItemId);
    if (cartItem) {
      cartItem.setQuantity(qty);
    }
  }

  updateCheckItems(cart);
};

/**
 * Used by the POS reducer to set the item mods
 * @param {} draft
 * @param {*} action
 */
export const editItemMods = (draft, action) => {
  const {
    cart: { items },
  } = draft;
  const { cartItemId, mods, specialInstructions = null } = action;

  draft.lastmodified = new Date();

  const cartItem = items.find(cartItem => cartItem.id === cartItemId);
  if (cartItem) {
    cartItem.mods = mods;
    cartItem.special_instructions = specialInstructions;
  }
  updateCheckItems(draft.cart);
};

export const setTipCents = (draft, action) => {
  const { cart } = draft;
  const { tipCents } = action;

  draft.lastmodified = new Date();

  if (!cart?.checks) {
    cart.resetChecks();
  }

  const check = cart.checks[0];
  check.tip_cents = parseInt(tipCents);
  updateCheckItems(cart);
};

export const updateFees = (draft, action) => {
  const { fees } = action;

  draft.cart._updateFees(fees);
  draft.cart.fees = fees;
  draft.loadingFees = false;
  draft.feesModified = new Date();
};
