import React from 'react';
import { View, Text, Dimensions, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';
import Alert from './Alert';
import { styles } from './Styles/ItemButtonStyles';
import ItemCustomizerButton from './ItemCustomizerButton';

export default class ItemButton extends React.PureComponent {
  static propTypes = {
    selected: PropTypes.bool,
    columns: PropTypes.number,
    disabled: PropTypes.bool,
    modifier_groups: PropTypes.array,
  };

  static defaultProps = {
    columns: 4,
  };

  _onPress = () => {
    const { item, onPress, disabled, disabledMsg } = this.props;
    if (disabled) {
      if (disabledMsg) {
        Alert.alert('', disabledMsg);
      }
      return;
    }
    onPress(item);
  };

  render() {
    const {
      cartModifier,
      changeQuantity,
      children,
      customizeModifier,
      disabled,
      modifier,
      selected,
      testID,
      text,
    } = this.props;
    let { buttonStyle, columns } = this.props;

    const showCustomize = modifier?.modifier_groups.length > 0;
    let customizeText = modifier?.hasRequiredModifiers() ? 'Required' : 'Optional';
    const showQty = modifier?.modifier_allow_quantities;
    if (cartModifier?.mods && cartModifier?.selected) {
      customizeText = cartModifier.getChildModsString()?.buttonText || customizeText;
    }
    const { width: browserWidth } = Dimensions.get('window');

    const viewStyles = [styles.button];
    const textStyle = [styles.text];
    buttonStyle = buttonStyle || {};

    if (selected) {
      viewStyles.push(styles.selected);
    }
    if (disabled === true) {
      viewStyles.push(styles.disabled);
      textStyle.push(styles.disabledText);
    }

    let customizeButtonStyle = [];
    if (cartModifier && !cartModifier.isValid()) {
      customizeButtonStyle = [styles.errorButton];
    }

    let customizeTextStyle = [];
    if (cartModifier && !cartModifier.isValid()) {
      customizeTextStyle = [styles.errorText];
    }

    const style = {};

    if (!columns) {
      columns = 4;
    }

    const width = browserWidth / columns - 6;

    viewStyles.push(style);

    if (buttonStyle) {
      viewStyles.push(buttonStyle);
    }

    const textNode = text ? (
      <Text
        adjustsFontSizeToFit
        numberOfLines={2}
        ellipsizeMode="middle"
        style={textStyle}
      >
        {text}
      </Text>
    ) : null;

    return (
      <View style={[styles.buttonContainer, { width }]}>
        <TouchableWithoutFeedback
          testID={testID}
          accessible
          accessibilityLabel={text}
          onPress={this._onPress}
        >
          <View style={[viewStyles, showCustomize && styles.leftButtonSplit]}>
            {children || textNode}
          </View>
        </TouchableWithoutFeedback>

        {showCustomize && (
          <ItemCustomizerButton
            operation="customize"
            text={text}
            onPress={customizeModifier}
            customText={customizeText}
            customizeButtonStyle={customizeButtonStyle}
            customizeTextStyle={customizeTextStyle}
          />
        )}

        {showQty && modifier.heading.max_selected > 1 && (
          <ItemCustomizerButton
            modifier={cartModifier}
            operation="quantity"
            text={text}
            onPress={changeQuantity}
            customText="QTY"
            customizeButtonStyle={customizeButtonStyle}
            customizeTextStyle={customizeTextStyle}
          />
        )}
      </View>
    );
  }
}
