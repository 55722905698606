import React, { Component } from 'react';
import { Pressable, View } from 'react-native';
import {
  Button,
  Row,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Stack,
  Text,
} from 'native-base';
import { AntDesign, Ionicons, MaterialIcons } from '@expo/vector-icons';
import ConsumerTabList from './ConsumerTabList';
import { Header, SearchBar } from '../../bbot-component-library';
import API from '../../api';
import { capturePaymentMethod } from '../Stripe/StripeHelper';
import Colors from '../../constants/Colors';

class ConsumerTabsView extends Component {
  state = {
    search: '',
    updated: false,
  };

  _onSearchTextChange = text => this.setState({ search: text });

  _startTab = async () => {
    const { location } = this.props;
    try {
      const tab = await capturePaymentMethod({ location, forceTab: true });
    } catch (err) {
      console.log('failed starting tab');
    }
  };

  _clearSearch = () => {
    this.setState({ search: '' });
  };

  render() {
    const { location } = this.props;
    const { search, updated } = this.state;
    const { config } = API;

    const canCreate =
      config.allow_pay_with_consumer_tab &&
      (config.use_stripe_chip_reader || config.allow_manual_card_entry);

    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexBasis: 0 }}>
          <Header
            style={{
              backgroundColor: null,
              alignItems: 'center',
              padding: 10,
              borderBottomWidth: 2,
              borderBottomColor: '#D3D3D3',
            }}
          >
            <Row style={{ width: '100%', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <SearchBar
                  value={search}
                  onChangeText={this._onSearchTextChange}
                  onClear={this._clearSearch}
                />
              </View>
              {canCreate && (
                <View style={{ marginLeft: 10 }}>
                  <Button
                    endIcon={
                      <Icon
                        name="pluscircleo"
                        as={AntDesign}
                      />
                    }
                    colorScheme="primary"
                    style={{ color: Colors.primary, height: 40 }}
                    onPress={this._startTab}
                  >
                    NEW TAB
                  </Button>
                </View>
              )}
            </Row>
          </Header>
          <ConsumerTabList
            search={search}
            location={location}
            updated={updated}
          />
        </View>
      </View>
    );
  }
}

export default ConsumerTabsView;
