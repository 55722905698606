import React from 'react';
import _ from 'lodash';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import EStyleSheet from 'react-native-extended-stylesheet';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import StatusIcon from './StatusIcon';
import Colors from '../constants/Colors';

import OrderItem, { styles as mStyles } from './OrderItem';

export default class GroupedItems extends React.Component {
  state = {
    expanded: false,
  };

  render() {
    const { item } = this.props.children[0].props;
    const items = this.props.children.map(c => c.props.item);
    const qty = this.props.children.length;
    const { selectedItems } = this.props;
    const selected = _.intersection(selectedItems, items);
    const priceEach = item.tax_determined_total_cents / 100;

    const qtyTxt = !selected.length ? qty : `(${selected.length}/${qty})`;

    return (
      <View style={{ borderBottomWidth: 1, padding: 5 }}>
        <View style={[GroupedItems.styles.body, { flexDirection: 'row', alignItems: 'center' }]}>
          <TouchableWithoutFeedback onPress={this._toggle}>
            <View
              style={{
                width: 24,
                height: 24,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 0,
              }}
            >
              <Icon
                as={AntDesign}
                style={{ fontSize: 18 }}
                name={this.state.expanded ? 'caretdown' : 'caretright'}
              />
            </View>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback
            onPress={() => {
              this.props.onPress(items);
            }}
          >
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  padding: 0,
                  alignItems: 'center',
                }}
              >
                <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold' }}>{item.getName()}</Text>
                <Text
                  numberOfLines={1}
                  style={{ textAlign: 'right' }}
                >
                  {qtyTxt}
                </Text>
                <FormattedCurrency
                  style={{ width: '18%', textAlign: 'right' }}
                  value={priceEach}
                />
                <FormattedCurrency
                  style={{ width: '18%', textAlign: 'right' }}
                  value={priceEach * qty}
                />
              </View>
              <View style={mStyles.details}>
                <View style={mStyles.detailRow}>
                  {OrderItem.getModifiers(item)}
                  {OrderItem.getSpecialInstructions(item)}
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>

        {!this.state.expanded && (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: 2,
              marginLeft: 25,
            }}
          >
            {this.props.children.map(child => {
              const { item } = child.props;
              // let statusIcon = StatusIcons[item.status] || StatusIcons.default;
              const selected = this.props.selectedItems.includes(item);
              const style = {
                backgroundColor: selected ? Colors.primaryLight : null,
              };
              return (
                <TouchableWithoutFeedback
                  key={`itemicon_${item.orderitemid}`}
                  onPress={() => {
                    this.props.onItemPress(item);
                  }}
                >
                  <View style={[{ marginRight: 5, padding: 5 }, style]}>
                    <StatusIcon
                      status={item.status}
                      style={{ height: 24, width: 24 }}
                    />
                    {!!item.claimed_by && (
                      <Icon
                        as={FontAwesome}
                        name="check"
                        size={8}
                        style={{ position: 'absolute', bottom: 0, right: 0 }}
                      />
                    )}
                  </View>
                </TouchableWithoutFeedback>
              );
            })}
          </View>
        )}
        {this.state.expanded && (
          <View style={GroupedItems.styles.children}>{this.props.children}</View>
        )}
      </View>
    );
  }

  _toggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  static styles = EStyleSheet.create({
    body: {
      minHeight: 30,
    },
    children: {
      borderTopWidth: 1,
      borderTopColor: Colors.gray,
      marginTop: 5,
    },
  });
}
