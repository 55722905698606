/**
 * Usage:
 *
 * class MyClass extends mix(MyBaseClass).with(Mixin1, Mixin2) {
 *    // mixing functions
 * }
 *
 *
 */

const mix = superclass => new MixinBuilder(superclass);

class MixinBuilder {
  constructor(superclass) {
    this.superclass = superclass;
  }

  with(...mixins) {
    return mixins.reduce((c, mixin) => mixin(c), this.superclass);
  }
}

export default mix;
