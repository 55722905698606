import React, { Component } from 'react';
import _ from 'lodash';
import { View, ActivityIndicator } from 'react-native';
import { Icon, Switch, Text, ScrollView } from 'native-base';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import Alert from '../components/Alert';
import { ListItem, Body, Left, Right, Content } from '../bbot-component-library';
import API from '../api';
import ActionSheet from '../components/ActionSheet';
import ServiceConditionModal from '../components/ServiceConditionModal/ServiceConditionModal';
import Loader from '../components/Loader';
import Colors from '../constants/Colors';
import { checkAccess, clearAccess } from '../helpers/Access';
import { Station } from '../models';
import { styles } from '../styles/ListComponentStyle';

/**
 * Each Menu will have the ability to Toggle Ordering
 * Each Station will have its own set of controls:
 - Allow Ordering
 - Service Conditions
 - Fulfillment Methods (Expandable Section)
 - Location That Can Order
 */

class OrderControlScreen extends Component {
  static propTypes = {};

  static navigationOptions = {
    title: 'Order Control',
  };

  state = {
    last_call: false,
    processing: {},
    show_menus: false,
    locations_shown: {},
    fm_shown: {},
    stationLocations: {},
    showSCModal: false,
    modalStation: null,
    loading: true,
  };

  componentDidMount() {
    const { navigation } = this.props;

    API.on('config_updated', this._refresh);
    API.on('stations', this._refresh);
    API.on('locations', this._refresh);
    API.on('menu', this._refresh);

    this._blurListener = navigation.addListener('willBlur', this._lock);
    this._focusListener = navigation.addListener('didFocus', () => {
      if (this.state.loading && API.hasPolled) this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    API.off('config_updated', this._refresh);
    API.off('stations', this._refresh);
    API.off('locations', this._refresh);
    API.off('menu', this._refresh);
    this._blurListener.remove();
    this._focusListener.remove();
    if (this._inactionListener) this._inactionListener.remove();
  }

  _refresh = () => {
    this.setState({
      loading: false,
    });
    this.forceUpdate();
  };

  _lock = () => {
    clearAccess();
    if (this._inactionListener) this._inactionListener.remove();
  };

  render() {
    const config = API.getConfig();
    const { menus } = API.menuData;

    return (
      <View style={{ flex: 1 }}>
        <Loader shown={this.state.loading} />
        <ScrollView>
          <Content>
            <View>
              <ListItem itemDivider>
                <Text variant="divider">Menu Settings</Text>
              </ListItem>
              <ListItem
                key="showMenus"
                _icon_
                onPress={() => this._showMenus()}
              >
                <Left icon>
                  <Icon
                    size={8}
                    style={styles.iconStyle}
                    as={MaterialIcons}
                    name="menu-book"
                  />
                </Left>
                <Body justifyLeft>
                  <Text>Set Menu Status</Text>
                </Body>
                <Right onListItem>
                  <ExpandableArrow expanded={this.state.show_menus} />
                </Right>
              </ListItem>
              {this.state.show_menus && this._renderMenus(menus)}

              {config.kds_stations.map(station => this._renderStation(station))}
            </View>
            {config.show_service_conditions && (
              <ServiceConditionModal
                visible={this.state.showSCModal}
                station={this.state.modalStation}
                onClose={() => this.setState({ showSCModal: false })}
                onSuccess={() => {
                  this.setState({ showSCModal: false });
                  this.forceUpdate();
                }}
              />
            )}
          </Content>
        </ScrollView>
      </View>
    );
  }

  _toggleFulfillmentMethod = async (station, method, state) => {
    const { processing } = this.state;
    if (!processing[station.id]) processing[station.id] = {};
    processing[station.id][method] = true;
    const hasAccess = await checkAccess('toggle_fulfillment_method_allowed', true);
    this.setState({ processing });
    if (hasAccess) {
      this._doToggleFulfillmentMethod(station, method, state, hasAccess);
    } else {
      this._doneProcessing(`${station.id}.${method}`);
    }
  };

  _doToggleFulfillmentMethod = async (station, method, state, hasAccess) => {
    const result = await API.toggleFulfillmentMethod(station, method, state, hasAccess.user.id);
    this._doneProcessing(`${station.id}.${method}`);
    if (result.success) {
      this._refresh();
    }
  };

  _doneProcessing = key => {
    const { processing } = this.state;
    _.set(processing, key, false);

    this.setState({
      processing,
    });
  };

  _showMenus = () => {
    this.setState({
      show_menus: !this.state.show_menus,
    });
  };

  _renderMenus = menus =>
    menus.map(menu => {
      const STATUS_LABELS_MENU = {
        on: 'On',
        off: 'Off',
        auto: `Determined by Schedule (${menu.visible_per_schedule ? 'On Now' : 'Off Now'})`,
      };
      return (
        <ListItem
          _icon_
          disabled={!menu.use_schedule}
          onPress={() => this._showOrderAllowedSheet(menu)}
          key={`menu_${menu.menuId}`}
        >
          <Left icon>
            <Icon
              style={{ marginLeft: 15 }}
              name="level-down"
              as={Entypo}
            />
          </Left>
          <Body justifyLeft>
            <Text>{menu.name}</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>
              {menu.use_schedule
                ? STATUS_LABELS_MENU[menu.order_allowed]
                : 'Menu Schedule Set by Station'}
            </Text>
          </Right>
        </ListItem>
      );
    });

  _renderStation = station => {
    const STATUS_LABELS_STATION = {
      on: 'On',
      off: 'Off',
      auto: `Determined by Schedule (${station.open_based_on_schedule ? 'On Now' : 'Off Now'})`,
    };
    const selectedIds = station.service_condition_ids;
    const allConditions = station.customer?.service_conditions || [];
    const selected = allConditions?.reduce(
      (res, condition) =>
        res.concat(condition.choices.filter(c => selectedIds.includes(c.id)).map(c => c.name)),
      [],
    );

    return (
      <View key={`station_${station.id}`}>
        <ListItem itemDivider>
          <Text variant="divider">{station.station_name}</Text>
        </ListItem>

        {/* Allow Ordering */}
        <ListItem
          _icon_
          onPress={() => this._showOrderAllowedSheet(station)}
        >
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={MaterialIcons}
              name="perm-device-information"
            />
          </Left>
          <Body justifyLeft>
            <Text>Allow Ordering</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{STATUS_LABELS_STATION[station.order_allowed]}</Text>
          </Right>
        </ListItem>
        {allConditions.length > 0 && API.config.show_service_conditions && (
          <ListItem
            _icon_
            onPress={() => {
              this.setState({
                showSCModal: true,
                modalStation: station,
              });
            }}
          >
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={MaterialIcons}
                name="room-service"
              />
            </Left>
            <Body justifyLeft>
              <Text>Service Conditions</Text>
            </Body>
            <Right onListItem>
              <Text style={styles.note}>{selected.join(', ')}</Text>
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            </Right>
          </ListItem>
        )}

        <ListItem
          _icon_
          onPress={() => this._showLocations(station)}
        >
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Entypo}
              name="location"
            />
          </Left>
          <Body justifyLeft>
            <Text>Allowed Locations</Text>
          </Body>
          <Right onListItem>
            <ExpandableArrow expanded={this.state.locations_shown[station.id]} />
          </Right>
        </ListItem>

        <View style={{ marginLeft: 30 }}>{this._renderStationLocations(station)}</View>
        {this._renderFulfillmentMethods(station)}
      </View>
    );
  };

  _showLocations = async station => {
    this.state.locations_shown[station.id] = !this.state.locations_shown[station.id];

    this.setState({
      locations_shown: this.state.locations_shown,
    });
    if (this.state.locations_shown[station.id]) {
      const result = await API.getLocationOrderAllowed();
      if (result.error) {
        // TODO: Show an error
      } else {
        this.setState({
          stationLocations: result.stations,
        });
      }
    }
  };

  _renderStationLocations = station => {
    if (this.state.locations_shown[station.id] && this.state.stationLocations[station.id]) {
      const { locations } = this.state.stationLocations[station.id];
      const locationModels = Object.keys(locations)
        .map(locationId => API._locations[locationId])
        .filter(l => l);

      if (!locationModels.length) {
        return (
          <ListItem
            _icon_
            key={`${station.id}_noLocations`}
          >
            <Body justifyLeft>
              <Text style={styles.note}>No Locations Available</Text>
            </Body>
            <Right style={{ flex: 1 }} />
          </ListItem>
        );
      }

      const zones = _.groupBy(_.orderBy(locationModels, 'shortId'), 'zone_for_reports');

      let rows = [];
      for (const zone in zones) {
        rows.push(
          <ListItem
            key={zone}
            _icon_
            onPress={() => this._showToggleZone(zone, station, zones[zone])}
          >
            <Left icon />
            <Body justifyLeft>
              <Text style={{ fontWeight: 'bold' }}>{zone}</Text>
            </Body>
            <Right onListItem>
              <Text style={styles.note}>Toggle All</Text>
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            </Right>
          </ListItem>,
        );
        rows = rows.concat(
          zones[zone].map(location => (
            <ListItem
              _icon_
              key={location.id}
            >
              <Left icon>
                <View style={{ width: 10 }} />
              </Left>
              <Body justifyLeft>
                <Text>{location.locationName}</Text>
              </Body>
              <Right onListItem>
                <Switch
                  value={location.order_allowed === 'on'}
                  onValueChange={allowed => this._toggleLocation(station, location, allowed)}
                />
              </Right>
            </ListItem>
          )),
        );
      }
      return rows;
    }
  };

  _renderFulfillmentMethods = station => {
    if (!station.may_change_fulfillment_method) {
      return (
        <ListItem
          _icon_
          onPress={() => {
            Alert.alert('Station does not currently have access to edit fulfillment methods');
          }}
        >
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              name="flow-line"
              as={Entypo}
            />
          </Left>
          <Body justifyLeft>
            <Text style={styles.note}>Allowed Fulfillment Methods</Text>
          </Body>
          <Right style={{ flex: 1 }}>
            <Text>
              {station.selected_fulfillment_methods
                .map(fm => API.menuData.fulfillment_pretty_names[fm])
                .join(', ')}
            </Text>
          </Right>
        </ListItem>
      );
    }

    return [
      <ListItem
        _icon_
        onPress={() => this._showFulfillmentMethods(station)}
        key={`fm_${station.id}`}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            name="flow-line"
            as={Entypo}
          />
        </Left>
        <Body justifyLeft>
          <Text>Fulfillment Methods</Text>
        </Body>
        <Right onListItem>
          <ExpandableArrow expanded={this.state.fm_shown[station.id]} />
        </Right>
      </ListItem>,
      ...station.possible_fulfillment_methods.map(fm => {
        const isProcessing = !!_.get(this.state.processing, `${station.id}.${fm}`);
        if (!this.state.fm_shown[station.id]) return null;
        return (
          <ListItem
            _icon_
            key={`${station.id}_${fm}`}
          >
            <Left icon>
              <Icon
                style={[{ marginLeft: 15 }, styles.iconStyle]}
                name="level-down"
                as={Entypo}
              />
            </Left>
            <Body justifyLeft>
              <Text>{API.menuData.fulfillment_pretty_names[fm]}</Text>
            </Body>
            <Right onListItem>
              {!isProcessing && (
                <Switch
                  value={station.selected_fulfillment_methods.includes(fm)}
                  onValueChange={val => this._toggleFulfillmentMethod(station, fm, val)}
                />
              )}
              {isProcessing && (
                <View style={{ margin: 15 }}>
                  <ActivityIndicator color={Colors.primary} />
                </View>
              )}
            </Right>
          </ListItem>
        );
      }),
    ];
  };

  _showFulfillmentMethods = station => {
    this.state.fm_shown[station.id] = !this.state.fm_shown[station.id];
    this.setState({
      fm_shown: this.state.fm_shown,
    });
  };

  _showToggleZone = (zone, station, locations) => {
    const buttons = [
      { text: 'Set All On', value: true },
      { text: 'Set All Off', value: false },
    ];
    ActionSheet.show(
      {
        options: buttons,
        title: zone,
      },
      async btnId => {
        const button = buttons[btnId];
        const hasAccess = await checkAccess('toggle_location_order_allowed', true);
        if (hasAccess) {
          await API.setLocationOrderAllowed(station, locations, button.value, hasAccess.user.id);
          this.setState({
            stationLocations: { ...this.state.stationLocations },
          });
        }
      },
    );
  };

  _toggleLocation = async (station, location, allowed) => {
    const hasAccess = await checkAccess('toggle_location_order_allowed', true);
    if (hasAccess) {
      this._doToggleLocation(station, location, allowed, hasAccess);
    }
  };

  _doToggleLocation = async (station, location, allowed, user) => {
    const response = await API.setLocationOrderAllowed(station, [location], allowed, user.user.id);
    if (response.success) {
      this.setState({
        stationLocations: { ...this.state.stationLocations },
      });
    }
  };

  _showOrderAllowedSheet = object => {
    let offText = 'Force Off';
    if (object.extra_properties?.allow_reset_to_auto) offText += ' (Resets to auto at 5AM)';
    let buttons = [
      { text: 'Force On', value: 'on' },
      { text: 'Auto (Determined by Schedule)', value: 'auto' },
      { text: offText, value: 'off' },
    ];
    if (object instanceof Station) {
      if (
        object.order_allowed === 'on' ||
        (object.order_allowed === 'auto' && object.open_based_on_schedule)
      ) {
        buttons = buttons.concat(
          { text: 'Pause Ordering for 5 Minutes', value: '5' },
          { text: 'Pause Ordering for 10 Minutes', value: '10' },
          { text: 'Pause Ordering for 15 Minutes', value: '15' },
        );
      }
    }
    ActionSheet.show(
      {
        options: buttons,
        title: 'Allow Ordering',
      },
      async result => {
        const btn = buttons[result];
        if (btn) {
          const [action, endpoint] =
            object instanceof Station
              ? ['toggle_station_order_allowed', API.setOrderAllowed]
              : ['toggle_menu_order_allowed', API.setMenusAllowed];
          const hasAccess = await checkAccess(action, true);
          if (hasAccess) {
            this.setState({ loading: true });
            const result = await endpoint(object, btn.value, hasAccess.user.id);
            await API.handheldPoll();
            // this.forceUpdate();
            this.setState({ loading: false });
          }
        }
      },
    );
  };
}

function ExpandableArrow(props) {
  const arrow = props.expanded ? 'chevron-down' : 'chevron-right';
  return (
    <Icon
      style={styles.arrow}
      variant="arrow"
      as={Entypo}
      name={arrow}
    />
  );
}

export default OrderControlScreen;
