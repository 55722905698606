import _ from 'lodash';
import moment from 'moment';
import Model from './Model';

export class Guest {
  constructor(obj) {
    this.seat_number = obj.seat_number;
    this.card_ids = obj.card_ids || [];
    this.nickname = obj.nickname;
    this.default_tip = obj.default_tip || null;
  }
}

export default class SeatedGroup extends Model {
  id = null;

  time_seated = null;

  guests = [];

  cards = [];

  smart_cards = [];

  orders = [];

  _field_map = {
    time_seated: t => moment(t),
  };

  constructor(obj) {
    super();
    this.update(obj);
  }

  update(obj) {
    super.update(obj);
    this._updateGuests();
  }

  _updateGuests() {
    this.guests.forEach((guest, i) => {
      if (!(guest instanceof Guest)) this.guests[i] = new Guest(guest);
    });
  }

  addCardToGuests(seat_numbers, id) {
    if (!Array.isArray(seat_numbers)) return false;

    seat_numbers.forEach(seat => {
      this.guests[seat - 1].card_ids = _.uniq(_.concat(this.guests[seat - 1].card_ids, id));
    });

    return this.save();
  }

  getGuestCardIds(seat_numbers) {
    return _.uniq(
      this.guests.reduce(
        (cardIds, guest) =>
          seat_numbers.includes(guest.seat_number) ? cardIds.concat(guest.card_ids) : cardIds,
        [],
      ),
    );
  }

  async save() {
    return await this.api.editGroup(this);
  }
}
