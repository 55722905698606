import Model from './Model';

/**
 * Todo: Make this use the 'update' function
 */

export default class MenuHeading extends Model {
  id = null;

  customer_id = null;

  archived = false;

  heading_name = '';

  display_position = 100;

  items = [];

  _field_map = {
    items: items => items.map(item => this.menu.menuData.menuItemsById[item]),
  };

  constructor(menu, obj) {
    super();

    Object.defineProperty(this, 'menu', { value: menu });

    this.update(obj);
  }

  get menus_heading_is_on() {
    return this.api.menuData.menus.filter(m => m.headings.find(h => h.id === this.id));
  }

  toJSON() {
    const obj = super.toJSON();
    obj.items = this.items.map(item => item.menuItemId);
    return obj;
  }
}
