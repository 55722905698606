import React from 'react';
import _ from 'lodash';
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Icon, Text } from 'native-base';
import color from 'color';
import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import { ClosedOrderInfo, styles } from './KDSOrderTicket';
import { HeaderColors } from '../../../constants/FulfillmentMethods';
import Colors from '../../../constants/Colors';
import OrderStaffNotes from '../../Order/OrderStaffNotes';
import OrderCheckoutInfo from '../../Order/OrderCheckoutInfo';
import ScrollButton from '../../ScrollButton';
import KDSOrderItem from './KDSOrderItem';
import StatusIcon from '../../StatusIcon';
import OrderHelper from '../../../helpers/OrderHelper';
import API from '../../../api';

/**
 * TODO: This and KDSOrderTicket should extend the same component. Or pull out all the scroll container button stuff,
 * make it into a component, and both tickets can use it to power their scrollview
 */

export default class KDSGroupedTicket extends React.Component {
  state = {
    processing: false,
  };

  _lastScrollOffset = 0;

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  _onPress = async () => {
    const { orders } = this.props;
    const order = orders[0];
    const related_orders = [order, ...order.related_orders];

    const { minStatus, nextStatus } = this._getStatuses();
    if (!nextStatus) return;

    this.setState({ processing: true });
    const ordersToUpdate = related_orders.filter(o => o.status === minStatus);
    const items = _.flatMap(ordersToUpdate, o => o.items);
    await OrderHelper.changeItemsState(items, nextStatus);
    this.setState({ processing: false });
  };

  _getStatuses = () => {
    const { orders } = this.props;
    const order = orders[0];

    const related_orders = [order, ...order.related_orders];

    const { status_sequence } = order;
    const statuses = related_orders.map(o => o.status);

    const minStatus = _.minBy(statuses, s => status_sequence.indexOf(s));
    const nextStatus = status_sequence?.[status_sequence.indexOf(minStatus) + 1];

    return {
      minStatus,
      nextStatus,
    };
  };

  render() {
    const { orders, columns, height, onToggle } = this.props;
    const order = orders[0];
    const related_orders = [order, ...order.related_orders];

    const isOpened = order.status !== 'waiting' && !order.is_snoozed;
    const badgeInfo = HeaderColors[order.fulfillment_method] || {};
    const { minStatus, nextStatus } = this._getStatuses();

    const buttonText = nextStatus
      ? `SET ${order.api.status_pretty_names[nextStatus]}`
      : order.api.status_pretty_names[minStatus];
    const bgColor = color(badgeInfo.color).darken(0.3).hex();

    const isClosed = related_orders.every(o => o.time_closed);
    const lastClosed = _.max(related_orders, o => o.time_closed);

    return (
      <View style={[styles.orderTicketContainer, { flex: 1 / columns, height }]}>
        {this.state.processing && (
          <View style={styles.orderTicketLoading}>
            <ActivityIndicator color={Colors.primary} />
          </View>
        )}
        <View style={styles.orderTicket}>
          <View style={[styles.orderTicketHeader, { backgroundColor: bgColor }]}>
            <View style={styles.headerIcon}>
              <Icon
                name="link"
                as={Entypo}
                style={{ color: 'white' }}
              />
            </View>
            <View style={{ flex: 1, flexDirection: 'column' }}>
              <View style={{ flex: 1 }}>
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  style={[styles.orderTicketOrderNumber]}
                >
                  {`#${related_orders.map(order => order.orderNumber).join(', ')}`}
                </Text>
              </View>
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  style={styles.locationName}
                >
                  {order.location ? order.location.locationName : 'unknown'}
                </Text>
              </View>
            </View>
            <View style={styles.headerRight}>
              <TouchableWithoutFeedback onPress={() => onToggle(order.checkout_id)}>
                <Icon
                  name="expand-arrows-alt"
                  as={FontAwesome5}
                  style={{ color: Colors.light }}
                />
              </TouchableWithoutFeedback>
            </View>
          </View>
          <View
            style={styles.orderTicketContent}
            onLayout={this._onContentLayout}
          >
            <TouchableWithoutFeedback
              onPress={
                API.handheldDevice.preferences.kds_always_advance_ticket_on_tap && this._onPress
              }
            >
              <View style={{ flex: 1 }}>
                <ScrollView
                  contentContainerStyle={{ flexGrow: 1 }}
                  ref={fl => (this._scrollView = fl)}
                  onContentSizeChange={this._onItemsLayout}
                  onScroll={this._onScroll}
                  scrollEventThrottle={16}
                  onTouchStart={this._onTouchStart}
                  onTouchEnd={this._onTouchEnd}
                  nestedScrollEnabled
                  onStartShouldSetResponder={evt => true}
                >
                  <View
                    style={{
                      borderBottomWidth: 3,
                      borderBottomColor: Colors.gray,
                      paddingBottom: 5,
                    }}
                  >
                    {related_orders.map(order => (
                      <View key={`${order.checkout_id}_${order.orderId}`}>
                        <View
                          style={{
                            backgroundColor: Colors.lightGray,
                            padding: 5,
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <StatusIcon
                            status={order.status}
                            size="small"
                          />
                          <Text style={{ marginLeft: 5 }}>
                            {`#${order.orderNumber} - ${order.customer.customer_name}`}
                          </Text>
                        </View>
                        <View>{order.grouped_items.map(entry => this._renderItem(entry))}</View>
                      </View>
                    ))}
                  </View>
                  {order.staff_notes.length > 0 && <OrderStaffNotes notes={order.staff_notes} />}
                  <OrderCheckoutInfo
                    checkoutInfo={order.checkout_info}
                    blockStyle={{ padding: 10 }}
                    labelStyle={{ fontWeight: 'bold', flex: 1 }}
                    valueStyle={{ flex: 2, flexWrap: 'wrap' }}
                  />
                  {!isClosed && (
                    <View style={{ justifyContent: 'flex-end', flex: 1 }}>
                      <Pressable onPress={this._onPress}>
                        <View
                          style={[
                            styles.nextStatusButton,
                            { backgroundColor: nextStatus ? bgColor : Colors.darkGray },
                          ]}
                        >
                          <Text style={styles.nextStatusButtonText}>{buttonText}</Text>
                        </View>
                      </Pressable>
                    </View>
                  )}
                </ScrollView>

                {isClosed && (
                  <ClosedOrderInfo
                    order={lastClosed}
                    color={badgeInfo.color}
                  />
                )}
                <ScrollButton
                  visible={this.state.hasScroll}
                  enabled={this.state.showPrev}
                  onPress={() => {
                    this._scroll(-1);
                  }}
                  direction="up"
                />
                <ScrollButton
                  visible={this.state.hasScroll}
                  enabled={this.state.showMore}
                  onPress={() => {
                    this._scroll(1);
                  }}
                  direction="down"
                />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    );
  }

  _renderItem = items => {
    if (API.handheldDevice.getPreference('kds_ticket_item_appearance') === 'expanded') {
      return items.map((item, idx) => (
        <KDSOrderItem
          key={`${idx}-item.orderitemid`}
          item={item}
          onPress={this._itemPressed}
          showQty={false}
        />
      ));
    }

    const item = items[0];
    return (
      <KDSOrderItem
        key={item.orderitemid}
        item={item}
        items={items}
        ref={this._setRef}
        onPress={this._itemPressed}
        showQty
      />
    );
  };

  _itemPressed = () => {
    this._showModal = false;
  };

  _scroll = dir => {
    const scrollAmount = this._viewHeight - 30;
    const y =
      dir > 0 ? this._lastScrollOffset + scrollAmount : this._lastScrollOffset - scrollAmount;
    this._scrollView?.scrollTo({
      x: 0,
      y,
    });
  };

  _onScroll = event => {
    const { nativeEvent } = event;
    this._lastScrollOffset = nativeEvent.contentOffset.y;
    this._calcVisibleItems(nativeEvent);
  };

  _onContentLayout = event => {
    this._viewHeight = event.nativeEvent.layout.height;
    this._triggerManualScrollButtonUpdate();
  };

  /**
   * Only this gets called when the FlatList Updates (Changing order from waiting -> making)
   */
  _onItemsLayout = (width, height) => {
    this._itemsHeight = height;
    if (this._viewHeight) {
      this._triggerManualScrollButtonUpdate();
    }
  };

  _triggerManualScrollButtonUpdate() {
    if (!this._mounted) return;

    const fakeEvent = {
      contentOffset: { y: 0 },
      contentSize: { height: this._itemsHeight },
      layoutMeasurement: { height: this._viewHeight },
    };
    this._calcVisibleItems(fakeEvent);
  }

  _calcVisibleItems = nativeEvent => {
    const offsetY = nativeEvent.contentOffset.y;
    const totalY = nativeEvent.contentSize.height;
    const layoutY = nativeEvent.layoutMeasurement.height;
    /*  This code calculates how many items are currently visible in the scrollview. Can be used to display "x more items"...
        let visible = 0;
        let before = 0;
        let after = 0;
        for(let i=0; i<this._itemRefs.length; i++){
          let item = this._itemRefs[i];
          if(item.yOffset < offsetY) before++;
          else if(item.yOffset >= offsetY && item.yOffset < (offsetY + layoutY)){
            visible++;
          }
          if(item.yOffset > offsetY + layoutY){
            after++;
          }
        } */
    this.setState({
      hasScroll: totalY > layoutY,
      showPrev: offsetY !== 0,
      showMore: offsetY < totalY - layoutY,
      // prev: before,
      // more: after,
    });
  };
}
