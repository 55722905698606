import React, { useEffect, useState } from 'react';
import MLoader from './MLoader';

let showFn = null;
let hideFn = null;

/**
 * Usage:
 * In App.js:
 * ```javascript
 *  @import LoadingOverlay, {showLoader, hideLoader} from "./components/Modals/LoadingOverlay"
 * ```
 * Anywhere in the app:
 * ```javascript
 *  showLoader();
 *  hideLoader();
 * ```
 *
 * @param defaults
 * @returns {JSX.Element}
 * @constructor
 */

function LoadingOverlay(defaults) {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    showFn = (props = {}) => {
      setVisible(true);
      setMessage(props.message || defaults.message);
    };
    hideFn = () => {
      setVisible(false);
    };
  }, []);

  return (
    <MLoader
      shown={visible}
      message={message}
    />
  );
}

export const showLoader = props => {
  if (showFn) showFn(props);
  else throw 'No top-level instance of LoadingOverlay found';
};

export const hideLoader = () => {
  if (hideFn) hideFn();
  else throw 'No instance available to hide';
};

export default LoadingOverlay;
