import React from 'react';
import _ from 'lodash';
import { View, Text, ActivityIndicator } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-native-globalize';
import Alert from './Alert';
import API from '../api';
import Breadcrumb from './Breadcrumb';
import Colors from '../constants/Colors';
import OrderHelper from '../helpers/OrderHelper';
import { checkAccess } from '../helpers/Access';

export default class OrderItemStatusChanger extends React.Component {
  static propTypes = {
    selectedItems: PropTypes.array,
    order: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    selectedItems: [],
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      sendText: true,
    };
  }

  render() {
    const items = this._actionableItems();
    const count = items.length;
    const { order } = this.props;
    const numOrders = this._numOrders();
    const currStatus = order ? API.menuData.status_pretty_names[order.status] : '';
    return (
      <View style={styles.container}>
        {this.state.processing && (
          <View style={styles.processing}>
            <ActivityIndicator color={Colors.primary} />
          </View>
        )}
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            paddingVertical: 5,
          }}
        >
          <Text>
            {items.length}
            <FormattedPlural
              value={items.length}
              one={
                <FormattedMessage
                  id="selectedItem"
                  defaultMessage=" item selected"
                />
              }
              other={
                <FormattedMessage
                  id="selectedItem__plural"
                  defaultMessage=" items selected"
                />
              }
            />
          </Text>
          {numOrders > 1 && (
            <FormattedMessage
              id="selectedOrder__numSelectedOrders"
              values={{ numSelectedOrders: numOrders }}
            />
          )}
        </View>
        {order && !this._actionableItems().length && <Text>{currStatus}</Text>}
        <View style={styles.breadcrumbContainer}>{this.getBreadCrumbs()}</View>
      </View>
    );
  }

  _actionableItems = () => {
    // make closed_statueses not actionable unless a special property is set
    let inactionableStatuses = ['refunded'];
    const { allowRegress } = this.props;
    if (!allowRegress) inactionableStatuses = API.menuData.closed_statuses;
    return this.props.selectedItems.filter(i => !inactionableStatuses.includes(i.status));
  };

  getBreadCrumbs() {
    const items = this._actionableItems();
    if (!items.length) return null;

    const item_statuses = _.map(items, i => i.status);
    const items_by_status = _.groupBy(items, i => i.status);
    const statuses = _.uniq(item_statuses);
    const { order } = this.props;
    let fulfillment_method = null;
    if (order) {
      fulfillment_method = order.fulfillment_method;
    } else {
      const fulfillment_methods = _.uniq(_.map(items, i => i.order.fulfillment_method));
      if (fulfillment_methods.length > 1) {
        return (
          <FormattedMessage
            id="selectedOrder__differentFulfillmentMethods"
            style={{
              padding: 10,
              textAlign: 'center',
              flex: 1,
              color: Colors.error,
            }}
          />
        );
      }
      fulfillment_method = fulfillment_methods[0];
    }

    if (!API.menuData?.status_sequences) {
      return (
        <FormattedMessage
          id="selectedOrder__errorLoadingStatusSequence"
          style={{ padding: 10 }}
        />
      );
    }
    // if (!statuses.length || statuses.includes('refunded')) return null;
    const status_sequence = API.menuData.status_sequences[fulfillment_method] || [];
    const min_status = status_sequence.findIndex(s => statuses.includes(s));

    return status_sequence.map((status, i) => {
      let pretty_name = API.menuData.status_pretty_names[status];
      const count = _.get(items_by_status, [status, 'length']);
      if (count > 0) pretty_name += ` (${count})`;
      return (
        <Breadcrumb
          key={i}
          disabled={i < min_status || !items.length}
          text={pretty_name}
          position={i}
          bgColor={statuses.includes(status) ? Colors.primary : ''}
          textColor={statuses.includes(status) ? '#FFF' : ''}
          count={status_sequence.length}
          onPress={() => {
            this._changeItemState(status);
          }}
        />
      );
    });
  }

  _changeItemState = async status => {
    const items = this._actionableItems();
    if (!items.length) return;

    const { bot_protected_statuses } = API.menuData;
    if (bot_protected_statuses.includes(status)) {
      Alert.alert('Invalid Action', 'You can only perform this action from KDS View');
      return;
    }

    const { fulfillment_method } = items[0].order;
    const status_sequence = API.menuData.status_sequences[fulfillment_method];
    if (!status_sequence) return;
    const new_status_index = status_sequence.indexOf(status);
    let accessControlled = false;

    items.forEach(item => {
      const index = status_sequence.indexOf(item.status);
      if (new_status_index < index) accessControlled = true;
    });

    const userWithAccess = API.currUser;

    if (accessControlled) {
      const userWithAccess = await checkAccess('move_item_status_backwards', true);
      if (!userWithAccess) return;
    }

    this._doChangeState(items, status, userWithAccess);
  };

  _doChangeState = async (items, status, user) => {
    const { onChange } = this.props;
    this.setState({
      processing: true,
    });
    const response = await OrderHelper.changeItemsState(items, status, '', user);

    this.setState({
      processing: false,
    });

    if (response.success) {
      onChange(items);
    }
  };

  _numOrders = () => _.uniq(this._actionableItems().map(i => i.orderId)).length;
}

const styles = EStyleSheet.create({
  container: {
    borderTopWidth: 1,
    alignItems: 'center',
    backgroundColor: '#e9e6ea',
  },
  breadcrumbContainer: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  processing: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    backgroundColor: 'rgba(1,1,1,0.5)',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
