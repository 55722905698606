import * as DeviceInfo from 'expo-device';
import { Platform } from 'react-native';
import MDM from '@bbot/bbot-mdm';
import Constants from 'expo-constants';
import EventModel from './EventModel';
import { SENTRY_ENV, IS_ELO } from '../constants/Config';

import manifest from '../app.json';

export default class HandheldDevice extends EventModel {
  id = null;

  name = ''; // Name as given by user

  customer_id = '';

  config_id = null;

  reader_serial = '';

  unique_id = Constants.installationId;

  device_name = Constants.deviceName; // Name reported by the OS

  /** (Android) * */
  make = '';

  model = '';

  mdm = 'unknown';

  /** (Android) Device Serial Number (no longer available as of Android 8) * */
  serial = '';

  /** App Version (called apk_version because Terminal used to be Android Only) * */
  apk_version = manifest.expo.version;

  /** Deprecated * */
  bundle_version = '';

  release_channel = SENTRY_ENV;

  api_level = 0;

  platform = Platform.OS;

  preferences = {};

  possible_kds_ticket_item_appearance_options = [
    { name: 'Default', value: 'default' },
    { name: 'Expanded', value: 'expanded' },
  ];

  getPreference = key =>
    key in this.preferences ? this.preferences[key] : this.api.config.preferences[key];

  resetToDefaults = async () => {
    this.preferences = {};
    await this.api.updateDevice({ preferences: {} });
  };

  constructor(obj) {
    super();

    this.update(obj);

    if (Platform.OS === 'web' && window.Cypress) {
      // Make sure this value is the same as railbot_app/textFixtureConstants.py `TEST_HANDHELD_DEVICE_ID`
      Object.defineProperty(this, 'id', { value: '77cb584e-aa0a-450b-ac12-60b6c43a7de2' });
    }

    this.load().then(() => {
      this._ready = true;
      this.trigger('ready');
    });
  }

  async updatePrefs(obj) {
    const newPrefs = { ...this.preferences, ...obj };
    await this.api.updateDevice({
      preferences: newPrefs,
    });
    Object.keys(obj).forEach(key => this.trigger(key, obj[key]));
    this.trigger('update');
  }

  async load() {
    if (Platform.OS === 'web') await this.loadWeb();
    if (Platform.OS === 'android') await this.loadAndroid();
  }

  async save() {}

  async loadWeb() {
    // this.device_name = window.navigator.userAgent;
    this.make = window.navigator.vendor;
    this.model = window.navigator.userAgent;
  }

  async loadAndroid() {
    this.make = DeviceInfo.brand;
    this.model = DeviceInfo.modelName;
    this.api_level = DeviceInfo.platformApiLevel;

    if (IS_ELO) this.mdm = 'Elo';
    else {
      MDM.isManaged()
        .then(managed => {
          if (managed) {
            if (managed.includes('miradore')) this.mdm = 'Miradore';
            else if (managed.includes('shoonya')) this.mdm = 'Esper';
            else this.mdm = managed;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
