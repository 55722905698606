import React, { Component } from 'react';
import { FlatList, Text, View } from 'react-native';
import { FormattedCurrency, FormattedMessage } from 'react-native-globalize';
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../../api';

// Components
import Alert from '../Alert';
import OrderListViewHeader from '../OrderListViewHeader';
import OrderListView from '../OrderListView';
import OrderItemStatusChanger from '../OrderItemStatusChanger';
import RefundIconButton from '../RefundIconButton';
import IconButton from '../IconButton';
import KeypadModal from '../KeypadModal';
import Cart from '../../models/Cart';

// Styles
import Colors from '../../constants/Colors';
import ClaimButton from '../IconButtons/ClaimButton';
import IconFooter from '../../bbot-component-library/IconFooter';

class OrdersView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOrders: [],
      selectedItems: [],
    };

    this._mounted = null;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  _refreshSelectedItems = (obj, selectedItems) => {
    if (!this._mounted) return;
    this.setState({
      selectedItems: [...selectedItems],
    });
  };

  render() {
    const numGuests = this.props.location.getNumGuests();
    const config = API.getConfig();
    const { selectedItems } = this.state;
    const { orders, testID } = this.props;

    return (
      <View
        style={{ height: '100%' }}
        testID={testID}
      >
        <OrderListViewHeader />
        <FlatList
          style={{ paddingBottom: 10, height: 5 }}
          contentContainerStyle={{ flexGrow: 1 }}
          data={orders}
          renderItem={({ item }) => this.renderOrder(item, true)}
          keyExtractor={item => item.orderId}
          ListEmptyComponent={this.renderEmptyMessage}
        />
        <View>
          <OrderItemStatusChanger
            allowRegress
            selectedItems={selectedItems}
            onChange={this.props.refresh}
          />
          {/* TODO: Either All orders or selected Items */}
          <OrderTotals orders={orders} />
          <IconFooter>
            <RefundIconButton
              testID="ordersViewFooterButtonRefund"
              orders={orders}
              selectedItems={selectedItems}
              onSuccess={() => this._refreshSelectedItems(null, selectedItems)}
            />
            {!API.config.hide_seated_groups && (
              <IconButton
                testID="ordersViewFooterButtonGuests"
                icon="people"
                onPress={() => this.setState({ showNumGuestsChooser: true })}
                label="button__numGuests"
                values={{ numGuests }}
                defaultMessage={`Guest${numGuests !== 1 ? 's' : ''}: ${numGuests}`}
              />
            )}
            {!selectedItems.length && (
              <IconButton
                testID="ordersViewFooterButtonClear"
                icon={API.config.hide_seated_groups ? 'clear-all' : 'account-remove'}
                iconType={API.config.hide_seated_groups ? MaterialIcons : MaterialCommunityIcons}
                label="common__clear"
                onPress={this._clearTable}
                shown={!selectedItems.length}
              />
            )}
            {!!selectedItems.length && (
              <IconButton
                testID="ordersViewFooterButtonDeselect"
                icon="select-off"
                iconType={MaterialCommunityIcons}
                label="order__deselect"
                shown={!!selectedItems.length}
                onPress={() => this.setState({ selectedItems: [] })}
              />
            )}
            <IconButton
              testID="ordersViewFooterButtonReorder"
              icon="redo-alt"
              iconType={FontAwesome5}
              label="common__reOrder"
              shown={config?.show_create_order}
              disabled={!selectedItems.length}
              disabledMsg="Select at least 1 item"
              onPress={this._orderAgain}
            />
            <ClaimButton
              selectedItems={selectedItems}
              onPress={() => this._refreshSelectedItems(null, selectedItems)}
            />
          </IconFooter>
        </View>
        {/*= ======== Guests Chooser Modal =========== */}
        <KeypadModal
          testID="numberOfGuestsKeypadModal"
          title="Number of Guests?"
          minValue={this.props.location.getNumGuests() || 1}
          maxValue={36}
          value={this.props.location.getNumGuests() || 1}
          visible={this.state.showNumGuestsChooser}
          onClose={this._setNumGuests}
          onCancel={() => this.setState({ showNumGuestsChooser: false })}
        />
      </View>
    );
  }

  _orderAgain = () => {
    const { navigation, location } = this.props;
    const cart = new Cart({
      location_id: location.id,
    });
    API._carts = [cart];

    try {
      this.state.selectedItems.forEach(item => {
        if (!item.seat_numbers.length) {
          cart.addItem(item);
        } else {
          item.seat_numbers = [1];
          cart.addItem(item);
        }
      });
    } catch (err) {
      Alert.alert('Error', "Error re-ordering this item. Please use 'New Order' button.");
      return;
    }

    navigation.navigate('OrderCreator', {
      location,
      cart,
    });
  };

  _onSelectedItemsChange = (obj, selectedItems) => {
    this.setState({
      selectedItems: [...selectedItems],
    });
  };

  renderEmptyMessage = () => (
    <View
      style={{
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
      }}
    >
      <FormattedMessage
        id="common__noOrders"
        style={{ color: Colors.gray }}
      />
    </View>
  );

  renderOrder(order, show_groups = false) {
    return (
      <OrderListView
        key={order.orderId}
        order={order}
        onHeaderPress={this._onSelectedItemsChange}
        onItemPress={this._onSelectedItemsChange}
        refresh={this.props.refresh}
        selectedItems={this.state.selectedItems?.slice()}
      />
    );
  }

  clear = () => {
    this.setState({
      show_more: false,
      old_orders: [],
    });
  };

  _clearTable = async () => {
    const { location } = this.props;

    Alert.alert('Clear Table?', null, [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'Continue',
        onPress: async () => {
          this.setState({
            loading: true,
          });

          // this._locationOrdersView.clear();

          const response = await API.clearLocation(location);

          if (!this._mounted) return;
          // todo handle error state

          this.props.refresh();

          this.setState({
            // showNumGuestsChooser: true,
            loading: false,
            show_more: false,
            old_orders: [],
          });
        },
      },
    ]);
  };

  _goToOrderCreator = () => {
    const { navigation } = this.props;
    navigation.navigate('OrderCreator', {
      location: this.props.location,
      location_overview_key: navigation.state.key,
    });
  };

  _setNumGuests = async number => {
    if (!this._mounted) return;
    const { orders } = this.props;
    this.setState({
      loading: true,
      showNumGuestsChooser: false,
    });

    const response = await this.props.location.changeNumGuests(number);

    if (response.success) {
      this.setState({
        location: this.props.location,
        loading: false,
      });
      if (!orders.length && API.config?.show_create_order) {
        // If there's no items, let's go straight to the order creator:
        this._goToOrderCreator();
      }
    } else {
      if (!this._mounted) return;
      this.setState({
        loading: false,
        error: 'There was a problem communicating with the server',
      });
    }
  };
}
// TODO: Pass in Order Items, If there is an order Items length the iterate over the order Items and not the list of orders
function OrderTotals({ orders }) {
  const totals = {
    subTotal: 0,
    fees: 0,
    tax: 0,
    tip: 0,
    total: 0,
  };
  orders.forEach(order => {
    totals.subTotal += order.subtotal_cents;
    totals.fees += order.fee_pretax_cents;
    totals.tax += order.tax_cents;
    totals.tip += order.tip_cents;
    totals.total += order.total_cents;
  });
  const labelStyle = {
    flex: 1,
    color: Colors.darkGray,
    fontSize: 13,
    textAlign: 'right',
  };
  const totalStyle = { flex: 1, textAlign: 'right' };

  return (
    <View style={{ height: 45, paddingVertical: 5, paddingHorizontal: 10 }}>
      <View style={{ flexDirection: 'row' }}>
        {!API.main_customer.tax_inclusive_pricing && (
          <FormattedMessage
            style={labelStyle}
            id="common__subTotal"
          />
        )}
        {totals.fees > 0 && (
          <FormattedMessage
            style={labelStyle}
            id="common__fees"
          />
        )}
        <FormattedMessage
          style={labelStyle}
          id="common__tip"
        />
        <FormattedMessage
          style={labelStyle}
          id={API.main_customer.tax_inclusive_pricing ? 'tax__included' : 'common__tax'}
        />
        <FormattedMessage
          style={labelStyle}
          id="common__total"
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        {!API.main_customer.tax_inclusive_pricing && (
          <FormattedCurrency
            style={totalStyle}
            value={totals.subTotal / 100}
          />
        )}
        {totals.fees > 0 && (
          <FormattedCurrency
            style={totalStyle}
            value={totals.fees / 100}
          />
        )}
        <FormattedCurrency
          style={totalStyle}
          value={totals.tip / 100}
        />
        <FormattedCurrency
          style={totalStyle}
          value={totals.tax / 100}
        />
        <FormattedCurrency
          style={totalStyle}
          value={totals.total / 100}
        />
      </View>
    </View>
  );
}

OrdersView.propTypes = {};

export default OrdersView;

const styles = EStyleSheet.create({
  footer: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
    height: 60,
  },
});
