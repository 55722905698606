import React from 'react';
import { View, TouchableWithoutFeedback } from 'react-native';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import TabBarIcon from '../components/TabBarIcon';
import API from '../api';

import LocationStackNavigator from './LocationStackNavigator';
import OrdersStackNavigator from './OrdersStackNavigator';
import SettingsStackNavigator from './SettingsStackNavigator';
import StationsStackNavigator from './StationsStackNavigator';
import MenuManagement from '../screens/MenuManagement/MenuManagement';
import BotControlStack from './BotControlStack';
import { TipReportsStack } from './TipReportsStack';
import { KDSViewStack } from './KDSViewStack';
import { OrderControlStack } from './OrderControlStack';
import { TabsStackNavigator } from './TabsStackNavigator';
import Colors from '../constants/Colors';
import TabBarLabel from '../components/TabBarLabel';

MenuManagement.navigationOptions = ({ navigation }) => ({
  tabBarLabel: props => (
    <TabBarLabel
      {...props}
      labelPath="mainTabNavigator__menuManagement"
    />
  ),
  tabBarTestID: 'menu',
  tabBarIcon: props => (
    <TabBarIcon
      {...props}
      type={MaterialCommunityIcons}
      name="food"
    />
  ),
});

const MyTabNav = createBottomTabNavigator(
  {
    LocationStackNavigator,
    StationsStackNavigator,
    // TabsStackNavigator,
    KDSViewStack,
    TipReportsStack,
    OrdersStackNavigator,
    MenuManagement,
    OrderControlStack,
    BotControlStack,
    SettingsStackNavigator,
  },
  {
    // swipeEnabled: true,
    tabBarPosition: 'bottom',
    tabBarOptions: {
      activeTintColor: Colors.dark,
      activeBackgroundColor: '#FFF',
      inactiveBackgroundColor: '#000',
      inactiveTintColor: '#fff',
      style: {
        backgroundColor: '#000',
      },
    },
    backBehavior: 'none',
    defaultNavigationOptions: ({ navigation }) => ({
      tabBarButtonComponent: CustomTabButton,
    }),
  },
);

class CustomTabButton extends React.Component {
  componentDidMount() {
    this._mounted = true;
    this._listeners = [];
    this._listeners.push(API.on('config_updated', this._refresh));
    if (this.props.testID === 'botcontrol') {
      this._listeners.push(API.on('stations', this._refresh));
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this._listeners.forEach(l => l.remove());
  }

  _refresh = () => {
    if (!this._mounted) return;
    this.forceUpdate();
  };

  render() {
    const config = API.getConfig();
    if (!config) return null;
    const { onPress, onLongPress, testID, accessibilityLabel, ...props } = this.props;

    if (!config.hasScreenAccess(testID)) return null;

    return (
      <TouchableWithoutFeedback
        onPress={onPress}
        onLongPress={onLongPress}
        testID={testID}
        hitSlop={{
          left: 15,
          right: 15,
          top: 5,
          bottom: 5,
        }}
        accessible
        accessibilityLabel={`${testID}_tab`}
      >
        <View {...props} />
      </TouchableWithoutFeedback>
    );
  }
}

export default MyTabNav;
