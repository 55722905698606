import EStyleSheet from 'react-native-extended-stylesheet';

export const styles = EStyleSheet.create({
  fieldLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#575757',
  },
  textInputField: {
    backgroundColor: 'white',
    borderRadius: 5,
    width: '100%',
  },
  invalidFieldLabel: {
    color: '#C00',
  },
  errorField: {
    backgroundColor: '#ffeaea',
    borderWidth: 2,
    borderColor: '#C00',
    borderRadius: 5,
  },
});
