import moment from 'moment';
import _ from 'lodash';
import EventModel from './EventModel';

import { IconMap } from './SavedCard';

export default class PartyTab extends EventModel {
  /** @type {?string} */
  id = null;

  /** @type {('type' | string)} TODO enumerate the other values type can take */
  type = 'tab';

  tab_name = '';

  seated_group_id = null;

  default_card_id = null;

  default_tip = 0.0;

  /** @type {?moment.Moment} The time of creation of the Party Tab */
  start_date = null;

  /** @type {?moment.Moment} The time the Party Tab was closed */
  end_date = null;

  /** @type {?moment.Moment} The time of the last change to the Party Tab */
  last_modified = null;

  used_cents = 0;

  limit_cents = 0;

  available_cents = 0;

  card_brand = '';

  card_last_4 = '';

  card_exp_month = null;

  card_exp_year = null;

  tab_owner_secret = null;

  tab_type = 'consumer';

  locations = [];

  // internal properties:
  _field_map = {
    last_modified: d => moment(d),
    start_date: d => moment(d),
    end_date: d => moment(d),
  };

  _joinURL = null;

  _local = false;

  constructor(obj) {
    super(obj);
    this.update(obj);
  }

  get name() {
    return this.tab_name || `x${this.last4}`;
  }

  get brand() {
    return this.card_brand;
  }

  get last4() {
    return this.card_last_4;
  }

  get expiry() {
    return `${this.card_exp_month} / ${this.card_exp_year}`;
  }

  get icon() {
    const brand = this.card_brand?.toLowerCase();
    return IconMap.hasOwnProperty(brand) ? IconMap[brand] : IconMap.default;
  }

  get orders() {
    return _.filter(this.api._orders, order => order.party_tab_ids.includes(this.id));
  }

  get promos_pretax_cents_added() {
    return _.sumBy(this.orders, 'promos_pretax_cents_added');
  }

  get fee_pretax_cents() {
    return _.sumBy(this.orders, 'fee_pretax_cents');
  }

  get pre_tip_total() {
    const { include_fees, include_discounts, include_taxes } =
      this.api.main_customer.app_properties.tipping;

    let subtotal = _.sumBy(this.orders, 'subtotal_cents');
    if (include_discounts) subtotal += this.promos_pretax_cents_added;
    if (include_fees) subtotal += this.fee_pretax_cents;
    if (include_taxes) subtotal += this.tax_amount;

    return subtotal;
  }

  get pretax_total() {
    return _.sumBy(this.orders, 'pretax_cents');
  }

  get tax_amount() {
    return _.sumBy(this.orders, 'tax_cents');
  }

  get tip_amount() {
    return _.sumBy(this.orders, 'tip_cents');
  }

  get total() {
    return _.sumBy(this.orders, 'total_cents');
  }

  /**
   * Returns an object with information on how to charge this payment method.
   * @param {number} amount_cents The total cost to charge this payment method
   * @returns {Charge} The object with information on the current Charge on the Party Tab
   */
  getCharge(amount_cents) {
    return {
      id: this.id,
      tab_id: this.id,
      type: this.type,
      amount_cents,
    };
  }

  refresh = async () => {
    const response = await this.api.updateTab(this, {});
    return this;
  };
}
