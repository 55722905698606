import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import _ from 'lodash';
import { Text } from 'native-base';
import { SectionGrid } from 'react-native-super-grid';
import AsyncStorage from '@react-native-community/async-storage';
import { FormattedMessage } from 'react-native-globalize';
import { FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import LocationButton from '../components/LocationButton';
import API from '../api';
import Loader from '../components/Loader';
import ReaderStatus from '../components/ReaderStatus';
import HeaderIconButton from '../components/HeaderIconButton';
import { Location } from '../models';
import { SearchBar } from '../bbot-component-library';
import { escapeRegExp, sortAlphaNum } from '../helpers/HelperFunctions';
import Colors from '../constants/Colors';
import Alert from '../components/Alert';
import { LocationFilterModal } from '../components';
import NotificationToggle from '../components/TerminalNotifications/NotificationToggle';
import TranslatedButton from '../components/Translated/TranslatedButton';

const { width } = Dimensions.get('window');
const targetBtnSize = 137;

export default class LocationsScreen extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    title: (
      <FormattedMessage
        id="common__locations"
        defaultMessage="Locations"
      />
    ),
    headerRight: () => (
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <NotificationToggle showLabel />
        {API.config?.quick_order_location && (
          <HeaderIconButton
            testID="quick-order-entry-button"
            name="Quick Order"
            type={MaterialCommunityIcons}
            icon="food"
            accessibilityLabel="quick-order-button"
            onPress={() => navigation.navigate('POS')}
          />
        )}
        <HeaderIconButton
          testID="locationsFilterModalButton"
          textPath="button__filter"
          name="Filter"
          icon="filter"
          type={Ionicons}
          onPress={() => {
            navigation.getParam('showLocationsModal')();
          }}
        />
        <HeaderIconButton
          testID="locationsUserButton"
          textPath="common__currentUser"
          formatData={{ username: API?.currUser ? API.currUser.getName() : 'User' }}
          name={API?.currUser ? API.currUser.getName() : 'User'}
          icon="user"
          type={FontAwesome}
          accessibilityLabel="userBtn"
          onPress={() => {
            API.clearUser();
            navigation.navigate('Pin', {
              forwardTo: 'LocationsScreen',
            });
          }}
        />
        <ReaderStatus />
      </View>
    ),
  });

  constructor(props) {
    super(props);

    props.navigation.setParams({ showLocationsModal: this._showLocationsModal });

    this.state = {
      search: '',
      status: 'loading',
      zones: [],
      filteredZones: [],
      showModal: false,
      updated: false,
      orientation: 'portrait',
      columns: Math.floor(width / targetBtnSize) || 1,
      stickyHeaderIndices: [],
      locationsModal: false,
      selectedLocations: Object.keys(API._locations),
    };
  }

  componentDidMount() {
    this._mounted = true;

    this._debouncedRefresh = _.debounce(this._refresh, 50); // only run this once if it's called multiple times in a row.

    /** TODO: I think the only time we need to update this view is when the locations change.
     * Everything else should be handled internally by the LocationButton component.   */
    this._listeners = [
      API.on('locations', this._locationsUpdate),
      API.on('seated_groups', this._debouncedRefresh),
      API.on('smart_orders', this._debouncedRefresh),
    ];

    this._loadSelectedLocations();

    if (API.hasPolled) this._locationsUpdate(API.getLocations());
  }

  componentWillUnmount() {
    this._mounted = false;
    this._listeners.forEach(listener => listener?.remove());
  }

  _getZoneData = () => {
    let zone_data = [];
    _.forEach(Location.LocationsByZone(), (locations, zone) => {
      zone_data.push({
        title: zone,
        data: _.orderBy(
          locations,
          ['has_orders', 'has_guests', 'shortId'],
          ['desc', 'desc', (a, b) => sortAlphaNum(a, b)],
        ),
      });
    });
    zone_data = _.orderBy(zone_data, 'title');
    return {
      zones: zone_data,
      filteredZones: this._filteredLocations(this.state.search, zone_data),
    };
  };

  _showLocationsModal = () => {
    this.setState({
      locationsModal: true,
    });
  };

  _filteredLocations = (search, zones) => {
    if (!zones) zones = this.state.zones;
    const { selectedLocations } = this.state;
    const filteredLocations = [];

    search = escapeRegExp(search);
    const regEx = new RegExp(`${search}`, 'gi');
    zones.forEach(zone => {
      const items = zone.data.filter(
        i =>
          selectedLocations.includes(i.id) && `${i.locationName}|${i.shortId}`.search(regEx) !== -1,
      );
      if (items.length) filteredLocations.push({ title: zone.title, data: items });
    });
    if (!filteredLocations.length) {
      const title = search ? `No Results for '${search}'` : 'No Locations Selected';
      return [{ title, data: [], hideActions: true }];
    }
    return filteredLocations;
  };

  _locationsUpdate = locations => {
    const zoneData = this._getZoneData();
    if (this._mounted) {
      this.setState({
        status: locations.length ? 'good' : 'noResults',
        ...zoneData,
      });
    } else {
      console.log('trying to update unmounted component (LocationsScreen)');
    }
  };

  _refresh = () => {
    if (!this._mounted) return;
    const { updated } = this.state;
    const zoneData = this._getZoneData();
    this.setState({
      updated: !updated,
      ...zoneData,
    });
  };

  render() {
    // Todo: if(API.currentScreen !== 'LocationsScreen') return null;
    const { status, filteredZones, search, selectedLocations, locationsModal } = this.state;

    return (
      <View
        style={[styles.container]}
        testID="locationsScreen"
      >
        <View
          style={{ flex: 1 }}
          onLayout={this._onLayout}
        >
          <View style={{ backgroundColor: '#F2F2F2', flexDirection: 'row' }}>
            <SearchBar
              testID="locationsSearchInput"
              value={search}
              onChangeText={this._filter}
              onClear={() =>
                this.setState({ search: '', filteredZones: this._filteredLocations() })
              }
            />
          </View>
          {status === 'loading' && <Loader />}
          {status === 'noResults' && (
            <FormattedMessage
              id="LocationStack/LocationScreen/noLocations"
              defaultMessage="No Locations"
              style={{ margin: 10, fontWeight: 'bold' }}
            />
          )}
          <SectionGrid
            testID="locationsGridListing"
            itemDimension={150}
            spacing={10}
            sections={filteredZones}
            renderItem={this.renderItem}
            renderSectionHeader={this._renderZoneHeader}
            stickySectionHeadersEnabled
            extraData={search}
            style={{ flex: 1 }}
          />
        </View>
        {locationsModal && (
          <LocationFilterModal
            headerTitle="Select your tables"
            onCancel={() => this.setState({ locationsModal: false })}
            onSave={this._saveSelectedLocations}
            locations={selectedLocations}
          />
        )}
      </View>
    );
  }

  _onLayout = () => {
    const { width, height } = Dimensions.get('window');
    const numColumns = Math.floor(width / targetBtnSize) || 1;
    this.setState({
      columns: numColumns,
      orientation: width > height ? 'landscape' : 'portrait',
    });
  };

  _renderZoneHeader = ({ section: { title, hideActions } }) => (
    <View
      testID={`locationsZoneHeader-${_.capitalize(title)}`}
      style={styles.zoneHeader}
    >
      <View>
        <Text style={styles.zoneText}>{_.capitalize(title)}</Text>
      </View>
      {/* {title === 'Main Floor' && (<PriorityMenu>
              <PriorityMenu.Item value={'hello'}>Hello</PriorityMenu.Item>
              <PriorityMenu.Item value={'this'}>This</PriorityMenu.Item>
              <PriorityMenu.Item value={'is'}>Is A</PriorityMenu.Item>
              <PriorityMenu.Item value={'test'}>Test</PriorityMenu.Item>
            </PriorityMenu>)} */}
      {!hideActions && (
        <TranslatedButton
          variant="ghost"
          testID={`locationsZoneHeaderClearAllGuests-${_.capitalize(title)}`}
          disabled={this.state[`zone_${title}_clearing`]}
          onPress={() => this._clearZoneLocations(title)}
          style={{
            margin: 0,
            padding: 0,
            height: 20,
            color: Colors.primary,
          }}
          textPath={
            API.config.hide_seated_groups ? 'button__clearClosedOrders' : 'button__clearAllGuests'
          }
        />
      )}
    </View>
  );

  renderItem = ({ item, index }) => (
    <LocationButton
      location={item}
      onPress={this.onLocationPress}
      onLongPress={this.onLocationLongPress}
    />
  );

  onLocationPress = item => {
    this.props.navigation.navigate('LocationOverview', {
      location: item,
    });
  };

  onLocationLongPress = item => {
    const { navigate } = this.props.navigation;

    // For now, do nothing
    navigate('GuestManager', { location: item });
  };

  _filter = text => {
    this.setState({
      search: text,
      filteredZones: this._filteredLocations(text),
    });
  };

  _clearZoneLocations = async zone_for_reports => {
    const key = `zone_${zone_for_reports}_clearing`;
    this.setState({
      [key]: true,
    });
    // Confirm:
    Alert.alert('Confirm', 'Are you sure you want to clear all seated groups from this zone?', [
      {
        text: 'Cancel',
        style: 'cancel',
        onPress: () => {
          this.setState({ [key]: false });
        },
      },
      {
        text: 'Continue',
        onPress: async () => {
          const response = await API.clearZoneGuests(zone_for_reports);
          this.setState({ [key]: false });
        },
      },
    ]);
  };

  _saveSelectedLocations = locations => {
    API._selectedLocations = locations;
    AsyncStorage.setItem('selected_locations', JSON.stringify(locations));
    this.setState(
      {
        selectedLocations: locations,
        locationsModal: false,
      },
      this._refresh,
    );
  };

  _loadSelectedLocations = async () => {
    const selectedLocations = await AsyncStorage.getItem('selected_locations');
    if (selectedLocations) {
      this.setState({ selectedLocations: JSON.parse(selectedLocations) }, this._refresh);
      API._selectedLocations = selectedLocations;
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flex: 1,
    alignItems: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  zoneHeader: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  zoneText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});
