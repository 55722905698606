import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import API from '../../api';

class OrderTotals extends Component {
  render() {
    const { order } = this.props;
    return (
      <View
        key={`${order.orderId}-totals`}
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          margin: 10,
          flexDirection: 'row',
        }}
      >
        <View style={{ minWidth: 250, flex: 0.5 }}>
          <TotalsRow
            label="Discount"
            value={order.promos_pretax_cents_added}
          />
          <TotalsRow
            label="Service Charges"
            value={order.getTaxDeterminedFeeTotal()}
          />
          {!API.main_customer.tax_inclusive_pricing ? (
            <>
              <TotalsRow
                label="Subtotal"
                value={order.pretax_cents}
              />
              <View style={{ height: 16 }} />
              <TotalsRow
                label="Tax"
                showIfZero
                value={order.tax_cents}
              />
            </>
          ) : (
            <View style={{ height: 16 }} />
          )}
          <TotalsRow
            label="Tip"
            value={order.tip_cents}
          />
          <TotalsRow
            label="Order Total"
            showIfZero
            value={order.total_cents}
          />
          {API.main_customer.tax_inclusive_pricing && (
            <TotalsRow
              label="Tax Included in Total"
              showIfZero
              value={order.tax_cents}
            />
          )}
        </View>
      </View>
    );
  }
}

function TotalsRow({ label, value, showIfZero, size = 16, fontStyle, style }) {
  if (!value && !showIfZero) return null;
  if (!value) value = 0;

  return (
    <View style={[{ flexDirection: 'row', alignItems: 'center' }, style]}>
      <Text
        style={{ fontSize: 18, fontWeight: 'bold', flex: 1 }}
        numberOfLines={1}
      >
        {label}:{' '}
      </Text>
      <FormattedCurrency
        style={{ fontSize: size }}
        value={value / 100}
        numberStyle="accounting"
      />
    </View>
  );
}

OrderTotals.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderTotals;
