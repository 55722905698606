import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Platform, Linking, ActivityIndicator, TextInput } from 'react-native';
import { activateKeepAwake } from '@sayem314/react-native-keep-awake';
import { Icon, Text, Switch, Input, ScrollView, Select, FormControl, Row, View } from 'native-base';
import { hideMessage, showMessage } from 'react-native-flash-message';
import * as IntentLauncher from 'expo-intent-launcher';
import ReactNativeForegroundService from '@supersami/rn-foreground-service';
import {
  Entypo,
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  AntDesign,
} from '@expo/vector-icons';
import { Body, Left, Right, ListItem } from '../bbot-component-library';
import { styles } from '../styles/ListComponentStyle';

import API from '../api';
import Alert from '../components/Alert';
import { show, hide } from '../components/NotificationBanner';
import PinPadModal from '../components/PinPad/PinPadModal';
import { prompt } from '../components/Prompt/GlobalPrompt';
import Colors from '../constants/Colors';
import {
  SHOW_SERVER_CHOOSER,
  getSentryEnv,
  IS_ELO,
  SHOW_LOCALE_CHOOSER,
} from '../constants/Config';
import { URLS } from '../constants/Constants';
import PollerService from '../services/PollerService';
import { checkAccess } from '../helpers/Access';

import WifiStatus from '../components/SettingsScreen/WifiStatus';
import PollInterval from '../components/SettingsScreen/PollInterval';
import StorageStatus from '../components/SettingsScreen/StorageStatus';
import KDSOrdersView from './KDSOrdersView';
import { switchLocale } from '../i18n/localeLoader';
import LANGUAGES from '../i18n/languages';
import LocalePicker from '../components/LocalePicker';

const { version } = API;

export default class SettingsScreen extends React.Component {
  static navigationOptions = {
    title: 'Settings',
  };

  devModeCount = 0;

  constructor(props) {
    super(props);

    this.state = {
      menuUpdateInfo: '',
      fullUpdateInfo: '',
      bundleUpdate: '',
      resetDefaultsInfo: '',
      prefsUpdating: {},
      showDev: false,
      simulateError: false,
      requestHeaders: null,
      kds_min_ticket_height: API.handheldDevice.getPreference('kds_min_ticket_height'),
    };

    this._loadSettings();
  }

  _loadSettings = async () => {
    const account = await API.getAccount();

    this.setState({
      account,
      kds_idle_screen: API.handheldDevice.getPreference('kds_idle_screen'),
      kds_autofocus: API.handheldDevice.getPreference('kds_autofocus'),
      keep_awake_on_battery: API.handheldDevice.getPreference('keep_awake_on_battery'),
      kds_show_waiting: API.handheldDevice.getPreference('kds_show_waiting'),
      kds_always_advance_ticket_on_tap: API.handheldDevice.getPreference(
        'kds_always_advance_ticket_on_tap',
      ),
      kds_show_item_status: API.handheldDevice.getPreference('kds_show_item_status'),
      kds_hide_checkout_info: API.handheldDevice.getPreference('kds_hide_checkout_info'),
      kds_status_advance_top: API.handheldDevice.getPreference('kds_status_advance_top'),
      kds_min_ticket_height: API.handheldDevice.getPreference('kds_min_ticket_height') || '',
      kds_use_high_contrast: API.handheldDevice.getPreference('kds_use_high_contrast'),
      kds_all_day_grouping_rule: API.handheldDevice.getPreference('kds_all_day_grouping_rule'),
      kds_all_day_flash_screen: API.handheldDevice.getPreference('kds_all_day_flash_screen'),
      quick_order_mode: API.handheldDevice.getPreference('quick_order_mode'),
      notifications: API.notifications,
      showLanguagePicker: false,
      device_language: switchLocale(API.deviceLocale),
    });
  };

  componentDidMount() {
    this._mounted = true;
    // this.expoUpdateListener = Updates.addListener(this._expoUpdateEventListener);

    API.handheldDevice.on('update', this._forceUpdate);

    this._focusListener = this.props.navigation.addListener('didFocus', this.didFocus);
    this._blurListener = this.props.navigation.addListener('didBlur', this.didBlur);
  }

  didFocus = () => {
    this.setState({ focus: true });
  };

  didBlur = () => {
    this.setState({ focus: false });
  };

  componentWillUnmount() {
    this._mounted = false;
    this._focusListener?.remove();
    this._blurListener?.remove();

    // if (this.expoUpdateListener) this.expoUpdateListener.remove();
    API.handheldDevice.off('update', this._forceUpdate);
  }

  _forceUpdate = () => {
    this.forceUpdate();
  };

  render() {
    const { config } = API;
    if (!config) return null;

    const { showLanguagePicker, device_language } = this.state;

    return (
      <ScrollView>
        <ListItem itemDivider>
          <Text variant="divider">Configuration</Text>
        </ListItem>
        <ListItem onPress={this._devicePressed}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={MaterialIcons}
              name="perm-device-information"
            />
          </Left>
          <Body justifyLeft>
            <Text>Device</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{API.handheldDevice.name}</Text>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>
        <ListItem onPress={this._goToConfigChooser}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Ionicons}
              name="ios-cog"
            />
          </Left>
          <Body justifyLeft>
            <Text>Device Profile</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{config.config_name}</Text>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>
        <ListItem onPress={this._doMenuUpdate}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={MaterialIcons}
              name="restaurant-menu"
            />
          </Left>
          <Body justifyLeft>
            <Text>Update Menu</Text>
          </Body>
          <Right onListItem>
            {this.state.menuUpdateInfo ? (
              <Text style={styles.note}>{this.state.menuUpdateInfo}</Text>
            ) : (
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            )}
          </Right>
        </ListItem>
        <ListItem onPress={this._doFullUpdate}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={MaterialIcons}
              name="refresh"
            />
          </Left>
          <Body justifyLeft>
            <Text>Refresh All Data</Text>
          </Body>
          <Right onListItem>
            {this.state.fullUpdateInfo ? (
              <Text style={styles.note}>{this.state.fullUpdateInfo}</Text>
            ) : (
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            )}
          </Right>
        </ListItem>

        {config?.allow_disabling_chirp && (
          <ListItem>
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={Ionicons}
                name="ios-volume-high"
              />
            </Left>
            <Body justifyLeft>
              <Text>Play Notification Sounds</Text>
            </Body>
            <Right onListItem>
              <Switch
                value={this.state.notifications}
                onValueChange={this._toggleNotifications}
              />
            </Right>
          </ListItem>
        )}

        {Platform.OS === 'web' && document?.fullscreenEnabled && (
          <ListItem onPress={this.toggleFullscreen}>
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={MaterialIcons}
                name="fullscreen"
              />
            </Left>
            <Body justifyLeft>
              <Text>Toggle FullScreen</Text>
            </Body>
            <Right onListItem>
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            </Right>
          </ListItem>
        )}

        {Platform.OS === 'android' && (
          <ListItem>
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={MaterialIcons}
                name="wb-sunny"
              />
            </Left>
            <Body justifyLeft>
              <Text>Keep Awake On Battery</Text>
            </Body>
            <Right onListItem>
              {this.state.prefsUpdating.keep_awake_on_battery ? (
                <ActivityIndicator
                  size="small"
                  color={Colors.primary}
                />
              ) : (
                <Switch
                  value={this.state.keep_awake_on_battery}
                  onValueChange={val => this._updatePref('keep_awake_on_battery', val)}
                />
              )}
            </Right>
          </ListItem>
        )}

        {SHOW_LOCALE_CHOOSER && (
          <>
            <ListItem
              key="show_locale_picker"
              onPress={() => this.setState({ showLanguagePicker: true })}
            >
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={Ionicons}
                  name="globe-outline"
                />
              </Left>
              <Body justifyLeft>
                <Text>Device Language</Text>
                <Text
                  style={styles.note}
                  numberOfLines={1}
                >
                  Experimental feature. Not fully supported.
                </Text>
              </Body>
              <Right onListItem>
                {device_language && (
                  <Text style={styles.note}>{LANGUAGES[device_language].locale__name}</Text>
                )}
              </Right>
            </ListItem>
            {showLanguagePicker && (
              <LocalePicker
                selectedValue={device_language}
                visible
                onHideCallback={this._closeLocalePicker}
                onItemPress={this._selectLocale}
              />
            )}
          </>
        )}

        <ListItem icon>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={MaterialCommunityIcons}
              name="clock-fast"
            />
          </Left>
          <Body justifyLeft>
            <Text>Quick Order Mode</Text>
            <Text style={styles.note}>
              If enabled, will reroute back to the `New Order` screen after a successful checkout
              instead of the main Location overview screen
            </Text>
          </Body>
          <Right onListItem>
            {this.state.prefsUpdating.quick_order_mode ? (
              <ActivityIndicator
                size="small"
                color={Colors.primary}
              />
            ) : (
              <Switch
                value={this.state.quick_order_mode}
                onValueChange={val => this._updatePref('quick_order_mode', val)}
              />
            )}
          </Right>
        </ListItem>

        {config?.kds_stations?.length > 0 && (
          <>
            <ListItem itemDivider>
              <Text variant="divider">KDS View</Text>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialCommunityIcons}
                  name="target"
                />
              </Left>
              <Body justifyLeft>
                <Text>Auto-Focus Current Orders</Text>
                <Text style={styles.note}>
                  Focus KDS Current Orders view after 3 minutes of no interaction and there are new
                  orders
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_autofocus ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_autofocus}
                    onValueChange={val => this._updatePref('kds_autofocus', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialCommunityIcons}
                  name="dog"
                />
              </Left>
              <Body justifyLeft>
                <Text>KDS Idle Images</Text>
                <Text style={styles.note}>
                  Show images of animals on the KDS view when there are no orders.
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_idle_screen ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_idle_screen}
                    onValueChange={val => this._updatePref('kds_idle_screen', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialCommunityIcons}
                  name="file-hidden"
                />
              </Left>
              <Body justifyLeft>
                <Text>Always show ticket items</Text>
                <Text style={styles.note}>
                  By default, ticket items are hidden until the ticket is tapped
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_show_waiting ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_show_waiting}
                    onValueChange={val => this._updatePref('kds_show_waiting', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialCommunityIcons}
                  name="arrow-right-circle"
                />
              </Left>
              <Body justifyLeft>
                <Text>Always advance tickets on tap</Text>
                <Text style={styles.note}>
                  If true, tapping anywhere on a ticket other than the header will advance its
                  status
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_always_advance_ticket_on_tap ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_always_advance_ticket_on_tap}
                    onValueChange={val => this._updatePref('kds_always_advance_ticket_on_tap', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={Entypo}
                  name="progress-two"
                />
              </Left>
              <Body justifyLeft>
                <Text>Show individual item status</Text>
                <Text style={styles.note}>
                  If true, each item will have a status indicator on KDS tickets
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_show_item_status ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_show_item_status}
                    onValueChange={val => this._updatePref('kds_show_item_status', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialIcons}
                  name="info"
                />
              </Left>
              <Body justifyLeft>
                <Text>Hide Extra Checkout Info</Text>
                <Text
                  style={styles.note}
                  numberOfLines={1}
                >
                  Hide extra checkout info on Tickets
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_hide_checkout_info ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_hide_checkout_info}
                    onValueChange={val => this._updatePref('kds_hide_checkout_info', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={Entypo}
                  name="align-top"
                />
              </Left>
              <Body justifyLeft>
                <Text>Status Advance at Top</Text>
                <Text style={styles.note}>
                  Show the Status Advance button at the top of tickets
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_status_advance_top ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_status_advance_top}
                    onValueChange={val => this._updatePref('kds_status_advance_top', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialIcons}
                  name="vertical-split"
                />
              </Left>
              <Body justifyLeft>
                <Text>Split Items by Modifier on All Day View</Text>
                <Text style={styles.note}>Split menu items if they contain modifiers</Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_all_day_grouping_rule ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_all_day_grouping_rule}
                    onValueChange={val => this._updatePref('kds_all_day_grouping_rule', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialIcons}
                  name="highlight"
                />
              </Left>
              <Body justifyLeft>
                <Text>Flash Screen on All Day View</Text>
                <Text style={styles.note}>
                  Set screen to flash after inactivity on All Day View
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_all_day_flash_screen ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_all_day_flash_screen}
                    onValueChange={val => this._updatePref('kds_all_day_flash_screen', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialIcons}
                  name="palette"
                />
              </Left>
              <Body justifyLeft>
                <Text>High Contrast Colors</Text>
                <Text style={styles.note}>
                  Use high contrast color set for fulfillment method headings
                </Text>
              </Body>
              <Right onListItem>
                {this.state.prefsUpdating.kds_use_high_contrast ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Switch
                    value={this.state.kds_use_high_contrast}
                    onValueChange={val => this._updatePref('kds_use_high_contrast', val)}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialCommunityIcons}
                  name="table-row-height"
                />
              </Left>
              <Body justifyLeft>
                <Text>Minimum Ticket Height</Text>
                <Text
                  style={styles.note}
                  numberOfLines={1}
                >
                  Specifies the minimum height of KDS Tickets
                </Text>
              </Body>
              <Right
                onListItem
                style={{ width: 80, justifyContent: 'flex-end' }}
              >
                {this.state.prefsUpdating.kds_min_ticket_height ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Input
                    w={75}
                    style={{
                      fontSize: 16,
                      borderWidth: 1,
                      height: 35,
                      borderRadius: 5,
                      textAlign: 'right',
                      backgroundColor: 'white',
                      padding: 0,
                    }}
                    textAlign="right"
                    variant="unstyled"
                    placeholder={KDSOrdersView.MIN_TICKET_HEIGHT?.toString()}
                    keyboardType="numeric"
                    value={this.state.kds_min_ticket_height?.toString()}
                    onChangeText={text => {
                      this.setState({ kds_min_ticket_height: text.replace(/[^0-9]/g, '') });
                    }}
                    onBlur={({ nativeEvent }) => {
                      this._updatePref('kds_min_ticket_height', this.state.kds_min_ticket_height);
                    }}
                  />
                )}
              </Right>
            </ListItem>
            <ListItem>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialIcons}
                  name="notes"
                />
              </Left>
              <Body justifyLeft>
                <Text>KDS Ticket Item Appearance</Text>
                <Text style={styles.note}>
                  {API.handheldDevice.getPreference('kds_ticket_item_appearance') === 'default'
                    ? 'Ticket items grouped by quantity'
                    : 'Each ticket item displayed individually'}
                </Text>
              </Body>
              <Right
                onListItem
                style={{ width: 80, justifyContent: 'flex-end' }}
              >
                {this.state.prefsUpdating.kds_ticket_item_appearance ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.primary}
                  />
                ) : (
                  <Select
                    w={120}
                    style={{ height: 35, borderWidth: 0, fontSize: 16 }}
                    selectedValue={API.handheldDevice.getPreference('kds_ticket_item_appearance')}
                    onValueChange={value => this._updatePref('kds_ticket_item_appearance', value)}
                  >
                    {API.handheldDevice.possible_kds_ticket_item_appearance_options.map(option => (
                      <Select.Item
                        key={option.value}
                        label={option.name}
                        value={option.value}
                      />
                    ))}
                  </Select>
                )}
              </Right>
            </ListItem>
            <ListItem onPress={this._resetDefaultPrefs}>
              <Left icon>
                <Icon
                  size={8}
                  style={styles.iconStyle}
                  as={MaterialIcons}
                  name="refresh"
                />
              </Left>
              <Body justifyLeft>
                <Text>Reset To Defaults</Text>
                <Text style={styles.note}>
                  Tap here to reset preferences to defaults from Device Profile.
                </Text>
              </Body>
              <Right onListItem>
                {this.state.resetDefaultsInfo ? (
                  <Text style={styles.note}>{this.state.resetDefaultsInfo}</Text>
                ) : (
                  <Icon
                    name="chevron-right"
                    as={Entypo}
                  />
                )}
              </Right>
            </ListItem>
          </>
        )}

        <ListItem itemDivider>
          <Text variant="divider">Info</Text>
        </ListItem>

        <ListItem onPress={() => {}}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Feather}
              name="clock"
            />
          </Left>
          <Body justifyLeft>
            <Text>Timezone</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{API.main_customer?.timezone}</Text>
          </Right>
        </ListItem>

        <StorageStatus focus={this.state.focus} />

        {Platform.OS === 'android' && <WifiStatus focus={this.state.focus} />}

        {Platform.OS === 'android' && !IS_ELO && (
          <ListItem
            onPress={() => {
              IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.DEVICE_INFO_SETTINGS);
            }}
          >
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={Feather}
                name="info"
              />
            </Left>
            <Body justifyLeft>
              <Text>Device Info</Text>
            </Body>
            <Right onListItem>
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            </Right>
          </ListItem>
        )}

        {Platform.OS === 'android' && (
          <ListItem
            onPress={() => {
              try {
                IntentLauncher.startActivityAsync(
                  IntentLauncher.ActivityAction.ACCESSIBILITY_SETTINGS,
                );
              } catch (err) {
                showMessage({
                  message: 'Failed launching Accessibility Settings',
                });
              }
            }}
          >
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={FontAwesome}
                name="universal-access"
              />
            </Left>
            <Body justifyLeft>
              <Text>Accessibility Settings</Text>
            </Body>
            <Right onListItem>
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            </Right>
          </ListItem>
        )}

        <ListItem itemDivider>
          <Text variant="divider">Bulk Actions</Text>
        </ListItem>

        <ListItem onPress={this._closeOpenOrders}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Feather}
              name="check-circle"
            />
          </Left>
          <Body justifyLeft>
            <Text>Close Open Orders</Text>
          </Body>
          <Right onListItem>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>

        <ListItem onPress={this._clearClosedOrders}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={MaterialCommunityIcons}
              name="notification-clear-all"
            />
          </Left>
          <Body justifyLeft>
            <Text>Clear Closed Orders</Text>
          </Body>
          <Right onListItem>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>

        <ListItem itemDivider>
          <Text variant="divider">Build Info</Text>
        </ListItem>

        <ListItem onPress={this._releaseChannelPressed}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Feather}
              name="git-branch"
            />
          </Left>
          <Body justifyLeft>
            <Text>Version</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{`${Platform.OS === 'web' ? 'Web' : 'Android'} ${
              version || 'dev'
            }`}</Text>
          </Right>
        </ListItem>

        <ListItem onPress={() => SHOW_SERVER_CHOOSER && this._navigate('ServerChooserSettings')}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={FontAwesome}
              name="server"
            />
          </Left>
          <Body justifyLeft>
            <Text>Server</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{axios.defaults.baseURL}</Text>
            {SHOW_SERVER_CHOOSER && (
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            )}
          </Right>
        </ListItem>

        <ListItem onPress={() => this._navigate('Changelog')}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Octicons}
              name="versions"
            />
          </Left>
          <Body justifyLeft>
            <Text>Changelog</Text>
          </Body>
          <Right onListItem>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>

        <ListItem itemDivider>
          <Text variant="divider">Support</Text>
        </ListItem>

        <ListItem onPress={this._emailSupport}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Entypo}
              name="email"
            />
          </Left>
          <Body justifyLeft>
            <Text>E-mail Us</Text>
          </Body>
          <Right onListItem>
            <Text style={[styles.note, { color: 'blue' }]}>support@bbot.menu</Text>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>

        {API.is_admin && (
          <ListItem onPress={() => this._navigate('CustomerChooserSettings')}>
            <Left icon>
              <Icon
                size={8}
                style={styles.iconStyle}
                as={MaterialCommunityIcons}
                name="account-switch"
              />
            </Left>
            <Body justifyLeft>
              <Text>Change Customer</Text>
            </Body>
            <Right onListItem>
              <Text style={styles.note}>{API.main_customer.customer_name}</Text>
              <Icon
                name="chevron-right"
                as={Entypo}
              />
            </Right>
          </ListItem>
        )}

        <ListItem onPress={this._logout}>
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={Entypo}
              name="log-out"
            />
          </Left>
          <Body justifyLeft>
            <Text>Logout</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{this.state.account}</Text>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>

        <PollInterval focus={this.state.focus} />

        {this._getDevMenu()}

        {this.state.simulateError && <MissingComponentWhichSimulatesUncaughtError />}
      </ScrollView>
    );
  }

  _devicePressed = async () => {
    const hasAccess = await checkAccess('change_device', true);
    if (hasAccess) {
      this._navigate('DeviceRegistrationSettings', { stayAfterSave: true });
    }
  };

  _goToConfigChooser = async () => {
    const hasAccess = await checkAccess('change_profile', true);
    if (hasAccess) {
      API.lastPin = hasAccess.pin_number;
      this._navigate('ConfigChooserSettings', { forwardTo: 'SettingsScreen' });
    }
  };

  _getDevMenu = () => {
    if (!this.state.devMode) return null;
    return [
      <ListItem
        itemDivider
        key="dev_title"
      >
        <Text variant="divider">Developer Menu</Text>
      </ListItem>,
      <ListItem key="dev_0">
        <Left icon>
          <Icon
            styles={styles.iconStyle}
            as={MaterialIcons}
            name="desktop-mac"
          />
        </Left>
        <Body justifyLeft>
          <Text>Environment</Text>
        </Body>
        <Right onListItem>
          <Text style={styles.note}>{getSentryEnv()}</Text>
        </Right>
      </ListItem>,
      Platform.OS === 'web' && (
        <ListItem key="dev_build">
          <Left icon>
            <Icon
              styles={styles.iconStyle}
              as={MaterialIcons}
              name="desktop-mac"
            />
          </Left>
          <Body justifyLeft>
            <Text>Build Time</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{__DEV__ ? 'DEVBUILD' : __BUILDTIME__}</Text>
          </Right>
        </ListItem>
      ),
      Platform.OS === 'android' && (
        <ListItem
          key="check_poller"
          onPress={() => {
            if (!ReactNativeForegroundService.is_running()) {
              Alert.alert('Service Error', 'The foreground service is not running', [
                {
                  text: 'START NOW',
                  onPress: () => {
                    API._pollerService = new PollerService(API.taskPoll);
                  },
                },
              ]);
            } else if (!ReactNativeForegroundService.is_task_running('poller')) {
              Alert.alert('Task Error', 'The poller task is not running', [
                {
                  text: 'START NOW',
                  onPress: () => {
                    API._pollerService = new PollerService(API.taskPoll);
                  },
                },
              ]);
            } else {
              Alert.alert('Success', 'Everything appears to be running normally');
            }
          }}
        >
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={AntDesign}
              name="android"
            />
          </Left>
          <Body justifyLeft>
            <Text>Check Poller Status</Text>
          </Body>
          <Right onListItem>
            <Icon
              name="chevron-right"
              as={Entypo}
            />
          </Right>
        </ListItem>
      ),
      Platform.OS === 'android' && (
        <ListItem key="mdm">
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={AntDesign}
              name="android"
            />
          </Left>
          <Body justifyLeft>
            <Text>MDM</Text>
          </Body>
          <Right onListItem>
            <Text style={styles.note}>{API.handheldDevice.mdm}</Text>
          </Right>
        </ListItem>
      ),
      Platform.OS === 'android' && !!global.HermesInternal && (
        <ListItem key="hermes">
          <Left icon>
            <Icon
              size={8}
              style={styles.iconStyle}
              as={AntDesign}
              name="android"
            />
          </Left>
          <Body justifyLeft>
            <Text>Hermes Enabled!</Text>
          </Body>
          <Right onListItem />
        </ListItem>
      ),
      <ListItem
        key="force_pdf"
        onPress={() => {
          API.config.force_pdf_printing = !API.config.force_pdf_printing;
        }}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={FontAwesome}
            name="print"
          />
        </Left>
        <Body justifyLeft>
          <Text>Toggle Force PDF Printing</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="show_recall"
        onPress={() => {
          API.config.kds_show_recall_button = true;
        }}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={FontAwesome}
            name="undo"
          />
        </Left>
        <Body justifyLeft>
          <Text>Allow Recall Button on KDS</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_2"
        onPress={this._testError}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="bug"
          />
        </Left>
        <Body justifyLeft>
          <Text>Test Error Handler</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_2-5"
        onPress={this._testUncaughtError}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="bug"
          />
        </Left>
        <Body justifyLeft>
          <Text>Test Uncaught Error</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_3"
        onPress={this._testShowMessage}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="bug"
          />
        </Left>
        <Body justifyLeft>
          <Text>Test Show Message</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_4"
        onPress={this._testShowBanner}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="bug"
          />
        </Left>
        <Body justifyLeft>
          <Text>Test Show Banner</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_5"
        onPress={API.playNotificationSound}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="sound"
          />
        </Left>
        <Body justifyLeft>
          <Text>Play Notification Sound</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_6"
        onPress={this._testShowHeaders}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="bug"
          />
        </Left>
        <Body justifyLeft>
          <Text>Show Request Headers</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      <ListItem
        key="dev_1"
        onPress={() => {
          this.props.navigation.navigate('GPSDebug');
        }}
      >
        <Left icon>
          <Icon
            size={8}
            style={styles.iconStyle}
            as={Entypo}
            name="hair-cross"
          />
        </Left>
        <Body justifyLeft>
          <Text>GPS Debug</Text>
        </Body>
        <Right onListItem>
          <Icon
            name="chevron-right"
            as={Entypo}
          />
        </Right>
      </ListItem>,
      this.state.requestHeaders ? (
        <ListItem key="requestHeaders">
          <Body justifyLeft>
            <TextInput
              multiline
              numberOfLines={10}
            >
              {this.state.requestHeaders}
            </TextInput>
          </Body>
        </ListItem>
      ) : null,
    ];
  };

  _navigate = (screen, params) => {
    this.props.navigation.navigate(screen, params);
  };

  _startUpdate = async (key, fn) => {
    this.setState({ [key]: 'Updating...' });
    await fn();
    this.setState({ [key]: 'Success!' });
    setTimeout(() => {
      if (this._mounted) this.setState({ [key]: '' });
    }, 3000);
  };

  _doMenuUpdate = async () => {
    await this._startUpdate('menuUpdateInfo', API.doMenuUpdate);
  };

  _doFullUpdate = async () => {
    await this._startUpdate('fullUpdateInfo', () => API.doMenuUpdate(true));
  };

  _resetDefaultPrefs = async () => {
    Alert.alert(
      'Reset Default Preferences',
      'Are you sure you want to reset the default preferences?',
      [
        {
          text: 'Cancel',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: 'OK',
          onPress: () => {
            this._startUpdate('resetDefaultsInfo', async () => {
              await API.handheldDevice.resetToDefaults();
              await this._loadSettings();
            });
          },
        },
      ],
    );
  };

  _toggleNotifications = () => {
    API.notifications = !API.notifications;
    this.setState({
      notifications: API.notifications,
    });
  };

  _updatePref = async (key, val) => {
    const { prefsUpdating } = this.state;
    prefsUpdating[key] = true;
    this.setState({ prefsUpdating });
    await API.handheldDevice.updatePrefs({ [key]: val });
    prefsUpdating[key] = false;
    this.setState({
      [key]: API.handheldDevice.getPreference(key),
      prefsUpdating,
    });
    if (key === 'keep_awake_on_battery') activateKeepAwake();
  };

  _logout = async () => {
    await API.logout();
    this._navigate('Login');
  };

  _testError = () => {
    try {
      unknownFunction();
    } catch (err) {
      API.sendCaughtError(err);
    }
    showMessage({
      message: 'Error triggered! It should appear in Sentry shortly.',
      autoHide: true,
      backgroundColor: 'red',
      duration: 5000,
    });
  };

  _testUncaughtError = () => {
    this.setState({
      simulateError: true,
    });
  };

  _clearClosedOrders = () => {
    API.clearClosedOrders();

    showMessage({
      message: 'Closed orders have been cleared',
      position: 'top',
      floating: true,
      type: 'success',
    });
    API.trigger('orders');
  };

  _selectLocale = async val => {
    if (await API.setDeviceLocale(val)) {
      this.setState({
        device_language: val,
      });
    }
    this.setState({
      showLanguagePicker: false,
    });
  };

  _closeLocalePicker = () => this.setState({ showLanguagePicker: false });

  _closeOpenOrders = () => {
    this.setState({ closingOrders: true });

    PinPadModal.show(
      () => {
        prompt({
          title: 'Close orders older than',
          placeholder: '(number of hours)',
          suffix: 'hours old',
          onSubmit: hours_txt => {
            let hours = '';
            if (hours_txt.includes(':')) {
              hours = moment.duration(hours_txt).asHours();
            } else {
              hours = parseFloat(hours_txt);
            }
            if (isNaN(hours)) {
              Alert.alert(
                'Invalid number',
                `"${hours_txt}" does not appear to be a valid number. Please try again`,
              );
            } else {
              const description = hours > 0 ? `older than ${hours} hours old` : '';
              Alert.alert(
                'Are you sure?',
                `All open orders ${description} will be set to their closed status. Guests will NOT be notified`,
                [
                  {
                    text: 'Cancel',
                    style: 'cancel',
                  },
                  {
                    text: 'Continue',
                    onPress: async () => {
                      const response = await API.closeOpenOrders(hours);

                      if (response.success) {
                        const message =
                          response.orders_closed > 0
                            ? `Successfully closed ${response.orders_closed} orders`
                            : 'No open orders found';
                        showMessage({
                          message,
                          type: 'success',
                          position: 'top',
                        });
                      } else {
                        showMessage({
                          message: response.error,
                          type: 'error',
                          position: 'top',
                        });
                      }
                    },
                  },
                ],
              );
            }
          },
        });
      },
      () => {
        this.setState({ closingOrders: false });
      },
    );
  };

  toggleFullscreen = () => {
    if (document.fullscreenElement) closeFullscreen();
    else openFullscreen();
  };

  _releaseChannelPressed = () => {
    this.devModeCount++;
    if (this.devModeCount === 7) this.setState({ devMode: true });
  };

  _emailSupport = () => {
    const url = 'mailto:support@bbot.menu?subject=Terminal%20Support';
    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else {
      Linking.openURL(url);
    }
  };

  _testShowMessage = () => {
    if (!this._messageShown) {
      this._messageShown = true;
      showMessage({
        floating: true,
        position: 'top',
        autoHide: false,
        hideOnPress: false,
        message: 'This is not dismissable',
      });
    } else {
      this._messageShown = false;
      hideMessage();
    }
  };

  _testShowBanner = () => {
    if (!this._bannerShown) {
      this._bannerShown = true;
      show({
        floating: true,
        position: 'top',
        autoHide: false,
        hideOnPress: false,
        message: 'This is not dismissable',
      });
    } else {
      this._bannerShown = false;
      hide();
    }
  };

  _testShowHeaders = async () => {
    try {
      const result = await axios.get(URLS.DEBUG.SHOW_HEADERS);
      this.setState({
        requestHeaders: result.data,
      });
    } catch (err) {}
  };
}

/* View in fullscreen */
export const openFullscreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/* Close fullscreen */
export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
};
