import React from 'react';
import _ from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import moment from 'moment';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback, Platform } from 'react-native';
import { Icon, Button } from 'native-base';
import { showMessage } from 'react-native-flash-message';
import { FormattedCurrency, FormattedMessage } from 'react-native-globalize';
import { MaterialIcons } from '@expo/vector-icons';
import API from '../api';

// Components
import { GroupedItems, OrderItem } from './index';
import EditOrderModal from './Modals/EditOrderModal';
import OrderCheckoutInfo from './Order/OrderCheckoutInfo';
import OrderStaffNotes from './Order/OrderStaffNotes';

// Styles
import { Colors } from '../styles';

export default class OrderListView extends React.Component {
  static propTypes = {
    showTotal: PropTypes.bool,
    showTaxes: PropTypes.bool,
    collapsible: PropTypes.bool,
    startCollapsed: PropTypes.bool,
    selectedItems: PropTypes.array,
    onHeaderPress: PropTypes.func,
    onItemPress: PropTypes.func,
    refresh: PropTypes.func,
  };

  static defaultProps = {
    startCollapsed: false,
    showTotal: false,
    showTaxes: false,
    selectedItems: [],
    onHeaderPress: () => {},
    onItemPress: () => {},
    refresh: () => {},
  };

  constructor(props) {
    super(props);
    const { order, selectedItems } = props;

    this.state = {
      all_selected: _.intersection(order.items, selectedItems).length === order.items.length,
      showEditOrderModal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      this.setState({
        all_selected:
          _.intersection(this.props.order.items, this.props.selectedItems).length ===
          this.props.order.items.length,
      });
    }
  }

  render() {
    const { order, showTotal } = this.props;
    const { all_selected, showEditOrderModal } = this.state;
    const bartenderName = getBartenderName(order.bartender_id);
    return (
      <View style={styles.orderSection}>
        <OrderHeader
          order={order}
          allSelected={all_selected}
          onPress={this._headerPress}
        />
        <View>{this._getGroupedItems()}</View>
        {showTotal && <OrderFooter order={order} />}
        <View style={[styles.editOrderFooter]}>
          {!!bartenderName && (
            <View>
              <Text style={styles.bartenderNameLabel}>Server:</Text>
              <Text style={styles.bartenderName}>{getBartenderName(order.bartender_id)}</Text>
            </View>
          )}
          <View />
          <Button
            icon
            variant="ghost"
            onPress={this._toggleModal}
          >
            <Icon
              as={MaterialIcons}
              name="edit"
              style={{ fontSize: 18, color: Colors.primary }}
            />
          </Button>
        </View>
        {showEditOrderModal && (
          <EditOrderModal
            visible
            order={order}
            onSave={this._editOrder}
            onCancel={this._toggleModal}
          />
        )}
      </View>
    );
  }

  _editOrder = async fieldsToEdit => {
    const result = await API.modifyOrder(this.props.order, fieldsToEdit);
    const message = result?.success
      ? 'Changes saved'
      : result?.error?.errorCode || 'Error: changes have not been saved';

    if (result) {
      showMessage({
        position: 'bottom',
        floating: true,
        type: result.success ? 'success' : 'danger',
        message,
      });
    }

    this.props.refresh();
    this._toggleModal();
  };

  _toggleModal = () => {
    const { showEditOrderModal } = this.state;
    this.setState({
      showEditOrderModal: !showEditOrderModal,
    });
  };

  _getGroupedItems = () => {
    const { order } = this.props;
    return _.map(order.grouped_items, (v, k) => {
      if (v.length > 1) {
        return (
          <GroupedItems
            key={`group_${k}`}
            selectedItems={this.props.selectedItems}
            onItemPress={this._onItemPress}
            onPress={this._selectGroup}
          >
            {v.map(item => (
              <OrderItem
                key={item.orderitemid}
                item={item}
                onPress={this._onItemPress}
                selected={this.isItemSelected(item)}
                compact
              />
            ))}
          </GroupedItems>
        );
      }
      const item = v[0];
      return (
        <OrderItem
          key={item.orderitemid}
          item={item}
          onPress={this._onItemPress}
          selected={this.isItemSelected(item)}
        />
      );
    });
  };

  isItemSelected = item =>
    this.props.selectedItems.findIndex(i => i && i.getId() === item.getId()) >= 0;

  _selectGroup = items => {
    const selected = _.intersection(this.props.selectedItems, items);

    let selectedItems = [];
    if (selected.length === items.length) {
      selectedItems = _.without(this.props.selectedItems, ...items);
    } else {
      selectedItems = _.union(this.props.selectedItems, items);
    }
    this.props.onItemPress(null, selectedItems);
  };

  _onItemPress = item => {
    let { onItemPress, selectedItems } = this.props;
    if (selectedItems.includes(item)) {
      selectedItems = _.without(selectedItems, item);
    } else {
      selectedItems.push(item);
    }
    onItemPress(item, selectedItems.slice());
  };

  _headerPress = () => {
    let { order, onHeaderPress, selectedItems } = this.props;

    if (this.state.all_selected) selectedItems = _.without(selectedItems, ...order.items);
    else selectedItems = _.uniq(selectedItems.concat(order.items));

    onHeaderPress(order, selectedItems);
  };
}

const getBartenderName = bartenderId => {
  if (!bartenderId) return '';

  const bartender = API._bartenders[bartenderId];
  if (!bartender) return ''; // probably need to do a big refresh

  return `${bartender.first_name} ${bartender.last_name}`;
};

function OrderHeader(props) {
  const { order, onPress, allSelected } = props;
  let orderTime;

  if (moment().diff(order.time, 'days') > 0) orderTime = order.time.format('MMM D, h:mmA');
  else if (moment().diff(order.time, 'minutes') > 60) orderTime = order.time.format('h:mmA');
  else orderTime = order.time.fromNow(true);

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.orderHeader}>
        <View style={styles.orderHeaderTop}>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row' }}>
              <FormattedMessage
                id="common__orderNumber"
                values={{ orderNumber: order.orderNumber }}
                style={styles.orderNumber}
              />
              <RelatedOrders order={order} />
            </View>
            <FormattedMessage
              id={allSelected ? 'ordersView__deselectAll' : 'ordersView__selectAll'}
              style={styles.tapToSelect}
            />
          </View>
          <View style={{ flex: 1 }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {order.customer.customer_name}
              </Text>
              {!!order.location_name && <Text>{order.location_name}</Text>}
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.orderTime}>{orderTime}</Text>
            <UserDesiredTime order={order} />
          </View>
        </View>
        {order.staff_notes.length > 0 && <OrderStaffNotes notes={order.staff_notes} />}
        {(order.checkout_info.length > 0 || order.location?.runner_nav_note?.length > 0) && (
          <View style={{ padding: 10 }}>
            <OrderCheckoutInfo
              checkoutInfo={order.checkout_info}
              blockStyle={{ flexDirection: 'column' }}
              fieldStyle={styles.checkoutField}
              labelStyle={styles.checkoutField__Label}
              valueStyle={styles.checkoutField__Value}
            />
            <RunnerNavNote note={order.location?.runner_nav_note || ''} />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}
OrderHeader.propTypes = {
  order: PropTypes.object.isRequired,
  onPress: PropTypes.func,
};
OrderHeader.defaultProps = {
  onPress: () => {},
};

function OrderFooter(props) {
  const { order } = props;

  return (
    <View style={styles.orderFooter}>
      <View style={styles.footerRow}>
        <Text style={{ flex: 1, textAlign: 'right' }}>Sub-Total: </Text>
        <FormattedCurrency
          style={styles.footerAmount}
          value={order.getSubTotal() / 100}
        />
      </View>
      <View style={styles.footerRow}>
        <Text style={{ flex: 1, textAlign: 'right' }}>Tax: </Text>
        <FormattedCurrency
          style={styles.footerAmount}
          value={order.getTaxTotal() / 100}
        />
      </View>
      <View style={styles.footerRow}>
        <Text style={{ flex: 1, textAlign: 'right' }}>Tip: </Text>
        <FormattedCurrency
          style={styles.footerAmount}
          value={order.getTipTotal() / 100}
        />
      </View>
      <View style={styles.footerRow}>
        <Text style={{ flex: 1, textAlign: 'right' }}>Total: </Text>
        <FormattedCurrency
          style={styles.footerAmount}
          value={order.getTotal() / 100}
        />
      </View>
    </View>
  );
}

function RunnerNavNote({ note }) {
  if (!note) return null;
  return (
    <View style={styles.runnerNavNote}>
      <Text style={styles.runnerNavNote__Label}>Runner Notes:</Text>
      <Text style={styles.runnerNavNote__Value}>{note}</Text>
    </View>
  );
}

function UserDesiredTime(props) {
  const { order } = props;
  const now = moment();
  if (!order.user_desired_time) return null;

  const time_format = now.isSame(order.user_desired_time, 'day') ? 'LT' : 'MMM D, LT';
  let duration = order.time_closed
    ? ''
    : ` (${moment.duration(order.user_desired_time.diff()).humanize(true)})`;
  if (duration.includes('just now ago')) {
    duration = '(just now)';
  }

  const deliverString = order.user_desired_time.format(time_format); // +  duration;
  const style = {
    textAlign: 'right',
  };
  if (!order.time_closed) {
    if (now.diff(order.user_desired_time, 'minutes') >= 30)
      style.color = Colors.error; // 30 minutes overdue
    else if (now.diff(order.user_desired_time, 'minutes') >= 15) style.color = Colors.warning; // 15 minutes overdue

    if (order.user_desired_time.diff(now, 'minutes') < 60) {
      style.color = Colors.primary;
    }
  }

  return (
    <View style={{ justifyContent: 'flex-end' }}>
      <Text style={style}>
        For
        {deliverString}
      </Text>
      <Text style={style}>{duration}</Text>
    </View>
  );
}

function RelatedOrders(props) {
  const { order } = props;
  if (!order.related_orders.length) return null;

  return (
    <Text style={styles.relatedOrders}>
      {_.map(order.related_orders, 'orderNumber').join(', ')}
    </Text>
  );
}

const styles = EStyleSheet.create({
  orderSection: {
    margin: 10,
    marginBottom: 0,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    ...(Platform.OS === 'web' ? { boxShadow: '2px 2px 2px 0 rgba(0,0,0,0.5)' } : { elevation: 1 }),
  },
  orderHeader: {
    flex: 1,
    backgroundColor: '#ededed',
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8',
  },
  orderHeaderTop: {
    flexDirection: 'row',
    padding: 10,
  },
  soOrderHeader: {
    backgroundColor: '#cde0ed',
  },
  orderHeaderCenter: {
    justifyContent: 'center',
    flex: 1,
    marginHorizontal: 5,
  },
  orderHeaderRight: {
    alignItems: 'flex-end',
  },
  tapToSelect: {
    color: Colors.darkGray,
    fontSize: 12,
  },
  orderNumber: {
    fontWeight: 'bold',
  },
  bartenderNameLabel: {
    fontStyle: 'italic',
    color: Colors.darkGray,
  },
  bartenderName: {
    fontWeight: 'bold',
  },
  relatedOrders: {
    fontSize: 12,
    marginLeft: 5,
    color: Colors.darkGray,
  },
  orderTime: {
    textAlign: 'right',
  },
  orderFooter: {
    borderBottomWidth: 1.1,
    borderBottomColor: '#606060',
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 5,
  },
  footerRow: {
    flexDirection: 'row',
  },
  footerAmount: {
    textAlign: 'right',
    width: '15%',
  },
  editOrderFooter: {
    backgroundColor: Colors.lightTheme.grayscale.background,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  checkoutField: {
    flex: 1,
    flexDirection: 'row',
  },
  checkoutField__Label: {
    flex: 1,
    fontStyle: 'italic',
    color: Colors.darkGray,
    marginRight: 5,
  },
  checkoutField__Value: {
    flex: 2,
    fontWeight: 'bold',
  },
  runnerNavNote: {
    flexDirection: 'row',
  },
  runnerNavNote__Label: {
    flex: 1,
    fontStyle: 'italic',
    color: Colors.darkGray,
    marginRight: 5,
  },
  runnerNavNote__Value: {
    flex: 2,
  },
  '@media (min-width: 768)': {
    checkoutField__Value: {
      flex: 4,
    },
    runnerNavNote__Value: {
      flex: 4,
    },
  },
});
