import React, { useEffect, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import moment from 'moment';

// Components
import { View } from 'react-native';
import { FormControl, Button, Text, Icon, Row } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Content } from '../../bbot-component-library';
import Card from './Card';
import PickerModal from '../../components/Modals/PickerModal';

const dateFormat = 'ddd, MMM DD';

function DesiredTimePicker({ cart, checkingPrices, locationAllowsFutureOrdering, ...rest }) {
  const [availableTimeBlocks, setAvailableTimeBlocks] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [userDesiredDate, setUserDesiredDate] = useState('');
  const [timeBlocksToShow, setTimeBlocksToShow] = useState([]);
  const [selectedTimeBlock, setSelectedTimeBlock] = useState('');
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);

  const getAvailableDates = timeBlocks => [
    ...new Set(timeBlocks.map(timeBlock => moment(timeBlock).format(dateFormat))),
  ];

  const getTimeBlocksToShow = (desiredDate, newAvailableTimeBlocks) =>
    newAvailableTimeBlocks // Only show the time blocks on the selected day
      .filter(
        timeBlock =>
          (timeBlock.isASAP || !moment(timeBlock).isBefore()) &&
          moment(timeBlock).format(dateFormat) === desiredDate,
      );

  const setNewSelectedTimeBlock = timeBlock => {
    setSelectedTimeBlock(timeBlock);
    cart.delivery_info.user_desired_time = timeBlock.isASAP ? null : timeBlock;
  };

  const onDesiredDateChange = desiredDate => {
    const newTimeBlocksToShow = getTimeBlocksToShow(desiredDate, availableTimeBlocks);
    setUserDesiredDate(desiredDate);
    setTimeBlocksToShow(newTimeBlocksToShow);
    setNewSelectedTimeBlock(newTimeBlocksToShow[0]);
  };

  useEffect(() => {
    if (!checkingPrices) {
      const newAvailableTimeBlocks = cart.calculateAvailableTimeBlocks();
      const newAvailableDates = getAvailableDates(newAvailableTimeBlocks);
      const timeBlocksToShow = getTimeBlocksToShow(newAvailableDates[0], newAvailableTimeBlocks);

      setAvailableTimeBlocks(newAvailableTimeBlocks);
      setAvailableDates(newAvailableDates);
      setUserDesiredDate(newAvailableDates[0]);
      setTimeBlocksToShow(timeBlocksToShow);
      setSelectedTimeBlock(timeBlocksToShow[0]);
    }
  }, [checkingPrices]);

  if (!availableTimeBlocks.length || !locationAllowsFutureOrdering) return null;

  return (
    <Card
      testID="timePickerCard"
      titlePath="picker__desiredTime"
      title="Desired Time"
      {...rest}
    >
      <View>
        <FormControl
          style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}
        >
          <Button
            testID="desiredTimeDateButton"
            style={styles.timepickerButton}
            onPress={() => setShowDateModal(true)}
            transparent
            endIcon={
              <Icon
                name="chevron-down"
                as={MaterialCommunityIcons}
                style={styles.iconStyle}
              />
            }
          >
            {userDesiredDate}
          </Button>
          <Button
            testID="desiredTimeTimeButton"
            style={styles.timepickerButton}
            onPress={() => setShowTimeModal(true)}
            transparent
            endIcon={
              <Icon
                name="chevron-down"
                as={MaterialCommunityIcons}
                style={styles.iconStyle}
              />
            }
          >
            {selectedTimeBlock.isASAP ? 'ASAP' : moment(selectedTimeBlock).format('h:mm A')}
          </Button>

          {showDateModal && availableTimeBlocks && (
            <PickerModal
              data={availableDates.map(date => ({ value: date, label: date }))}
              selectedValue={userDesiredDate}
              visible={showDateModal}
              onHideCallback={() => setShowDateModal(false)}
              width={300}
              title="Order Date"
              onItemPress={desiredDate => {
                onDesiredDateChange(desiredDate);
                setShowDateModal(false);
              }}
            />
          )}
          {showTimeModal && availableTimeBlocks && (
            <PickerModal
              data={timeBlocksToShow.map(timeBlock => {
                if (timeBlock.isASAP) return { value: timeBlock, label: 'ASAP' };
                return { value: timeBlock, label: moment(timeBlock).format('h:mm A') };
              })}
              selectedValue={selectedTimeBlock}
              visible={showTimeModal}
              onHideCallback={() => setShowTimeModal(false)}
              title="Order Time"
              width={300}
              onItemPress={timeBlock => {
                setNewSelectedTimeBlock(timeBlock);
                setShowTimeModal(false);
              }}
            />
          )}
        </FormControl>
      </View>
    </Card>
  );
}

export default DesiredTimePicker;

export const styles = EStyleSheet.create({
  timepickerButton: {
    flex: 1,
    marginHorizontal: 10,
    borderRadius: 10,
    borderWidth: 1,
  },
  iconStyle: {
    fontSize: 24,
  },
});
