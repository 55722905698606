import TcpSocket from 'react-native-tcp-socket';
import Printer from './Printer';

/**
 * EpsonPrinter
 * @author Andrew Schreffler, Justin Belardi
 * @description Printer Driver for Epson Ethernet Printers. sendPrintTask() utilizes a TCP connection to send
 * the print job's raw bytes to the target printer.
 * It is imperative that each TCP connection is destroyed after each print job is sent.
 */

export default class EpsonPrinter extends Printer {
  sendPrintTask = async taskDict =>
    new Promise((resolve, reject) => {
      let result = null;
      try {
        const client = TcpSocket.createConnection(
          {
            port: this.port || 9100,
            host: this.ip,
          },
          () => {
            // Send raw_bytes_string to printer via a TCP connection, then immediately destroy the client
            result = client.write(taskDict.raw_bytes_string, null, () => client.destroy());
            resolve(
              result
                ? { success: true }
                : {
                    error: `Could not establish connection with printer at ${this.ip}: ${this.port}`,
                  },
            );
          },
        );

        // added an error listener to catch TCP Connection issues and report them back to the server
        client.on('error', error => {
          resolve({ error });
        });
      } catch (error) {
        resolve({
          error: `Could not establish TCP connection with pi ethernet printer ${this.ip}: ${this.port}`,
        });
      }
    });
}
