import Model from './Model';

export default class AppProperties extends Model {
  demo_account = false;

  hide_join_tab = false;

  menu_item_images = {};

  menus_expanded = true;

  order_num_digits = 3;

  pre_checkout_instructions = {};

  restrict_user_desired_times = {};

  order_num_daily_reset = false;

  tax = null;

  tipping = {
    choices: [],
    default_tip: null,
    for_fulfillment_methods: [],
    include_fees: false,
    include_discounts: false,
    include_taxes: false,
  };

  zeroDollarItemPriceDisplay = 'price_range';

  constructor(data) {
    super();
    this.update(data);
  }
}
