import React from 'react';
import { Pressable, Center } from 'native-base';

function FooterTab({ children, ...props }) {
  return (
    <Pressable
      flex={1}
      {...props}
    >
      <Center>{children}</Center>
    </Pressable>
  );
}

export default FooterTab;
