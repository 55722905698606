import React, { Component } from 'react';
import { Text, TouchableWithoutFeedback, Animated } from 'react-native';
import PropTypes from 'prop-types';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from '../constants/Colors';

let defaultInstance = null;

export default class NotificationBanner extends Component {
  static propTypes = {
    message: PropTypes.string,
    textColor: PropTypes.string,
    color: PropTypes.string,
    onPress: PropTypes.func,
    dismissable: PropTypes.bool,
    autoHide: PropTypes.bool,
    isGlobal: PropTypes.bool,
  };

  static defaultProps = {
    isGlobal: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      marginTop: new Animated.Value(-30),
      message: props.message || null,
      color: props.color || Colors.ternary,
      textColor: props.textColor || Colors.light,
    };
  }

  componentDidMount() {
    this._mounted = true;
    if (this.props.isGlobal) defaultInstance = this;
  }

  componentWillUnmount() {
    defaultInstance = null;
    this._mounted = false;
  }

  slideIn = () => {
    Animated.timing(this.state.marginTop, {
      toValue: 0,
    }).start();
  };

  render() {
    return (
      this.state.visible && (
        <TouchableWithoutFeedback onPress={this._onPress}>
          <Animated.View
            style={[
              styles.notifyBar,
              {
                backgroundColor: this.state.color,
                marginTop: this.state.marginTop,
              },
            ]}
          >
            <Text style={[styles.text, { color: this.state.textColor }]}>{this.state.message}</Text>
          </Animated.View>
        </TouchableWithoutFeedback>
      )
    );
  }

  _onPress = () => {
    if (this.props.dismissable) {
      this.hide();
    }
  };

  show = options => {
    this.setState(
      {
        visible: true,
        ...options,
      },
      this.slideIn,
    );
  };

  hide = () => {
    Animated.timing(this.state.marginTop, {
      toValue: -40,
      useNativeDriver: false, // Platform.OS === 'android'
    }).start(() => {
      if (this._mounted) this.setState({ visible: false });
    });
  };

  static styles = EStyleSheet.create({
    notifyBar: {
      zIndex: 1000,
      height: 40,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingHorizontal: 10,
      paddingVertical: 5,
      width: '100%',
      borderBottomWidth: 2,
      borderBottomColor: 'black',
    },
    text: {
      fontSize: 18,
      fontWeight: 'bold',
    },
  });

  static show(options) {
    if (defaultInstance) defaultInstance.show(options);
  }

  static hide() {
    if (defaultInstance) defaultInstance.hide();
  }
}

const { styles } = NotificationBanner;
export const { show } = NotificationBanner;
export const { hide } = NotificationBanner;
