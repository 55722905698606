import React, { useRef, useState } from 'react';
import { Animated, Platform, Pressable, StyleSheet } from 'react-native';
import { Icon, Text, View } from 'native-base';
import { FormattedCurrency, FormattedPlural } from 'react-native-globalize';
import { AntDesign } from '@expo/vector-icons';
import TabOrderItem from './TabOrderItem';

function TabOrder({ order }) {
  const [expanded, setExpanded] = useState(false);
  const [fullHeight, setFullHeight] = useState(0);
  const expandAnim = useRef(new Animated.Value(1)).current;
  const rotateAnim = useRef(new Animated.Value(0)).current;

  const toggle = () => {
    const ANIMATION_DURATION = 100;
    if (expanded) {
      Animated.parallel([
        Animated.timing(expandAnim, {
          toValue: 0,
          duration: ANIMATION_DURATION,
          useNativeDriver: false, // not supported for 'height'
        }),
        Animated.timing(rotateAnim, {
          toValue: 0,
          duration: ANIMATION_DURATION,
          useNativeDriver: Platform.OS !== 'web',
        }),
      ]).start();
    } else {
      Animated.parallel(
        [
          Animated.timing(expandAnim, {
            toValue: fullHeight,
            duration: ANIMATION_DURATION,
            useNativeDriver: false,
          }),
          Animated.timing(rotateAnim, {
            toValue: 1,
            duration: ANIMATION_DURATION,
            useNativeDriver: Platform.OS !== 'web',
          }),
        ],
        { stopTogether: false },
      ).start();
    }
    setExpanded(!expanded);
  };

  const onLayout = ({ nativeEvent: { layout } }) => {
    if (!fullHeight) {
      setFullHeight(layout.height);
      expandAnim.setValue(0);
    }
    // Calculate height based on #items and #modifiers?
  };

  const spin = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'],
  });

  return (
    <View style={[styles.container, {}]}>
      <Pressable
        onPress={toggle}
        style={styles.header}
      >
        <Text>
          {order.party_tab_ids.length ? 'Tab ' : ''}
          {`Order# ${order.orderNumberToday}`}
        </Text>
        <Text style={{ textAlign: 'center', flex: 1 }}>
          {`${order.items.length} `}
          <FormattedPlural
            value={order.items.length}
            one="item"
            other="items"
          />
        </Text>
        <FormattedCurrency
          value={order.getSubtotalOrTaxInclusiveTotalCents() / 100}
          style={{
            width: 75,
            textAlign: 'right',
            marginRight: 10,
          }}
        />
        <Animated.View
          style={{
            transform: [
              {
                rotate: spin,
              },
            ],
          }}
        >
          <Icon
            as={AntDesign}
            name="rightcircle"
            style={{ fontSize: 18 }}
          />
        </Animated.View>
      </Pressable>
      <Animated.View
        style={{ height: fullHeight ? expandAnim : 'auto', overflow: 'hidden' }}
        onLayout={onLayout}
      >
        <View style={{ padding: 5, backgroundColor: 'white' }}>
          {order.grouped_items.map((items, i, z) => (
            <TabOrderItem
              key={items[0].itemId}
              groupedItems={items}
              last={i === z.length - 1}
            />
          ))}
        </View>
      </Animated.View>
    </View>
  );
}

export default TabOrder;

const styles = StyleSheet.create({
  container: {
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 5,
    elevation: 1,
    overflow: 'hidden',
  },
  header: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e7e7e7',
    borderBottomColor: 'darkgray',
    borderBottomWidth: 1,
  },
});
