import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView } from 'native-base';
import MenuSelection from './MenuSelection';
import { SelectItemViewStyles as styles } from '../../styles/POSStyles';
import Grid from '../../bbot-component-library/POS/Grid';
import SearchBar from '../../bbot-component-library/POS/SearchBar';

export function SelectItemHeader({
  setSearchText: saveSearchText,
  selectedMenuHeadingId,
  headingName,
}) {
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    saveSearchText(searchText);
  }, [searchText]);

  useEffect(() => {
    setSearchText(null);
  }, [selectedMenuHeadingId]);

  return (
    <View style={styles.Header}>
      <View style={styles.SearchRow}>
        <SearchBar
          onChangeText={setSearchText}
          searchText={searchText}
        />
      </View>
      {(!searchText?.length || searchText?.length < 2) && (
        <View>
          <Text style={styles.HeadingTitle}>{headingName}</Text>
        </View>
      )}
    </View>
  );
}

export function SelectItemBody({ items, onMenuItemPress, POSDispatch, cart, menuId }) {
  return (
    <ScrollView
      style={styles.Body}
      showsVerticalScrollIndicator={false}
    >
      <Grid
        data={items}
        renderItem={({ item }) => (
          <MenuSelection
            key={item.menuItemId}
            name={item.name_for_bartender}
            onPress={() => onMenuItemPress(item, POSDispatch, cart, menuId)}
          />
        )}
        keyExtractor={item => item.menuItemId}
      />
    </ScrollView>
  );
}
