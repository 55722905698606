import _ from 'lodash';
import React from 'react';
import {
  View,
  Keyboard,
  FlatList,
  ActivityIndicator,
  StatusBar,
  Dimensions,
  ScrollView,
} from 'react-native';
import { Text, Container, Button, FormControl, Stack, Input, Select, Box } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import { Entypo, FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import SideMenu from 'react-native-side-menu-updated';
import { Footer, FooterTab } from '../bbot-component-library';
import OrderListView from '../components/OrderListView';

/* ========= App Imports ========== */
import API from '../api';
import Loader from '../components/Loader';
import OrderListViewHeader from '../components/OrderListViewHeader';
import OrderItemStatusChanger from '../components/OrderItemStatusChanger';

import {
  CommandBar,
  CommandBarCenter,
  CommandBarLeft,
  CommandBarRight,
  LocationFilter,
  SendReceiptModal,
} from '../components';
import Colors from '../constants/Colors';
import HeaderIconButton from '../components/HeaderIconButton';
import OrderHelper from '../helpers/OrderHelper';
import DateTimePickerField from '../components/DateTimePickerField';
import IconButton from '../components/IconButton';
import RefundIconButton from '../components/RefundIconButton';
import { offsetInput } from '../helpers/DateTimePickerHelper';
import Order from '../models/Order';

class OrderSearchScreen extends React.Component {
  static navigationOptions = props => {
    const { navigation } = props;
    const onShowFilters = navigation.getParam('onShowFilters');
    const onRefresh = navigation.getParam('onRefresh');
    return {
      title: 'Orders',
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          <HeaderIconButton
            name="Refresh"
            icon="refresh"
            onPress={onRefresh}
            type={Ionicons}
          />
          <HeaderIconButton
            name="Search"
            icon="filter"
            onPress={onShowFilters}
            type={FontAwesome}
          />
        </View>
      ),
    };
  };

  constructor(props) {
    super(props);
    const { navigation } = props;

    navigation.setParams({
      onShowFilters: this.toggleDrawer,
      onRefresh: this._onRefresh,
    });

    const { width } = Dimensions.get('window');

    this.state = {
      loading: false,
      loadingMore: false,
      orders: [],
      orderFees: [],
      page: 1,
      numPages: 0,
      selectedOrders: [],
      selectedItems: [],
      showPinPad: false,
      offset: this._calculateOffset(width),
      isResized: true,
      drawerOpen: true,
    };
  }

  closeDrawer = () => {
    if (this.state.drawerOpen) {
      Keyboard.dismiss();
      this.setState({ drawerOpen: false });
    }
  };

  openDrawer = () => {
    this.setState({ drawerOpen: true });
  };

  toggleDrawer = () => {
    this.state.drawerOpen ? this.closeDrawer() : this.openDrawer();
  };

  componentDidMount() {
    const { navigation } = this.props;
    this.focusListener = navigation.addListener('didFocus', () => setTimeout(this.openDrawer, 200));
    this._dimensionListener = Dimensions.addEventListener('change', this._dimensionsChanged);
  }

  componentWillUnmount() {
    this.focusListener.remove();
    this._dimensionListener.remove();
  }

  _calculateOffset = width => (width > 400 ? 400 : width * 0.8);

  _dimensionsChanged = ({ window }) => {
    const { width } = window;
    this.setState({ offset: this._calculateOffset(width) });
  };

  refreshOrders = async params => {
    try {
      this.closeDrawer();

      params.page = 1;
      this.searchParams = params;

      this.setState({
        loading: true,
        orders: [],
        selectedItems: [],
        orderFees: [],
        numPages: 0,
        page: 1,
        receiptModal: false,
      });

      params.hours_in_past = parseInt(params.hours_in_past);
      if (isNaN(params.hours_in_past)) params.hours_in_past = null;

      const result = await API.searchOrders(params);

      this.setState({
        loading: false,
        orders: result.orders,
        orderFees: result.orderFees,
        numPages: result.numPages,
      });
    } catch (err) {}
  };

  _onRefresh = () => {
    this.refreshOrders(this.searchParams);
  };

  render() {
    const {
      selectedOrders,
      offset,
      loading,
      selectedItems,
      orders,
      dataChanged,
      drawerOpen,
      receiptModal,
    } = this.state;
    return (
      <SideMenu
        disableGestures
        isOpen={drawerOpen}
        openMenuOffset={offset}
        edgeHitWidth={0}
        menu={<FilterDrawer onSearch={this.refreshOrders} />}
        menuPosition="right"
        onChange={isOpen => !isOpen && this.setState({ drawerOpen: false })}
        overlayColor="rgba(0,0,0,0.4)"
      >
        <View style={{ backgroundColor: 'white', flex: 1 }}>
          <View style={{ flex: 1 }}>
            {loading && <Loader />}
            <FlatList
              style={{ flex: 1 }}
              ListHeaderComponent={<OrderListViewHeader />}
              stickyHeaderIndices={[0]}
              data={orders}
              renderItem={this._renderItem}
              extraData={dataChanged}
              keyExtractor={item => item.orderId}
              onEndReached={this.showMore}
              onEndReachedThreshold={0.5}
              initialNumToRender={10}
              onRefresh={this._onRefresh}
              refreshing={false}
              ListFooterComponent={this._renderFooter}
              ListEmptyComponent={this._listEmpty}
            />
            <OrderItemStatusChanger
              selectedItems={selectedItems}
              onChange={this._onItemStatusChange}
              allowRegress
            />
            <Box
              safeAreaBottom
              style={styles.footer}
            >
              <RefundIconButton
                selectedItems={selectedItems}
                orders={[selectedOrders[0]]}
                onSuccess={() => {}}
              />
              <IconButton
                label="order__sendReceipt"
                defaultMessage="Send Receipt"
                disabled={this._numOrders() !== 1}
                onPress={() => this.setState({ receiptModal: true })}
                icon="send"
                iconType={FontAwesome}
              />
              <IconButton
                label="order__reprintTicket"
                defaultMessage="Re-print Ticket"
                icon="ticket"
                iconType={Entypo}
                disabled={this._numOrders() !== 1}
                onPress={() => {
                  OrderHelper.printTicket(selectedItems[0].order, true);
                }}
              />
              <IconButton
                label="order__deselect"
                defaultMessage="De-select"
                disabled={!selectedItems.length}
                onPress={this._deselectAll}
                icon="select-off"
                iconType={MaterialCommunityIcons}
              />
            </Box>
            <SendReceiptModal
              visible={receiptModal}
              onCancel={() => this.setState({ receiptModal: false })}
              onSave={() => this.setState({ receiptModal: false })}
              order={selectedItems[0] ? selectedItems[0].order : null}
            />
          </View>
        </View>
      </SideMenu>
    );
  }

  _renderItem = data => {
    const order = data.item;
    const { selectedItems } = this.state;
    return (
      <OrderListView
        order={order}
        onHeaderPress={this._updateSelected}
        onItemPress={this._updateSelected}
        selectedItems={selectedItems}
      />
    );
  };

  _listEmpty = (
    <View style={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
      <Text style={{ color: Colors.gray }}>No Orders Found</Text>
    </View>
  );

  _renderFooter = () => {
    if (!this.state.loadingMore) return null;
    return (
      <View style={{ paddingVertical: 20, borderTopWidth: 1, borderTopColor: Colors.gray }}>
        <ActivityIndicator
          size="large"
          color={Colors.primary}
        />
      </View>
    );
  };

  showMore = async () => {
    if (this.searchParams.page < this.state.numPages) {
      const page = this.state.page + 1;
      this.setState({
        loadingMore: true,
        page,
      });

      this.searchParams.page = page;
      const result = await API.searchOrders(this.searchParams);

      if (!result.error) {
        this.setState({
          loadingMore: false,
          orders: this.state.orders.concat(result.orders),
          orderFees: this.state.orderFees.concat(result.orderFees),
          numPages: result.numPages,
        });
      } else {
        this.setState({
          loadingMore: false,
        });
      }
    }
  };

  _updateSelected = (obj, selectedItems) => {
    const { orders } = this.state;
    this.setState({
      selectedOrders: orders.filter(order =>
        selectedItems.map(item => item.orderId).includes(order.orderId),
      ),
      selectedItems: [...selectedItems],
    });
  };

  _onItemStatusChange = selectedItems => {
    // let orders = this.state.orders;
    this.setState({
      dataChanged: !this.state.dataChanged,
    });
  };

  _deselectAll = () => {
    this.setState({
      selectedItems: [],
    });
  };

  _numOrders = () => _.uniqBy(this.state.selectedItems, 'orderId').length;

  static propTypes = {};
}

class FilterDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.state = {
      order_number: '',
      hours_in_past: '',
      phone_number: '',
      locationShortId: '',
      last4: '',
      exp_month: '',
      exp_year: '',
      checkout_info: '',
      start_date: undefined,
      end_date: undefined,
      sort_field: 'last_modified',
      sort_dir: '-',
    };
  }

  render() {
    const locationCodes = API.getLocationCodes().sort();
    const sortBy = Object.keys(Order.SortableFields).map(key => ({
      value: key,
      text: Order.SortableFields[key],
    }));
    const {
      order_number,
      start_date,
      end_date,
      sort_field,
      sort_dir,
      locationShortId,
      phone_number,
      last4,
      exp_month,
      exp_year,
      checkout_info,
    } = this.state;

    return (
      <View style={{ minWidth: 250, padding: 10, flex: 1 }}>
        <ScrollView>
          <FormControl>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Order Number</FormControl.Label>
              <Input
                value={order_number}
                onChangeText={text => this.setState({ order_number: text })}
              />
            </Stack>
            {/*
            <Stack stackedLabel accessible={false}>
              <Label>Hours In Past</Label>
              <Input
                value={ hours_in_past}
                keyboardType={'number-pad'}
                onChangeText={text => this.setState({hours_in_past: text})}/>
            </Stack>
            */}
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Start Date</FormControl.Label>
              <DateTimePickerField
                value={start_date}
                onChange={text => this.setState({ start_date: text })}
                pickerOptions={{
                  maximumDate: end_date ? offsetInput(end_date) : null,
                }}
              />
            </Stack>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>End Date</FormControl.Label>
              <DateTimePickerField
                value={end_date}
                onChange={text => this.setState({ end_date: text })}
                pickerOptions={{
                  minimumDate: start_date ? offsetInput(start_date) : null,
                }}
              />
            </Stack>
            <Stack
              picker
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Sort By</FormControl.Label>
              <Select
                mode="dialog"
                selectedValue={sort_field}
                onValueChange={val => {
                  this.setState({ sort_field: val });
                }}
                style={{ width: '100%', alignSelf: 'flex-start' }}
              >
                {sortBy.map(opt => (
                  <Select.Item
                    key={opt.value}
                    label={opt.text}
                    value={opt.value}
                  />
                ))}
              </Select>
            </Stack>
            <Stack
              picker
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Sort Order</FormControl.Label>
              <Select
                mode="dialog"
                selectedValue={sort_dir}
                onValueChange={val => {
                  this.setState({ sort_dir: val });
                }}
                style={{ width: '100%', alignSelf: 'flex-start' }}
              >
                <Select.Item
                  key="newest-first"
                  label="Newest First"
                  value="-"
                />
                <Select.Item
                  key="oldest-first"
                  label="Oldest First"
                  value=""
                />
              </Select>
            </Stack>
            <Stack
              picker
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Location</FormControl.Label>
              <Select
                mode="dialog"
                selectedValue={locationShortId}
                onValueChange={val => {
                  this.setState({ locationShortId: val });
                }}
                style={{ width: '100%', alignSelf: 'flex-start' }}
              >
                <Select.Item
                  key="all"
                  label="All"
                  value=""
                />
                {locationCodes.map((code, index) => (
                  <Select.Item
                    key={`${code}-${index}`}
                    label={code}
                    value={code}
                  />
                ))}
              </Select>
            </Stack>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Phone Number</FormControl.Label>
              <Input
                value={phone_number}
                keyboardType="phone-pad"
                onChangeText={text => this.setState({ phone_number: text })}
              />
            </Stack>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Card Last 4</FormControl.Label>
              <Input
                value={last4}
                keyboardType="number-pad"
                maxLength={4}
                onChangeText={text => this.setState({ last4: text })}
              />
            </Stack>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Card Expiry Month</FormControl.Label>
              <Input
                value={exp_month}
                keyboardType="number-pad"
                onChangeText={text => this.setState({ exp_month: text })}
              />
            </Stack>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Card Expiry Year</FormControl.Label>
              <Input
                value={exp_year}
                keyboardType="number-pad"
                onChangeText={text => this.setState({ exp_year: text })}
              />
            </Stack>
            <Stack
              stackedLabel
              accessible={false}
            >
              <FormControl.Label>Checkout Info</FormControl.Label>
              <Input
                value={checkout_info}
                onChangeText={text => this.setState({ checkout_info: text })}
              />
            </Stack>
          </FormControl>
        </ScrollView>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            paddingHorizontal: 15,
            paddingTop: 10,
          }}
        >
          <Button
            block
            colorScheme="light"
            style={{ flex: 1, marginRight: 5 }}
            onPress={this.resetForm}
          >
            <Text>Reset</Text>
          </Button>
          <Button
            block
            style={{ flex: 1, marginLeft: 5 }}
            primary
            onPress={this._onSearch}
          >
            <Text style={{ color: 'white' }}>Search Orders</Text>
          </Button>
        </View>
      </View>
    );
  }

  _onSearch = () => {
    this.props.onSearch(this.getParams());
  };

  getParams = () => ({
    ...this.state,
  });

  resetForm = () => {
    this.setState({
      order_number: '',
      hours_in_past: '',
      phone_number: '',
      locationShortId: '',
      last4: '',
      exp_month: '',
      exp_year: '',
      start_date: null,
      end_date: null,
    });
  };

  static propTypes = {
    onSearch: PropTypes.func.isRequired,
  };
}

export default OrderSearchScreen;

const styles = EStyleSheet.create({
  footer: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
    maxHeight: 55,
    flex: 1,
  },
});
