/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-assign */
// eslint-disable-next-line import/prefer-default-export
import { View } from 'react-native';
import { SEGMENT_API_KEY } from '../constants/Config';

export const createClient = () => {
  if (!SEGMENT_API_KEY) console.log('SEGMENT_API_KEY is not defined');
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console && console.error && console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'group',
        'track',
        'ready',
        'alias',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function (t) {
        return function () {
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (let t = 0; t < analytics.methods.length; t += 1) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function (t) {
        if (!document.getElementById('segmentScript')) {
          const e = document.createElement('script');
          e.id = 'segmentScript';
          e.type = 'text/javascript';
          e.async = !0;
          e.src =
            // eslint-disable-next-line yoda
            ('https:' === document.location.protocol ? 'https://' : 'http://') +
            'cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          const n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(e, n);
        }
      };
      analytics.SNIPPET_VERSION = '3.0.1';
      analytics.load(SEGMENT_API_KEY);
      analytics.page();
    }

  return analytics;
};

export function AnalyticsProvider(props) {
  return (
    <View
      {...props}
      style={{ flex: 1 }}
    />
  );
}
