import { loadCldr, loadMessages, localeIsLoaded } from 'react-native-globalize';
import LANGUAGES from './languages';

// We must preload all locales we plan on using (require needs to be top-level on android)
// TODO: find a way to hot-load locales (might require an app restart
export const loadLocale = {
  da: () => loadCldr(require('react-native-globalize/locale-data/da')),
  de: () => loadCldr(require('react-native-globalize/locale-data/de')),
  en: () => loadCldr(require('react-native-globalize/locale-data/en')),
  'en-AU': () => loadCldr(require('react-native-globalize/locale-data/en-AU')),
  'en-CA': () => loadCldr(require('react-native-globalize/locale-data/en-CA')),
  'en-CH': () => loadCldr(require('react-native-globalize/locale-data/en-CH')),
  'en-GB': () => loadCldr(require('react-native-globalize/locale-data/en-GB')),
  'en-HK': () => loadCldr(require('react-native-globalize/locale-data/en-HK')),
  'en-NZ': () => loadCldr(require('react-native-globalize/locale-data/en-NZ')),
  'en-SG': () => loadCldr(require('react-native-globalize/locale-data/en-SG')),
  'es-MX': () => loadCldr(require('react-native-globalize/locale-data/es-MX')),
  'es-US': () => loadCldr(require('react-native-globalize/locale-data/es-US')),
  fr: () => loadCldr(require('react-native-globalize/locale-data/fr')),
  'fr-CA': () => loadCldr(require('react-native-globalize/locale-data/fr-CA')),
  ja: () => loadCldr(require('react-native-globalize/locale-data/ja')),
  nl: () => loadCldr(require('react-native-globalize/locale-data/nl')),
  pl: () => loadCldr(require('react-native-globalize/locale-data/pl')),
  zh: () => loadCldr(require('react-native-globalize/locale-data/zh')),
};

const filterLocale = locale => {
  if (locale === 'da-DK') return 'da';
  if (locale === 'de-DE') return 'de';
  if (locale === 'en-US') return 'en';
  if (locale === 'ja-JP') return 'ja';
  if (locale === 'nl-NL') return 'nl';
  if (locale === 'pl-PL') return 'pl';
  return locale;
};

export const switchLocale = locale => {
  locale = filterLocale(locale);
  if (!localeIsLoaded(locale)) loadLocale[locale]();
  if (!LANGUAGES[locale]) locale = 'en';

  loadMessages({
    [locale]: LANGUAGES[locale],
  });
  return locale;
};
