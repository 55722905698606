import React, { useState, useContext, useEffect } from 'react';
import { Text, Button, Row } from 'native-base';
import { View, Pressable, ScrollView } from 'react-native';
import { SelectModifierViewStyles as styles } from '../../styles/POSStyles';
import POSContext from '../../contexts/POSContext';
import API from '../../api';
import ModifierGroup from './ModifierGroup';
import { getInitialModIds, removeItem, saveItem } from './utils';
import SpecialInstructionsInput from './SpecialInstructionsInput';

function EditItemView(props) {
  const posContext = useContext(POSContext);
  const [selectedModifiersByGroup, setSelectedModifiersByGroup] = useState({}); // maps modifier group id to array of selected modifier
  const [specialInstructions, setSpecialInstructions] = useState(initialSpecialInstructions);

  const setSelectedModifiers = (modifierGroupId, modifiers) => {
    setSelectedModifiersByGroup(selectedModifiersByGroup => ({
      ...selectedModifiersByGroup,
      [modifierGroupId]: modifiers,
    }));
  };
  const { Container, Header, HeaderRight, ItemTitle, CancelButton, SaveButton } = styles;

  const {
    POSDispatch,
    POSValues: {
      selectedCartItem,
      selectedCartItem: {
        name,
        menuItemId,
        id: cartItemId,
        mods: selectedCartItemMods,
        special_instructions: initialSpecialInstructions,
      },
    },
  } = posContext;

  const selectedMenuItem = API.menuData.menuItemsById[menuItemId] ?? { modifierGroups: [] };
  const { modifier_groups: modifierGroups } = selectedMenuItem;

  // Set up the initial modifiers
  useEffect(() => {
    setSelectedModifiersByGroup(selectedCartItemMods);
  }, [selectedCartItemMods]);

  return (
    <View style={Container}>
      <View style={Header}>
        <Row alignItems="center">
          <Text style={ItemTitle}>{name}</Text>
        </Row>

        <Row style={HeaderRight}>
          <Button
            onPress={() => removeItem(cartItemId, POSDispatch)}
            style={CancelButton}
          >
            Remove Item
          </Button>

          <Button
            onPress={() =>
              saveItem(
                cartItemId,
                selectedCartItem,
                modifierGroups,
                selectedModifiersByGroup,
                POSDispatch,
                specialInstructions,
              )
            }
            style={SaveButton}
          >
            Update
          </Button>
        </Row>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        {modifierGroups.map(modifierGroup => (
          <ModifierGroup
            cartItemId={cartItemId}
            modifierGroup={modifierGroup}
            key={modifierGroup.id}
            onSelectedModifiersChange={setSelectedModifiers}
            initialModIds={getInitialModIds(selectedCartItemMods, modifierGroup)}
            cartItemModifiers={selectedCartItemMods}
          />
        ))}
        <SpecialInstructionsInput
          onSpecialInstructionsChange={setSpecialInstructions}
          defaultInstructions={initialSpecialInstructions}
        />
      </ScrollView>
    </View>
  );
}

export default EditItemView;
