import { createStackNavigator } from 'react-navigation-stack';
import React, { useState, useEffect } from 'react';
import { Entypo } from '@expo/vector-icons';
import KDSView from '../screens/KDSView';
import TabBarIcon from '../components/TabBarIcon';
import { defaultNavigationOptions } from './Defaults';
import API from '../api';
import TabBarLabel from '../components/TabBarLabel';

export const KDSViewStack = createStackNavigator(
  {
    KDSView,
  },
  {
    headerMode: 'none',
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__kdsView"
        />
      ),
      tabBarTestID: 'kdsview',
      tabBarIcon: props => <TabBarIconWithBadge {...props} />,
    }),
    defaultNavigationOptions,
  },
);

class TabBarIconWithBadge extends React.PureComponent {
  state = {
    count: API.newKDSOrderCount(),
  };

  componentDidMount() {
    this._mounted = true;
    API.on('orders', this._setCount, 'TabBarIconWithBadge');
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('orders', this._setCount);
  }

  _setCount = async () => {
    if (this._mounted) {
      this.setState({
        count: API.newKDSOrderCount(),
      });
    }
  };

  render() {
    const { focused } = this.props;
    const { count } = this.state;
    return (
      <TabBarIcon
        {...this.props}
        badge={focused ? null : count}
        name="grid"
        type={Entypo}
      />
    );
  }
}
