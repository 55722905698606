import React, { PureComponent } from 'react';
import { View, Image, Text, ActivityIndicator } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import Colors from '../constants/Colors';

export default class Loader extends PureComponent {
  render() {
    const { message, shown } = this.props;
    if (!shown) return null;

    const msgTxt = message ? <Text style={styles.message}>{message}</Text> : null;

    return (
      <View
        nativeID="loader"
        style={styles.ViewStyle}
      >
        <ActivityIndicator
          size="large"
          color={Colors.primary}
        />
        {msgTxt}
      </View>
    );
  }

  static propTypes = {
    message: PropTypes.string,
    shown: PropTypes.bool,
  };

  static defaultProps = {
    message: '',
    shown: true,
  };
}

const styles = EStyleSheet.create({
  ViewStyle: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 1000,
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bbot: {
    width: '30%',
    top: '-2rem',
    left: '2rem',
  },
  message: {
    maxWidth: '50%',
    fontSize: 18,
    color: 'white',
  },
});
