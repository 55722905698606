import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Icon, Text } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { Body, Left, Right, ListItem } from '../../bbot-component-library';
import { styles } from '../../styles/ListComponentStyle';

/**
 * Storage Status information
 * Currently only supports Web
 * Todo: Find a way to support Android
 * @param focus
 * @returns {JSX.Element}
 * @constructor
 */
function StorageStatus({ focus }) {
  const [usage, setUsage] = useState('0');
  const [quota, setQuota] = useState('0');

  function niceBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n /= 1024;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
  }

  const doUpdate = () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate().then(({ usage, quota }) => {
        setUsage(niceBytes(usage));
        setQuota(niceBytes(quota));
      });
    }
  };

  useEffect(() => {
    if (focus) doUpdate();
  }, [focus]);

  if (quota === '0') return null;

  return (
    <ListItem onPress={doUpdate}>
      <Left icon>
        <Icon
          size={8}
          style={styles.iconStyle}
          as={MaterialIcons}
          name="storage"
        />
      </Left>
      <Body>
        <Text>Storage</Text>
      </Body>
      <Right onListItem>
        <Text note>
          {usage}/{quota}
        </Text>
      </Right>
    </ListItem>
  );
}

export default StorageStatus;
