import PouchDB from 'pouchdb';

PouchDB.plugin(require('pouchdb-upsert'));

const newDB = () => new PouchDB('terminal', { revs_limit: 1, auto_compaction: true });
let DB = newDB();

export default () => DB;

export const resetDB = async () => {
  try {
    await DB.destroy();
    DB = newDB();
    return DB;
  } catch (err) {
    console.error(err);
    return null;
  }
};
