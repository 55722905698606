import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Vibration } from 'react-native';
import { Icon } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../../styles';

export default class PinButton extends React.PureComponent {
  render() {
    const { value, onPress } = this.props;
    let text = value;

    if (value === '') {
      return <View style={styles.wrap} />;
    }
    if (value === 'Del') {
      text = (
        <Icon
          as={MaterialIcons}
          name="backspace"
          size={5}
          color={Colors.primary}
        />
      );
    }

    return (
      <TouchableOpacity
        accessible
        testID={`pin${String(value)}`}
        accessibilityLabel={`pin${String(value)}`}
        style={[styles.wrap, styles.button]}
        onPress={() => {
          Vibration.vibrate(50);
          onPress(value);
        }}
      >
        <Text
          style={styles.buttonText}
          fsClass="fs-exclude"
        >
          {text}
        </Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    width: 60,
    height: 60,
    margin: 10,
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#dfdfdf',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {},
});
