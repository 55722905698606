import React from 'react';
import { Text, View } from 'native-base';
import OrderHelper from '../../helpers/OrderHelper';

function TabItemModifiers({ mods, color }) {
  if (!mods) return null;
  const sortedMods = OrderHelper.sortModifiers(mods);
  return (
    <View style={{ marginLeft: 8 }}>
      {sortedMods.map(mod => (
        <View key={mod.id}>
          <Text style={{ fontSize: 13, color: color || 'black' }}>
            {mod.modifier?.name_for_bartender || mod.name}
          </Text>
          <TabItemModifiers
            mods={mod.mods}
            color={color}
          />
        </View>
      ))}
    </View>
  );
}

export default TabItemModifiers;
