import EStyleSheet from 'react-native-extended-stylesheet';

// FONT STYLES

const Title20 = {
  fontFamily: 'TTNorms',
  fontWeight: '500',
  fontSize: 20,
  color: 'white',
};

const Title32x700 = {
  ...Title20,
  fontSize: 32,
  fontWeight: '700',
};

const Title20x700 = {
  ...Title20,
  fontWeight: '700',
};

const Title16 = {
  ...Title20,
  fontSize: 16,
};

const Title16x700 = {
  ...Title16,
  fontWeight: '700',
};

const Title18 = {
  ...Title20,
  fontSize: 18,
};

const Title12 = {
  ...Title20,
  fontSize: 12,
};

const MenuSelection = {
  backgroundColor: '#454647',
  height: 90,
  width: 180,
  borderRadius: 8,
  padding: 16,
  justifyContent: 'center',
};

export const SelectItemViewStyles = EStyleSheet.create({
  HeadingTitle: Title20,

  ViewContainer: {
    height: '100%',
    paddingLeft: 32,
    paddingRight: 32,
  },

  Header: {
    width: '100%',
    marginBottom: 24,
  },

  SearchRow: {
    marginTop: 32,
    marginBottom: 24,
  },

  SearchIcon: {
    marginLeft: 20,
  },

  Body: {
    width: '100%',
    flexGrow: 1,
  },
});

export const SelectModifierViewStyles = EStyleSheet.create({
  ItemTitle: Title20,

  Container: {
    flex: 1,
    paddingLeft: 30,
    paddingRight: 30,
  },

  Header: {
    marginTop: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  CancelButton: {
    backgroundColor: 'transparent',
    marginRight: 10,
  },

  SaveButton: {
    backgroundColor: '#4969F5',
    color: 'white',
  },

  Body: {
    flexGrow: 1,
  },

  BodyContentContainer: {
    backgroundColor: 'red',
    height: 6000,
  },
});

export const LeftColStyles = EStyleSheet.create({
  HeadingTitle: Title16,
  MenuTitle: Title18,

  Col: {
    width: 220,
    backgroundColor: '#1D1D1D',
    padding: 10,
  },

  TitleContainer: {
    padding: 10,
    marginBottom: 20,
    maxHeight: 40,
  },

  HeadingContainer: {
    borderRadius: 5,
    marginBottom: 10,
    padding: 10,
  },

  HeadingContainerSelected: {
    borderRadius: 8,
    marginBottom: 10,
    padding: 10,
    backgroundColor: 'black',
  },
  Footer: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 4,
    borderTopColor: '#494949',
    borderTopWidth: 1,
  },
});

export const ItemSelectorColStyles = EStyleSheet.create({
  ItemSelectorCol: {
    flexGrow: 1,
    backgroundColor: '#191919',
  },
});

export const MenuSelectionStyles = EStyleSheet.create({
  SelectionText: Title16,

  Selection: MenuSelection,

  SelectedSelection: {
    ...MenuSelection,

    borderWidth: 3,
    borderColor: '#4969F5',
  },
});

export const ModifierGroupStyles = EStyleSheet.create({
  HeadingName: Title16,

  Container: {
    marginTop: 14,
    marginBottom: 14,
  },

  SubHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },

  SubHeaderChoiceRequired: {
    ...Title12,
    color: '#BFBFBF',
  },
  SubHeaderError: {
    ...Title12,
    color: 'red',
  },
});

export const SearchBarStyles = EStyleSheet.create({
  SearchBar: {
    color: 'white',
    fontSize: 15,
  },
  SearchIcon: {
    marginLeft: 20,
  },
  ClearIcon: {
    marginRight: 20,
  },
});

export const GridStyles = EStyleSheet.create({
  ItemSeparatorComponent: {
    height: 15,
  },
  ItemWrapper: {
    marginRight: 15,
  },
});

export const SubtotalLineStyles = isLast =>
  EStyleSheet.create({
    SubtotalNameText: isLast ? Title16x700 : Title16,
    SubtotalAmountText: isLast ? Title16x700 : Title16,

    SubtotalLine: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: isLast ? 0 : 16,
    },
  });

export const CartColStyles = EStyleSheet.create({
  HeaderClearOrder: Title16,
  HeaderNewOrder: Title20x700,
  CheckoutText: Title20x700,

  CartCol: {
    width: 350,
    backgroundColor: '#253039',
  },

  CartItemsContainer: {
    flexGrow: 1,
  },

  Header: {
    padding: 24,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#454854',
  },

  SubtotalsContainer: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 28,
    paddingBottom: 28,
  },

  Checkout: {
    backgroundColor: '#4969F5',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 28,
    paddingBottom: 28,
  },
});

export const CartItemStyles = (isSelected = false) =>
  EStyleSheet.create({
    PriceText: Title16,
    QtyText: Title16x700,

    NameText: {
      ...Title16,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    CartItem: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 24,
      paddingBottom: 24,
      borderBottomWidth: 1,
      borderBottomColor: '#101519',
      backgroundColor: isSelected ? '#1D1D1D' : 'transparent',
    },
    CartItemTop: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    CartItemBody: {
      marginTop: 20,
      paddingLeft: 40,
    },

    LeftContainer: {
      flex: 1,
    },
    RightContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    QuantityContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 90,
    },
    QuantityButton: {
      height: 28,
      width: 28,
      borderRadius: 30,
      backgroundColor: '#5F5F66',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export const SearchResultsStyles = EStyleSheet.create({
  HeadingName: {
    ...Title18,
    marginBottom: 12,
  },
  HeadingContainer: {
    marginBottom: 32,
  },
  Container: {
    flex: 1,
  },
  Body: {
    width: '100%',
    flexGrow: 1,
  },
});

export const PaymentTypeStyles = EStyleSheet.create({
  EnterPaymentScreen: {
    backgroundColor: '#1D1D1D',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
  },
});

export const PaymentEntryColStyles = EStyleSheet.create({
  PaymentEntryCol: {
    flexGrow: 1,
    backgroundColor: '#191919',
  },
});

export const TotalSectionStyles = EStyleSheet.create({
  TotalDueContainer: {
    alignItems: 'center',
    marginTop: 68,
    marginBottom: 50,
  },
  TotalDueText: Title32x700,
});

export const TipSectionStyles = EStyleSheet.create({
  TipsText: Title20x700,
  TipPercentText: Title16,
  TipSelection: TipSelectionBaseStyle,

  TipsSectionContainer: {
    minWidth: 600,
    marginBottom: 50,
  },
  TipsTextRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  TipsAmountText: {
    ...Title12,
    fontWeight: '400',
  },
  TipsCalculationText: {
    ...Title12,
    fontWeight: '400',
    marginTop: 4,
  },
  CustomTipsTextContainer: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  CustomTipsInput: {
    ...Title32x700,
    textAlign: 'center',
  },
});

const TipSelectionBaseStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#454647',
  height: 84,
  flex: 1,
  borderWidth: 3,
  borderColor: '#454647',
};

export const TipSelectionStyle = (isFirst = false, isLast = false, isSelected = false) =>
  EStyleSheet.create({
    TipSelection: {
      ...TipSelectionBaseStyle,
      borderColor: isSelected ? '#4969F5' : '#454647',
      borderTopLeftRadius: isFirst ? 8 : 0,
      borderBottomLeftRadius: isFirst ? 8 : 0,
      borderTopRightRadius: isLast ? 8 : 0,
      borderBottomRightRadius: isLast ? 8 : 0,
    },
  });

export const ButtonStyle = (variant = 'primary', size = 'lg') => {
  const getPaddingX = () => {
    if (size === 'lg') return 42;
    if (size === 'sm') return 16;
  };
  const getPaddingY = () => {
    if (size === 'lg') return 24;
    if (size === 'sm') return 13;
  };

  return EStyleSheet.create({
    borderRadius: 8,
    backgroundColor: variant === 'primary' ? '#4969F5' : '#313131',
    color: 'white',
    paddingTop: getPaddingY(),
    paddingBottom: getPaddingY(),
    paddingLeft: getPaddingX(),
    paddingRight: getPaddingX(),
  });
};

export const PaymentCompletedStyles = EStyleSheet.create({
  ReceiptTypeText: Title16,
  PageContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: '#191919',
    alignItems: 'center',
  },
  CompletedIcon: {
    marginTop: 84,
    marginBottom: 32,
    color: '#0BA508',
  },
  ReceiptDoneIcon: {
    marginRight: 6,
    color: '#0BA508',
  },
  PaymentCompletedText: {
    ...Title32x700,
    marginBottom: 30,
  },
  ReceiptText: {
    ...Title20,
    marginTop: 44,
    marginBottom: 30,
  },
});

export const ReceiptPageStyles = EStyleSheet.create({
  BackText: Title20,
  ErrorText: {
    ...Title16,
    width: '100%',
    color: 'red',
    marginTop: 4,
    marginBottom: 30,
  },
  ReceiptText: {
    ...Title32x700,
    marginBottom: 30,
  },
  PageContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: '#191919',
    alignItems: 'center',
  },
  InputContainer: {
    ...Title16,
    alignItems: 'center',
    width: '30%',
  },
  ContactInput: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: '#313131',
    color: 'white',
  },
  BackRow: {
    width: '100%',
    paddingLeft: 32,
    paddingTop: 40,
    marginBottom: 16,
  },
});

export const CreditCardEntryStyles = EStyleSheet.create({
  PageContainer: {
    alignItems: 'center',
  },
  ManualCardContainer: {
    backgroundColor: '#313131',
    color: 'white',
    width: 600,
    padding: 16,
    borderRadius: 8,
    marginBottom: 30,
  },
});

export const SpecialInstructionsStyles = EStyleSheet.create({
  HeadingName: Title16,
  Container: {
    marginTop: 24,
  },
  TextArea: {
    color: 'white',
    backgroundColor: '#313131',
    borderRadius: 8,
  },
});
