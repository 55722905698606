// ==== NPM  ==== //
import React from 'react';
import { View, Text, BackHandler, Platform, Dimensions } from 'react-native';
import _ from 'lodash';
import uuid from 'uuid';
import { FormattedCurrency, FormattedMessage, withGlobalize } from 'react-native-globalize';
import PropTypes from 'prop-types';
import { Row } from 'native-base';
import { FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import Alert from '../components/Alert';

// ==== API ==== //
import API from '../api';

// ==== Components === //
import KeypadModal from '../components/KeypadModal';
import ItemViewer from '../components/ItemViewer';
import ItemBuilder from './ItemBuilder';

// ==== Models ==== //
import Cart from '../models/Cart';

import { LocationProvider } from '../providers/LocationProvider';
import { CartItem, ItemPart } from '../models';
import HeaderIconButton from '../components/HeaderIconButton';
import IconButton from '../components/IconButton';
import { Header } from '../bbot-component-library';
import Colors from '../constants/Colors';

/** *
 *  The Order Creation screen
 *
 *  Creates a Cart model, to which you can add and remove CartItems.
 */

class OrderCreator extends React.Component {
  // navigation Options injected after withGlobalize called, since withGlobalize seems to
  // break the navigation options when they are here...

  constructor(props) {
    super(props);
    const location = props.navigation.getParam('location');

    const cart = this._handleSetCart();

    props.navigation.setParams({
      onDelete: this._itemDelete,
      onToggleMultiSelect: this._onToggleMultiSelect,
    });

    this.state = {
      cart,
      location,
      selectedItem: null,
      selectedItems: [],
      selectedIndex: null,
      showNumGuestsChooser: !location.seated_group_id,
      windowWidth: Dimensions.get('window').width,
      multiselect: false,
      cartErrors: {}, // dict of errors for the cart. Each item id with
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    this.checkCartForErrors();
    const { location, cart } = this.state;
    this._locationListener = location.on('update', this.refresh);
    this._dimensionsListener = Dimensions.addEventListener('change', this._updateDimensions);
    this._cartListener = cart.on('update', () => {
      this.setState({ cart });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.cart._submitted && API.handheldDevice.getPreference('quick_order_mode')) {
      // This is running twice and creating two new carts when routed back here from success screen. RE: quick_order_mode
      const cart = this._handleSetCart();
      this.setState({ cart });
      this._itemBuilder._resetBreadcrumbs(); // Optional - clears out itembuilder breadcrumbs so it starts at menu selection instead of the last breadcrumb it was on (ex. "Shared Plates")
    }
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this._mounted = false;
    if (this._locationListener) this._locationListener.remove();
    this._dimensionsListener?.remove();
    this._cartListener.remove();
  }

  refresh = () => {
    if (this._mounted) {
      this._itemViewer.forceUpdate(); // this is terrible, optimize later
      this._itemBuilder.forceUpdate();
    }
  };

  handleBackPress = () => {
    const { navigation } = this.props;
    const { cart } = this.state;

    if (navigation.isFocused()) {
      if (this._itemBuilder && this._itemBuilder.state.breadcrumbs.length) {
        this._itemBuilder._breadcrumbClicked(this._itemBuilder.state.breadcrumbs.length - 2);
        return true;
      }
      if (cart.items.length) {
        Alert.alert(
          'Are you sure?',
          'You have an unfinished order. Clicking continue will delete it. Continue?',
          [
            { text: 'Cancel', style: 'cancel' },
            {
              text: 'Continue',
              onPress: () => {
                navigation.goBack();
              },
            },
          ],
        );
        return true;
      }
    }
  };

  _updateDimensions = ({ window }) => {
    const { width } = window;
    if (this._mounted) this.setState({ windowWidth: width });
  };

  render() {
    const {
      cartErrors,
      multiselect,
      location,
      cart,
      current,
      selectedItems,
      selectedItem,
      showNumGuestsChooser,
    } = this.state;
    const { navigation } = this.props;
    const tab_id = navigation.getParam('tab_id');

    return (
      <LocationProvider location={location}>
        <View style={{ flex: 1 }}>
          <ItemViewer
            ref={iv => (this._itemViewer = iv)}
            style={{ flexGrow: 0.4, flexShrink: 0, flexBasis: 0 }}
            items={cart.items}
            selectedItems={multiselect ? selectedItems : [selectedItem]}
            onItemPress={this._setSelectedItem}
            current={current}
            multiselect={multiselect}
          />
          <View
            style={{ alignItems: 'flex-end', borderTopWidth: 1, paddingRight: 10 }}
            testID="orderCreatorTotalCost"
          >
            <FormattedMessage
              id="common__colon__total"
              defaultMessage="Total: "
            />
            <FormattedCurrency value={cart.getTotal()} />
          </View>
          <Header>
            {!API.config.hide_seated_groups && (
              <IconButton
                testID="orderCreatorCopyButton"
                label="button__guests"
                defaultMessage="Guests"
                icon="people"
                badge={location.getNumGuests()}
                badgeProps={{ colorScheme: 'info' }}
                onPress={() => this.setState({ showNumGuestsChooser: true })}
              />
            )}
            <IconButton
              testID="orderCreatorCopyButton"
              label="button__copy"
              defaultMessage="Copy"
              icon="content-copy"
              iconType={MaterialIcons}
              onPress={this._copySelected}
              disabled={!selectedItem}
            />
            <IconButton
              testID="orderCreatorSplitButton"
              label="button__split"
              defaultMessage="Split"
              shown={!tab_id}
              icon="call-split"
              iconType={MaterialCommunityIcons}
              onPress={this._goToSplitOrder}
              disabled={!cart.items.length || !!selectedItem || !this._allItemsAvailable()}
              highlight={Platform.OS === 'web' && !Object.values(cartErrors).flat().length}
            />
            <IconButton
              testID="orderCreatorCheckoutButton"
              label="common__checkout"
              defaultMessage="Checkout"
              icon="shopping-cart"
              iconType={FontAwesome}
              shown={!tab_id}
              onPress={this._goToCardChooser}
              disabled={!cart.items.length || !!selectedItem || !this._allItemsAvailable()}
              highlight={Platform.OS === 'web' && !Object.values(cartErrors).flat().length}
            />
            <IconButton
              testID="orderCreatorPayNowButton"
              shown={API.config.use_stripe_chip_reader && !tab_id}
              label="button__quickPay"
              defaultMessage="Quick Pay"
              icon="payment"
              iconType={MaterialIcons}
              disabled={!cart.items.length || !!selectedItem || !this._allItemsAvailable()}
              highlight={!Object.values(cartErrors).flat().length}
              onPress={this._goToTipScreen}
            />
            <IconButton
              testID="orderCreatorAddToTab"
              shown={!!tab_id}
              disabled={!cart.items.length || !!selectedItem || !this._allItemsAvailable()}
              label="button__addToTab"
              defaultMessage="Add to Tab"
              icon="cash-multiple"
              iconType={MaterialCommunityIcons}
              onPress={this._addToTab}
              highlight={!Object.values(cartErrors).flat().length}
            />
          </Header>
          <ItemBuilder
            ref={itemBuilder => (this._itemBuilder = itemBuilder)}
            style={{ flexGrow: 0.6, flexShrink: 1, flexBasis: 0 }}
            cart={cart}
            location={location}
            numGuests={location.getNumGuests()}
            onItemChange={this._onItemChange}
            onModifiersChange={this._onModifiersChange}
            onDone={this._doneEditing}
          />

          {/*= ======== Guests Chooser Modal =========== */}
          <KeypadModal
            testID="guestChooserModal"
            title="Number of Guests"
            minValue={location.getNumGuests() || 1}
            maxValue={36}
            value={location.getNumGuests() || 1}
            visible={showNumGuestsChooser}
            onClose={this._changeNumGuests}
            onCancel={() => {
              this.setState({ showNumGuestsChooser: false });
            }}
          />
        </View>
      </LocationProvider>
    );
  }

  _handleSetCart = () => {
    const location = this.props.navigation.getParam('location');
    let cart = this.state?.cart || this.props.navigation.getParam('cart') || API._carts?.[0];
    const tab_id = this.props.navigation.getParam('tab_id');

    if (cart?._submitted) {
      API._carts = [];
      cart = null;
    }

    if (!cart) {
      cart = new Cart({
        location_id: location.id,
        tab_id,
        fulfillment_method: location.fulfillment_method,
        customer_id: API.getCustomerId(),
      });
      API._carts = [cart]; // currently just support 1 cart
    } else {
      cart.update({
        location_id: location.id,
        tab_id,
        fulfillment_method: location.fulfillment_method,
      });
      cart.resetChecks();
      /*
        let savedData = cart.compact()
        console.log(savedData);
        cart = new Cart(savedData);
      */
      // TODO: confirm all items are available at the current location
    }
    return cart;
  };

  // todo: put this into a state variable and update only when necessary
  _allItemsAvailable = () => {
    const { location, cart } = this.state;
    return cart.items.every(i => location.fulfillable_items.includes(i.menuItemId) && i.isValid());
  };

  /**
   *   If the server re-orders items from the OrdersView and those orderItems come from the consumer
   *   site then there will be no seat number associated with the order. Therefore run a validation across all cart Items
   *   to display errors when a seat number is not associated with the orders.
   */
  checkCartForErrors = () => {
    const { cart } = this.state;

    const cartErrors = {};
    cart.items.forEach((item, index) => {
      const itemErrors = item.hasModErrors([]);
      if (itemErrors.length > 0) {
        cartErrors[index] = item.hasModErrors();
      }
    });
    if (this._mounted) {
      this.setState({
        cartErrors,
        cart,
      });
    }
  };

  _changeNumGuests = async number => {
    const { location } = this.state;
    await location.changeNumGuests(number);

    this.setState(
      {
        showNumGuestsChooser: false,
        location,
      },
      () => {
        this.checkCartForErrors();
      },
    );
  };

  _copySelected = () => {
    const { multiselect, cart, selectedItem, selectedItems, location } = this.state;
    if (!selectedItem || !selectedItems.length) return;
    const num_guests = location.getNumGuests();

    const items = multiselect ? selectedItems : [selectedItem];
    let newItem;
    for (const item of items) {
      // newItem = _.cloneDeep(item);
      newItem = new CartItem(cart, item);
      // newItem.id = uuid.v4();
      newItem.seat_numbers = [];
      if (num_guests < 2) newItem.seat_numbers.push(1);
      cart.addItem(newItem);
    }

    if (!multiselect) this._setSelectedItem(newItem);
    else {
      this._itemViewer.forceUpdate();
    }
  };

  _setSelectedItem = item => {
    const { cart, multiselect, selectedItems } = this.state;
    const index = cart.items.indexOf(item);

    this.props.navigation.setParams({
      item,
    });

    this.setState({
      selectedIndex: index,
      selectedItem: item,
      selectedItems: _.xor(selectedItems, [item]),
    });

    if (!multiselect) this._itemBuilder.selectItem(item);
  };

  _onItemChange = (item, quickAdd) => {
    const { cart } = this.state;

    if (!this.state.selectedItem) {
      cart.addItem(item);
      if (!quickAdd) {
        this.props.navigation.setParams({
          item,
        });
      }
    } else {
      cart.items[this.state.selectedIndex] = item;
    }

    cart._needPriceCheck = true;
    this.setState(
      {
        cart,
        selectedItem: quickAdd ? null : item,
        selectedIndex: cart.items.indexOf(item),
        // items: this.state.items
      },
      () => {
        this.checkCartForErrors();
      },
    );
  };

  _onModifiersChange = current => {
    const { selectedItem } = this.state;
    this.setState({
      selectedItem,
      current,
    });
  };

  _itemDelete = () => {
    const { cart, multiselect, selectedItem, selectedItems } = this.state;
    const itemsToDelete = multiselect ? selectedItems : [selectedItem];
    if (!multiselect && !itemsToDelete[0]) {
      Alert.alert('Clear Cart?', 'This will remove all items from the cart', [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Continue',
          onPress: () => {
            cart.clear();
            this.setState({
              cart,
            });
          },
        },
      ]);
    } else {
      itemsToDelete.forEach(item => {
        // this.state.cart.items.splice(this.state.selectedIndex, 1);
        cart.removeItem(item);
      });
    }
    this.props.navigation.setParams({
      item: null,
      multiselect: false,
    });
    this.setState({
      selectedItem: null,
      selectedIndex: null,
      selectedItems: [],
      multiselect: false,
      // items: this.state.items
    });

    this._itemBuilder.itemDeleted();
    // this._itemBuilder.reset();
  };

  _onToggleMultiSelect = () => {
    const multiselect = !this.state.multiselect;
    this.setState({
      multiselect,
      selectedItem: null,
      selectedIndex: null,
      selectedItems: [],
    });
    this.props.navigation.setParams({ multiselect });
    if (multiselect) {
      this._itemBuilder.itemDeleted();
    }
  };

  _doneEditing = () => {
    this.props.navigation.setParams({ item: null });

    this.state.cart._needPriceCheck = true;

    this.setState(
      {
        selectedItem: null,
        selectedIndex: null,
      },
      () => {
        this.checkCartForErrors();
      },
    );
  };

  /**
   * @private
   */
  _goToSplitOrder = () => {
    const { navigation } = this.props;
    const { cart, location, cartErrors } = this.state;

    if (Object.values(cartErrors)?.length > 0 && Object.values(cartErrors).flat().length > 0) {
      Alert.alert(
        'Cannot proceed to checkout. Cart contains errors.',
        Object.values(cartErrors)
          .flat()
          .map(item => item.getName())
          .join('\r\n'),
      );
      return null;
    }
    this._updateCheckItems(cart);

    cart._needPriceCheck = true;
    navigation.navigate('SplitOrderScreen', {
      cart,
      location,
    });
  };

  _goToCardChooser = () => {
    const { navigation } = this.props;
    const { cart, location, cartErrors } = this.state;

    this._updateCheckItems(cart);

    navigation.navigate('CardChooser', {
      cart,
      location,
      check: cart.checks[0],
    });
  };

  /**
   * Hack to fix my shitty `cart.addItem` code, which doesn't account for copying/editing modifiers
   * or updating check items
   * @private
   */
  _updateCheckItems = cart => {
    const check = cart.checks[0];
    check.items = [];
    cart.items.forEach((item, i) => {
      item._parts = [];
      check.items.push(
        new ItemPart({
          check,
          item,
          numerator: item.qty,
        }),
      );
    });
  };

  _addToTab = () => {
    const { navigation } = this.props;
    const tab_id = navigation.getParam('tab_id');
    const tab = API._tabs[tab_id];

    this._goToTipScreen(tab);
  };

  _goToTipScreen = (card = null) => {
    const { navigation } = this.props;
    const { navigate } = navigation;
    const { cart } = this.state;
    const check = cart.checks[0];

    cart._needPriceCheck = true;
    if (cart.checks.length > 1) {
      cart.checks.length = 1;
    }

    this._updateCheckItems(cart);

    navigate('CheckoutScreen', {
      check,
      card,
      location: this.state.location,
    });
  };
}

OrderCreator.propTypes = {
  onNumGuestsChange: PropTypes.func,
};
OrderCreator.defaultProps = {
  onNumGuestsChange: () => {},
};

const OrderCreatorG = withGlobalize(OrderCreator);

OrderCreatorG.navigationOptions = ({ navigation }) => {
  const location = navigation.getParam('location');
  const item = navigation.getParam('item');
  const removeItemFn = navigation.getParam('onDelete');
  const multiSelectMode = navigation.getParam('multiselect');
  const toggleMultiSelect = navigation.getParam('onToggleMultiSelect');

  return {
    title: location.locationName,
    headerRight: () => (
      // location.uses_promo_codes ? <HeaderIconButton name={"Promos"} as={MaterialIcons} onPress={()=>{}} icon={"money-off"} key={'promos'}/> : null,
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <HeaderIconButton
          testID="toggleMultiSelect"
          textPath="button__multi"
          name="Multi"
          onPress={toggleMultiSelect}
          key="toggle"
          type={MaterialCommunityIcons}
          icon={
            multiSelectMode ? 'checkbox-multiple-marked-outline' : 'checkbox-multiple-blank-outline'
          }
        />
        <HeaderIconButton
          testID="orderCreatorRemoveButton"
          textPath={multiSelectMode || item ? 'common__remove' : 'common__clear'}
          name={multiSelectMode || item ? 'Remove' : 'Clear'}
          onPress={removeItemFn}
          type={Ionicons}
          key="remove"
          icon="trash"
        />
      </View>
    ),
  };
};

export default OrderCreatorG;
