/**
 * Model class for HandheldConfig aka DeviceProfile
 *
 * @author Gilles St-Cyr
 * @date 2019-03-15
 */

import _ from 'lodash';
import EventModel from './EventModel';

const ONE_HUNDRED_DOLLARS = 10000;
const screen_map = {
  show_locations_overview: 'LocationsScreen',
  show_stations_overview: 'StationsScreen',
  show_kds_view: 'KDSView',
  default: 'OrderSearchScreen',
};

// aka Device Profile
export default class HandheldConfig extends EventModel {
  id = null;

  customer_id = null;

  config_name = '';

  description = '';

  poll_interval = 20; // TODO: change this back to 10

  show_locations_overview = false;

  show_stations_overview = false;

  show_create_order = false;

  show_menu_editor = false;

  show_order_control = false;

  show_service_conditions = false; // Did we get rid of this? Or never finish it...

  show_kds_view = false;

  show_bot_control = false;

  show_tip_report = false;

  hide_seated_groups = false;

  use_stripe_chip_reader = false;

  /**
   * If defined, this location will be used for the new "Quick Order" mode UI and the
   * button will appear on the locations screen
   * @type {String}
   */
  quick_order_location = null;

  additional_closed_statuses = [];

  statuses_to_chirp_for = [];

  allow_disabling_chirp = false;

  receive_service_requests = false;

  force_volume_level = 1;

  default_tab_cents = ONE_HUNDRED_DOLLARS;

  tab_multiplier = 1.5;

  kds_station_ids = [];

  kds_show_prices = false;

  kds_edit_item_status = false; // Allows you to edit Order Item statuses individually

  kds_idle_screen = false;

  kds_partial_refunds = false;

  kds_group_related_tickets = false;

  kds_show_recall_button = false;

  locations = [];

  allow_manual_card_entry = false;

  allow_pay_with_consumer_tab = false;

  custom_kds_ticket_colors = {};

  // #kds_stations = []; // Mapped to array of Station models;
  // #monitored_stations = [];

  post_checkout_image = null;

  permissions = {
    add_edit_menu_items: 'Manager',
    allow_refunds: 'Staff',
    change_device: 'Staff',
    change_profile: 'Staff',
    move_item_status_backwards: 'Prompt',
    manage_reader: 'Prompt',
    manage_inventory: 'Manager',
    toggle_fulfillment_method_allowed: 'Prompt',
    toggle_location_order_allowed: 'Prompt',
    toggle_menu_order_allowed: 'Prompt',
    toggle_station_order_allowed: 'Prompt',
    reprint_ticket: 'Staff',
  };

  preferences = {
    kds_all_day_flash_screen: true,
    kds_all_day_grouping_rule: false,
    kds_always_advance_ticket_on_tap: false,
    kds_autofocus: true,
    kds_hide_checkout_info: false,
    kds_idle_screen: true,
    kds_min_ticket_height: null,
    kds_show_item_status: false,
    kds_show_waiting: false,
    kds_status_advance_top: false,
    kds_ticket_item_appearance: 'default',
    kds_use_high_contrast: false,
    keep_awake_on_battery: false,
    order_filters: {},
    quick_order_mode: false,
    theme: 'default',
  };

  menus = [];

  constructor(data = {}) {
    super();
    this._default_permissions = this.permissions; // capture defaults since they get overwritten
    this.update(data);
  }

  /**
   * Update Function
   * @param data
   */
  update = data => {
    super.update(data);
    this.permissions = Object.assign(this._default_permissions, data.permissions);
    this.kds_station_ids = data.kds_stations;
  };

  get kds_stations() {
    if (!Object.values(this.api._stations).length) return [];
    return _.orderBy(
      this.kds_station_ids.map(id => this.api._stations[id]),
      'station_name',
    );
  }

  set kds_stations(stations) {}

  /**
   * Returns the default start screen
   * @returns {string}
   */
  get default_screen() {
    if (this.show_kds_view) return screen_map.show_kds_view;
    if (this.show_stations_overview) return screen_map.show_stations_overview;
    if (this.show_locations_overview) return screen_map.show_locations_overview;
    return screen_map.default;
  }

  get customer() {
    return this.api._customers[this.customer_id] || {};
  }

  /**
   * Maps a screen testID to an access flag
   * @param screen
   * @returns {boolean}
   */
  hasScreenAccess(screen) {
    const map = {
      locations: this.show_locations_overview,
      stations: this.show_stations_overview,
      menu: this.show_menu_editor,
      ordercontrol: this.show_order_control && this.kds_station_ids?.length,
      kdsview: this.show_kds_view,
      tips: this.show_tip_report,
      tabs: this.allow_pay_with_consumer_tab,
      botcontrol: this.kds_stations.some(station => station?.stationControlsRobot),
    };

    // if screen is not in the map, it's not access controlled, so allow access:
    if (!(screen in map)) return true;
    return map[screen];
  }
}
