import _ from 'lodash';
import Model from './Model';

/**
 * EventModel allows a model to register events and trigger event listeners
 * With this, whenever the API updates a Model, it can trigger any registered events
 * and then whatever view is listening for the event can update the UI.
 *
 * This will be particularly handy once we implement socket connections
 */

export default class EventModel extends Model {
  constructor() {
    super();

    Object.defineProperty(this, '_events', {
      value: [],
      enumerable: false,
    });
  }

  update(obj, writeToDB, addMissingFields) {
    super.update(obj, writeToDB, addMissingFields);
    this.trigger('update', Object.keys(obj));
  }

  /**
   * Listen to an event on this model. Returns an object with a 'remove' function
   * which can be called to stop listening to the event.
   * @param event
   * @param fn
   * @returns {{remove: remove}}
   */
  on(event, fn) {
    if (!this._events[event]) this._events[event] = [fn];
    else this._events[event].push(fn);

    return {
      remove: () => {
        this._events[event] = _.without(this._events[event], fn);
      },
    };
  }

  off(event, fn) {
    _.pull(this._events[event], fn);
  }

  trigger(event, ...args) {
    if (this._events[event]) {
      this._events[event].forEach(fn => {
        if (typeof fn === 'function') {
          try {
            fn.apply(null, args);
          } catch (err) {
            console.error('Event listener failed to run. ', err);
            this.off(event, fn);
          }
        }
      });
    }
  }
}
