import _ from 'lodash';
import moment from 'moment';
import EventModel from './EventModel';

/**
 * @typedef {Object} ExtraFieldData Data for a one required field
 * @property {string} key The unique key for this required field
 * @property {InputType} type The type of the input field
 * @property {number} display_order The integer position
 * @property {boolean} remember TODO Unknown if relevant to Terminal
 * @property {boolean} required True if empty field is unacceptable on the patron checkout screen
 * @property {boolean} required_on_handheld - True if empty field is unacceptable on the handheld checkout screen
 * @property {boolean} show_on_handheld - True if this field should be prompted on the
 * @property {boolean} show_on_ticket TODO Probably irrelevant to Terminal
 * @property {boolean} default_choice True if this option is selected by default
 * @property {boolean} custom True if a custom field, false if preset
 * @property {string} name_for_bartender The display name for the KDS side
 * @property {string} name_for_patron The display name label of this field for the patron, or label of the checkbox
 * @property {?FieldChoice[]} choices TODO - Only appears on Custom Fields, which at the moment can't be multiple choice
 * @property {?string[]} dependent_on_fields The list of keys of other fields that this depends on
 */

/**
 * @typedef {Object} FieldChoice Data on a single choice in a multiple choice field
 * @property {string} label The text for this choice
 * @property {number} order The 1-indexed order relative to other FieldChoices in the multiple choices
 * @property {boolean} selected True if this choice is selected by default in this option's multiple choice field
 */

export default class Location extends EventModel {
  /** @type {?string} The location's UUID */
  id = null;

  /** @type {string} The location's unique short code */
  shortId = '';

  /** @type {string} The (potentially) human readable name for this location */
  locationName = '';

  allow_consumer_tabs = false;

  /** @type {'auto' | string} TODO Enumerate all possible values `allow_order_ahead` could possibly take */
  allow_order_ahead = 'auto';

  customer_id = null;

  /** @type {'' | string} TODO Enumerate all possible values `fulfillment_method` could possibly take */
  fulfillment_method = '';

  possible_fulfillment_methods = null;

  /** @type {array} If fulfillment_method is `patron_choice`, gives the available options:
  possible_fulfillment_methods = [];
  /** @type {boolean} If true, allows new orders to be made at this location. False prevents new orders */
  order_allowed = false;

  /** @type {Dictionary<ExtraFieldData>} Maps field keys (not uuid) to details on required checkout info field */
  required_checkout_info = {};

  runner_nav_note = '';

  seated_group_id = null;

  /** @type {moment.Moment} The moment that the guests were initially seated at this location, if there are guests */
  time_guests_seated = null;

  zone_for_reports = null;

  forced_tip_fraction = null; // If set to 0, disable tipping?

  forced_tip_name = '';

  // Internal fields:
  menu_ids = [];

  fulfillable_items = [];

  hash = null; // fulfillable_items hash

  uses_promo_codes = false;

  _field_map = {
    time_guests_seated: val => moment(val),
    fulfillable_items: fi => fi || this.fulfillable_items, // fulfillable_items is null when it doesn't need to be updated
  };

  constructor(data) {
    super(data);
    this.update(data);
  }

  detailsUpdate(obj) {
    this._last_modified = new Date();

    if (obj.fulfillable_items) this.fulfillable_items = obj.fulfillable_items;

    this.hash = obj.hash || null;
    this.menu_ids = obj.menu_ids || [];
    this.uses_promo_codes = obj.uses_promo_codes;

    this.trigger('update', this);
  }

  // This is debounced so if we call it multiple times on the same station, the trigger only gets fired once:
  ordersUpdated = _.debounce(order => {
    if (order.time > this.last_order_time) {
      this.last_order_time = order.time;
    }
    this.trigger('orders');
  }, 100);

  get customer() {
    return this.api._customers[this.customer_id];
  }

  get menus() {
    const { menuData } = this.api;
    if (!menuData) return [];
    return _.orderBy(
      menuData.menus.filter(menu => this.menu_ids.includes(menu.menuId)),
      ['display_position', 'menuName'],
    );
  }

  get orders() {
    const orders = _.sortBy(
      _.filter(
        this.api._orders,
        order =>
          order.location_id === this.id &&
          order.time >= this.time_guests_seated &&
          order.time > new Date() - 24 * 60 * 60 * 1000,
      ),
      ['time'],
    ).reverse();
    return orders;
  }

  get order_history() {
    return _.sortBy(
      _.filter(
        this.api._orders,
        order => order.location_id === this.id && order.time < this.time_guests_seated, // There is a seated group at the table
      ),
      ['time'],
    ).reverse();
  }

  get has_orders() {
    return !!this.orders.length;
  }

  get has_guests() {
    return !!this.seated_group_id;
  }

  get item_statuses() {
    const orderitems = [].concat(...this.orders.map(o => o.items));
    return _.mapValues(
      _.groupBy(orderitems, i => i.status),
      v => v.length,
    );
  }

  get seated_group() {
    return this.api._seated_groups[this.seated_group_id];
  }

  getNumGuests() {
    return this.seated_group ? this.seated_group.guests?.length : 0;
  }

  getSubTotal() {
    if (!this.orders.length) return 0;
    return this.orders.reduce((total, order) => (total += order.getSubTotal()), 0);
  }

  getOrderTotals() {
    const totals = {
      subTotal: 0,
      fees: 0,
      tax: 0,
      tip: 0,
      total: 0,
    };
    this.orders.forEach(order => {
      totals.subTotal += order.subtotal_cents;
      totals.fees += order.fee_pretax_cents;
      totals.tax += order.tax_cents;
      totals.tip += order.tip_cents;
      totals.total += order.total_cents;
    });

    return totals;
  }

  getTotal() {
    if (!this.orders.length && this.total_pretax > 0) return this.total_pretax;

    return this.orders.reduce(
      (total, order) => (total += order.getTotal()),
      0, // initial value
    );
  }

  get tabs() {
    return _.filter(this.api._tabs, tab => tab.locations.includes(this.id));
  }

  get open_tabs() {
    return _.filter(
      this.api._tabs,
      tab => tab.locations.includes(this.id) && tab.end_date.isAfter() && tab.tab_name,
    );
  }

  get closed_tabs() {
    return _.filter(
      this.api._tabs,
      tab => tab.locations.includes(this.id) && tab.end_date.isSameOrBefore(),
    );
  }

  get future_tabs() {
    return _.filter(
      this.api._tabs,
      tab => tab.locations.includes(this.id) && tab.start_date.isAfter(),
    );
  }

  get smart_orders() {
    return this.api._smart_orders.filter(o => o.location_id === this.id);
  }

  get has_service_requests() {
    return (
      Object.values(this.api._notices).filter(n => n.location_id === this.id && !n.time_read)
        .length > 0
    );
  }

  getSortedOrders() {
    const orders = [...this.orders];
    return orders.sort((o1, o2) => o1.orderNumberToday < o2.orderNumberToday);
  }

  getAllItems() {
    return _.reduce(this.orders, (all, curr) => all.concat(curr.items), []);
  }

  getHashedItems() {
    const itemMap = {};
    const items = [];

    this.orders.forEach(order => {
      order.items.forEach(item => {
        if (!itemMap[item.order_item_hash]) {
          item.qty = 1;
          itemMap[item.order_item_hash] = item;
          items.push(item);
        } else {
          itemMap[item.order_item_hash].qty++;
        }
      });
    });

    return items.sort((a, b) => a.itemName > b.itemName);
  }

  changeNumGuests = async numGuests => {
    // Do Sanity Checks before allowing reduction of numGuests
    try {
      let response;

      if (!this.seated_group) {
        // Seat New Group
        response = await this.api.seatGroup(this.id, numGuests);
        super.update();

        return response;
      }
      // Edit Existing Group
      const currGuests = this.seated_group.guests.length;
      if (numGuests >= currGuests) {
        for (let i = 0; i < numGuests - currGuests; i++) {
          this.seated_group.guests.push({
            card_ids: [],
            nickname: '',
            seat_number: this.seated_group.guests.length + 1,
            default_tip: null,
          });
        }
      }

      await this.seated_group.save();
      super.update();

      return {
        success: true,
        seated_group: this.seated_group,
      };
    } catch (err) {
      return {
        success: false,
        error: err,
      };
    }
  };

  showTipSection = () => {
    const { tipping } = this.api.customer.app_properties;
    return (
      this.forced_tip_fraction === null &&
      (tipping.for_fulfillment_methods.includes(this.fulfillment_method) ||
        tipping.for_fulfillment_methods.includes('all'))
    );
  };

  static Zones() {
    return _.uniq(_.map(this.api._locations, 'zone_for_reports')).sort();
  }

  static LocationsByZone() {
    const zones = _.groupBy(this.api.visible_locations, 'zone_for_reports');
    for (const zone in zones) zones[zone] = _.orderBy(zones[zone], 'locationName');
    return zones;
  }

  static AllLocationIDs() {
    return Object.keys(this.api._locations);
  }
}
