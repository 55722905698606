import React, { useState } from 'react';
import { View, FormControl, Button, Checkbox, Radio } from 'native-base';
import { Platform, TextInput } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import RenderHTML from 'react-native-render-html';
import Card from './Card';
import { formatPhoneNumber, validateEmail } from '../../helpers/HelperFunctions';

/**
 * PromptsToGuestCard
 * A Card visible on the Checkout Screen that is present when Cart.prompts_to_guests is not an empty array.
 * This appearing usually means that there is a Spendgo or Thanx integration attached to the Mx.
 *
 * @param cart an instance of the Cart model passed in through the CheckoutScreen index. The Cart methods setPromptsToGuest
 * and getCartPrice are utilized in this component.
 */

function PromptsToGuestCard({ cart }) {
  /** @type {[boolean, ReactHook<boolean>]} True if the email address is empty or valid */
  const [validEmailAddress, setValidEmailAddress] = useState(true);
  /** @type {[boolean, ReactHook<boolean>]} True if the phone number is empty or valid */
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  /**
   * setPromptValue replaced the Cart.prompts_to_guests field with a list of new prompts. Called whenever an Input
   * is changed
   * @param promptId - ID of a specific prompt in prompts_to_guest
   * @param value - new value of a prompt (name, phone number, email, etc.)
   * @param isMulti - indicates whether this is a checkbox or radio input, which requires different behavior
   */
  const setPromptValue = (promptId, value, isMulti = false) => {
    const newPrompts = cart.prompts_to_guest.map(prompt => {
      if (prompt.prompt_id === promptId) {
        if (isMulti) {
          prompt.choices = prompt.choices.map(choice => {
            // Value is an array for multichoice inputs
            choice.selected = value.includes(choice.id);
            return choice;
          });
        } else {
          prompt.value = value;
        }
      }
      return prompt;
    });
    cart.setPromptsToGuest(newPrompts);
  };

  /**
   * updateCartForLoyaltyPromo sets the pushed param of a Button prompt to true and then calls getCartPrice with the
   * updated prompts_to_guests
   * @param promptId ID of a prompt (usually a Button) in prompts_to_guest
   */
  const updateCartForLoyaltyPromo = promptId => {
    const newPrompts = cart.prompts_to_guest.map(prompt => {
      if (prompt.prompt_id === promptId) prompt.pushed = true;
      return prompt;
    });
    cart.setPromptsToGuest(newPrompts);
    cart._needPriceCheck = true;
    cart.getCartPrice();
  };

  return (
    <Card testId="loyaltyIntegrationsCard">
      <FormControl>
        {cart.prompts_to_guest.map((prompt, index) => (
          <View
            key={index}
            style={styles.prompt}
          >
            {prompt.prompt_type === 'label' && (
              <RenderHTML
                source={{ html: prompt.text }}
                // baseStyle prop cannot take a stylesheet, so we're just putting an inline style here
                baseStyle={{ fontWeight: 'bold', fontSize: index === 0 ? 16 : 14 }}
              />
            )}
            {prompt.prompt_type === 'textinput' && (
              <TextInput
                placeholder={prompt.heading}
                style={[styles.inputs, !validPhoneNumber && styles.invalidContactInfoInput]}
                onChangeText={val => setPromptValue(prompt.prompt_id, val)}
              />
            )}
            {prompt.prompt_type === 'phoneinput' && (
              <TextInput
                placeholder={prompt.heading}
                style={[styles.inputs, !validPhoneNumber && styles.invalidContactInfoInput]}
                keyboardType="phone-pad"
                onChangeText={val => setPromptValue(prompt.prompt_id, val)}
                onBlur={e => {
                  const newPhoneNumber = e.target.value;
                  const validity =
                    newPhoneNumber?.length === 0 || formatPhoneNumber(newPhoneNumber) !== null;
                  setValidPhoneNumber(validity);
                }}
              />
            )}
            {prompt.prompt_type === 'emailinput' && (
              <TextInput
                placeholder={prompt.heading}
                style={[styles.inputs, !validEmailAddress && styles.invalidContactInfoInput]}
                keyboardType="email-address"
                onChangeText={val => setPromptValue(prompt.prompt_id, val)}
                onBlur={e => {
                  const newEmail = e.target.value;
                  const validity = newEmail?.length === 0 || validateEmail(newEmail);
                  setValidEmailAddress(validity);
                }}
              />
            )}
            {prompt.prompt_type === 'multiselect_group' &&
              (prompt.min_selectable !== 1 || prompt.max_selectable !== 1) && (
                <Checkbox.Group
                  defaultValue={prompt.choices
                    .filter(({ selected }) => selected)
                    .map(({ id }) => id)}
                  onChange={vals => setPromptValue(prompt.prompt_id, vals || [], true)}
                >
                  {prompt.choices.map(({ contents, id }) => (
                    <Checkbox
                      key={id}
                      value={id}
                      style={{ marginBottom: 5 }}
                    >
                      {contents}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              )}
            {prompt.prompt_type === 'multiselect_group' &&
              !(prompt.min_selectable !== 1 || prompt.max_selectable !== 1) && (
                <Radio.Group
                  name="multichoiceRadioPrompt"
                  defaultValue={
                    prompt.choices.filter(({ selected }) => selected).map(({ id }) => id)[0]
                  }
                  onChange={val => setPromptValue(prompt.prompt_id, [val], true)}
                >
                  {prompt.choices.map(({ contents, id }) => (
                    <Radio
                      key={id}
                      value={id}
                      style={{ marginBottom: 5 }}
                    >
                      {contents}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            {prompt.prompt_type === 'applybutton' && (
              <Button
                onPress={() => updateCartForLoyaltyPromo(prompt.prompt_id)}
                disabled={!validEmailAddress || !validPhoneNumber}
              >
                {prompt.button_text}
              </Button>
            )}
            {prompt.prompt_type === 'cancelbutton' && (
              <Button onPress={() => updateCartForLoyaltyPromo(prompt.prompt_id)}>
                {prompt.button_text}
              </Button>
            )}
            {prompt.prompt_type === 'externallinkbutton' && (
              <Button
                target="_blank"
                href={prompt.external_link}
              >
                {prompt.button_text}
              </Button>
            )}
          </View>
        ))}
      </FormControl>
    </Card>
  );
}

export default PromptsToGuestCard;

const styles = EStyleSheet.create({
  prompt: {
    marginBottom: 15,
  },
  inputs: {
    fontSize: 14,
    marginVertical: 8,
    padding: 8,
    borderWidth: 1,
    borderColor: '#DDD',
    ...(Platform.OS === 'web' ? { boxSizing: 'border-box' } : {}),
    borderRadius: 4,
  },
  invalidContactInfoInput: {
    borderWidth: 1,
    borderColor: '#C00',
  },
  contactInfoErrorMessage: {
    color: '#c00',
    fontSize: 14,
  },
});
