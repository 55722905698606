import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { View } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import DateTimePicker from '@react-native-community/datetimepicker';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Icon } from 'native-base';
import { FontAwesome, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import {
  ActionSheet,
  FilterButton,
  FilterButtonInput,
  FilterButtonSecondary,
  FilterButtonToggle,
  LocationFilterModal,
} from './index';
import API from '../api';
import { offsetInput, offsetOutput } from '../helpers/DateTimePickerHelper';
import Location from '../models/Location';
import KDSOrdersView from '../screens/KDSOrdersView';
import { Colors } from '../styles';
import DraggableScrollView from './DraggableScrollView';
import { HeaderColors } from '../constants/FulfillmentMethods';
import Order from '../models/Order';

const TimeSpanOptions = {
  today: {
    label: 'Today',
    filter: v => moment().startOf('day').isSame(v, 'day'),
  },
  tomorrow: {
    label: 'Tomorrow',
    filter: v => moment().add(1, 'day').startOf('day').isSame(v, 'day'),
  },
  yesterday: {
    label: 'Yesterday',
    filter: v => moment().subtract(1, 'day').startOf('day').isSame(v, 'day'),
  },
  last_3_days: {
    label: 'Last 3 Days',
    filter: v =>
      moment().subtract(3, 'day').startOf('day').isSameOrBefore(v) &&
      moment().startOf('day').isSameOrAfter(v),
  },
  next_3_days: {
    label: 'Next 3 Days',
    filter: v =>
      moment().startOf('day').isSameOrBefore(v) &&
      moment().add(3, 'day').startOf('day').isSameOrAfter(v),
  },
  custom: {
    label: 'Custom',
    filter: (v, start, end) => v.isBetween(start, end, null, '[]'),
  },
};

export default class KDSFilteredOrdersView extends React.Component {
  _defaultFilters = {
    start_time: moment.tz(moment(), API.main_customer.timezone).startOf('day').add(-7, 'days'),
    end_time: moment.tz(moment(), API.main_customer.timezone).startOf('day').add(7, 'days'),
    created_date_filter: '',
    desired_date_filter: '',
    fulfillment_method: '',
    locations: Location.AllLocationIDs(),
    order_state: '',
    order_state_text: 'All',
    order_status: [], // Empty array == ALL
    order_status_text: 'All',
    search_input: '',
    sort_field: 'last_modified',
    sort_field_text: 'Last Modified',
    sort_order: 'desc',
    vendor: '',
    vendor_text: 'All',
  };

  _kdsView = null;

  state = {
    ...this._defaultFilters,
    show_location_filter_modal: false,
    show_time_picker: '',
    vendors: Object.values(API._customers),
  };

  async componentDidMount() {
    this._mounted = true;
    await this._deriveStateFromAsyncStorage();
    this._listeners = [
      API.on('locations', this._updateLocations),
      API.on('vendors', this._updateVendors),
    ];
  }

  componentWillUnmount() {
    this._mounted = false;
    this._listeners?.forEach(listener => listener.remove());
  }

  render() {
    const {
      start_time,
      end_time,
      created_date_filter,
      desired_date_filter,
      fulfillment_method,
      locations,
      order_state_text,
      order_status_text,
      search_input,
      show_location_filter_modal,
      show_time_picker,
      vendor_text,
      vendors,
      sort_field,
      sort_field_text,
      sort_order,
    } = this.state;

    const fulfillmentPrettyName =
      API.menuData.fulfillment_pretty_names[fulfillment_method] || 'All';
    const locationsAreFiltered = locations.length !== Location.AllLocationIDs().length;
    const minDate = show_time_picker === 'end_time' ? start_time.toDate() : null;

    return (
      <View style={{ flex: 1 }}>
        <View style={styles.filtersContainer}>
          <DraggableScrollView
            horizontal
            keyboardShouldPersistTaps="always"
            contentContainerStyle={styles.filters}
            nativeID="filters"
          >
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              <View style={styles.datetimeFilters}>
                <FilterButton
                  icon="clock"
                  iconType={FontAwesome5}
                  label="Created"
                  value={TimeSpanOptions[created_date_filter]?.label}
                  onPress={() => this._showDatePickerOptions('Created', 'created_date_filter')}
                  active={!!created_date_filter}
                />
                {created_date_filter === 'custom' && (
                  <>
                    <FilterButton
                      label="Start"
                      value={start_time.format('MMM D, LT')}
                      onPress={this._showStartTimePicker}
                    />
                    <FilterButton
                      label="End"
                      value={end_time.format('MMM D, LT')}
                      onPress={this._showEndTimePicker}
                    />
                  </>
                )}
              </View>
              <View style={styles.datetimeFilters}>
                <FilterButton
                  icon="clock"
                  iconType={FontAwesome5}
                  label="Desired"
                  value={TimeSpanOptions[desired_date_filter]?.label}
                  onPress={() => this._showDatePickerOptions('Desired', 'desired_date_filter')}
                  active={!!desired_date_filter}
                />
                {desired_date_filter === 'custom' && (
                  <>
                    <FilterButton
                      label="Start"
                      value={start_time.format('MMM D, LT')}
                      onPress={this._showStartTimePicker}
                    />
                    <FilterButton
                      label="End"
                      value={end_time.format('MMM D, LT')}
                      onPress={this._showEndTimePicker}
                    />
                  </>
                )}
              </View>

              <View style={{ flexDirection: 'row', paddingVertical: 5 }}>
                <FilterButton
                  label="Sort By"
                  value={sort_field_text}
                  onPress={this._showSortBy}
                />
                <FilterButton
                  label="Order"
                  value={_.capitalize(sort_order)}
                  onPress={this._showSortDir}
                />
                <FilterButton
                  label="Locations"
                  value={locationsAreFiltered ? 'Filtered' : 'All'}
                  onPress={this._showLocationFilterModal}
                />
                {vendors.length > 1 && (
                  <FilterButton
                    label="Vendor"
                    value={vendor_text || 'All'}
                    onPress={this._setVendor}
                  />
                )}
                <FilterButton
                  label="Fulfillment Method"
                  value={fulfillmentPrettyName}
                  onPress={this._setFulfillmentMethod}
                />
                <FilterButton
                  label="Order State"
                  value={order_state_text || 'All'}
                  onPress={this._setOrderState}
                />
                <FilterButton
                  label="Order Status"
                  value={order_status_text || 'All'}
                  onPress={this._setOrderStatus}
                />
                <FilterButtonInput
                  label="Search"
                  value={search_input}
                  onChange={this._setSearchInput}
                />
              </View>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row',
                paddingVertical: 5,
              }}
            >
              <FilterButtonSecondary
                label="RESET"
                onPress={this._resetFilters}
              />
            </View>
          </DraggableScrollView>
        </View>
        <KDSOrdersView
          ref={me => (this._kdsView = me)}
          filterFn={this._filterFn}
          sortField={sort_field}
          sortDir={sort_order}
          emptyMsg="No Results"
        />
        {!!show_time_picker && (
          <DateTimePicker
            onChange={this._handleDateChange}
            minimumDate={minDate}
            value={offsetInput(this.state[show_time_picker])}
            is24Hour
            mode="datetime"
          />
        )}
        {show_location_filter_modal && (
          <LocationFilterModal
            headerTitle="Filter by Locations"
            locations={locations}
            onCancel={() => this.setState({ show_location_filter_modal: false })}
            onSave={this._filterLocations}
          />
        )}
      </View>
    );
  }

  _filterFn = order => {
    const {
      created_date_filter,
      desired_date_filter,
      end_time,
      fulfillment_method,
      locations,
      order_state,
      search_input,
      start_time,
      vendor,
      order_status,
    } = this.state;
    const created_date = TimeSpanOptions[created_date_filter];
    const desired_date = TimeSpanOptions[desired_date_filter];
    return (
      (created_date ? created_date.filter(order.time) : true) &&
      (desired_date ? desired_date.filter(order.desired_time, start_time, end_time) : true) &&
      locations.includes(order.location_id) &&
      (vendor ? order.customer_id === vendor : true) &&
      (fulfillment_method ? order.fulfillment_method === fulfillment_method : true) &&
      (order_state ? order[order_state] : true) &&
      (search_input ? this._orderContainsSearchInput(order, search_input) : true) &&
      (order_status.length ? order_status.includes(order.status) : true)
    );
  };

  _resetFilters = () => {
    this.setState(this._defaultFilters, () => {
      this._refresh();
      this._saveStateToAsyncStorage();
    });
  };

  _refresh = () => {
    if (this._kdsView && this._mounted) this._kdsView.refresh(true);
  };

  _saveStateToAsyncStorage = async () => {
    const { show_location_filter_modal, show_time_picker, vendors, ...rest } = this.state;
    const filters = JSON.stringify(rest);
    await AsyncStorage.setItem('kds_filters', filters).catch(error =>
      console.log('Error saving to AsyncStorage: ', error),
    );
  };

  _deriveStateFromAsyncStorage = async () => {
    await AsyncStorage.getItem('kds_filters')
      .then(filters => {
        if (!filters) return;

        const filtersObj = JSON.parse(filters);
        filtersObj.start_time = moment(filtersObj.start_time);
        filtersObj.end_time = moment(filtersObj.end_time);

        this.setState(filtersObj, this._refresh);
      })
      .catch(error => console.log(error));
  };

  _handleDateChange = (event, date) => {
    const newState = { show_time_picker: false };
    if (date) {
      newState[this.state.show_time_picker] = moment(offsetOutput(date));
    }
    if (this._mounted) {
      this.setState(newState, () => {
        this._refresh();
        this._saveStateToAsyncStorage();
      });
    }
  };

  _showDatePickerOptions = (label, key) => {
    let options = Object.entries(TimeSpanOptions).map(([value, { label }]) => ({
      value,
      text: label,
    }));
    if (key === 'created_date_filter')
      options = options.filter(o => !['tomorrow', 'next_3_days'].includes(o.value));
    options.push({
      value: '',
      text: 'Clear',
      icon: (
        <Icon
          name="clear"
          style={{ color: '#000' }}
          as={MaterialIcons}
          size={7}
        />
      ),
    });
    ActionSheet.show(
      {
        title: `${label} Date Filter`,
        options,
      },
      id => {
        const val = options[id].value;
        let keyToClear = '';
        if (val) {
          keyToClear =
            key === 'created_date_filter' ? 'desired_date_filter' : 'created_date_filter';
        }
        this.setState(
          {
            [key]: val,
            ...(keyToClear ? { [keyToClear]: '' } : {}),
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _showStartTimePicker = () => {
    this.setState({
      show_time_picker: 'start_time',
    });
  };

  _showEndTimePicker = () => {
    this.setState({
      show_time_picker: 'end_time',
    });
  };

  _showLocationFilterModal = () => {
    this.setState({
      show_location_filter_modal: true,
    });
  };

  _showSortBy = () => {
    const sortBy = Object.keys(Order.SortableFields).map(key => ({
      value: key,
      text: Order.SortableFields[key],
    }));
    ActionSheet.show(
      {
        options: sortBy,
        title: 'Sort Field',
      },
      id => {
        const pressed = sortBy[id];
        this.setState(
          {
            sort_field: pressed.value,
            sort_field_text: pressed.text,
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _showSortDir = () => {
    const opts = [
      { text: 'Oldest First (Asc)', value: 'asc' },
      { text: 'Newest First (Desc)', value: 'desc' },
    ];
    ActionSheet.show(
      {
        options: opts,
        title: 'Sort Field',
      },
      id => {
        const pressed = opts[id];
        this.setState(
          {
            sort_order: pressed.value,
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _filterLocations = locations => {
    this.setState(
      {
        locations,
        show_location_filter_modal: false,
      },
      () => {
        this._refresh();
        this._saveStateToAsyncStorage();
      },
    );
  };

  _updateLocations = () => {
    this.setState({
      locations: Location.AllLocationIDs(),
    });
  };

  _updateVendors = () => {
    this.setState({
      vendors: Object.values(API._customers),
    });
  };

  _setVendor = () => {
    const opts = _.orderBy(
      _.map(this.state.vendors, vendor => ({
        text: vendor.customer_name,
        value: vendor.customer_id,
      })),
      'text',
    );
    opts.unshift({ text: 'All', value: '' });

    ActionSheet.show(
      {
        options: opts,
        title: 'Vendor',
      },
      id => {
        const pressed = opts[id];
        this.setState(
          {
            vendor: pressed.value,
            vendor_text: pressed.text,
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _setFulfillmentMethod = () => {
    const methods = _.uniq(
      [].concat(..._.map(API.config.kds_stations, 'possible_fulfillment_methods')),
    );
    const opts = _.orderBy(
      _.map(methods, key => ({
        icon: (
          <Icon
            name="square"
            size={7}
            as={FontAwesome}
            style={{ color: HeaderColors[key]?.color, marginRight: 5 }}
          />
        ),
        text: API.menuData.fulfillment_pretty_names[key],
        value: key,
      })),
      'text',
    );
    opts.unshift({ text: 'All', value: '' });

    ActionSheet.show(
      {
        options: opts,
        title: 'Fulfillment Method',
      },
      id => {
        const pressed = opts[id];
        this.setState(
          {
            fulfillment_method: pressed.value,
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _setOrderState = () => {
    const opts = [
      { text: 'All', value: '' },
      { text: 'Open', value: 'kds_open' },
      { text: 'Closed', value: 'kds_closed' },
      { text: 'Future', value: 'kds_future' },
    ];

    ActionSheet.show(
      {
        options: opts,
        title: 'Order State',
      },
      id => {
        const pressed = opts[id];
        this.setState(
          {
            order_state: pressed.value,
            order_state_text: pressed.text,
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _setOrderStatus = () => {
    const { order_status } = this.state;
    let available_statuses = new Set();
    API.config.kds_stations.forEach(station => {
      station.possible_fulfillment_methods.forEach(method => {
        API.menuData.status_sequences[method].forEach(status => {
          available_statuses.add(status);
        });
      });
    });
    available_statuses = Array.from(available_statuses);
    const options = available_statuses.map(o => ({
      value: o,
      text: API.menuData.status_pretty_names[o],
    }));
    // Here, empty array = ALL
    ActionSheet.show(
      {
        options,
        title: 'Order Status',
        multi: true,
        selected: order_status.length ? order_status : available_statuses,
        min_selected: 1,
      },
      choices => {
        this.setState(
          {
            order_status: choices.length === options.length ? [] : choices,
            order_status_text: choices.length === options.length ? 'All' : 'Filtered',
          },
          () => {
            this._refresh();
            this._saveStateToAsyncStorage();
          },
        );
      },
    );
  };

  _debouncedRefresh = _.debounce(() => {
    this._refresh();
    this._saveStateToAsyncStorage();
  }, 250);

  _setSearchInput = value => {
    this.setState(
      {
        search_input: value,
      },
      this._debouncedRefresh,
    );
  };

  _orderContainsSearchInput(order, input) {
    const { extra_checkout_info, items, orderNumber } = order;
    const extraCheckoutInfo = Object.values(extra_checkout_info);
    input = input.toLowerCase();

    if (String(orderNumber).toLowerCase().includes(input)) return true;

    const extraCheckoutInfoContainsSearchInput =
      extraCheckoutInfo &&
      extraCheckoutInfo.some(info => {
        const { key, name_for_bartender, value } = info;
        return (
          name_for_bartender?.toLowerCase().includes(input) ||
          value?.toString().toLowerCase().includes(input) ||
          (key === 'address' && this._addressContainsSearchInput(value, input))
        );
      });
    if (extraCheckoutInfoContainsSearchInput) return true;

    const itemsContainSearchInput =
      items &&
      items.some(item => {
        const { itemName, mods } = item;
        return (
          itemName.toLowerCase().includes(input) || this._itemModsContainSearchInput(mods, input)
        );
      });

    return itemsContainSearchInput;
  }

  _addressContainsSearchInput(address, input) {
    const { delivery_instructions, formatted_address } = address;
    return (
      delivery_instructions?.toLowerCase().includes(input) ||
      formatted_address?.toLowerCase().includes(input)
    );
  }

  _itemModsContainSearchInput(mods, input) {
    return (
      mods &&
      mods.some(mod => {
        const { mods, name } = mod;
        return name.toLowerCase().includes(input) || this._itemModsContainSearchInput(mods, input);
      })
    );
  }
}

const styles = EStyleSheet.create({
  datetimeFilters: {
    backgroundColor: '#f2f2f2',
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    flexDirection: 'row',
    paddingVertical: 5,
    marginLeft: 10,
  },
  filters: {
    alignItems: 'center',
    flexGrow: 1,
    paddingVertical: 5,
  },
  filtersContainer: {
    backgroundColor: 'white',
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
});
