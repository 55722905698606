import React from 'react';
import { Platform, View, Text, TouchableWithoutFeedback } from 'react-native';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import TabBarIcon from '../components/TabBarIcon';
import API from '../api';
// Todo: REMOVE THIS FILE
import SettingsStackNavigator from './SettingsStackNavigator';
import TabBarLabel from '../components/TabBarLabel';

SettingsStackNavigator.navigationOptions = ({ navigation }) => ({
  tabBarLabel: props => (
    <TabBarLabel
      {...props}
      labelPath="mainTabNavigator__settings"
    />
  ),
  tabBarTestID: 'settings',
  tabBarIcon: props => (
    <TabBarIcon
      {...props}
      name="md-settings"
    />
  ),
});

const KDSTabNavigator = createBottomTabNavigator(
  {
    // Todo: Add KDS Tabs Here
    SettingsStackNavigator,
  },
  {
    tabBarOptions: {
      activeBackgroundColor: '#000',
      inactiveBackgroundColor: '#000',
      inactiveTintColor: '#fff',
    },
    defaultNavigationOptions: ({ navigation }) => ({
      tabBarButtonComponent: CustomTabButton,
    }),
  },
);

class CustomTabButton extends React.Component {
  componentDidMount() {
    this._mounted = true;
    API.on('config_updated', this._refresh);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('config_updated', this._refresh);
  }

  _refresh = () => {
    if (!this._mounted) return;
    this.forceUpdate();
  };

  render() {
    const config = API.getConfig();
    if (!config) return null;
    const { onPress, onLongPress, testID, accessibilityLabel, ...props } = this.props;

    if (testID === 'locations' && !config.show_locations_overview) return null;
    if (testID === 'stations' && !config.show_stations_overview) return null;

    return (
      <TouchableWithoutFeedback
        onPress={onPress}
        onLongPress={onLongPress}
        testID={testID}
        hitSlop={{
          left: 15,
          right: 15,
          top: 5,
          bottom: 5,
        }}
        accessibilityLabel={accessibilityLabel}
      >
        <View {...props} />
      </TouchableWithoutFeedback>
    );
  }
}

export default KDSTabNavigator;
