/* ========== Packages ================= */
import React, { Component } from 'react';
import { View, BackHandler } from 'react-native';
import { Header, HeaderBackButton } from 'react-navigation-stack';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormattedMessage, withGlobalize } from 'react-native-globalize';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import Alert from '../components/Alert';

import API from '../api';
/*= =========== Models ============== */
import { ItemPart, CheckModel } from '../models';

import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

import KeypadModal from '../components/KeypadModal';
import IndicatorScrollView from '../components/IndicatorScrollView';
import CmdButton from '../components/CmdButton';
import CheckView from '../components/CheckView';
import Loader from '../components/Loader';
import SubmitOrderButton from '../components/SplitOrder/SubmitOrderButton';
import NewCheckPage from '../components/SplitOrder/NewCheckPage';
import { HeaderIconButton } from '../components';

class SplitOrderScreen extends Component {
  constructor(props) {
    super(props);

    const { navigation } = props;

    this.state = {
      loading: false,
      /** @type {Location} The Location object */
      location: navigation.getParam('location'),
      /** @type {?Cart} */
      cart: navigation.getParam('cart') || null,
      /** @type {?Order[]} Probably a list of Orders */
      orders: navigation.getParam('orders') || null,
      /** @type {CheckModel[]} */
      checks: [],
      /** @type {ItemPart[]} The list of selected ItemParts */
      selectedItems: [],
      /** @type {boolean} If true, the keypad modal for entering number of ways to split an item is shown */
      showSplitModal: false,
    };

    navigation.setParams({
      onSplit: () => this._showSplitItem(),
      onSplitBySeat: () => this._splitBySeat(),
      onBackPress: this._onBackPress,
    });

    this._resetView();
  }

  componentDidMount() {
    const { cart, location } = this.state;

    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this._onBackPress);
    this.state.location.on('update', this._update);
    this._cartListener = cart.on('update', this._cartUpdated);
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this.state.location.off('update', this._update);
    this._cartListener.remove();
  }

  _update = () => {
    this.state.checks.forEach(check => {
      if (!check.isValid()) {
        check.charge = null;
      }
    });
    this.forceUpdate();
  };

  _onBackPress = () => {
    const { navigation } = this.props;
    const { cart } = this.state;
    if (!navigation.isFocused()) return false;

    if (cart.checks.find(check => check.charge)) {
      Alert.alert(
        'Incomplete Order',
        'You have not yet submitted the order. Are you sure you want to abort?',
        [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Abort Checkout',
            onPress: () => {
              // todo: closetabs where available_cents == 0
              const tab_ids = cart.checks.reduce((res, check) => {
                if (check.charge && !check.charge.keep_open) res.push(check.charge.id);
                return res;
              }, []);
              API.closeTabs(tab_ids, cart.location_id);
              navigation.goBack();
            },
          },
        ],
      );
    } else if (cart.checks.length > 1) {
      Alert.alert('Are you sure?', "Any changes you've made will not be saved.", [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Abort Checkout',
          onPress: () => {
            navigation.goBack();
          },
        },
      ]);
    } else {
      navigation.goBack();
    }
    return true;
  };

  _resetView() {
    const { cart } = this.state;
    this.state.checks = [];
    this.state.selectedItems = [];

    if (this.state.cart) {
      this._splitCart(this.state.cart);
    }
    if (this.state.orders) {
      this._splitOrders(this.state.orders);
    }
  }

  /**
   * When the user clicks on Split Item, the modal for the number of ways to split the item is revealed. If no items are
   * selected, this method does nothing.
   * @private
   */
  _showSplitItem() {
    if (!this.state.selectedItems.length) return;
    this.setState({
      showSplitModal: true,
    });
  }

  /**
   * Given a Cart, moves all of the items in the Cart into one Check labeled 'Check 1' and makes it the only check in
   * the Cart.
   *
   * @param {Cart} cart The cart to add the item to.
   * @private
   */
  _splitCart(cart) {
    // Creates "Check 1"
    const check = new CheckModel(
      {
        label: 'Check 1',
        seat: 1,
      },
      cart,
    );

    // Populates Check 1 with all of the items in the cart
    cart._needPriceCheck = true;
    cart.items.forEach((item, i) => {
      item._parts = [];
      check.items.push(
        new ItemPart({
          check,
          item,
          numerator: item.qty,
        }),
      );
    });
    // Makes Check 1 the only check in the cart
    cart.checks = [check];
  }

  /**
   * This functionality is not supported yet
   *
   * @param {Order[]} orders
   * @private
   */
  _splitOrders(orders) {
    // Creates a new Check called 'Check 1'
    const check = new CheckModel(
      {
        label: 'Check 1',
        seat: 1,
      },
      this.state.cart,
    );

    // Populates Check one with all of the items in the list of Orders.
    orders.forEach(order => {
      order.items.forEach((item, i) => {
        item._parts = [];
        check.items.push(
          new ItemPart({
            check,
            item,
            numerator: item.qty,
          }),
        );
      });
    });
    // This screen then has only one check, with all the items in the orders
    this.state.checks = [check];
  }

  render() {
    return (
      <View
        testID="splitOrderScreen"
        style={{ flex: 1 }}
      >
        {this.state.loading && <Loader />}
        <View
          testID="splitOrderScreenChecksContainer"
          style={{ flex: 1 }}
        >
          <IndicatorScrollView
            columns={1}
            indicatorStyle={{
              width: 20,
              height: 20,
              borderRadius: 20,
              marginHorizontal: 20,
              marginBottom: 10,
            }}
          >
            {this._getGridPages()}
          </IndicatorScrollView>
        </View>
        {this._getSubmitOrderButton()}
        {/* The Keypad Modal where the Terminal User enters the number of ways to split the item */}
        <KeypadModal
          testID="splitOrderScreenKeypadModal"
          visible={this.state.showSplitModal}
          maxValue={10}
          minValue={1}
          value={2}
          overwrite
          title="Split item into how many parts?"
          onCancel={() => {
            this.setState({ showSplitModal: false });
          }}
          onClose={this._splitItem}
        />
      </View>
    );
  }

  _canPlaceOrder() {
    const { cart } = this.state;
    // todo make sure all charges have an amount > check amount
    return !this.state.loading && !cart.checks.find(check => check.items.length && !check.charge);
  }

  _getSubmitOrderButton = () => {
    const canPlaceOrder = this._canPlaceOrder();

    return (
      <SubmitOrderButton
        onPress={this.placeOrder}
        disabled={!canPlaceOrder}
      />
    );
  };

  _getGridPages() {
    const { checks } = this.state.cart;
    const numPages = Math.ceil((checks.length + 1) / 4); // add one for the "Add Check" tile

    const pages = [];

    _.times(numPages, n => {
      pages.push(
        <View
          testID="gridPage"
          key={n}
          style={{ width: Layout.window.width, flex: 1 }}
        >
          {this._getPage(n)}
        </View>,
      );
    });

    return pages;
  }

  /**
   * Gets the current Page Layout
   * @param n
   * @returns {JSX.Element}
   * @private
   */
  _getPage(n) {
    const { cart } = this.state;
    const start = n * 4;
    const checks = cart.checks.slice(start, start + 4);
    const checkout_began = cart.checks.some(check => check.charge);

    // Add the "New Check" Button
    if (checks.length < 4 && !checkout_began)
      checks.push(<NewCheckPage onPress={this._newCheck} />);

    // Tack on invisible checks so everything lays out nicely:
    while (checks.length < 4) {
      checks.push(
        <View style={{ margin: 10, flex: 1 }}>
          <View style={{ flex: 1 }} />
        </View>,
      );
    }

    const displayItems = checks.map((check, index) => {
      if (check instanceof CheckModel) {
        return (
          <View style={styles.cell}>
            <CheckView
              index={index}
              check={check}
              page={n}
              selectedItems={this.state.selectedItems}
              onDelete={this._deleteCheck}
              onDrop={this._moveSelectedItems}
              onItemPress={this._selectItem}
              onCheckout={this._startCheckout}
              onRemoveItem={this.onRemoveItem}
            />
          </View>
        );
      }
      return check;
    });

    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          {displayItems[0]}
          {displayItems[1]}
        </View>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          {displayItems[2]}
          {displayItems[3]}
        </View>
      </View>
    );
  }

  /**
   * Removes the given check from the cart. No change if the cart doesn't contain the given check.
   * @param {CheckModel} check
   * @private
   */
  _deleteCheck = check => {
    const { cart } = this.state;
    cart.checks = _.without(cart.checks, check);

    this.setState({
      cart,
      checks: cart.checks,
    });
  };

  /**
   * Toggles whether the given itemPart is selected or not. Disables the Split By Item button if no item parts are
   * selected anymore.
   *
   * @param {ItemPart} itemPart
   * @private
   */
  _selectItem = itemPart => {
    // Toggles whether the specific Item Part is selected or not
    const isSelected = this.state.selectedItems.includes(itemPart);
    if (isSelected) {
      this.state.selectedItems = _.without(this.state.selectedItems, itemPart);
    } else {
      this.state.selectedItems.push(itemPart);
    }
    // Split by Item Button should only be enabled if the list of selected items is non-empty
    this.props.navigation.setParams({
      splitDisabled: !this.state.selectedItems.length,
    });

    this.setState({
      selectedItems: this.state.selectedItems,
    });
  };

  /**
   * Moves the currently selected items to the `targetCheck`. Deselects all of the selected items, and updates the state
   * of the screen to reflect the new checks.
   *
   * @param {CheckModel} targetCheck
   * @private
   */
  _moveSelectedItems = targetCheck => {
    const { cart } = this.state;

    // Removes each selected item (which may each be from different checks) from their respective checks
    this.state.selectedItems.forEach(selectedItem => {
      const ownerCheck = selectedItem.check;
      ownerCheck.items = _.without(ownerCheck.items, selectedItem);
    });

    // Adds all of the selected items to the target check's list of items
    targetCheck.items = targetCheck.items.concat(this.state.selectedItems);
    targetCheck.items.forEach(i => (i.check = targetCheck));

    this.setState({
      selectedItems: [],
      cart,
      checks: cart.checks,
    });
  };

  /**
   * After the user has entered the number of ways to split the currently selected item into the Keypad Modal, this
   * method will split the first selected item into `numParts` equal pieces. Each split item will be added back to the
   * check that the first item belongs to. Deselects all selected items when complete.
   *
   * @param {number} numParts The integer number of ways to split the first selected item.
   * @private
   */
  _splitItem = numParts => {
    // Gets the first ItemPart in the list of selected ItemParts.
    /** @type {?ItemPart} */
    const item = this.state.selectedItems.length ? this.state.selectedItems[0] : null;

    if (item) {
      // this adds the split items back onto the current items check:
      item.check.items = item.check.items.concat(item.split(numParts));
    }

    this.setState({
      showSplitModal: false,
      selectedItems: [],
    });
  };

  _newCheck = () => {
    const { cart } = this.state;

    const newCheck = new CheckModel(
      {
        label: `Check ${cart.checks.length + 1}`,
      },
      cart,
    );

    cart.checks.push(newCheck);

    if (this.state.selectedItems.length) {
      this._moveSelectedItems(newCheck);
    } else {
      this.setState({
        checks: cart.checks,
      });
    }
  };

  _splitBySeat() {
    const { cart } = this.state;

    if (cart.checks.some(c => c.charge)) {
      return;
    }

    this._resetView();

    const seats = cart.checks[0].getUniqSeats();
    const maxSeat = _.max(seats);

    for (let i = 1; i < maxSeat; i++) {
      if (!cart.checks[i])
        cart.checks.push(new CheckModel({ label: `Check ${i + 1}`, seat: i + 1 }, cart));
    }

    cart.checks[0].items.forEach(itemPart => {
      if (itemPart.item.seat_numbers.length) {
        // Remove the item from the default check:
        cart.checks[0].items = _.without(cart.checks[0].items, itemPart);

        const numParts = itemPart.item.seat_numbers.length;
        // Split the item into equal parts, and assign one part to each guest:
        const parts = [itemPart, ...itemPart.split(numParts)];

        for (let i = 0; i < numParts; i++) {
          const seat_number = itemPart.item.seat_numbers[i];
          parts[i].check = cart.checks[seat_number - 1];
          cart.checks[seat_number - 1].items.push(parts[i]);
        }
      }
    });

    // Remove any empty checks:
    cart.removeEmptyChecks();

    this.setState({
      checks: cart.checks,
    });
  }

  _startCheckout = async check => {
    const { navigation } = this.props;
    const { location } = this.state;

    navigation.navigate('CardChooser', {
      check,
      location,
    });
  };

  onRemoveItem = item => {
    const { navigation } = this.props;
    const { cart } = this.state;
    cart.removeItem(item);
    cart.removeEmptyChecks();
    navigation.getParam('onGoBack')();
    if (!cart.checks.length) {
      navigation.goBack();
    } else {
      this.setState({
        cart,
      });
    }
  };

  _cartUpdated = () => {
    const { navigation } = this.props;
    const { cart } = this.state;

    this.setState({ cart });

    navigation.setParams({
      splitBySeatDisabled: cart.checks.some(c => c.charge),
    });

    // If there is only one check and it has a charge, automatically attempt to place the order. Otherwise, only place
    // the order when the button is pushed.
    if (cart.checks.length === 1 && cart.checks[0].charge) {
      this.placeOrder();
    }
  };

  // TODO: This should be unified with OrderCreator._postTipScreen in a helper
  placeOrder = async () => {
    const { cart } = this.state;
    const { navigation } = this.props;

    this.setState({
      loading: true,
    });

    const response = await cart.submit();

    if (response.errors.length) {
      this.setState({
        loading: false,
        errors: response.errors,
      });
      let message = '';
      if (response.removedItems.length) {
        message = ' The following item(s) are unavailable and were removed from your cart: \r\n';
        message += response.removedItems.join(', \r\n');
      } else if (response.errors.length) {
        message += response.errors[0];
      } else {
        message = 'An unexpected error occurred.';
      }
      Alert.alert('Checkout Failed', message);
    } else {
      navigation.navigate('LocationOverview');
    }
  };
}

SplitOrderScreen.propTypes = {};

SplitOrderScreen = withGlobalize(SplitOrderScreen);

// Need to add navigationOptions after withGlobalize:
SplitOrderScreen.navigationOptions = props => {
  const { navigation, navigationOptions } = props;
  const onSplit = navigation.getParam('onSplit');
  const onSplitBySeat = navigation.getParam('onSplitBySeat') || null;
  let splitDisabled = navigation.getParam('splitDisabled');
  const onBackPress = navigation.getParam('onBackPress');
  const location = navigation.getParam('location');
  if (splitDisabled === undefined) splitDisabled = true;
  const splitBySeatDisabled = navigation.getParam('splitBySeatDisabled');

  return {
    title: (
      <FormattedMessage
        id="title__splitChecks"
        defautlMessage="Split Checks"
      />
    ),
    headerRight: () => (
      <View style={{ flexDirection: 'row' }}>
        <HeaderIconButton
          testID="splitOrderScreenSplitItemButton"
          type={MaterialIcons}
          textPath="button__splitItem"
          name="Split Item"
          disabled={splitDisabled}
          onPress={onSplit}
          icon="call-split"
        />
        {location.getNumGuests() > 1 && (
          <HeaderIconButton
            testID="splitOrderScreenBySeatButton"
            type={MaterialIcons}
            textPath="button__bySeat"
            name="By Seat"
            disabled={splitBySeatDisabled}
            onPress={onSplitBySeat}
            icon="event-seat"
          />
        )}
      </View>
    ),
    headerLeft: () => (
      <HeaderBackButton
        onPress={onBackPress}
        tintColor={navigationOptions.headerTintColor}
        pressColorAndroid={navigationOptions.headerPressColorAndroid}
        backImage={navigationOptions.headerBackImage}
        titleStyle={navigationOptions.headerBackTitleStyle}
      />
    ),
  };
};

export default SplitOrderScreen;

const styles = EStyleSheet.create({
  cell: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'black',
    margin: 10,
  },
});
