import React, { useState } from 'react';
import StripeCardModal from './StripeCardModal';

let showStripeModalFn = null;

export const showStripeModal = props => {
  if (showStripeModalFn) {
    showStripeModalFn(props);
  } else {
    throw 'You must instantiate an instance of <GlobalStripeCardModal/> before calling this function';
  }
};

/**
 * Global Instance of the StripeCardModal function
 *
 * Usage:
 *
 * ```
 * import {showStripeModal}, GlobalStripeCardModal from 'components/Stripe/GlobalStripeCardModal'
 * showStripeModa({
 *   reusable: true,
 *   onSuccess: (result) => { // do Something }
 * });
 * ```
 *
 * ```
 * <MyMainView/>
 * <GlobalStripeCardModal/>  // Must be included somewhere in your app
 * ```
 *
 * @returns {JSX.Element}
 * @constructor
 */
function GlobalStripeCardModal() {
  const [visible, setVisible] = useState(false);
  const [reusable, setReusable] = useState(false);
  const [paymentIntentParams, setPaymentIntentParams] = useState({});
  const [onSuccess, setOnSuccess] = useState(() => {});
  const [onError, setOnError] = useState(() => {});
  const [headerText, setHeaderText] = useState(null);
  const [saveButtonText, setSaveButtonText] = useState('SAVE');

  showStripeModalFn = ({
    reusable,
    paymentIntentParams,
    onSuccess,
    onError,
    headerText,
    saveButtonText,
  }) => {
    setReusable(reusable);
    setPaymentIntentParams(paymentIntentParams);
    setOnSuccess(() => result => {
      onSuccess(result);
      setVisible(false);
    });
    setOnError(() => onError);
    setHeaderText(headerText);
    setSaveButtonText(saveButtonText);
    setVisible(true);
  };

  return (
    <StripeCardModal
      visible={visible}
      reusable={reusable}
      paymentIntentParams={paymentIntentParams}
      onSuccess={onSuccess}
      onClose={() => {
        setVisible(false);
      }}
      onError={onError}
      headerText={headerText}
      saveButtonText={saveButtonText}
    />
  );
}

export default GlobalStripeCardModal;
