import React, { useEffect, useRef } from 'react';
import { Platform, ScrollView } from 'react-native';

let DraggableScrollView;

if (Platform.OS === 'android') {
  DraggableScrollView = function ({ children, scrollRef, ...props }) {
    return (
      <ScrollView
        ref={scrollRef}
        {...props}
      >
        {children}
      </ScrollView>
    );
  };
} else {
  DraggableScrollView = function ({ children, scrollRef, ...props }) {
    const sv = useRef(null);

    useEffect(() => {
      if (scrollRef) scrollRef(sv.current);

      const slider = sv.current.getScrollableNode();
      if ('horizontal' in props) {
        slider.classList.add('draggable-scroll');
        let isDown;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', e => {
          isDown = true;
          slider.classList.add('active');
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', e => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; // scroll-fast
          slider.scrollLeft = scrollLeft - walk;
        });
      }
    }, [sv]);

    return (
      <ScrollView
        {...props}
        ref={sv}
      >
        {children}
      </ScrollView>
    );
  };
}

export default DraggableScrollView;
