import React from 'react';
import { View, Text, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import OrderListView from '../components/OrderListView';
import Station from '../models/Station';
import OrderItemStatusChanger from '../components/OrderItemStatusChanger';
import Loader from '../components/Loader';
import API from '../api';
import Colors from '../constants/Colors';
import OrderListViewHeader from '../components/OrderListViewHeader';
import ClaimButton from '../components/IconButtons/ClaimButton';
import IconFooter from '../bbot-component-library/IconFooter';

// TODO: This view doesn't update once opened

class StationView extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const station = navigation.getParam('station');
    return {
      title: station.station_name,
    };
  };

  constructor(props) {
    super(props);
    const station = props.navigation.getParam('station');
    const filters = props.navigation.getParam('filters');
    this.state = {
      station,
      filters,
      orders: station.getFilteredOrders(filters.showClosed, filters.showSnoozed),
      selectedItems: [],
      loading: false,
      dataChanged: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    API.on('orders', this._update);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('orders', this._update);
  }

  render() {
    const { orders, selectedItems, dataChanged } = this.state;

    return (
      <View style={{ height: '100%' }}>
        <Loader shown={this.state.loading} />
        <OrderListViewHeader />
        <FlatList
          data={orders}
          renderItem={this._renderItem}
          style={{ height: 5 }}
          contentContainerStyle={{ flexGrow: 1 }}
          extraData={this.state.dataChanged}
          keyExtractor={item => item.orderId}
          ListEmptyComponent={this._listEmpty}
        />
        <View>
          <OrderItemStatusChanger
            selectedItems={this.state.selectedItems}
            onChange={this.refresh}
            allowRegress
          />
          <IconFooter>
            <ClaimButton
              selectedItems={selectedItems}
              onPress={() => this.setState({ selectedItems: [...selectedItems] })}
            />
          </IconFooter>
        </View>
      </View>
    );
  }

  _getStationOrders = () => {
    const { station, filters } = this.state;
    return station.getFilteredOrders(filters.showClosed, filters.showSnoozed);
  };

  _update = async () => {
    if (this._mounted) {
      this.setState({
        dataChanged: !this.state.dataChanged,
        orders: this._getStationOrders(),
      });
    }
  };

  _renderItem = data => {
    const order = data.item;
    return (
      <OrderListView
        order={order}
        onHeaderPress={this._setSelectedItems}
        onItemPress={this._setSelectedItems}
        selectedItems={this.state.selectedItems}
      />
    );
  };

  _listEmpty = (
    <View style={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
      <Text style={{ color: Colors.gray }}>No Orders</Text>
    </View>
  );

  refresh = async () => {
    const { filters, station } = this.state;

    // todo: this could probably use the 'dataChanged' flag instead of the setState callback
    this.setState(
      {
        selectedItems: [],
        loading: true,
        orders: [],
      },
      () => {
        this.setState({
          loading: false,
          orders: station.getFilteredOrders(filters.showClosed, filters.showSnoozed),
        });
      },
    );
  };

  _setSelectedItems = (obj, selectedItems) => {
    this.setState({
      selectedItems: [...selectedItems],
      dataChanged: !this.state.dataChanged,
    });
  };

  static propTypes = {
    station: PropTypes.instanceOf(Station),
  };
}

export default StationView;
