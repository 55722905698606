import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableHighlight } from 'react-native';
import _ from 'lodash';
import { FormattedCurrency, FormattedMessage } from 'react-native-globalize';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Checkbox } from 'native-base';
import API from '../api';
import Colors from '../constants/Colors';
import StatusIcon from './StatusIcon';
import OrderItemModifiers from './OrderItemModifiers';
import CartItemModifiers from './CartItemModifiers';
import { getTextColor } from '../helpers/DynamicColors';

export default class OrderItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onPress: PropTypes.func,
    selected: PropTypes.bool,
    compact: PropTypes.bool,
    showTaxes: PropTypes.bool,
    current: PropTypes.object,
  };

  static defaultProps = {
    compact: false,
    onPress: () => {},
  };

  componentDidMount() {
    API.on('cart', this._refresh);
  }

  componentWillUnmount() {
    API.off('cart', this._refresh);
  }

  _refresh = () => {
    // Inefficient AF.  Open to better options.
    this.forceUpdate();
  };

  render() {
    const { item, selected, showTaxes, compact, showCheckbox, onPress, current } = this.props;
    if (_.isEmpty(item)) {
      return this._noItem();
    }

    let priceEach;
    let lastChange;
    let fulfillable = true;
    const itemNameStyle = {};

    if (item.constructor.className === 'OrderItem') {
      priceEach = item.tax_determined_total_cents / 100;
      lastChange = item.time_modified.fromNow();
    }
    if (item.constructor.className === 'CartItem') {
      priceEach = item.getTotal();
      if (!item.location.fulfillable_items.includes(item.menuItemId)) {
        itemNameStyle.color = 'red';
        fulfillable = false;
      }
    }

    const priceTotal = priceEach * item.qty;
    const bodyStyle = {
      backgroundColor: selected
        ? item.status === 'refunded'
          ? '#fffcb6'
          : Colors.primary
        : undefined,
    }; // styles['status_' + item.status] - can maybe look at status index within status_sequence, and have a color scale.

    if (item.status === 'refunded') {
      itemNameStyle.textDecorationLine = 'line-through';
      itemNameStyle.textDecorationStyle = 'solid';
    }

    const textStyle = {
      color: getTextColor(bodyStyle.backgroundColor),
    };

    return (
      <TouchableHighlight
        testID={`menuItem-${item.getName()}`}
        accessible
        accessibilityLabel={`oi_${item.getName()}`}
        title={`button ${item.id}`}
        onPress={() => {
          onPress && onPress(item);
        }}
        underlayColor="#bbbbbb"
      >
        <View style={[styles.body, bodyStyle]}>
          {this._getImage(textStyle)}
          <View style={{ flex: 1 }}>
            <View style={styles.titleRow}>
              {showCheckbox && (
                <View style={{ marginRight: 20 }}>
                  <Checkbox
                    checked={selected}
                    onPress={() => onPress(item)}
                  />
                </View>
              )}
              {item.status === 'refunded' ? (
                <Text
                  testID="menuItemRefundedMessage"
                  style={{ marginRight: 5 }}
                >
                  [Refunded]
                </Text>
              ) : null}
              <Text style={[styles.itemTitle, textStyle, itemNameStyle]}>{item.getName()}</Text>
              <View style={{ alignItems: 'flex-end', width: '10%' }}>
                <Text
                  testID="menuItemQuantity"
                  style={[styles.amount, textStyle]}
                >
                  {item.qty}
                </Text>
              </View>
              <View
                testID="menuItemPriceEach"
                style={[styles.amount, { width: '18%' }]}
              >
                <FormattedCurrency
                  value={priceEach || 0}
                  style={textStyle}
                />
              </View>
              <View
                testID="menuItemPriceTotal"
                style={[styles.amount, { width: '18%' }]}
              >
                <FormattedCurrency
                  value={priceTotal || 0}
                  style={textStyle}
                />
              </View>
            </View>
            <View style={[styles.details, { marginLeft: showCheckbox ? 40 : 0 }]}>
              <View style={styles.detailRow}>
                {!fulfillable && (
                  <Text style={[textStyle, { fontStyle: 'italic' }]}>
                    No longer available. Remove from cart to continue.
                  </Text>
                )}
                {!compact && OrderItem.getModifiers(item, this.props.current, textStyle, true)}
                {!compact && OrderItem.getSpecialInstructions(item, textStyle)}
                {this._getSeats(textStyle)}
                {this._getClaimedBy(item, textStyle)}
              </View>
              <View style={styles.otherInfo}>
                <Text style={[styles.timestamp, textStyle]}>{lastChange}</Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    );
  }

  _getClaimedBy(item, textStyle) {
    const isMe = item.claimed_by === API.handheldDevice.name;
    const device = (
      <FormattedMessage
        id={isMe ? 'device__thisDevice' : 'useDefaultMessage'}
        defaultMessage={item.claimed_by}
      />
    );
    return item.claimed_by ? (
      <FormattedMessage
        id="order__claimedBy"
        values={{ device }}
        style={[{ fontStyle: 'italic' }, textStyle, isMe ? { color: Colors.highlight } : null]}
      />
    ) : null;
  }

  _getImage(textStyle) {
    const { item } = this.props;
    if (item.status) {
      return (
        <StatusIcon
          status={item.status}
          style={{ height: 24, width: 24, marginRight: 5 }}
          color={textStyle.color}
        />
      );
    }
  }

  _noItem() {
    return (
      <View style={styles.body}>
        <Text>Loading...</Text>
      </View>
    );
  }

  /**
   * @param {OrderItem | CartItem | unknown} item
   * @param current=
   * @param style
   * @param showModifierRemoveButton
   * @param {ItemPart} itemPart
   * @returns {JSX.Element|(unknown[]|JSX.Element)[]}
   */
  static getModifiers(item, current, style, showModifierRemoveButton = false, itemPart) {
    if (item.constructor.className === 'OrderItem') {
      return (
        <OrderItemModifiers
          style={style}
          item={item}
        />
      );
    }
    if (item.constructor.className === 'CartItem') {
      return (
        <CartItemModifiers
          current={current}
          item={item}
          itemPart={itemPart}
          style={style}
          showModifierRemoveButton={showModifierRemoveButton}
        />
      );
    }
  }

  static getModifierPrice(modifier, style) {
    if (modifier.pretax_cents) {
      return (
        <Text>
          [+
          <FormattedCurrency
            style={style}
            value={modifier.pretax_cents / 100}
          />
          ]
        </Text>
      );
    }
  }

  /**
   *
   * @param item
   * @param style
   * @returns {?JSX.Element} The <Text> with the special instructions, or nothing if there are no special instructions
   */
  static getSpecialInstructions(item, style = null) {
    if (item.special_instructions) {
      return <Text style={[style, styles.special_instructions]}>{item.special_instructions}</Text>;
    }
  }

  _getSeats(style) {
    const { item } = this.props;
    const { location } = item;
    if (!location) return null;

    if (location.getNumGuests() > 1) {
      if (item.seat_numbers.length) {
        return <Text style={[style, styles.seats]}>[Seat #{item.seat_numbers.join(', #')}]</Text>;
      }
      if (item.errors?.includes('seats')) {
        return (
          <Text style={[style, styles.seats, { color: Colors.error }]}>[ Missing Seat # ]</Text>
        );
      }
    }
  }
}

export const styles = EStyleSheet.create({
  body: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8',
    minHeight: 40,
    marginTop: 1,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemTitle: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
  },
  itemSelected: {
    backgroundColor: Colors.primaryLight,
  },
  amount: {
    alignItems: 'flex-end',
  },
  details: {
    flexDirection: 'row',
    flex: 1,
  },
  detailRow: {
    flex: 1,
    marginLeft: 5,
  },
  otherInfo: {
    flexDirection: 'row',
  },
  special_instructions: {
    color: Colors.secondary,
  },
  timestamp: {
    color: '#787878',
    fontSize: 12,
  },
  status_waiting: {
    backgroundColor: Colors.waiting,
  },
  status_making: {
    // kitchen has taken the order
    backgroundColor: Colors.making,
  },
  status_humandelivering: {
    // ready for pickup
    backgroundColor: Colors.humandelivering,
  },
  status_humandelivered: {
    //
    // backgroundColor: Colors.humandelivered
  },
  seats: {
    fontSize: 12,
    fontStyle: 'italic',
    color: '#767676',
  },
});
