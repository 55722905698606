import { Audio } from 'expo-av';
import { Sounds } from '../constants/Constants';
import API from '../api';

export default class WebSetup {
  api = null;

  constructor(api) {
    window.name = 'BbotTerminal';
    this.api = api;
    this.initWebSocket();
    this.registerTouchHandler(api);
    this.addAdditionalStylesheets();
    this.registerUnloadHandler();

    this.registerVisibilityListener(api);
  }

  /**
   * The websocket connection should keep the KDS alive while it's in a background tab
   */
  initWebSocket = () => {
    const ws = new WebSocket('wss://echo.websocket.events');
    ws.onopen = () => {
      console.log('WebSocket connected');
    };
  };

  registerVisibilityListener = () => {
    document.addEventListener('visibilitychange', () => {
      API.trigger('visible', !document.hidden);
    });
  };

  /**
   * Detect every time the screen is touched so that our onInaction events work
   * We need this since our top level view 'onTouchStart' handler in App.js doesn't work in Web
   */
  registerTouchHandler = API => {
    document.addEventListener('click', API.touched);

    /**
     * Initialize Audio systems to play on touch, since this is required on iOS for each audio stream
     * @returns {Promise<void>}
     */
    const touchEnd = async () => {
      if (!API.audioInitialized) await API.playNotificationSound();
      document.removeEventListener('touchend', touchEnd);
    };
    // load
    document.addEventListener('touchend', touchEnd);
  };

  /**
   * Here we're adding some extra stylesheet info to fix issues in the web build:
   */
  addAdditionalStylesheets = () => {
    const css = document.createElement('style');
    css.setAttribute('type', 'text/css');
    /**
     * We need `not(input)` on data-focusable because otherwise it breaks on iOS
     */
    css.textContent = `
      .simplepicker-wrapper.active {
        z-index: 9999 
      }
      [data-focusable]:not(input) { 
        cursor: pointer; 
        user-select: none; /* supported by Chrome and Opera */
       -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
      }
      @media (hover: hover) and (pointer: fine) {
        .draggable-scroll::-webkit-scrollbar {
          height: 4px;      
        }
        .draggable-scroll::-webkit-scrollbar-track {
          height: 5px;      
        }
        .draggable-scroll::-webkit-scrollbar-thumb:horizontal {
          border-radius: 5px;
          background-color: #333;
        }
      }
      input:focus-visible {
        outline: none
      }
    `;
    document.head.appendChild(css);
  };

  /**
   * In production mode, we want to show a warning message when someone is about to navigate away from the
   * Web Terminal
   */
  registerUnloadHandler = () => {
    if (process.env.NODE_ENV === 'production') {
      window.addEventListener('beforeunload', e => {
        const confirmationMessage =
          'You are attempting to close Bbot Terminal. ' +
          'Are you sure this is want you want to do?';

        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
      });
    }
  };
}
