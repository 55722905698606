import {
  InterfaceType,
  StarConnectionSettings,
  StarPrinter,
  StarXpandCommand,
} from 'react-native-star-io10';
import Printer from './Printer';

/**
 * StarImagePrinter
 * @author Justin Belardi
 * @description Handles sending print jobs to Star Printers. sendPrintTask()
 * utilizes react-native-star-io10 library to send base64 PNG images to Star Printers.
 */

export default class StarImagePrinter extends Printer {
  constructor(printerConfigDict) {
    super(printerConfigDict);
    this.pixelWidth = printerConfigDict.pixel_width_for_terminal;
  }

  sendPrintTask = async taskDict =>
    new Promise(async (resolve, reject) => {
      const settings = new StarConnectionSettings();
      settings.interfaceType = InterfaceType.Lan;
      settings.identifier = this.ip;

      const printer = new StarPrinter(settings);

      try {
        const contents = taskDict?.actions[0]?.png_b64;
        if (!contents) resolve({ error: 'We tried to Print to Star Printer without a PNG' });

        const builder = new StarXpandCommand.StarXpandCommandBuilder();
        builder.addDocument(
          new StarXpandCommand.DocumentBuilder().addPrinter(
            new StarXpandCommand.PrinterBuilder()
              .actionPrintImage(
                new StarXpandCommand.Printer.ImageParameter(contents, this.pixelWidth),
              )
              .actionCut(StarXpandCommand.Printer.CutType.Partial),
          ),
        );

        const commands = await builder.getCommands();
        await printer.open();
        await printer.print(commands);
        resolve({ success: true });
      } catch (error) {
        console.error(error);
        resolve({
          error,
        });
      } finally {
        await printer.close();
        await printer.dispose();
      }
    });
}
