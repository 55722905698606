import React, { useContext, useEffect } from 'react';
import { View, Text } from 'native-base';
import { Pressable, ScrollView } from 'react-native';
import { CartColStyles as styles } from '../../styles/POSStyles';
import SubtotalLine from './SubtotalLine';
import POSContext from '../../contexts/POSContext';
import CartItem from './CartItem';
import { POS_REDUCER_ACTIONS, ITEM_SELECTOR_COL_VIEWS, POS_SCREEN } from '../../screens/POS';
import EmptyState from '../../bbot-component-library/POS/EmptyState';

function CartCol(props) {
  // Hooks
  const {
    POSDispatch,
    POSValues: { isGettingFees, cart, cart: { items: cartItems } = { items: [] } },
  } = useContext(POSContext);

  const {
    CartCol,
    Header,
    HeaderNewOrder,
    HeaderClearOrder,
    CartItemsContainer,
    SubtotalsContainer,
    Checkout,
    CheckoutText,
  } = styles;

  const check = cart?.checks[0]; // NOTE: ASSUMING SINGLE CHECK!!!
  const pretaxCents = check?.pretax_total ?? 0;
  const taxCents = check?.tax_total ?? 0;
  const totalCents = check?.total ?? 0;

  const fees = cart?.checks[0]?.fees ?? [];

  const clearOrder = () => {
    POSDispatch({
      type: POS_REDUCER_ACTIONS.CLEAR_ORDER,
    });
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
      itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.SELECT_ITEM,
    });
  };

  const goToCheckout = () => {
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
      itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.SELECT_ITEM,
    });
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_POS_SCREEN,
      POSScreen: POS_SCREEN.CHECKOUT,
    });
  };

  return (
    <View style={CartCol}>
      <View style={Header}>
        <Text style={HeaderNewOrder}>New Order</Text>
        <Pressable>
          <Text
            style={HeaderClearOrder}
            onPress={clearOrder}
          >
            Clear Order
          </Text>
        </Pressable>
      </View>

      {cartItems.length > 0 ? (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={CartItemsContainer}
        >
          {cartItems.map(cartItem => (
            <CartItem
              key={cartItem.id}
              cartItem={cartItem}
            />
          ))}
        </ScrollView>
      ) : (
        <EmptyState message="There are no items in this order. Use the menu on the left to add items." />
      )}

      <View style={SubtotalsContainer}>
        <SubtotalLine
          subtotalName="Subtotal"
          subtotalAmount={pretaxCents}
        />
        {fees?.map(fee => (
          <SubtotalLine
            key={fee.id}
            subtotalName={fee.name}
            subtotalAmount={fee.pretax_cents}
          />
        ))}
        <SubtotalLine
          subtotalName="Taxes"
          subtotalAmount={taxCents}
          isLoading={isGettingFees}
        />
        <SubtotalLine
          isLast
          subtotalName="Total"
          subtotalAmount={totalCents}
          isLoading={isGettingFees}
        />
      </View>

      <Pressable
        style={Checkout}
        onPress={goToCheckout}
        disabled={!cart || !cart?.items?.length || isGettingFees}
      >
        <Text style={CheckoutText}>Checkout</Text>
      </Pressable>
    </View>
  );
}

export default CartCol;
