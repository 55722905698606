import { Pressable, View, Animated } from 'react-native';
import { Icon, Text } from 'native-base';
import { FormattedCurrency, FormattedMessage } from 'react-native-globalize';
import React, { useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { AntDesign } from '@expo/vector-icons';
import { Orientation } from '../../api';

/**
 * A <Card> is the type of object for each of the subsections on the <TipScreen>. The only two properties are the title
 * to be displayed at the top of the Card, and the children to be rendered just underneath the title.
 *
 * @param props
 * @param {string} props.title The title displayed at the top of the Card
 * @param {React.component[]} props.children The children of this Card
 * @param {Orientation} [props.orientation=Orientation.PORTRAIT] The orientation of the screen, landscape or vertical
 * @returns {JSX.Element}
 * @constructor
 */
function Card({
  title,
  titleRight,
  children,
  orientation = Orientation.PORTRAIT,
  collapsible,
  startCollapsed,
  style = {},
  titlePath,
  ...rest
}) {
  const [collapsed, setCollapsed] = useState(collapsible ? startCollapsed : true);
  const landscape = orientation === Orientation.LANDSCAPE;
  // const [value, setValue] = useState(new Animated.Value(0))
  const ContainerCmp = collapsible ? Pressable : View;

  // useEffect(() => {})

  /**
   * TODO: Animate the expand/collapse
   */
  return (
    <View
      style={[styles.card, landscape && styles.landscapeCard, style]}
      {...rest}
    >
      {(!!title || !!titleRight) && (
        <ContainerCmp
          style={styles.cardTitle}
          onPress={() => setCollapsed(!collapsed)}
        >
          <FormattedMessage
            id={titlePath}
            defaultMessage={title}
            style={styles.cardTitleText}
          />
          {titleRight}
          {collapsible && (
            <Icon
              size={5}
              as={AntDesign}
              name={collapsed ? 'caretright' : 'caretdown'}
            />
          )}
        </ContainerCmp>
      )}

      {collapsible ? !collapsed && children : children}
    </View>
  );
}

export default Card;

const styles = EStyleSheet.create({
  card: {
    alignSelf: 'stretch',
    padding: 20,
    paddingHorizontal: '5%',
    borderBottomColor: 'lightgray',
    borderBottomWidth: 8,
  },
  landscapeCard: {
    paddingHorizontal: '5%',
  },
  cardTitle: {
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 0.5,
    paddingBottom: 5,
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardTitleText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});
