import React, { useState, memo, useEffect, useReducer } from 'react';
import { ActivityIndicator, TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import { Badge, Button, Icon, Switch, Text } from 'native-base';
import { Fontisto, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { FormattedCurrency } from 'react-native-globalize';
import { Body, Left, ListItem, Right } from '../../bbot-component-library';
import Colors from '../../constants/Colors';
import API from '../../api';
import Alert from '../../components/Alert';

function ListMenuItem({ item, onPress }) {
  const [processing, setProcessing] = useState(false);
  const [inventory, setInventory] = useState(null);
  const forceUpdate = useReducer(x => x + 1, 0)[1];

  useEffect(() => {
    setInventory(API.inventory[item.inventory_item_id]);
    const inventoryListener = item.on('inventory', count => {
      setInventory(count);
    });
    const updateListener = item.on('update', () => {
      forceUpdate();
    });
    return () => {
      inventoryListener.remove();
      updateListener.remove();
    };
  }, []);

  const toggleStockStatus = async () => {
    setProcessing(true);
    const result = await API.stockUpdate(item, !item.in_stock);
    if (result.error) {
      Alert.alert('Error', result.error);
    }
    setProcessing(false);
  };

  const onRowPress = () => onPress(item);

  const getItemDescription = () => {
    if (['food', 'drink'].includes(item.menu_item_class)) {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.note}
          >
            {item.menu_names.join(', ')} -{' '}
          </Text>
          <FormattedCurrency
            style={styles.note}
            value={item.price_in_cents / 100}
          />
        </View>
      );
    }
    const amount = item.price_in_cents;

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          style={styles.note}
          numberOfLines={1}
        >
          {item.heading ? item.heading.heading_name : ''}
        </Text>
        {amount > 0 && (
          <Text style={styles.note}>
            {' '}
            (+
            <FormattedCurrency value={amount / 100} />)
          </Text>
        )}
      </View>
    );
  };

  return (
    <ListItem
      icon
      style={{ height: 50 }}
    >
      <Left style={styles.itemLeft}>{getIcon(item.menu_item_class)}</Left>
      <Body style={styles.itemBody}>
        <TouchableWithoutFeedback onPress={onRowPress}>
          <View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text
                numberOfLines={1}
                style={{ flex: 1 }}
              >
                {item.name_for_customer}
              </Text>
              {!!item.vendor_name && (
                <Badge
                  colorScheme="info"
                  variant="outline"
                  ml={1}
                  mr={3}
                  _text={{
                    numberOfLines: 1,
                    maxWidth: 85,
                  }}
                >
                  {item.vendor_name}
                </Badge>
              )}
            </View>
            {getItemDescription()}
          </View>
        </TouchableWithoutFeedback>
      </Body>
      <Right style={styles.itemRight}>
        <View style={{ paddingLeft: 10, paddingRight: 40 }}>
          {inventory ? (
            <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{inventory}</Text>
          ) : (
            <Text>-</Text>
          )}
        </View>
        <View style={{ marginRight: 5, width: 50, alignItems: 'center' }}>
          {processing ? (
            <ActivityIndicator
              size="small"
              color={Colors.primary}
            />
          ) : (
            <Switch
              onValueChange={toggleStockStatus}
              value={item.in_stock}
            />
          )}
        </View>
      </Right>
    </ListItem>
  );
}

export default memo(ListMenuItem);

const getIcon = item_class => {
  switch (item_class) {
    case 'drink':
      return (
        <Button style={{ backgroundColor: '#00bfff' }}>
          <Icon
            color="#FFF"
            as={MaterialCommunityIcons}
            name="liquor"
          />
        </Button>
      );
    case 'food':
      return (
        <Button style={{ backgroundColor: '#24cc00' }}>
          <Icon
            color="#FFF"
            as={Ionicons}
            name="ios-pizza"
          />
        </Button>
      );
    case 'mixer':
      return (
        <Button style={{ backgroundColor: '#000285' }}>
          <Icon
            color="white"
            as={Fontisto}
            name="cocktail"
          />
        </Button>
      );
    case 'addon':
      return (
        <Button style={{ backgroundColor: '#076700' }}>
          <Icon
            color="#FFF"
            as={MaterialCommunityIcons}
            name="french-fries"
          />
        </Button>
      );
  }
};

const styles = StyleSheet.create({
  itemLeft: {
    paddingRight: 20,
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },

  itemBody: {
    flex: 1,
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#C9C9C9',
  },

  itemRight: {
    justifyContent: 'space-between',
    maxWidth: 130,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#C9C9C9',
  },
  note: {
    fontSize: 13,
    color: Colors.darkGray,
  },
});
