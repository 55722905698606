import React from 'react';
import { View } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';

function Header({ children, style, ...props }) {
  return (
    <View
      style={[styles.header, style]}
      {...props}
    >
      {children}
    </View>
  );
}

const styles = EStyleSheet.create({
  header: {
    flexDirection: 'row',
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});

export default Header;
