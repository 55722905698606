import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  View,
  findNodeHandle,
  TouchableWithoutFeedback,
  Dimensions,
} from 'react-native';
import { Button, Text, Spinner, Row, Box, Icon } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { AntDesign, Entypo, MaterialIcons, SimpleLineIcons } from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import Layout from '../constants/Layout';
import API from '../api';

// Models
import CartItem from '../models/CartItem';

// Components
import Breadcrumb from '../components/Breadcrumb';
import IndicatorScrollView from '../components/IndicatorScrollView';
import ItemButton from '../components/ItemButton';
import Alert from '../components/Alert';
import { prompt } from '../components/Prompt/GlobalPrompt';

// Styles
import { Typography } from '../styles';
import Colors from '../constants/Colors';
import IconButton from '../components/IconButton';
import KeypadModal from '../components/KeypadModal';
import { SearchBar } from '../bbot-component-library';

export default class ItemBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      item: null,
      menuData: API.getMenu(), // Todo: Don't get this from State! (gilles bad)
      breadcrumbs: [],
      search: '',
      path: [],
      errors: [],
      current: null,
      parent: null,
      modifierLevel: 0,
      numColumns: 4,
      showQuantityEdit: false,
      keypadVal: 1,
      closeQtyPicker: () => {},
    };

    this.breadcrumbContainer = null;
  }

  componentDidMount() {
    this._mounted = true;
    API.on('menu', this._updateMenu);

    this.setState({
      breadcrumbs: this._getDefaultBreadCrumbs(),
    });

    this._handleLayoutChange({
      nativeEvent: {
        layout: {
          width: Dimensions.get('window').width,
        },
      },
    });
  }

  componentWillUnmount() {
    this._mounted = false;
    this.breadcrumbContainer = null;
    API.off('menu', this._updateMenu);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onModifiersChange } = this.props;
    const { current } = this.state;

    if (prevState.current !== current) {
      onModifiersChange(current);
    }
  }

  _updateMenu = () => {
    if (this._mounted) {
      this.setState({
        menuData: API.getMenu(),
      });
    }
  };

  render() {
    this._focusedModifier = null;
    const depth = this.state.breadcrumbs.length;
    // If there's only one menu, pre-select it
    const breadcrumbFlex = depth / 4;
    const searchFlex = (4 - depth) / 4;
    const searchString =
      depth === 0
        ? 'all menus'
        : depth === 3
        ? 'modifiers'
        : this.state.breadcrumbs[depth - 1].label;

    return (
      <View
        testID="itemBuilder"
        style={this.props.style}
      >
        {/* ====== BreadCrumbs Section ========== */}
        <View
          style={{
            flexDirection: 'row',
            height: 50,
            width: '100%',
            borderBottomColor: 'black',
            borderBottomWidth: 1,
          }}
        >
          <MenuHome
            onPress={() => {
              this._breadcrumbClicked(-1);
              this.setState({ search: '' });
            }}
          />
          <View style={{ flexDirection: 'row', flex: breadcrumbFlex, marginTop: 5 }}>
            {this.state.breadcrumbs.map((crumb, i) => {
              const homeBtnWidth = 36;
              const margin = 5;
              return (
                <Breadcrumb
                  testID={`itemBuilderBreadcrumb-${crumb.label}`}
                  key={`bc_${i}`}
                  position={i}
                  text={crumb.label}
                  onPress={this._breadcrumbClicked}
                />
              );
            })}
          </View>
          <MenuSearch
            value={this.state.search}
            onKeyPress={val => this.setState({ search: val })}
            style={{ flex: searchFlex }}
            searchString={searchString}
          />
        </View>

        {/* ====== Display Items ========== */}
        <View
          style={{ flex: 1 }}
          onLayout={this._onLayout}
        >
          <ScrollView
            ref={sv => (this._itemBuilderSV = sv)}
            contentContainerStyle={{ justifyContent: 'center' }}
          >
            {this._getDisplayItems()}
          </ScrollView>
        </View>

        {/*= ========== Quantity / Item Commands */}
        {/* <View */}
        <Box
          safeAreaBottom
          style={styles.footer}
        >
          <Row style={{ marginLeft: 25, alignItems: 'center' }}>
            <IconButton
              testID="itemBuilderDecrementButton"
              icon="minus"
              iconType={Entypo}
              onPress={() => this._changeQty(-1)}
              disabled={!this.state.item}
            />
            <TouchableWithoutFeedback
              disabled={!this.state.item}
              onPress={() => {
                this.setState({
                  keypadVal: this.state.item.qty,
                  showQuantityEdit: true,
                  closeQtyPicker: val => {
                    this.state.item.setQuantity(val);
                    this.setState({
                      item: this.state.item,
                      showQuantityEdit: false,
                    });
                  },
                });
              }}
            >
              <View style={{ marginLeft: 10, marginRight: 10, alignItems: 'center' }}>
                <Text
                  testID="itemBuilderItemQuantity"
                  style={{
                    color: this.state.item ? 'white' : 'grey',
                    fontSize: 22,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  {(this.state.item && this.state.item.qty) || 0}
                </Text>
                <FormattedMessage
                  id="common__uppercase__short__quantity"
                  defautlMessage="QTY"
                  style={{ color: this.state.item ? 'white' : 'grey', fontSize: 12 }}
                />
              </View>
            </TouchableWithoutFeedback>
            <IconButton
              icon="plus"
              testID="itemBuilderIncrementButton"
              iconType={Entypo}
              onPress={() => this._changeQty(1)}
              disabled={!this.state.item}
            />
          </Row>
          <IconButton
            label="button__requests"
            defaultMessage="REQUESTS"
            testID="itemBuilderSpecialRequestButton"
            icon="bubble"
            iconType={SimpleLineIcons}
            onPress={this._specialRequest}
            disabled={!this.state.item}
          />
          <IconButton
            label="button__done"
            defaultMessage="DONE"
            testID="itemBuilderDoneButton"
            icon="check"
            iconType={MaterialIcons}
            disabled={!this.state.item}
            highlight={!this.state.errors.length}
            onPress={() => this._doneEditing()}
          />
        </Box>

        {/*= ======== Item Quantity Chooser Modal =========== */}
        {this.state.showQuantityEdit && (
          <KeypadModal
            visible
            testID="quantityDialog"
            title="Edit Quantity"
            minValue={0}
            maxValue={this.state.maxAllowed}
            value={this.state.keypadVal}
            onClose={val => this.state.closeQtyPicker(val)}
            onCancel={() => {
              this.setState({ showQuantityEdit: false });
            }}
            showIncrement
          />
        )}
      </View>
    );
  }

  _onLayout = ({ nativeEvent }) => {
    this._displaySectionHeight = nativeEvent.layout.height;
    this.forceUpdate();
  };

  _cartItemFromMenuItem = item => {
    const { cart, onItemChange } = this.props;
    const { menuId } = item.menus[0];
    const menu = this.state.menuData.menus.find(m => m.menuId === menuId);
    const heading = item.menu_heading;

    const cartItem = new CartItem(cart, {
      menuId: menu.menuId,
      menu_heading_id: heading.id,
      customer_id: menu.customer.customer_id,
      menuItemId: item.menuItemId,
      name: item.name,
      pretax_cents: item.pretax_cents,
      tax_cents: item.tax_cents,
      tax_fraction: item.tax_fraction,
    });

    this.selectItem(cartItem);
  };

  selectItem = item => {
    if (!this.state.menuData) return;
    const { onItemChange } = this.props;
    const menuId = item.menuId || item.menus[0].menuId;
    const menu = this.state.menuData.menus.find(m => m.menuId === menuId);
    const heading = menu.headings.find(h => h.id === item.menu_heading_id);
    const menuItem = this.state.menuData.menuItemsById[item.menuItemId];

    this.state.breadcrumbs = [
      { obj: menu, label: menu.menuName },
      { obj: heading, label: heading.heading_name },
      { obj: menuItem, label: menuItem.name },
    ];

    this.setState(
      {
        search: '',
        item,
        current: item,
        breadcrumbs: this.state.breadcrumbs,
        errors: item.hasModErrors([]),
      },
      () => {
        // this._applyDefaultModifiers(item.menuItem);
        onItemChange(item);
      },
    );
  };

  _showSearchResults = () => {
    const { breadcrumbs, search } = this.state;
    const { location } = this.props;
    const depth = breadcrumbs.length;
    let items = depth
      ? breadcrumbs[depth - 1].obj.items
      : location.menus.reduce((acc, menu) => acc.concat(menu.items), []);

    const query = new RegExp(search.trim(), 'i');

    items = _.uniqBy(
      _.filter(items, item => item.name_for_bartender.search(query) >= 0),
      'menuItemId',
    );

    if (!items.length) {
      return (
        <View style={styles.noModifiers}>
          <FormattedMessage
            id="search__notFound"
            values={{ search }}
            defautlMessage={`No matches for "${search}`}
            style={styles.noModifiersText}
          />
          <TouchableWithoutFeedback onPress={() => this.setState({ search: '' })}>
            <FormattedMessage
              id="search__clear"
              defaultMessage="Clear Search"
              style={{
                color: Colors.primary,
                fontSize: 16,
                fontWeight: 'bold',
                marginTop: 10,
              }}
            />
          </TouchableWithoutFeedback>
        </View>
      );
    }

    return (
      <View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.primaryLight,
            padding: 5,
          }}
        >
          <FormattedMessage
            id="search__results"
            values={{ search }}
            defaultMessage={`Showing search results for: ${search}`}
          />
        </View>
        <View style={styles.buttonGroup}>
          {items.map(item => (
            <ItemButton
              testID={`itemBuilderItemButton-${item.name_for_bartender}`}
              disabled={!location.fulfillable_items.includes(item.menuItemId)}
              disabledMsg="Item currently unfulfillable"
              key={item.menuItemId}
              text={item.name_for_bartender}
              item={item}
              onPress={this._cartItemFromMenuItem}
            />
          ))}
        </View>
      </View>
    );
  };

  _getDisplayItems() {
    const { location } = this.props;
    const { menuData, refreshingMenus, breadcrumbs, search } = this.state;
    // todo: use _displaySectionHeight to figure out ItemButton height (_displaySectionHeight/7)?
    if (!menuData || !menuData.menus) {
      return (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <FormattedMessage
            id="search__error"
            defalutMessage="There was an error loading the Menu"
            style={{ margin: 15 }}
          />
          <Button
            variant="ghost"
            onPress={this._refreshMenus}
            disabled={refreshingMenus}
            style={{ alignSelf: 'center' }}
            endIcon={refreshingMenus ? <Spinner size="sm" /> : null}
          >
            <FormattedMessage
              id="common__refresh"
              defautlMessage="Refresh"
            />
          </Button>
        </View>
      );
    }
    const level = breadcrumbs.length;
    if (search && level < 3) return this._showSearchResults();
    switch (level) {
      // Menus
      case 0:
        const available_menus = location.menus;

        return (
          <View style={styles.buttonGroup}>
            {available_menus.map((menu, index) => (
              <ItemButton
                testID={`itemBuilderMenuButton-${menu.name}`}
                key={`menubtn${index}`}
                text={menu.name}
                disabled={!menu.is_available}
                onPress={() => {
                  this._addBreadcrumb(menu, menu.name);
                }}
              />
            ))}
            {!available_menus.length && (
              <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                <FormattedMessage
                  id="menu__noneAvailable"
                  defaultMessage="No menus available"
                  style={{ margin: 15 }}
                />
                <Button
                  variant="ghost"
                  onPress={this._refreshMenus}
                  disabled={refreshingMenus}
                  style={{ alignSelf: 'center' }}
                  endIcon={refreshingMenus ? <Spinner size="sm" /> : null}
                >
                  <FormattedMessage
                    id="common__refresh"
                    defautlMessage="Refresh"
                  />
                </Button>
              </View>
            )}
          </View>
        );
      // Primary Types
      case 1:
        const menu = this.state.breadcrumbs[0].obj;
        return (
          <View style={styles.buttonGroup}>
            {menu.headings.map(heading => {
              const f_items = _.intersection(
                location.fulfillable_items,
                heading.items.map(i => i.menuItemId),
              );

              return (
                <ItemButton
                  testID={`itemBuilderHeadingButton-${heading.heading_name}`}
                  key={heading.id}
                  text={heading.heading_name}
                  disabled={!f_items.length}
                  disabledMsg="Heading contains no fulfillable items"
                  onPress={() => {
                    this._addBreadcrumb(heading, heading.heading_name);
                  }}
                />
              );
            })}
          </View>
        );
      // Items
      case 2:
        const heading = this.state.breadcrumbs[1].obj;
        return (
          <View style={styles.buttonGroup}>
            {heading.items.map((item, index) => {
              const fulfillable = location.fulfillable_items.includes(item.menuItemId);
              return (
                <ItemButton
                  testID={`itemBuilderItemButton-${item.name_for_bartender}`}
                  key={`itemBtn_${index}`}
                  item={item}
                  disabled={!fulfillable}
                  disabledMsg="Item currently unfulfillable"
                  text={item.name}
                  onPress={this._itemButtonPressed}
                />
              );
            })}
          </View>
        );
      // CartItemModifiers
      case 3:
        const item = this.state.breadcrumbs[2].obj;
        const { current, search } = this.state;
        const modifierGroups = current?.menuItem.modifier_groups;

        let allModifiersFiltered = modifierGroups
          ? modifierGroups.map(group => Object.values(group.modifiers)).flat()
          : [];

        if (search && allModifiersFiltered.length > 0) {
          const query = new RegExp(search.trim(), 'i');
          allModifiersFiltered = _.filter(
            allModifiersFiltered,
            modifier => modifier.name_for_bartender.search(query) >= 0,
          );
        }

        const noMatchText = search
          ? { path: 'search__notFound', default: `No matches for "${search}"` }
          : { path: 'search__notAvailable', default: 'No options available' };

        return (
          <View>
            <View style={{ flex: 1 }}>
              {this.renderPath()}
              {modifierGroups.map(group => this.renderModifierGroup(group))}
              {allModifiersFiltered.length === 0 && search.length > 0 && (
                <View
                  key="no_options"
                  style={styles.search__noResults}
                >
                  <FormattedMessage
                    id={noMatchText.path}
                    values={{ search }}
                    defaultMessage={noMatchText.default}
                    style={[Typography.header3]}
                  />
                  {search !== '' && (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        this.setState(
                          {
                            search: '',
                          },
                          () => {
                            this.props.onModifiersChange(current);
                          },
                        );
                      }}
                    >
                      <View>
                        <FormattedMessage
                          id="search__clear"
                          defaultMessage="Clear Search"
                          style={{
                            color: Colors.primary,
                            fontSize: 16,
                            fontWeight: 'bold',
                            marginTop: 10,
                          }}
                        />
                      </View>
                    </TouchableWithoutFeedback>
                  )}
                </View>
              )}
            </View>
            {current.menuItemId === item.menuItemId && this._guestChooser()}
          </View>
        );
    }
  }

  _guestChooser() {
    if (this.props.numGuests <= 1) return null;

    const buttons = _.times(this.props.numGuests, n => (
      <ItemButton
        testID={`itemBuilderGuestButton-${n + 1}`}
        key={`guestBtn_${n}`}
        columns={4}
        selected={this.state.item && this.state.item.seat_numbers.indexOf(n + 1) >= 0}
        onPress={() => {
          this._onGuestBtnPress(n + 1);
        }}
      >
        <Text
          ellipsizeMode="tail"
          style={{ textAlign: 'center' }}
        >
          {n + 1}
        </Text>
      </ItemButton>
    ));
    buttons.push(
      <ItemButton
        testID="itemBuilderAllGuestsButton"
        key="allGuestsBtn"
        columns={4}
        selected={this.state.item && this.state.item.seat_numbers.length === this.props.numGuests}
        onPress={this._onAllGuestsBtnPress}
      >
        <FormattedMessage
          id="common__all"
          defaultMessage="All"
        />
      </ItemButton>,
    );

    return (
      <View testID="itemBuilderGuestSelectionView">
        <View
          key="guestSelection"
          style={styles.modifierGroupHeader}
        >
          <View style={styles.modifierGroupHeader}>
            <FormattedMessage
              id="order__seatNumber"
              defaultMessage="Seat Number: "
              style={{ fontWeight: 'bold', color: '#000' }}
            />
            {this.state.item?.errors.includes('seats') ? (
              <FormattedMessage
                id="common__required"
                defaultMessage="Required"
                style={[styles.requiredBadge, { color: Colors.error, borderColor: Colors.error }]}
              />
            ) : (
              <Icon
                as={AntDesign}
                name="checkcircle"
                style={{ color: Colors.success, fontSize: 16, marginLeft: 15 }}
              />
            )}
          </View>
        </View>
        <IndicatorScrollView columns={4}>{buttons}</IndicatorScrollView>
      </View>
    );
  }

  _onGuestBtnPress = number => {
    const { item } = this.state;

    if (item.seat_numbers.indexOf(number) >= 0) {
      item.seat_numbers = _.without(item.seat_numbers, number);
    } else {
      item.seat_numbers.push(number);
      item.seat_numbers.sort();
    }
    const errors = this._validateItem(item);
    this.setState({
      item,
      errors,
    });
    this.props.onItemChange(item);
  };

  _onAllGuestsBtnPress = () => {
    const { item } = this.state;
    const { numGuests } = this.props;

    if (item.seat_numbers.length === numGuests) {
      item.seat_numbers = [];
    } else {
      item.seat_numbers = _.times(numGuests, i => i + 1);
    }
    const errors = this._validateItem(item);
    this.setState({
      item,
      errors,
    });
    this.props.onItemChange(item);
  };

  _itemButtonPressed = item => {
    const { cart, numGuests } = this.props;
    const menu = this.state.breadcrumbs[0].obj;
    const menuHeading = this.state.breadcrumbs[1].obj;

    if (!item.modifierGroups.length && numGuests === 1) {
      const existingItem = cart.itemExists(item.menuItemId);
      if (existingItem) {
        existingItem.setQuantity(++existingItem.qty);
        this._doneEditing(true);
        return;
      }
    }

    const cartItem = new CartItem(cart, {
      menuId: menu.menuId,
      menu_heading_id: menuHeading.id,
      customer_id: menu.customer.customer_id,
      menuItemId: item.menuItemId,
      name: item.name,
      pretax_cents: item.pretax_cents,
      tax_cents: item.tax_cents,
      tax_fraction: item.tax_fraction,
    });

    if (item.modifierGroups.length || numGuests > 1) {
      this.setState(
        {
          item: cartItem,
          current: cartItem,
        },
        () => {
          this.setState({
            errors: cartItem.hasModErrors([]),
          });
          this._applyDefaultModifiers(item);
        },
      );
      this._addBreadcrumb(item, item.name);
    } else {
      // this is called to auto-add an item to the cart
      setTimeout(() => this._doneEditing(true));
    }
    this.props.onItemChange(cartItem, !item.modifierGroups.length && numGuests === 1);
  };

  _applyDefaultModifiers = item => {
    item.modifierGroups.forEach(group => {
      const modifierGroup = this.state.menuData.modifierGroupsById[group.modifierGroupId];

      group.modifierIds.forEach(modifierId => {
        const modifier = modifierGroup.modifiers[modifierId];
        if (modifier.pre_selected && modifier.is_fulfillable) this._toggleModifier(modifier, false);
      });

      // TODO: apply recursively for all modifiers of the modifier
    });
  };

  _changeQty = by => {
    if (!this.state.item) return;
    if (this.state.item.qty === 1 && by < 1) return;

    this.state.item.setQuantity(this.state.item.qty + by);

    this.setState({
      item: this.state.item,
    });

    this.props.onItemChange(this.state.item);
  };

  _addBreadcrumb = (obj, label, props) => {
    this.setState({
      breadcrumbs: [...this.state.breadcrumbs, { obj, label, props }],
    });
  };

  _resetBreadcrumbs = () => {
    this.setState({ breadcrumbs: [] });
  };

  _breadcrumbClicked = i => {
    this.state.breadcrumbs.length = i + 1;
    this.setState({
      breadcrumbs: this.state.breadcrumbs,
      item: i < 2 ? null : this.state.item,
    });
  };

  _getDefaultBreadCrumbs(menuData) {
    menuData = menuData || this.state.menuData;
    if (!menuData || !menuData.menus) return [];
    if (menuData && menuData.menus.length === 1)
      return [{ obj: menuData.menus[0], label: menuData.menus[0].menuName }];
    return [];
  }

  // Dont use setState inside _validateItem because validateItem is used on every render
  // to highlight the Done button if the item is valid
  _validateItem(itemOrMod) {
    const errors = itemOrMod?.hasModErrors([]) || [];

    return errors;
  }

  /**
   * Called when we are Done editing an item
   * @private
   */
  _doneEditing = stayOnLevel => {
    const { item, current, breadcrumbs } = this.state;
    const errors = this._validateItem(item);

    if (!errors.length) {
      item?.cleanup();

      this.setState(
        {
          item: null,
          current: null,
          errors: [],
          breadcrumbs: stayOnLevel ? breadcrumbs : breadcrumbs.slice(0, -1),
        },
        () => {
          this.props.onModifiersChange(null);
        },
      );

      this.props.onDone();
    } else {
      const message = errors
        .map((cartMod, index) => {
          const text =
            cartMod === item && cartMod.errors?.includes('seats')
              ? `${cartMod.getName()}∙ Seat #`
              : cartMod.getName();
          return (index !== 0 ? '∙ ' : '') + text;
        })
        .join('\r\n');

      Alert.alert('The following selections require customization:', message);

      if (errors[0] === item) {
        this.setState(
          {
            errors,
            current: item,
          },
          () => {
            this.props.onModifiersChange(current);
          },
        );
      } else {
        this.setState(
          {
            errors,
            current: errors[0],
          },
          () => {
            // Callback after state has been set and view has re-rendered:
            // This function will scroll the first modifier that has an error into view
            if (this._focusedModifier) {
              // In this case, we have a modifier with an error, so we want to scroll it into view
              // MeasureLayout finds the offset from the modifier group with the error to the parent ScrollView
              this._focusedModifier.measureLayout(
                findNodeHandle(this._itemBuilderSV),
                (x, y, w, h) => {
                  this._itemBuilderSV?.scrollTo({ y });
                },
              );
            } else {
              this._itemBuilderSV.scrollToEnd();
            }
          },
        );
      }
    }
  };

  itemDeleted = () => {
    const { breadcrumbs } = this.state;
    this.setState({
      item: null,
      current: null,
      errors: [],
      breadcrumbs: breadcrumbs.slice(0, -1),
    });
  };

  reset = () => {
    this.setState({
      item: null,
      current: null,
      errors: [],
      breadcrumbs: this._getDefaultBreadCrumbs(),
    });
  };

  _specialRequest = () => {
    prompt({
      title: 'Special Requests',
      onSubmit: value => {
        this.state.item.special_instructions = value;
        this.props.onItemChange(this.state.item);
      },
      defaultValue: this.state.item.special_instructions,
      placeholder: 'eg. Dressing on the side',
    });
  };

  _refreshMenus = async () => {
    const availableMenus = this.props.location.menus;
    this.setState({ refreshingMenus: true });
    if (!availableMenus.length) {
      await API.doMenuUpdate();
    }
    this.setState({ refreshingMenus: false });

    this.forceUpdate();
  };

  _handleLayoutChange = ({ nativeEvent }) => {
    const { layout } = nativeEvent;
    const { width } = layout;

    const minColWidth = 240; // Allows for 2 decent sized columns on a Moto G4

    let numColumns = 0;
    if (width < 500) {
      numColumns = 2;
    } else {
      numColumns = Math.floor(width / minColWidth);
    }

    this.setState({
      numColumns,
    });
  };

  renderModifierGroup = modifierGroup => {
    const { current, numColumns, search } = this.state;

    const modifiers = Object.values(modifierGroup.modifiers);
    const cartModifiers = current.mods[modifierGroup.id];

    // let optionToCustomize = modifiers.some( (mod) => mod.modifier_groups?.length > 0 )
    const required = modifierGroup.min_selected > 0;
    const requirementsMet =
      current.mods[modifierGroup.id]
        ?.filter(mod => mod.menuModifier.is_fulfillable && mod.selected)
        .reduce((total, m) => (total += m.qty), 0) >= modifierGroup.min_selected;
    const hasError = current.errors.indexOf(modifierGroup.id) !== -1 || !requirementsMet;

    let filteredModifiers = modifiers;

    if (search) {
      const query = new RegExp(search.trim(), 'i');
      filteredModifiers = _.filter(
        modifiers,
        modifier => modifier.name_for_bartender.search(query) >= 0,
      );
    }

    if (filteredModifiers?.length === 0) {
      return null;
    }

    return (
      <View
        testID={`itemBuilderModifierGroup-${modifierGroup.heading_name}`}
        key={`${modifierGroup.id}-modifier-group`}
        style={styles.modifierGroup}
        onLayout={this._handleLayoutChange}
      >
        <View
          testID="itemBuilderModifierGroupHeader"
          style={styles.modifierGroupHeader}
        >
          <Text
            testID={`itemBuilderModifierGroupHeader-${modifierGroup.heading_name}`}
            style={{
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {modifierGroup.heading_name}
          </Text>
          <Text style={{ color: '#878787', fontSize: 11, marginLeft: 15 }}>
            {modifierGroup.description}
          </Text>
          {required ? (
            requirementsMet ? (
              <Icon
                as={AntDesign}
                name="checkcircle"
                style={{ color: Colors.success, fontSize: 16, marginLeft: 15 }}
              />
            ) : (
              <FormattedMessage
                id="common__required"
                defaultMessage="Required"
                style={[
                  styles.requiredBadge,
                  hasError && { color: Colors.error, borderColor: Colors.error },
                ]}
              />
            )
          ) : (
            <FormattedMessage
              id="itemBuilder__optional"
              defaultMessage="(Optional)"
              style={{ marginLeft: 4, fontSize: 10, color: Colors.darkGray }}
            />
          )}
        </View>
        <IndicatorScrollView columns={numColumns}>
          {filteredModifiers.map((modifier, index) => {
            const cartModifier = cartModifiers?.find(mod => mod.menuItemId === modifier.menuItemId);
            return (
              <ItemButton
                testID={`itemBuilderModifier-${modifierGroup.heading_name}-${modifier.name}`}
                key={`itemBtn_${index}-${modifier.id}`}
                text={modifier.name}
                selected={this._isModifierSelected(modifier)}
                columns={numColumns}
                onPress={() => {
                  this._toggleModifier(modifier);
                }}
                modifier={modifier} // pass the cart modifier if available so that you can check if it is valid and show "Customize"
                cartModifier={cartModifier}
                customizeModifier={() => this._handleCustomizeModifier(modifier)}
                changeQuantity={() => this._handleEditModifierQuantity(modifier)}
                disabled={
                  !cartModifier?.selected && !API.menuData.modifiersById[modifier.id].is_fulfillable
                }
              />
            );
          })}
        </IndicatorScrollView>
      </View>
    );
  };

  _selectCurrent = mod => {
    this.props.onModifiersChange(mod);
    this.setState({
      current: mod,
      parent: mod.parent,
    });
  };

  calculatePath = (current, node, path) => {
    const breadCrumbArrowHeight = 40;

    if (!node.parent) {
      return (
        <>
          <TouchableWithoutFeedback
            testID={`itemBuilderPathNode-${node.getName()}`}
            onPress={() => this._selectCurrent(node)}
          >
            <View
              style={[
                styles.breadcrumb,
                styles.breadcrumb__cartItem,
                { height: breadCrumbArrowHeight },
              ]}
            >
              <Text style={{ fontWeight: 'bold' }}>{node.getName()}</Text>
            </View>
          </TouchableWithoutFeedback>
          <View style={{ height: '100%', justifyContent: 'center' }}>
            <Icon
              as={AntDesign}
              name="caretright"
              style={{ fontSize: 12, color: Colors.darkGray }}
            />
          </View>
        </>
      );
    }

    return (
      <>
        {this.calculatePath(current, node.parent, path)}
        <TouchableWithoutFeedback
          testID={`itemBuilderPathNode-${node.getName()}`}
          onPress={() => this._selectCurrent(node)}
        >
          <View style={[styles.breadcrumb, { height: breadCrumbArrowHeight }]}>
            <Text
              style={[
                node === current
                  ? {
                      textDecorationLine: 'underline',
                      color: Colors.dark,
                    }
                  : { color: Colors.darkGray },
              ]}
            >
              {node.getName()}
            </Text>
          </View>
        </TouchableWithoutFeedback>
        {node !== current && (
          <View style={{ height: '100%', justifyContent: 'center' }}>
            <Icon
              as={AntDesign}
              name="caretright"
              style={{ fontSize: 12, color: Colors.darkGray }}
            />
          </View>
        )}
      </>
    );
  };

  renderPath = () => {
    const { current } = this.state;
    if (!current.parent) return null; // Only show breadcrumbs after the first nested modifier is selected
    return (
      <ScrollView
        horizontal
        ref={ref => {
          this.breadcrumbContainer = ref;
        }}
        contentContainerStyle={{
          padding: 5,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {this.calculatePath(current, current, '')}
      </ScrollView>
    );
  };

  _isModifierSelected(modifier) {
    const { current } = this.state;
    if (!current.mods) return false;
    if (current.mods[modifier.menu_heading_id]) {
      const cartModifier = current.mods[modifier.menu_heading_id].find(
        m => m.menuItemId === modifier.menuItemId,
      );
      return cartModifier && cartModifier.selected;
    }
  }

  _toggleModifier = async (modifier, doUpdate = true, isModifyingQty = false) => {
    const { item, current } = this.state;
    const response = await current.toggleModifier(modifier, isModifyingQty);

    if (!response.success) {
      Alert.alert(response.errorTitle, response.errorMessage);
      return false;
    }

    if (doUpdate) {
      const errors = this._validateItem(item);
      this.setState(
        {
          errors,
          current,
          search: '',
        },
        () => {
          this.props.onModifiersChange(current);
        },
      );
    }
    return true;
  };

  _handleCustomizeModifier = async modifier => {
    const { current } = this.state;

    // Need to check if is already selected
    let cartMod =
      current.mods &&
      current.mods[modifier.menu_heading_id]?.find(m => m.menuItemId === modifier.menuItemId);

    let response = null;

    if (cartMod) {
      if (!cartMod.selected) {
        response = await current.toggleModifier(modifier);
      }
      // else do nothing because the cartModifier is already selected and you just want to enter the cartModifier level
    } else {
      response = await current.toggleModifier(modifier);
      cartMod = response.cartModifier;
    }

    if (response && !response.success) {
      Alert.alert(response.errorTitle, response.errorMessage);
      return;
    }

    try {
      await current.isCurrentLevelValid(); // Return true if valid or throws error if invalid
      this.setState(
        {
          errors: this._validateItem(cartMod),
          current: cartMod,
          parent: cartMod.parent,
        },
        () => {
          this.breadcrumbContainer?.scrollToEnd({ animated: true });
          this.props.onModifiersChange(cartMod);
        },
      );
    } catch (error) {
      // One of the modifier groups for the current level does not have its requirements met therefore alert and fail.
      Alert.alert('Invalid Modifier!', error.message);
      this.props.onModifiersChange(current);
    }
  };

  _handleEditModifierQuantity = async modifier => {
    const { current } = this.state;
    const { heading } = modifier;
    const { max_selected } = heading;

    await this._toggleModifier(modifier, true, true);

    const cartMod =
      current.mods &&
      current.mods[modifier.menu_heading_id]?.find(m => m.menuItemId === modifier.menuItemId);

    // Qty of all other mods that are selected; not counting the modifier currently being toggled.
    const numSelected = current.mods[heading.id]
      .filter(m => m.selected && m.menuItemId !== cartMod.menuItemId)
      .reduce((totalMods, mod) => (totalMods += mod.qty), 0);

    // If this is modifier selected and has a quantity of 1 (since qty defaults to 1) then keypad should be able to enter the max_selected value from the heading group.
    const remainingAllowedModifiers = max_selected - numSelected;

    let response;

    if (!cartMod?.selected) {
      response = await this._toggleModifier(modifier);
    }

    if ((response || cartMod.selected) && remainingAllowedModifiers > 1) {
      this.setState({
        maxAllowed: remainingAllowedModifiers,
        showQuantityEdit: true,
        keypadVal: cartMod.qty,
        closeQtyPicker: async val => {
          if (val === 0) {
            cartMod.selected = false;
            cartMod.setQuantity(1);
          } else {
            cartMod.setQuantity(val);
          }
          this.setState(
            {
              showQuantityEdit: false,
              errors: this._validateItem(current),
            },
            () => this.props.onModifiersChange(current),
          );
        },
      });
    }
  };
}

ItemBuilder.propTypes = {
  onItemChange: PropTypes.func,
  onDone: PropTypes.func,
  numGuests: PropTypes.number,
};
ItemBuilder.defaultProps = {
  onItemChange: () => {},
  onDone: () => {},
};

function MenuHome({ onPress }) {
  return (
    <TouchableWithoutFeedback
      testID="itemBuilderMenuHomeButton"
      onPress={onPress}
    >
      <View style={styles.homeBreadcrumb}>
        <Icon
          as={Entypo}
          name="dots-three-vertical"
        />
      </View>
    </TouchableWithoutFeedback>
  );
}

function MenuSearch({ value, onKeyPress, style, searchString }) {
  return (
    <View style={[{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 20 }, style]}>
      <SearchBar
        testID="itemBuilderMenuSearchInput"
        style={{ flex: 1 }}
        value={value}
        onChangeText={val => {
          onKeyPress(val);
        }}
        placeholderTextPath="form__placeholder__searchMenu"
        values={{ searchString }}
        defaultMessage={`Search ${searchString}`}
        underlineColorAndroid="transparent"
      />
      <TouchableWithoutFeedback
        testID="itemBuilderMenuClearSearchButton"
        onPress={() => {
          onKeyPress('');
        }}
      >
        <Icon
          as={MaterialIcons}
          name="clear"
          style={{ color: value ? 'black' : 'lightgray' }}
        />
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = EStyleSheet.create({
  content: {
    flex: 1,
  },
  itemButton: {
    width: Layout.window.width / 4 - 10,
    height: 40,
    margin: 5,
    backgroundColor: '#ccc',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    margin: 10,
  },
  homeBreadcrumb: {
    backgroundColor: '#cdcdcd',
    width: 36,
    height: 36,
    marginLeft: 5,
    marginTop: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollContent: {},
  buttonGroup: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '101%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  modifierGroupHeader: {
    padding: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  modifierGroup: {
    marginBottom: 20,
  },
  requiredBadge: {
    marginLeft: 10,
    borderRadius: 20,
    paddingVertical: 3,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGray,
    color: Colors.darkGray,
    fontWeight: 'bold',
    fontSize: 10,
  },
  highlightedText: {
    fontWeight: 'bold',
    color: Colors.primary,
  },
  breadcrumb: {
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  breadcrumb__cartItem: {},
  breadcrumb__cartModifier: {},
  breadcrumb__currentItem: {
    textDecorationLine: 'underline',
  },
  search__noResults: {
    textAlign: 'center',
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
    maxHeight: 60,
    flex: 1,
  },
});
