import { showMessage } from 'react-native-flash-message';
import API from '../../api';
import { prompt } from '../Prompt/GlobalPrompt';
import { showStripeModal } from './GlobalStripeCardModal';
import { showLoader, hideLoader } from '../Modals/LoadingOverlay';
import Alert from '../Alert';

/**
 * Initiates the capture of a payment method. If `forceTab` is true, will attempt to start a tab, otherwise will
 * fallback to the location configuration
 *
 * Scenarios:
 *  - Start Tab from Tabs screen:
 *    - forceTab = true
 *  - Add Card from Card Chooser:
 *    - reusable: true
 *
 * @param props
 * @param props.location
 * @param props.forceTab {boolean} We are starting a tab, so enforce Visa/Mastercard limitation
 * @param props.reusable {boolean}
 * @param props.check {CheckModel} Check, if applicable
 * @param props.note {string} Note to be displayed in the Stripe Modal
 * @param props.paymentIntentParams {object} PaymentIntentParameters to be passed along to Stripe
 * @param props.saveButtonText {string} Text to show on the "SAVE" button
 * @returns {Promise<Promise<unknown> | Promise.Promise>}
 */
export const capturePaymentMethod = async ({
  location,
  forceTab = false,
  reusable = true,
  tabName = '',
  defaultTip = 0,
  check = null,
  note = '',
  paymentIntentParams = {},
  saveButtonText,
}) =>
  new Promise((resolve, reject) => {
    const { seated_group } = location;
    const tabsAllowed = API.config.allow_pay_with_consumer_tab && location.allow_consumer_tabs;

    const onTabNameSuccess = async (tabName, card) => {
      // todo: pass seat # if only 1 uniqSeat, otherwise prompt for Seat# with Tab Name.
      // todo: Pass param letting us know this is a keep-open tab
      showLoader();
      const result = await API.openTab(seated_group?.id, tabName, location.id, card.id, defaultTip);
      hideLoader();

      const { tab, joinURL } = result;
      tab._joinURL = joinURL;

      if (tab) {
        resolve(tab);
      } else {
        reject();
        showMessage({
          type: 'warning',
          message: 'There was an error opening the Tab.',
        });
      }
    };

    const startTab = async card => {
      const defaultTabName = tabName || card.name_on_card;
      prompt({
        title: 'Tab Name',
        defaultValue: defaultTabName,
        validator: value => {
          if (!value) return 'Tab name required';
          const exists = Object.values(API._tabs).find(
            t => t.end_date.isAfter() && t.tab_name?.toLowerCase() === value.toLowerCase(),
          );
          if (exists) return 'Tab name is taken, please try again';
          return true;
        },
        onSubmit: tabName => onTabNameSuccess(tabName, card),
      });
    };

    /**
     * Here we use a temporary 'fake' tab, fund it with a one-time paymentIntent, and use it to pay for the order
     * @param paymentIntent
     * @returns {Promise<void>}
     */
    const processNonReusableCard = async paymentIntent => {
      showLoader();

      const data = await API.openTab(seated_group?.id, '', location.id, null);
      if (data.error) {
        showMessage({
          type: 'danger',
          message: data.error,
        });
        hideLoader();
        reject();
      } else {
        const { tab } = data;
        const amount = parseInt(paymentIntent.amount);
        const response = await API.fundTabFromPaymentIntent(tab, paymentIntent.id, amount, true);
        const { charge } = response;
        hideLoader();
        // charge.card_info.cardholder_name
        // charge.card_info.overcapture_supported

        tab.update(response.tab);

        resolve(tab);
      }
    };

    const processReusableCard = async paymentMethod => {
      const card = paymentMethod?.cardDetails || paymentMethod.card || paymentMethod.Card;
      const expandable = ['visa', 'mastercard'].includes(card.brand.toLowerCase());

      if (forceTab && !expandable) {
        showMessage({
          message: `Currently tabs can only be started with Visa or Mastercard. Got "${card.brand}"`,
          type: 'danger',
          position: 'top',
          floating: 'true',
          autoHide: false,
        });
        reject(`Tabs require visa or mastercard. Got ${card.brand}`);
      } else {
        showLoader();
        const result = await API.saveReusableCard(
          seated_group,
          paymentMethod.id,
          check?.getUniqSeats(),
        );
        hideLoader();
        if (result.error) {
          showMessage({
            type: 'danger',
            floating: true,
            position: 'top',
            message: 'Error saving card information',
          });
          return;
        }
        const { card } = result;

        if (!forceTab) {
          if (tabsAllowed && expandable) {
            Alert.alert('Start Tab?', '', [
              {
                text: 'No Thanks',
                onPress: () => {
                  resolve(card);
                },
              },
              {
                text: 'Start Tab',
                onPress: () => {
                  startTab(card);
                },
              },
            ]);
          } else {
            resolve(card);
          }
        } else {
          await startTab(card);
        }
      }
    };

    const onStripeSuccess = async paymentMethodOrPaymentIntent => {
      if (reusable || forceTab) {
        await processReusableCard(paymentMethodOrPaymentIntent);
      } else {
        await processNonReusableCard(paymentMethodOrPaymentIntent);
      }
    };

    const onStripeError = error => {
      hideLoader();
    };

    showStripeModal({
      reusable,
      saveButtonText,
      headerText: forceTab ? 'Only VISA/MC Supported' : '',
      footerText: reusable ? 'Tap not supported' : '',
      onSuccess: onStripeSuccess,
      onError: onStripeError,
      paymentIntentParams,
    });
  });
