import { View } from 'react-native';
import _ from 'lodash';
import { FormattedCurrency } from 'react-native-globalize';
import React, { useEffect, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Text } from 'native-base';
import { TextM } from '../../components';
import FuzzyField from '../../components/FuzzyField';
import Card from './Card';
import CheckoutCartItem from './CheckoutCartItem';
import PromoInput from './PromoInput';
import Colors from '../../constants/Colors';
import API from '../../api';

/**
 * The <ItemsCard> is always displayed as long as the cart is non-empty (which it should never be if when viewing the
 * Tip Screen)
 *
 * @param props
 * @param {CheckModel} props.check Information on the current order, including items and various prices
 * @param {boolean} props.checkingPrices If true,  price values of the items and totals are fuzzy to denote checking
 * @param {number} props.tipAmount (in dollars) The final amount of the tip, as given from the <TipsCard> element
 * @constructor
 */
function CartItemsCard({ check, checkingPrices, tipAmount, location, isTab, defaultTip, ...rest }) {
  const [discounts, setDiscounts] = useState([]);
  const [fees, setFees] = useState([]);

  useEffect(() => {
    setDiscounts(check.getDiscounts());
    setFees(check.getFees());
  }, [check.cart._last_modified]);

  return (
    <Card
      testID="itemsCard"
      title="Items"
      titlePath="common__items"
      {...rest}
    >
      <View testID="itemsCardReceiptItems">
        {check.items.map(item => (
          <CheckoutCartItem
            key={item.id}
            item={item}
            blurPrice={checkingPrices}
          />
        ))}
      </View>
      <PromoInput cart={check.cart} />
      {/* -------- TOTALS -------- */}
      <View style={{ width: '100%', marginVertical: 10, paddingLeft: '10%' }}>
        {/* Discount Lines */}
        {discounts.length !== 0 &&
          discounts.map(discount => (
            <DiscountLine
              key={discount.promotion_id}
              check={check}
              discount={discount}
              checkingPrices={checkingPrices}
            />
          ))}
        {/* Sub-Total Line */}
        {!API.main_customer.tax_inclusive_pricing && (
          <View
            testID="itemsCardSubtotalLine"
            style={styles.summaryRow}
          >
            <TextM
              id="common__subTotal"
              defaultMessage="Sub-Total"
              style={styles.summaryTitle}
            />
            <FuzzyField
              testID="itemsCardSubtotalCost"
              fuzzy={checkingPrices}
            >
              <FormattedCurrency
                style={styles.totals}
                value={check.pretax_total / 100}
              />
            </FuzzyField>
          </View>
        )}
        {/* Fees Lines */}
        {fees.length !== 0 &&
          fees.map(fee => (
            <View
              testID="itemsCardFeesLine"
              style={styles.summaryRow}
              key={fee.id}
            >
              <Text style={styles.summaryTitle}>{fee.name}</Text>
              <FuzzyField
                testID="itemsCardFeesCost"
                fuzzy={checkingPrices}
              >
                <FormattedCurrency
                  style={styles.totals}
                  value={fee.total_cents / 100}
                />
              </FuzzyField>
            </View>
          ))}
        {/* Tax Line */}
        {!API.main_customer.tax_inclusive_pricing && (
          <View
            testID="itemsCardTaxLine"
            style={styles.summaryRow}
          >
            <TextM
              id="common__tax"
              defaultMessage="Tax"
              style={styles.summaryTitle}
            />
            <FuzzyField
              testID="itemsCardTaxCost"
              fuzzy={checkingPrices}
            >
              <FormattedCurrency
                style={styles.totals}
                value={check.tax_total / 100}
              />
            </FuzzyField>
          </View>
        )}
        <View
          testID="itemsCardTaxLine"
          style={styles.summaryRow}
        >
          <TextM
            id="common__total"
            defaultMessage="Total"
            style={[styles.summaryTitle, styles.bold]}
          />
          <FuzzyField
            testID="itemsCardTotalCost"
            fuzzy={checkingPrices}
          >
            <FormattedCurrency
              style={[styles.totals, styles.bold]}
              value={check.total / 100}
            />
          </FuzzyField>
        </View>
        {/* Tip Line */}
        <View
          testID="itemsCardTipLine"
          style={styles.summaryRow}
        >
          <TextM
            id={location.forced_tip_name ? 'common__tipName' : 'common__tip'}
            defaultMessage={
              location.forced_tip_name || `Tip${isTab ? ` (${_.round(defaultTip * 100, 2)}%)` : ''}`
            }
            values={{ tipName: location.forced_tip_name }}
            style={styles.summaryTitle}
          />
          <FuzzyField
            testID="itemsCardTipCost"
            fuzzy={checkingPrices}
          >
            <FormattedCurrency
              style={styles.totals}
              value={tipAmount / 100}
            />
          </FuzzyField>
        </View>
        {isTab && (
          <View style={styles.summaryRow}>
            <Text style={{ fontSize: 12 }}>
              * Tip defaults to % set by the guest and can be adjusted when closing out tab
            </Text>
          </View>
        )}
        {/* Total w Tip Line */}
        <View
          testID="itemsCardTotalWTipLine"
          style={[
            styles.summaryRow,
            { paddingTop: 5, borderTopWidth: 1, borderTopColor: 'darkgray' },
          ]}
        >
          <TextM
            id="itemCart__totalWithTip"
            defaultMessage="Total with tip"
            style={[styles.summaryTitle, styles.bold]}
          />
          <FuzzyField
            testID="itemsCardFinalCost"
            fuzzy={checkingPrices}
          >
            <FormattedCurrency
              style={[styles.totals, styles.bold]}
              value={(check.total + (tipAmount || 0)) / 100}
            />
          </FuzzyField>
        </View>
        {/* Tax Included Line */}
        {API.main_customer.tax_inclusive_pricing && (
          <View
            testID="itemsCardTaxInclusiveLine"
            style={styles.summaryRow}
          >
            <TextM
              id="tax__inclusive"
              defaultMessage="Tax included in Total"
              style={styles.summaryTitle}
            />
            <FuzzyField
              testID="itemsCardTaxCost"
              fuzzy={checkingPrices}
            >
              <FormattedCurrency
                style={styles.totals}
                value={check.tax_total / 100}
              />
            </FuzzyField>
          </View>
        )}
      </View>
    </Card>
  );
}

function DiscountLine({ check, discount, checkingPrices }) {
  let discountAmount = 0;
  check.items.forEach(item => {
    const d = item.discounts.find(d => d.promotion_id === discount.promotion_id);
    if (d) discountAmount += d.cents_added;
  });
  return (
    <View
      testID="itemsCardDiscountLine"
      style={styles.summaryRow}
      key={discount.promotion_id}
    >
      <TextM
        id="itemCart__discountName"
        values={{ discountName: discount.name }}
        defaultMessage={`PROMO: ${discount.name}`}
        style={[styles.summaryTitle, { color: Colors.primary, fontWeight: 'bold' }]}
        numberOfLines={1}
        adjustsFontSizeToFit
      />
      <FuzzyField
        testID="itemsCardDiscountCost"
        fuzzy={checkingPrices}
      >
        <FormattedCurrency
          style={styles.totals}
          value={(discountAmount || 0) / 100}
        />
      </FuzzyField>
    </View>
  );
}

export default CartItemsCard;

const styles = EStyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  summaryRow: {
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },
  summaryTitle: {
    flex: 1,
    fontSize: 14,
  },
  totals: {
    fontSize: 14,
  },
});
