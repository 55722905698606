import { useGlobalize } from 'react-native-globalize';
import React from 'react';
import RNCurrencyInput from 'react-native-currency-input';
import EStyleSheet from 'react-native-extended-stylesheet';

const numberFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

// TODO: Refactor this component. Maybe use Symbol Input with an added 'formatter' prop.
// Can maybe use HelperFunctions.formatNumber
// Or even better, use screen/CheckoutScreen/Form/CurrencyField (need to create)?

function CurrencyInput({ style, ...props }) {
  const { formatNumber, getCurrencySymbol } = useGlobalize();

  const seperator = formatNumber(0, numberFormatOptions).substr(1, 1);
  const delimiter = seperator === '.' ? ',' : '.';
  const currencySymbol = getCurrencySymbol();

  return (
    <RNCurrencyInput
      prefix={currencySymbol}
      delimiter={delimiter}
      separator={seperator}
      style={[styles.container, style]}
      {...props}
    />
  );
}

export default CurrencyInput;

const styles = EStyleSheet.create({
  container: {
    width: 100,
    fontSize: 14,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#d4d4d4',
    borderRadius: 4,
  },
});
