import axios from 'axios';
import {
  FormControl,
  Row,
  Input,
  InputGroup,
  Button,
  Text,
  Icon,
  Checkbox,
  InputLeftAddon,
  InputRightAddon,
} from 'native-base';
import React from 'react';
import {
  Dimensions,
  View,
  TouchableWithoutFeedback,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import EStyleSheet from 'react-native-extended-stylesheet';
import * as IntentLauncher from 'expo-intent-launcher';
import { MaterialIcons } from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import API from '../api';
import Loader from '../components/Loader';
import { prompt } from '../components/Prompt/GlobalPrompt';
import Colors from '../constants/Colors';
import { IS_ELO, SHOW_SERVER_CHOOSER, SHOW_LOCALE_CHOOSER } from '../constants/Config';
import { validateEmail } from '../helpers/HelperFunctions';
import Fontello from '../assets/fonts/Fontello';
import IconButton from '../components/IconButton';
import LocalePicker from '../components/LocalePicker';

const { version } = API;

export default class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    const dim = Dimensions.get('window');
    this.state = {
      errorMsg: '',
      processing: false,
      emailValid: false,
      passValid: false,
      orientation: dim.width > dim.height ? 'LANDSCAPE' : 'PORTRAIT',
      serverURL: axios.defaults.baseURL,
      showPass: false,
      email: '',
      password: '',
      rememberEmail: false,
      emailFocused: true,
      showLanguagePicker: false,
      device_language: API.deviceLocale || 'en',
    };
    this._passwordInput = null;
  }

  get style() {
    return this.state.orientation === 'LANDSCAPE' ? landscapeStyles : portraitStyles;
  }

  componentDidMount() {
    if (Platform.OS !== 'web') this._interval = setInterval(this._checkConnection, 10000);

    API.multiGet(['rememberEmail', 'email']).then(values => {
      const isValid = validateEmail(values.email);
      this.setState({
        rememberEmail: values.rememberEmail === 'true',
        email: values.rememberEmail === 'true' ? values.email : '',
        emailValid: isValid,
        emailFocused: !isValid,
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  _checkConnection = () => {
    API.checkConnection().then(online => {
      API.trigger('online', online);
    });
  };

  render() {
    return (
      <View style={{ flex: 1, alignItems: 'center' }}>
        {SHOW_LOCALE_CHOOSER && (
          <>
            <IconButton
              icon="globe-outline"
              label="locale__name"
              defaultMessage="English"
              color="black"
              onPress={() => this.setState({ showLanguagePicker: true })}
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                elevation: 0,
              }}
            />
            <LocalePicker
              selectedValue={this.state.device_language}
              visible={this.state.showLanguagePicker}
              onHideCallback={this._closeLocalePicker}
              onItemPress={this._selectLocale}
            />
          </>
        )}
        {this.state.processing && <Loader />}
        <View
          onLayout={this.onLayout}
          style={[styles.contentContainer, this.style.contentContainer]}
        >
          <View style={[this.style.header]}>
            <Fontello
              name="bbot-logo"
              style={{ fontSize: 60, color: 'black' }}
            />
            <Text
              style={{
                fontSize: 30,
                fontWeight: 'bold',
                marginTop: 15,
                paddingTop: 10,
              }}
            >
              Bbot Terminal
            </Text>
          </View>
          <KeyboardAvoidingView style={this.style.body}>
            <InputGroup
              w={{ base: '100%' }}
              mb={2}
            >
              <InputLeftAddon
                w={{ base: '25%' }}
                key="l"
              >
                E-mail
              </InputLeftAddon>
              <Input
                key="input"
                name="email"
                testID="emailInput"
                w={{ base: '75%' }}
                accessibilityLabel="ccEmail"
                autoFocus={this.state.emailFocused}
                keyboardType="email-address"
                onChangeText={text => {
                  this.setState({
                    email: text,
                    emailValid: validateEmail(text),
                  });
                }}
                variant="underlined"
                value={this.state.email}
                onSubmitEditing={() => this._passwordInput?.focus()}
              />
            </InputGroup>
            <InputGroup w={{ base: '100%' }}>
              <InputLeftAddon w={{ base: '25%' }}>Password</InputLeftAddon>
              <Input
                name="Password"
                accessibilityLabel="ccPassword"
                ref={passwordInput => (this._passwordInput = passwordInput)}
                w={{ base: '70%' }}
                testID="passwordInput"
                secureTextEntry={!this.state.showPass}
                autoFocus={!this.state.emailFocused}
                returnKeyType="send"
                variant="underlined"
                onChangeText={pass => {
                  this.setState({
                    password: pass,
                    passValid: pass.length > 0,
                  });
                }}
                value={this.state.password}
                onSubmitEditing={({ nativeEvent }) => {
                  if (this._isValid()) this.doLogin();
                }}
              />
              <InputRightAddon w={{ base: '5%' }}>
                <TouchableWithoutFeedback
                  onPress={() => this.setState({ showPass: !this.state.showPass })}
                >
                  <Icon
                    name={this.state.showPass ? 'visibility-off' : 'visibility'}
                    as={MaterialIcons}
                  />
                </TouchableWithoutFeedback>
              </InputRightAddon>
            </InputGroup>
            <Row style={{ borderBottomWidth: 0, justifyContent: 'center', padding: 10 }}>
              <Checkbox.Group
                onChange={() => this.setState({ rememberEmail: !this.state.rememberEmail })}
                values={[this.state.rememberEmail ? 'remember' : null]}
                accessibilityLabel="Remember me"
              >
                <Checkbox
                  value="remember"
                  style={{ margin: 5 }}
                >
                  <FormattedMessage
                    id="form__rememberMe"
                    defaultMessage="Remember me"
                  />
                </Checkbox>
              </Checkbox.Group>
            </Row>

            <Button
              block
              colorScheme="light"
              accessibilityLabel="ccLogin"
              testID="loginButton"
              style={{ marginTop: 10 }}
              isDisabled={!this._isValid()}
              onPress={this.doLogin}
            >
              <FormattedMessage
                id="form__login"
                defaultMessage="Login"
              />
            </Button>
            {this._errorMessage()}
          </KeyboardAvoidingView>
          <View style={this.style.footer}>
            <TouchableWithoutFeedback onPress={this._changeIP}>
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: '#ddd' }}>{this.state.serverURL}</Text>
                <Text style={{ color: '#ddd' }}>{version}</Text>
              </View>
            </TouchableWithoutFeedback>
            {Platform.OS === 'android' && !IS_ELO && (
              <Button
                variant="ghost"
                onPress={() => {
                  IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.WIFI_SETTINGS);
                }}
              >
                <Text>Wifi Settings</Text>
              </Button>
            )}
          </View>
        </View>
      </View>
    );
  }

  _isValid = () => !this.state.processing && this.state.emailValid && this.state.passValid;

  onLayout = () => {
    const dimensions = Dimensions.get('window');
    this.setState({
      orientation: dimensions.width > dimensions.height ? 'LANDSCAPE' : 'PORTRAIT',
    });
  };

  _changeIP = () => {
    if (SHOW_SERVER_CHOOSER) {
      this.props.navigation.navigate('ServerChooser');
    } else if (axios.defaults.baseURL !== 'https://bbot.menu/') {
      prompt({
        title: 'Server IP Address:',
        onSubmit: val => {
          axios.defaults.baseURL = val;
          this.setState({
            serverURL: val,
          });
        },
        defaultValue: axios.defaults.baseURL,
        placeholder: 'https://dev.bbot.menu/',
      });
    }
  };

  doLogin = async () => {
    const { navigation } = this.props;

    this.setState({
      processing: true,
      showPass: false,
      errorMsg: null,
    });

    const response = await API.login(this.state.email, this.state.password);

    if (response.success) {
      AsyncStorage.setItem('rememberEmail', this.state.rememberEmail.toString());
      if (response.allowed_customers.length > 1) {
        navigation.navigate('CustomerChooser', {
          customers: response.allowed_customers,
        });
      } else {
        navigation.navigate('AuthLoading');
      }
    } else {
      this.setState({
        processing: false,
        errorMsg: response.error,
      });
    }
  };

  _closeLocalePicker = () => this.setState({ showLanguagePicker: false });

  _selectLocale = async val => {
    if (await API.setDeviceLocale(val)) {
      this.setState({
        device_language: val,
      });
    }
    this.setState({
      showLanguagePicker: false,
    });
  };

  _errorMessage() {
    if (this.state.errorMsg) {
      return (
        <View style={this.style.errorMessage}>
          <Text style={this.style.errorText}>{this.state.errorMsg}</Text>
        </View>
      );
    }
  }
}

const styles = EStyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
});

const portraitStyles = EStyleSheet.create({
  contentContainer: {
    flex: 1,
  },
  header: {
    alignItems: 'center',
    margin: 30,
  },
  headerLogo: {},
  body: {
    flex: 1,
    minHeight: 300,
    minWidth: 340,
    maxWidth: 500,
  },
  formControl: {
    marginBottom: 10,
  },
  errorMessage: {
    marginTop: 5,
    alignItems: 'center',
  },
  errorText: {
    color: Colors.error,
  },
  footer: {
    alignItems: 'center',
    marginBottom: 5,
  },
});

const landscapeStyles = EStyleSheet.create({
  contentContainer: {
    maxWidth: 500,
  },
  header: {
    alignItems: 'center',
    margin: 30,
  },
  headerLogo: {},
  body: {
    flex: 1,
    minHeight: 250,
    minWidth: 400,
  },
  form: {
    marginBottom: 10,
  },
  errorMessage: {
    marginTop: 5,
    alignItems: 'center',
  },
  errorText: {
    color: Colors.error,
  },
  footer: {
    alignItems: 'center',
    marginBottom: 5,
  },
});
