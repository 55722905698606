import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { Pressable } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import { ItemPart } from '../models';
import API from '../api';
import OrderHelper from '../helpers/OrderHelper';

export default class CartItemModifiers extends Component {
  static propTypes = {};

  render() {
    const { item, itemPart, current, style, showModifierRemoveButton = false } = this.props;
    const flattenedMods = Object.values(item.mods).flat();
    const sortedModGroups = OrderHelper.prepareGroupedMods(flattenedMods);
    if (!flattenedMods.some(mod => mod.selected)) {
      return null;
    }
    const partIndex = item._parts?.indexOf(itemPart);
    const partWeights = item._parts?.map(part => part.numerator / part.denominator);

    const someModsWithoutHeadingsShown = sortedModGroups.filter(
      group => !group.showOnTicket && group.modifiers.some(mod => mod.selected),
    ).length;

    return (
      <View style={{ marginTop: someModsWithoutHeadingsShown ? 6 : 0 }}>
        {sortedModGroups.map(group => {
          const mods = group.modifiers;
          return (
            <View key={group.groupName}>
              {group.showOnTicket && group.modifiers.some(mod => mod.selected) && (
                <Text style={[style, { marginTop: 6 }]}>{group.groupName}</Text>
              )}
              {mods.map((mod, idx) => {
                const textStyle = {
                  textDecorationLine: mod === current ? 'underline' : 'none',
                  color:
                    mod?.errors?.length ||
                    !API.menuData?.modifiersById[mod.modifier.id].is_fulfillable
                      ? 'red'
                      : style?.color ?? 'black',
                  fontWeight: !API.menuData?.modifiersById[mod.modifier.id].is_fulfillable
                    ? 'bold'
                    : 'normal',
                };
                if (mod.selected) {
                  const modParts = itemPart
                    ? ItemPart.distributeByWeights(
                        mod.modifier.price_in_cents * item.qty,
                        partWeights,
                      )
                    : [mod.modifier.price_in_cents];
                  const modPrice = itemPart ? modParts?.[partIndex] : mod.modifier.price_in_cents;
                  return (
                    <View key={`${idx}-mod.id`}>
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={[style, textStyle]}>
                          {mod.qty > 1 && `${mod.qty}x `}
                          {mod.modifier.name}
                        </Text>
                        {mod.pretax_cents > 0 && (
                          <Text style={[{ marginLeft: 10 }, style]}>
                            [+
                            <FormattedCurrency value={modPrice / 100} /> ea.]
                          </Text>
                        )}
                      </View>
                      {!API.menuData.modifiersById[mod.modifier.id].is_fulfillable && (
                        <View style={{ flexDirection: 'row' }}>
                          <Text
                            style={[style, { marginLeft: 8 }]}
                          >{`${mod.getName()} is no longer available. `}</Text>
                          {showModifierRemoveButton && (
                            <Pressable onPress={() => this._removeCartMod(mod)}>
                              <Text style={[style, { fontStyle: 'italic' }]}>
                                Click here to remove from cart.
                              </Text>
                            </Pressable>
                          )}
                        </View>
                      )}
                      <CartItemModifiers
                        style={style}
                        current={current}
                        item={mod}
                      />
                    </View>
                  );
                }
                return null;
              })}
            </View>
          );
        })}
      </View>
    );
  }

  _removeCartMod = mod => {
    mod.selected = false;
    API._carts[0].trigger('update');
  };
}
