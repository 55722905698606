import React from 'react';
import { View, Text, TextInput, Pressable } from 'react-native';
import { FormattedCurrency } from 'react-native-globalize';
import { Slider } from 'native-base';
import { TipSectionStyles as styles } from '../../../styles/POSStyles';

function CustomTipSection({
  setShowTipSlider,
  showTipSlider,
  selectedTipPercent,
  cartSubtotalCents,
  setSelectedTipPercent,
  customTipInputRef,
  setCustomTip,
  customTip,
}) {
  const {
    TipsCalculationText,
    TipsTextRow,
    TipsText,
    TipsAmountText,
    CustomTipsTextContainer,
    CustomTipsInput,
  } = styles;

  return (
    <View>
      <View style={TipsTextRow}>
        <Text style={TipsText}>Custom Tip Amount</Text>
        <Pressable onPress={() => setShowTipSlider(showSlider => !showSlider)}>
          <Text style={TipsCalculationText}>
            {showTipSlider ? 'Enter amount instead' : 'Enter percent instead'}
          </Text>
        </Pressable>
      </View>

      {showTipSlider && (
        <View style={CustomTipsTextContainer}>
          <Text style={TipsText}>{`${(selectedTipPercent * 100).toFixed(0)}%`}</Text>
          <FormattedCurrency
            style={TipsAmountText}
            value={selectedTipPercent * cartSubtotalCents * 0.01}
          />
        </View>
      )}

      <View>
        {showTipSlider ? (
          <Slider
            colorScheme="blue"
            defaultValue={selectedTipPercent * 100}
            size="md"
            onChange={newTipPercent => {
              setCustomTip((newTipPercent * 0.0001 * cartSubtotalCents).toFixed(2));
              setSelectedTipPercent(newTipPercent * 0.01);
            }}
          >
            <Slider.Track>
              <Slider.FilledTrack />
            </Slider.Track>
            <Slider.Thumb />
          </Slider>
        ) : (
          <TextInput
            autoFocus
            ref={customTipInputRef}
            style={CustomTipsInput}
            value={customTip ?? ''}
            keyboardType="numeric"
            onChangeText={newTipAmount => {
              const tipAmount = newTipAmount.replace(/[^0-9 | .]/, '');
              setCustomTip(tipAmount);
              setSelectedTipPercent((tipAmount * 100) / cartSubtotalCents);
            }}
            placeholder="0.00"
            underlineColorAndroid="transparent"
          />
        )}
      </View>
    </View>
  );
}

export default CustomTipSection;
