import React from 'react';
import { Icon } from 'native-base';
import { View, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { EmptyStateStyles as styles } from './Styles';

function EmptyState({ message, icon = null }) {
  return (
    <View style={styles.Container}>
      {icon ?? (
        <Icon
          size={12}
          marginRight={1}
          as={Ionicons}
          name="leaf-outline"
          color="white"
        />
      )}
      <Text style={styles.EmptyStateText}>{message}</Text>
    </View>
  );
}

export default EmptyState;
