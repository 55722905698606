/**
 * Printer
 * @author Justin Belardi
 * @description Base Printer class for all Terminal Printers. The sendPrintTask() function here resolves as an error.
 * It should be overwritten by and component that extends this class
 */

export default class Printer {
  constructor(printerConfigDict) {
    this.ip = printerConfigDict.local_ip;
    this.port = printerConfigDict.local_port;
  }

  sendPrintTask = async () =>
    new Promise((resolve, reject) => {
      resolve({
        error: `sendPrintTask was not overwritten in the Printer Specific Class for printer with config: ${this.printerConfigDict}`,
      });
    });
}
