import React, { useEffect, useRef, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';

// Components
import { Text } from 'native-base';
import { ScrollView, View, Modal, Pressable } from 'react-native';

// Styles
import { styles as gStyle, modalStyles } from '../../styles/Global';
import Colors from '../../constants/Colors';

const listItemHeight = 60;

function PickerModal({ data, selectedValue, onItemPress, visible, onHideCallback, width, title }) {
  const [containerHeight, setContainerHeight] = useState(null);
  const [scrollHeight, setScrollHeight] = useState(null);
  const scrollViewRef = useRef();

  useEffect(() => {
    const scrollOffset = selectedValue
      ? data.findIndex(item => item.value === selectedValue) * listItemHeight
      : scrollHeight / 2;
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: scrollOffset - containerHeight / 2 + listItemHeight / 2,
      animated: false,
    });
  }, [selectedValue, scrollHeight, containerHeight]);

  return (
    <Modal
      visible={visible}
      onRequestClose={onHideCallback}
      transparent
      statusBarTranslucent
    >
      <Pressable
        style={{ height: '100%', width: '100%' }}
        onPress={onHideCallback}
      >
        <View style={gStyle.modalBackground}>
          <View style={[styles.pickerContainer, { width }]}>
            {title && (
              <View
                style={[
                  modalStyles.header,
                  styles.pickerHeader,
                  { width, backgroundColor: 'black' },
                ]}
              >
                <Text style={{ color: 'white' }}>{title}</Text>
              </View>
            )}
            <View
              style={[modalStyles.body, styles.pickerBody]}
              onLayout={event => {
                const { height } = event.nativeEvent.layout;
                setContainerHeight(height);
              }}
            >
              <ScrollView
                ref={scrollViewRef}
                onContentSizeChange={(width, height) => {
                  setScrollHeight(height);
                }}
              >
                {data.map(item => (
                  <Pressable
                    key={item.value}
                    testID={`selectedItem${item.value}`}
                    onPress={() => onItemPress(item.value)}
                  >
                    <View
                      style={[
                        styles.listItem,
                        item.value === selectedValue && { backgroundColor: Colors.primary },
                      ]}
                    >
                      <Text
                        bold
                        style={item.value === selectedValue && { color: 'white' }}
                      >
                        {item.label}
                      </Text>
                    </View>
                  </Pressable>
                ))}
              </ScrollView>
            </View>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
}

export default PickerModal;

export const styles = EStyleSheet.create({
  pickerContainer: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    alignSelf: 'center',
    maxHeight: '80%',
  },

  pickerHeader: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },

  pickerBody: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    flexDirection: 'row',
    overflow: 'hidden',
  },

  listItem: {
    alignItems: 'center',
    justifyContent: 'center',
    height: listItemHeight,
    borderBottomWidth: 1,
    borderColor: 'rgba(211, 211, 211, 1)',
  },
});
