import React from 'react';
import { Dimensions, View, Text, FlatList } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FontAwesome } from '@expo/vector-icons';
import StationButton from '../components/StationButton';
import API from '../api';
import Loader from '../components/Loader';
import ReaderStatus from '../components/ReaderStatus';
import HeaderIconButton from '../components/HeaderIconButton';
import DraggableScrollView from '../components/DraggableScrollView';
import FilterPillToggle from '../components/FilterPillToggle';

const { width } = Dimensions.get('window');
const targetBtnSize = 150;

export default class StationsScreen extends React.Component {
  static navigationOptions = props => {
    const { navigation } = props;
    return {
      title: 'Stations',
      headerRight: () => (
        <View style={{ marginRight: 10, flexDirection: 'row', alignItems: 'center' }}>
          <HeaderIconButton
            testID="stationsUserButton"
            name={API.currUser ? API.currUser.getName() : 'User'}
            textPath="username"
            formatData={{ username: API?.currUser ? API.currUser.getName() : 'User' }}
            icon="user"
            type={FontAwesome}
            accessibilityLabel="userBtn"
            onPress={() => {
              API.clearUser();
              navigation.navigate('Pin', {
                forwardTo: 'StationsScreen',
              });
            }}
          />
          <ReaderStatus />
        </View>
      ),
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dataChanged: false,
      showClosed: false,
      showSnoozed: false,
      stations: [],
      orientation: API.orientation,
      columns: Math.floor(width / targetBtnSize),
    };
  }

  componentDidMount() {
    this._mounted = true;

    this.props.navigation.addListener('willFocus', () => {
      if (this.state.stations.length) this._updateStations();
    });

    API.on('stations', this._updateStations);

    if (API.hasPolled) {
      this._updateStations();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('stations', this._updateStations);
  }

  _onLayout = () => {
    const { width, height } = Dimensions.get('window');
    const numColumns = Math.floor(width / targetBtnSize);
    this.setState({
      columns: numColumns,
      orientation: (width > height ? 'landscape' : 'portrait') + numColumns,
    });
  };

  render() {
    const { loading, orientation, stations, columns, dataChanged, showClosed, showSnoozed } =
      this.state;
    return (
      <View
        testID="stationsScreen"
        style={{ flex: 1 }}
        onLayout={this._onLayout}
      >
        <Loader shown={loading} />
        <View style={{ flex: 1 }}>
          <View
            style={{
              backgroundColor: 'white',
              borderBottomColor: '#ccc',
              borderBottomWidth: 1,
              paddingHorizontal: 5,
            }}
          >
            <DraggableScrollView
              horizontal
              keyboardShouldPersistTaps="always"
              contentContainerStyle={{
                alignItems: 'center',
                flexGrow: 1,
                paddingVertical: 5,
              }}
              nativeID="filters"
            >
              <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <FilterPillToggle
                  label="Closed Orders"
                  onPress={this.toggleClosed}
                  active={showClosed}
                />
                <FilterPillToggle
                  label="Snoozed Orders"
                  onPress={this.toggleSnoozed}
                  active={showSnoozed}
                />
              </View>
            </DraggableScrollView>
          </View>
          <FlatList
            key={orientation}
            data={stations}
            keyExtractor={s => s.id}
            renderItem={this._renderItem}
            horizontal={false}
            numColumns={columns}
            columnWrapperStyle={columns > 1 ? styles.row : null}
            extraData={dataChanged}
            ListEmptyComponent={this._listEmpty}
          />
        </View>
      </View>
    );
  }

  _listEmpty = () => (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20,
      }}
    >
      <Text style={{ fontSize: 16, color: '#a9a9a9', fontWeight: 'bold' }}>
        {this.state.loading ? 'Loading' : 'No Stations Found'}
      </Text>
    </View>
  );

  toggleClosed = () => {
    this.setState({
      showClosed: !this.state.showClosed,
      dataChanged: !this.state.dataChanged,
    });
  };

  toggleSnoozed = () => {
    this.setState({
      showSnoozed: !this.state.showSnoozed,
      dataChanged: !this.state.dataChanged,
    });
  };

  _renderItem = data => (
    <StationButton
      columns={this.state.columns}
      station={data.item}
      showSnoozed={this.state.showSnoozed}
      showClosed={this.state.showClosed}
      onPress={this.goToStation}
    />
  );

  _updateStations = () => {
    if (this._mounted) {
      this.setState({
        stations: API.getStations(),
        loading: false,
        dataChanged: !this.state.dataChanged,
      });
    }
  };

  goToStation = station => {
    const { navigation } = this.props;
    navigation.navigate('StationView', {
      station,
      filters: {
        showClosed: this.state.showClosed,
        showSnoozed: this.state.showSnoozed,
      },
    });
  };
}

const styles = EStyleSheet.create({
  row: {
    flex: 1,
    alignItems: 'center',
  },
});
