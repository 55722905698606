export const tintColor = '#2f95dc';

// BBOT Palette:
export const primary = '#090934';
export const primaryLight = '#A5E3F6';
export const secondary = '#20409a';
export const ternary = '#9665aa';
export const quaternary = '#48A9A6';
export const special = '#11711a';

export const pickupNoFeedback = '#e3652a';

export const lightGray = '#e5e7e9';
export const gray = '#bcbec0';
export const darkGray = '#7f8183';
export const gray1 = '#bebebe';
export const light = '#FFF';
export const white = '#FFF';
// export const light = '#000';

export const success = '#5CB85C';

export const dark = '#000';
// export const dark = '#FFF';
export const accent = '#ed1651';
export const error = '#ff0000';
export const warning = '#F0AD4E';
export const danger = '#D9534F';

export const tabIconDefault = light;
export const tabIconSelected = primary;
export const tabBar = '#fefefe';
export const errorBackground = error;
export const errorText = light;
export const warningBackground = '#EAEB5E';
export const warningText = '#666804';
export const noticeBackground = tintColor;
export const noticeText = '#fff';
export const background = '#fff';
export const highlight = '#17b5e9';
export const buttonSelected = '#c7c7c7';
export const isPolling = success;
export const linkColor = primary;
export const disabled = darkGray;

// -----------------------------------
//
// Existing Theme
//
// -----------------------------------

export const oldTheme = {
  primary: {
    regular: '#090934',
    light: '#05b3e9',
    dark: '#090934',
  },

  secondary: {
    regular: '#20409a',
    light: '#20409A',
    dark: '#20409A',
  },

  success: {
    regular: '#18981D',
    light: '#E3F9E5',
    dark: '#05400A',
  },

  warning: {
    regular: '#F7C948',
    light: '#FFFAEB',
    dark: '#4E3B09',
  },

  error: {
    regular: '#E12D39',
    light: '#490311',
    dark: '#FFEEEE',
  },

  grayscale: {
    title: '#101D37',
    body: '#4B5466',
    label: '#6E7891',
    placeholder: '#B6C0D5',
    line: '#ECEFF7',
    inputBackground: '#FDFDFF',
    background: '#FDFDFD',
    offWhite: '#FCFCFC',
  },
};

// -----------------------------------
//
// New Design System Colors
//
// -----------------------------------

export const lightTheme = {
  primary: {
    regular: '#1167E8',
    light: '#EAF2FE',
    dark: '#034284',
  },

  secondary: {
    regular: '#20409A',
    light: '#20409A',
    dark: '#20409A',
  },

  success: {
    regular: '#18981D',
    light: '#E3F9E5',
    dark: '#05400A',
  },

  warning: {
    regular: '#F7C948',
    light: '#FFFAEB',
    dark: '#4E3B09',
  },

  error: {
    regular: '#E12D39',
    light: '#490311',
    dark: '#FFEEEE',
  },

  grayscale: {
    title: '#101D37',
    body: '#4B5466',
    label: '#6E7891',
    placeholder: '#B6C0D5',
    line: '#ECEFF7',
    inputBackground: '#FDFDFF',
    background: '#FDFDFD',
    offWhite: '#FCFCFC',
  },
};
