import { StyleSheet } from 'react-native';
import { Button, Icon, Text } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { ListItem } from '../../bbot-component-library';

export default function MenuListEmpty({ searchText, isEmpty, noResults, refreshing, onRefresh }) {
  let message = `No Results for "${searchText}"`;
  if (noResults && !searchText) message = 'There are no items which match your selected filters';
  if (isEmpty) message = 'There were issues getting your menu items';
  return (
    <ListItem style={style.container}>
      <Text style={style.text}>{message}</Text>
      {isEmpty && (
        <Button
          isDisabled={refreshing}
          colorScheme="primary"
          onPress={onRefresh}
          isLoading={refreshing}
          isLoadingText="Updating Menu Data"
          leftIcon={
            <Icon
              as={Ionicons}
              name="refresh"
            />
          }
        >
          Refresh Menu Items
        </Button>
      )}
    </ListItem>
  );
}

const style = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    flexDirection: 'column',
  },
  text: {
    color: '#777',
    fontWeight: 'bold',
    marginBottom: 10,
  },
});
