import React, { Component } from 'react';
import { Pressable, View } from 'react-native';
import { Badge, Icon, Text } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import NotificationModal from './NotificationModal';
import API from '../../api';

export default class NotificationToggle extends Component {
  static propTypes = {};

  static style = EStyleSheet.create({
    body: {
      marginHorizontal: 15,
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'visible',
    },
    badge: {
      transform: [{ scale: 0.8 }],
      position: 'absolute',
      top: -10,
      right: 2,
    },
  });

  state = {
    newMessageCount: 0,
  };

  componentDidMount() {
    this._listener = API.on('notices', this._update);
    this.setState({
      newMessageCount: this._newMessageCount(),
    });
  }

  componentWillUnmount() {
    this._listener.remove();
  }

  _update = () => {
    this.setState({ newMessageCount: this._newMessageCount() });
  };

  render() {
    const { showLabel } = this.props;
    const { newMessageCount } = this.state;
    const { config } = API;
    if (!config.receive_service_requests) return null;

    return (
      <View>
        {newMessageCount > 0 && (
          <Badge
            colorScheme="success"
            rounded="full"
            variant="solid"
            style={NotificationToggle.style.badge}
            zIndex={10}
            alignSelf="flex-end"
          >
            {newMessageCount}
          </Badge>
        )}
        <Pressable
          testID="notificationToggle"
          onPress={this._togglePopup}
          style={NotificationToggle.style.body}
        >
          <Icon
            name="message-text"
            as={MaterialCommunityIcons}
            size={22}
            color="white"
          />
          {showLabel && <Text style={{ color: 'white', fontSize: 12, marginTop: 3 }}>Inbox</Text>}
        </Pressable>
      </View>
    );
  }

  _newMessageCount = () =>
    Object.values(API._notices).filter(n => !n.time_read && n.status !== 'dismissed').length;

  _togglePopup = ({ nativeEvent }) => {
    const { showLabel } = this.props;
    NotificationModal.show(showLabel);
  };
}
