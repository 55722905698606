import React, { Component } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import PropTypes from 'prop-types';
import Markdown from 'react-native-markdown-display';
import { Asset } from 'expo-asset';

const CHANGELOG = require('../changelog.md');

export default class Changelog extends Component {
  static navigationOptions = () => ({
    title: 'Changelog',
  });

  static propTypes = {};

  state = {
    changelog: '# Changelog\r\n### Loading...',
  };

  componentDidMount() {
    if (Platform.OS === 'web') {
      fetch(CHANGELOG)
        .then(response => response.text())
        .then(text => {
          this.setState({ changelog: text });
        });
    } else {
      // Might be able to simplify this
      const file = Asset.fromModule(CHANGELOG);

      file.downloadAsync().then(() => {
        fetch(file.localUri)
          .then(response => response.text())
          .then(text => {
            this.setState({
              changelog: text,
            });
          });
      });
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView>
          <View style={{ flex: 1, margin: 20 }}>
            <Markdown>{this.state.changelog}</Markdown>
          </View>
        </ScrollView>
      </View>
    );
  }
}
