import React from 'react';
import { Text } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

function Title({ children, ...props }) {
  return (
    <Text
      style={styles.title}
      {...props}
    >
      {children}
    </Text>
  );
}

const styles = EStyleSheet.create({
  title: {
    fontSize: Platform.OS === 'ios' ? 17 : 19,
    fontFamily: Platform.OS === 'ios' ? 'System' : 'Roboto_medium',
    color: Platform.OS === 'ios' ? '#000' : '#FFF',
    fontWeight: Platform.OS === 'ios' ? '700' : undefined,
    textAlign: Platform.OS === 'ios' ? 'center' : 'left',
    paddingLeft: Platform.OS === 'ios' ? 4 : 0,
    marginLeft: Platform.OS === 'ios' ? undefined : -3,
    paddingTop: 1,
  },
});

export default Title;
