import color from 'color';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from '../constants/Colors';

/**
 * @param {string} backgroundColor
 * @returns {any|null}
 */

export const getTextColor = (backgroundColor = '#FFFFFF') => {
  if (!backgroundColor) return null;
  return color(backgroundColor).isDark() ? Colors.light : Colors.dark;
};
