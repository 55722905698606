import React from 'react';
import { Platform, View, TextInput } from 'react-native';
import { Switch, Text } from 'native-base';
import * as Animatable from 'react-native-animatable';
import PropTypes from 'prop-types';
import AsyncStorage from '@react-native-community/async-storage';
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../../api';
import PinButton from './PinButton';
import PinDigit from './PinDigit';
import { TextL } from '../Text';
import HelpIcon from '../HelpIcon';
import { Colors } from '../../styles';
import IconButton from '../IconButton';
import LocalePicker from '../LocalePicker';
import { SHOW_LOCALE_CHOOSER } from '../../constants/Config';
import Loader from '../Loader';

const PINLENGTH = 4;

export default class PinPad extends React.Component {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    changeUser: PropTypes.bool,
    style: PropTypes.object,
  };

  static defaultProps = {
    changeUser: false,
    style: {},
  };

  _input = null;

  constructor(props) {
    super(props);

    this._isTouchDevice = Platform.OS !== 'web' || 'ontouchstart' in document.documentElement;

    this.state = {
      currPin: [],
      processing: false,
      useAdminPin: true,
      showLanguagePicker: false,
    };

    this._input = React.createRef();
  }

  async componentDidMount() {
    const useAdminPin = JSON.parse(await AsyncStorage.getItem('useAdminPin')) || false;
    this.setState({ useAdminPin });
  }

  toggleUseAdminPin = val => {
    this.setState({ useAdminPin: val });
    AsyncStorage.setItem('useAdminPin', JSON.stringify(val));
  };

  render() {
    const { useAdminPin } = this.state;
    const { style } = this.props;
    const loading = this.state.processing ? <Loader /> : null;

    return (
      <View style={[styles.container, style]}>
        <View style={styles.pinPad}>
          {loading}
          <TextL
            id="pidPad__enterPin"
            defalutMessage="Enter Pin"
            style={{ marginBottom: 10 }}
          />

          <Animatable.View
            ref={ref => (this.pinScreen = ref)}
            style={styles.displayArea}
          >
            {this._getPinScreen()}
          </Animatable.View>

          <View
            style={styles.keyPad}
            testID="pinpad_keypad"
          >
            <View style={styles.buttonRow}>
              <PinButton
                value={1}
                onPress={this._buttonPressed}
              />
              <PinButton
                value={2}
                onPress={this._buttonPressed}
              />
              <PinButton
                value={3}
                onPress={this._buttonPressed}
              />
            </View>
            <View style={styles.buttonRow}>
              <PinButton
                value={4}
                onPress={this._buttonPressed}
              />
              <PinButton
                value={5}
                onPress={this._buttonPressed}
              />
              <PinButton
                value={6}
                onPress={this._buttonPressed}
              />
            </View>
            <View style={styles.buttonRow}>
              <PinButton
                value={7}
                onPress={this._buttonPressed}
              />
              <PinButton
                value={8}
                onPress={this._buttonPressed}
              />
              <PinButton
                value={9}
                onPress={this._buttonPressed}
              />
            </View>
            <View style={styles.buttonRow}>
              <PinButton
                value=""
                onPress={this._buttonPressed}
              />
              <PinButton
                value={0}
                onPress={this._buttonPressed}
              />
              <PinButton
                value="Del"
                onPress={this._buttonPressed}
              />
            </View>
          </View>

          <View style={styles.footer}>
            {SHOW_LOCALE_CHOOSER && (
              <IconButton
                icon="globe-outline"
                label="locale__name"
                color="black"
                onPress={() => this.setState({ showLanguagePicker: true })}
                style={{ flex: -1 }}
              />
            )}
            {API.is_admin && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Switch
                  value={useAdminPin}
                  onValueChange={this.toggleUseAdminPin}
                />
                <Text style={{ marginHorizontal: 6 }}>Admin Pin</Text>
                <HelpIcon
                  color={Colors.primary}
                  title="Admin Pin"
                  message="Logs you in as an admin instead of an employee"
                />
              </View>
            )}
          </View>
        </View>
        {!this._isTouchDevice && (
          <TextInput
            nativeID="pinPadInput"
            ref={this._input}
            autoFocus
            onKeyPress={this._onKeyPress}
            onBlur={this._reFocusInput}
            keyboardType="numeric"
            style={styles.hiddenInput}
          />
        )}
        {this.state.showLanguagePicker && (
          <LocalePicker
            selectedValue={API.deviceLocale}
            visible
            onHideCallback={this._closeLocalePicker}
            onItemPress={this._selectLocale}
          />
        )}
      </View>
    );
  }

  _reFocusInput = () => {
    if (this._input.current) this._input.current.focus();
  };

  shakeDisplay() {
    this.pinScreen.shake(800);
  }

  _getPinScreen() {
    const screen = [];
    for (let i = 0; i < PINLENGTH; i++) {
      screen.push(
        <PinDigit
          key={i}
          position={i}
          value={this.state.currPin[i]}
        />,
      );
    }
    return screen;
  }

  _buttonPressed = value => {
    if (value === 'Del') {
      this.state.currPin.pop();
    } else if (this.state.currPin.length < 4) {
      this.state.currPin.push(value);
    }

    if (this.state.currPin.length === PINLENGTH) {
      this._verifyPin(this.state.currPin.join(''));
    }

    this.setState({
      currPin: this.state.currPin,
    });
  };

  async _verifyPin(pin) {
    const { changeUser, onSuccess } = this.props;
    const { useAdminPin } = this.state;

    this.setState({
      processing: true,
    });

    const isAdmin = API.is_admin ? useAdminPin : false;
    const result = await API.checkPin(pin, changeUser, isAdmin);
    this.setState({
      currPin: [],
      processing: false,
    });
    if (!result.user) {
      this.shakeDisplay();
    } else {
      onSuccess(result.user);
    }
  }

  _onKeyPress = ({ nativeEvent }) => {
    const { key } = nativeEvent;
    if (key === 'Backspace') this._buttonPressed('Del');
    else if (key in [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]) {
      this._buttonPressed(parseInt(key));
    } else {
      nativeEvent.stopImmediatePropagation();
    }
  };

  clear = () => {
    this.setState({
      currPin: [],
    });
  };

  focus = () => {
    if (Platform.OS === 'web') {
      setTimeout(() => {
        const el = document.getElementById('pinPadInput');
        if (el) el.focus();
      });
    }
  };

  _selectLocale = async val => {
    if (await API.setDeviceLocale(val)) {
      this.setState({
        device_language: val,
      });
    }
    this.setState({
      showLanguagePicker: false,
    });
  };

  _closeLocalePicker = () => this.setState({ showLanguagePicker: false });
}

const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  pinPad: {
    padding: 30,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  displayArea: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    maxHeight: 50,
  },
  keyPad: {
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  buttonRow: {
    maxHeight: 70,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'center',
  },
  hiddenInput: {
    position: 'absolute',
    top: -1001,
  },
});
