import { Image } from 'react-native';
import { Icon } from 'native-base';
import React from 'react';
import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import API from '../api';
import { StatusIcons } from './Constants';
import Colors from './Colors';

const customColor = fulfillmentMethod =>
  API.config.custom_kds_ticket_colors[fulfillmentMethod] ||
  API.customer.default_kds_ticket_colors[fulfillmentMethod] ||
  null;

export const HeaderColors = {
  server_delivery: {
    name: 'Server Delivery',
    get color() {
      return (
        customColor('server_delivery') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#07330C' : Colors.special)
      );
    },
    icon: <Image source={StatusIcons.large.wait_for_runner} />,
  },
  patron_pickup: {
    name: 'Pickup',
    get color() {
      return (
        customColor('patron_pickup') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#563A60' : Colors.ternary)
      );
    },
    icon: <Image source={StatusIcons.large.onshelf} />,
  },
  runner_with_cc: {
    name: 'Runner',
    get color() {
      return (
        customColor('runner_with_cc') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#245150' : Colors.quaternary)
      );
    },
    icon: (
      <Icon
        name="running"
        as={FontAwesome5}
      />
    ),
  },
  robot_delivery: {
    name: 'Robot',
    get color() {
      return (
        customColor('robot_delivery') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#007BA0' : Colors.primary)
      );
    },
    icon: <Image source={StatusIcons.large.botsent} />,
  },
  driver_delivery: {
    name: 'Delivery',
    get color() {
      return (
        customColor('driver_delivery') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#20409a' : Colors.secondary)
      );
    },
    icon: <Image source={StatusIcons.large.wait_for_driver} />,
  },
  runner_nofeedback: {
    name: 'Runner',
    get color() {
      return (
        customColor('runner_nofeedback') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#64021C' : Colors.accent)
      );
    },
    icon: (
      <Icon
        as={Ionicons}
        name="check"
      />
    ),
  },
  pickup_nofeedback: {
    name: 'Pickup',
    get color() {
      return (
        customColor('pickup_nofeedback') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#843611' : Colors.pickupNoFeedback)
      );
    },
    icon: (
      <Icon
        as={Ionicons}
        name="check"
      />
    ),
  },
  catering: {
    name: 'Catering',
    get color() {
      return (
        customColor('catering') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#234523' : Colors.success)
      );
    },
    icon: <Image source={StatusIcons.large.making} />,
  },
  driver_delivery_with_expo: {
    get color() {
      return (
        customColor('driver_delivery_with_expo') ||
        (API.handheldDevice.preferences.kds_use_high_contrast
          ? '#0E3744'
          : Colors.secondary_analagous)
      );
    },
  },
  patron_pickup_with_expo: {
    get color() {
      return (
        customColor('patron_pickup_with_expo') ||
        (API.handheldDevice.preferences.kds_use_high_contrast
          ? '#004E39'
          : Colors.primary_analagous)
      );
    },
  },
  counter_service: {
    get color() {
      return (
        customColor('counter_service') ||
        (API.handheldDevice.preferences.kds_use_high_contrast ? '#BF40BF' : Colors.counter_service)
      );
    },
  },
  counter_service_with_expo: {
    get color() {
      return (
        customColor('counter_service_with_expo') ||
        (API.handheldDevice.preferences.kds_use_high_contrast
          ? '#FFEA00'
          : Colors.counter_service_with_expo)
      );
    },
  },
  counter_nofeedback: {
    get color() {
      return (
        customColor('counter_nofeedback') ||
        (API.handheldDevice.preferences.kds_use_high_contrast
          ? '#FF3131'
          : Colors.counter_nofeedback)
      );
    },
  },
};
