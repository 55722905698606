import React, { Component } from 'react';
import { List, Text, Icon, Switch, View } from 'native-base';
import { ActivityIndicator } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import API from '../api';
import { ListItem, Body, Left, Right } from '../bbot-component-library';
import { styles } from '../styles/ListComponentStyle';
import Colors from '../constants/Colors';
import FolderTabs from '../components/FolderTabs';
import FolderTab from '../components/FolderTab';
import LocationChooserModal from '../components/LocationChooserModal';

const headerHeight = 80; // RNHeader.HEIGHT;

export default class BotControl extends Component {
  static navigationOptions = () => ({
    title: 'Bot Control',
  });

  constructor(props) {
    super(props);

    this.state = {
      config: {},
      messages: {},
      selected_station: null,
      locations: [],
      kds_stations: [],
      manualUpdating: false,
      cancelUpdating: false,
      stowBasketUpdating: false,
      recoverUpdating: false,
      showLocationChooser: false,
    };
  }

  /// call getTabContent() in tab header

  componentDidMount() {
    this._mounted = true;
    const config = API.getConfig();
    const stationsWithRobots = config.kds_stations.filter(station => station.stationControlsRobot);

    this.setState({
      selected_station: stationsWithRobots[0],
      kds_stations: stationsWithRobots,
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  static propTypes = {};

  render() {
    const { messages, kds_stations, manualUpdating } = this.state;
    if (!kds_stations.length) return null;

    return (
      <View style={{ flex: 1 }}>
        {__DEV__ && !kds_stations.find(s => s.rails.length) && (
          <View style={{ alignItems: 'center', backgroundColor: 'red' }}>
            <Text style={{ color: 'white' }}>SHOWING BOT CONTROL IN DEV MODE</Text>
          </View>
        )}
        <FolderTabs style={{ backgroundColor: '#000' }}>
          {[
            <View key="empty-view" />,
            ...kds_stations.map((station, index) => (
              <FolderTab
                active={this.state.selected_station === station}
                key={`station_${station.id}`}
                heading={station.station_name}
                onPress={() => this._setSelectedStation(station)}
              >
                <List style={{ borderWidth: 0 }}>
                  <ListItem itemDivider>
                    <Text variant="divider">Missions</Text>
                  </ListItem>
                  <ListItem
                    first
                    onPress={() => this._manualModePressed(station.id)}
                  >
                    <Left icon>
                      <Icon
                        size={8}
                        style={styles.iconStyle}
                        as={MaterialIcons}
                        name="location-on"
                      />
                    </Left>
                    <Body leftJustify>
                      <Text>Manual Mode</Text>
                    </Body>
                    <Right onListItem>
                      {manualUpdating ? (
                        <ActivityIndicator
                          size="small"
                          color={Colors.primary}
                        />
                      ) : (
                        <Text note>{messages[station.id]?.manual_message}</Text>
                      )}
                      <Icon
                        active4
                        name="chevron-right"
                        as={Entypo}
                      />
                    </Right>
                  </ListItem>
                  <ListItem
                    first
                    onPress={e => this._recallRobot(station.id, index)}
                  >
                    <Left icon>
                      <Icon
                        size={8}
                        style={styles.iconStyle}
                        as={MaterialIcons}
                        name="home"
                      />
                    </Left>
                    <Body leftJustify>
                      <Text>Cancel Mission</Text>
                    </Body>
                    <Right onListItem>
                      {this.state.cancelUpdating ? (
                        <ActivityIndicator
                          size="small"
                          color={Colors.primary}
                        />
                      ) : (
                        <Text note>{messages[station.id]?.recall_message}</Text>
                      )}
                      <Icon
                        active4
                        name="chevron-right"
                        as={Entypo}
                      />
                    </Right>
                  </ListItem>
                  <ListItem itemDivider>
                    <Text variant="divider">Maintenance and Repair</Text>
                  </ListItem>
                  <ListItem
                    first
                    onPress={this._devicePressed}
                  >
                    <Left icon>
                      <Icon
                        size={8}
                        style={styles.iconStyle}
                        as={MaterialIcons}
                        name="unfold-more"
                      />
                    </Left>
                    <Body leftJustify>
                      <Text>Stow Basket Higher Up</Text>
                    </Body>
                    <Right onListItem>
                      {this.state.stowBasketUpdating ? (
                        <ActivityIndicator
                          size="small"
                          color={Colors.primary}
                        />
                      ) : (
                        <Switch
                          value={station.basket_stowed}
                          onValueChange={e =>
                            this._toggleBasket(station.id, station.basket_stowed, index)
                          }
                        />
                      )}
                    </Right>
                  </ListItem>
                  <ListItem
                    first
                    onPress={e => this._recoverRobot(station.id, index)}
                  >
                    <Left icon>
                      <Icon
                        size={8}
                        style={styles.iconStyle}
                        as={MaterialIcons}
                        name="cancel"
                      />
                    </Left>
                    <Body leftJustify>
                      <Text>Recover From Problem</Text>
                    </Body>
                    <Right onListItem>
                      {this.state.recoverUpdating ? (
                        <ActivityIndicator
                          size="small"
                          color={Colors.primary}
                        />
                      ) : (
                        <Text note>{messages[station.id]?.recover_message}</Text>
                      )}
                      <Icon
                        active4
                        name="chevron-right"
                        as={Entypo}
                      />
                    </Right>
                  </ListItem>
                </List>
              </FolderTab>
            )),
          ]}
        </FolderTabs>
        <LocationChooserModal
          visible={this.state.showLocationChooser}
          locations={this.state.locations}
          headerTitle="Manual Mode"
          onCancel={() => {
            this.setState({ showLocationChooser: false });
          }}
          onSave={this._locationPicked}
        />
      </View>
    );
  }

  _setSelectedStation = station => {
    this.setState({
      selected_station: station,
    });
  };

  _locationPicked = async location => {
    // trigger here.
    const response = await API.forceDelivery(this.state.selected_station.id, location.id);
    this._setMessage(this.state.selected_station.id, 'manual_message', response);
    this.setState({ showLocationChooser: false });
  };

  _manualModePressed = async () => {
    this.setState({
      manualUpdating: true,
    });
    const locations = this.state.selected_station.getRobotLocations();

    this.setState({
      showLocationChooser: true,
      locations,
      manualUpdating: false,
    });
  };

  _recoverRobot = async (stationId, index) => {
    this.setState({
      recoverUpdating: true,
    });
    const response = await API.recoverRobot(stationId);
    this._setMessage(
      stationId,
      'recover_message',
      response.success ? 'Recovery started' : 'Unable to Recover',
    );
    this.setState({
      recoverUpdating: false,
    });
  };

  _recallRobot = async (stationId, index) => {
    this.setState({
      cancelUpdating: true,
    });
    const response = await API.recallRobot(stationId);
    this._setMessage(
      stationId,
      'recall_message',
      response.success ? 'Recalled!' : 'Unable to Recall',
    );
    this.setState({
      cancelUpdating: false,
    });
  };

  _setMessage = (stationId, key, message) => {
    const { messages } = this.state;
    if (!messages[stationId]) messages[stationId] = {};
    messages[stationId][key] = message;

    this.setState({ messages }, () => {
      setTimeout(() => {
        if (!this._mounted) return;
        messages[stationId][key] = '';
        this.setState({ messages });
      }, 5000);
    });
  };

  _toggleBasket = async (stationId, basket_stowed, index) => {
    this.setState({
      stowBasketUpdating: true,
    });

    const response = await API.stowBasket(stationId, !basket_stowed);

    if (!response.success) {
      showMessage({
        floating: true,
        position: 'top',
        type: 'error',
        message: response.error,
      });
    }
    this.setState({
      kds_stations: this.state.kds_stations,
      stowBasketUpdating: false,
    });
  };
}
