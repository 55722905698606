/**
 * Screen for displaying Tipping Reports.
 *
 * @author Gilles St-Cyr
 * @date 2020-01-08
 *
 * @todo Cleanup and re-factor.
 * @todo SHOW Warning if No Locations Selected
 * @todo: optimize this._processResults() and api/getTipsReport endpoint to make load times faster for larger time ranges
 */
import React, { Component, useCallback } from 'react';
import {
  Dimensions,
  FlatList,
  ScrollView,
  SectionList,
  TouchableWithoutFeedback,
} from 'react-native';
import { hideMessage, showMessage } from 'react-native-flash-message';
import { View, Container, Text, Icon, Button } from 'native-base';
import moment from 'moment';
import _ from 'lodash';
import DateTimePicker from '@react-native-community/datetimepicker';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormattedCurrency } from 'react-native-globalize';
import SideMenu from 'react-native-side-menu-updated';
import { FontAwesome5, Ionicons, MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import API from '../api';
import { ActionSheet, FilterButton, HeaderIconButton, Loader, LocationFilter } from '../components';
import { Location } from '../models';
import { Colors } from '../styles';
import { offsetInput } from '../helpers/DateTimePickerHelper';

const window = Dimensions.get('window');

export default class TipsReportScreen extends Component {
  static propTypes = {};

  state = {
    loading: false,
    error: null,
    report: [],
    station: '',
    locations: [], // currently selected locations
    grouping: 60, // grouping in minutes
    grouping_text: 'Hour',
    isMobileDrawerOpen: false,
    selectedRows: [],
    reportOrders: [],
    dataChanged: false,
    selected_time_picker: '',
    time_range: 'today',
    last_time_range: 'today',
    menu_visits: [],
    from: moment(),
    to: moment(),
    averageTip: 0,
    tipPercentage: 0,
    windowWidth: window.width,
    bartenders: {},
  };

  _listEmpty = (
    <View style={{ flex: 1, marginTop: 15, alignItems: 'center' }}>
      <Text style={{ fontSize: 20, fontWeight: 'bold' }}>No Results</Text>
    </View>
  );

  constructor(props) {
    super(props);
    const { navigation } = props;

    this.tabletWidth = 768;

    navigation.setParams({
      refresh: this._refresh,
      showMobileDrawerToggle: this.state.windowWidth < this.tabletWidth,
      toggleMobileDrawer: this._toggleMobileDrawer,
    });

    this.menu_visits = [];
    this.date_headings = [];

    this.state.locations = Location.AllLocationIDs();

    AsyncStorage.getItem('tip_report_limit_dates_message').then(res => {
      if (res === 'true') {
        this.setState({ hideLimitDatesMessage: true });
      }
    });
  }

  static navigationOptions = ({ navigation }) => {
    const refresh = navigation.getParam('refresh');
    const showMobileDrawerToggle = navigation.getParam('showMobileDrawerToggle');
    const toggleMobileDrawer = navigation.getParam('toggleMobileDrawer');
    return {
      title: 'Tips Report',
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          <HeaderIconButton
            name="Refresh"
            type={Ionicons}
            icon="refresh"
            onPress={refresh}
          />
          {showMobileDrawerToggle && (
            <HeaderIconButton
              name="Locations"
              type={FontAwesome5}
              icon="list"
              onPress={toggleMobileDrawer}
            />
          )}
        </View>
      ),
    };
  };

  componentDidMount() {
    this.getTipReport('today');
    this._dimensionListener = Dimensions.addEventListener('change', this._updateWindowWidth);
  }

  componentWillUnmount() {
    this._dimensionListener.remove();
  }

  _updateWindowWidth = ({ window }) => {
    this.setState(
      {
        windowWidth: window.width,
      },
      () => {
        this.props.navigation.setParams({
          showMobileDrawerToggle: this.state.windowWidth < this.tabletWidth,
        });
      },
    );
  };

  // todo: refactor this to not accept range as input. It should just use this from/to state
  getTipReport = async range => {
    let { from } = this.state;
    let { to } = this.state;

    if (range === 'today') {
      from = moment();
      to = moment();
    } else if (range === 'yesterday') {
      from = moment().subtract(1, 'day');
      to = moment().subtract(1, 'day');
    }

    this.setState({
      time_range: range,
      from,
      to,
      loading: true,
    });

    const result = await API.tipReport(range, from.toISOString(), to.toISOString());

    // Converts military time (ie: 1130) to fractional hours (ie: 11.5). Used for date math.
    function milToHours(milTime) {
      const hours = Math.floor(milTime / 100);
      const minutes = milTime % 100;
      return hours + minutes / 60;
    }

    const { orders, menu_visits, date_headings, bartenders } = result;

    this.menu_visits = menu_visits;
    this.date_headings = date_headings;

    // Iterate through headings, and update them with shift start and end times
    // being careful to handle shifts which span midnight.
    date_headings.forEach(heading => {
      const { date, shifts } = heading;
      heading.day_start = moment(heading.day_start);
      heading.day_end = moment(heading.day_end);
      heading.visits = this._getNumVisits(heading.day_start, heading.day_end);
      let last;
      const baseTime = moment(date).startOf('day');
      shifts.forEach(shift => {
        if (last && last.name === shift.name) {
          shift.start = last.start;
          if (shift.hours[0] < last.hours[1]) baseTime.add(1, 'day');
        } else {
          shift.start = baseTime.clone().add(milToHours(shift.hours[0]), 'hours');
        }
        shift.end = baseTime.clone().add(milToHours(shift.hours[1]), 'hours');
        shift.visits = this._getNumVisits(shift.start, shift.end);

        if (last && last.name === shift.name) {
          last.end = shift.end;
          last.visits = shift.visits;
        }

        last = shift;
      });
    });

    const sections = this._processResults(orders, date_headings);

    this.setState({
      error: result.error,
      report: sections,
      orders,
      loading: false,
      bartenders,
    });
  };

  _showLimitDatesMessage = () => {
    if (this.state.hideLimitDatesMessage) return;
    return showMessage({
      floating: true,
      icon: 'auto',
      position: 'top',
      type: 'info',
      message: 'Tip Report Limited To 7 days',
      description: (
        <View style={{ width: '100%' }}>
          <Text style={{ color: Colors.white, marginTop: 5, marginBottom: 10 }}>
            Report range is limited to one week. Longer ranges can be searched in the Owner Panel
          </Text>
          <Button
            onPress={this._stopShowingLimitDatesMessage}
            transparent
          >
            Don't show this again
          </Button>
        </View>
      ),
      autoHide: true,
      duration: 10000,
      backgroundColor: Colors.secondary,
      color: Colors.white,
    });
  };

  _stopShowingLimitDatesMessage = () => {
    AsyncStorage.setItem('tip_report_limit_dates_message', 'true');
    hideMessage();
    this.setState({
      hideLimitDatesMessage: false,
    });
  };

  // todo: Create more optimized execution for order.forEach loop
  _getDayText = day => {
    let text = day.format('dddd, MMM Do');
    if (day.isSame(moment(), 'day')) text = 'Today';
    else if (day.isSame(moment().subtract(1, 'day'), 'day')) text = 'Yesterday';
    return text;
  };

  _filterLocations = locations => {
    this.setState(
      {
        locations,
      },
      () => {
        this._processResults();
      },
    );
  };

  _filterStation = station => {
    this.setState(
      {
        station,
      },
      () => {
        this._processResults();
      },
    );
  };

  // TODO: Optimize below function to allow >7 day reports. See below for details.
  _processResults = (
    orders = this.state.orders,
    date_headings = this.date_headings,
    locations = this.state.locations,
    station = this.state.station,
  ) => {
    let sections = {};

    const groupByServer = this.state.grouping_text === 'By Server';

    orders = _.orderBy(orders, 'time', 'asc').filter(o => {
      const locationVisible = locations.includes(o.location_id);
      const stationVisible = station ? station === o.bartending_station_id : true;
      return locationVisible && stationVisible;
    });

    // Create keys for each date in the selected date range:
    const days = this.state.to.diff(this.state.from, 'day') + 1;
    for (let i = 0; i < days; i++) {
      const date = this.state.to.clone().subtract(i, 'days');
      const dateKey = this._getDayText(date);
      sections[dateKey] = {
        visits: this._getNumVisits(date.clone().startOf('day'), date.clone().endOf('day')),
      };
    }

    // Map orders into a dict of days, shifts, and ranges:
    // Bulk of report processing time (55%) comes from this loop. Specifically, moment() operations below AND call to ._getDayText.
    // 18% is spent on two separate setState() operations (~9% each).
    orders.forEach(order => {
      order.time = moment(order.time);
      const { time } = order;
      const minutes = time.hours() * 60 + time.minutes();

      const hm = Number.parseInt(time.format('Hmm'));
      const heading = date_headings.find(
        heading => heading.day_start <= time && time < heading.day_end,
      ); // ~10% of runtime
      const date = moment(heading.date);
      const day = this._getDayText(date);

      if (groupByServer) {
        if (!sections[day][order.bartender_id])
          sections[day][order.bartender_id] = { [order.bartender_id]: [] };

        sections[day][order.bartender_id][order.bartender_id].push(order);
      } else {
        try {
          const shift = heading.shifts.find(shift => shift.hours[0] <= hm && hm < shift.hours[1]);

          if (!sections[day][shift.name]) sections[day][shift.name] = { shift };

          const range = Math.floor(minutes / this.state.grouping);

          const rangeStart = moment()
            .startOf('day')
            .minutes(range * this.state.grouping);
          const rangeEnd = moment()
            .startOf('day')
            .minutes(range * this.state.grouping)
            .add(this.state.grouping, 'minute');

          const rangeKey = `${rangeStart.format('h:mmA')} - ${rangeEnd.format('h:mmA')}`;

          if (!sections[day][shift.name][rangeKey]) sections[day][shift.name][rangeKey] = [];

          order.day = heading;
          order.shift = shift;
          order.rangeStart = rangeStart;
          order.rangeEnd = rangeEnd;

          sections[day][shift.name][rangeKey].push(order);
        } catch (err) {
          // live with it for now
          // looks like sometimes shift isn't found for a given order
        }
      }
    });

    // Convert sections dict into something our SectionList component can understand:
    sections = Object.keys(sections).map(date => {
      const numVisits = sections[date].visits;
      delete sections[date].visits;
      return {
        title: date,
        visits: numVisits,
        data: Object.keys(sections[date]).map(shift => {
          const shiftObj = sections[date][shift].shift;
          delete sections[date][shift].shift;
          return {
            title: shift,
            shift: shiftObj,
            data: Object.keys(sections[date][shift]).map(span => {
              const order = sections[date][shift][span][0];
              const numVisits = this._getNumVisits(order.rangeStart, order.rangeEnd);
              return {
                title: span,
                visits: numVisits,
                data: sections[date][shift][span],
              };
            }),
          };
        }),
      };
    });

    const tips = this._tipAverages(orders);

    this.setState({
      selectedRows: [...orders],
      reportOrders: [...orders],
      report: sections,
      averageTip: tips.averageTip,
      tipPercentage: tips.tipPercentage,
    });

    return sections;
  };

  _renderSection = ({ item }) => {
    const { grouping_text, bartenders, selectedRows } = this.state;
    return (
      <ShiftSection
        data={item}
        groupingMethod={grouping_text}
        bartenders={bartenders}
        selectedRows={selectedRows}
        onRangePress={this._onRangePress}
      />
    );
  };

  render() {
    return (
      <SideMenu
        disableGestures
        isOpen={this.state.windowWidth < this.tabletWidth && this.state.isMobileDrawerOpen}
        menu={
          <LocationFilter
            locations={this.state.locations}
            filterLocations={this._filterLocations}
            station={this.state.station}
            filterStation={this._filterStation}
            style={{ borderLeftColor: 'black', borderLeftWidth: 1 }}
          />
        }
        menuPosition="right"
        onChange={isOpen => !isOpen && this.setState({ isMobileDrawerOpen: false })}
        overlayColor="rgba(0,0,0,0.4)"
      >
        <View style={{ backgroundColor: 'white', flex: 1 }}>
          <Loader shown={this.state.loading} />
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <View
              testID="leftPane"
              style={{ flex: 2 }}
            >
              <View
                style={{
                  backgroundColor: '#ffffff',
                  padding: 10,
                  borderBottomWidth: 1,
                  borderBottomColor: '#ccc',
                }}
              >
                <ScrollView horizontal>
                  <FilterButton
                    label="Start Time"
                    value={this.state.from.format('MMM D, LT')}
                    onPress={this._changeFrom}
                  />
                  <FilterButton
                    label="End Time"
                    value={this.state.to.format('MMM D, LT')}
                    onPress={this._changeTo}
                  />
                  <FilterButton
                    label="Grouping"
                    value={this.state.grouping_text}
                    onPress={this._changeGrouping}
                  />
                </ScrollView>
              </View>
              <View style={{ flex: 1, flexDirection: 'column' }}>
                <View style={{ flex: 1 }}>
                  {this._fixedHeader()}
                  <SectionList
                    sections={this.state.report}
                    // `keyExtractor` must include a random value, or rows may disappear when switching between stations.
                    keyExtractor={(item, index) => `shift_${index}__${Math.random()}`}
                    renderItem={this._renderSection}
                    renderSectionHeader={this._sectionHeader}
                    ListEmptyComponent={this._listEmpty}
                    extraData={this.state.dataChanged}
                  />
                </View>
                <View style={styles.summary}>
                  <View
                    style={{
                      flex: 3,
                      alignItems: 'flex-start',
                      paddingRight: 36,
                    }}
                  >
                    <Text style={[styles.summaryValue, { textAlign: 'left' }]}>Totals</Text>
                  </View>
                  <View style={[styles.summaryRow, { alignItems: 'center' }]}>
                    {/* Visits (not calculated; just a placeholder to ensure proper alignment of column values) */}
                    <View style={{ flex: 1, alignItems: 'center', marginLeft: 5 }}>
                      <Text style={[styles.summaryValue, { textAlign: 'center' }]} />
                    </View>
                  </View>
                  <View style={[styles.summaryRow, { alignItems: 'center' }]}>
                    {/* Orders */}
                    <View style={{ flex: 1, alignItems: 'center', marginLeft: 5 }}>
                      <Text style={[styles.summaryValue, { textAlign: 'center' }]}>
                        {this.state.selectedRows.length}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.summaryRow, { alignItems: 'flex-end', paddingRight: 4 }]}>
                    {/* Tips */}
                    <View style={{ flex: 1, alignItems: 'flex-end', marginLeft: 5 }}>
                      <Text
                        style={[styles.summaryValue, { textAlign: 'right' }]}
                        numberOfLines={1}
                      >
                        <FormattedCurrency value={this._selectedTotal()} />
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {this.state.windowWidth >= this.tabletWidth && (
              <View
                testID="rightPane"
                style={{ flex: 1 }}
              >
                <LocationFilter
                  locations={this.state.locations}
                  filterLocations={this._filterLocations}
                  station={this.state.station}
                  filterStation={this._filterStation}
                  style={{ borderLeftColor: 'black', borderLeftWidth: 1 }}
                />
              </View>
            )}
          </View>
          {!!this.state.selected_time_picker && (
            <DateTimePicker
              onChange={
                this.state.selected_time_picker === 'from' ? this._fromChanged : this._toChanged
              }
              maximumDate={new Date()}
              value={offsetInput(this.state[this.state.selected_time_picker])}
              is24Hour
              mode="datetime"
            />
          )}
        </View>
      </SideMenu>
    );
  }

  _selectedRange = () => {
    const sorted = _.orderBy(this.state.selectedRows, 'time');
    if (!sorted.length) return 'Empty Selection';
    if (sorted[0].time.isSame(sorted[sorted.length - 1].time, 'day')) {
      return sorted[0].time.format('ddd, MMM Do');
    }
    return `${sorted[0].time.format('ddd, MMM Do')} - ${sorted[sorted.length - 1].time.format(
      'ddd, MMM Do',
    )}`;
  };

  _tipAverages = (orders = this.state.selectedRows) => {
    const numRows = orders.length;
    let tipAvg = 0;
    let tipTotal = 0;
    let total = 0;
    let numWithTips = 0;

    for (let i = 0; i < numRows; i++) {
      const order = orders[i];
      if (order.payout_tip_cents && order.subtotal_cents) {
        tipAvg += order.payout_tip_cents / order.subtotal_cents;
        numWithTips++;
      }
      tipTotal += order.payout_tip_cents;
      total += order.subtotal_cents;
    }
    const averageTip = numWithTips ? _.round((tipAvg / numWithTips) * 100, 1) : 0;
    const tipPercentage = total ? _.round((tipTotal / total) * 100, 1) : 0;

    return {
      averageTip,
      tipPercentage,
    };
  };

  _selectedTotal = () => _.sumBy(this.state.selectedRows, 'payout_tip_cents') / 100;

  _getNumVisits = (start, end) => {
    const menu_visits = this.menu_visits.filter(v => {
      const t = moment(v.t);
      return start <= t && t < end && this.state.locations.includes(v.l);
    });
    return menu_visits.length;
  };

  _onRangePress = orders => {
    const selectedOrders = _.xor(this.state.selectedRows, orders);
    const { averageTip, tipPercentage } = this._tipAverages(selectedOrders);

    this.setState({
      selectedRows: selectedOrders,
      averageTip,
      tipPercentage,
    });
  };

  _refresh = () => {
    this.getTipReport(this.state.time_range);
  };

  _fromChanged = (event, val) => {
    if (!val) {
      return this.setState({ selected_time_picker: '' });
    }
    const from = moment(val);
    let to = from > this.state.to ? from : this.state.to;

    if (to.diff(from, 'days') > 7) {
      to = from.clone().add(7, 'days'); // clone() required due to moment.add() mutating original object
      this._showLimitDatesMessage();
    }

    const range = this._getRange(from, to);

    this.setState(
      {
        from,
        to,
        time_range: range,
        selected_time_picker: '',
      },
      () => {
        this.getTipReport(range);
      },
    );
  };

  _toChanged = (event, val) => {
    if (!val) {
      return this.setState({ selected_time_picker: '' });
    }
    const to = moment(val);
    let from = to < this.state.from ? to : this.state.from;

    if (to.diff(from, 'days') > 7) {
      from = to.clone().subtract(7, 'days');
      this._showLimitDatesMessage();
    }

    const range = this._getRange(from, to);

    this.setState(
      {
        from,
        to,
        time_range: range,
        selected_time_picker: '',
      },
      () => {
        this.getTipReport(range);
      },
    );
  };

  _changeFrom = () => {
    this.setState({
      selected_time_picker: 'from',
    });
  };

  _changeTo = () => {
    this.setState({
      selected_time_picker: 'to',
    });
  };

  _changeGrouping = () => {
    const options = [
      { text: 'Quarter Hour', value: 15 },
      { text: 'Half Hour', value: 30 },
      { text: 'Hour', value: 60 },
      { text: 'By Server', value: null },
    ];
    ActionSheet.show(
      {
        options,
        title: 'Grouping',
      },
      id => {
        const pressed = options[id];
        this.setState(
          {
            grouping: pressed.value,
            grouping_text: pressed.text,
            loading: true,
          },
          () => {
            this._processResults();
            this.setState({
              loading: false,
            });
          },
        );
      },
    );
  };

  _getRange = (from, to) => {
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    let time_range = 'custom';
    if (from.isSame(today, 'day') && to.isSame(today, 'day')) {
      time_range = 'today';
    } else if (from.isSame(yesterday, 'day') && to.isSame(yesterday, 'day')) {
      time_range = 'yesterday';
    }
    return time_range;
  };

  _fixedHeader = () => {
    let icon = 'check-box-outline-blank';
    if (this.state.selectedRows.length) {
      icon =
        this.state.selectedRows.length === this.state.reportOrders.length
          ? 'check-box'
          : 'indeterminate-check-box';
    }
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (this.state.selectedRows.length) this._onRangePress(this.state.selectedRows);
          else this._onRangePress([...this.state.reportOrders]);
        }}
      >
        <View style={styles.fixedHeader}>
          <Icon
            name={icon}
            as={MaterialIcons}
            style={{ marginRight: 10 }}
          />
          <View style={{ flex: 3 }} />
          <Text style={{ flex: 1, textAlign: 'center' }}>Visits</Text>
          <Text style={{ flex: 1, textAlign: 'center' }}>Orders</Text>
          <Text style={{ flex: 1, textAlign: 'right', paddingRight: 4 }}>Tips</Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  /**
   * Header for each Day
   * @param section
   * @returns {*}
   */
  _sectionHeader = ({ section }) => {
    // Day Header

    // Fancy way of reducing the section into an array of orders:
    const dateOrders = section.data.reduce(
      (acc, { data }) => acc.concat(data.reduce((acc2, { data }) => acc2.concat(data), [])),
      [],
    );
    const selectedDateOrders = _.intersection(this.state.selectedRows, dateOrders);

    let icon = dateOrders.length ? 'check-box-outline-blank' : 'block';
    if (selectedDateOrders.length) {
      icon =
        selectedDateOrders.length === dateOrders.length ? 'check-box' : 'indeterminate-check-box';
    }

    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (!dateOrders.length) return;
          if (selectedDateOrders.length) this._onRangePress(selectedDateOrders);
          else this._onRangePress(dateOrders);
        }}
      >
        <View style={styles.dateHeader}>
          <Icon
            name={icon}
            as={MaterialIcons}
            style={{ marginRight: 10 }}
          />
          <Text style={styles.dateHeaderText}>{section.title}</Text>
          <Text style={{ flex: 1, textAlign: 'center', color: Colors.darkGray }}>
            {section.visits}
          </Text>
          <Text style={{ flex: 1, textAlign: 'center', color: Colors.darkGray }}>
            {dateOrders.length}
          </Text>
          <Text style={{ flex: 1, textAlign: 'right', color: Colors.darkGray }}>
            <FormattedCurrency value={_.sumBy(dateOrders, 'payout_tip_cents') / 100} />
          </Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  _toggleMobileDrawer = () => {
    this.setState({
      isMobileDrawerOpen: !this.state.isMobileDrawerOpen,
    });
  };
}

const formatTime = time => {
  const format = time.minutes() !== 0 ? 'h:mmA' : 'hA';
  return time.format(format);
};

function ShiftSection({ data, groupingMethod, bartenders, onRangePress, selectedRows }) {
  const { shift } = data;

  const shiftRows = data.data.reduce((acc, range) => acc.concat(range.data), []);
  const shiftRowsSelected = _.intersection(selectedRows, shiftRows);

  const icon = shiftRowsSelected.length
    ? shiftRowsSelected.length === shiftRows.length
      ? 'check-box'
      : 'indeterminate-check-box'
    : 'check-box-outline-blank';

  const groupByServer = groupingMethod === 'By Server';

  const renderItem = useCallback(
    ({ item }) => {
      // Render a shift Range:
      const selected = _.intersection(selectedRows, item.data).length === item.data.length;
      const icon = selected ? 'check-box' : 'check-box-outline-blank';
      const bartender = bartenders ? bartenders[item.data[0]?.bartender_id] : null;
      const groupTitle = groupByServer
        ? bartender
          ? `${bartender.first_name} ${bartender.last_name}`
          : 'Unassigned'
        : item.title;

      return (
        <TouchableWithoutFeedback
          onPress={() => {
            onRangePress(item.data);
          }}
        >
          <View style={styles.rangeRow}>
            <Icon
              as={MaterialIcons}
              name={icon}
              style={{ marginRight: 10 }}
            />
            <Text style={{ flex: 3 }}>{groupTitle}</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{item.visits}</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{item.data.length}</Text>
            <View style={{ flex: 1, alignItems: 'flex-end' }}>
              <FormattedCurrency value={_.sumBy(item.data, 'payout_tip_cents') / 100} />
            </View>
          </View>
        </TouchableWithoutFeedback>
      );
    },
    [selectedRows, bartenders, groupByServer, onRangePress],
  );

  return (
    <FlatList
      data={data.data}
      keyExtractor={(item, index) => `shift_${index}`}
      ListHeaderComponent={
        groupByServer || !shift ? null : (
          <TouchableWithoutFeedback
            onPress={() => {
              if (shiftRowsSelected.length) onRangePress(shiftRowsSelected);
              else onRangePress(shiftRows);
            }}
          >
            <View style={styles.shiftHeader}>
              <Icon
                as={MaterialIcons}
                name={icon}
                style={{ marginRight: 10 }}
              />
              <View style={{ flex: 3, flexDirection: 'row', alignItems: 'center' }}>
                <Text style={styles.shiftHeaderText}>{data.title}</Text>
                <Text style={styles.shiftHeaderTimeRange}>
                  {formatTime(shift.start)} -{formatTime(shift.end)}
                </Text>
              </View>
              <Text style={{ flex: 1, textAlign: 'center', color: Colors.darkGray }}>
                {shift.visits}
              </Text>
              <Text style={{ flex: 1, textAlign: 'center', color: Colors.darkGray }}>
                {shiftRows.length}
              </Text>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <FormattedCurrency
                  style={{ color: Colors.darkGray }}
                  value={_.sumBy(shiftRows, 'tip_cents') / 100}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        )
      }
      renderItem={renderItem}
    />
  );
}

const styles = EStyleSheet.create({
  fixedHeader: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  dateHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    backgroundColor: Colors.lightGray,
  },
  dateHeaderText: {
    flex: 3,
    fontSize: 14,
  },
  dayFooter: {
    padding: 8,
    backgroundColor: Colors.primaryLight,
  },
  shiftHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderBottomWidth: 1,
  },
  shiftHeaderText: {
    fontWeight: 'bold',
    marginRight: 15,
    fontSize: 15,
    color: Colors.darkGray,
  },
  shiftHeaderTimeRange: {
    color: Colors.darkGray,
    fontSize: 13,
  },
  rangeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 8,
    alignItems: 'center',
    borderBottomWidth: 1,
  },
  summary: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    backgroundColor: Colors.gray,
    alignItems: 'center',
  },
  summaryRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  summaryLabel: {},
  summaryValue: {
    fontSize: 18,
    fontWeight: 'bold',
  },
});
