import React, { useContext, useState } from 'react';
import { View, Pressable, Text } from 'react-native';
import { Input, Button, Row, Icon } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { CHECKOUT_REDUCER_ACTIONS, CHECKOUT_SCREEN } from './Checkout';
import { POSCheckoutScreenContext } from '../../../contexts/POSContext';
import { ButtonStyle, ReceiptPageStyles as styles } from '../../../styles/POSStyles';
import API from '../../../api';
import { validateEmail, validatePhoneNumber } from '../../../helpers/HelperFunctions';

function SendReceipt({ receiptType }) {
  const {
    checkoutState: { orderIds },
    checkoutDispatch,
  } = useContext(POSCheckoutScreenContext);

  const [contact, setContact] = useState(null);
  const [error, setError] = useState(null);

  const { PageContainer, BackRow, BackText, ReceiptText, InputContainer, ContactInput, ErrorText } =
    styles;

  const returnToPaymentComplete = () =>
    checkoutDispatch({
      type: CHECKOUT_REDUCER_ACTIONS.SET_CHECKOUT_SCREEN,
      newCheckoutScreen: CHECKOUT_SCREEN.PAYMENT_COMPLETED,
    });

  const sendReceipt = () => {
    if (receiptType === 'email') {
      // send email if valid
      if (validateEmail(contact)) {
        orderIds.forEach(orderId => API.sendReceipt(null, contact, '', orderId));
        checkoutDispatch({
          type: CHECKOUT_REDUCER_ACTIONS.SET_RECEIPT_EMAIL,
          email: contact,
        });
        returnToPaymentComplete();
      } else {
        setError('Please enter a valid email.');
      }
    } else if (receiptType === 'text') {
      // send text
      if (validatePhoneNumber(contact)) {
        orderIds.forEach(orderId => API.sendReceipt(null, '', contact, orderId));
        checkoutDispatch({
          type: CHECKOUT_REDUCER_ACTIONS.SET_RECEIPT_PHONE,
          phone: contact,
        });
        returnToPaymentComplete();
      } else {
        setError('Please enter a valid phone number.');
      }
    } else {
      console.error('type prop not accepted');
    }
  };

  const label = receiptType === 'email' ? 'Email' : 'Text';

  return (
    <View style={PageContainer}>
      <View style={BackRow}>
        <Pressable onPress={returnToPaymentComplete}>
          <Row
            height="100%"
            alignItems="center"
          >
            <Icon
              size={6}
              marginRight={1}
              as={Ionicons}
              name="arrow-back-outline"
              color="white"
            />
            <Text style={BackText}>Back</Text>
          </Row>
        </Pressable>
      </View>

      <Text style={ReceiptText}>{`Send Receipt by ${label}`}</Text>

      <View style={InputContainer}>
        <Input
          autoFocus
          style={ContactInput}
          width="100%"
          borderRadius={8}
          borderWidth={0}
          placeholder={label}
          onChangeText={setContact}
        />

        <Text style={ErrorText}>{error}</Text>

        <Button
          style={ButtonStyle('primary', 'sm')}
          width="100%"
          marginBottom={30}
          onPress={sendReceipt}
        >
          Send
        </Button>
        <Button
          style={ButtonStyle('secondary', 'sm')}
          width="100%"
          onPress={returnToPaymentComplete}
        >
          Cancel
        </Button>
      </View>
    </View>
  );
}

export default SendReceipt;
