import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Container, Button, Text } from 'native-base';
import API from '../../api';
import Colors from '../../constants/Colors';
import PinPad from '../../components/PinPad/PinPad';
import { Content } from '../../bbot-component-library';
import { clearAccess } from '../../helpers/Access';

export default class PinScreen extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currPin: [],
      email: '',
      processing: false,
    };
  }

  componentDidMount() {
    clearAccess();
    API.getAccount().then(email => {
      this.setState({
        email,
        currPin: [],
      });
    });
  }

  render() {
    return (
      <Container testID="pinScreenContainer">
        <Content
          contentContainerStyle={{ flexGrow: 1 }}
          style={{ width: '100%' }}
        >
          <View
            testID="pin-screen"
            style={styles.container}
          >
            <PinPad
              changeUser
              onSuccess={this._onPinSuccess}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              margin: 10,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text style={{ color: Colors.gray }}>
              Account:
              {this.state.email}
            </Text>
            <Button
              variant="ghost"
              disabled={this.state.processing}
              onPress={() => {
                API.logout().then(() => {
                  this.props.navigation.navigate('Login');
                });
              }}
            >
              <Text>LOGOUT</Text>
            </Button>
          </View>
        </Content>
      </Container>
    );
  }

  _onPinSuccess = async user => {
    const { navigation } = this.props;

    API.unlockScreen();
    const forwardTo =
      navigation.getParam('forwardTo') || API.config ? API.config.default_screen : 'AuthLoading';
    navigation.navigate(forwardTo);
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
