import React, { useEffect, useState } from 'react';
import { Button, Icon, FormControl, Text, View } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions, ScrollView } from 'react-native';
import { Feather, MaterialIcons, Octicons } from '@expo/vector-icons';
import { HeaderBackButton } from 'react-navigation-stack';
import TipEditor from '../components/TipEditor';
import API from '../api';
import Alert from '../components/Alert';
import { Loader } from '../components';
import { Colors } from '../styles';
import TabOrder from '../components/ConsumerTab/TabOrder';
import TabSummaryView from '../components/ConsumerTab/TabSummaryView';
import Segment, { SegmentButton } from '../components/Segment';

function CloseTabScreen({ navigation }) {
  const tab_id = navigation.getParam('tab_id');
  const tabModel = API._tabs[tab_id];
  const [newTipTotal, setNewTipTotal] = useState(tabModel.tip_amount);
  const [processing, setProcessing] = useState(true);
  const [tab, setTab] = useState(tabModel);
  const [mode, setMode] = useState('hidden');

  useEffect(() => {
    tabModel.refresh().then(() => {
      setProcessing(false);
      setTab(tabModel);
    });
  }, []);

  const closeTab = async () => {
    setProcessing(true);

    if (newTipTotal !== tab.tip_amount) {
      const editTipResponse = await API.editTabTip(tab, newTipTotal);
      if (editTipResponse.error) {
        Alert.alert('Error', editTipResponse.error);
        setProcessing(false);
        return;
      }
    }

    const response = await API.closeTabs([tab.id], tab.locations[0]?.id);

    setProcessing(false);

    if (response) {
      const from = navigation.getParam('from');
      if (from) navigation.navigate(from);
      return navigation.goBack();
    }
    Alert.alert('Error', 'Something went wrong when closing the tab. Please try again');
  };

  const onCancel = () => {
    const from = navigation.getParam('from');
    if (from) navigation.navigate(from);
    else navigation.goBack();
  };

  const showUpdatedTotals = tipAmount => {
    setNewTipTotal(tipAmount.amount);
  };

  return (
    <View style={{ alignItems: 'center', height: Dimensions.get('window').height - 80 }}>
      {processing && <Loader />}
      <ScrollView
        style={{ width: '100%' }}
        contentContainerStyle={{ alignItems: 'center', width: '100%', flexGrow: 1 }}
      >
        <View style={styles.container}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ marginBottom: 10, flex: 1, marginRight: 10 }}>
              <Text
                style={{ fontSize: 20, fontWeight: 'bold' }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {`${tab.tab_name}'${tab.tab_name.slice(-1).toLowerCase() === 's' ? '' : 's'} Tab`}
              </Text>
              <Text>
                {`${tab.orders.length} order${tab.orders.length === 1 ? '' : 's'} on the Tab`}
              </Text>
            </View>
            <Segment style={{ backgroundColor: 'transparent' }}>
              <SegmentButton
                darkMode
                selected={mode === 'hidden'}
                onPress={() => setMode('hidden')}
                note="Hidden"
                icon={
                  <Icon
                    as={Octicons}
                    name="eye-closed"
                    style={{ color: 'black' }}
                  />
                }
              />
              <SegmentButton
                darkMode
                selected={mode === 'order'}
                onPress={() => setMode('order')}
                note="By Order"
                icon={
                  <Icon
                    as={Feather}
                    name="list"
                    style={{ color: 'black' }}
                  />
                }
              />
              <SegmentButton
                darkMode
                selected={mode === 'list'}
                onPress={() => setMode('list')}
                note="By Item"
                icon={
                  <Icon
                    as={MaterialIcons}
                    name="receipt"
                    style={{ color: 'black' }}
                  />
                }
              />
            </Segment>
          </View>
          <View style={{ marginVertical: 10 }}>
            {mode === 'order' &&
              tab.orders.map(order => (
                <TabOrder
                  key={order.orderId}
                  order={order}
                />
              ))}
            {mode === 'list' && <TabSummaryView tab={tab} />}
          </View>
          <View style={styles.tipCard}>
            <Text style={styles.tipTitle}>Tip</Text>
            <TipEditor
              style={{ marginBottom: 25 }}
              total={tab.pre_tip_total}
              onTipChange={showUpdatedTotals}
              tipAmount={newTipTotal}
            />
          </View>

          <View style={{ alignItems: 'flex-end' }}>
            <View style={{ width: 250 }}>
              {!API.main_customer.tax_inclusive_pricing && (
                <View style={[styles.summaryRow]}>
                  <FormControl.Label style={styles.summaryTitle}>Sub-Total</FormControl.Label>
                  <FormattedCurrency value={tab.pretax_total / 100} />
                </View>
              )}
              {tab.promos_pretax_cents_added !== 0 && (
                <View style={[styles.summaryRow]}>
                  <FormControl.Label style={styles.summaryTitle}>Discounts</FormControl.Label>
                  <FormattedCurrency value={tab.promos_pretax_cents_added / 100} />
                </View>
              )}
              {tab.fee_pretax_cents > 0 && (
                <View style={[styles.summaryRow]}>
                  <FormControl.Label style={styles.summaryTitle}>Fees</FormControl.Label>
                  <FormattedCurrency value={tab.fee_pretax_cents / 100} />
                </View>
              )}
              {!API.main_customer.tax_inclusive_pricing && (
                <View style={[styles.summaryRow]}>
                  <FormControl.Label style={styles.summaryTitle}>Tax</FormControl.Label>
                  <FormattedCurrency value={tab.tax_amount / 100} />
                </View>
              )}
              <View style={[styles.summaryRow]}>
                <FormControl.Label style={[styles.summaryTitle, { fontWeight: 'bold' }]}>
                  Total
                </FormControl.Label>
                <FormattedCurrency
                  value={(tab.total - tab.tip_amount) / 100}
                  style={{ fontWeight: 'bold' }}
                />
              </View>
              <View style={[styles.summaryRow]}>
                <FormControl.Label style={styles.summaryTitle}>Tip</FormControl.Label>
                <FormattedCurrency value={newTipTotal / 100} />
              </View>
              <View
                style={[
                  styles.summaryRow,
                  {
                    borderTopWidth: 1,
                    borderTopColor: 'black',
                    marginTop: 5,
                    paddingTop: 5,
                  },
                ]}
              >
                <FormControl.Label style={[styles.summaryTitle, { fontWeight: 'bold' }]}>
                  Total with Tip
                </FormControl.Label>
                <FormattedCurrency
                  value={(tab.total - tab.tip_amount + newTipTotal) / 100}
                  style={{ fontWeight: 'bold' }}
                />
              </View>
              {API.main_customer.tax_inclusive_pricing && (
                <View style={[styles.summaryRow]}>
                  <FormControl.Label style={styles.summaryTitle}>
                    Tax included in Total
                  </FormControl.Label>
                  <FormattedCurrency value={tab.tax_amount / 100} />
                </View>
              )}
            </View>
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}>
            <Button
              variant="ghost"
              onPress={onCancel}
              style={{ marginRight: 10 }}
            >
              <Text style={{ color: Colors.primary }}>Cancel</Text>
            </Button>
            <Button
              icon
              colorScheme="primary"
              onPress={closeTab}
            >
              <Text style={{ color: 'white' }}>Close Tab</Text>
            </Button>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

CloseTabScreen.navigationOptions = ({ navigation, navigationOptions }) => {
  const from = navigation.getParam('from');
  const headerLeft = from ? (
    <HeaderBackButton
      onPress={() => navigation.navigate(from)}
      tintColor={navigationOptions.headerTintColor}
      pressColorAndroid={navigationOptions.headerPressColorAndroid}
      backImage={navigationOptions.headerBackImage}
      titleStyle={navigationOptions.headerBackTitleStyle}
    />
  ) : undefined;

  return {
    title: 'Close Tab',
    headerLeft,
  };
};

export default CloseTabScreen;

const styles = EStyleSheet.create({
  container: {
    maxWidth: 480,
    minWidth: 335,
    width: '100%',
    padding: 10,
    flex: 1,
  },
  summaryRow: {
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },
  summaryTitle: {
    flex: 1,
    fontSize: 14,
  },
  tipCard: {
    borderTopWidth: 8,
    borderBottomWidth: 8,
    borderColor: 'lightgray',
    paddingTop: 8,
  },
  tipTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 3,
  },
});
