import React, { Component } from 'react';
import { View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import moment from 'moment';
import Colors from '../constants/Colors';

/**
 * TODO: This should show a history of all Seated Groups and orders for the Location since the beginning of the day / shift of the person who's pinned in.
 *
 */

class LocationDetails extends Component {
  static navigationOptions = props => {
    const { navigation } = props;
    const location = navigation.getParam('location');

    return {
      title: `${location.shortId} Smart Orders`,
    };
  };

  constructor(props) {
    super(props);
    const { navigation } = this.props;

    const location = navigation.getParam('location');

    this.state = {
      location,
    };
  }

  componentDidMount() {
    this._mounted = true;

    // refresh
    this._interval = setInterval(() => {
      if (this._mounted) {
        this.setState({
          location: this.state.location,
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this._interval);
  }

  render() {
    const smartOrders = this.state.location.smart_orders;

    return (
      <View style={{ flex: 1 }}>
        <View>
          {smartOrders.map((so, id) => {
            const incart = so.incart || [];
            const activity_time = moment(so.activity_time);
            return (
              <View
                style={styles.entry}
                key={so.activity_time}
              >
                <View style={styles.header}>
                  <Text style={styles.headerText}>
                    Device
                    {id + 1}
                  </Text>
                  <Text style={styles.timeText}>{activity_time.fromNow()}</Text>
                </View>
                <View style={styles.cart}>
                  {incart.map((item, index) => (
                    <View
                      key={index}
                      style={{ flexDirection: 'row' }}
                    >
                      <Text style={styles.cartItem}>
                        {item.qty}x {item.name}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            );
          })}
        </View>
      </View>
    );
  }
}

export default LocationDetails;

const styles = EStyleSheet.create({
  entry: {},
  header: {
    flexDirection: 'row',
    height: 19,
    paddingLeft: 5,
    backgroundColor: Colors.gray,
    justifyContent: 'center',
  },
  headerText: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
  },
  timeText: {},
  cart: {},
  cartItem: {
    marginLeft: 10,
  },
});
