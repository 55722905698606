import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedCurrency } from 'react-native-globalize';

import Card from './Card';
import TipEditor from '../../components/TipEditor';
import API from '../../api';

/**
 * The <TipsCard> contains the row of buttons to select presets or custom tip amounts, and allows the patron to enter
 * their custom amount or percentage for tip if they choose.
 *
 * The selections will update the <TipScreen> component's state to be accurately displayed in the total cost.
 *
 * @param props
 * @param {CheckModel} props.check Information on the current order, including items and various prices
 * @param {ReactHook<number>} props.setTipAmount The React hook for setting the final dollar amount of the Tip
 * @returns {JSX.Element}
 * @constructor
 */
function TipsCard({ check, setTipAmount, setTipPercent, tipAmount, checkingPrices, ...rest }) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(check.tip_calculation_total);
  }, [checkingPrices]);

  return (
    <Card
      testID="tipScreenTipsCard"
      titlePath="common__tip"
      title="Tip"
      titleRight={<FormattedCurrency value={tipAmount / 100} />}
      {...rest}
    >
      <TipEditor
        total={total}
        onTipChange={setTipAmount}
        tipAmount={tipAmount}
      />
    </Card>
  );
}

export default TipsCard;
