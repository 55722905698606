import { Linking, Platform, Text, View } from 'react-native';
import React from 'react';
import API from '../../api';

function OrderCheckoutInfo({
  checkoutInfo,
  blockStyle,
  fieldStyle,
  labelStyle,
  valueStyle,
  interactive = false,
}) {
  if (!checkoutInfo.length || API.handheldDevice.getPreference('kds_hide_checkout_info'))
    return null;

  const Fields = checkoutInfo
    .map(field => {
      if (field.key === 'user_desired_time' || !field.value) return null;

      if (field.key === 'address') {
        const res = [];
        const val = [
          [field.value.line1, field.value.line2].join(' '),
          field.value.city,
          field.value.zip,
        ].join(', ');
        res.push(
          <Field
            key={field.key}
            label={field.name_for_bartender}
            value={val}
            fieldStyle={fieldStyle}
            labelStyle={labelStyle}
            valueStyle={valueStyle}
          />,
        );
        if (field.value.delivery_instructions)
          res.push(
            <Field
              key="delivery_instructions"
              label="Instructions"
              value={field.value.delivery_instructions}
              fieldStyle={fieldStyle}
              labelStyle={labelStyle}
              valueStyle={valueStyle}
            />,
          );

        return res;
      }
      const fieldType =
        Platform.OS === 'web' && interactive && field.key === 'phone_number' ? 'tel:' : null;

      return (
        <Field
          key={field.key}
          label={field.name_for_bartender}
          value={field.value}
          fieldStyle={fieldStyle}
          labelStyle={labelStyle}
          valueStyle={valueStyle}
          fieldType={fieldType}
        />
      );
    })
    .filter(i => i);

  if (!Fields.length) return null;

  return <View style={blockStyle}>{Fields}</View>;
}

export default OrderCheckoutInfo;

function Field({ label, value, fieldStyle, labelStyle, valueStyle, fieldType }) {
  const text = Array.isArray(value) ? value.join(', ') : value; // Todo: we shouldn't need this, since values are joined server-side
  const textStyle = {};
  let onPress = null;
  if (fieldType) {
    textStyle.color = '#00658c';
    onPress = () => {
      if (fieldType === 'tel:') {
        Linking.openURL(`tel:${value}`);
      }
    };
  }
  return (
    <View
      style={[{ flexDirection: 'row' }, fieldStyle]}
      key={label}
    >
      <Text
        numberOfLines={1}
        style={[labelStyle]}
      >
        {label}:{' '}
      </Text>
      <Text
        style={[valueStyle, , textStyle]}
        onPress={onPress}
      >
        {text}
      </Text>
    </View>
  );
}
