import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { showMessage } from 'react-native-flash-message';
import { Text } from 'native-base';
import { FormattedMessage } from 'react-native-globalize';
import API, { Orientation } from '../../api';
import Loader from '../../components/Loader';
import ReaderStatus from '../../components/ReaderStatus';
import CartItemsCard from './CartItemsCard';
import TipsCard from './TipsCard';
import ExtraCheckoutInfoCard from './ExtraCheckoutInfoCard';
import { InputType } from './Form';
import PaymentInfoCard from './PaymentInfoCard';
import ReceiptInfoCard from './ReceiptInfoCard';
import CheckoutButtonCard from './CheckoutButtonCard';
import FulfillmentMethodCard from './FulfillmentMethodCard';
import Alert from '../../components/Alert';
import { capturePaymentMethod } from '../../components/Stripe/StripeHelper';
import PromptsToGuestCard from './PromptsToGuestCard';
import { prompt } from '../../components/Prompt/GlobalPrompt';
import Colors from '../../constants/Colors';
import NavigationService from '../../navigation/NavigationService';
import DesiredTimePicker from './DesiredTimePicker';

/**
 * The Terminal Checkout Screen
 *
 *
 * @param props
 * @constructor
 */
function CheckoutScreen({ navigation }) {
  /** @type {Location} */
  const location = navigation.getParam('location');
  /** @type {CheckModel} */
  const check = navigation.getParam('check');
  /** @type {CartModel} */
  const { cart } = check;

  const menuData = API.getMenu();

  const [fulfillmentMethod, setFulfillmentMethod] = useState(cart.fulfillment_method);

  /** @type {SavedCard | PartyTab} The currently selected payment method, either an entered card or a party tab */
  const [card, setCard] = useState(navigation.getParam('card'));

  /** @type {boolean} Only show the Extra Checkout Information for the first check in the cart */
  const showExtraCheckoutInfoCard = check.cart.checks[0].label === check.label;

  /** @type {Dictionary<ExtraFieldData>} A list of data for each extra checkout field (only ones for handheld) */
  const extraCheckoutInfo = _.pickBy(
    location.required_checkout_info,
    field => field.show_on_handheld,
  );

  /** @type {ExtraFieldData[]} Extra Checkout Info sorted in order of display */
  const fields = _.sortBy(
    _.values(extraCheckoutInfo).filter(
      field => !field.method || field.method === fulfillmentMethod,
    ),
    'display_order',
  );

  /** @type {Dictionary<boolean>} Maps keys of fields to true if they are optional, false if required (default state) */
  const defaultValidFields = {};
  fields.forEach(f => {
    // TODO: Need proper field validation on init for pre-populated fields
    defaultValidFields[f.key] = !f.required_on_handheld;
  });

  /** @type {[Dictionary<boolean>, ReactHook<Dictionary<boolean>>]} Maps the key of a field to whether it is valid */
  const [validFields, setValidFields] = useState(defaultValidFields);

  /** @type {Object<string, *>} Maps the keys of checkbox fields to their default choice */
  const defaultFieldValues = {};

  // If not first check, defaultFieldValues is empty to not override the first check.
  useEffect(() => {
    // If its first check AND there already exist values (i.e. editing old answers), repopulate defaultFieldValues
    if (check.cart.extra_checkout_info.length) {
      check.cart.extra_checkout_info.forEach(
        fieldData => (defaultFieldValues[fieldData.key] = fieldData.value),
      );
    } else {
      // Otherwise, populate defaultFieldValues with the default_choice if given in fieldData
      fields.forEach(field => {
        if (field.type === InputType.CHECKBOX || field.type === InputType.RADIO) {
          if (field.choices && field.choices.length > 1) {
            defaultFieldValues[field.key] = field.value;
          } else {
            // for backwards compatibility:
            defaultFieldValues[field.key] = field.value || (field.default_choice ? ['yes'] : []);
            if (field.key === 'marketing_opt_in' && !field.choices) {
              field.choices = [{ label: 'yes' }];
            }
          }
        } else if (field.type === InputType.ADDRESS) {
          defaultFieldValues[field.key] = field.value || {};
        } else {
          defaultFieldValues[field.key] = field.value;
        }
      });
      setFieldValues(defaultFieldValues);
    }
  }, [showExtraCheckoutInfoCard]);

  let { default_tip } = menuData.customer.app_properties.tipping;
  if (card && card.type === 'tab' && card.default_tip) {
    default_tip = card.default_tip;
  }

  let defaultTipAmount = 0;
  if (location.forced_tip_fraction) {
    defaultTipAmount = Math.round(check.tip_calculation_total * location.forced_tip_fraction);
  } else {
    defaultTipAmount = Math.round(check.tip_calculation_total * default_tip || 0);
  }

  /** @type {[boolean, ReactHook<boolean>]} If true, blurs the prices in the Items Card (while checking promo codes, etc) */
  const [checkingPrices, setCheckingPrices] = useState(true);
  /** @type {[boolean, ReactHook<boolean>]} Used when verifying a Card that was just submitted */
  const [loading, setLoading] = useState(false);
  /** @type {[Orientation, ReactHook<Orientation>]} For adjusting the padding on landscape handhelds */
  const [orientation, setOrientation] = useState(API.orientation);
  /** @type {[number, ReactHook<number>]} (In cents) The amount of money for tips. Computations in <TipsCard> */
  const [tipAmount, setTipAmount] = useState(defaultTipAmount);
  const [tipPercent, setTipPercent] = useState(default_tip);

  /** @type {[boolean, ReactHook<boolean>]} True if all required checkout fields have been filled out (correctly) */
  const [highlightErrors, setHighlightErrors] = useState(false);
  /** @type {[Map<string, *>, ReactHook<Map<string, *>>]} Maps Field keys to their values (only saves valid values) */
  const [fieldValues, setFieldValues] = useState(defaultFieldValues);
  /** @type {[string, ReactHook<string>]} The email address to send the check to. */
  const [email, setEmail] = useState(check.sendReceipt.email);
  /** @type {[string, ReactHook<string>]} The phone number to send status updates of this check to. */
  const [phone, setPhone] = useState(check.sendReceipt.phone);
  const [receiptType, setReceiptType] = useState('email');

  const [yCoords, setYCoords] = useState({});

  const scrollView = useRef(null);

  // If true, the Approve / Continue button at the bottom of the screen cannot be clicked
  const continueButtonDisabled = checkingPrices || !check.isValid();
  const continueButtonLabel =
    !card && check.total + tipAmount > 0
      ? { path: 'common__payNow', default: 'Pay Now' }
      : { path: 'common__approve', default: 'Approve' };

  const setExtraCheckoutInfo = newValues => {
    check.cart.extra_checkout_info = _.reduce(
      newValues,
      (res, value, key) =>
        res.concat({
          key,
          value,
          name_for_patron: extraCheckoutInfo[key].name_for_patron,
          name_for_bartender: extraCheckoutInfo[key].name_for_bartender,
          show_on_ticket: extraCheckoutInfo[key].show_on_ticket,
          show_on_kds: extraCheckoutInfo[key].show_on_kds,
        }),
      [],
    );
    // Update the sendReceipt values:
    if (newValues?.email) setEmail(newValues.email);
    if (newValues?.phone_number) setPhone(newValues.phone_number);

    setFieldValues(newValues);
  };

  useEffect(() => {
    // Initial getCartPrice
    check.cart.getCartPrice().then(() => {
      setCheckingPrices(false);
    });
    // equivalent to componentDidMount
    API.on('orientation', setOrientation);
    const updateListener = check.cart.on('updating', isUpdating => setCheckingPrices(isUpdating));
    // equivalent to componentWillUnmount
    return function cleanup() {
      API.off('orientation', setOrientation);
      updateListener.remove();
    };
  }, []);

  /**
   * Navigates to the thank you screen for the patron to return the device to the Terminal User. Sends the request to
   * the Bbot server ot charge the payment method the information found in the `approve` method.
   */
  const goToThankYou = cardCaptured => {
    navigation.navigate('CaptureSuccessScreen', {
      cart,
      card: cardCaptured,
      joinURL: cardCaptured?._joinURL,
    });
    if (card?._joinURL) card._joinURL = null;
  };

  const validateCheckoutInfo = () => {
    // just need to look at validFields
    if (!showExtraCheckoutInfoCard) return [];

    const errors = [];
    for (const key in validFields) {
      if (
        !validFields[key] &&
        (!extraCheckoutInfo[key].method || extraCheckoutInfo[key].method === fulfillmentMethod)
      )
        errors.push(key);
    }
    return errors;
  };

  const fundTab = async (tab, amount) => {
    await tab.refresh(); // Get the latest tab details
    if (tab.available_cents < amount) {
      try {
        // Fund Tab:
        const response = await API.expandConsumerTab(tab, amount, location.id);
        if (response.errorCode) {
          showMessage({
            type: 'danger',
            position: 'center',
            floating: true,
            message: response.errorCode,
            autoHide: false,
          });
          return false;
        }
        return true;
      } catch (err) {
        return false;
      }
    } else {
      return true;
    }
  };

  /**
   * Gets the information required to charge the payment method, or notes that this is free. Navigates to the Thank You
   * Screen after this check.
   */
  const approve = async (startTab = false, closeTab = false) => {
    let cardToCharge = card;

    const errors = validateCheckoutInfo();

    if (errors.length) {
      setHighlightErrors(true);
      scrollView.current?.scrollTo({
        y: yCoords[errors[0]] - 56, // header height (Todo, get this as const - Header.HEIGHT causing errors though)
      });
      return;
    }

    // Updates the Send Receipt Statuses before submitting.
    check.sendReceipt = {
      email: receiptType === 'email' ? email : null,
      phone: receiptType === 'sms' ? phone : null,
    };

    // Check for a valid payment method if the total is non-zero
    const total = check.total + tipAmount;

    if (total === 0) {
      check.charge = {
        type: 'free',
        amount_cents: 0,
      };
    } else {
      if (!cardToCharge) {
        try {
          cardToCharge = await capturePaymentMethod({
            location,
            forceTab: startTab,
            reusable: startTab,
            tabName: fieldValues.name || fieldValues.first_name,
            defaultTip: tipPercent,
            saveButtonText: 'SUBMIT',
            paymentIntentParams: {
              amount: total,
            },
          });
        } catch (err) {
          console.log('failed to capture: ', err.toString());
          return;
        }
      }

      check.charge = cardToCharge.getCharge(total);
      check.tip_cents = tipAmount;
    }

    // this logic could definitely be cleaned up
    if (cart.checks.length === 1) {
      const response = await handleCheckout();
      if (!response.success) {
        cart.trigger('update'); // we update here because we want the split order screen to update if you came from there
        setLoading(false);
        return;
      }
    }
    cart.trigger('update');

    if (closeTab) {
      // navigate to the Close Tab screen:
      NavigationService.navigate('LocationCloseTabScreen', {
        tab_id: card.id,
        from: 'LocationOverview',
      });
    } else {
      goToThankYou(cardToCharge);
    }
  };

  const handleCheckout = async () => {
    setLoading(true); // not sure if needed, we should already be loading...

    const response = await cart.submit();
    if (!response.success) {
      if (response.errors.length) {
        cart._lastError = response.errors;
        let removedItems = null;
        if (response.removedItems?.length) {
          removedItems = (
            <View style={{ flexWrap: 'wrap', flex: 1 }}>
              <Text style={{ fontWeight: 'bold' }}>Unavailable Item(s):</Text>
              {response.removedItems.map(item => (
                <Text key={`item_${item}`}>{item}</Text>
              ))}
            </View>
          );
        }
        prompt({
          title: 'Cart Error',
          submitButtonStyle: { backgroundColor: Colors.warning },
          cancelText: ' ',
          inputCmp: (
            <View style={{ flex: 1, marginTop: 5 }}>
              {response.errors.map((err, i) => (
                <View
                  style={{ flexDirection: 'row', flex: 1 }}
                  key={`err_${i}`}
                >
                  <View style={{ flexWrap: 'wrap', flex: 1 }}>
                    <Text style={{}}>{err}</Text>
                  </View>
                </View>
              ))}
              {removedItems}
            </View>
          ),
          onSubmit: () => {},
        });
        // Alert.alert("Error", response.errors.join("\r\n"));
      } else {
        // Show Failure notice
        Alert.alert(
          'Checkout Failed',
          'An unknown error occurred. Please return the device to your server.',
          [
            {
              text: 'OK',
              onPress: () => {
                // navigate to OrderCreator?
                navigation.goBack();
              },
            },
          ],
        );
      }
    }
    return response;
  };

  return (
    <View
      testID="tipScreen"
      style={styles.container}
    >
      {loading && <Loader />}
      <View style={styles.innerContainer}>
        {/* keyboardShouldPersistTaps fixes issue with AddressField popup not being tappable */}
        <ScrollView
          ref={scrollView}
          contentContainerStyle={styles.scrollview}
          keyboardShouldPersistTaps="always"
        >
          <View style={{ width: '100%', maxWidth: 600 }}>
            {/** Cart Items, totals, and Promo Input * */}
            <CartItemsCard
              check={check}
              location={location}
              checkingPrices={checkingPrices}
              tipAmount={tipAmount}
              orientation={orientation}
              isTab={card?.type === 'tab'}
              defaultTip={default_tip}
            />
            {/** Tips Section * */}
            {location.showTipSection() && !(card?.type === 'tab') && (
              <TipsCard
                check={check}
                setTipAmount={tip => {
                  setTipAmount(tip.amount);
                  setTipPercent(tip.percent);
                }}
                setTipPercent={setTipPercent}
                tipAmount={tipAmount}
                orientation={orientation}
                checkingPrices={checkingPrices}
              />
            )}
            {location.possible_fulfillment_methods?.length > 1 && (
              <FulfillmentMethodCard
                cart={cart}
                fulfillmentMethod={fulfillmentMethod}
                setFulfillmentMethod={setFulfillmentMethod}
              />
            )}
            <DesiredTimePicker
              locationAllowsFutureOrdering={location.allow_order_ahead !== 'off'}
              cart={cart}
              checkingPrices={checkingPrices}
            />
            {/* Only Show the Extra Checkout Info one time per checkout. SplitScreen will track this. */}
            {showExtraCheckoutInfoCard && (
              <ExtraCheckoutInfoCard
                cart={cart}
                fields={fields}
                highlightErrors={highlightErrors}
                fieldValues={fieldValues}
                setFieldValues={setExtraCheckoutInfo}
                orientation={orientation}
                updateElementPosition={(key, val) => {
                  yCoords[key] = val;
                  setYCoords(yCoords);
                }}
                validFields={validFields}
                setValidFields={setValidFields}
              />
            )}
            {cart.prompts_to_guest?.length > 0 && (
              <PromptsToGuestCard
                prompts={cart.prompts_to_guest}
                cart={cart}
              />
            )}
            <ReceiptInfoCard
              email={email}
              phoneNumber={phone}
              setEmail={setEmail}
              setPhoneNumber={setPhone}
              receiptType={receiptType}
              setReceiptType={setReceiptType}
              orientation={orientation}
            />
            {!!card && (
              <PaymentInfoCard
                card={card}
                location={location}
                orientation={orientation}
              />
            )}
            <CheckoutButtonCard
              location={location}
              card={card}
              onApprove={approve}
              check={check}
              disabled={continueButtonDisabled}
              text={continueButtonLabel}
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

CheckoutScreen.navigationOptions = props => ({
  title: (
    <FormattedMessage
      id="common__checkout"
      defaultMessage="Checkout"
    />
  ),
  headerRight: () => (
    <View style={styles.header}>
      <ReaderStatus requirePin />
    </View>
  ),
});

export default CheckoutScreen;

const styles = EStyleSheet.create({
  header: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: { flex: 1, alignItems: 'center' },
  innerContainer: { flex: 1, width: '100%' },
  scrollview: {
    alignItems: 'center',
    flexGrow: 1,
    ...(Platform.OS === 'web' ? { flexBasis: 0 } : {}),
  },
});
