import moment from 'moment';
import Model from './Model';

export default class DriverDeliveryJob extends Model {
  id = null;

  delivery_provider = '';

  external_id = null;

  requested_pickup_time = null;

  job_status = '';

  driver_status = '';

  pretty_status = '';

  unimplemented = false;

  _field_map = {
    requested_pickup_time: time => moment(time),
  };

  constructor(obj) {
    super();
    this.update(obj);
  }
}
