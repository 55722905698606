import { Skeleton } from 'native-base';
import React from 'react';
import { View, Text } from 'react-native';
import { FormattedCurrency } from 'react-native-globalize';
import { SubtotalLineStyles as styles } from '../../styles/POSStyles';

function SubtotalLine({ isLast = false, subtotalName, subtotalAmount, isLoading }) {
  return (
    <View style={styles(isLast).SubtotalLine}>
      <Text style={styles(isLast).SubtotalNameText}>{subtotalName}</Text>

      <Skeleton
        isLoaded={!isLoading}
        rounded={4}
        w={10}
        h={6}
      >
        <FormattedCurrency
          value={subtotalAmount * 0.01}
          style={styles(isLast).SubtotalAmountText}
        />
      </Skeleton>
    </View>
  );
}

export default SubtotalLine;
