import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FontAwesome } from '@expo/vector-icons';

export default class ScrollButton extends React.PureComponent {
  render() {
    const { onPress, visible, enabled, direction } = this.props;
    if (!visible) return null;
    const style = direction === 'up' ? styles.scrollBtnUp : styles.scrollBtnDown;
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={[styles.scrollBtn, style, !enabled ? { opacity: 0.1 } : null]}>
          {/* <Text style={{color: 'black'}}>+{this.state.more}</Text> */}
          <Icon
            as={FontAwesome}
            name={`chevron-${direction}`}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = EStyleSheet.create({
  scrollBtn: {
    position: 'absolute',
    height: 70,
    width: 50,
    backgroundColor: 'rgba(255,255,255,0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 2,
    zIndex: 1001,
  },
  scrollBtnUp: {
    right: 10,
    top: 10,
  },
  scrollBtnDown: {
    right: 10,
    bottom: 40,
  },
});
