import _ from 'lodash';

let CartModifier;

export function setCartModifierClass(cm) {
  CartModifier = cm;
}

const hasModifiers = superclass =>
  class extends superclass {
    qty = 0;

    _deselectAll(heading_id) {
      this.mods[heading_id].forEach(m => (m.selected = false));
    }

    toggleModifier(modifier, isModifyingQty = false) {
      return new Promise((resolve, reject) => {
        const { heading } = modifier;
        if (!this.mods || !this.mods[heading.id]) this.mods[heading.id] = [];

        if (this.errors && this.errors.indexOf(heading.id) > -1) {
          this.errors.splice(this.errors.indexOf(heading.id), 1);
        }

        let exists = this.mods[heading.id].find(m => m.menuItemId === modifier.menuItemId);

        if (exists && !isModifyingQty) {
          exists.selected = !exists.selected;
          if (!exists.selected) exists.qty = 1;
        } else if (!exists) {
          exists = new CartModifier(this, modifier);
          this.mods[heading.id].push(exists);
        }

        if (heading.max_selected) {
          const numSelected = this.mods[heading.id]
            .filter(m => m.selected)
            .reduce((total, m) => (total += m.qty), 0);
          if (numSelected > heading.max_selected) {
            // todo: Notify
            if (heading.max_selected === 1) {
              this._deselectAll(heading.id);
              exists.selected = true;
            } else {
              exists.selected = false;
              resolve({
                success: false,
                errorType: 'max-selected',
                errorTitle: 'Too many selected',
                errorMessage: `You can only select ${heading.max_selected}`,
              });
              return;
            }
          }
        }

        resolve({ success: true, cartModifier: exists });
      });
    }

    isChecked(modifier) {
      if (!this.mods[modifier.heading.id]) return false;
      const exists = this.mods[modifier.heading.id].find(m => m.menuItemId === modifier.menuItemId);
      return exists && exists.selected;
    }

    hasRequiredModifiers() {
      let hasRequiredMods = false;
      this.modifier_groups.forEach(group => {
        if (group.min_selected > 0) {
          hasRequiredMods = true;
        }
      });
      return hasRequiredMods;
    }

    get modifier_groups() {
      if (!this.modifierGroups) return [];
      if (this._modifier_groups) return this._modifier_groups;
      this._modifier_groups = [];

      this.modifierGroups.forEach(mg => {
        const group_id = mg.modifierGroupId;
        const group = _.clone(this.api.menuData.modifierGroupsById[group_id]);

        group.modifiers = mg.modifierIds
          .map(id => this.api.menuData.modifiersById[id])
          .filter(m => m.enabled);

        if (group.modifiers.length) {
          group.modifiers = _.sortBy(group.modifiers, ['display_position', 'name']);
          this._modifier_groups.push(group);
        }
      });

      _.sortBy(this._modifier_groups, ['order_in_menu', 'heading_name']);

      return this._modifier_groups;
    }

    setQuantity(newQty) {
      this.qty = newQty;
    }

    /**
     * Called in ItemBuilder._doneEditing function.
     * Removes any modifiers which are not 'selected'
     * Note: Doesn't work as an arrow function
     */
    cleanup() {
      if (!this.mods) return;
      Object.keys(this.mods).forEach(groupId => {
        if (this.mods[groupId].length) {
          this.mods[groupId] = this.mods[groupId].filter(mod => mod.selected);
          this.mods[groupId].forEach(mod => mod.cleanup());
        }
      });
    }
  };

export default hasModifiers;
