import React, { Component } from 'react';
import { Dimensions, Pressable } from 'react-native';

// Components
import { View, Text, TouchableWithoutFeedback, FlatList } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Icon } from 'native-base';
import { Entypo } from '@expo/vector-icons';
import { Typography } from '../../styles';

class RelatedOrder extends Component {
  state = {
    expanded: false,
    isMobile: Dimensions.get('window').width <= 600,
  };

  _renderModifiers = mods => {
    const renderedMods = [];
    mods.forEach(m => {
      renderedMods.push(
        <View
          key={m.id}
          style={{ paddingLeft: 35 }}
        >
          <Text>{m.name}</Text>
        </View>,
      );
    });
    return renderedMods;
  };

  _toggleOpen = () => {
    const { expanded } = this.state;

    this.setState({
      expanded: !expanded,
    });
  };

  _renderOrderItem = ({ item, index }) => {
    const orderItemList = item; // change variable name to make the code more readable
    const { order } = this.props;
    const { grouped_items } = order;

    // Possible TODO: Group order items by their hash and their status if we want to track individual item progression
    // const groups = _.chain(item).groupBy("pretty_status").map((value, key) => ({ pretty_status: key, qty: value.length, items: value })).value()

    // Find moment for item that is most recently modified
    // const mostRecentTimeModified = moment(Math.max.apply(Math, orderItemList.map((i) => i.time_modified)));

    // Sort items by least recently modified
    orderItemList.sort((a, b) => a.time_modified.diff(b.time_modified));

    return (
      <View
        style={[
          styles.orderItemContainer,
          { ...(index !== grouped_items.length - 1 && { borderBottomWidth: 1 }) },
        ]}
      >
        <View
          key={`${orderItemList[0].itemId}-${orderItemList[0].status}`}
          style={[styles.orderItemGroup]}
        >
          <Text style={{ width: 30 }}>{orderItemList.length}</Text>
          <Text style={{ flex: 1, marginRight: 20, color: 'black' }}>
            {orderItemList[0].itemName}
          </Text>
          <View style={[styles.statusLabel]}>
            <Text
              style={[
                Typography.bold,
                {
                  color: '#777777',
                  textAlign: 'right',
                  textTransform: 'capitalize',
                },
              ]}
            >
              {orderItemList[0].pretty_status}
            </Text>
          </View>
        </View>
        <View>{this._renderModifiers(orderItemList[0]?.mods)}</View>
        {orderItemList[0].special_instructions?.length > 0 && (
          <View style={{ paddingTop: 10, paddingLeft: 30 }}>
            <Text style={[Typography.italic, { textAlign: 'right' }]}>
              {orderItemList[0].special_instructions}
            </Text>
          </View>
        )}
      </View>
    );
  };

  handleLayoutChange = ({ nativeEvent }) => {
    const { layout } = nativeEvent;
    if (layout) {
      this.setState({
        ...layout,
        isMobile: layout.width <= 560,
      });
    }
  };

  render() {
    const { order, style } = this.props;
    const { expanded, isMobile } = this.state;
    if (!order) {
      return null;
    }

    const { grouped_items, station } = order;

    return (
      <Pressable
        style={[styles.relatedOrderContainer, style]}
        onLayout={this.handleLayoutChange}
        onPress={this._toggleOpen}
      >
        <View style={[styles.relatedOrderHeader]}>
          <View style={{ marginRight: 20, justifyContent: 'center' }}>
            <Icon
              as={Entypo}
              fontSize={16}
              name={expanded ? 'chevron-down' : 'chevron-right'}
            />
          </View>
          <View
            style={[
              styles.relatedOrderHeader__left,
              !isMobile && {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Text
              style={[Typography.bold, !isMobile && { marginRight: 20 }]}
            >{`#${order.orderNumber}`}</Text>
            <Text style={[Typography.bold]}>{station?.station_name}</Text>
          </View>
          <View
            style={[
              styles.relatedOrderHeader__right,
              !isMobile && {
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'flex-start',
              },
            ]}
          >
            <View style={[styles.label, isMobile && styles.label__mobile]}>
              <Text
                style={[Typography.bold, { color: '#FFF', textAlign: 'right', flexBasis: 'auto' }]}
              >
                {order.pretty_status}
              </Text>
            </View>
            <Text style={[Typography.italic, { marginRight: 20, textAlign: 'right' }]}>
              {order.last_modified.fromNow()}
            </Text>
          </View>
        </View>

        {expanded && grouped_items && (
          <View style={[styles.relatedOrderContent]}>
            <FlatList
              data={grouped_items}
              keyExtractor={i => `${i[0].itemId}-${i[0].order_item_hash}`}
              renderItem={this._renderOrderItem}
            />
          </View>
        )}
      </Pressable>
    );
  }
}

export default RelatedOrder;

const styles = EStyleSheet.create({
  relatedOrderContainer: {
    borderWidth: 2,
    borderColor: '#EEEEEE',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    shadowOffset: { width: 0, height: 3 },
    shadowColor: '#EEEEEE',
    shadowOpacity: 1,
    shadowRadius: 0,
  },
  // ----------------------------------------
  // Related Order Header
  // ----------------------------------------
  relatedOrderHeader: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  relatedOrderHeader__left: {
    justifyContent: 'center',
    flex: 1,
  },
  relatedOrderHeader__right: {
    justifyContent: 'center',
    flex: 1,
  },
  label: {
    backgroundColor: '#000000',
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 50,
  },
  label__mobile: {
    alignSelf: 'flex-end',
  },
  statusLabel: {
    backgroundColor: '#EEEEEE',
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 50,
  },
  // ----------------------------------------
  // Related Order Content
  // ----------------------------------------
  relatedOrderContent: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  orderItemContainer: {
    borderColor: '#DDDDDD',
    paddingVertical: 5,
  },
  orderItemGroup: {
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center',
  },
});
