import React from 'react';
import { Button, Text } from 'native-base';
import { useGlobalize } from 'react-native-globalize';

function TranslatedButton({ textPath, data, buttonStyle = {}, textStyle = {}, ...props }) {
  const { formatMessage } = useGlobalize();

  return (
    <Button
      {...props}
      style={buttonStyle}
    >
      <Text style={textStyle}>{formatMessage(textPath, data)}</Text>
    </Button>
  );
}

export default TranslatedButton;
