import { createSwitchNavigator } from 'react-navigation';
import POS from '../screens/POS';
import { defaultNavigationOptions } from './Defaults';

export default createSwitchNavigator(
  {
    POS,
  },
  {
    defaultNavigationOptions,
  },
);
