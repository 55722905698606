import { ActivityIndicator, Modal, Text, View } from 'react-native';
import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from '../../constants/Colors';
import { modalStyles } from '../../styles/Global';

/**
 * Modal Loader Component which can render a full screen Loading Spinner overtop of existing modals
 * @param message
 * @param shown
 * @returns {JSX.Element}
 * @constructor
 */
function MLoader({ message, shown }) {
  const msgTxt = message ? <Text style={styles.message}>{message}</Text> : null;

  return (
    <Modal
      transparent
      visible={shown}
      statusBarTranslucent
    >
      <View style={modalStyles.background}>
        <ActivityIndicator
          size="large"
          color={Colors.primary}
        />
        {msgTxt}
      </View>
    </Modal>
  );
}

export default MLoader;

const styles = EStyleSheet.create({
  message: {
    fontSize: 18,
    color: 'white',
  },
});
