import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableWithoutFeedback, View, Modal } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Container, Radio, Text, Checkbox, Button } from 'native-base';
import { styles as globalStyles } from '../../styles/Global';
import API from '../../api';
import { Body, ListItem, Left, Right, Title, Header, Footer } from '../../bbot-component-library';
import Colors from '../../constants/Colors';

export default class ServiceConditionModal extends Component {
  static propTypes = {};

  state = {
    selectedIds: [],
  };

  static get styles() {
    return EStyleSheet.create({
      modal: {
        backgroundColor: 'white',
        width: '50%',
        maxHeight: '80%',
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible && !prevProps.visible) {
      this.setState({
        selectedIds: this.props.station.service_condition_ids,
      });
    }
  }

  render() {
    const { visible, station, onClose, onSuccess } = this.props;
    if (!station) return null;
    const { service_conditions } = station.customer;

    return (
      <Modal
        visible={visible}
        transparent
        onRequestClose={onClose}
        statusBarTranslucent
      >
        <TouchableWithoutFeedback onPress={onClose}>
          <View style={globalStyles.modalBackground}>
            <TouchableWithoutFeedback>
              <View style={styles.modal}>
                <Container style={{}}>
                  <Header style={{ padding: 5 }}>
                    <Body>
                      <Title>Service Conditions</Title>
                    </Body>
                  </Header>
                  <View style={{ flex: 1, width: '100%' }}>
                    {service_conditions.map(condition => this._getField(condition))}
                  </View>
                  <Footer style={{ padding: 5 }}>
                    <Right>
                      <Button
                        colorScheme="info"
                        onPress={this._save}
                      >
                        <Text style={{ color: 'white' }}>Save</Text>
                      </Button>
                    </Right>
                  </Footer>
                </Container>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  _getField = condition => {
    const { widget, group_name, choices } = condition;
    const { station } = this.props;
    const { selectedIds } = this.state;
    const result = [
      <ListItem
        itemDivider
        key={condition.group_name}
        style={{
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderTopColor: 'lightgray',
          backgroundColor: Colors.lightGray,
          padding: 5,
        }}
      >
        <Left>
          <Text style={{ fontWeight: 'bold' }}>{group_name}</Text>
        </Left>
      </ListItem>,
    ];
    if (widget === 'radio') {
      const currVal = _.intersection(
        selectedIds,
        choices.map(c => c.id),
      )[0];

      return result.concat(
        <Radio.Group
          testID={`condition_${_.snakeCase(group_name)}`}
          onChange={nextValue => {
            this._setServiceConditions(condition, nextValue);
          }}
          accessibilityLabel="choose one"
          value={currVal}
          style={{ padding: 5 }}
        >
          {choices.map((choice, i) => (
            <Radio
              key={choice.id}
              value={choice.id}
            >
              {choice.name}
            </Radio>
          ))}
        </Radio.Group>,
      );
    }
    if (widget === 'checkbox') {
      return result.concat(
        <Checkbox.Group
          testID={`condition_${_.snakeCase(group_name)}`}
          accessibilityLabel="choose values"
          onChange={newValues => {
            const diff = _.xor(selectedIds, newValues);
            this._setServiceConditions(condition, diff[0]);
          }}
          value={selectedIds}
          style={{ padding: 5 }}
        >
          {choices.map((choice, i) => (
            <Checkbox value={choice.id}>{choice.name}</Checkbox>
          ))}
        </Checkbox.Group>,
      );
    }
  };

  _setServiceConditions = async (condition, id) => {
    let { selectedIds } = this.state;

    if (condition.widget === 'radio') {
      selectedIds = _.without(selectedIds, ...condition.choices.map(c => c.id));
      selectedIds.push(id);
    } else if (condition.widget === 'checkbox') {
      selectedIds = _.xor(selectedIds, [id]);
    }
    this.setState({
      selectedIds,
    });
  };

  _save = async () => {
    const { onSuccess } = this.props;

    this.setState({
      processing: true,
    });
    const { station } = this.props;
    const { selectedIds } = this.state;
    const response = await API.setServiceConditions(station, selectedIds);
    if (response.success) {
      onSuccess(selectedIds);
    }
    this.setState({
      processing: false,
    });
  };
}

const { styles } = ServiceConditionModal;
