import analytics from './instrumentation';

export const getBrowserData = () => {
  const hasTouchEvents = window ? 'ontouchstart' in window : false;
  const innerWidth = window?.innerWidth || 0;
  const innerHeight = window?.innerHeight || 0;
  const userAgent = window?.navigator?.userAgent || 0;

  return {
    href: window?.location?.href || '',
    referrer: window?.document?.referrer || '',
    platform: hasTouchEvents && innerWidth < 900 ? 'mobile' : 'desktop',
    browser_width: innerWidth,
    browser_height: innerHeight,
    touch: hasTouchEvents,
    user_agent: userAgent,
  };
};

// Track and store custom event using Segment as destination.
// Check Segment configuration for more details:
// https://app.segment.com/doordash/sources/consumer_web/overview
const trackSegment = (eventName, body, context) => {
  if (typeof window === 'undefined') {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  context = { ...context, app: { ...context?.app } };

  const payload = {
    ...body,
    ...getBrowserData(),
    app_version: '',
    is_ssr: window?.is_ssr ?? false,
  };

  // console.warn events locally
  if (
    process.env.REACT_APP_BBOT_ENVIRONMENT === 'localdev' &&
    process.env.REACT_APP_DEBUG_TRACKING_EVENTS
  ) {
    console.warn('::: Tracking Event Fired :::', eventName, { payload, context });
  }

  if (analytics) analytics.track(eventName, payload, context);
};

export default trackSegment;
