import React, { useEffect, useReducer } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Icon, Text } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import API from '../../api';
import { Body, Left, Right, ListItem } from '../../bbot-component-library';
import { styles } from '../../styles/ListComponentStyle';

/**
 * Displays the Next/Last Poll times
 * @param focus
 * @returns {JSX.Element}
 * @constructor
 */
function PollInterval({ focus }) {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    lastPoll: 0,
    nextPoll: '',
  });

  useEffect(() => {
    let interval;
    if (focus) {
      interval = setInterval(() => {
        const secsTillNextPoll = moment(API._pollCompleteTime)
          .add(API._pollInterval || 20, 'seconds')
          .diff(moment(), 'seconds');
        const nextPoll = _.max([secsTillNextPoll, 0]);
        setState({
          lastPoll: moment().diff(moment(API._last_poll), 'seconds'),
          nextPoll: nextPoll > 0 ? `${nextPoll}s` : 'Polling',
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [focus]);

  return (
    <ListItem>
      <Left icon>
        <Icon
          size={8}
          style={styles.iconStyle}
          as={MaterialCommunityIcons}
          name="update"
        />
      </Left>
      <Body>
        <Text>Last/Next Update</Text>
      </Body>
      <Right onListItem>
        <Text note>
          {state.lastPoll}
          s/
          {state.nextPoll}
        </Text>
      </Right>
    </ListItem>
  );
}

export default PollInterval;
