import React from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { Buttons } from '../styles';

function FilterButtonSecondary({ label, onPress }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={Buttons.pillSecondary}>
        <Text style={Buttons.pillLabelSecondary}>{label}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default FilterButtonSecondary;
