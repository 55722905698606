import { Input, Stack, FormControl, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import Required from './Required';
import { styles } from './styles';

/**
 * TODO: FIGURE OUT WHY THIS ISN"T HIGHLIGHTING WHEN ERROR
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TextField(props) {
  const {
    field,
    index,
    label,
    required,
    fieldValue,
    setFieldValue,
    valid,
    setValid,
    highlightError,
    validator = val => (required ? !!val : true),
    keyboardType,
    formatter,
    placeholder,
    ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (highlightError) {
      setTouched(true);
      setValid(validator(fieldValue));
    }
  }, [highlightError]);

  useEffect(() => {
    if (fieldValue) setValid(validator(fieldValue));
  }, [fieldValue]);

  const onChangeText = text => {
    const val = formatter ? formatter(text) : text;
    setFieldValue(val);
    const isValid = validator(val);
    setValid(isValid);
  };

  const onBlur = () => {
    setTouched(true);
  };

  return (
    <Stack
      stackedLabel
      key={index}
      style={styles.fieldItem}
      {...rest}
      accessible={false}
    >
      {/* The Label for the Checkout Information */}
      <Text style={[styles.fieldLabel, !valid && touched && styles.invalidFieldLabel]}>
        {label}
        <Required show={required} />
      </Text>
      <Input
        testID={`requiredCheckoutInfo-${index}`}
        style={[styles.textInputField, highlightError && !valid ? styles.errorField : null]}
        keyboardType={keyboardType}
        placeholder={placeholder}
        onChangeText={onChangeText}
        value={fieldValue || ''}
        onBlur={onBlur}
      />
    </Stack>
  );
}

export default TextField;
