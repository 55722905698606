import _ from 'lodash';
import React, { Component } from 'react';
import { View, Text, Platform, Dimensions, Linking, Pressable } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Container, Input, Icon, Box, Button } from 'native-base';
import StripeT from 'react-native-stripe-terminal';
import NfcManager, { Ndef, NfcEvents } from 'react-native-nfc-manager';
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import { Content, Footer, FooterTab, SearchBar } from '../bbot-component-library';
import API from '../api';
import Alert from '../components/Alert';
import { CardView, TabView, styles as cardStyles } from '../components/CardView';
import Loader from '../components/Loader';
import ReaderStatus from '../components/ReaderStatus';
import Colors from '../constants/Colors';
import IconButton from '../components/IconButton';
import Fontello from '../assets/fonts/Fontello';
import { capturePaymentMethod } from '../components/Stripe/StripeHelper';
import IconFooter from '../bbot-component-library/IconFooter';

/*
- When adding card, need to choose the guest as well
 */

export default class CardChooser extends Component {
  static navigationOptions = props => ({
    title: (
      <FormattedMessage
        id="common__chooseCard"
        defaultMessage="Choose Card"
      />
    ),
    headerRight: () => <ReaderStatus />,
  });

  constructor(props) {
    super(props);

    const { navigation } = this.props;
    const location = navigation.getParam('location');
    const check = navigation.getParam('check');
    const uniqSeats = check.getUniqSeats();
    const config = API.getConfig();

    this.state = {
      check,
      consumer_tabs: location.open_tabs,
      containerHeight: Dimensions.get('window').height - 64,
      group_cards: [],
      guest_cards: location.seated_group ? location.seated_group.getGuestCardIds(uniqSeats) : [],
      loading: true,
      location,
      party_tabs: [],
      readerConnected: StripeT.readerConnected,
      selected_card: null,
      smart_ordering_cards: [],
      tabAmount: Math.max(config.default_tab_cents, check.total * config.tab_multiplier),
      availableCaptureMethods: this._getAvailableCaptureMethods(),
      tab_search: '',
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.refreshCards();

    this._stripeChangeListener = StripeT.on('ConnectionStatusChange', () => {
      if (!this._mounted) return;
      this.setState({
        readerConnected: StripeT.readerConnected,
        availableCaptureMethods: this._getAvailableCaptureMethods(),
      });
    });

    if (Platform.OS === 'android') {
      // Default Android method
      this._linkingListener = Linking.addEventListener('url', this._handleNFC);
      // Needed once we fire up react-native-nfc-manager
      NfcManager.setEventListener(NfcEvents.DiscoverTag, tag => {
        console.log('DiscoverTag', tag.ndefMessage[0]);
        console.log('Decoded: ', Ndef.uri.decodePayload(tag.ndefMessage[0].payload));
      });
      NfcManager.registerTagEvent();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this._stripeChangeListener.remove();
    this._linkingListener?.remove();
  }

  _getAvailableCaptureMethods = () => {
    const methods = [];
    if (API.config.allow_manual_card_entry) methods.push('manual');
    if (StripeT.readerConnected) methods.push('reader');

    return methods;
  };

  _handleNFC = async ({ url }) => {
    const path = url.replace('bbot://', '');
    const parts = path.split('/');
    const action = parts[0];
    if (action.toUpperCase() === 'JOIN-TAB') {
      const code = parts[1];
      const tab = await API.getTabInfo(code);
      console.log('scanned tag: ', tab.tab_id);
      if (tab.message) {
        Alert.alert('Tab Not Found', tab.message);
      } else {
        const tabModel = API._tabs[tab.tab_id];
        if (tabModel) {
          this.setState(
            {
              selected_card: tabModel,
            },
            this._goToTipScreen,
          );
        } else {
          Alert.alert('Tab Not Found', "Can't find tab, please try refreshing.");
        }
      }
    }
  };

  async refreshCards() {
    this.has_cards = false;

    // If on a given handheldPoll, we notice a tab we care about is updated, we should re-call getLocationDetails
    const result = await API.getCards(this.state.location);

    if (!this._mounted) return;

    if (result.error) {
      this.setState({
        loading: false,
      });

      // todo do something on error
      console.log('Error: ', result.error);
      return;
    }

    this.setState({
      loading: false,
      group_cards: result.group_cards,
      // party_tabs: result.party_tabs,
      smart_ordering_cards: result.smart_ordering_cards,
    });
  }

  render() {
    const { loading, location } = this.state;
    const possibleSeats = this.state.check.getUniqSeats();
    const config = API.getConfig();

    return (
      <Container
        testID="cardChooserScreen"
        onLayout={this._onContentLayout}
      >
        <Loader shown={loading} />
        <Content>
          <View style={{ maxWidth: 650, width: '100%', alignSelf: 'center' }}>
            {this._getCartTab()}
            {this._getGroupCards()}
            {config.allow_pay_with_consumer_tab && this._getConsumerTabs()}
            {this._getTabs()}
            {this._getSOCards()}
            {this._getNoCardsMsg()}
          </View>
        </Content>

        <IconFooter testID="cardChooser_Footer">
          <IconButton
            testID="addCardTab"
            label="button__addCard"
            defaultMessage="Add Card/Tab"
            icon="credit-card"
            iconType={FontAwesome5}
            onPress={this.showStripePopup}
            disabled={!this.state.availableCaptureMethods.length}
          />
          <IconButton
            testID="CardChooserPromptGuest"
            label="button__promptGuest"
            defaultMessage="Prompt Guest"
            icon="user-card"
            iconType={Fontello}
            disabled={!this.state.availableCaptureMethods.length}
            onPress={this.promptCustomer}
          />
        </IconFooter>
      </Container>
    );
  }

  /**
   * This is needed since react-native-web ScrollViews are broken and don't respect their parent
   * elements flex height. Hopefully fixed in a future release at which point this could be removed. Flex-basis: 0
   * sometimes helps but not here.
   * @private
   */
  _onContentLayout = () => {
    // added the setTimeout because in react-native-web, this function is called before the height is finished re-calculating
    setTimeout(() => {
      const height = Dimensions.get('window').height - 64;
      if (height !== this.state.containerHeight) {
        this.setState({
          containerHeight: height,
        });
      }
    });
  };

  _getNoCardsMsg() {
    if (!this.state.loading && !this.has_cards) {
      return (
        <View style={{ alignItems: 'center', padding: 20 }}>
          <FormattedMessage
            id="card__chooser__noMethodsAvailable"
            defaultMessage="No Payment Methods Available"
            style={{ fontWeight: 'bold', fontSize: 16 }}
          />
        </View>
      );
    }
  }

  _getCartTab() {
    const { check } = this.state;
    if (!check.cart.tab_id) return null;
    const tab = API._tabs[check.cart.tab_id];
    if (!tab) return null;

    return (
      <View style={{ padding: 20 }}>
        <FormattedMessage
          id="tab__current"
          defaultMessage="Current Tab"
          style={styles.title}
        />
        <TabView
          tab={API._tabs[check.cart.tab_id]}
          onPress={this._selectCard}
        />
      </View>
    );
  }

  _getGroupCards() {
    const { group_cards, guest_cards, consumer_tabs } = this.state;
    const filtered_cards = group_cards.filter(
      card =>
        guest_cards.includes(card.id) && !consumer_tabs.find(t => t.default_card_id === card.id),
    );
    if (filtered_cards.length) this.has_cards = true;

    const { allow_manual_card_entry } = API.config;

    return (
      !!filtered_cards.length && (
        <View style={{ padding: 20 }}>
          {!!filtered_cards.length && <Text style={styles.title}>Credit Cards</Text>}
          {filtered_cards.map(card => (
            <CardView
              testID="cardChooserCardView"
              key={card.id}
              card={card}
              onPress={this._selectCard}
            />
          ))}
        </View>
      )
    );
  }

  _getSOCards() {
    const { smart_ordering_cards, consumer_tabs } = this.state;

    const cards_to_display = smart_ordering_cards.filter(
      card =>
        !consumer_tabs.find(
          t => t.brand === card.brand && t.last4 === card.last4 && t.expiry === card.expiry,
        ),
    );

    if (cards_to_display.length) this.has_cards = true;

    return (
      <View style={{ padding: 20 }}>
        {!!cards_to_display.length && (
          <View>
            <FormattedMessage
              id="card__fromWebsite"
              defaultMessage="Cards used on Website"
              style={styles.title}
            />
            <FormattedMessage
              id="card__confirm"
              defaultMessage="YOU MUST CONFIRM THE LAST 4 DIGITS OF THE CARD WITH GUEST BEFORE USING ONE OF THESE OPTIONS."
              style={styles.note}
            />
          </View>
        )}
        {cards_to_display.map(card => (
          <CardView
            key={card.id}
            card={card}
            onPress={this._selectCard}
          />
        ))}
      </View>
    );
  }

  _getTabs() {
    // filter tabs by seated_group_id
    const { party_tabs } = this.state;
    const filtered_tabs = party_tabs;
    // let filtered_tabs = party_tabs.filter(tab => this.state.guest_cards.includes(tab.id)); // && tab.available_cents > 0);
    if (filtered_tabs.length > 0) this.has_cards = true;
    else return null;

    return (
      <View style={{ padding: 20 }}>
        <FormattedMessage
          id="tab__partyTabs"
          defaultMessage="Party Tabs"
          style={styles.title}
        />
        {filtered_tabs.map(tab => (
          <TabView
            key={tab.id}
            tab={tab}
            onPress={this._selectCard}
          />
        ))}
      </View>
    );
  }

  /**
   * TODO: Don't show for split check orders
   * @returns {JSX.Element|null}
   * @private
   */
  _getConsumerTabs() {
    // filter tabs by location_id
    const { consumer_tabs, tab_search } = this.state;
    if (consumer_tabs.length || tab_search || API.config.allow_pay_with_consumer_tab)
      this.has_cards = true;
    else return null;

    return (
      <View style={{ padding: 20 }}>
        <View
          style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <FormattedMessage
            id="tab__cloud"
            defaultMessage="Cloud Tabs"
            style={styles.title}
          />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: 200,
              paddingRight: 10,
            }}
          >
            <SearchBar
              value={tab_search}
              onChangeText={this._searchTabs}
              style={{ marginLeft: 10 }}
              variant="ghost"
              InputRightElement={
                <Pressable
                  variant="ghost"
                  style={{ marginRight: 16 }}
                  onPress={() => this._searchTabs('')}
                >
                  <Icon
                    name="clear"
                    as={MaterialIcons}
                    style={{ color: tab_search ? 'black' : 'gray' }}
                  />
                </Pressable>
              }
            />
          </View>
        </View>

        {consumer_tabs.map(tab => (
          <TabView
            key={tab.id}
            tab={tab}
            onPress={this._selectCard}
          />
        ))}
        {!consumer_tabs.length && !!tab_search && (
          <View style={{ borderRadius: 4, borderWidth: 1 }}>
            <FormattedMessage
              id="tab__noResult"
              defaultMessage={`No results for: ${tab_search}`}
              values={{ tab_search }}
              style={{ marginHorizontal: 10, marginVertical: 15 }}
            />
          </View>
        )}
        {!consumer_tabs.length && !tab_search && (
          <View style={{ borderRadius: 4, borderWidth: 1 }}>
            <FormattedMessage
              id="tab__notFound"
              defaultMessage="No tabs found for this location"
              style={{ marginHorizontal: 10, marginVertical: 15 }}
            />
          </View>
        )}
      </View>
    );
  }

  _searchTabs = search => {
    const { location } = this.state;
    let tabs = [];
    if (!search) {
      tabs = location.open_tabs;
    } else {
      tabs = Object.values(API._tabs).filter(
        t =>
          t.end_date.isAfter() &&
          t.tab_name &&
          (t.tab_name.toLowerCase().includes(search.toLowerCase()) ||
            t.card_last_4.includes(search)),
      );
    }
    this.setState({
      consumer_tabs: tabs,
      tab_search: search,
    });
  };

  /**
   * Sets the currently selected Card / Tab
   * @param card
   * @private
   */
  _selectCard = card => {
    this.setState(
      {
        selected_card: card,
      },
      () => this._goToTipScreen(),
    );
  };

  /**
   * We'll prompt the customer on the next screen to present their card for an instant transaction
   */
  promptCustomer = () => {
    this.setState(
      {
        selected_card: null,
      },
      () => {
        this._goToTipScreen(true);
      },
    );
  };

  openTab = async (card, tabName) => {
    const { location } = this.state;
    const { seated_group } = location;
    const { consumer_tabs, guest_cards, check } = this.state;
    const seats = check.getUniqSeats();

    const card_id = card.id;

    // todo: pass seat # if only 1 uniqSeat, otherwise prompt for Seat# with Tab Name.
    // todo: Pass param letting us know this is a keep-open tab
    const result = await API.openTab(seated_group.id, tabName, location.id, card_id);
    const { tab, joinURL } = result;
    tab._joinURL = joinURL;
    consumer_tabs.push(tab);
    seated_group.addCardToGuests(seats, tab.id);

    this.setState({
      consumer_tabs,
      guest_cards: guest_cards.concat(tab.id),
      selected_card: tab,
    });

    this._goToTipScreen();
  };

  showStripePopup = async () => {
    const { location, consumer_tabs, group_cards, guest_cards, check } = this.state;

    const cardOrTab = await capturePaymentMethod({
      location,
      reusable: true,
      check,
    });

    if (cardOrTab.type === 'tab') {
      this.setState(
        {
          selected_card: cardOrTab,
          consumer_tabs: consumer_tabs.concat([cardOrTab]),
        },
        () => {
          this._goToTipScreen();
        },
      );
    } else {
      this.setState(
        {
          selected_card: cardOrTab,
          group_cards: group_cards.concat(cardOrTab),
          guest_cards: guest_cards.concat(cardOrTab.id),
        },
        () => {
          this._goToTipScreen();
        },
      );
    }
  };

  _goToTipScreen = (force = false) => {
    const { navigate } = this.props.navigation;
    const { check, selected_card, location } = this.state;

    if (selected_card || !check.total || force) {
      navigate('CheckoutScreen', {
        check,
        card: selected_card,
        location,
      });
    }
  };
}

const styles = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    backgroundColor: Colors.gray,
    borderColor: Colors.gray,
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: 15,
    marginTop: 10,
    marginRight: 10,
  },
  note: {
    fontSize: '0.9rem',
    marginBottom: 10,
    color: Colors.darkGray,
  },
  hButton: {
    flex: 1,
    backgroundColor: Colors.primaryLight,
    borderRadius: 5,
    padding: 10,
    margin: 10,
    borderWidth: 1,
    borderColor: Colors.primary,
    height: 50,
  },
  continueBtn: {
    flex: 1,
    padding: 15,
    borderRadius: 5,
    borderWidth: 1,
    marginHorizontal: 10,
    height: 50,
  },
  continueBtnText: {
    color: Colors.dark,
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
    height: 60,
  },
});
