import _ from 'lodash';
import Model from './Model';
import Modifier from './Modifier';

export default class ModifierGroup extends Model {
  id = null;

  customer_id = null;

  order_in_menu = 0;

  heading_name = '';

  min_selected = 0;

  max_selected = 999;

  display_position = 0;

  archived = false;

  integrations = {};

  show_on_ticket = false;

  modifiers = [];

  _field_map = {
    modifiers: modifiers =>
      _.mapValues(modifiers, (obj, menuItemId) => {
        const modifier = new Modifier(this, Object.assign(obj, { menuItemId }));
        this.menuData.modifiersById[menuItemId] = modifier;
        return modifier;
      }),
  };

  constructor(menuData, data) {
    super();
    Object.defineProperty(this, 'menuData', { value: menuData, enumerable: false });

    this.update(data);
  }

  get description() {
    // Pseudocode
    // --------------------------------------------------
    // if max == 999:
    //    if min === 0:       Unlimited
    //    else:               Choose at least min (Required)
    // else if min == 0:
    //    if max == 1:        Choose one (optional)
    //    else:               Choose up to max_selected
    // else if min > 0:
    //  if min < max:         Choose between min and max (Required)
    //  else:                 Choose min (Required)

    if (this.max_selected === 999) {
      if (this.min_selected === 0) {
        return 'Unlimited';
      }
      return `Choose at least ${this.min_selected}`;
    }
    if (this.min_selected === 0) {
      if (this.max_selected === 1) {
        return 'Choose one (Optional)';
      }
      return `Choose up to ${this.max_selected}`;
    }
    if (this.min_selected > 0) {
      if (this.min_selected < this.max_selected) {
        return `Choose between ${this.min_selected} and ${this.max_selected}`;
      }
      return `Choose ${this.min_selected}`;
    }
  }
}
