import _ from 'lodash';
import sh from 'shorthash';
import uuid from 'uuid';
import Model from './Model';
import CartModifier from './CartModifier';
import ItemPart from './ItemPart';
import mix from './mixins/Mixin';
import hasModifiers from './mixins/hasModifiers';

/**
 * @class CartItem
 */
export default class CartItem extends mix(Model).with(hasModifiers) {
  static get className() {
    return 'CartItem';
  }

  /** @type {string} This CartItem's specific UUID */
  id = uuid.v4();

  /** @type {?string} The ID of the customer that made the order for this item */
  customer_id = null;

  /** @type {?string} */
  menuId = null;

  /** @type {?string} */
  menu_heading_id = null;

  /** @type {?string} */
  menuItemId = null;

  /** @type {?string} */
  name = null;

  /** @type {Dictionary<CartModifier>} Maps group ids */
  mods = {};

  pretax_cents = 0;

  tax_cents = 0;

  tax_fraction = 0;

  /** @type {number[]} The list of integer seat numbers this item applies to */
  seat_numbers = [];

  qty = 1;

  /** @type {?string} The set of special instructions to the kitchen about this particular item */
  special_instructions = null;

  errors = [];

  // Fields for the Price Check
  discounts = [];

  lineitem_pretax_cents = null;

  lineitem_tax_cents = null;

  _field_map = {
    mods: mods => {
      if (Array.isArray(mods)) {
        const res = {};
        mods.forEach(mod => {
          const menuModifier = this.api.menuData.modifiersById[mod.id];
          if (!res[menuModifier.heading.id]) res[menuModifier.heading.id] = [];
          res[menuModifier.heading.id].push(new CartModifier(this, mod));
        });
        return res;
      }
      const newMods = {};
      for (const groupId in mods) {
        newMods[groupId] = mods[groupId].map(mod => new CartModifier(this, mod));
      }
      return newMods;
    },
    lineitem_pretax_cents: val => {
      if (this._parts.length) {
        const weights = this._parts.map(part => part.numerator / part.denominator);
        const values = ItemPart.distributeByWeights(val, weights);
        for (let i = 0; i < this._parts.length; i++) {
          this._parts[i].lineitem_pretax_cents = values[i];
        }
      }
      return val;
    },
    lineitem_tax_cents: val => {
      if (this._parts.length) {
        const weights = this._parts.map(part => part.numerator / part.denominator);
        const values = ItemPart.distributeByWeights(val, weights);
        for (let i = 0; i < this._parts.length; i++) {
          this._parts[i].lineitem_tax_cents = values[i];
        }
      }
      return val;
    },
    discounts: discounts => {
      if (this._parts.length) {
        this._parts.forEach(part => {
          part.discounts = [];
        });
        discounts.forEach(discount => {
          const weights = this._parts.map(part => part.numerator / part.denominator);
          const values = ItemPart.distributeByWeights(discount.cents_added, weights);
          for (let i = 0; i < this._parts.length; i++) {
            this._parts[i].discounts = [];
            this._parts[i].discounts.push({
              promotion_id: discount.promotion_id,
              name: discount.name,
              cents_added: values[0],
            });
          }
        });
      }
      return discounts;
    },
  };

  /**
   * A list of properties to always include when converting CartItem into its JSON representation. Takes priority over
   * properties in the _do_not_serialize list of properties.
   * @type {string[]}
   * @private
   */
  _force_serialize = ['displayed_tax_cents', 'displayed_pretax_cents'];

  /**
   * A list of properties to never include when converting CartItem into its JSON representation
   * @type {string[]}
   * @private
   */
  _do_not_serialize = ['cart'];

  _parts = [];

  constructor(cart, obj) {
    super();

    if (obj.constructor.className === 'OrderItem') {
      return CartItem.fromOrderItem(cart, obj);
    }
    if (obj.constructor.className === 'CartItem') {
      this.update(
        _.pick(obj, [
          'customer_id',
          'menuId',
          'menuItemId',
          'menu_heading_id',
          'name',
          'pretax_cents',
          'tax_cents',
          'tax_fraction',
          'mods',
        ]),
      );
    } else {
      this.update(obj);
    }

    this.setCart(cart);
  }

  setCart(cart) {
    // We define it this way so it isn't enumerated
    Object.defineProperty(this, 'cart', {
      value: cart,
      writable: true,
      enumerable: false,
    });
  }

  update(data) {
    super.update(data);
  }

  /**
   * @returns {Location} The restaurant location this Cart Item will be sent to
   */
  get location() {
    return this.cart.location;
  }

  /**
   * @returns {boolean} True if this item's location is able to fulfill this menu item, false otherwise.
   */
  get is_fulfillable() {
    return this.cart.location.fulfillable_items.includes(this.menuItemId);
  }

  /**
   * @returns {string | boolean} The customer id if a customer was found, or false if no customer was found.
   */
  getCustomerId() {
    const menuData = this.api.getMenu();
    const menu = menuData.menus.find(m => m.menuId === this.menuId);
    return menu.customer && menu.customer.customer_id;
  }

  /**
   * Recursively goes down the child modifiers and returns the list of modifiers with errors and
   * marks the modifiers that have errors by populating their errors property with the Group IDs
   *
   * @returns {Array} the list of modifier with errors
   */
  hasModErrors(errors = []) {
    this.errors = []; // reset the errors array

    this.menuItem.modifier_groups.forEach(group => {
      const cartMods = this.mods[group.id] || [];
      const quantityOfSelectedMods = cartMods
        .filter(m => m.selected)
        .reduce((total, m) => (total += m.qty), 0);
      const selected = cartMods.filter(m => m.selected);
      if (
        group.min_selected > quantityOfSelectedMods ||
        group.max_selected < quantityOfSelectedMods
      ) {
        this.errors.push(group.id);
      }
      selected.forEach(mod => {
        const childrenErrors = mod.hasModErrors(errors);
        errors.concat(childrenErrors);
      });
    });

    // if the location has a seated group and the seated group has more than 1 guest then make sure a seat number
    // is associated with the cartItem
    if (this.cart.location?.seated_group?.guests.length > 1 && this.seat_numbers.length === 0) {
      this.errors.push('seats');
    }
    // if the location has a seated group and number of guests is 1 then just default the item to be associated with
    // the only guest at that location
    else if (this.cart.location.seated_group?.guests.length == 1) {
      this.seat_numbers = [1];
    }

    if (this.errors.length > 0) {
      errors.push(this);
    }

    return errors;
  }

  static fromOrderItem(cart, obj) {
    const menuData = this.api.getMenu();

    const menuItem = menuData.menuItemsById[obj.itemId];
    const heading = menuItem.menu_heading;
    const { menu } = heading;

    const newObj = {
      customer_id: obj.order.customer_id,
      menuId: menu.menuId,
      menu_heading_id: heading.id,
      menuItemId: obj.itemId,
      name: obj.itemName,
      mods: obj.mods,
      seat_numbers: obj.seat_numbers.slice(), // shallow copy to prevent carrying the memory reference over
      pretax_cents: menuItem.pretax_cents,
      tax_cents: menuItem.tax_cents,
      tax_fraction: menuItem.tax_fraction,
    };

    return new CartItem(cart, newObj);
  }

  /**
   * @returns {string} The ID of the Cart Item
   */
  getId() {
    return this.id;
  }

  getTotal = () =>
    (this.api.main_customer.tax_inclusive_pricing
      ? this.getPretaxTotal() + this.getTaxTotal()
      : this.getPretaxTotal()) / 100;

  getName() {
    return this.name || this.name_for_bartender;
  }

  getCreatedTime() {
    return this.cart.time;
  }

  getPretaxTotal(taxOnly) {
    const field = taxOnly ? 'tax_cents' : 'pretax_cents';
    let sum = this[field];
    for (const groupId in this.mods) {
      sum += this.mods[groupId].reduce(
        (tot, modifier) =>
          (tot += modifier.selected ? modifier.getPretaxTotal() * modifier.qty : 0),
        0,
      );
    }
    return sum;
  }

  getTaxTotal() {
    let sum = this.tax_cents;
    for (const groupId in this.mods) {
      sum += this.mods[groupId].reduce(
        (tot, modifier) => (tot += modifier.selected ? modifier.getTaxTotal() * modifier.qty : 0),
        0,
      );
    }
    return sum;
  }

  getModifiers() {
    return this.mods;
  }

  getModifierString() {
    if (!this.mods) return null;
    let modsString = '';
    let index = 0;
    const l = Object.keys(this.mods).length;
    for (const heading_id in this.mods) {
      const modList = this.mods[heading_id];
      modList.forEach(mod => {
        if (mod.selected) {
          modsString += mod.getModifierString();
        }
      });
      if (index !== l - 1) {
        modsString += ', ';
      }
      index++;
    }
    return modsString;
  }

  getHash() {
    let string = this.getName().replace(/\W/g, '');
    const mods = [...this.mods]; // Don't want to sort the actual mods, just a copy for reference
    mods
      .sort((a, b) => a.name > b.name)
      .forEach(i => {
        string += `_${i.name.replace(/\W/g, '')}`;
      });
    string += this.special_instructions;
    return sh.unique(string);
  }

  get menuItem() {
    return this.api.menuData.menuItemsById[this.menuItemId];
  }

  get displayed_pretax_cents() {
    if (this.hasOwnProperty('lineitem_pretax_cents') && this.lineitem_pretax_cents != null) {
      return this.lineitem_pretax_cents;
    }
    return this.frontend_post_discount_cents;
  }

  get displayed_tax_cents() {
    if (this.lineitem_tax_cents != null) {
      return this.lineitem_tax_cents;
    }
    let total = this.tax_cents;
    if (typeof this.mods === 'object') {
      // nested modifiers
      for (const groupId in this.mods) {
        total += this.mods[groupId].reduce(
          (sum, modifier) => (sum += modifier.selected ? modifier.getTaxTotal() : 0),
          0,
        );
      }
    } else {
      // non-nested modifiers
      if (this.mods) total += this.mods.reduce((sum, mod) => (sum += mod.tax_cents), 0);
    }
    return total * this.qty;
  }

  set displayed_tax_cents(val) {} // do nothing

  get frontend_post_discount_cents() {
    if (this.hasOwnProperty('lineitem_pretax_cents') && this.lineitem_pretax_cents != null) {
      return this.lineitem_pretax_cents;
    }
    return this.frontend_pre_discount_cents; // no discounts;
  }

  set frontend_post_discount_cents(val) {} // do nothing

  get frontend_pre_discount_cents() {
    if (this.hasOwnProperty('lineitem_pretax_cents') && this.lineitem_pretax_cents != null) {
      // Price came from server. Undo the effects of discounts, to back-calculate the pre-discount price to display.
      return this.lineitem_pretax_cents - _.sumBy(this.discounts, 'cents_added');
    }
    let total = this.pretax_cents;

    for (const groupId in this.mods) {
      this.mods[groupId].forEach(modifier => {
        if (modifier.selected) total += modifier.getPretaxTotal() * modifier.qty;
      });
    }
    return total * this.qty;
  }

  set frontend_pre_discount_cents(val) {}

  /**
   * @returns {boolean} True if this Cart Item has a non empty list of mods.
   */
  hasMods() {
    return Object.keys(this.mods).length > 0;
  }

  isValid() {
    for (let i = 0; i < this.menuItem.modifier_groups.length; i++) {
      const group = this.menuItem.modifier_groups[i];
      const selected = this.mods[group.id] ? this.mods[group.id].filter(m => m.selected) : [];
      if (!selected.every(m => this.api.menuData.modifiersById[m.id].is_fulfillable)) return false;
      const qtyOfSelectedMods = selected.reduce((total, mod) => (total += mod.qty), 0);
      if (
        group.min_selected &&
        (qtyOfSelectedMods < group.min_selected || qtyOfSelectedMods > group.max_selected)
      ) {
        return false;
      }
    }

    for (const k in this.mods) {
      if (this.mods[k].find(m => !m.isValid())) return false;
    }
    return this.special_instruction_config?.required ? !!this.special_instructions : true;
  }

  locateErrors() {
    const errors = [];
    for (let i = 0; i < this.menuItem.modifier_groups.length; i++) {
      const group = this.menuItem.modifier_groups[i];
      const selected = this.mods[group.id] ? this.mods[group.id].filter(m => m.selected) : [];
      if (
        group.min_selected &&
        (selected.length < group.min_selected || selected.length > group.max_selected)
      ) {
        errors.push(this);
        this.errors[group.id] = [];
      }
    }

    for (const k in this.mods) {
      if (this.mods[k].find(m => !m.isValid())) return false;
    }
    return this.special_instruction_config?.required ? !!this.special_instructions : true;
  }

  isCurrentLevelValid() {
    return new Promise((resolve, reject) => {
      const menuItem = this.api.menuData.menuItemsById[this.menuItemId];

      // Traverse all the modifier groups even ones that dont have selections yet
      // and determine if there are the necessary amount of selections for each
      menuItem.modifier_groups.forEach(group => {
        const groupId = group.id;
        const selected = this.mods[groupId]?.filter(m => m.selected) || [];
        const qtyOfSelectedMods = selected.reduce((total, mod) => (total += mod.qty), 0);
        // We dont check group.max_selected here because we check that when a user selected an item
        if (qtyOfSelectedMods < group.min_selected || qtyOfSelectedMods > group.max_selected) {
          const error = new Error(
            `Group "${group.heading_name}" requires you to "${group.description}" before proceeding.`,
          );
          error.group = group;
          reject(error);
        }
      });
      resolve(true);
    });
  }

  /**
   * @returns {Object} A JSON repr of this CartItem model
   */
  toJSON() {
    const obj = super.toJSON();
    obj.mods = {};
    for (const id in this.mods) {
      obj.mods[id] = this.mods[id].map(mod => mod.toJSON());
    }
    return obj;
  }

  setQuantity(newQty) {
    this.qty = newQty;
    if (this._parts.length) this._parts[0].numerator = newQty;
  }
}
