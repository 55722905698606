import * as Colors from './Colors';
import * as Typography from './Typography';

export const formField__base = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: 20,
  marginLeft: 0,
  borderBottomWidth: 0,
};

export const formField__inputBase = {
  width: '100%',
  padding: 10,
  borderWidth: 1,
  borderColor: Colors.lightTheme.grayscale.line,
};

export const formField = {
  ...formField__base,
};

export const formField__input = {
  ...formField__inputBase,
};

export const formField__label = {
  ...Typography.label,
  color: Colors.lightTheme.grayscale.label,
  marginBottom: 8,
};

export const formField__hint = {
  ...Typography.label,
  color: Colors.lightTheme.grayscale.placeholder,
  fontWeight: 'normal',
  marginTop: 4,
  marginBottom: 4,
};
