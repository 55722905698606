import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

// Components
import { View, Text } from 'native-base';
import { ScrollView } from 'react-native';
import MenuSelection from './MenuSelection';
import Grid from '../../bbot-component-library/POS/Grid';
import CartModifier from '../../models/CartModifier';

// Styles
import { ModifierGroupStyles as styles } from '../../styles/POSStyles';

// Util functions
import { getDefaultModIds, getInitialModIds, getModifier } from './utils';

function ModifierGroup({
  onSelectedModifiersChange,
  onNestedModifiersChange,
  initialModIds,
  cartItemId,
  parentModifierId,
  cartItemModifiers,
  modifierGroup,
  modifierGroup: { id, mods, modifiers, min_selected, max_selected, heading_name },
}) {
  // Example of a nested modifier. This is how the selectedModifierIds will be laid out.
  // We are being more explicit/verbsoe than we need to be because of the increased complexity from nested modifiers.
  // sausage-cased keys below are examples and will be different in practice, while camelCased keys will be exactly how you see them.
  // {
  //   mod-id-1-no-nested-mods-1234: null,    <---- Notice that modifiers with no nested mods are just mapped to null
  //   mod-id-2-with-nested-mods-5678: {      <---- Mods with nested mods map to an objet that organizes those nested mods by heading
  //     selectedModsByHeadingId: {
  //       heading-id-1: {
  //         modifiers: {
  //           mod-id-3-no-nested-1353: null,
  //           mod-id-4-no-nested-8539 null,
  //           mod-id-5-nested-mods-8589: {
  //             selectedModsByHeadingId: {
  //               heading-id-2: {
  //                 modifiers: {
  //                   mod-id-6-no-nested-0989: null,
  //                   mod-id-7-no-nested-7848: null,
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  const [selectedModifierIds, setSelectedModifierIds] = useImmer({});
  const [parentCartItemId, setParentCartItemId] = useState(null);

  const { Container, HeadingName, SubHeader, SubHeaderChoiceRequired, Body, SubHeaderError } =
    styles;

  const choicesText = `Choices: ${Object.keys(selectedModifierIds).length}${
    min_selected ? `/${min_selected}` : ''
  }`;

  const showErrorState =
    Object.keys(selectedModifierIds).length < min_selected ||
    Object.keys(selectedModifierIds).length > max_selected;

  const textStyle = showErrorState ? SubHeaderError : SubHeaderChoiceRequired;

  const availableNestedModifiers = Object.keys(selectedModifierIds).reduce(
    (nestedModifiersAggregate, parentModifierId) => ({
      ...nestedModifiersAggregate,
      [parentModifierId]: getModifier(parentModifierId)?.modifier_groups,
    }),
    {},
  );

  /**
   * This has a nested reduce() call! This iterates through the initialModifierIds, checks if each modifier has nested mods under it.
   * If so, we create a parallel object to represent it, as deep as the nesting goes.
   * In the end, we should have a javascript object that represents the modifier data structure accurately, nesting and all.
   * @param {} initialModifierIds
   * @returns
   */
  const createDefaultModObj = initialModifierIds =>
    initialModifierIds.reduce((selectedModifierIds, modifierId) => {
      // Below chunk for nested modifiers
      const { modifier_groups: modifierGroups } = getModifier(modifierId);
      const modifierGroupsObj = {
        selectedModsByHeading: modifierGroups.reduce(
          (modifierGroupAggregate, modifierGroup) => ({
            ...modifierGroupAggregate,
            [modifierGroup.id]: { modifiers: {}, name: modifierGroup.heading_name },
          }),
          {},
        ),
      };

      // If no nested mods, map modifier id to null
      return {
        ...selectedModifierIds,
        [modifierId]: modifierGroups.length ? modifierGroupsObj : null,
      };
    }, {});

  /**
   * Set the initial default modifiers
   */
  useEffect(() => {
    if (cartItemId !== parentCartItemId) {
      if (initialModIds) {
        setSelectedModifierIds(() => createDefaultModObj(initialModIds));
        setParentCartItemId(cartItemId);
      }
    }
  }, [initialModIds, cartItemId]);

  useEffect(() => {
    if (parentModifierId) {
      onNestedModifiersChange(parentModifierId, id, selectedModifierIds);
    } else {
      onSelectedModifiersChange(id, buildModifiersFromSelected(selectedModifierIds));
    }
  }, [selectedModifierIds, parentModifierId]);

  /**
   * Recursive! Builds CartModifier objects that have the mods field filled out correctly.
   * Builds the top level mods, then explores the nested mods, builds those, and attaches to the mods field.
   * @param {*} modifierIds The nested modifier field that tracks the currently selected mods and nested mods.
   * @returns
   */
  const buildModifiersFromSelected = modifierIds => {
    if (!Object.keys(modifierIds).length) {
      return [];
    }

    const modifiers = [];
    for (const selectedModifierId in modifierIds) {
      const modifier = new CartModifier(null, getModifier(selectedModifierId));
      const nestedModifierGroups = modifierIds[selectedModifierId]?.selectedModsByHeading;

      // Use recursion if needed, i.e. modifierIds[selectedModifierId is not null.
      if (nestedModifierGroups) {
        const modsEntries = Object.entries(nestedModifierGroups).map(
          ([modifierGroupId, { modifiers }]) => [
            modifierGroupId,
            buildModifiersFromSelected(modifiers),
          ],
        );
        modifier.mods = Object.fromEntries(modsEntries);
      }

      modifiers.push(modifier);
    }
    return modifiers;
  };

  /**
   * This is called by the nested ModifierGroup to update this current state.
   * @param {*} parentModifierId
   * @param {*} modifierGroupId
   * @param {*} modifierIds
   */
  const onNestedModsSelected = (parentModifierId, modifierGroupId, modifierIds) => {
    setSelectedModifierIds(draft => {
      draft[parentModifierId].selectedModsByHeading[modifierGroupId].modifiers = modifierIds;
    });
  };

  /**
   * When mods are selected/deselected, call the callback function to let parent know.
   */
  const selectModifierIds = modifierIds => {
    setSelectedModifierIds(createDefaultModObj(modifierIds));
  };

  /**
   * Handles clicking a modifier (M) in the grid
   * If the Mod Group (MG) requires a selection and allows max 1 selection, this should deselect all other selections.
   * @param {*} modifier
   */
  const handleClickModifier = modifier => {
    const { menuItemId } = modifier;
    // If one is required, treat as radio button. Replace selectedModifierIds with current id.
    if (min_selected === 1 && max_selected === 1) {
      selectModifierIds([menuItemId]);
      return;
    }

    // If one is allowed (max_selected 1) and user isn't click to toggle off, treat like radio button
    if (max_selected === 1 && !Object.keys(selectedModifierIds).includes(menuItemId)) {
      selectModifierIds([menuItemId]);
      return;
    }

    // if already selected, de-select it
    if (Object.keys(selectedModifierIds).includes(menuItemId)) {
      selectModifierIds(Object.keys(selectedModifierIds).filter(modId => modId !== menuItemId));
    } else if (Object.keys(selectedModifierIds).length < max_selected) {
      selectModifierIds([...Object.keys(selectedModifierIds), menuItemId]);
    }
  };

  const getNestedCartItemModifiers = (
    cartItemMods,
    currentModifierGroupId,
    nestedParentModifierGroupId,
  ) =>
    cartItemMods &&
    cartItemMods[currentModifierGroupId]?.find(
      cartModifier => cartModifier.id === nestedParentModifierGroupId,
    )?.mods;

  return (
    <View style={Container}>
      <View>
        <Text style={HeadingName}>{modifierGroup.heading_name}</Text>
      </View>

      <View style={SubHeader}>
        <Text style={textStyle}>{choicesText}</Text>
        <Text style={textStyle}>{`${
          modifierGroup.min_selected > 0 ? 'Required' : 'Optional'
        }`}</Text>
      </View>

      <ScrollView style={Body}>
        <Grid
          renderItem={({ item: modifier }) => (
            <MenuSelection
              key={modifier.menuItemId}
              name={modifier.name_for_bartender}
              selected={Object.keys(selectedModifierIds).includes(modifier.menuItemId)}
              onPress={() => handleClickModifier(modifier)}
            />
          )}
          data={modifiers}
          keyExtractor={modifier => modifier.menuItemId}
        />

        {Object.entries(availableNestedModifiers).map(([parentModifierId, nestedModifierGroups]) =>
          nestedModifierGroups.map(nestedModifierGroup => (
            <ModifierGroup
              modifierGroup={nestedModifierGroup}
              key={nestedModifierGroup.id}
              onNestedModifiersChange={onNestedModsSelected}
              parentModifierId={parentModifierId}
              initialModIds={
                cartItemId
                  ? getInitialModIds(
                      getNestedCartItemModifiers(cartItemModifiers, id, parentModifierId),
                      nestedModifierGroup,
                    )
                  : getDefaultModIds(nestedModifierGroup)
              }
              cartItemId={cartItemId}
              cartItemModifiers={getNestedCartItemModifiers(
                cartItemModifiers,
                id,
                parentModifierId,
              )}
            />
          )),
        )}
      </ScrollView>
    </View>
  );
}

export default ModifierGroup;
