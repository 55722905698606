import { createStackNavigator } from 'react-navigation-stack';
import React from 'react';
import OrderSearchScreen from '../screens/OrderSearchScreen';
import { defaultNavigationOptions } from './Defaults';
import TabBarIcon from '../components/TabBarIcon';
import TabBarLabel from '../components/TabBarLabel';

const ordersStack = createStackNavigator(
  {
    OrderSearchScreen,
  },
  {
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__orderSearch"
        />
      ),
      tabBarTestID: 'ordersearch',
      tabBarIcon: props => (
        <TabBarIcon
          {...props}
          name="md-search"
        />
      ),
    }),
    defaultNavigationOptions,
  },
);

export default ordersStack;
