import React, { useState } from 'react';
import { Icon, Text, View, IconButton } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import {
  FontAwesome5,
  MaterialCommunityIcons,
  AntDesign,
  FontAwesome,
  Octicons,
} from '@expo/vector-icons';
import { Body, ListItem, Left, Right } from '../../bbot-component-library';
import { formatDate } from '../../helpers/HelperFunctions';
import Colors from '../../constants/Colors';
import API from '../../api';
import Loader from '../Loader';

const TYPE_ICONS = {
  info: (
    <Icon
      as={FontAwesome5}
      name="info-circle"
      style={{ color: 'black' }}
    />
  ),
  warning: (
    <Icon
      as={FontAwesome}
      name="warning"
    />
  ),
  cutlery: (
    <Icon
      as={MaterialCommunityIcons}
      name="silverware-clean"
    />
  ),
  napkins: (
    <Icon
      as={Octicons}
      name="stack"
    />
  ),
  water: (
    <Icon
      as={MaterialCommunityIcons}
      name="cup-water"
    />
  ),
  service: (
    <Icon
      as={MaterialCommunityIcons}
      name="room-service"
    />
  ),
  box: (
    <Icon
      as={MaterialCommunityIcons}
      name="food-takeout-box-outline"
    />
  ),
  custom: (
    <Icon
      as={AntDesign}
      name="message1"
    />
  ),
};

function NotificationView({ notification, mode }) {
  const unread = notification.status === 'active';
  const titleStyle = styles[unread ? 'unread' : 'read'];
  const [lastModified, setLastModified] = useState(notification.last_modified);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  if (notification.status === 'dismissed') return null;

  const onDismiss = async () => {
    setLoading(true);
    const result = await API.setNotificationState(notification, 'dismissed');
    if (!result.success) {
    }
    /* else {
      setLastModified(result.notification.last_modified);
      setLoading(false);
    } */
  };

  const markRead = async () => {
    if (!notification.time_read) {
      const result = await API.setNotificationState(notification, 'read');
      setLastModified(result.notification.last_modified);
    }
    setExpanded(!expanded);
  };

  const icon = TYPE_ICONS[notification.type.toLowerCase()] ?? TYPE_ICONS.service;

  return (
    <ListItem
      onPress={markRead}
      style={styles.listItem}
    >
      {loading && <Loader />}
      <Left style={[styles.left]}>{icon}</Left>
      <Body>
        <Text style={titleStyle}>{notification.title}</Text>
        <Text
          note
          numberOfLines={expanded ? null : 2}
          ellipsizeMode="tail"
        >
          {notification.body}
        </Text>
      </Body>
      <Right>
        <Text
          numberOfLines={1}
          note
        >
          {formatDate(notification.time_created)}
        </Text>
        {unread ? (
          <IconButton
            icon={
              <Icon
                as={Octicons}
                size={22}
                name="dot-fill"
                style={{ color: Colors.unreadNotification }}
              />
            }
            variant="ghost"
            onPress={markRead}
          />
        ) : (
          <IconButton
            icon={
              <Icon
                as={FontAwesome5}
                size={22}
                name="trash"
              />
            }
            variant="ghost"
            onPress={onDismiss}
          />
        )}
      </Right>
    </ListItem>
  );
}

export default NotificationView;

const styles = EStyleSheet.create({
  listItem: {
    marginHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
  },
  left: {
    marginRight: 20,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unread: {
    color: Colors.unreadNotification,
  },
  read: {
    color: 'black',
  },
});
