import { Badge, Icon, Pressable } from 'native-base';
import { ActivityIndicator } from 'react-native';
import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Ionicons } from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import Alert from './Alert';
import Colors from '../constants/Colors';

function IconButton({
  color = Colors.light,
  testID,
  badge,
  badgeProps = { colorScheme: 'primary' },
  onPress,
  icon,
  iconType = Ionicons,
  label,
  processing,
  disabled,
  disabledMsg,
  shown = true,
  style,
  defaultMessage,
  values,
}) {
  if (!shown) return null;
  const textColor = disabled || processing ? Colors.disabled : color;

  const onBtnPress = () => {
    if (disabled) {
      if (disabledMsg) Alert.alert(disabledMsg);
    } else {
      onPress();
    }
  };

  const getIcon = () => {
    if (React.isValidElement(icon)) return React.cloneElement(icon, { color: textColor });
    return (
      <Icon
        name={icon}
        as={iconType}
        size={6}
        style={{
          color: textColor,
          marginVertical: 3,
          alignItems: 'center',
          width: 26,
        }}
      />
    );
  };

  return (
    <Pressable
      testID={testID}
      onPress={onBtnPress}
      style={[IconButton.styles.buttonStyle, style]}
      disabled={disabled && !disabledMsg}
    >
      {!!badge && !processing && (
        <Badge
          style={IconButton.styles.badgeStyle}
          _text={{ color: textColor }}
          rounded="full"
          zIndex={1}
          variant="solid"
          {...badgeProps}
        >
          {badge}
        </Badge>
      )}
      {processing ? <ActivityIndicator color={color} /> : getIcon()}
      {!!label && (
        <FormattedMessage
          id={label}
          values={values}
          defaultMessage={defaultMessage}
          style={{ textAlign: 'center', lineHeight: 15, color: textColor }}
          numberOfLines={1}
        />
      )}
    </Pressable>
  );
}

export default IconButton;

IconButton.styles = EStyleSheet.create({
  buttonStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  badgeStyle: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    marginTop: -25,
    marginRight: -30,
    zIndex: 1000,
  },
});
