import React from 'react';
import { Dimensions, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { back } from '@react-navigation/core/lib/commonjs/NavigationActions';
import FolderTab from './FolderTab';
import DraggableScrollView from './DraggableScrollView';
import Colors from '../constants/Colors';

export default class FolderTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: React.Children.toArray(props.children)[1],
      viewWidth: Dimensions.get('window').width,
    };

    this.tabPositions = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.children !== this.props.children) {
      const active = React.Children.toArray(this.props.children).find(child => child.props.active);
      this.setState({
        activeTab: active || React.Children.toArray(this.props.children)[1],
      });
    } else if (this.props.activeTab && this.props.activeTab !== this.state.activeTab) {
      this.setState({
        activeTab: this.props.activeTab,
      });
    }
  }

  _tabs(children = this.props.children) {
    return children.filter(child => child?.type?.displayName === 'FolderTab'); // React.Children.map(children, child => child);
  }

  get tabLeft() {
    const { children } = this.props;
    return children[0];
  }

  get tabRight() {
    const { children } = this.props;
    return children.filter(child => child?.type?.displayName === 'TabRight');
  }

  render() {
    const child = React.Children.only(this.state.activeTab.props.children);
    return (
      <View
        style={{ flex: 1 }}
        onLayout={this._onLayout}
      >
        {this._tabBar()}
        <View style={{ flex: 1 }}>{child}</View>
      </View>
    );
  }

  _onLayout = ({ nativeEvent }) => {
    this.setState({
      viewWidth: nativeEvent.layout.width,
    });
  };

  _tabsScroller = () => (
    <DraggableScrollView
      horizontal
      scrollRef={sv => (this._scrollview = sv)}
      contentContainerStyle={{ marginHorizontal: 8 }}
    >
      {this._tabs().map((tab, index, tabs) => {
        if (tab?.type?.displayName !== 'FolderTab') return tab;
        const { activeTab } = this.state;
        const activeIndex = tabs.findIndex(t => activeTab.props.heading === t.props.heading);
        // We aren't the last tab, and we aren't to the left of the active tab:
        const showRightBorder = index < tabs.length - 1 && activeIndex !== index + 1;
        return (
          <FolderTab
            key={tab.props.heading}
            heading={tab.props.heading}
            count={tab.props.count}
            activeColor={tab.props.activeColor}
            active={activeIndex === index}
            showRightBorder={showRightBorder}
            onLayout={({ nativeEvent }) => {
              this.tabPositions[index] = nativeEvent.layout.x;
            }}
            onPress={() => {
              this._scrollview.scrollTo({ x: this.tabPositions[index] - 75 });
              this._setActive(tab);
              if (tab.props.onPress) tab.props.onPress(index);
            }}
          />
        );
      })}
    </DraggableScrollView>
  );

  _setActive = tab => {
    const { onTabChange } = this.props;
    this.setState({
      loading: true,
      activeTab: tab,
    });
    if (onTabChange) onTabChange(tab);
  };

  setActive = tabIndex => {
    const { children } = this.props;
    const tab = React.Children.toArray(children)[tabIndex];

    this._setActive(tab);
  };

  getActiveTabName = () => {
    const { activeTab } = this.state;
    return activeTab.props.heading;
  };

  /**
   * Formats the TabBar depending on Screen Width
   * @returns {*}
   * @private
   */
  _tabBar = () => {
    const { viewWidth } = this.state;
    const numTabs = this._tabs().length;
    const wrapWidth = numTabs <= 3 ? 800 : 1000;
    if (viewWidth <= wrapWidth) {
      return (
        <View style={[{ backgroundColor: Colors.primary }, this.props.style]}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingVertical: 5,
            }}
          >
            {this.tabLeft}
            {this.tabRight}
          </View>
          <View style={{ backgroundColor: Colors.primary }}>{this._tabsScroller()}</View>
        </View>
      );
    }
    return (
      <View style={[styles.tabBar, { flexDirection: 'row' }, this.props.style]}>
        {this.tabLeft}
        <View style={{ overflow: 'hidden' }}>{this._tabsScroller()}</View>
        {this.tabRight}
      </View>
    );
  };
}

const styles = EStyleSheet.create({
  tabBar: {
    paddingLeft: 10,
    backgroundColor: Colors.primary,
  },
});
