import React from 'react';
import { Icon } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

export const defaultNavigationOptions = {
  headerStyle: {
    backgroundColor: '#000000',
  },
  headerTitleStyle: {
    color: '#fff',
  },
  headerTintColor: '#ccc',

  // Fixes issue with back arrow disappearing on iOS:
  headerBackImage: () => (
    <Icon
      name="arrow-back"
      as={MaterialIcons}
      style={{ color: '#ccc' }}
      size={6}
    />
  ),
};
