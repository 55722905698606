import React, { useContext } from 'react';
import { View } from 'react-native';
import POSContext from '../../../contexts/POSContext';
import { PAYMENT_TYPE } from '../../../screens/POS/Checkout/Checkout';
import { PaymentEntryColStyles } from '../../../styles/POSStyles';
import StripeProvider from '../../StripeElements/StripeProvider';
import CreditCardEntry from './CreditCardEntry';

function PaymentEntryCol() {
  const {
    POSValues: { selectedPaymentType },
  } = useContext(POSContext);

  const getPaymentEntryContent = paymentType => {
    switch (paymentType) {
      case PAYMENT_TYPE.CREDIT_CARD:
        return <CreditCardEntry manual={false} />;
      case PAYMENT_TYPE.MANUAL_CREDIT_CARD_ENTRY:
        return <CreditCardEntry manual />;
      default:
      // TODO: Implement below!
      // case PAYMENT_TYPE.CASH:
      //   return <CashEntry />;
      // case PAYMENT_TYPE.SPLIT_PAYMENT:
      //   return <SplitPayment />;
    }
  };

  return (
    <StripeProvider>
      <View style={PaymentEntryColStyles.PaymentEntryCol}>
        {getPaymentEntryContent(selectedPaymentType)}
      </View>
    </StripeProvider>
  );
}

export default PaymentEntryCol;
