import React from 'react';
import { Row } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';

function Left({ children, ...props }) {
  const isIcon = props.hasOwnProperty('icon');
  return (
    <Row
      style={[styles.left, isIcon && styles.icon]}
      {...props}
    >
      {children}
    </Row>
  );
}

const styles = EStyleSheet.create({
  left: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
  },
  icon: {
    flex: -1,
    alignItems: 'center',
    marginRight: 25,
    marginLeft: 5,
  },
});

export default Left;
