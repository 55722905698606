import React from 'react';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { defaultNavigationOptions } from './Defaults';

import KDSTabNavigator from './KDSTabNavigator';
import MainTabNavigator from './MainTabNavigator';
import { SHOW_SERVER_CHOOSER } from '../constants/Config';
import AuthLoadingScreen from '../screens/AuthLoadingScreen';
import CheckConnectionScreen from '../screens/CheckConnectionScreen';
import ConfigChooser from '../screens/ConfigChooser';
import CustomerChooser from '../screens/CustomerChooser';
import DeviceRegistration from '../screens/DeviceRegistration';
import LandingPage from '../screens/LandingPage';
import LoginScreen from '../screens/LoginScreen';
import PinScreen from '../screens/PinScreen/PinScreen';
import ServerChooser from '../screens/ServerChooser';
import StripeConfig from '../screens/StripeConfig';
import POSSwitchNavigator from './POSSwitchNavigator';

const AuthStack = createSwitchNavigator({
  AuthLoading: AuthLoadingScreen,
  Login: LoginScreen,
  CheckConnectionScreen,
});

// Create stacks so that we have a Header bar
const CustomerStack = createStackNavigator({ CustomerChooser }, { defaultNavigationOptions });
const ConfigStack = createStackNavigator({ ConfigChooser }, { defaultNavigationOptions });
const DeviceStack = createStackNavigator({ DeviceRegistration }, { defaultNavigationOptions });
const ReaderStack = createStackNavigator(
  { StripeConfigInit: StripeConfig },
  { defaultNavigationOptions },
);
const ServerStack = createStackNavigator({ ServerChooser }, { defaultNavigationOptions });

const AppNavigator = createSwitchNavigator(
  {
    // You could add another route here for authentication.
    // Read more at https://reactnavigation.org/docs/en/auth-flow.html
    ServerStack,
    Auth: AuthStack,
    Pin: PinScreen,
    CustomerChooser: CustomerStack,
    ConfigChooser: ConfigStack,
    DeviceStack,
    ReaderStack,
    Landing: LandingPage,
    Main: MainTabNavigator,
    KDS: KDSTabNavigator,
    POS: POSSwitchNavigator,
  },
  {
    defaultNavigationOptions,
    initialRouteName: 'Auth',
  },
);

export default createAppContainer(AppNavigator);
