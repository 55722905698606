import React from 'react';
import { Row } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';

function Right({ children, ...props }) {
  const onListItem = props.hasOwnProperty('onListItem');
  return (
    <Row
      style={[styles.right, onListItem && styles.onListItem]}
      {...props}
    >
      {children}
    </Row>
  );
}

const styles = EStyleSheet.create({
  right: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  onListItem: {
    flex: -1,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export default Right;
