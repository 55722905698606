import React, { Component } from 'react';
import { TouchableWithoutFeedback, View, Platform, Modal, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import EStyleSheet from 'react-native-extended-stylesheet';
import PinPad from './PinPad';
import API from '../../api';

/** By storing the defaultPinPadModal intance on the API,
 * editing this file no longer destroys the reference: */

export default class PinPadModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    isGlobal: PropTypes.bool,
  };

  static defaultProps = {
    visible: false,
    onClose: () => {},
    onSuccess: () => {},
  };

  state = {
    visible: false,
  };

  static show = (onSuccess, onClose) => {
    if (API.defaultPinPadModalInstance) {
      API.defaultPinPadModalInstance.show(onSuccess, onClose);
    }
  };

  componentDidMount() {
    const { isGlobal } = this.props;
    if (isGlobal) {
      API.defaultPinPadModalInstance = this;
    }
  }

  componentWillUnmount() {
    API.defaultPinPadModalInstance = null;
  }

  render() {
    let { visible, isGlobal } = this.props;
    if (isGlobal) visible = this.state.visible;
    return (
      <Modal
        testID="pin-pad-modal"
        ref={modal => (this._modal = modal)}
        hardwareAccelerated
        visible={visible}
        onRequestClose={this._onClose}
        transparent
        onShow={() => {
          if (isGlobal && Platform.OS === 'web') {
            this._modal.parentNode.parentNode.style.zIndex = '11000';
          }
        }}
        animationType="none"
        statusBarTranslucent
      >
        <TouchableWithoutFeedback onPress={this._onClose}>
          <View
            style={style.background}
            nativeID="pinPad"
          >
            <TouchableWithoutFeedback onPress={() => {}}>
              <View style={{ borderRadius: 30, overflow: 'hidden', maxHeight: '100%' }}>
                <ScrollView style={{ maxHeight: 510 }}>
                  <PinPad
                    ref={me => (this._pinPad = me)}
                    onSuccess={this._onPinSuccess}
                  />
                </ScrollView>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  show = (cb, onClose) => {
    this._callback = cb;
    this._closeCallback = onClose;
    this.setState(
      {
        visible: true,
      },
      () => {
        this._pinPad.focus();
      },
    );
  };

  _onPinSuccess = user => {
    const { onClose, onSuccess, isGlobal } = this.props;

    if (isGlobal && this._callback) {
      this._callback(user);
      this._onClose();
    } else {
      onClose(true);
      onSuccess(user);
    }
  };

  _onClose = () => {
    const { onClose, isGlobal } = this.props;
    if (this._pinPad) this._pinPad.clear();
    if (isGlobal) {
      this.setState({
        visible: false,
      });
      if (this._closeCallback) this._closeCallback();
    } else {
      onClose();
    }
  };
}

const style = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});
