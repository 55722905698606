import React from 'react';
import { View, ActivityIndicator, StyleSheet, Platform } from 'react-native';
import _ from 'lodash';
import { Button, Text, Icon, Badge, Row } from 'native-base';
import {
  AntDesign,
  Entypo,
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import API from '../api';
import OrderCreator from './OrderCreator';
import HeaderIconButton from '../components/HeaderIconButton';
import { Colors } from '../styles';
import { Header } from '../bbot-component-library';

// Different browsing tabs for location overview page
import OrdersView from '../components/Order/OrdersView';
import ConsumerTabsView from '../components/ConsumerTab/ConsumerTabsView';
import SmartOrdersView from '../components/SmartOrders/SmartOrdersView';
import { checkAccess } from '../helpers/Access';
import IconButton from '../components/IconButton';

/**
 *
 * ONLY COMBINE ITEMS IF IN SAME ORDER?
 * This Screen is really slow and needs optimization
 */

export default class LocationOverview extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const location = navigation.getParam('location');
    const handlePress = navigation.getParam('handleGoToOrderCreator');
    const { config } = API;

    return {
      title: location.locationName,
      headerRight: () =>
        config?.show_create_order ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ActivityIndicator
              animating={!location.fulfillable_items}
              color={Colors.primary}
            />
            <HeaderIconButton
              type={FontAwesome5}
              textPath={
                location.order_allowed !== 'off' ? 'button__blockOrdering' : 'button__allowOrdering'
              }
              name={`${location.order_allowed !== 'off' ? 'Block' : 'Allow'} Ordering`}
              icon={location.order_allowed !== 'off' ? 'lock' : 'unlock'}
              onPress={async () => {
                const hasAccess = await checkAccess('toggle_location_order_allowed', true);
                if (hasAccess) {
                  API.setLocationOrderAllowed(
                    {},
                    [location],
                    location.order_allowed !== 'on',
                    hasAccess.user.id,
                  ).then(res => {
                    navigation.setParams({ location });
                  });
                }
              }}
            />
            <HeaderIconButton
              testID="locationHeaderIconButtonNewOrder"
              type={MaterialCommunityIcons}
              textPath="button__newOrder"
              name="New Order"
              onPress={handlePress}
              disabled={!location.fulfillable_items}
              icon="food"
            />
          </View>
        ) : null,
    };
  };

  constructor(props) {
    super(props);

    const location = props.navigation.getParam('location');

    if (!location) {
      props.navigation.navigate('Main');
      return;
    }

    if (!location.seated_group || (API.config.hide_seated_groups && location.getNumGuests() > 1)) {
      API.clearLocation(location);
    }

    this.state = {
      location,
      orders: location.orders,
      smart_orders: location.smart_orders.filter(so => so.incart?.length > 0),
      old_orders: location.order_history,
      open_tabs: location.open_tabs,
      items: [],
      menuData: API.getMenu(),
      breadcrumbs: [],
      numGuests: 0,
      showNumGuestsChooser: false,
      currentTab: location.allow_consumer_tabs ? 'tabs' : 'current-orders',
    };
    props.navigation.setParams({
      handleGoToOrderCreator: this._goToOrderCreator,
    });
  }

  componentDidMount() {
    this._mounted = true;

    this._updateLocationDetails();

    const debouncedRefresh = _.debounce(this.refresh, 50);
    // TODO: Each component should manage their own listeners
    API.on('orders', debouncedRefresh, 'LocationOverview');
    API.on('smart_orders', debouncedRefresh);
    API.on('tabs', debouncedRefresh);
    API.on('seated_groups', debouncedRefresh);
  }

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this._timeout);
  }

  _updateLocationDetails = async () => {
    const { location } = this.state;
    try {
      await API.getLocationDetails(location);
      this.refresh();
    } catch (err) {}
    // setup next run:
    if (this._mounted) this._timeout = setTimeout(this._updateLocationDetails, 10000);
    if (module.hot) {
      module.hot.dispose(() => clearTimeout(this._timeout));
    }
  };

  // Current Orders Specific
  refresh = () => {
    if (!this._mounted) return;
    const { location } = this.state;
    this.setState({
      orders: location.orders,
      old_orders: location.order_history,
      smart_orders: location.smart_orders.filter(so => so.incart?.length > 0),
      open_tabs: location.open_tabs,
      loading: false,
    });
  };

  changePageTab = key => {
    this.setState({
      currentTab: key,
    });
  };

  render() {
    const { currentTab, old_orders, smart_orders, orders, open_tabs, location } = this.state;

    return (
      <View
        style={{ borderTopWidth: 1, borderColor: 'gray', flex: 1 }}
        testID={`locationOverview-${location.id}`}
      >
        <Header>
          <IconButton
            testID="locationsOverviewCurrentOrdersButton"
            label="common__currentOrders"
            defaultMessage="Current Orders"
            badge={orders.length > 0 ? orders.length : null}
            badgeProps={{ colorScheme: 'info' }}
            onPress={() => this.changePageTab('current-orders')}
            icon="list"
            iconType={Entypo}
            style={currentTab === 'current-orders' ? styles.activeTab : null}
          />
          <IconButton
            testID="locationsOverviewOrderHistoryButton"
            onPress={() => this.changePageTab('order-history')}
            style={currentTab === 'order-history' ? styles.activeTab : null}
            iconType={MaterialIcons}
            icon="history"
            label="common__orderHistory"
            defaultMessage="Order History"
          />
          {location.allow_consumer_tabs && (
            <IconButton
              testID="locationsOverviewConsumerTabsButton"
              onPress={() => this.changePageTab('tabs')}
              style={currentTab === 'tabs' ? styles.activeTab : null}
              iconType={MaterialCommunityIcons}
              icon="cash-multiple"
              label="common__tabs"
              defaultMessage="Tabs"
              badge={open_tabs.length ? open_tabs.length : null}
              badgeProps={{ colorScheme: 'info' }}
            />
          )}
          <IconButton
            testID="locationsOverviewOrderPreviewsButton"
            badge={smart_orders?.length > 0 ? smart_orders.length : null}
            style={currentTab === 'smart-orders' ? styles.activeTab : null}
            onPress={() => this.changePageTab('smart-orders')}
            badgeProps={{ colorScheme: 'info' }}
            label="common__orderPreviews"
            defaultMessage="Order Previews"
            iconType={AntDesign}
            icon="shoppingcart"
          />
        </Header>
        <View style={{ flex: 1 }}>
          {currentTab === 'current-orders' && (
            <OrdersView
              testID="locationOverviewCurrentOrdersView"
              location={location}
              navigation={this.props.navigation}
              orders={orders}
              refresh={this.refresh}
            />
          )}

          {currentTab === 'order-history' && (
            <OrdersView
              testID="locationOverviewOrderHistoryView"
              location={location}
              navigation={this.props.navigation}
              orders={old_orders}
              refresh={this.refresh}
            />
          )}

          {currentTab === 'tabs' && (
            <ConsumerTabsView
              testID="locationOverviewTabsView"
              location={location}
              refresh={this.refresh}
            />
          )}

          {currentTab === 'smart-orders' && (
            <SmartOrdersView
              testID="locationOverviewOrderPreviewsView"
              location={location}
              smart_orders={smart_orders}
            />
          )}
        </View>
      </View>
    );
  }

  _goToOrderCreator = () => {
    const { navigation } = this.props;
    const location = navigation.getParam('location');

    if (!location.seated_group || (API.config.hide_seated_groups && location.getNumGuests() > 1)) {
      API.clearLocation(location);
    }

    navigation.navigate('OrderCreator', {
      location: this.state.location,
      location_overview_key: navigation.state.key,
    });
  };

  _showLoading = () => {
    this.setState({
      loading: true,
    });
  };
}

const styles = StyleSheet.create({
  pageTab: {
    backgroundColor: '#000',
    height: '100%',
    borderRadius: 0,
    flex: 1,
  },
  activeTab: {
    backgroundColor: '#555',
  },
  badge: {
    position: 'absolute',
    bottom: '60%',
    right: '50%',
    marginRight: -30,
    zIndex: 10,
  },
});
