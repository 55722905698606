import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  View,
  FlatList,
  TouchableWithoutFeedback,
  Dimensions,
  ActivityIndicator,
  Platform,
  Modal,
} from 'react-native';

import { Icon, Text } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import { FormattedCurrency, FormattedPlural } from 'react-native-globalize';
import FlashMessage, { showMessage } from 'react-native-flash-message';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import OrderItemStatusChanger from '../OrderItemStatusChanger';
import ScrollButton from '../ScrollButton';

import API from '../../api';
import StatusIcon from '../StatusIcon';
import HelpIcon from '../HelpIcon';
import OrderHelper from '../../helpers/OrderHelper';
import RelatedOrder from '../Order/RelatedOrder';
import { Typography } from '../../styles';
import OrderActionsMenu from '../OrderActionsMenu';
import OrderCheckoutInfo from '../Order/OrderCheckoutInfo';
import OrderStaffNotes from '../Order/OrderStaffNotes';
import OrderTotals from '../Order/OrderTotals';

/**

 * - If you can't select individual items, tapping anywhere scrolls down
 * - Helper text (Scroll down to change item statuses)
 * - Before sending text, show banner ("Sending Text to Customer... " (Cancel))
 * - Change Order and Tab Color of Past orders
 */

const { width: windowWidth } = Dimensions.get('window');

export default class KDSOrderModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onClose: () => {},
  };

  state = {
    order: null,
    hasScroll: false,
    enableScrollUp: false,
    enableScrollDown: false,
    selectedItems: [],
  };

  constructor(props) {
    super(props);
    this.config = API.getConfig();
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this._listener) this._listener.remove();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { visible, order } = this.props;

    if (visible !== prevProps.visible) {
      if (!visible) {
        this.setState({
          selectedItems: [],
        });
      } else {
        if (this._listener) this._listener.remove(); // remove listener if one already exists
        this._listener = order.on('update', () => {
          if (!this._mounted) return;
          this.setState({
            selectedItems: this.state.selectedItems,
          });
        });
      }
    }

    // Hacky fix for ListFooterComponentStyle not being implemented in react-native-web
    if (Platform.OS === 'web' && this.props.visible) {
      setTimeout(() => {
        const el = document.getElementById('modalListFooter');
        if (el) {
          const parent = el.parentNode;
          if (parent) {
            parent.style.flex = 1;
            parent.style.justifyContent = 'flex-end';
          }
        }
      });
    }
  }

  // Todo: remove any processing from the render function
  render() {
    const { visible, order } = this.props;
    if (!order) return null;
    const itemData = Object.values(order.grouped_items);
    // todo: sort itemData?

    if (API.config.kds_show_prices) {
      // shove this in here since we're already using ListFooterComponent for something else:
      itemData.push([
        {
          orderitemid: 'totals',
          order,
        },
      ]);
    }

    // Always show related orders at the bottom of the list
    if (order.related_orders.length) {
      itemData.push([
        {
          orderitemid: 'related-orders',
        },
      ]);
    }

    const { width } = Dimensions.get('window');

    const checkIcon =
      this.state.selectedItems.length === order.items.length
        ? 'check-box'
        : !this.state.selectedItems.length
        ? 'check-box-outline-blank'
        : 'indeterminate-check-box';

    return (
      <Modal
        visible={visible}
        transparent
        onRequestClose={this._hideModal}
        hardwareAccelerated
        fsClass="fs-unmask"
        statusBarTranslucent
      >
        <TouchableWithoutFeedback onPress={this._hideModal}>
          <View style={styles.center}>
            {/* Prevents touches from hiding modal */}
            <TouchableWithoutFeedback>
              <View
                ref={mv => (this._modalView = mv)}
                style={[styles.modal, { width: width > 800 ? 800 : width }]}
              >
                <View style={[styles.header]}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.headerText}>Order #{order.orderNumber}</Text>
                  </View>
                  <View style={{ flex: 1, alignItems: 'center' }}>
                    <Text style={styles.numItems}>
                      {order.items.length}{' '}
                      <FormattedPlural
                        value={order.items.length}
                        other="Items"
                        zero="Item"
                        one="Item"
                      />
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.orderTime}>{order.time.format('LT')}</Text>
                    <TouchableWithoutFeedback onPress={this._hideModal}>
                      <Icon
                        as={Ionicons}
                        name="close"
                        size={30}
                        style={{ marginLeft: 10, color: 'white' }}
                        color="white"
                      />
                    </TouchableWithoutFeedback>
                  </View>
                </View>
                <View
                  style={styles.content}
                  onLayout={this._onContentLayout}
                >
                  <TouchableWithoutFeedback onPress={this._selectAll}>
                    <View
                      style={{
                        flexDirection: 'row',
                        padding: 10,
                        backgroundColor: '#eee',
                        marginBottom: 10,
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        as={MaterialIcons}
                        name={checkIcon}
                        size={7}
                        style={{ marginRight: 10, marginLeft: 10, color: '#000' }}
                      />
                      <Text>Toggle Select All/None</Text>
                    </View>
                  </TouchableWithoutFeedback>
                  <FlatList
                    contentContainerStyle={{ flexGrow: 1 }}
                    ref={fl => (this._flatList = fl)}
                    onScroll={this._onScroll}
                    scrollEventThrottle={16}
                    onContentSizeChange={this._onFlatListLayout}
                    data={itemData}
                    keyExtractor={i => i[0].orderitemid}
                    renderItem={this._renderItem}
                    ListFooterComponent={this._listFooter}
                    ListFooterComponentStyle={{ flex: 1, justifyContent: 'flex-end' }}
                  />
                  <ScrollButton
                    enabled={this.state.enableScrollUp}
                    visible={this.state.hasScroll}
                    direction="up"
                    onPress={() => {
                      this._doScroll(-1);
                    }}
                  />
                  <ScrollButton
                    enabled={this.state.enableScrollDown}
                    visible={this.state.hasScroll}
                    direction="down"
                    onPress={() => {
                      this._doScroll(1);
                    }}
                  />
                </View>
                {this._getFixedFooter()}
                <OrderActionsMenu
                  order={order}
                  selectedItems={this.state.selectedItems}
                  setSelectItems={() => this.setState({ selectedItems: [] })}
                  closeModal={this.props.onClose}
                />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>

        <FlashMessage ref={fm => (this._flash = fm)} />
      </Modal>
    );
  }

  /**
   * Shown at the bottom of the ListView
   * @returns {null|*}
   * @private
   */
  _listFooter = () => {
    const { order } = this.props;
    if (!order) return null;

    if (this.state.selectedItems.length && this.state.selectedItems.length < order.items.length) {
      return null;
    }

    return (
      <View nativeID="modalListFooter">
        <OrderItemStatusChanger
          order={order}
          selectedItems={this.state.selectedItems}
          onChange={this._statusChanged}
          allowRegress
        />
      </View>
    );
  };

  _statusChanged = items => {
    // if ticket is no longer viewable on current screen, close modal
    const { status } = items[0];
    if (API.menuData.closed_statuses.includes(status)) {
      this._hideModal();
    }
  };

  _selectAll = () => {
    const { order } = this.props;
    const numSelected = this.state.selectedItems.length;
    this.setState({
      selectedItems: numSelected < order.items.length ? [...order.items] : [],
    });
  };

  /**
   * Shown as a fixed footer, don't need to scroll to see it.
   * Hide when in refund mode, or selectedItems.length > 0 and < order.items.length
   * @returns {null|*}
   * @private
   */
  _getFixedFooter = () => {
    const { order } = this.props;

    if (
      !order ||
      !this.state.selectedItems.length ||
      this.state.selectedItems.length === order.items.length
    )
      return null;
    return (
      <View style={styles.footer}>
        <OrderItemStatusChanger
          allowRegress
          order={order}
          selectedItems={this.state.selectedItems}
        />
      </View>
    );
  };

  // TODO: Related ORDERS
  _toggleItems = items => {
    const { kds_edit_item_status } = this.config;
    if (!kds_edit_item_status) return;
    if (!Array.isArray(items)) {
      items = [items];
    }
    items = items.filter(i => i.status !== 'refunded');
    this.setState({
      selectedItems: _.xor(this.state.selectedItems, items),
    });
  };

  _renderRelatedOrders = () => {
    const { order } = this.props;
    const { related_orders } = order;

    return (
      <View
        key={`${order.orderId}-related-orders`}
        style={[styles.relatedOrdersContainer]}
      >
        <Text style={[Typography.header2, { color: Colors.darkGray, marginBottom: 40 }]}>
          Related Orders
        </Text>
        {related_orders.map(o => (
          <RelatedOrder
            key={`${o.orderId}-related-order`}
            order={o}
            style={{ marginBottom: 10 }}
          />
        ))}
      </View>
    );
  };

  _renderItem = ({ item }) => {
    // See if we can get actual labels
    if (item[0].orderitemid === 'totals') {
      const { order } = this.props;
      const { checkout_info, staff_notes } = order;

      return (
        <View key={`${order.orderId}-item`}>
          {staff_notes.length > 0 && (
            <OrderStaffNotes
              notes={staff_notes}
              blockStyle={{ paddingLeft: 22 }}
            />
          )}
          {checkout_info.some(info => info.value) && (
            <View style={styles.checkoutInfoContainer}>
              <OrderCheckoutInfo
                checkoutInfo={checkout_info}
                interactive
                blockStyle={{ flex: 1, padding: 0 }}
                labelStyle={{
                  flex: 1,
                  fontWeight: 'bold',
                  maxWidth: 140,
                  textAlign: 'right',
                  marginRight: 10,
                }}
                valueStyle={{ flex: 3, flexWrap: 'wrap' }}
              />
            </View>
          )}
          <OrderTotals order={order} />
        </View>
      );
    }
    if (item[0].orderitemid === 'related-orders') {
      return this._renderRelatedOrders();
    }

    return (
      <OrderItems
        items={item}
        onPress={this._toggleItems}
        selectedItems={_.intersection(this.state.selectedItems, item)}
      />
    );
  };

  _hideModal = () => {
    const { onClose } = this.props;
    this.setState({
      hasScroll: false,
    });
    onClose();
  };

  _lastScrollOffset = 0;

  _onScroll = ({ nativeEvent }) => {
    const totalY = nativeEvent.contentSize.height;
    const offsetY = nativeEvent.contentOffset.y;
    const layoutY = nativeEvent.layoutMeasurement.height;

    this._lastScrollOffset = offsetY;

    if (totalY > layoutY) {
      this.setState({
        hasScroll: true,
        enableScrollUp: offsetY !== 0,
        enableScrollDown: offsetY < totalY - layoutY,
      });
    }
  };

  _doScroll = dir => {
    const scrollAmount = this._flatListPageHeight - 30;
    const y =
      dir > 0 ? this._lastScrollOffset + scrollAmount : this._lastScrollOffset - scrollAmount;
    this._flatList.scrollToOffset({
      offset: y,
    });
  };

  _onContentLayout = ({ nativeEvent }) => {
    this._viewHeight = nativeEvent.layout.height;
    if (this._flatListPageHeight > this._viewHeight) {
      const nativeEvent = {
        contentOffset: { y: 0 },
        contentSize: { height: this._flatListPageHeight },
        layoutMeasurement: { height: this._viewHeight },
      };
      this._onScroll({ nativeEvent });
    }
  };

  _onFlatListLayout = (width, height) => {
    this._flatListPageHeight = height;
  };

  _unSnooze = async () => {
    const { order } = this.props;
    this.setState({ unSnoozing: true });
    // todo: do something with response if fails
    const response = await API.snoozeOrder(order, null);
    this.setState({ unSnoozing: false });
    this._hideModal();
  };
}

/**
 * Wraps a group of orderItems
 */
class OrderItems extends React.Component {
  render() {
    const { items, onPress, selectedItems } = this.props;
    const item = items[0];
    const qty = items.length;
    const is_refunded = !items.find(item => item.status !== 'refunded');
    const extraStyle = is_refunded ? { textDecorationLine: 'line-through' } : null;

    return (
      <TouchableWithoutFeedback onPress={this._onPress}>
        <View style={[styles.orderItemsContainer]}>
          <View style={{ flexDirection: 'row' }}>
            <View style={[styles.col1]}>
              {items.map(item => (
                <OrderItemCheckbox
                  key={item.orderitemid}
                  item={item}
                  onPress={onPress}
                  selected={selectedItems.includes(item)}
                />
              ))}
            </View>
            <View style={[styles.col2]}>
              {items.map(item => (
                <TouchableWithoutFeedback
                  key={item.orderitemid}
                  onPress={() => {
                    onPress(item);
                  }}
                >
                  <View style={{ height: 35, justifyContent: 'center' }}>
                    <StatusIcon
                      status={item.status}
                      style={styles.orderItemsIcon}
                    />
                  </View>
                </TouchableWithoutFeedback>
              ))}
            </View>
            <View style={[styles.col3, styles.col]}>
              <Text style={[this._styles.qty, extraStyle]}>{qty}</Text>
            </View>
            <View style={[styles.col4, styles.col]}>
              <Text style={[this._styles.name, extraStyle]}>{item.itemName}</Text>
              <View>
                <ItemModifiers mods={item.mods} />
                {!!item.special_instructions && (
                  <Text style={{ color: Colors.secondary, marginLeft: 20, fontSize: 16 }}>
                    {item.special_instructions}
                  </Text>
                )}
              </View>
            </View>
            {API.config.kds_show_prices && (
              <View style={[styles.col5, styles.col]}>
                {items.map(item => (
                  <View
                    key={item.orderitemid}
                    style={{ flexDirection: 'row', height: 35, justifyContent: 'flex-end' }}
                  >
                    <FormattedCurrency
                      style={[
                        this._styles.name,
                        {
                          textDecorationLine: item.refunds_pretax_cents_added
                            ? 'line-through'
                            : null,
                        },
                        extraStyle,
                      ]}
                      value={item.getTaxDeterminedOriginalTotalCents() / 100}
                      numberStyle="accounting"
                    />
                    {item.refunds_pretax_cents_added < 0 && item.status !== 'refunded' && (
                      <FormattedCurrency
                        style={[this._styles.name, { marginLeft: 5 }]}
                        numberStyle="accounting"
                        value={item.getTaxDeterminedPostRefundTotalCents() / 100}
                      />
                    )}
                    {item.status === 'refunded' && (
                      <FormattedCurrency
                        style={[this._styles.name, { marginLeft: 5 }]}
                        numberStyle="accounting"
                        value={0}
                      />
                    )}
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }

  _onPress = () => {
    const { items, onPress, selectedItems } = this.props;
    if (selectedItems.length) onPress(selectedItems);
    else onPress(items);
  };

  _styles = EStyleSheet.create({
    qty: {
      fontSize: 18,
    },
    name: {
      fontSize: 18,
    },
  });
}

class OrderItemCheckbox extends React.PureComponent {
  render() {
    const { item, id, onPress, selected } = this.props;
    const style = item.status === 'refunded' ? { color: Colors.gray } : null;
    if (!API.config?.kds_edit_item_status) return null;

    return (
      <TouchableWithoutFeedback
        onPress={() => {
          onPress(item);
        }}
      >
        <View style={{ height: 35, justifyContent: 'center' }}>
          <Icon
            as={MaterialIcons}
            name={selected ? 'check-box' : 'check-box-outline-blank'}
            style={[style, { color: '#000' }]}
            size={7}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

class ItemModifiers extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mods } = this.props || [];

    this.state = {
      sortedModGroups: OrderHelper.prepareGroupedMods(mods),
    };
  }

  render() {
    const { mods } = this.props;
    if (!mods) return null;

    const { sortedModGroups } = this.state;

    const someModsWithoutHeadingsShown = sortedModGroups.filter(
      group => !group.showOnTicket,
    ).length;

    return (
      <View style={{ marginTop: someModsWithoutHeadingsShown ? 6 : 0 }}>
        {sortedModGroups.map(group => (
          <View key={`${group.modifiers[0]?.modifier?.heading.id}-heading.id`}>
            {group.showOnTicket && <Text style={{ marginTop: 6 }}>{` ${group.groupName}`}</Text>}
            {group.modifiers.map((mod, idx) => (
              <React.Fragment key={`${idx}-mod.id`}>
                <Text style={{ fontWeight: 'bold' }}>
                  {' '}
                  {mod.name || mod.modifier?.name_for_bartender}
                </Text>
                {mod.mods?.length > 0 && <ItemModifiers mods={mod.mods} />}
              </React.Fragment>
            ))}
          </View>
        ))}
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  modal: {
    height: '85%',
    backgroundColor: '#fff',
    elevation: 5,
    borderRadius: 8,
    overflow: 'hidden',
  },
  header: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    backgroundColor: Colors.primary,
  },
  headerRelated: {
    backgroundColor: Colors.ternary,
  },
  headerText: {
    color: Colors.light,
    fontSize: 16,
    fontWeight: 'bold',
  },
  orderTime: {
    color: Colors.light,
  },
  numItems: {
    color: Colors.light,
  },
  content: {
    flex: 1,
  },
  footer: {
    backgroundColor: 'grey',
  },
  checkoutInfoContainer: {
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 22,
    paddingRight: 10,
    paddingVertical: 15,
  },
  orderItemsContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 15,
    paddingHorizontal: 10,
  },
  col: {
    paddingTop: 7,
  },
  col1: {
    width: 50,
    alignItems: 'center',
  },
  col2: {
    width: 50,
    alignItems: 'center',
  },
  col3: {
    width: 50,
    alignItems: 'center',
  },
  col4: {
    flex: 1,
  },
  col5: {},
  orderItemsIcon: {
    height: 25,
  },
  orderItemsSelected: {
    backgroundColor: Colors.primaryLight,
  },
  orderItemBtn: {
    width: 30,
    height: 30,
    borderColor: 'black',
    borderWidth: 2,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    marginBottom: 10,
  },
  orderItemBtnSelected: {
    backgroundColor: Colors.primaryLight,
  },
  relatedOrdersContainer: {
    borderTopWidth: 1,
    borderColor: '#DDD',
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
});
