import React from 'react';
import { ScrollView } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform, Dimensions } from 'react-native';

const deviceHeight = Dimensions.get('window').height;

function Content({ children, ...props }) {
  return (
    <ScrollView
      contentContainerStyle={{ flex: 1, width: '100%' }}
      style={styles.content}
      {...props}
    >
      {children}
    </ScrollView>
  );
}

const styles = EStyleSheet.create({
  content: {
    backgroundColor: '#fff',
  },
});

export default Content;
