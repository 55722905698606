import React from 'react';
import { Text, View, StyleSheet, Pressable } from 'react-native';
import { FormattedMessage } from 'react-native-globalize';
import Colors from '../../constants/Colors';

function SubmitOrderButton({ onPress, disabled }) {
  return (
    <View style={style.container}>
      <Pressable
        testID="splitOrderScreenPlaceOrderButton"
        onPress={onPress}
        style={[style.button, disabled ? style.disabled : null]}
        disabled={disabled}
      >
        <FormattedMessage
          id="order__submit"
          defaultMessage="Submit Order"
          style={style.text}
        />
      </Pressable>
    </View>
  );
}

export default SubmitOrderButton;

const style = StyleSheet.create({
  container: {
    height: '5%',
    minHeight: 50,
  },
  button: {
    height: '100%',
    flex: 1,
    backgroundColor: Colors.accent,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    backgroundColor: Colors.gray,
  },
  text: {
    color: Colors.light,
    fontWeight: 'bold',
    fontSize: 16,
  },
});
