import React from 'react';

// import { LocationContext } from '../contexts/LocationContext';

const LocationContext = React.createContext();

export class LocationProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
    };
  }

  update(location) {
    this.setState({
      location,
    });
  }

  getValues() {
    return {
      location: this.state.location,
      update: this.update,
    };
  }

  render() {
    return (
      <LocationContext.Provider value={this.getValues()}>
        {this.props.children}
      </LocationContext.Provider>
    );
  }
}

export function withLocationContext(Component) {
  return class ComponentWithContext extends React.Component {
    render() {
      return (
        <LocationContext.Consumer>
          {value => (
            <Component
              {...this.props}
              locationProvider={value}
            />
          )}
        </LocationContext.Consumer>
      );
    }
  };
}
