import React from 'react';
import { IconButton, Icon, Input } from 'native-base';
import { Platform, View } from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useGlobalize } from 'react-native-globalize';

function SearchBar({
  leftElement = null,
  value = null,
  onChangeText,
  onClear = null,
  testID = null,
  placeholderTextPath = 'common__search',
  values,
  defaultMessage = 'Search',
  style = null,
}) {
  const { formatMessage } = useGlobalize();

  return (
    <Input
      variant="unstyled"
      value={value}
      testID={testID}
      placeholder={formatMessage(placeholderTextPath, values, { defaultMessage })}
      onChangeText={onChangeText}
      style={[{ fontSize: 16 }, style]}
      flex={1}
      InputLeftElement={
        leftElement || (
          <Icon
            size={6}
            as={Ionicons}
            ml={2}
            name="ios-search"
          />
        )
      }
      InputRightElement={
        onClear ? (
          <IconButton
            testID="ClearSearchButton"
            onPress={onClear}
            icon={
              <Icon
                size={6}
                as={Ionicons}
                name={Platform.OS === 'ios' ? 'ios-close' : 'md-close'}
              />
            }
          />
        ) : (
          <View style={{ padding: 5 }}>
            <Icon
              size={6}
              as={MaterialCommunityIcons}
              name="arrow-right"
            />
          </View>
        )
      }
    />
  );
}

export default SearchBar;
