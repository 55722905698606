import React from 'react';
import { Pressable, ScrollView, Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { Icon } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { FormattedMessage } from 'react-native-globalize';
import { BBText } from './Text';
import OrderItem from './OrderItem';

export default class ItemViewer extends React.Component {
  // Todo: this is lazy, should try to make it smarter:
  // See ItemBuilder for how to scroll to a specific item
  componentDidUpdate(prevProps, prevState, snapshot) {
    this._scrollView.scrollToEnd();
  }

  render() {
    const { multiselect } = this.props;

    return (
      <View
        testID="itemViewer"
        style={this.props.style}
      >
        <View style={styles.menuItemsHeader}>
          {multiselect && (
            <Pressable>
              <Icon as={Ionicons} />
            </Pressable>
          )}
          <FormattedMessage
            id="common__uppercase__name"
            style={[styles.headerText, { flex: 1 }]}
          />
          <FormattedMessage
            id="common__uppercase__short__quantity"
            style={[styles.headerText, { width: '10%', textAlign: 'right' }]}
          />
          <FormattedMessage
            id="common__uppercase__each"
            style={[styles.headerText, { width: '18%', textAlign: 'right' }]}
          />
          <FormattedMessage
            id="common__uppercase__total"
            style={[styles.headerText, { width: '18%', textAlign: 'right' }]}
          />
        </View>
        <View style={{ flex: 1 }}>
          <ScrollView
            ref={sv => {
              this._scrollView = sv;
            }}
          >
            <View
              testID="itemViewerItems"
              style={styles.locationItems}
            >
              {this._getItems()}
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }

  _getItems() {
    const { items, onItemPress, multiselect } = this.props;
    if (items.length) {
      return items.map(item => (
        <OrderItem
          key={`item_${uuid.v4()}`}
          showCheckbox={multiselect}
          selected={this.isItemSelected(item)}
          item={item}
          onPress={() => {
            onItemPress(item);
          }}
          current={this.props.current}
        />
      ));
    }
    return (
      <View
        testID="itemViewerEmptyOrderMessage"
        style={styles.noItems}
      >
        <BBText
          id="itemViewer__addItems"
          defaultMessage="Add some items"
        />
      </View>
    );
  }

  isItemSelected(item) {
    return this.props.selectedItems.findIndex(i => i && i.getId() === item.getId()) >= 0;
  }
}

ItemViewer.propTypes = {
  items: PropTypes.array,
  numGuests: PropTypes.number,
  onItemPress: PropTypes.func,
};
ItemViewer.defaultProps = {
  numGuests: 1,
  onItemPress: () => {},
};

let styles = EStyleSheet.create({
  menuItemsHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderBottomColor: '#bebebe',
    borderBottomWidth: 2,
    padding: 5,
  },
  headerText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  locationItems: {},
  noItems: {
    padding: 10,
    backgroundColor: '#ece9f0',
  },
});
