import React from 'react';
import { Row, Pressable } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform, PixelRatio } from 'react-native';

function ListItem({ children, onPress, ...props }) {
  const isItemDivider = props.hasOwnProperty('itemDivider');
  const isConnected = props.connected;

  return (
    <Pressable
      onPress={() => {
        if (onPress) onPress();
      }}
    >
      <Row
        style={[
          styles.listItem,
          isItemDivider && styles.itemDivider,
          onPress && { cursor: 'pointer' },
          isConnected && styles.connected,
        ]}
        {...props}
      >
        {children}
      </Row>
    </Pressable>
  );
}

const styles = EStyleSheet.create({
  listItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: Platform.OS === 'ios' ? 10 : 12 + 6,
    paddingVertical: Platform.OS === 'ios' ? 10 : 12 + 3,
    paddingLeft: Platform.OS === 'ios' ? 10 : 12 + 6,
    borderBottomWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: '#c9c9c9',
    width: '100%',
  },
  itemDivider: {
    borderBottomWidth: 3,
    borderColor: '#7f8183',
    marginLeft: null,
    padding: Platform.OS === 'ios' ? 10 : 12,
    paddingLeft: Platform.OS === 'ios' ? 10 : 12 + 5,
    backgroundColor: '#e5e7e9',
    flexDirection: 'row',
  },
  connected: {
    backgroundColor: '#bfe7e8',
  },
});

export default ListItem;
