import React from 'react';
import { Text, View } from 'react-native';
import OrderHelper from '../../../helpers/OrderHelper';

export default class KDSItemModifiers extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mods } = this.props || [];

    this.state = {
      sortedModGroups: OrderHelper.prepareGroupedMods(mods),
    };
  }

  render() {
    const { mods, color, font } = this.props;

    const { sortedModGroups } = this.state;

    if (!mods) {
      return null;
    }

    const someModsWithoutHeadingsShown = sortedModGroups.filter(
      group => !group.showOnTicket,
    ).length;

    return (
      <View style={{ marginTop: someModsWithoutHeadingsShown ? 6 : 0 }}>
        {sortedModGroups.map(headingGroup => (
          <View key={`${headingGroup.modifiers[0].modifier?.heading?.id}-heading.id`}>
            {headingGroup.showOnTicket && (
              <Text style={{ marginTop: 6, fontSize: 13 }}>{`${headingGroup.groupName}`}</Text>
            )}
            {headingGroup.modifiers.map((mod, idx) => (
              <React.Fragment key={`${mod.id}-${idx}-mod.id`}>
                <Text
                  style={{
                    fontSize: 13,
                    color,
                    fontStyle: font,
                    fontWeight: 'bold',
                  }}
                >
                  {mod.name || mod.modifier?.name_for_bartender}
                </Text>
                <KDSItemModifiers
                  mods={mod.mods}
                  color={color}
                />
              </React.Fragment>
            ))}
          </View>
        ))}
      </View>
    );
  }
}
