import React, { Component } from 'react';
import { StyleSheet, View, TouchableWithoutFeedback, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormattedMessage } from 'react-native-globalize';
import Colors from '../constants/Colors';

export default class Segment extends Component {
  /**
   * https://frontarm.com/james-k-nelson/passing-data-props-children/
   * Uses this technique to automatically add first and last flags to children
   */

  render() {
    const { style, children, ...props } = this.props;

    const elements = React.Children.toArray(children);

    elements[0] = React.cloneElement(elements[0], { first: true });
    elements[elements.length - 1] = React.cloneElement(elements[elements.length - 1], {
      last: true,
    });

    return (
      <View
        style={[commonStyles.segment, commonStyles.centered, style]}
        {...props}
      >
        {elements}
      </View>
    );
  }
}

export function SegmentButton({
  display,
  textPath,
  testID,
  note,
  lines = 1,
  value,
  selected,
  onPress,
  style,
  textStyle,
  icon,
  ...props
}) {
  const isFirst = props.hasOwnProperty('first');
  const isLast = props.hasOwnProperty('last');
  const styles = props.hasOwnProperty('darkMode') ? darkStyles : lightStyles;

  const noteEl = note ? (
    <Text
      note
      style={styles.noteText}
    >
      {note}
    </Text>
  ) : null;

  return (
    <TouchableWithoutFeedback
      testID={testID}
      onPress={() => {
        onPress(value);
      }}
    >
      <View
        style={[
          styles.segmentButton,
          selected && styles.selected,
          isFirst && styles.segmentButtonFirst,
          isLast && styles.segmentButtonLast,
          style,
        ]}
      >
        {icon || null}
        {!!textPath && (
          <FormattedMessage
            numberOfLines={1}
            id={textPath}
            defaultMessage={display}
            style={[
              styles.segmentButtonText,
              selected ? styles.segmentButtonTextSelected : null,
              textStyle,
            ]}
          />
        )}
        {!textPath && (
          <Text
            numberOfLines={lines}
            style={[
              styles.segmentButtonText,
              selected ? styles.segmentButtonTextSelected : null,
              textStyle,
            ]}
          >
            {display}
          </Text>
        )}
        {noteEl}
      </View>
    </TouchableWithoutFeedback>
  );
}

const commonStyles = StyleSheet.create({
  segment: {
    flexDirection: 'row',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const lightStyles = StyleSheet.create({
  segmentButton: {
    flex: 1,
    alignSelf: 'stretch',
    padding: 10,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  segmentButtonFirst: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  segmentButtonLast: {
    borderRightWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  segmentButtonText: {
    fontSize: 20,
    color: Colors.dark,
  },
  segmentButtonTextSelected: {
    color: Colors.light,
  },
  selected: {
    backgroundColor: Colors.primary,
  },
  noteText: {
    fontSize: 12,
    color: Colors.darkGray,
  },
});

const darkStyles = EStyleSheet.create({
  segmentButton: {
    padding: 5,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.light,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 95,
  },
  segmentButtonFirst: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  segmentButtonLast: {
    borderRightWidth: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  segmentButtonText: {
    fontSize: 14,
    color: Colors.light,
  },
  segmentButtonTextSelected: {
    color: Colors.dark,
  },
  selected: {
    backgroundColor: Colors.light,
  },
});
