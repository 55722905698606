import * as Colors from './Colors';

// ---------------------------------------------
//
// Text Decoration
//
// ---------------------------------------------
export const italic = {
  fontStyle: 'italic',
};

export const bold = {
  fontWeight: 'bold',
};

export const underline = {
  textDecorationLine: 'underline',
  textDecorationStyle: 'solid',
};

// ---------------------------------------------
//
// Text Sizes
//
// ---------------------------------------------
const rem = 16;

export const sizeSmall = {
  fontSize: 0.875 * rem,
};

export const sizeRegular = {
  fontSize: 1 * rem,
};

export const sizeLarge = {
  fontSize: 1.5 * rem,
};

// ---------------------------------------------
//
// Font Types
//
// ---------------------------------------------
export const paragraph = {
  ...sizeLarge,
  ...bold,
  marginBottom: 16,
};

export const header2 = {
  ...sizeRegular,
  ...bold,
  marginBottom: 16,
};

export const header3 = {
  ...sizeSmall,
  ...bold,
  marginBottom: 16,
};

export const label = {
  ...sizeRegular,
  ...bold,
  color: Colors.lightTheme.grayscale.title,
  marginBottom: 16,
};
