import React, { useState } from 'react';
import { Text, View } from 'native-base';
import { Image, Pressable, TouchableWithoutFeedback } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FormattedMessage } from 'react-native-globalize';
import Colors from '../../constants/Colors';
import Card from './Card';
import API from '../../api';
import { IconMap } from '../../models/SavedCard';

/**
 *
 * @param card {SavedCard | PartyTab}
 * @param location {Location}
 * @param check {CheckModel}
 * @param onApprove
 * @param disabled
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
function CheckoutButtonCard({ card, location, check, onApprove, disabled, text }) {
  const tabsAllowed = API.config.allow_pay_with_consumer_tab && location.allow_consumer_tabs;

  return (
    <Card style={{ borderBottomWidth: 0 }}>
      {!check.isValid() && (
        <FormattedMessage
          id="backToServer__short"
          defaultMessage="Please pass the device back to your server"
          style={{ color: Colors.error, marginBottom: 5 }}
        />
      )}

      {tabsAllowed && !card && (
        <View style={{ alignItems: 'center' }}>
          <StartTabButton onPress={() => onApprove(true)} />
          <FormattedMessage
            id="common__dashOr"
            defaultMessage="- OR -"
            style={{ marginBottom: 10 }}
          />
        </View>
      )}

      <ContinueButton
        onPress={() => onApprove()}
        disabled={disabled}
        textPath={text.path}
        defaultMessage={text.default}
      />

      {card?.type === 'tab' && (
        <ContinueButton
          style={{ marginTop: 10, backgroundColor: Colors.warning }}
          onPress={() => onApprove(false, true)}
          disabled={disabled}
          textPath="tab__finalize"
          defaultMessage="Finalize Tab"
        />
      )}
    </Card>
  );
}

export default CheckoutButtonCard;

function StartTabButton({ onPress, disabled }) {
  return (
    <Pressable
      style={[
        styles.submitButton,
        styles.centered,
        {
          marginBottom: 10,
          backgroundColor: 'white',
          borderColor: Colors.primary,
          borderWidth: 3,
        },
      ]}
      onPress={onPress}
    >
      <Text style={[styles.submitButtonText, { color: Colors.primary }]}>Start Tab</Text>
      <View style={{ position: 'absolute', right: 10, flexDirection: 'row' }}>
        <Image
          style={{ width: 24, height: 24, marginRight: 10 }}
          source={IconMap.visa}
        />
        <Image
          style={{ width: 24, height: 24 }}
          source={IconMap.mastercard}
        />
      </View>
    </Pressable>
  );
}

/**
 * The <ContinueButton> is the button at the bottom of the screen. It is not enabled until various criteria in different
 * parts of the <TipScreen> have been satisfied, such as having the required checkout information fields populated.
 *
 * @param props
 * @param {function} props.onPress - What to do once the button has been clicked
 * @param {boolean} props.disabled - True if the user can click this button to finalize the purchase.
 * @param {string} props.label - The text inside of the button
 * @returns {JSX.Element}
 * @constructor
 */
function ContinueButton({ onPress, disabled, textPath, defaultMessage, style, ...rest }) {
  return (
    <Pressable
      testID="approveButton"
      accessible
      disabled={disabled}
      onPress={onPress}
      style={[styles.centered, styles.submitButton, disabled && styles.submitButtonDisabled, style]}
      {...rest}
    >
      <FormattedMessage
        id={textPath}
        defaultMessage={defaultMessage}
        style={styles.submitButtonText}
      />
    </Pressable>
  );
}

const styles = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    width: '100%',
    height: 40,
    borderRadius: 5,
    backgroundColor: Colors.primary, // Overwritten if button is disabled, see submitButtonDisabled style
  },
  submitButtonDisabled: {
    backgroundColor: Colors.darkGray,
  },
  submitButtonText: {
    color: Colors.light,
    fontWeight: 'bold',
    fontSize: 20,
  },
});
