import React from 'react';
import {
  Dimensions,
  Platform,
  StatusBar,
  StyleSheet,
  View,
  BackHandler,
  ActivityIndicator,
  LogBox,
} from 'react-native';
import { GlobalizeProvider } from 'react-native-globalize';
import EStyleSheet from 'react-native-extended-stylesheet';
import FlashMessage, { showMessage } from 'react-native-flash-message';
import * as SplashScreen from 'expo-splash-screen';
import { NativeBaseProvider, extendTheme, Fab, Icon } from 'native-base';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import {
  AntDesign,
  Entypo,
  Feather,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import { enableES5 } from 'immer';
import { createClient, AnalyticsProvider } from './segment';
import { RUNNING_TESTS } from './constants/Config';
import AppNavigator from './navigation/AppNavigator';
import NavigationService from './navigation/NavigationService';
import API from './api';
import PinPadModal from './components/PinPad/PinPadModal';
import NotificationBanner from './components/NotificationBanner';
import GlobalPrompt from './components/Prompt/GlobalPrompt';
import ActionSheet from './components/ActionSheet';
import Colors from './constants/Colors';
import { CUSTOMIZED_THEME } from './styles/Global';
import KeepAwake from './services/KeepAwake';
import Sentry from './services/Sentry';
import ErrorScreen from './screens/ErrorScreen';
import QRCodeModal from './components/Modals/QRCodeModal';
import NotificationModal from './components/TerminalNotifications/NotificationModal';
import StripeProvider from './components/StripeElements/StripeProvider';
import LoadingOverlay from './components/Modals/LoadingOverlay';
import GlobalStripeCardModal from './components/Stripe/GlobalStripeCardModal';
import { switchLocale } from './i18n/localeLoader';
import { clearAccess } from './helpers/Access';

// Needed to run tests successfully because yellow warning prompts block tests from detecting UI elements. Usually unset, only set to true when devConstants.runningTests is set to true.
if (LogBox) {
  LogBox.ignoreAllLogs(RUNNING_TESTS);
  LogBox.ignoreLogs([
    'Warning: React does not recognize the `dataComponent`',
    'Constants.manifest is null',
    "Property '_UIManager'",
  ]);
} else console.disableYellowBox = RUNNING_TESTS;

export default class App extends React.Component {
  state = {
    isLoadingComplete: false,
    updating: false,
    progress: 0,
    isSplashReady: false,
    isAppReady: false,
    customer: {},
    deviceLocale: '',
    customerLocale: '',
    showAccessFAB: false,
  };

  constructor(props) {
    super(props);

    EStyleSheet.build({
      $outline: 0, // used for debugging layout issues
    });
  }

  componentDidMount() {
    API.initSentry();
    enableES5();

    this._loadResourcesAsync();
    this._listeners = [
      BackHandler.addEventListener('hardwareBackPress', this.onBackPress),
      API.on('customer_change', this._setCustomer),
      API.on('locale_changed', this._updateDeviceLocale),
      API.on('access', this._toggleAccessFAB),
    ];

    this._updateDeviceLocale();

    if (Platform.OS === 'android') {
      this._batteryStateListener = KeepAwake();
    }

    this._segmentClient = createClient();
  }

  /**
   * App is exiting
   */
  componentWillUnmount() {
    this._listeners?.forEach(listener => listener.remove());
    if (this._batteryStateListener) this._batteryStateListener.remove();
  }

  onBackPress = () => true;

  onLayout = e => {
    const { width, height } = Dimensions.get('window');
    const orientation = width > height ? 'landscape' : 'portrait';
    API.setOrientation(orientation);
  };

  _reloadApp = () => {
    if (Platform.OS === 'web') window.location.reload();
    else {
    }
  };

  // eslint-disable-next-line react/sort-comp
  render() {
    if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator
            size="large"
            color={Colors.primary}
          />
        </View>
      );
    }
    const { dispatch, state } = this.props;
    const { deviceLocale, customer, customerLocale, showAccessFAB } = this.state;
    const currency = customer.currency ? customer.currency.toUpperCase() : 'USD';

    return (
      <GlobalizeProvider
        localeFallback
        defaultLocale="en"
        locale={deviceLocale || customerLocale}
        currency={currency}
      >
        <NativeBaseProvider theme={extendTheme(CUSTOMIZED_THEME)}>
          <AnalyticsProvider client={this._segmentClient}>
            <Sentry.ErrorBoundary
              fallback={<ErrorScreen />}
              showDialog={Platform.OS === 'web'}
            >
              {Platform.OS === 'android' && (
                <StatusBar
                  barStyle="light-content"
                  hidden
                />
              )}
              <View
                testID="welcome"
                style={[styles.container, StyleSheet.absoluteFill]}
                onLayout={this.onLayout}
                onTouchStart={API.touched}
              >
                <NotificationBanner isGlobal />
                <StripeProvider>
                  <AppNavigator
                    ref={navigatorRef => {
                      NavigationService.setTopLevelNavigator(navigatorRef);
                    }}
                    dispatch={dispatch}
                    state={state}
                    onNavigationStateChange={this._onNavigationStateChange}
                  />
                  <GlobalStripeCardModal />
                </StripeProvider>
                <ActionSheet />
                <FlashMessage
                  position="bottom"
                  style={{ zIndex: 10000 }}
                />
                <PinPadModal
                  onClose={() => {}}
                  onSuccess={() => {}}
                  isGlobal
                />
                <QRCodeModal />
                {/* ==== For showing KDS Messages ==== */}
                <NotificationModal />
                {!!showAccessFAB && (
                  <Fab
                    placement="bottom-right"
                    colorScheme="blue"
                    size="sm"
                    bottom={60}
                    onPress={() => {
                      this.setState({ showAccessFAB: false });
                      clearAccess();
                    }}
                    icon={
                      <Icon
                        name="unlock"
                        as={FontAwesome}
                      />
                    }
                    label={showAccessFAB.user.name}
                  />
                )}
              </View>
              <GlobalPrompt
                headerStyle={{ backgroundColor: Colors.primary }}
                titleStyle={{ color: Colors.light }}
                submitButtonStyle={{ backgroundColor: Colors.primary }}
                submitButtonTextStyle={{ color: Colors.light }}
              />
              <LoadingOverlay />
            </Sentry.ErrorBoundary>
          </AnalyticsProvider>
        </NativeBaseProvider>
      </GlobalizeProvider>
    );
  }

  _setCustomer = customer => {
    let locale = customer.locale ? customer.locale.replace('_', '-') : 'en';

    if (!this.state.deviceLocale) {
      locale = switchLocale(locale);
      API.setDeviceLocale(locale);
    }

    this.setState({
      customer,
      customerLocale: locale,
    });
  };

  _updateDeviceLocale = () => {
    AsyncStorage.getItem('locale').then(val => {
      API.deviceLocale = val;
      if (val) {
        const locale = switchLocale(val);
        this.setState({ deviceLocale: locale });
      }
    });
  };

  _toggleAccessFAB = user => {
    this.setState({
      showAccessFAB: user,
    });
  };

  _loadResourcesAsync = async () => {
    try {
      await Promise.all([
        Asset.loadAsync([
          require('./assets/images/readers/chipper2x.png'),
          require('./assets/images/contactless-payment-symbol.png'),
          require('./assets/images/ChipReader.png'),
          require('./assets/images/list-loading.gif'),
          require('./assets/images/thank-you.png'),
          require('./assets/fonts/SpaceMono-Regular.ttf'),
          require('./assets/sounds/alarm_old.mp3'),
          require('./assets/sounds/notification_old.mp3'),
          require('./assets/sounds/new_message.mp3'),
        ]),
        Font.loadAsync({
          fontello: require('./assets/fonts/fontello/font/fontello.ttf'),
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
          Roboto: require('./assets/fonts/Roboto.ttf'),
          Roboto_medium: require('./assets/fonts/Roboto-Medium.ttf'),
          TTNorms: require('./assets/fonts/TTNorms.otf'),
          ...MaterialCommunityIcons.font,
          ...MaterialIcons.font,
          ...Feather.font,
          ...Entypo.font,
          ...AntDesign.font,
          ...Ionicons.font,
          ...FontAwesome.font,
          ...FontAwesome5.font,
          ...Foundation.font,
          ...Octicons.font,
          ...Fontisto.font,
        }),
      ]);
    } catch (err) {
      await API.sendCaughtError(err, null, 'Error loading assets');
    }

    await this._handleFinishLoading();
  };

  _handleLoadingError = error => {
    // In this case, you might want to report the error to your error
    // reporting service, for example Sentry
    console.warn(error);
  };

  _handleFinishLoading = async () => {
    this.setState({ isLoadingComplete: true });

    API.on('connected', (connected, msg) => {
      if (!connected) {
        showMessage({
          message: msg,
          autoHide: false,
          backgroundColor: 'red',
        });
      } else if (connected) {
        showMessage({
          message: 'You are back online',
          duration: 3000,
          backgroundColor: '#16ff01',
        });
      }
    });

    if (Platform.OS !== 'web') await SplashScreen.hideAsync();
  };

  _onNavigationStateChange = (prevState, currentState, action) => {
    const currentRouteName = getRouteName(currentState);
    const prevRouteName = getRouteName(prevState);

    // check because hot-reloading during dev breaks shit
    if (API) API.currentScreen = currentRouteName;

    if (currentRouteName !== prevRouteName) {
      API.trigger('screen', currentRouteName);
      // the line below uses the @react-native-firebase/analytics tracker
      // change the tracker here to use other Mobile analytics SDK.
      try {
        //   Analytics.setCurrentScreen(currentRouteName, currentRouteName);
      } catch (err) {}
    }
  };
}

function getRouteName(navigationState) {
  if (!navigationState) {
    return null;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators
  if (route.routes) {
    return getRouteName(route);
  }
  return route.routeName;
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    backgroundColor: '#ffffff',
  },
});
