import React from 'react';
import { Box } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';

function IconFooter({ children, style, ...props }) {
  return (
    <Box
      style={[styles.footer, style]}
      {...props}
    >
      {children}
    </Box>
  );
}

const styles = EStyleSheet.create({
  footer: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
  },
});

export default IconFooter;
