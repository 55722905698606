import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Row, ScrollView, Icon } from 'native-base';
import { Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import API from '../../api';
import { LeftColStyles as styles } from '../../styles/POSStyles';

import NavigationService from '../../navigation/NavigationService';

import POSContext from '../../contexts/POSContext';
import { ITEM_SELECTOR_COL_VIEWS, POS_REDUCER_ACTIONS } from '../../screens/POS';
import { mergeMenuHeadings } from './utils';
import ReaderStatus from '../ReaderStatus';

function MenuCol() {
  const [selectedMenuHeadingId, setSelectedMenuHeadingId] = useState(null);
  const { POSDispatch } = useContext(POSContext);
  const {
    HeadingContainerSelected,
    HeadingContainer,
    Col,
    TitleContainer,
    MenuTitle,
    HeadingTitle,
    Footer,
  } = styles;

  const returnToTerminal = () => {
    NavigationService.navigate('LocationsScreen');
  };

  const menuHeadings = mergeMenuHeadings();

  const menuHeadingStyle = menuHeading =>
    menuHeading.id === selectedMenuHeadingId ? HeadingContainerSelected : HeadingContainer;

  useEffect(() => {
    if (menuHeadings.length && !selectedMenuHeadingId) {
      const firstMenuHeading = menuHeadings[0];
      setSelectedMenuHeadingId(firstMenuHeading.id);
      POSDispatch({
        type: POS_REDUCER_ACTIONS.SET_SELECTED_MENU_HEADING,
        selectedMenuHeading: firstMenuHeading,
      });
    }
  }, [menuHeadings]);

  /**
   * When a menu heading is pressed, we want to update the middle column view and update the variable that tracks the currently selected menu heading
   * @param menuHeading
   */
  const menuHeadingPressed = menuHeading => {
    setSelectedMenuHeadingId(menuHeading.id);
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_SELECTED_MENU_HEADING,
      selectedMenuHeading: menuHeading,
    });
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_ITEM_SELECTOR_COL_VIEW,
      itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.SELECT_ITEM,
    });
  };

  return (
    <View style={Col}>
      <View style={TitleContainer}>
        <Text style={MenuTitle}>{API.customer?.customer_name}</Text>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        {menuHeadings?.map(menuHeading => (
          <Pressable
            key={menuHeading.id}
            style={menuHeadingStyle(menuHeading)}
            onPress={() => menuHeadingPressed(menuHeading)}
          >
            <Text style={HeadingTitle}>{menuHeading.heading_name}</Text>
          </Pressable>
        ))}
      </ScrollView>

      <Row
        justifyContent="space-between"
        alignItems="center"
        style={Footer}
      >
        <Pressable onPress={returnToTerminal}>
          <Icon
            size={6}
            marginRight={1}
            marginLeft={2}
            as={Ionicons}
            name="arrow-back-outline"
            color="white"
          />
        </Pressable>

        <ReaderStatus hideText />
      </Row>
    </View>
  );
}

export default MenuCol;
