import React, { useEffect, useState } from 'react';
import { AppState, Platform } from 'react-native';
import WifiManager from 'react-native-wifi-reborn';
import { Icon, Text } from 'native-base';
import * as IntentLauncher from 'expo-intent-launcher';
import { Feather } from '@expo/vector-icons';
import { Body, ListItem, Right, Left } from '../../bbot-component-library';

import { styles } from '../../styles/ListComponentStyle';

/**
 * Wifi Status information
 * @param focus
 * @returns {JSX.Element}
 * @constructor
 */
function WifiStatus({ focus }) {
  const [connectedWifi, setConnectedWifi] = useState('');

  useEffect(() => {
    const listener = AppState.addEventListener('change', updateSSID);
    return () => {
      listener.remove();
    };
  }, []);

  const updateSSID = async () => {
    if (Platform.OS === 'web') return;
    try {
      const wifi = await WifiManager.getCurrentWifiSSID();
      setConnectedWifi(wifi);
    } catch (err) {
      setConnectedWifi('Error retrieving wifi');
    }
  };

  return (
    <ListItem
      onPress={() => {
        IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.WIFI_SETTINGS);
      }}
    >
      <Left icon>
        <Icon
          size={8}
          style={styles.iconStyle}
          as={Feather}
          name="wifi"
        />
      </Left>
      <Body>
        <Text>Wifi Network</Text>
      </Body>
      <Right>
        <Text note>{connectedWifi || 'Not Connected'}</Text>
      </Right>
    </ListItem>
  );
}

export default WifiStatus;
