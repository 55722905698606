import React, { useEffect, useState } from 'react';
import Prompt from './Prompt';

let promptFn = null;

export const prompt = props => {
  if (promptFn) promptFn(props);
  else throw 'No top-level instance of GlobalPrompt found';
};

/**
 * @returns {JSX.Element}
 * @constructor
 */
function GlobalPrompt(defaults) {
  // Setup State for every possible prop
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [onCancel, setOnCancel] = useState(() => () => {});
  const [cancelText, setCancelText] = useState('');
  const [onSubmit, setOnSubmit] = useState(() => value => {});
  const [submitText, setSubmitText] = useState('');
  const [validator, setValidator] = useState(null);
  const [onChangeText, setOnChangeText] = useState();
  const [borderColor, setBorderColor] = useState(defaults.borderColor);
  const [promptStyle, setPromptStyle] = useState(defaults.promptStyle);
  const [headerStyle, setHeaderStyle] = useState(defaults.headerStyle);
  const [titleStyle, setTitleStyle] = useState(null);
  const [buttonStyle, setButtonStyle] = useState(null);
  const [buttonTextStyle, setButtonTextStyle] = useState(null);
  const [submitButtonStyle, setSubmitButtonStyle] = useState(null);
  const [submitButtonTextStyle, setSubmitButtonTextStyle] = useState(null);
  const [cancelButtonStyle, setCancelButtonStyle] = useState(null);
  const [cancelButtonTextStyle, setCancelButtonTextStyle] = useState(null);
  const [inputStyle, setInputStyle] = useState(null);
  const [textInputProps, setTextInputProps] = useState(null);
  const [inputCmp, setInputCmp] = useState(null);

  const show = (props = {}) => {
    setTitle(props.title);
    setDefaultValue(props.defaultValue);
    setPlaceholder(props.placeholder);
    setCancelText(props.cancelText);
    setOnCancel(() => props.onCancel);
    setSubmitText(props.submitText);
    setOnSubmit(() => props.onSubmit);
    setValidator(() => props.validator);
    setOnChangeText(() => props.onChangeText);
    setBorderColor(props.borderColor);
    setPromptStyle(props.promptStyle || defaults.promptStyle);
    setHeaderStyle(props.headerStyle || defaults.headerStyle);
    setTitleStyle(props.titleStyle || defaults.titleStyle);
    setButtonStyle(props.buttonStyle || defaults.buttonStyle);
    setButtonTextStyle(props.buttonTextStyle || defaults.buttonTextStyle);
    setSubmitButtonStyle(props.submitButtonStyle || defaults.submitButtonStyle);
    setSubmitButtonTextStyle(props.submitButtonTextStyle || defaults.submitButtonTextStyle);
    setCancelButtonStyle(props.cancelButtonStyle || defaults.cancelButtonStyle);
    setCancelButtonTextStyle(props.cancelButtonTextStyle || defaults.cancelButtonTextStyle);
    setInputStyle(props.inputStyle || defaults.inputStyle);
    setTextInputProps(props.textInputProps || defaults.textInputProps);
    setInputCmp(props.inputCmp || defaults.inputCmp);

    setVisible(true);
  };

  /**
   * Set up our promptFn to be this instances `show` function
   */
  useEffect(() => {
    promptFn = show;
    return () => {
      promptFn = null;
    };
  }, []);

  const handleCancel = () => {
    setVisible(false);
    if (onCancel) onCancel();
  };

  const handleSubmit = value => {
    setVisible(false);
    onSubmit(value);
  };

  return !visible ? null : (
    <Prompt
      visible
      title={title}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onCancel={handleCancel}
      cancelText={cancelText}
      onSubmit={handleSubmit}
      submitText={submitText}
      validator={validator}
      onChangeText={onChangeText}
      borderColor={borderColor}
      promptStyle={promptStyle}
      headerStyle={headerStyle}
      titleStyle={titleStyle}
      buttonStyle={buttonStyle}
      buttonTextStyle={buttonTextStyle}
      submitButtonStyle={submitButtonStyle}
      submitButtonTextStyle={submitButtonTextStyle}
      cancelButtonStyle={cancelButtonStyle}
      cancelButtonTextStyle={cancelButtonTextStyle}
      inputStyle={inputStyle}
      inputCmp={inputCmp}
      textInputProps={textInputProps}
    />
  );
}

export default GlobalPrompt;
