import { View } from 'react-native';
// import CurrencyInput from "./CurrencyInput";
import _ from 'lodash';
import { Text } from 'native-base';
import { FormattedNumber, useGlobalize } from 'react-native-globalize';
import React, { useEffect, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import CurrencyInput from 'react-native-currency-input';
import API from '../api';
import SymbolInput from './SymbolInput';
import { TextM } from './Text';
import Segment, { SegmentButton } from './Segment';

/**
 * Computes the value of the tip in cents given the type of the tip (percentage or amount), the numerical value, and the
 * check (for computing the value of a percentage)
 *
 * @param {TipType} tipType The type of tip the tipValue is representing
 * @param {number} tipValue 0-1 if the tip type is TipType.PERCENTAGE, any non-negative integer number of cents otherwise
 * @param {number} total The current total which is calculated from the check in the parent component.
 * @returns {number} (In cents) The final tip amount
 */
export const getTipAmount = (tipType, tipValue, total) => {
  // TipType.NOTIP, return zero
  if (tipType === TipType.NOTIP) return 0;
  // TipType.AMOUNT, return the value
  if (tipType === TipType.AMOUNT) return tipValue;
  // TipType.PERCENT, compute the percentage, same way as Consumer App: https://github.com/BbotLLC/consumer/blob/master/src/stores/CartStore.js
  return Math.round(total * tipValue);
};

/**
 * Denotes the type of tip, whether it's a fixed amount or a percentage of the total cost
 * @enum {string}
 */
export const TipType = {
  NOTIP: 'noTip',
  AMOUNT: 'custom$',
  PERCENTAGE: 'custom%',
};

/**
 * The <TipsCard> contains the row of buttons to select presets or custom tip amounts, and allows the patron to enter
 * their custom amount or percentage for tip if they choose.
 *
 * The selections will update the <TipScreen> component's state to be accurately displayed in the total cost.
 *
 * @param props
 * @param {CheckModel} props.check Information on the current order, including items and various prices
 * @param {ReactHook<number>} props.setTipAmount The React hook for setting the final dollar amount of the Tip
 * @returns {JSX.Element}
 * @constructor
 */

function TipEditor({ total, onTipChange, tipAmount, ...rest }) {
  const menuData = API.getMenu();
  const { choices, default_tip, show_no_tip } = menuData.customer.app_properties.tipping;

  /** @type {[(number | TipType), ReactHook<number | TipType>]} One of the values in tipChoices, NoTip, or TipType if custom */
  const [selected, setSelected] = useState(default_tip);
  const [initialized, setInitialized] = useState(false);
  /** @type {[number, ReactHook<number>]} (In dollars) Persistence of the number in custom amount */
  const [customAmount, setCustomAmount] = useState(0);
  /** @type {[string, ReactHook<string>]} Persistence of the entry in custom percentage (to allow for decimals) */
  const [customPercentageString, setCustomPercentageString] = useState('0');
  const { formatCurrency } = useGlobalize();

  useEffect(() => {
    if (tipAmount > 0) {
      const initialTipPercentage = +(tipAmount / total).toFixed(2);
      const currSelected = choices.includes(initialTipPercentage)
        ? initialTipPercentage
        : TipType.AMOUNT;
      setSelected(currSelected);
    } else {
      // We got a tipAmount of zero, so let's reset it to our default:
      onTipChange({
        amount: getTipAmount(TipType.PERCENTAGE, selected, total),
        percent: selected,
      });
    }
    setCustomAmount(tipAmount);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      // If we're already initialized, and the total changes, it means
      // we just heard back from getCartPrice, so need to update our value
      onTipChange({
        amount: getTipAmount(TipType.PERCENTAGE, default_tip, total),
        percent: default_tip,
      });
      setSelected(default_tip);
    }
  }, [total]);

  // Builds a list of <SegmentButton>
  const presetTipButtons = choices.map((tipChoice, i) => {
    const tipAmount = getTipAmount(TipType.PERCENTAGE, tipChoice, total);
    return (
      <SegmentButton
        key={`tipScreenPresetPercent-${tipChoice * 100}`}
        testID={`tipScreenPresetPercent-${tipChoice * 100}`}
        display={`${tipChoice * 100}%`}
        value={tipChoice}
        note={formatCurrency(tipAmount / 100)}
        selected={selected === tipChoice}
        onPress={value => {
          setSelected(value);
          onTipChange({
            amount: tipAmount,
            percent: value,
          });
        }}
      />
    );
  });

  const noTipButton = show_no_tip && (
    <SegmentButton
      testID="tipScreenNoTip"
      display="No Tip"
      value={TipType.NOTIP}
      lines={2}
      selected={selected === TipType.NOTIP}
      onPress={val => {
        onTipChange({
          amount: 0,
          percent: 0,
        });
        setSelected(val);
      }}
    />
  );

  const fixedTipButtons = [
    // Enter a Custom Fixed Amount
    <SegmentButton
      key="tipScreenCustomAmountButton"
      testID="tipScreenCustomAmountButton"
      display="$"
      note="Other Amount"
      value={TipType.AMOUNT}
      selected={selected === TipType.AMOUNT}
      onPress={val => {
        setCustomAmount(tipAmount);
        setSelected(val);
      }}
    />,
    // Enter a Custom Percentage Amount
    <SegmentButton
      key="tipScreenCustomPercentageButton"
      testID="tipScreenCustomPercentageButton"
      display="%"
      note="Other Percent"
      value={TipType.PERCENTAGE}
      selected={selected === TipType.PERCENTAGE}
      onPress={val => {
        const currentPct = tipAmount / (total || 1);
        setCustomPercentageString(_.round(currentPct * 100, 2));
        setSelected(val);
      }}
    />,
  ];

  return (
    <View {...rest}>
      <Segment
        nativeID="tipChoiceSelector"
        style={styles.segmentStyle}
      >
        {noTipButton}
        {presetTipButtons}
      </Segment>
      <Segment
        nativeID="tipFixedSelector"
        style={styles.segmentStyle}
      >
        {fixedTipButtons}
      </Segment>
      {/* Space to enter a Custom Fixed Amount */}
      {selected === TipType.AMOUNT && (
        <View style={styles.customTipInputs}>
          <TextM
            id="common__tipAmount"
            defaultMessage="Tip Amount: "
          />
          <CurrencyInput
            testID="tipScreenCustomAmountInput"
            prefix="$"
            delimiter=","
            separator="."
            value={_.round(customAmount / 100, 2)}
            style={{
              width: 80,
              fontSize: 16,
              padding: 5,
              backgroundColor: 'white',
              borderWidth: 1,
            }}
            onChangeValue={amount => {
              // Convert user tip in dollars to tip in cents
              setCustomAmount(amount * 100);
              const pc = (amount * 100) / total;
              onTipChange({
                amount: amount * 100,
                percent: pc,
              });
              setCustomPercentageString(_.round(pc * 100, 2));
            }}
            selectTextOnFocus
          />
          <Text
            testID="tipScreenCalcTipPercentage"
            style={{ fontSize: 16, marginLeft: 10 }}
          >
            (
            <FormattedNumber
              value={(customAmount * 100) / (total || 1)}
              maximumFractionDigits={2}
            />{' '}
            <TextM defaultMessage="%" />)
          </Text>
        </View>
      )}

      {/* Space to enter a Custom Percentage Amount */}
      {selected === TipType.PERCENTAGE && (
        <View style={styles.customTipInputs}>
          <TextM
            id="common__tipPercentage"
            defaultMessage="Tip Percentage: "
          />
          <SymbolInput
            testID="tipScreenCustomPercentageInput"
            suffix="%"
            value={customPercentageString}
            onChangeText={text => {
              text = text.replace(/[^0-9.,]+/, '');
              setCustomPercentageString(text);
              const percentage = text?.length > 0 ? parseFloat(text) / 100 : 0;
              const tipAmount = getTipAmount(TipType.PERCENTAGE, percentage, total);
              onTipChange({
                amount: tipAmount,
                percent: percentage,
              });
              setCustomAmount(tipAmount);
            }}
            autoFocus
            selectTextOnFocus
            style={{ backgroundColor: 'white' }}
          />
        </View>
      )}
    </View>
  );
}

export default TipEditor;

const styles = EStyleSheet.create({
  segmentStyle: {
    marginTop: 5,
    marginBottom: 10,
  },
  customTipInputs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
});
