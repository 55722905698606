import React from 'react';
import { Text, View } from 'native-base';
import { FormattedCurrency } from 'react-native-globalize';
import TabItemModifiers from './TabItemModifiers';
import { Colors } from '../../styles';

function TabOrderItem({ groupedItems, last }) {
  const qty = groupedItems.length;
  const item = groupedItems[0];

  const textStyle = {};
  let viewStyle = {};
  if (item.status === 'refunded') textStyle.textDecorationLine = 'line-through';
  if (!last) {
    viewStyle = {
      borderBottomWidth: 1,
      borderBottomColor: 'lightgray',
      marginBottom: 5,
      paddingBottom: 5,
    };
  }

  return (
    <View style={viewStyle}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={[{ width: 30 }]}>{qty} </Text>
        <Text style={[{ marginLeft: 5, flex: 1 }, textStyle]}>{item.itemName}</Text>
        {qty > 1 && (
          <Text
            style={[
              textStyle,
              {
                fontSize: 14,
                color: Colors.darkGray,
                marginLeft: 5,
                textAlign: 'right',
              },
            ]}
          >
            ({qty}
            x
            <FormattedCurrency value={item.tax_determined_total_cents / 100} />)
          </Text>
        )}
        <FormattedCurrency
          value={(qty * item.tax_determined_total_cents) / 100}
          style={[textStyle, { minWidth: 50, textAlign: 'right', marginLeft: 5 }]}
        />
      </View>
      <View style={{ marginLeft: 30 }}>
        <TabItemModifiers mods={item.mods} />
      </View>
    </View>
  );
}

export default TabOrderItem;
