/** Utility * */
import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { ActivityIndicator, Dimensions, Modal, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Button, Icon, List, Text, Avatar } from 'native-base';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { offsetInput, offsetOutput } from '../helpers/DateTimePickerHelper';

/** Components * */
import { Body, ListItem, Right } from '../bbot-component-library';
import Alert from './Alert';

import { modalStyles, styles as gStyle } from '../styles/Global';
import Colors from '../constants/Colors';

import API from '../api';

export default class DriverModal extends React.PureComponent {
  state = {
    capabilities: {},
  };

  componentDidMount() {
    this._mounted = true;
    API.getCourierCapabilities().then(capabilities => {
      if (this._mounted) {
        this.setState({
          capabilities,
        });
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { visible, order, onHide } = this.props;
    const { width } = Dimensions.get('window');

    const jobs = order?.driver_delivery_jobs || [];
    return (
      <Modal
        transparent
        visible={visible}
        statusBarTranslucent
      >
        <View style={gStyle.modalBackground}>
          <View style={[modalStyles.modal, { width: width >= 600 ? 600 : width }]}>
            <View style={modalStyles.header}>
              <Text>Driver Status</Text>
            </View>
            <View style={modalStyles.body}>
              <List
                dataArray={jobs}
                extraData={this.state.capabilities}
                keyExtractor={job => job.id}
                renderItem={this._renderJob}
              />
            </View>
            <View style={modalStyles.footer}>
              <Right>
                <Button
                  colorScheme="primary"
                  onPress={onHide}
                >
                  <Text>CLOSE</Text>
                </Button>
              </Right>
            </View>
          </View>
        </View>
      </Modal>
    );
  }

  _renderJob = ({ item: job }) => {
    const { order } = this.props;
    return (
      <DeliveryJob
        job={job}
        order={order}
        onChange={this._refresh}
        capabilities={this.state.capabilities[job.delivery_provider]}
      />
    );
  };

  _refresh = () => {
    this.forceUpdate();
  };
}

const DeliveryIcons = {
  relay: require('../assets/images/delivery/relay.png'),
  ddd: require('../assets/images/delivery/doordash.png'),
  stuart: require('../assets/images/delivery/stuart.png'),
  driveyello: require('../assets/images/delivery/driveyello.png'),
  default: (
    <Icon
      style={{ width: 55 }}
      as={MaterialIcons}
      name="drive-eta"
    />
  ),
  self: (
    <Icon
      as={Ionicons}
      style={{ width: 55 }}
      name="md-person"
    />
  ),
};

class DeliveryJob extends React.PureComponent {
  state = {
    showCalendar: false,
    updatingTime: false,
    cancellingJob: false,
  };

  render() {
    const { job, capabilities } = this.props;
    const pickup_time = moment(job.requested_pickup_time);
    let title = job.job_status;
    title += job.job_status === 'active' ? ` - ${job.pretty_status || job.driver_status}` : '';
    const unimplemented = !capabilities;
    let icon = capabilities?.icon || DeliveryIcons[job.delivery_provider] || DeliveryIcons.default;

    if (icon[0] === '/') icon = { uri: axios.defaults.baseURL + icon.substring(1) };

    return (
      <ListItem>
        {React.isValidElement(icon) ? (
          icon
        ) : (
          <Avatar
            size="12"
            source={icon}
          />
        )}
        <Body>
          <Text style={{ textTransform: 'capitalize' }}>{title}</Text>
          {job.job_status === 'active' && (
            <Text note="true">
              Requested for
              {pickup_time.format('LLL')}
            </Text>
          )}
          {unimplemented && <Text note="true">Integration Unimplemented</Text>}
        </Body>
        <Right style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {job.job_status === 'active' && !unimplemented && (
            <>
              {capabilities.cancel_job && (
                <Button
                  icon
                  colorScheme="danger"
                  onPress={this._cancelJob}
                  disabled={this.state.cancellingJob}
                >
                  {this.state.cancellingJob ? (
                    <ActivityIndicator
                      size="small"
                      style={{ marginHorizontal: 16 }}
                      color={Colors.primary}
                    />
                  ) : (
                    <Icon
                      as={MaterialIcons}
                      name="cancel"
                    />
                  )}
                </Button>
              )}
              {capabilities.update_job && (
                <Button
                  icon
                  colorScheme="primary"
                  onPress={this._selectDateTime}
                  disabled={this.state.updatingTime}
                  style={{ marginLeft: 5 }}
                >
                  {this.state.updatingTime ? (
                    <ActivityIndicator
                      size="small"
                      style={{ marginHorizontal: 16 }}
                      color={Colors.primary}
                    />
                  ) : (
                    <Icon
                      as={MaterialCommunityIcons}
                      name="clock-fast"
                    />
                  )}
                </Button>
              )}
            </>
          )}
        </Right>
        {this.state.showCalendar && (
          <DateTimePicker
            onChange={this._setPickupTime}
            value={offsetInput(pickup_time)}
            minimumDate={new Date()}
            mode="datetime"
          />
        )}
      </ListItem>
    );
  }

  _selectDateTime = () => {
    this.setState({
      showCalendar: true,
    });
  };

  _setPickupTime = async (event, timestamp) => {
    if (event?.type === 'dismissed') {
      this.setState({ showCalendar: false });
      return;
    }
    const { order, job, onChange } = this.props;
    this.setState({
      showCalendar: false,
      updatingTime: !!timestamp,
    });

    const customerTime = offsetOutput(timestamp);

    if (timestamp) {
      const response = await API.changeDriverTime(order, job, moment(customerTime));
      this.setState({
        updatingTime: false,
      });
      if (response.success) {
        onChange();
      } else {
        // Fail message;
      }
    }
  };

  _cancelJob = () => {
    const { order, job, onChange } = this.props;
    // Works on both Android and iOS
    Alert.alert(
      'Are you sure?',
      'This will cancel the driver.',
      [
        {
          text: 'Cancel',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: 'OK',
          onPress: async () => {
            this.setState({ cancellingJob: true });
            const response = await API.cancelDriver(order, job);
            this.setState({ cancellingJob: false });
            if (response.success) {
              onChange();
            } else {
              Alert.alert(
                'Error',
                "A server error occurred and the job couldn't be cancelled at this time",
              );
            }
          },
        },
      ],
      { cancelable: false },
    );
  };
}

const styles = EStyleSheet.create({
  modal: {},
});
