import React from 'react';
import _ from 'lodash';
import { Text, TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import KDSItemModifiers from './KDSItemModifiers';
import { Colors } from '../../../styles';
import API from '../../../api';
import KDSOrderItemProgress from './KDSOrderItemProgress';

export default class KDSOrderItem extends React.PureComponent {
  state = {
    made: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      made: props.item.made,
      showQty: props.showQty,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this._prefsListener = API.handheldDevice.on('update', () => this.forceUpdate());
  }

  componentWillUnmount() {
    this._mounted = false;
    this._prefsListener.remove();
  }

  render() {
    const { item, items } = this.props;
    const quantity = items?.length;
    const refunded = item.status === 'refunded';
    const textDecoration = refunded ? 'line-through' : 'none';
    const textColor = this.state.made || refunded ? '#b5b5b5' : '#000';

    const textStyle = { color: textColor, textDecorationLine: textDecoration };

    return (
      <TouchableWithoutFeedback onPress={this._onPress}>
        <View>
          <View
            style={styles.row}
            onLayout={this._onLayout}
          >
            {this.state.showQty && <Text style={[styles.quantity, textStyle]}>{quantity}</Text>}
            <View style={{ flex: 1 }}>
              <Text
                style={[styles.itemName, textStyle]}
                ellipsizeMode="tail"
              >
                {item.itemName}
              </Text>
              {item.mods ? (
                <KDSItemModifiers
                  mods={item.mods}
                  color={textColor}
                />
              ) : null}
              {!!item.special_instructions && (
                <Text style={{ marginLeft: 8, color: Colors.secondary, fontSize: 13 }}>
                  {item.special_instructions}
                </Text>
              )}
            </View>
          </View>
          <KDSOrderItemProgress item={item} />
        </View>
      </TouchableWithoutFeedback>
    );
  }

  _onLayout = event => {
    if (this._mounted) this.yOffset = event.nativeEvent.layout.y;
  };

  _onPress = () => {
    const { item, onPress } = this.props;
    if (item.order.is_snoozed) return;
    item.made = !item.made;
    this.setState({
      made: item.made,
    });
    onPress(item);
  };
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginHorizontal: 10,
    marginVertical: 8,
  },
  quantity: {
    width: 25,
  },
  itemName: {
    fontWeight: 'bold',
    fontSize: 16,
  },
});
