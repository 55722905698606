import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { View, Icon, Badge, Text } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../constants/Colors';

/**
 *
 * @param type
 * @param focused {Boolean} Passed in from react-navigation.
 * @param horizontal {Boolean} Passed in from react-navigation. True when device in Portrait mode.
 * @param tintColor {String} Passed in from react-navigation
 * @param disabled
 * @param name
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
export default function TabBarIcon({
  type = Ionicons,
  focused,
  horizontal,
  tintColor,
  disabled,
  name,
  badge,
}) {
  const color = disabled ? Colors.darkGray : tintColor;

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      {!!badge && (
        <Badge
          colorScheme="error"
          variant="solid"
          rounded="full"
          style={styles.badge}
        >
          {badge}
        </Badge>
      )}
      <Icon
        as={type}
        name={name}
        color={color}
        style={styles.icon}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    top: -10,
    right: -10,
    transform: [{ scale: 0.7 }],
    zIndex: 100,
  },
  icon: {
    marginBottom: 0,
    paddingBottom: 0,
    ...Platform.select({
      android: {
        fontSize: 15,
      },
      web: {
        fontSize: 20,
        width: '100%',
      },
    }),
  },
});
