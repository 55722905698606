import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import _ from 'lodash';
import { Fraction } from 'fractional';
import Model from './Model';

/**
 * An individual promotion. cents_added is negative
 * @typedef {{promotion_id: string, name: string, cents_added: number}} Discount
 */

/**
 * Item Part Model
 *  Used by SplitOrderScreen and displayed by SplitItem component
 *  Todo: Extend `Model`
 */
export default class ItemPart extends Model {
  /** @type {string} The UUID for this ItemPart */
  id = uuid.v4();

  /** @type {CartItem | OrderItem} */
  item = null;

  /** @type {number} The integer numerator of the fraction of this ItemPart */
  numerator = 1;

  /** @type {number} The integer denominator of the fraction of this ItemPart */
  denominator = 1;

  /** @type {CheckModel} The check that this ItemPart is currently a member of */
  check = null;

  /** @type {Discount[]} */
  discounts = [];

  pretax_total = null;

  tax_total = null;

  constructor(obj) {
    super();

    /** @type {CartItem | OrderItem} */
    this.item = obj.item;
    this.item._parts.push(this);
    this.denominator = obj.denominator || 1;

    // TODO: doesn't look like this is being used, can likely safely remove
    if (obj.constructor.className === 'CartItem') {
      obj = {};
    }
    // Inherits every property of obj.
    _.assign(this, obj);

    if (this.denominator === 1) {
      this.numerator = this.item.qty;
      this.pretax_total = this.item.getPretaxTotal() * this.numerator;
      this.tax_total = this.item.getTaxTotal() * this.numerator;
    }
  }

  /**
   * @returns {string} The UUID of the overall item (not of the ItemPart, use .id instead)
   */
  get itemId() {
    return this.item.id;
  }

  /**
   * @returns {string}
   */
  get menuItemId() {
    return this.item.menuItemId;
  }

  /**
   * @returns {string} The name of the item this ItemPart represents
   */
  get label() {
    return this.item.getName();
  }

  /**
   * @returns {number[]} The list of seat numbers that the overall Item belongs to
   */
  get seats() {
    return this.item.seat_numbers;
  }

  /**
   * @returns {string} The string representation of the fraction of this item in its respective cart
   */
  getFraction() {
    return new Fraction(this.numerator, this.denominator).toString();
  }

  /**
   * @returns {number} The numerical fraction of this item in its respective cart
   */
  getQty() {
    return this.numerator / this.denominator;
  }

  /**
   * Divides this ItemPart into `parts` equal pieces. Mutates this ItemPart and returns a list of the remaining `parts`-1
   * ItemParts in a list. Each ItemPart in the returned list is a distinct copy of this ItemPart.
   * @param {number} parts An integer number of ways to divide this Item
   * @returns {ItemPart[]} A list of length `parts`-1.
   */
  split(parts) {
    this.denominator *= parts;
    const newParts = [];

    const pretax_amounts = ItemPart.distributeByWeights(this.pretax_total, parts);
    const tax_amounts = ItemPart.distributeByWeights(this.tax_total, parts);

    this.pretax_total = pretax_amounts[0];
    this.tax_total = tax_amounts[0];

    for (let i = 1; i < parts; i++) {
      const newObj = new ItemPart(this);

      newObj.pretax_total = pretax_amounts[i];
      newObj.tax_total = tax_amounts[i];

      newParts.push(newObj);
    }

    return newParts;
  }

  get pretax_amount() {
    return this.hasOwnProperty('lineitem_pretax_cents')
      ? this.lineitem_pretax_cents
      : this.pretax_total;
  }

  get tax_amount() {
    return this.hasOwnProperty('lineitem_tax_cents') ? this.lineitem_tax_cents : this.tax_total;
  }

  /**
   * (In cents) The amount of money saved on this particular item given discounts
   * @returns {number} 0 or negative number
   */
  get discount_amount() {
    return _.sumBy(this.discounts, 'cents_added');
  }

  getPretaxAndTaxTotal() {
    return this.pretax_amount + this.tax_amount;
  }

  /**
   * @returns {number} A non-negative integer of cents. Cost + Discounts + Fees + Tax
   */
  getTotal = () =>
    this.api.main_customer.tax_inclusive_pricing
      ? this.pretax_amount + this.tax_amount
      : this.pretax_amount;

  toJSON() {
    return _.omit(this, ['check']);
  }

  static split(obj, parts) {}

  /**
   * Splits an integer `total_amount` into an array of integer amounts that are guaranteed to add up to it exactly. If
   * the weighs array is all zero, distributes the amount evenly. If the weights are given as an integer instead of an
   * array, it will divide as if weights was a list of that many ones, i.e. split into `weights` equal pieces.
   *
   * @example
   * ItemPart.distributeBuWeights (90, [1, 2])
   * // yields [30, 60]
   *
   * ItemPart.distributeBuWeights (90, 3)
   * // yeilds [30, 30, 30]
   *
   * @param {number} total_amount
   * @param {number | number[]} weights An integer number of ways to divide total amount, or list of numbers
   * @returns {*[]}
   */
  static distributeByWeights(total_amount, weights) {
    // Splits an integer amount into an array of integer amounts that are guaranteed to add up to it exactly.
    // If the weights array is all zero, distributes the amount evenly. E.g. distributeByWeights(90, [1,2]) yields [30,60]"""
    const distributions = [];
    let total_remaining = total_amount;
    if (typeof weights === 'number') weights = Array(weights).fill(1);

    let weight_remaining = _.sum(weights);

    weights.forEach((weight, i) => {
      const fraction_of_remaining_weight = weight_remaining == 0 ? 0 : weight / weight_remaining;
      weight_remaining -= weight;
      const distribution =
        i < weights.length - 1
          ? Math.round(total_remaining * fraction_of_remaining_weight)
          : total_remaining;
      total_remaining -= distribution;
      distributions.push(distribution);
    });

    return distributions.sort();
  }
}

ItemPart.propTypes = {
  check: PropTypes.instanceOf('CheckModel'),
};
