import React from 'react';
import { StyleSheet, View } from 'react-native';
import _ from 'lodash';
import color from 'color';
import Colors from '../../../constants/Colors';
import API from '../../../api';

function KDSOrderItemProgress({ item }) {
  if (!API.handheldDevice?.preferences?.kds_show_item_status) return null;
  const [step, steps] = item.progress;
  const startColor = color(Colors.primaryLight);
  return (
    <View style={KDSOrderItemProgress.styles.container}>
      {_.times(steps, num => {
        // let color = '#9881fc';
        // if (step === 1) color = Colors.ternary;
        // if (step === steps) color = Colors.primary;
        const color = startColor.darken((step / steps) * 0.7).string();
        return (
          <View
            key={num}
            style={[
              { backgroundColor: num + 1 <= step ? color : '#e7e7e7' },
              KDSOrderItemProgress.styles.step,
            ]}
          />
        );
      })}
    </View>
  );
}

export default KDSOrderItemProgress;

KDSOrderItemProgress.styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginLeft: 35,
    marginRight: 60,
    marginTop: -3,
  },
  step: {
    marginRight: 5,
    flex: 1,
    height: 3,
  },
});
