import React from 'react';
import { Pressable } from 'react-native';
import { Icon, Text, View } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FontAwesome } from '@expo/vector-icons';
import { Buttons, Colors } from '../styles';

function FilterPillToggle({ active, label, onPress, color = Colors.primary }) {
  const style = active
    ? {
        borderColor: color,
        color,
      }
    : {
        borderColor: Colors.darkGray,
        color: Colors.darkGray,
      };

  return (
    <Pressable
      onPress={onPress}
      style={[styles.pill, style]}
    >
      <Icon
        name={active ? 'check' : 'minus'}
        as={FontAwesome}
        size={6}
        style={[{ marginRight: 8 }, style]}
      />
      <Text style={[{ fontSize: 14 }, style]}>{label}</Text>
    </Pressable>
  );
}

export default FilterPillToggle;

const styles = EStyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 2,
    marginRight: 10,
  },
});
