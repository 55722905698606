import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { FormattedCurrency } from 'react-native-globalize';
import { Colors, Typography } from '../../styles';
import API from '../../api';

class OrderItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelected: false,
      pretax_cents: this.customReducer(0, 'pretax_cents', props.orderItemList),
    };
  }

  customReducer = (initialVal = 0, property, array) => {
    const reducer = (acc, cur) => cur[property] + acc;
    return array.reduce(reducer, initialVal);
  };

  render() {
    const { orderItemList } = this.props;

    const priceEach = orderItemList[0].tax_determined_total_cents / 100;
    return (
      <View style={[styles.orderItemContainer, this.props.isSelected ? styles.selected : {}]}>
        <View style={{ flex: 1 }}>
          <Text>{orderItemList[0].itemName}</Text>
          <Text style={styles.status}>{API.status_pretty_names[orderItemList[0].status]}</Text>
        </View>
        <Text style={{ textAlign: 'right' }}>{orderItemList.length}</Text>
        <Text style={{ width: '18%', textAlign: 'right' }}>
          <FormattedCurrency value={priceEach} />
        </Text>
        <Text style={{ width: '18%', textAlign: 'right' }}>
          <FormattedCurrency value={priceEach * orderItemList.length} />
        </Text>
      </View>
    );
  }
}

export default OrderItem;

const styles = StyleSheet.create({
  orderItemContainer: {
    flex: 1,
    paddingVertical: 10,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: Colors.gray1,
  },
  status: {
    ...Typography.italic,
    color: Colors.gray1,
  },
  selected: {
    backgroundColor: 'tomato',
  },
});
