import React, { Component } from 'react';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { Icon } from 'native-base';
import PropTypes from 'prop-types';
import EStyleSheet from 'react-native-extended-stylesheet';
import { FontAwesome } from '@expo/vector-icons';
import API from '../api';

export default class LandingPage extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    const isIos =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const forwardTo = props.navigation.getParam('forwardTo');
    if (!isIos || API.audioInitialized) props.navigation.navigate(forwardTo || 'Pin');
  }

  render() {
    return (
      <TouchableWithoutFeedback
        onPress={async () => {
          await API.audioPlayer.playAsync();
          await API.audioPlayer2.playAsync();
          API.audioInitialized = true;
          this.props.navigation.navigate('Pin');
        }}
      >
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <Icon
            style={{ fontSize: 50 }}
            name="volume-up"
            as={FontAwesome}
          />
          <Text style={{ marginTop: 10 }}>
            Touch to initialize Audio on iOS. A notification sound and an alarm sound will both
            play.
          </Text>
          <View style={styles.alert}>
            <Text style={styles.notification}>
              Order notification sounds
              <strong>will not work</strong> if the Terminal is in the background.
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = EStyleSheet.create({
  alert: {
    width: '80%',
    marginTop: 20,
    backgroundColor: 'rgba(255,0,0,0.1)',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(255,0,0,0.2)',
  },
  notification: {
    textAlign: 'center',
    fontSize: 20,
    color: 'rgb(160,0,0)',
    margin: 15,
  },
});
