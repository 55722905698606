/**
 * StripeCardModal
 *
 * Displays a modal informing the user to tap their card on the card reader or allows manual card entry.
 * Cross Platform (Web/Android)
 *
 * @author Gilles St-Cyr
 * @date 2019-03-24
 */

import React, { useEffect, useState } from 'react';
import { View, TouchableWithoutFeedback, Modal, KeyboardAvoidingView } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Button, Column, Icon, Row, Text, Alert, Spinner, Divider } from 'native-base';
import PropTypes from 'prop-types';
import StripeTerminal from 'react-native-stripe-terminal';
import { Ionicons } from '@expo/vector-icons';
import CardForm from '../StripeElements/CardForm';
import API from '../../api';
import Colors from '../../constants/Colors';
import { prompt } from '../Prompt/GlobalPrompt';

/** TODO: When Card is Declined, onSuccess is still called like wtf over * */

function StripeCardModal({
  visible,
  onSuccess,
  onClose,
  onError,
  reusable,
  paymentIntentParams,
  headerText,
  saveButtonText,
}) {
  const [readerConnected, setReaderConnected] = useState(false);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [showSimulatorButton, setShowSimulatorButton] = useState(false);
  const [methods, setMethods] = useState([]);
  const [mode, setMode] = useState(methods[0]);

  const toggleMode = () => {
    setMode(mode === 'manual' ? 'reader' : 'manual');
  };

  const handleError = error => {
    console.error(error);
    setError(error);
    if (error) {
      setStatus('Error');
      if (typeof onError === 'function') onError(error);
    }
  };

  const manualCardSuccess = result => {
    if (readerConnected) cancel();
    onSuccess(result);
  };

  const runSimulator = () => {
    StripeTerminal.setSimulatorConfiguration({
      testCardNumber: '4242424242424242',
    });
    startStripe();
  };

  const runSimulatorManualEntry = () => {
    prompt({
      title: 'Test Card #',
      defaultValue: '4242 4242 4242 4242',
      onSubmit: value => {
        const testCardNumber = value.replace(/[^\d]/g, '');
        StripeTerminal.setSimulatorConfiguration({
          testCardNumber,
        });
        startStripe();
      },
    });
  };

  const getContent = () => {
    if (mode === 'reader') {
      return (
        <View style={{ alignItems: 'center' }}>
          {!!headerText && <Text style={styles.headerText}>{headerText}</Text>}

          {status?.length ? (
            <>
              {status !== 'Error' && (
                <Spinner
                  size="lg"
                  my={6}
                />
              )}
              <Text style={styles.cardInstructionsText}>{status}</Text>
            </>
          ) : (
            <>
              <Icon
                size={20}
                as={Ionicons}
                name="card-outline"
                color="black"
                my={6}
              />

              <Text style={styles.cardInstructionsText}>PLEASE</Text>
              <Text style={styles.cardInstructionsText}>TAP / INSERT / SWIPE</Text>
              <Text style={styles.cardInstructionsText}>PAYMENT</Text>
            </>
          )}

          {showSimulatorButton && (
            <Alert
              w="100%"
              variant="subtle"
              colorScheme="warning"
              status="warning"
              my={4}
            >
              <Column
                space={2}
                flexShrink={1}
                w="100%"
              >
                <Row
                  flexShrink={1}
                  space={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Row
                    space={2}
                    flexShrink={1}
                    alignItems="center"
                  >
                    <Alert.Icon />
                    <Text>Test Mode Detected.</Text>
                  </Row>

                  {!status?.length && (
                    <>
                      <Button
                        onPress={runSimulator}
                        testID="chargeSimulatedCard"
                        bgColor="white"
                        key="test-card-btn"
                      >
                        <Text color="#3F3F3F">Test Card</Text>
                      </Button>
                      <Button
                        onPress={runSimulatorManualEntry}
                        testID="chargeSimulatedCardManual"
                        bgColor="white"
                        key="test-manual-card-btn"
                      >
                        <Text color="#3F3F3F">Test Manual</Text>
                      </Button>
                    </>
                  )}
                </Row>
              </Column>
            </Alert>
          )}
        </View>
      );
    }
    if (mode === 'manual') {
      return (
        <CardForm
          testID="stripeCardModal"
          paymentIntentParams={paymentIntentParams}
          saveButtonText={saveButtonText}
          reusable={reusable}
          onSuccess={manualCardSuccess}
          onError={handleError}
          onClose={onClose}
        />
      );
    }
    return (
      <View style={{ alignItems: 'center' }}>
        <Text style={styles.status}>No payment options available</Text>
      </View>
    );
  };

  const cancel = async () => {
    setStatus('Cancelling...');
    if (readerConnected) {
      try {
        if (reusable) await StripeTerminal.cancelReadReusableCard();
        else await StripeTerminal.cancelCollectPaymentMethod();
      } catch (err) {}
    } else {
      // API.cancelStripePaymentIntent...
    }
    // if(readerType === 'internet') API.cancelPaymentIntent()...
    onClose();
  };

  /**
   * Starts Stripe and prepares Reader for card capture
   * @returns {Promise<void>}
   */
  const startStripe = async () => {
    setStatus('Connecting...');
    setReaderConnected(true);
    try {
      if (reusable) {
        const { error, payment_method } = await StripeTerminal.readReusableCard();
        if (error) {
          handleError(error.message);
        } else {
          onSuccess(payment_method);
        }
      } else {
        const { error, clientSecret } = await StripeTerminal.createPaymentIntent(
          paymentIntentParams,
        );
        if (error) {
          handleError(error.message);
        } else {
          await collectPaymentMethod();
        }
      }
    } catch (err) {
      handleError(err.toString());
      API.sendCaughtError(err);
    }
  };

  /**
   * Step 2 of Physical Card Capture
   * @returns {Promise<void>}
   */
  const collectPaymentMethod = async () => {
    try {
      const { error } = await StripeTerminal.collectPaymentMethod();
      if (error) {
        handleError(error.message);
      } else {
        await confirmPayment();
      }
    } catch (err) {
      console.log('err collectPaymentMethod', StripeTerminal.cancelling);
      if (!StripeTerminal.cancelling) {
        handleError(`Card Read Failure: ${err.toString()}`);
        setStatus('Error');
      }
    }
  };

  /**
   * Step 3 of Physical Card Capture
   * @returns {Promise<void>}
   */
  const confirmPayment = async () => {
    setStatus('Processing...');
    try {
      const { paymentIntent, error } = await StripeTerminal.processPayment();
      if (error) {
        handleError(error.message);
      } else {
        onSuccess(paymentIntent);
      }
    } catch (err) {
      if (!StripeTerminal.cancelling) {
        setStatus('Error!');
        handleError(err.toString());
        console.error('Confirming Payment Intent failed: ', err.toString());
      }
    }
  };

  useEffect(() => {
    if (visible) {
      setStatus('');
      setError('');
      setShowSimulatorButton(false);
      const methods = [];
      if (StripeTerminal.readerConnected) methods.push('reader');
      if (API.config.allow_manual_card_entry) methods.push('manual');
      setMethods(methods);
      setMode(methods[0]);
      StripeTerminal.getConnectedReader().then(reader => {
        if (reader) {
          if (reader.serial_number.includes('SIMULATOR')) {
            setShowSimulatorButton(true);
          } else {
            startStripe();
          }
        } else setMode('manual');
      });
    }
  }, [visible]);

  useEffect(() => {
    const listener = StripeTerminal.on('ReaderStatus', status => setStatus(status));
    return () => {
      listener.remove();
      cancel();
    };
  }, []);

  return (
    <Modal
      visible={visible}
      transparent
    >
      <TouchableWithoutFeedback
        onPress={cancel}
        style={{ flex: 1 }}
      >
        <KeyboardAvoidingView style={styles.background}>
          <TouchableWithoutFeedback style={styles.modalContainer}>
            <View style={styles.modal}>
              <View style={{ width: '100%' }}>
                {getContent()}
                {!!error && (
                  <View style={styles.error}>
                    <Text style={styles.errorText}>{error}</Text>
                  </View>
                )}
              </View>
              {methods.length > 1 && (
                <>
                  <Divider />
                  <View style={{ alignItems: 'flex-end', width: '100%', paddingTop: 4 }}>
                    <Button
                      variant="ghost"
                      onPress={toggleMode}
                    >
                      <Text style={{ color: Colors.darkGray }}>
                        {mode === 'reader' ? 'Enter Card Manually' : 'Use Card Reader'}
                      </Text>
                    </Button>
                  </View>
                </>
              )}
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    </Modal>
  );
}

export default StripeCardModal;

const styles = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.9)',
  },

  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modal: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '99%',
    maxWidth: 400,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: 'black',
    backgroundColor: 'white',
    padding: 10,
  },

  cardInstructionsText: {
    fontSize: '1.25rem',
    textAlign: 'center',
    color: '#6F6F6F',
    fontWeight: '600',
  },

  headerText: {
    fontSize: '1.5rem',
    color: 'black',
    marginBottom: 25,
  },
  image: {
    marginVertical: 30,
    height: 140,
    width: '100%',
  },
  status: {
    fontSize: '2rem',
    marginBottom: 10,
    color: 'black',
  },
  error: {
    margin: 5,
  },
  errorText: {
    fontSize: '1rem',
    color: 'red',
    textAlign: 'center',
  },
});
