import React from 'react';
import { View } from 'react-native';
import { PaymentTypeStyles as styles } from '../../../styles/POSStyles';
import PaymentTypeCol from '../../../components/POS/Checkout/PaymentTypeCol';
import PaymentEntryCol from '../../../components/POS/Checkout/PaymentEntryCol';

function EnterPayment() {
  return (
    <View style={styles.EnterPaymentScreen}>
      <PaymentTypeCol />
      <PaymentEntryCol />
    </View>
  );
}

export default EnterPayment;
