import React, { useEffect } from 'react';
import { View } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useImmerReducer } from 'use-immer';
import { CancelToken } from 'axios';
import MenuCol from '../../components/POS/MenuCol';
import ItemSelectorCol from '../../components/POS/ItemSelectorCol';
import CartCol from '../../components/POS/CartCol';
import POSContext from '../../contexts/POSContext';
import { addItemToCart, editItemMods, setItemQty, setTipCents, updateFees } from './Utils';
import Checkout, { PAYMENT_TYPE } from './Checkout/Checkout';
import API from '../../api';

const source = CancelToken.source();

export const ITEM_SELECTOR_COL_VIEWS = {
  SELECT_ITEM: 'select_item',
  SELECT_MODIFIERS: 'select_modifiers',
  EDIT_ITEM: 'edit_item',
};

export const POS_REDUCER_ACTIONS = {
  SET_SELECTED_MENU_HEADING: 'SET_SELECTED_MENU_HEADING',
  SET_SELECTED_MENU_ITEM: 'SET_SELECTED_MENU_ITEM',
  SET_SELECTED_CART_ITEM: 'SET_SELECTED_CART_ITEM',
  SET_ITEM_SELECTOR_COL_VIEW: 'SET_ITEM_SELECTOR_COL_VIEW',
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  SET_QTY: 'SET_QTY',
  CLEAR_ORDER: 'CLEAR_ORDER',
  EDIT_CART_ITEM: 'EDIT_CART_ITEM',
  SET_POS_SCREEN: 'SET_POS_SCREEN',
  SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
  SET_TIP_CENTS: 'SET_TIP_CENTS',
  UPDATE_CART_FEES: 'UPDATE_CART_FEES',
  SET_IS_GETTING_FEES: 'SET_IS_GETTING_FEES',
};

export const POS_SCREEN = {
  MENU: 'MENU',
  CHECKOUT: 'CHECKOUT',
};

const POS_INITIAL_STATE = {
  itemSelectorColView: ITEM_SELECTOR_COL_VIEWS.SELECT_ITEM,
  menuItems: [],
  POSScreen: POS_SCREEN.MENU,
  selectedPaymentType: PAYMENT_TYPE.CREDIT_CARD,
  lastmodified: new Date(),
  isGettingFees: false,
};

const posReducer = (draft, action) => {
  const {
    SET_SELECTED_MENU_HEADING,
    SET_SELECTED_MENU_ITEM,
    SET_SELECTED_CART_ITEM,
    SET_ITEM_SELECTOR_COL_VIEW,
    ADD_ITEM_TO_CART,
    SET_QTY,
    CLEAR_ORDER,
    EDIT_CART_ITEM,
    SET_POS_SCREEN,
    SET_PAYMENT_TYPE,
    SET_TIP_CENTS,
    UPDATE_CART_FEES,
    SET_IS_GETTING_FEES,
  } = POS_REDUCER_ACTIONS;
  switch (action.type) {
    case SET_SELECTED_MENU_HEADING:
      draft.selectedMenuHeading = action.selectedMenuHeading;
      break;
    case SET_SELECTED_MENU_ITEM:
      draft.selectedMenuItem = action.menuItem;
      break;
    case SET_SELECTED_CART_ITEM:
      draft.selectedCartItem = action.cartItem;
      break;
    case SET_ITEM_SELECTOR_COL_VIEW:
      draft.itemSelectorColView = action.itemSelectorColView;
      break;
    case CLEAR_ORDER:
      delete draft.cart;
      break;
    case ADD_ITEM_TO_CART:
      addItemToCart(draft, action);
      break;
    case SET_QTY:
      setItemQty(draft, action);
      break;
    case EDIT_CART_ITEM:
      editItemMods(draft, action);
      break;
    case SET_POS_SCREEN:
      draft.POSScreen = action.POSScreen;
      break;
    case SET_PAYMENT_TYPE:
      draft.selectedPaymentType = action.paymentType;
      break;
    case SET_TIP_CENTS:
      setTipCents(draft, action);
      break;
    case UPDATE_CART_FEES:
      updateFees(draft, action);
      break;
    case SET_IS_GETTING_FEES:
      draft.isGettingFees = action.isGettingFees;
      break;
  }
};

function POS({}) {
  const [posStateValues, posDispatch] = useImmerReducer(posReducer, POS_INITIAL_STATE);
  const { cart, lastmodified } = posStateValues;

  useEffect(() => {
    if (cart) {
      updateCartFees(cart);
    }
  }, [cart, lastmodified]);

  const updateCartFees = async cartToUpdate => {
    try {
      posDispatch({
        type: POS_REDUCER_ACTIONS.SET_IS_GETTING_FEES,
        isGettingFees: true,
      });
      const { fees } = await API.getCartPrice(cartToUpdate, null, source.token);
      posDispatch({
        type: POS_REDUCER_ACTIONS.UPDATE_CART_FEES,
        fees,
      });
    } catch (error) {
      console.error(error);
    } finally {
      posDispatch({
        type: POS_REDUCER_ACTIONS.SET_IS_GETTING_FEES,
        isGettingFees: false,
      });
    }
  };

  return (
    <POSContext.Provider value={{ POSValues: posStateValues, POSDispatch: posDispatch }}>
      {posStateValues.POSScreen === POS_SCREEN.MENU ? (
        <View style={styles.POS}>
          <MenuCol />
          <ItemSelectorCol />
          <CartCol />
        </View>
      ) : (
        <Checkout />
      )}
    </POSContext.Provider>
  );
}

const styles = EStyleSheet.create({
  POS: {
    backgroundColor: '#1D1D1D',
    flex: 1,
    flexDirection: 'row',
  },
});

export default POS;
