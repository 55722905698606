import _ from 'lodash';
import React from 'react';
import { View } from 'native-base';
import TabOrderItem from './TabOrderItem';

function TabSummaryView({ tab }) {
  // Group All Items across all Orders by
  const allItems = _.groupBy(
    [].concat(..._.map(tab.orders, 'items')),
    i => i.order_item_hash + (i.status === 'refunded' ? 'ref' : ''),
  );
  const sorted = _.orderBy(Object.values(allItems), ['[0]itemName']);
  return (
    <View>
      {sorted.map((group, i, z) => (
        <TabOrderItem
          key={`itemGroup_${i}`}
          groupedItems={group}
          last={i === z.length - 1}
        />
      ))}
    </View>
  );
}

export default TabSummaryView;
