import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import OrderHelper from '../helpers/OrderHelper';

export default class OrderItemModifiers extends PureComponent {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      sortedModGroups: OrderHelper.prepareGroupedMods(props.item.mods),
    };
  }

  render() {
    const { item, style } = this.props;
    if (!item.mods.length) return null;
    const { sortedModGroups } = this.state;

    const someModsWithoutHeadingsShown = sortedModGroups.filter(
      group => !group.showOnTicket,
    ).length;

    return (
      <View style={{ marginTop: someModsWithoutHeadingsShown ? 6 : 0 }}>
        {sortedModGroups.map(group => (
          <View key={`${group.modifiers[0]?.modifier?.heading.id}-heading.id`}>
            {group.showOnTicket && (
              <Text style={[style, { marginTop: 6 }]}>{` ${group.groupName}`}</Text>
            )}
            {group.modifiers.map((mod, idx) => (
              <React.Fragment key={`${idx}-mod.id`}>
                <Text style={[style, { fontWeight: 'bold' }]}>
                  {' '}
                  {mod.name || mod.modifier?.name_for_bartender}
                </Text>
                {mod.mods?.length > 0 && (
                  <OrderItemModifiers
                    item={mod}
                    style={style}
                  />
                )}
              </React.Fragment>
            ))}
          </View>
        ))}
      </View>
    );
  }
}
