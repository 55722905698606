import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Text, View } from 'native-base';
import { ActivityIndicator } from 'react-native';
import FlashMessage, { showMessage } from 'react-native-flash-message';
import moment from 'moment';
import {
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { FooterTab, Footer } from '../bbot-component-library';
import Colors from '../constants/Colors';
import RefundReasonModal from './RefundReasonModal';
import SnoozeOrderModal from './KDS/SnoozeOrderModal';
import { SendReceiptModal } from './index';
import DriverModal from './DriverModal';
import EditOrderModal from './Modals/EditOrderModal';
import OrderHelper from '../helpers/OrderHelper';
import API from '../api';

// TODO: Hide modals when order changes?

function OrderActionsMenu({
  order,
  selectedItems,
  setSelectItems,
  closeModal,
  dir = 'horizontal',
}) {
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showSendReceiptModal, setShowSendReceiptModal] = useState(false);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [printingReceipt, setPrintingReceipt] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [unSnoozing, setUnSnoozing] = useState(false);
  const flash = useRef(null);

  const refundSuccess = () => {
    setShowRefundModal(false);
    setSelectItems([]);
    if (flash.current) {
      flash.current.showMessage({
        floating: true,
        position: 'top',
        type: 'success',
        message: 'Refund completed successfully',
      });
    }
  };

  const printTicket = () => {
    OrderHelper.printTicket(order, false).then(res => {
      if (res && flash.current) {
        flash.current.showMessage({
          position: 'top',
          floating: true,
          type: 'success',
          message: 'Ticket sent to printer',
        });
      }
    });
  };

  const printReceipt = async () => {
    setPrintingReceipt(true);
    let response;
    const { has_printers } = API.menuData.customer;
    if (has_printers && !API.config.force_pdf_printing) {
      response = await API.printTicket(order, true, true);
    } else {
      response = await API.printReceiptLocal(order);
    }
    setPrintingReceipt(false);
    if (!response.errorCode && API.menuData.customer.has_printers) {
      if (flash.current) {
        flash.current.showMessage({
          floating: true,
          position: 'top',
          type: 'success',
          message: 'Receipt sent to printer',
        });
      }
    }
    return response;
  };

  const resendText = async () => {
    setSendingSMS(true);
    const result = await API.textPatronAgain(order);
    setSendingSMS(false);

    const message = result.success
      ? 'Text notification has been re-sent to Patron'
      : result.warning;

    if (flash.current) {
      flash.current.showMessage({
        position: 'top',
        floating: true,
        type: result.success ? 'success' : 'warning',
        message,
      });
    } else {
      // flashMessageCallback?
    }
  };

  const unSnooze = async () => {
    setUnSnoozing(true);
    // todo: do something with response if fails
    const response = await API.snoozeOrder(order, null);
    setUnSnoozing(false);
    closeModal();
  };

  const editOrder = async fieldsToEdit => {
    const result = await API.modifyOrder(order, fieldsToEdit);
    const message = result?.success
      ? 'Changes saved'
      : result?.error?.errorCode || 'Error: changes have not been saved';

    if (result && flash.current) {
      flash.current.showMessage({
        position: 'top',
        floating: true,
        type: result?.success ? 'success' : 'danger',
        message,
      });
    }

    setShowEditOrderModal(false);
  };

  return (
    <View>
      <Footer style={{ backgroundColor: Colors.primary }}>
        <SideMenuButton
          icon="cash-refund"
          iconType={MaterialCommunityIcons}
          label="Refund"
          onPress={() => setShowRefundModal(true)}
        />
        <SideMenuButton
          icon="ticket"
          iconType={Entypo}
          label="Re-Print Ticket"
          onPress={printTicket}
        />
        <SideMenuButton
          icon="receipt"
          iconType={MaterialIcons}
          label="Print Receipt"
          onPress={printReceipt}
          disabled={printingReceipt}
          processing={printingReceipt}
        />
        <SideMenuButton
          icon="send"
          iconType={FontAwesome}
          label="Send Receipt"
          onPress={() => setShowSendReceiptModal(true)}
        />
        <SideMenuButton
          icon="sleep-off"
          iconType={MaterialCommunityIcons}
          label="Un-Snooze"
          onPress={unSnooze}
          processing={unSnoozing}
          shown={order.is_snoozed}
        />
        <SideMenuButton
          icon="sleep"
          iconType={MaterialCommunityIcons}
          label="Snooze"
          shown={order.user_desired_time > moment()}
          onPress={() => setShowSnoozeModal(true)}
        />
        <SideMenuButton
          icon="sms"
          iconType={MaterialIcons}
          label="Re-Send SMS"
          shown={order.kds_closed}
          onPress={resendText}
          processing={sendingSMS}
        />
        <SideMenuButton
          icon="car"
          iconType={FontAwesome}
          label="Manage Driver"
          shown={!order.time_closed && !!order.driver_delivery_jobs.length}
          onPress={() => {
            setShowDriverModal(true);
          }}
        />
        <SideMenuButton
          icon="edit"
          iconType={MaterialIcons}
          label="Edit"
          onPress={() => setShowEditOrderModal(true)}
        />
      </Footer>

      <RefundReasonModal
        order={order}
        visible={showRefundModal}
        items={order.items.filter(i => i.status !== 'refunded')}
        selectedItems={selectedItems.map(i => i.orderitemid)}
        onCancel={() => setShowRefundModal(false)}
        onSuccess={refundSuccess}
      />

      {!!order.user_desired_time && (
        <SnoozeOrderModal
          visible={showSnoozeModal}
          order={order}
          onCancel={() => setShowSnoozeModal(false)}
          onSuccess={closeModal}
        />
      )}

      <SendReceiptModal
        visible={showSendReceiptModal}
        order={order}
        onCancel={() => setShowSendReceiptModal(false)}
        onSave={() => {
          setShowSendReceiptModal(false);
          if (flash.current) {
            flash.current.showMessage({
              floating: true,
              position: 'top',
              type: 'success',
              message: 'Receipt sent!',
            });
          }
        }}
      />

      <DriverModal
        visible={showDriverModal}
        order={order}
        onHide={() => setShowDriverModal(false)}
      />

      <EditOrderModal
        visible={showEditOrderModal}
        order={order}
        onSave={editOrder}
        onCancel={() => setShowEditOrderModal(false)}
      />

      <FlashMessage ref={flash} />
    </View>
  );
}

export default OrderActionsMenu;

function SideMenuButton({
  color = Colors.primary,
  onPress,
  icon,
  iconType = Ionicons,
  label,
  processing,
  disabled,
  onDisabledPress,
  shown = true,
}) {
  if (!shown) return null;
  return (
    <FooterTab>
      <Button
        vertical
        onPress={onPress}
        disabled={processing || disabled}
        style={{ alignItems: 'center' }}
      >
        <View style={{ alignItems: 'center' }}>
          {processing ? (
            <ActivityIndicator color={Colors.primary} />
          ) : (
            <Icon
              name={icon}
              as={iconType}
              size={6}
              style={{ color: '#C0C6E7' }}
            />
          )}
        </View>
        <Text
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            textAlign: 'center',
            color: '#C0C6E7',
          }}
          numberOfLines={2}
        >
          {label}
        </Text>
      </Button>
    </FooterTab>
  );
}

/**
 (disabled && onDisabledPress) && (
 <HelpIcon onPress={onDisabledPress}/>
 )
 */
