// Utils
import trackSegment from '../trackSegment';
import API from '../../api';
import { getSentryEnv } from '../../constants/Config';

// Track custom events using NewRelic as a destination instead of regular Segment.
export const trackNewrelic = (eventName, body) => {
  // uncomment to log warnings for all tracking events
  // console.warn('::: Newrelict Event Fired :::', eventName, body)
  const newrelic = window?.newrelic;
  if (newrelic) {
    newrelic.addPageAction(eventName, body);
  }
};

// This tells us if the website is loaded using the BOS Builder (aka not a real checkout)
export const getInIframeContext = () => {
  if (window?.location) {
    return {
      in_iframe: window.location !== window.parent.location,
    };
  }
  return {};
};

export const getDomainContext = () => {
  if (window?.location) {
    return {
      domain: {
        href: window.location.href,
        hostname: window.location.hostname,
        pathname: window.location.pathname,
      },
    };
  }
  return {};
};

export const getEnvironmentContext = (context = {}) => {
  if (context.app) {
    return {
      app: {
        ...context.app,
        environment: process.env.REACT_APP_BBOT_ENVIRONMENT,
      },
      ...context,
    };
  }
  return {
    app: {
      environment: process.env.REACT_APP_BBOT_ENVIRONMENT,
    },
    ...context,
  };
};

export const getDeviceContext = () => {
  if (!window) return {};
  const deviceId = API.handheldDevice.id;
  return { device_id: deviceId };
};

export const trackHelper = (eventName, body, context) => {
  if (typeof window === 'undefined') {
    return;
  }

  const device = getDeviceContext();
  const domain = getDomainContext();
  const iframe = getInIframeContext();

  const payload = {
    ...body,
    domain,
    iframe,
    device,
    environment: getSentryEnv(),
  };

  const formattedContext = {
    ...getEnvironmentContext(context),
    ...getDeviceContext(),
    domain,
    iframe,
  };

  const injectedEventName = `bb_${eventName}`;

  trackSegment(injectedEventName, payload, formattedContext);
};

export const track = (eventName, body, context) => {
  trackHelper(eventName, body, context);
};

export const trackErrorAppear = payload => {
  track('error_appear', {
    ...payload,
    internalErrorDetails: payload.internalErrorDetails ?? payload.errorMessage,
    isGenericError: payload.isGenericError ?? true,
    isErrorExpected: payload.isErrorExpected ?? false,
  });
};
