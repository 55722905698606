import { Button } from 'native-base';
import React from 'react';
import { ButtonStyle } from '../../../styles/POSStyles';

function ApprovePaymentButton({ label, onPress, isLoading = false }) {
  return (
    <Button
      onPress={onPress}
      style={ButtonStyle('primary')}
      isLoading={isLoading}
    >
      {label}
    </Button>
  );
}

export default ApprovePaymentButton;
