import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import _ from 'lodash';

/**
 * FuzzyField component
 *
 * eg: <FuzzyField fuzzy={this.state.loading}><Text>This is blurred when loading is true</Text></FuzzyField>
 * @author Gilles St-Cyr
 *
 */

export default class FuzzyField extends Component {
  static propTypes = {
    fuzzy: PropTypes.bool,
    style: PropTypes.object,
  };

  state = {
    toggle: true,
    cubes: 10,
  };

  render() {
    const { fuzzy, children, style, ...rest } = this.props;
    return (
      <View
        style={[style]}
        {...rest}
      >
        {fuzzy && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {_.range(25).map(i => (
              <AnimatedCube key={i} />
            ))}
          </View>
        )}
        <View style={{ opacity: fuzzy ? 0.3 : 1 }}>{children}</View>
      </View>
    );
  }

  _onLayout = ({ nativeEvent }) => {
    const { width, height } = nativeEvent.layout;
    const cubes = (width * height) / 100;
    this.setState({ cubes });
  };
}

/**
 * @returns {*}
 * @constructor
 */

function AnimatedCube() {
  const delay = _.random(0, 500, false);
  return (
    <Animatable.View
      animation="flash"
      easing="linear"
      direction="alternate"
      useNativeDriver
      iterationCount="infinite"
      duration={1000}
      delay={delay}
      style={{
        width: '20%',
        height: '20%',
        backgroundColor: '#8b8b8b',
      }}
    />
  );
}
