import axios from 'axios';
import { Container, Icon, Text } from 'native-base';
import React, { Component } from 'react';
import { View, FlatList, ActivityIndicator, StyleSheet } from 'react-native';
import PortScan from 'react-native-find-local-devices';
import { Ionicons } from '@expo/vector-icons';
import { Body, ListItem, Right } from '../bbot-component-library';
import API from '../api';
import Colors from '../constants/Colors';
import Servers from '../constants/Servers';

/**
 * TODO: Add port 8000 scanner for local network and display results:
 * https://shift8web.ca/2019/03/how-to-build-a-port-scanner-with-javascript-using-react-native/
 * https://github.com/RichardRNStudio/react-native-find-local-devices
 */

export default class ServerChooser extends Component {
  static navigationOptions = {
    title: 'Choose Server',
  };

  state = {
    loading: true,
    devices: [],
    scanning: null,
  };

  async componentDidMount() {
    const { navigation } = this.props;

    /**
     * Bypass the server selection screen when a logged in user simply refreshes the screen. Server selection is only
     * performed when (1) logged out, or (2) using the Settings menu option. `API.isLoggedIn()` should only be called on
     * the `ServerChooser` route. Otherwise, if you call the function on the `ServerChooserSettings` route, then all
     * `CustomTabButton` components in `MainTabNavigator` will fail to render (due to `API.getConfig()` returning null).
     */

    // TODO: If the server is offline, this will kick you to Server Chooser when it shouldn't
    if (navigation.state.routeName === 'ServerChooser' && (await API.isLoggedIn())) {
      navigation.navigate('Auth');
    } else {
      this.setState({ loading: false });
      this.startScanning();
    }
  }

  startScanning = () => {
    this._portScan = new PortScan({
      ports: [8000],
      onDeviceFound: device => {
        this.setState({
          devices: [...this.state.devices, device.ipAddress],
        });
      },
      onResults: device => {},
      onCheck: device => {
        this.setState({
          scanning: `${device.ipAddress}:${device.port}`,
        });
      },
      onFinished: device => {
        this.startScanning();
      },
      onError: device => {},
    });
  };

  componentWillUnmount() {
    if (this._portScan) this._portScan.stop();
  }

  render() {
    return (
      <Container>
        {this.state.loading ? (
          <View style={styles.container}>
            <ActivityIndicator
              size="large"
              color={Colors.primary}
            />
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            <FlatList
              data={['Staging', 'Demo', 'Dev', 'Localhost', ...this.state.devices]}
              keyExtractor={item => item}
              renderItem={this._renderItem}
            />
            {!!this.state.scanning && (
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 5,
                  borderTopWidth: 1,
                  backgroundColor: 'lightgray',
                }}
              >
                <Text>
                  Scanning:
                  {this.state.scanning}
                </Text>
              </View>
            )}
          </View>
        )}
      </Container>
    );
  }

  _renderItem = ({ item }) => {
    const apiURL = Servers[item.toLowerCase()] || `http://${item.toLowerCase()}:8000/`;
    const { baseURL } = axios.defaults;
    const { routeName } = this.props.navigation.state;

    return (
      <ListItem
        accessibilityLabel={item}
        onPress={() => this._setServer(apiURL)}
        selected={routeName === 'ServerChooserSettings' && baseURL === apiURL}
      >
        <Body>
          <Text>{item}</Text>
          <Text
            note
            numberOfLines={1}
          >
            {apiURL}
          </Text>
        </Body>
        <Right>
          <Icon
            as={Ionicons}
            name="arrow-forward"
          />
        </Right>
      </ListItem>
    );
  };

  async _setServer(apiURL) {
    this._portScan.stop();
    await API.setServer(apiURL);
    this.props.navigation.navigate('Auth');
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
