import React, { useEffect, useState } from 'react';
import { Checkbox, Stack, FormControl, List, Text, View, Box } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import Required from './Required';
import { styles as fieldStyles } from './styles';

function CheckboxField({
  field,
  index,
  fieldValue,
  setFieldValue,
  valid,
  setValid,
  highlightError,
  ...rest
}) {
  const [touched, setTouched] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState(fieldValue);

  const validator = value => (field.required_on_handheld ? value?.length > 0 : true);

  useEffect(() => {
    setValid(validator(fieldValue));
  }, []);

  useEffect(() => {
    if (highlightError) {
      setTouched(true);
      setValid(validator(fieldValue));
    }
  }, [highlightError]);

  if (!field.choices || field.choices.length === 1) {
    const choice = field.choices ? field.choices[0] : [{ label: 'yes' }];
    return (
      <Stack
        style={[fieldStyles.fieldItem, styles.checkboxContainer]}
        {...rest}
      >
        <View style={{ flexDirection: 'row' }}>
          <Checkbox.Group
            onChange={value => setFieldValue(value)}
            values={fieldValue}
          >
            <Checkbox
              testID={`extraCheckoutInfo-${index}`}
              value={choice.label}
            />
          </Checkbox.Group>
          <Text
            style={[
              fieldStyles.fieldLabel,
              styles.checkboxLabel,
              !valid && touched && fieldStyles.invalidFieldLabel,
            ]}
          >
            {field.name_for_patron}
            <Required show={field.required_on_handheld} />
          </Text>
        </View>
      </Stack>
    );
  }
  return (
    <Stack
      stackedLabel
      {...rest}
    >
      <Text style={[fieldStyles.fieldLabel, !valid && touched && fieldStyles.invalidFieldLabel]}>
        {field.name_for_patron}
        <Required show={field.required_on_handheld} />
      </Text>
      <View style={[{ width: '100%' }, highlightError && !valid && fieldStyles.errorField]}>
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={value => {
            setFieldValue(value);
            setValid(validator(value));
            setTouched(true);
          }}
          defaultValue={fieldValue}
        >
          {field.choices.map((choice, i) => (
            <Box style={{ margin: 10 }}>
              <Checkbox
                key={`${field.key}_choice_${i}`}
                value={choice.label}
              >
                {choice.label}
              </Checkbox>
            </Box>
          ))}
        </Checkbox.Group>
      </View>
    </Stack>
  );
}

export default CheckboxField;

const styles = EStyleSheet.create({
  checkboxContainer: {
    paddingVertical: 10,
  },
  checkboxLabel: {
    marginLeft: 20,
  },
});
