import _ from 'lodash';
import moment from 'moment';
import { Station, Location, Order, SeatedGroup, Customer, MenuData } from '../models';
import DB, { resetDB } from '../services/PouchDB';

/**
 * CacheManager
 * @author Gilles St-Cyr
 * @description CacheManager is a class which can manage cached instances of objects
 *
 * @todo - Cleanup (doc.is_json should no longer be necessary)
 *
 */

export default class CacheManager {
  constructor(api) {
    this.api = api;
  }

  /**
   * Need app to use API.cache.orders, etc...
   * @param order
   */

  loadOrder = order => {
    // In these cases, we DON'T want inserts into the DB.
    if (this.api._orders[order.orderId]) this.api._orders[order.orderId].update(order, false);
    else this.api._orders[order.orderId] = new Order(order, false);
  };

  saveHash = async (key, value) =>
    DB()
      .upsert(key, doc => {
        doc.data = value;
        doc.type = key;
        return doc;
      })
      .catch(err => {});

  saveData = async (key, data, hash, is_json, callback) => {
    try {
      const encodedData = is_json ? JSON.parse(JSON.stringify(data)) : data;
      const res = await DB().upsert(key, doc => {
        doc.data = encodedData;
        doc.hash = hash;
        doc.is_json = is_json;
        doc.callback = callback;
        doc.type = key;
        return doc;
      });
      return res;
    } catch (err) {
      console.log(err);
      this.api.sendCaughtError(err);
      return false;
    }
  };

  removeData = async (type, entityIdentifier, idValue) => {
    try {
      const docs = await DB().allDocs({
        include_docs: true,
      });
      if (docs.total_rows) {
        const docToRemove = docs.rows.find(row => {
          const { doc } = row;
          const { data } = doc;
          return doc.type === type && data[entityIdentifier] === idValue;
        });
        if (docToRemove) {
          const { doc } = docToRemove;
          await DB().remove(doc);
        }
      }
      return true;
    } catch (err) {
      if (err.name === 'conflict') {
        console.warn(
          `Potential PouchDB conflicts when removing type - ${type}. Proceeding anyway.`,
        );
        return false;
      }
      console.log(err);
      this.api.sendCaughtError(err);
      return false;
    }
  };

  /**
   * Gets called on App Load / Page Refresh to hydrate the localStorage from the last known good state
   * @returns {Promise<void>}
   */
  load = async () => {
    try {
      const docs = await DB().allDocs({
        include_docs: true,
      });
      if (docs.total_rows > 1) {
        const TwoDaysAgo = moment().subtract(48, 'hours');

        const config = docs.rows.find(r => r.key === 'config');
        if (config) this.api.updateConfig(config.doc.data, config.doc.hash);
        const menuData = docs.rows.find(r => r.key === 'menuData');
        if (menuData?.doc.hash === '*') {
          this.api.updateMenuData(menuData.doc.data);
        }

        for await (const row of docs.rows) {
          const { doc } = row;
          if (doc._deleted) continue;
          const { data } = doc;
          switch (doc.type || row.key) {
            case 'order':
              // We only want to load orders which are newer than 2 days old
              // cleanup orders that use the minified `r_` prefix
              // todo: remove this in a month or so (June/2022)
              if (
                row.key.includes('order_') &&
                moment(data.user_desired_time || data.time).isAfter(TwoDaysAgo)
              ) {
                this.loadOrder(data);
              } else {
                DB()
                  .remove(row)
                  .catch(e => {});
              }
              break;
            case 'stations':
              this.api.updateStations(data, doc.hash);
              break;
            case 'lite_stations':
              this.api.updateLiteStations(data, doc.hash);
              break;
            case 'locations':
              if (Array.isArray(data)) {
                this.api.updateLocations(data, doc.hash);
              }
              break;
            case 'last_poll':
              this.api._last_poll = data;
              this.api._pollCompleteTime = moment(data);
              break;
            case 'customers':
              this.api.updateCustomers(data, doc.hash);
              break;
            case 'bartenders':
              this.api.updateBartenders(data, doc.hash);
              break;
            case 'seated_groups':
              this.api.updateSeatedGroups(data, doc.hash);
              break;
            case 'menu':
              if (this.api.config && this.api.config.menus.includes(data.menuId)) {
                this.api.updateMenu(data);
              } else {
                await DB().remove(doc);
              }
              break;
            case 'tabs':
              this.api.updateTabs(data, doc.hash);
              break;
            case 'notices':
              this.api.updateNotices(data, doc.hash);
              break;
            case 'hash':
              this[row.key] = row.data;
              break;
          }
        }
      }
    } catch (err) {
      this.api.sendCaughtError(err);
      await resetDB();
    }
  };

  clear = () => {};
}
