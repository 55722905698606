import { createStackNavigator } from 'react-navigation-stack';
import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import BotControlScreen from '../screens/BotControl';
import TabBarIcon from '../components/TabBarIcon';
import { defaultNavigationOptions } from './Defaults';
import TabBarLabel from '../components/TabBarLabel';

const BotControlStack = createStackNavigator(
  {
    BotControlScreen,
  },
  {
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__botControl"
        />
      ),
      tabBarTestID: 'botcontrol',
      tabBarIcon: props => (
        <TabBarIcon
          {...props}
          type={MaterialCommunityIcons}
          name="robot"
        />
      ),
    }),
    defaultNavigationOptions,
  },
);

export default BotControlStack;
