import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import { Icon, Text, Button } from 'native-base';
import { Entypo } from '@expo/vector-icons';
import { Body, ListItem, Right, Header, SearchBar } from '../bbot-component-library';

import Alert from '../components/Alert';
import Loader from '../components/Loader';

import API from '../api';
import Colors from '../constants/Colors';
import { escapeRegExp } from '../helpers/HelperFunctions';
import { trackClickSelectCustomer } from '../segment/tracking-events/homepage';

export default class CustomerChooser extends Component {
  static navigationOptions = {
    title: 'Choose Customer',
  };

  static propTypes = {};

  state = {
    all_customers: [], // non-filtered customers
    processing: true,
    search: '',
  };

  componentDidMount() {
    this._mounted = true;
    const { navigation } = this.props;
    const customers = navigation.getParam('customers');

    if (!customers) {
      this.getAllowedCustomers();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async getAllowedCustomers() {
    this.setState({
      processing: true,
    });
    const result = await API.getAllowedCustomers();
    // TODO: if (!result.success) show error + retry button
    if (this._mounted) {
      const customers = result.allowed_customers || [];
      this.setState({
        processing: false,
        all_customers: customers,
      });
    }
  }

  render() {
    const search = escapeRegExp(this.state.search);
    const regexp = new RegExp(search, 'gi');
    return (
      <View style={{ flex: 1 }}>
        <Header
          style={{
            backgroundColor: Colors.darkGray,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 8,
          }}
        >
          <SearchBar
            onChangeText={this._filter}
            testID="search-customer-input"
          />
        </Header>
        <View style={{ flex: 1, width: '100%' }}>
          {this.state.processing && <Loader />}
          <FlatList
            data={this.state.all_customers.filter(c => c.name_for_owner.search(regexp) >= 0)}
            renderItem={this._listItem}
            testID="customerScrollView"
            keyExtractor={item => item.customer_id}
          />
        </View>
      </View>
    );
  }

  _filter = text => {
    this.setState({
      search: text,
    });
  };

  _listItem = ({ item }) => (
    <ListItem>
      <Body style={{ alignSelf: 'center', alignItems: 'flex-start' }}>
        <Text>{item.name_for_owner}</Text>
      </Body>
      <Right style={{ alignSelf: 'center', alignItems: 'flex-end' }}>
        <Button
          icon
          variant="ghost"
          onPress={() => {
            trackClickSelectCustomer();
            this._setCustomer(item);
          }}
          testID={`cust_${item.name_for_owner}`}
        >
          <Icon
            name="arrow-with-circle-right"
            size={6}
            as={Entypo}
          />
        </Button>
      </Right>
    </ListItem>
  );

  _setCustomer = async customer => {
    const { navigation } = this.props;

    this.setState({ processing: true });
    const { success, error } = await API.selectCustomer(customer.customer_id);
    if (success) {
      navigation.navigate('AuthLoading');
    } else {
      Alert.alert('Error', `Selecting customer failed: ${error}`);
    }
    if (this._mounted) this.setState({ processing: false });
  };
}
