import React, { useContext, useState } from 'react';
import { Text, View, Platform } from 'react-native';
import { Alert, Row } from 'native-base';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import POSContext, { POSCheckoutScreenContext } from '../../../contexts/POSContext';
import { POS_REDUCER_ACTIONS } from '../../../screens/POS';
import { CHECKOUT_REDUCER_ACTIONS, CHECKOUT_SCREEN } from '../../../screens/POS/Checkout/Checkout';
import { capturePaymentMethod } from '../../Stripe/StripeHelper';
import ApprovePaymentButton from './ApprovePaymentButton';
import TipSection from './TipSection';
import TotalSection from './TotalSection';
import API from '../../../api';

import { CreditCardEntryStyles as styles } from '../../../styles/POSStyles';

import { CheckoutError } from '../../../errors/Checkout';

function CreditCardEntry({ manual = false }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkoutErrors, setCheckoutErrors] = useState([]);

  const {
    POSValues: { cart },
    POSDispatch,
  } = useContext(POSContext);

  const { checkoutDispatch } = useContext(POSCheckoutScreenContext);
  const totalCentsDue = (cart?.checks[0].total ?? 0) + (cart?.tip_amount ?? 0);

  const stripe = useStripe();
  const elements = useElements();

  const { PageContainer, ManualCardContainer } = styles;

  const handleTipSelect = tipCents => {
    POSDispatch({
      tipCents,
      type: POS_REDUCER_ACTIONS.SET_TIP_CENTS,
    });
  };

  /**
   * Inspired from StripeHelper.js
   * @param {} paymentIntentParams We need the amount + currency + statement descriptor for the stripe payment intent call.
   * @param {} location We get the seated_group from the location object.
   * @returns
   */
  const captureManualCard = async (paymentIntentParams, location) => {
    setIsSubmitting(true);

    const cardElement = elements.getElement('card');
    const { seated_group: seatedGroup } = location;

    // Create Payment Intent
    const { clientSecret, error: createError } = await API.createStripePaymentIntent(
      paymentIntentParams,
    );

    if (createError) {
      console.error('Create stripe payment intent error: ', createError);
      throw new Error(createError);
    }

    // Confirm Card Payment
    const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
      setup_future_usage: undefined,
    });

    if (confirmError) {
      throw new Error(confirmError);
    }

    // Open/fund tab
    const { tab, error } = await API.openTab(seatedGroup?.id, '', location.id, null);
    if (error) {
      throw new Error(error);
    }

    const amount = parseInt(paymentIntent.amount);
    const response = await API.fundTabFromPaymentIntent(tab, paymentIntent.id, amount, true);
    tab.update(response.tab);

    return tab;
  };

  /**
   * Gets the credit card info and submits the cart order.
   */
  const processCreditCard = async () => {
    try {
      // Get the credit card information from reader
      const total = parseInt(totalCentsDue);
      const location = API._locations[API.config.quick_order_location];

      const paymentIntentParams = {
        amount: total,
      };
      const cardToCharge = manual
        ? await captureManualCard(paymentIntentParams, location)
        : await capturePaymentMethod({
            location,
            forceTab: false,
            reusable: false,
            tabName: '',
            saveButtonText: 'SUBMIT',
            paymentIntentParams: {
              amount: total,
            },
          });

      // Assuming for now there is only one charge per check
      cart.checks[0].charge = cardToCharge.getCharge(total);
      setIsSubmitting(true);
      const { errors, orderIds, success } = await cart.submit();

      setCheckoutErrors(errors); // Set checkout errors on resolve no matter what.
      if (!success) {
        errors.forEach(errorMsg => {
          throw new CheckoutError(errorMsg);
        });

        if (!errors.length) {
          setCheckoutErrors(['Sorry! There was an error checking out.']);
          throw new CheckoutError();
        }
      }

      // Clear the current cart, get ready for the next order
      POSDispatch({
        type: POS_REDUCER_ACTIONS.CLEAR_ORDER,
      });

      checkoutDispatch({
        type: CHECKOUT_REDUCER_ACTIONS.SET_ORDER_IDS,
        orderIds,
      });

      // Send users to the Payment Completed screen
      checkoutDispatch({
        type: CHECKOUT_REDUCER_ACTIONS.SET_CHECKOUT_SCREEN,
        newCheckoutScreen: CHECKOUT_SCREEN.PAYMENT_COMPLETED,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <View style={PageContainer}>
      <View>
        {checkoutErrors.map(checkoutError => (
          <Alert
            key={checkoutError}
            variant="subtle"
            status="error"
            my={2}
          >
            <Row
              alignItems="center"
              space={2}
            >
              <Alert.Icon mt="1" />

              <Text>{checkoutError}</Text>
            </Row>
          </Alert>
        ))}
      </View>

      <TotalSection centsDue={totalCentsDue} />
      <TipSection
        cartSubtotalCents={cart?.checks[0]?.total}
        onTipSelect={handleTipSelect}
      />

      {manual && (
        <View style={ManualCardContainer}>
          {Platform.OS === 'web' && (
            <CardElement
              options={{
                style: StripeCardElementStyle,
              }}
            />
          )}
        </View>
      )}

      <ApprovePaymentButton
        label="Approve Payment"
        onPress={processCreditCard}
        isLoading={isSubmitting}
      />
    </View>
  );
}

// This can't be put in a stylesheet because it is in an alternate form
const StripeCardElementStyle = {
  base: {
    color: '#FFFFFF',
    '::placeholder': {
      color: '#767676',
    },
  },
};

export default CreditCardEntry;
