import EStyleSheet from 'react-native-extended-stylesheet';

export const SearchBarStyles = EStyleSheet.create({
  SearchIcon: {
    marginLeft: 20,
  },
});

export const GridStyles = EStyleSheet.create({
  ItemSeparatorComponent: {
    height: 15,
  },
  ItemWrapper: {
    marginRight: 15,
  },
});

export const EmptyStateStyles = EStyleSheet.create({
  Container: {
    width: '100%',
    // height: '100%',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  EmptyStateText: {
    marginTop: 20,
    textAlign: 'center',
    fontFamily: 'TTNorms',
    fontWeight: '300',
    fontSize: 16,
    color: 'white',
  },
});
