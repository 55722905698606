import React, { Component } from 'react';
import _ from 'lodash';
import { View, Text, TouchableWithoutFeedback, ScrollView, Modal, Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import { Button, Checkbox, List } from 'native-base';
import Colors from '../constants/Colors';
import { Body, ListItem } from '../bbot-component-library';

let globalInstance = null;

export const show = (options, onSuccess) => {
  if (globalInstance) {
    globalInstance.show(options, onSuccess);
  }
};

export const hide = () => {
  if (globalInstance) {
    globalInstance.hide();
  }
};

export default class ActionSheet extends Component {
  static propTypes = {};

  static show;

  static hide;

  state = {
    visible: false,
    config: {},
    selected: [], // Used for Multi Select
    onSuccess: () => {},
    onCancel: () => {},
  };

  componentDidMount() {
    globalInstance = this;
  }

  componentWillUnmount() {
    globalInstance = null;
  }

  render() {
    const { config, visible, selected } = this.state;
    const { title = '', options = [], multi = false } = config;

    return (
      <Modal
        visible={visible}
        onRequestClose={this.hide}
        transparent
        statusBarTranslucent
      >
        <TouchableWithoutFeedback onPress={this.hide}>
          <View style={styles.background}>
            <TouchableWithoutFeedback>
              <View style={styles.modal}>
                <View style={styles.title}>
                  <Text style={styles.titleText}>{title}</Text>
                </View>
                <ScrollView style={{ flex: Platform.OS === 'web' ? 1 : 0 }}>
                  {options.map((option, index) => (
                    <ListItem
                      onPress={() => {
                        this.onChoice(option, index);
                      }}
                      key={`choice_${index}`}
                    >
                      {multi && (
                        <Checkbox
                          value={option.value}
                          isChecked={selected.includes(option.value)}
                          onPress={() => this.onChoice(option, index)}
                        />
                      )}
                      <Body
                        style={[styles.choice, option.style, multi && { marginLeft: 15, flex: 1 }]}
                      >
                        {!!option.icon && option.icon}
                        <Text style={[styles.choiceText]}>{option.text}</Text>
                      </Body>
                    </ListItem>
                  ))}
                </ScrollView>
                {multi && (
                  <Button
                    block
                    onPress={this.onApply}
                    style={{ height: 45, borderRadius: 0 }}
                  >
                    <Text style={{ color: 'white' }}>Apply</Text>
                  </Button>
                )}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  show = (config, onSuccess) => {
    this.setState({
      config,
      onSuccess,
      selected: config.selected || [],
      min_selected: config.min_selected || 0,
      visible: true,
    });
  };

  hide = () => {
    const { onCancel } = this.state;
    this.setState({
      visible: false,
    });
    onCancel();
  };

  onChoice = (choice, index) => {
    const { onSuccess, config, selected, min_selected } = this.state;
    if (config.multi) {
      const updated_state = _.xor(selected, [choice.value]);
      if (updated_state.length >= min_selected) {
        this.setState({
          selected: updated_state,
        });
      }
    } else {
      this.setState({ visible: false });
      onSuccess(index, choice);
    }
  };

  onApply = () => {
    const { onSuccess, selected } = this.state;
    this.setState({ visible: false });
    onSuccess(selected);
  };
}

ActionSheet.show = show;
ActionSheet.hide = hide;

const styles = EStyleSheet.create({
  background: {
    flex: 1,
    maxHeight: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modal: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: 100,
    maxHeight: '100%',
  },
  title: {
    backgroundColor: Colors.lightGray,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: Colors.gray,
    padding: 15,
  },
  titleText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 18,
  },
  choice: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  choiceText: {
    fontSize: 16,
  },
});
