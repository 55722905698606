import React, { Component } from 'react';
import { BackHandler, Pressable, StyleSheet } from 'react-native';
import { Icon, View, Text } from 'native-base';
import { QRCode } from 'react-native-custom-qr-codes-expo';
import { FormattedMessage } from 'react-native-globalize';
import { AntDesign } from '@expo/vector-icons';
import { styles as gStyles } from '../styles/Global';
import Colors from '../constants/Colors';
import { TextM, TextS } from '../components/Text';
import API from '../api';

/**
 * CaptureSuccessScreen
 *
 * Check Mark Symbol
 * Please pass the device back to your server to finalize your order
 */

export default class CaptureSuccessScreen extends Component {
  static navigationOptions = {
    title: (
      <FormattedMessage
        id="common__thankYou"
        defaultMessage="Thank You"
      />
    ),
    headerLeft: () => null,
  };

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this._onTap);
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  _showQR = joinURL => {
    const { navigation } = this.props;
    const card = navigation.getParam('card');
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', margin: 10 }}>
        <Text
          style={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginBottom: 10,
            fontSize: 20,
            textAlign: 'center',
          }}
        >
          Scan to Join {card.tab_name}
        </Text>
        <QRCode content={joinURL} />
        <Text style={{ textAlign: 'center', marginTop: 10 }}>
          You can add to or close out your tab right on your phone!
        </Text>
      </View>
    );
  };

  render() {
    const { navigation } = this.props;
    const joinURL = navigation.getParam('joinURL');
    const cart = navigation.getParam('cart');

    const displayImg = joinURL ? (
      this._showQR(joinURL)
    ) : (
      <Icon
        as={AntDesign}
        name="checkcircleo"
        color={Colors.success}
        size={120}
      />
    );

    return (
      <Pressable
        style={{ flex: 1 }}
        accessible
        accessibilityLabel="Success"
        testID="tapAnywhereToContinue"
        onPress={this._onTap}
      >
        <View style={[gStyles.centered, { flex: 1, margin: 10 }]}>
          <View style={{ marginBottom: 50, width: 360 }}>
            {cart._orderIds?.map(orderId => {
              const order = API._orders[orderId];
              if (!order) return null;
              const station =
                API._stations[order.bartending_station_id] ||
                API._lite_stations[order.bartending_station_id];
              return (
                <View
                  style={styles.order}
                  key={orderId}
                >
                  <Text>Order#: </Text>
                  <Text style={{ fontWeight: 'bold' }}>{order.orderNumber}</Text>
                  {!!station && (
                    <Text
                      style={styles.orderStation}
                      ellipsizeMode="tail"
                      numberOfLines={1}
                    >
                      {station?.station_name}
                    </Text>
                  )}
                </View>
              );
            })}
          </View>
          {displayImg}
          <TextM
            id="backToServer__long"
            defaultMessage="Please pass the device back to your server to finalize your order"
            style={{ marginTop: 50, textAlign: 'center' }}
          />
        </View>
        <View style={[gStyles.centered, { margin: 10 }]}>
          <TextS
            id="success__touchToContinue"
            defaultMessage="(Touch anywhere to continue)"
            style={{ color: Colors.gray }}
          />
        </View>
      </Pressable>
    );
  }

  _onTap = () => {
    const { navigation } = this.props;
    const cart = navigation.getParam('cart');
    if (cart._submitted) {
      API.handheldDevice.getPreference('quick_order_mode')
        ? navigation.navigate('OrderCreator', {
            location: cart.location,
            cart: null,
            tab_id: null,
          })
        : navigation.navigate('LocationOverview');
      // if(location) else -> Back to Tabs screen?
    } else {
      navigation.navigate('SplitOrderScreen');
    }
    return true;
  };
}

const styles = StyleSheet.create({
  order: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderRadius: 5,
    borderColor: Colors.primary,
    padding: 5,
    marginBottom: 5,
    overflow: 'hidden',
  },
  orderStation: {
    flex: 1,
    textAlign: 'right',
    marginLeft: 5,
  },
});
