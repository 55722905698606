import React, { Component, useEffect, useState } from 'react';
import Stripe from 'react-native-stripe-terminal';
import {
  View,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform,
  FlatList,
} from 'react-native';
import { Button, Icon, Spinner, Text, Image, Box } from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import AsyncStorage from '@react-native-community/async-storage';
import { Entypo, Feather, FontAwesome5, Ionicons } from '@expo/vector-icons';
import { Body, ListItem, Right, Left } from '../bbot-component-library';
import API from '../api';
import Loader from '../components/Loader';
import Colors from '../constants/Colors';
import ErrorBanner from '../components/ErrorBanner';
import CmdButton from '../components/CmdButton';
import { IS_PROD } from '../constants/Config';
import { requestLocationPermission } from '../helpers/HelperFunctions';
import Alert from '../components/Alert';
import IconButton from '../components/IconButton';

/**
 * TODO: WARN IF NO LOCATION_ID FOUND
 */

const ReaderMap = {
  WISEPAD_3: {
    title: 'WisePad 3',
    image: require('../assets/images/readers/wisepad.png'),
    type: 'BT',
  },
  STRIPE_M2: {
    title: 'Stripe M2',
    image: require('../assets/images/readers/stripem2.png'),
    type: 'BT',
  },
  CHIPPER_2X: {
    title: 'Chipper 2X',
    image: require('../assets/images/readers/chipper2x.png'),
    type: 'BT',
  },
  VERIFONE_P400: {
    title: 'Verifone P400',
    image: require('../assets/images/readers/p400.png'),
    type: 'NET',
  },
  WISEPOS_E: {
    title: 'WisePOS E',
    image: require('../assets/images/readers/wisepos.png'),
    type: 'NET',
  },
  BBPOS_WISEPOS_E: {
    title: 'WisePOS E',
    image: require('../assets/images/readers/wisepos.png'),
    type: 'NET',
  },
};

const DiscoveryMethodPrettyNames = {
  BLUETOOTH_SCAN: 'Bluetooth',
  INTERNET: 'Internet',
  USB: 'USB',
};

export default class StripeConfig extends Component {
  static navigationOptions = ({ navigation }) => {
    const goTo = navigation.getParam('goTo');
    return {
      title: 'Connect Reader',
      headerRight: () => (
        <View style={{ marginRight: 10 }}>
          <CmdButton
            testID="skipConnectReader"
            text="Skip"
            onPress={() => {
              Stripe.cancelDiscovery();
              if (goTo) navigation.navigate(goTo);
              else navigation.goBack();
            }}
          />
        </View>
      ),
    };
  };

  _mounted = false;

  constructor(props) {
    super(props);
    const { navigation } = props;

    this._mounted = false;
    this.state = {
      initializing: true,
      initialized: false,
      loading: false,
      scanning: false,
      availableReaders: [],
      connectedReader: null,
      autoload: navigation.getParam('autoload'),
      goTo: navigation.getParam('goTo'),
      updateStatus: UPDATESTATE.UNKNOWN,
      updateProgress: 0,
      simulated: false,
      discoveryMethod: Platform.OS === 'web' ? 'INTERNET' : 'BLUETOOTH_SCAN',
    };
  }

  componentDidMount() {
    this._mounted = true;

    API.retrieveAsyncStorageData('bbpos_simulator').then(val => {
      this.setState({ simulated: JSON.parse(val) || false });
    });
    if (Platform.OS === 'android') {
      requestLocationPermission().then(granted => {
        if (granted) this._initializeStripe();
        else {
          this.setState({
            initializing: false,
            error: 'Location permission is required for Stripe Terminal',
          });
        }
      });
    } else {
      this._initializeStripe();
    }

    this._blurListener = this.props.navigation.addListener('willBlur', () => {
      Stripe.isDiscovering().then(res => {
        if (res) Stripe.cancelDiscovery();
      });
    });
  }

  componentWillUnmount() {
    this._mounted = false;

    if (this._blurListener) this._blurListener.remove();
  }

  _getSimulatedToggle = () => {
    if (IS_PROD) return null;
    return (
      <IconButton
        testID="toggleSimulatedReader"
        label="SIMULATED"
        icon={this.state.simulated ? 'check-square' : 'square'}
        iconType={Feather}
        onPress={this._toggleSimulated}
      />
    );
  };

  _toggleSimulated = async () => {
    const value = !this.state.simulated;
    await AsyncStorage.setItem('bbpos_simulator', JSON.stringify(value));
    this.setState({ simulated: value }, this._scanForReaders);
  };

  render() {
    if (!Array.isArray(this.state.availableReaders)) {
      console.error(this.state.availableReaders);
      return null;
    }
    return (
      <View style={{ flex: 1 }}>
        {this.state.loading && <Loader />}
        {this.state.error ? <ErrorBanner text={this.state.error} /> : null}
        <View style={{ flex: 1 }}>
          <FlatList
            data={this.state.availableReaders}
            renderItem={this._renderReader}
            ListEmptyComponent={this._getListEmptyComponent}
            keyExtractor={item => item.serial_number}
          />
          {this.state.scanning && (
            <View style={styles.spinnerView}>
              <Text style={styles.statusMsg}>
                Scanning for {DiscoveryMethodPrettyNames[this.state.discoveryMethod]} devices
              </Text>
              <Text note>(Please make sure the card reader is turned on)</Text>
              <Image
                style={{ marginTop: 10 }}
                source={require('../assets/images/list-loading.gif')}
                alt="Loading"
              />
            </View>
          )}
        </View>
        <View>{this._getFooter()}</View>
      </View>
    );
  }

  _getListEmptyComponent = () => {
    const { scanning, loading, initializing, loaded } = this.state;
    if (scanning) return null;
    let content = <Text>No devices found</Text>;
    if (initializing || loading) {
      content = <Spinner size="lg" />;
    }
    return (
      <ListItem>
        <Body>{content}</Body>
      </ListItem>
    );
  };

  _renderReader = ({ item: reader }) => {
    const isConnected = this.state.connectedReader?.serial_number === reader.serial_number;
    return (
      <ReaderView
        connected={isConnected}
        key={reader.serial_number}
        reader={reader}
        onPress={this._selectReader}
        onUpdate={this._installUpdate}
      />
    );
  };

  _getFooter() {
    const buttons = {
      INTERNET: {
        label: 'INTERNET',
        icon: (
          <Icon
            as={Ionicons}
            name="ios-globe"
          />
        ),
      },
      BLUETOOTH_SCAN: {
        label: 'BLUETOOTH',
        icon: (
          <Icon
            as={FontAwesome5}
            name="bluetooth-b"
          />
        ),
      },
      USB: {
        label: 'USB',
        icon: (
          <Icon
            as={FontAwesome5}
            name="usb"
          />
        ),
      },
    };

    return (
      <Box style={styles.iconsFooter}>
        {Stripe.AvailableMethods.map(method => (
          <IconButton
            key={`method_${method}`}
            label={buttons[method].label}
            style={{ color: 'white' }}
            onPress={() => this._scanForReaders({ discoveryMethod: method })}
            icon={buttons[method].icon}
          />
        ))}
        {this._getSimulatedToggle()}
      </Box>
    );

    switch (this.state.updateStatus) {
      case UPDATESTATE.INSTALLING:
        return (
          <View style={styles.footer}>
            <Text>Update Progress -{this.state.updateProgress}%</Text>
          </View>
        );
      case UPDATESTATE.SUCCESS:
        return (
          <View style={styles.footer}>
            <Text>Update Installed Successfully</Text>
            <Button
              variant="ghost"
              style={{ marginLeft: 20 }}
              onPress={() => this.setState({ updateStatus: null })}
            >
              <Text>Ok</Text>
            </Button>
          </View>
        );
      case UPDATESTATE.AVAILABLE:
        return (
          <View style={styles.footer}>
            <Text>Update Available</Text>
            <Button
              variant="ghost"
              style={{ marginLeft: 20 }}
              onPress={this._installUpdate}
            >
              <Text>Install Now</Text>
            </Button>
          </View>
        );
      case UPDATESTATE.UNAVAILABLE:
        return (
          <TouchableWithoutFeedback onPress={this._checkForUpdate}>
            <View style={styles.footer}>
              <Text>No Update Available</Text>
              <Button
                variant="ghost"
                style={{ marginLeft: 20 }}
                onPress={() => this.setState({ updateStatus: null })}
              >
                <Text>Ok</Text>
              </Button>
            </View>
          </TouchableWithoutFeedback>
        );
      case UPDATESTATE.CHECKING:
        return (
          <View style={styles.footer}>
            <Text style={{ marginRight: 5 }}>Checking for update </Text>
            <ActivityIndicator color={Colors.primary} />
          </View>
        );
      default:
        return (
          <TouchableWithoutFeedback onPress={this._checkForUpdate}>
            <View style={styles.footer}>
              <Text>Check for Update</Text>
            </View>
          </TouchableWithoutFeedback>
        );
    }
  }

  _initializeStripe = async () => {
    // Todo: simplify this... (always just call Stripe.init, since it internally handles it)
    const isInitialized = await Stripe.isInitialized();
    if (!isInitialized) {
      // We *should* always be initialized here, since we get initialized in AuthLoadingScreen
      try {
        await Stripe.init({
          fetchConnectionToken: API.getStripeConnectionToken,
          createPaymentIntent: API.createStripePaymentIntent,
          locationId: API.customer.stripe_terminal_location_id,
          autoReconnect: true,
        });

        if (!this._mounted) return;
        this.setState({ initialized: true }, this._getConnectedReader);
      } catch (err) {
        API.sendCaughtError(err);
        if (!this._mounted) return;
        this.setState({
          initializing: false,
          error: `Stripe Init failure: ${err}`,
        });
      }
    } else {
      const scanning = await Stripe.isDiscovering();
      if (!scanning) this._getConnectedReader();
      else {
        Stripe.setDiscoverCallback(this._discoverReadersCallback);
        this.setState({
          initializing: false,
          scanning,
        });
      }
    }
  };

  _getConnectedReader = async () => {
    const reader = await Stripe.getConnectedReader();
    if (reader) {
      if (this.state.autoload) {
        this.props.navigation.navigate(this.state.goTo || 'Pin');
      } else {
        if (!this._mounted) return;

        this.setState({
          initializing: false,
          connectedReader: reader,
          availableReaders: [reader],
        });
      }
    } else {
      this._scanForReaders();
    }
  };

  _scanForReaders = async options => {
    if (!this._mounted) return;

    try {
      const connectedReader = await Stripe.getConnectedReader();
      if (connectedReader) {
        await Stripe.disconnectReader(); // this promise doesn't resolve
      }
    } catch (err) {
      console.log(err);
    }
    const isDiscovering = await Stripe.isDiscovering();
    if (isDiscovering) {
      try {
        await Stripe.cancelDiscovery();
      } catch (err) {}
    }

    this._savedReader = await API.getReader();

    this.setState(
      {
        initializing: false,
        availableReaders: [],
        error: null,
        scanning: true,
        connectedReader: false,
        discoveryMethod: options?.discoveryMethod || this.state.discoveryMethod,
      },
      async () => {
        try {
          await Stripe.discoverReaders(
            {
              simulated: this.state.simulated,
              discoveryMethod: Stripe.DiscoveryMethods[this.state.discoveryMethod],
            },
            this._discoverReadersCallback,
          );
          if (this._mounted) {
            this.setState({
              scanning: false,
            });
          }
        } catch (err) {
          this.setState({ scanning: false });
        }
      },
    );
  };

  _discoverReadersCallback = readers => {
    if (!this._mounted) return;
    this.setState({
      availableReaders: readers || [],
      scanning: this.state.discoveryMethod !== 'INTERNET',
    });

    if (readers.length > 0 && this._savedReader && this.state.autoload) {
      // Connect to the reader we previously connected to:
      const foundReader = readers.find(r => r.serial_number === this._savedReader);
      if (foundReader) {
        this._selectReader(foundReader);
      }
    }
  };

  _selectReader = async reader => {
    const { goTo, connectedReader, discoveryMethod } = this.state;
    if (reader === connectedReader) {
      if (goTo) this.props.navigation.navigate(goTo);
      else this.props.navigation.goBack();
      return;
    }

    this.setState({
      loading: true,
      error: '',
      scanning: false,
    });

    try {
      const locationId = API.customer.stripe_terminal_location_id || reader.location_id;
      let result;
      switch (discoveryMethod) {
        case 'BLUETOOTH_SCAN':
          result = await Stripe.connectBluetoothReader(reader, { locationId });
          break;
        case 'INTERNET':
          result = await Stripe.connectInternetReader(reader);
          break;
        case 'USB':
          result = await Stripe.connectUsbReader(reader, { locationId });
          break;
        default:
          result = { error: "Unknown discovery method 'discoveryMethod'" };
      }
      if (result.error) {
        Alert.alert('Error', result.error.message);
        this.setState({ loading: false });
      } else {
        result.discovery_method = discoveryMethod;
        this._connectReaderCallback(result);
      }
    } catch (error) {
      if (!this._mounted) return;
      this.setState({
        loading: false,
        error: `Error connecting reader. Please try again: ${error}`,
      });
      // Start scanning again
      // this._scanForReaders();
      API.sendCaughtError(error);
    }
  };

  _connectReaderCallback = async reader => {
    await API.setConnectedReader(reader);

    if (!reader.available_update) {
      if (this.state.goTo) {
        this.props.navigation.navigate(this.state.goTo);
      } else {
        this.props.navigation.goBack();
      }
    }
  };

  _listLocations = async () => {
    const locations = await Stripe.listLocations({});
  };

  _checkForUpdate = async () => {
    this.setState({
      updateStatus: UPDATESTATE.CHECKING,
    });
    try {
      const updateAvailable = await Stripe.checkForUpdate();

      if (!this._mounted) return;

      this.setState({
        updateStatus: updateAvailable ? UPDATESTATE.AVAILABLE : UPDATESTATE.UNAVAILABLE,
      });
    } catch (err) {
      API.sendCaughtError(err);
      if (this._mounted) {
        this.setState({
          updateStatus: UPDATESTATE.UNKNOWN,
          error: 'An error occurred while checking for updates.',
        });
      }
    }
  };

  _installUpdate = async () => {
    try {
      this.setState({
        updateStatus: UPDATESTATE.INSTALLING,
        updateProgress: 0,
      });

      const success = await Stripe.installAvailableUpdate(this._progressUpdate);

      this.setState({
        updateStatus: success ? UPDATESTATE.SUCCESS : UPDATESTATE.FAILED,
        updateProgress: 0,
      });
    } catch (error) {
      API.sendCaughtError(error);
      this.setState({
        error: `${error}`,
        updateStatus: UPDATESTATE.FAILED,
        updateProgress: 0,
      });
    }
  };

  _progressUpdate = progress => {
    if (!this._mounted) return;
    try {
      this.setState({
        updateProgress: Math.round(progress * 100),
      });
    } catch (err) {
      console.log(err);
    }
  };
}

const UPDATESTATE = {
  UNKNOWN: 0,
  CHECKING: 1,
  AVAILABLE: 2,
  UNAVAILABLE: 3,
  INSTALLING: 4,
  SUCCESS: 5,
  FAILED: 6,
};

function ReaderView({ reader, onPress, connected, onUpdate }) {
  const [updateProgress, setUpdateProgress] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [batteryLevel, setBatteryLevel] = useState(0);

  if (!reader) return null;

  function battLevel(fraction) {
    if (fraction != null) return `${Math.round(fraction * 100)}%`;
    return '';
  }

  const deviceInfo = ReaderMap[reader.device_type.toUpperCase()];

  function UpdateInfo() {
    if (updateProgress > 0) {
      return (
        <View style={{ justifyContent: 'center', alignItems: 'center', padding: 4 }}>
          <View
            style={{
              position: 'absolute',
              left: 0,
              width: `${updateProgress}%`,
              height: 24,
              backgroundColor: '#a5ff80',
            }}
          />
          <Text>
            Updating ({updateProgress}
            %)
          </Text>
        </View>
      );
    }

    return null;
  }

  useEffect(() => {
    if (connected && deviceInfo.type === 'BT') {
      const listener = Stripe.on('ReaderSoftwareUpdateProgress', progress => {
        setUpdateProgress(Math.round(progress * 100));
        setUpdateStatus(UPDATESTATE.INSTALLING);
      });
      const finishListener = Stripe.on('FinishedInstallingUpdate', () => {
        setUpdateProgress(100);
        setUpdateStatus(UPDATESTATE.SUCCESS);
      });
      const batteryListener = Stripe.on(
        'BatteryLevelUpdate',
        ({ batteryLevel, batteryStatus, isCharging }) => {
          setBatteryLevel(batteryLevel);
        },
      );
      return () => {
        listener.remove();
        finishListener.remove();
        batteryListener.remove();
      };
    }
  }, [connected]);

  function RightSide() {
    if (!connected) {
      return (
        <Button
          style={{ minWidth: 75, marginRight: 10 }}
          colorScheme="primary"
          onPress={() => onPress(reader)}
        >
          <Icon
            testID="connectSimReader"
            as={Entypo}
            style={{ color: '#FFF' }}
            name="arrow-with-circle-right"
            size="xl"
          />
        </Button>
      );
    }
    if (updateStatus === UPDATESTATE.INSTALLING) return null;
    if (updateStatus === UPDATESTATE.SUCCESS) {
      return (
        <Button
          onPress={() => {
            setUpdateStatus(null);
            setUpdateProgress(0);
          }}
        >
          <Text style={{ color: '#FFF' }}>DONE</Text>
        </Button>
      );
    }
    return (
      <>
        <Text>{battLevel(batteryLevel || reader.battery_level)}</Text>
        {!!reader.available_update && !updateStatus && (
          <Button
            style={{ marginLeft: 10 }}
            onPress={() => onUpdate(reader)}
          >
            <Text style={{ color: '#FFF' }}>Update</Text>
          </Button>
        )}
      </>
    );
  }

  return (
    <ListItem
      noIndent
      thumbnail
      connected={connected}
      accessible
      accessibilityLabel={reader.serial_number}
      testID={reader.serial_number}
    >
      <Left style={{ flex: null }}>
        <Image
          style={{ width: 50, height: 50 }}
          source={deviceInfo.image}
          alt="Card Reader"
        />
      </Left>
      <Body style={{ marginLeft: 15 }}>
        <Text style={{ fontSize: 18 }}>{reader.label || deviceInfo.title}</Text>
        <Text style={{ fontWeight: '200' }}>{reader.serial_number}</Text>
        <UpdateInfo />
      </Body>
      <Right>
        <RightSide />
      </Right>
    </ListItem>
  );
}

const styles = EStyleSheet.create({
  reader: {
    flexDirection: 'row',
    padding: 5,
  },
  connected: {
    backgroundColor: '#bfe7e8',
  },
  deviceType: {
    fontSize: '1.1rem',
  },
  readerBatt: {
    justifyContent: 'center',
    marginRight: 10,
  },
  readerSerial: {
    color: Colors.gray,
    fontStyle: 'italic',
  },
  spinnerView: {
    alignItems: 'center',
    borderTopWidth: 1,
    padding: 10,
  },
  helpMsg: {
    color: Colors.darkGray,
    fontSize: '0.9rem',
    marginBottom: 5,
  },
  statusMsg: {
    margin: 10,
    fontWeight: 'bold',
    fontSize: 16,
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    borderTopWidth: 1,
    flexDirection: 'row',
  },
  iconsFooter: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
    height: 60,
  },
});
