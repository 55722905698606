import React, { useEffect, useState } from 'react';
import { KeyboardAvoidingView, TextArea } from 'native-base';
import { View, Text } from 'react-native';
import { SpecialInstructionsStyles as styles } from '../../styles/POSStyles';

function SpecialInstructionsInput({ onSpecialInstructionsChange, defaultInstructions }) {
  const [specialInstructions, setSpecialInstructions] = useState(defaultInstructions ?? '');

  useEffect(() => {
    if (defaultInstructions) {
      setSpecialInstructions(defaultInstructions);
    }
  }, [defaultInstructions]);

  const handleSpecialInstructionsChange = e => {
    const newInstructions = e.currentTarget.value;
    onSpecialInstructionsChange(newInstructions);
    setSpecialInstructions(newInstructions);
  };

  return (
    <KeyboardAvoidingView style={styles.Container}>
      <Text style={styles.HeadingName}>Special Instructions</Text>
      <TextArea
        value={specialInstructions}
        style={styles.TextArea}
        borderRadius={8}
        borderWidth={0}
        marginTop={2}
        placeholder="Please enter special instructions here..."
        onChange={handleSpecialInstructionsChange}
        maxWidth={580}
        marginBottom={10}
      />
      <View />
    </KeyboardAvoidingView>
  );
}
export default SpecialInstructionsInput;
