import { Box, Button, Icon, Input } from 'native-base';
import React from 'react';
import { Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Ionicons } from '@expo/vector-icons';
import { Buttons } from '../styles';
import { SearchBar } from '../bbot-component-library';

export default class FilterButtonInput extends React.Component {
  render() {
    const { label, value, onChange } = this.props;
    return (
      <View
        style={[Buttons.pillPrimary, { justifyContent: 'flex-start', maxWidth: 250, width: 250 }]}
      >
        <Text style={[Buttons.pillLabelPrimary, { marginHorizontal: 10 }]}>{label}</Text>
        <Input
          px="3"
          height="21"
          maxWidth="163"
          onChangeText={onChange}
          value={value}
          InputRightElement={
            value ? (
              <Button
                variant="ghost"
                icon
                onPress={() => onChange('')}
              >
                <Icon
                  as={Ionicons}
                  color="black"
                  name="close"
                />
              </Button>
            ) : null
          }
        />
      </View>
    );
  }
}
