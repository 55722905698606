import React from 'react';
import { FlatList, View, Text } from 'react-native';
// import MapView, { PROVIDER_GOOGLE, Marker, Polyline } from 'react-native-maps';
import EStyleSheet from 'react-native-extended-stylesheet';
import _ from 'lodash';
import moment from 'moment';
import API from '../api';
// import MapMarker from "react-native-maps/lib/components/MapMarker";

export default class GPSDebug extends React.Component {
  static navigationOptions = () => ({
    title: 'GPS DEBUG',
  });

  constructor(props) {
    super(props);
    this.state = {
      points: [], // API._lastPos
      region: {
        latitude: API._lastPos?.latitude,
        longitude: API._lastPos?.longitude,
        latitudeDelta: 0.001,
        longitudeDelta: 0.001,
      },
    };
  }

  componentDidMount() {
    this._mounted = true;
    API.on('gps', this._newPoint);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('gps', this._newPoint);
  }

  _newPoint = coord => {
    if (!this._mounted) return;

    this.state.points.unshift(coord);
    if (this.state.points.length > 50) this.state.points.length = 50;

    this.setState({
      points: [...this.state.points],
    });
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row', borderBottomWidth: 1 }}>
            <Text style={{ flex: 1 }}>Time</Text>
            <Text style={{ flex: 2 }}>Lat/Lng</Text>
            <Text style={{ flex: 1 }}>Dist</Text>
            <Text style={{ flex: 1 }}>Speed</Text>
            <Text style={{ flex: 1 }}>Altitude</Text>
          </View>
          <FlatList
            renderItem={this._getPoint}
            keyExtractor={(item, index) => `point_${index}`}
            data={this.state.points}
          />
        </View>
        {/*   <MapView
          provider={ PROVIDER_GOOGLE }
          style={ styles.map }
          initialRegion={this.state.region}
          >
          <MapMarker
            coordinate={{latitude: this.state.points[0].latitude, longitude: this.state.points[0].longitude}}
          />
          {
            this.state.points.slice(0,10).map( (point, index) => {
              let next = this.state.points.length > index ? this.state.points[index+1]: null;
              if(!next) return null;
              let color = "rgba(255,0,0,"+(10-index)/10+")";
              return (
                <Polyline key={index} strokeColor={color} coordinates={[
                  { latitude: point.latitude, longitude: point.longitude},
                  { latitude: next.latitude, longitude: next.longitude }
                ]}/>
              )
            })
          }
        </MapView> */}
      </View>
    );
  }

  _getPoint = ({ item, index }) => (
    <View
      style={{
        flexDirection: 'row',
        borderBottomWidth: 1,
        backgroundColor: item.sent ? 'lightgreen' : null,
      }}
    >
      <Text style={{ flex: 1 }}>{moment(item.timestamp).format('LTS')}</Text>
      <Text style={{ flex: 2 }}>{item.point.toString()}</Text>
      <Text style={{ flex: 1 }}>{_.round(item.distanceTravelled, 2)}m</Text>
      <Text style={{ flex: 1 }}>
        {_.round(item.speed, 2)}
        m/s
      </Text>
      <Text style={{ flex: 1 }}>
        {_.round(item.altitude, 2)}
        alt
      </Text>
    </View>
  );
}

const styles = EStyleSheet.create({
  map: {
    flex: 1,
  },
});
