import { createStackNavigator } from 'react-navigation-stack';
import { Platform } from 'react-native';
import React from 'react';
import { Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import TabsScreen from '../screens/TabsScreen';
import TabBarIcon from '../components/TabBarIcon';
import { defaultNavigationOptions } from './Defaults';
import TabBarLabel from '../components/TabBarLabel';

export const TabsStackNavigator = createStackNavigator(
  { TabsScreen },
  {
    navigationOptions: ({ navigation }) => ({
      tabBarLabel: props => (
        <TabBarLabel
          {...props}
          labelPath="mainTabNavigator__tabs"
        />
      ),
      tabBarTestID: 'tabs',
      tabBarIcon: props => (
        <TabBarIcon
          {...props}
          type={MaterialCommunityIcons}
          name="cash-multiple"
        />
      ),
    }),
    defaultNavigationOptions,
  },
);
