import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Button, Text } from 'native-base';
import { Image, View } from 'react-native';
import _ from 'lodash';
import { FormattedMessage } from 'react-native-globalize';
import { Loader } from '../index';

function FormWrapper({ onSave, onCancel, disabled, saveButtonText, children }) {
  const textPath =
    saveButtonText === 'SUBMIT' ? 'common__uppercase__submit' : 'common__uppercase__save';

  return (
    <View>
      <Loader shown={disabled} />
      <View style={{ backgroundColor: '#f7fafc', padding: 20 }}>
        <FormattedMessage
          id="cardReader__enterCard"
          defaultMessage="Enter your card"
          style={{ marginBottom: 10 }}
        />
        <View style={{ alignItems: 'center', justifyContent: 'center', marginVertical: 20 }}>
          <Image
            source={require('../../assets/images/StripeCard.png')}
            style={{ width: 168, height: 108 }}
          />
        </View>
        <View style={{ marginBottom: 20 }}>{children}</View>
        <View nativeID="card-errors" />
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            colorScheme="primary"
            onPress={onCancel}
            style={{ marginLeft: 10 }}
          >
            <FormattedMessage
              id="common__uppercase__cancel"
              defaultMessage="CANCEL"
              style={{ color: '#fff' }}
            />
          </Button>
          <Button
            colorScheme="primary"
            testID="stripeCardModalSaveButton"
            onPress={onSave}
            disabled={disabled}
            style={{ marginLeft: 10 }}
          >
            <FormattedMessage
              id={textPath}
              defaultMessage="SAVE"
              style={{ color: '#fff' }}
            />
          </Button>
        </View>
      </View>
    </View>
  );
}

export default FormWrapper;
