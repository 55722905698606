import _ from 'lodash';
import moment from 'moment';
import { showMessage } from 'react-native-flash-message';
import API from '../api';
import { checkAccess } from './Access';

export default class OrderHelper {
  static canRefund = items => {
    const user = API.getUser();
    if (!user) {
      return false;
    }
    return user.canRefund() && items.length && !items.some(i => i.status === 'refunded');
  };

  static changeItemsState = async (items, newState, reason = '', user = null) => {
    if (newState === 'botsent') newState = 'pleasesend'; // need to override this
    const orderitems = items.map(i => ({
      orderitemid: i.orderitemid,
      old_status: i.status,
      name_for_customer: i.getName(),
      status: newState,
      reason,
    }));

    const now = moment();

    const { order } = items[0];
    // optimistically set the status
    items.forEach(item => {
      item.notified = true;
      item.status = newState;
      item.time_modified = now;
      item._dirty = true;
    });
    if (API.menuData.closed_statuses_for_station.includes(newState)) {
      order.time_closed = now;
    }
    order.last_modified = now;
    order._lastLocalChange = now;

    API.trigger('orders', [order]);

    const response = await API.setItemsStatus(orderitems, user?.user?.username);

    if (response.success) {
      items.forEach(item => {
        item.notified = true;
        item.status = newState;
        item.time_modified = now;
        item._dirty = false;
      });

      // We only ever change the items of a single order at a time:
      const order_ids = _.uniq(items.map(i => i.order.orderId));
      order_ids.forEach(orderId => {
        const order = API._orders[orderId];
        if (order) {
          const data = response.changedOrderStatuses[orderId] || {};
          if (data.time_closed && !order?.time_closed?.isSame(data.time_closed)) {
            API.setLastClosedOrder(order);
          }
          order.update(data);
          order._lastLocalChange = order.last_modified;
        }
      });

      API.trigger('orders', order_ids);
    }

    return response;
  };

  static sortModifiers = mods => {
    if (!mods) return [];
    const mappedModifiers = mods.map(mod => ({
      ...mod,
      modifier: API?.menuData?.modifiersById[mod.id],
    }));
    return _.orderBy(mappedModifiers, [
      'modifier.heading.display_position',
      'modifier.heading.heading_name',
      'modifier.display_position',
      'modifier.name_for_bartender',
    ]);
  };

  static prepareGroupedMods = mods => {
    /*
     * To avoid multiple renders of the group heading we needed
     * an object like structure. A map was chosen so that we can
     * also have a predictable order of the output data.
     */

    const sortedModifiers = this.sortModifiers(mods);
    const modifierMap = new Map();

    sortedModifiers.forEach(mod => {
      // eslint-disable-next-line camelcase
      const heading_name = mod?.modifier?.heading?.heading_name || '';
      // TODO: Warn here that we're trying to display tickets from a menu we don't have on the profile
      // eslint-disable-next-line no-unused-expressions
      modifierMap.has(heading_name)
        ? modifierMap.set(heading_name, [...modifierMap.get(heading_name), mod])
        : modifierMap.set(heading_name, [mod]);
    });

    /*
     * To keep ungrouped modifiers at the top we had to iterate through the map
     * to remove and then reinsert grouped mods at the bottom. Maps will keep the
     * order based on when entries are added.
     */
    const seen = {};
    modifierMap.forEach((value, key) => {
      if (value[0]?.modifier?.heading.show_on_ticket && !seen[key]) {
        modifierMap.delete(key);
        modifierMap.set(key, value);
        seen[key] = true;
      }
    });

    const sortedHeadings = [];

    for (const [key, value] of modifierMap[Symbol.iterator]()) {
      sortedHeadings.push({
        groupName: key,
        modifiers: value,
        showOnTicket: value[0]?.modifier?.heading.show_on_ticket,
      });
    }

    return sortedHeadings;
  };

  static printTicket = (order, notify) =>
    new Promise((resolve, reject) => {
      const doPrint = async user => {
        let response;
        const { has_printers } = API.menuData.customer;

        if (has_printers && !API.config.force_pdf_printing) {
          response = await API.printTicket(order);
        } else {
          response = await API.printTicketLocal(order);
        }
        if (notify && has_printers) {
          showMessage({
            position: 'top',
            floating: true,
            type: 'success',
            message: 'Ticket sent to printer',
          });
        }
        resolve(response);
        return response;
      };

      checkAccess('reprint_ticket').then(result => {
        if (result) {
          doPrint(result);
        } else {
          resolve(false);
        }
      });
    });
}
