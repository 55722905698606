import React, { Component } from 'react';
import _ from 'lodash';
import { View, Text, Platform } from 'react-native';
import {
  Container,
  FormControl,
  Box,
  Input,
  Button,
  Icon,
  Select,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from 'native-base';
import { showMessage } from 'react-native-flash-message';
import { Ionicons } from '@expo/vector-icons';
import Alert from '../components/Alert';
import { Content } from '../bbot-component-library';
import Loader from '../components/Loader';
import API from '../api';

export default class DeviceRegistration extends Component {
  state = {
    name_status: '',
    device_name: API.handheldDevice.name,
    selected_profile: API.handheldDevice.config_id,
    loading: true,
    configs: [],
  };

  static navigationOptions = {
    title: 'Device Registration',
  };

  componentDidMount() {
    API.getAvailableConfigs().then(data => {
      const configs = _.orderBy(data, 'config_name');
      this.setState({
        loading: false,
        selected_profile: API.handheldDevice.config_id || configs[0].id,
        configs,
      });
    });
  }

  render() {
    const { name_status, loading, device_name, selected_profile, configs } = this.state;
    const nameOpts = {};

    if (name_status) nameOpts[name_status] = true;
    return (
      <View>
        <Loader shown={loading} />
        <View>
          <FormControl>
            <InputGroup w={{ base: '100%' }}>
              <InputLeftAddon
                w={{ base: '20%' }}
                children={
                  <FormControl.Label
                    style={{ flex: 1, marginLeft: 5 }}
                    numberOfLines={1}
                  >
                    Customer
                  </FormControl.Label>
                }
              />
              <Input
                style={{ flex: 4 }}
                w={{ base: '80%' }}
                variant="underlined"
                value={API.main_customer ? API.main_customer.customer_name : ''}
                editable={false}
              />
            </InputGroup>
            <InputGroup
              {...nameOpts}
              w={{ base: '100%' }}
            >
              <InputLeftAddon
                w={{ base: '20%' }}
                children={
                  <FormControl.Label style={{ flex: 1, marginLeft: 5 }}>
                    Device Name
                  </FormControl.Label>
                }
              />
              <Input
                style={{ flex: 4 }}
                w={{ base: '80%' }}
                value={device_name}
                variant="underlined"
                testID="deviceNameInput"
                placeholder="(Required)"
                accessibilityLabel="deviceName"
                onChangeText={this._onChange}
                autoFocus
                InputRightElement={
                  name_status === 'error' ? (
                    <Icon
                      style={{ marginRight: 15, fontSize: 16, color: 'orange' }}
                      as={Ionicons}
                      name="warning"
                    />
                  ) : (
                    name_status === 'success' && (
                      <Icon
                        style={{ marginRight: 15, fontSize: 16, color: 'green' }}
                        as={Ionicons}
                        name="checkmark-circle"
                      />
                    )
                  )
                }
              />
            </InputGroup>
            <InputGroup w={{ base: '100%' }}>
              <InputLeftAddon
                w={{ base: '20%' }}
                children={
                  <FormControl.Label style={{ flex: 1, marginLeft: 5 }}>
                    Device Profile
                  </FormControl.Label>
                }
              />
              <Box w={{ base: '80%' }}>
                <Select
                  style={Platform.select({
                    web: {
                      backgroundColor: 'white',
                      flex: 4,
                      height: 50,
                      borderWidth: 0,
                    },
                    android: {
                      flex: 4,
                    },
                  })}
                  testID="deviceProfilePicker"
                  variant="underlined"
                  selectedValue={selected_profile}
                  onValueChange={this._setDeviceProfile}
                  mode="dialog"
                >
                  {configs.map(config => (
                    <Select.Item
                      testID={config.id}
                      key={config.id}
                      label={config.config_name}
                      value={config.id}
                    />
                  ))}
                </Select>
              </Box>
            </InputGroup>
            {Platform.OS !== 'web' && (
              <View>
                <InputGroup w={{ base: '100%' }}>
                  <InputLeftAddon
                    w={{ base: '20%' }}
                    children={
                      <FormControl.Label style={{ flex: 1, marginLeft: 5 }}>Make</FormControl.Label>
                    }
                  />
                  <Input
                    style={{ flex: 4 }}
                    w={{ base: '80%' }}
                    value={API.handheldDevice.make}
                    editable={false}
                    variant="underlined"
                  />
                </InputGroup>
                <InputGroup w={{ base: '100%' }}>
                  <InputLeftAddon
                    w={{ base: '20%' }}
                    children={
                      <FormControl.Label style={{ flex: 1, marginLeft: 5 }}>
                        Model
                      </FormControl.Label>
                    }
                  />
                  <Input
                    style={{ flex: 4 }}
                    w={{ base: '80%' }}
                    value={API.handheldDevice.model}
                    editable={false}
                    variant="underlined"
                  />
                </InputGroup>
              </View>
            )}
            <View style={{ margin: 20, marginTop: 50 }}>
              <Button
                title="Save"
                // disabled={name_status === 'error'}
                testID="deviceSaveButton"
                accessibilityLabel="saveDeviceRegistration"
                onPress={this._onSave}
                block
              >
                <Text style={{ color: 'white' }}>Save</Text>
              </Button>
            </View>
          </FormControl>
        </View>
      </View>
    );
  }

  _onChange = async val => {
    val = _.trimStart(val);

    this.setState({
      device_name: val,
      name_status: !val || val.length < 4 ? 'error' : '',
    });

    this._debouncedValidate(val);
  };

  // Only validate after no input for 500ms
  _debouncedValidate = _.debounce(async val => {
    if (val.length >= 4) {
      const response = await API.checkDeviceName(val);
      this.setState({
        name_status: response.valid ? 'success' : 'error',
      });
    }
  }, 500);

  _setDeviceProfile = val => {
    this.setState({
      selected_profile: val,
    });
  };

  _validateName = name => name.length > 3;

  _onSave = async () => {
    this.setState({
      loading: true,
      name_status: '',
    });

    if (!this._validateName(this.state.device_name)) {
      this.setState({
        loading: false,
        name_status: 'error',
      });
      Alert.alert('Device Name required', 'Please name this device');
      return;
    }

    const result = await API.updateDevice({
      name: this.state.device_name,
      config_id: this.state.selected_profile,
    });

    if (result.error) {
      this.setState({
        name_status: 'error',
        loading: false,
      });
    } else if (!this.props.navigation.getParam('stayAfterSave')) {
      const authStep = this.props.navigation.getParam('authStep');
      this.props.navigation.navigate('AuthLoading', { step: authStep + 1 });
    } else {
      showMessage({
        message: 'Save Successful!',
        type: 'success',
        position: 'top',
        floating: true,
        autoHide: true,
      });
      this.setState({
        loading: false,
      });
    }
  };
}
