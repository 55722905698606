import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from '../../constants/Colors';

const margin = 3;
const height = 54; // '14.28%'; // should calculate this

export const styles = EStyleSheet.create({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: margin,
    marginBottom: margin,
  },
  button: {
    backgroundColor: '#dddddd',
    borderRadius: 2,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: 3,
    paddingRight: 3,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    marginBottom: 1,
    height,
  },
  leftButtonSplit: {
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    flexGrow: 0.8,
  },
  rightButtonSplit: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: '#EEE',
    borderColor: '#DDD',
    borderWidth: 2,
    paddingHorizontal: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
    flexGrow: 0.2,
    flexShrink: 1,
    flexBasis: 0,
    height,
  },
  selected: {
    borderWidth: 2,
    borderColor: Colors.highlight,
    backgroundColor: Colors.buttonSelected,
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 3,
  },
  errorButton: {
    borderColor: Colors.error,
  },
  errorText: {
    color: Colors.error,
  },
  disabled: {
    backgroundColor: Colors.gray,
  },
  disabledText: {
    color: Colors.darkGray,
  },
});
