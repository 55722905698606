import React, { useState } from 'react';
import { View } from 'native-base';
import Card from './Card';
import { CardView, TabView, styles } from '../../components';

/**
 * The <PaymentCard> contains the payment method currently being used in checkout. Most commonly, this would contain
 * the credit card preview, but could display other forms of payment.
 *
 * @param {SavedCard | PartyTab | null} card The credit card or party tab for the payment method
 * @param location
 * @constructor
 */
function PaymentInfoCard({ card, location, ...rest }) {
  const getContent = () =>
    card.type === 'tab' ? (
      <TabView
        tab={card}
        hideRadio
      />
    ) : (
      <CardView
        card={card}
        hideRadio
      />
    );

  return (
    <Card
      testID="tipScreenPaymentCard"
      title="Payment Method"
      titlePath="common__paymentMethod"
      {...rest}
    >
      {/* Cards are optional on free purchases, so we need to check if `card` is given */}
      <View style={{ marginTop: 20, alignSelf: 'stretch' }}>{getContent()}</View>
    </Card>
  );
}

export default PaymentInfoCard;
