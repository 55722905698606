import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

// Todo: rewrite as functional component

export default function Clock() {
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const _interval = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => {
      clearInterval(_interval);
    };
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{time.format('LT')}</Text>
    </View>
  );
}

const styles = EStyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },
});
