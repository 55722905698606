import React from 'react';
import { Pressable, Text, View, StyleSheet } from 'react-native';
import { FormattedMessage } from 'react-native-globalize';

function NewCheckPage({ onPress }) {
  return (
    <View style={styles.newCheckCell}>
      <Pressable
        testID="splitOrderScreenNewCheck"
        style={styles.button}
        onPress={onPress}
      >
        <FormattedMessage
          id="common__newCheck"
          defaultMessage="New Check"
          style={styles.text}
        />
      </Pressable>
    </View>
  );
}

export default NewCheckPage;

const styles = StyleSheet.create({
  newCheckCell: {
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 10,
    margin: 10,
    flex: 1,
  },
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
