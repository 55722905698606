import React from 'react';
import { View, Platform } from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import BackgroundTimer from 'react-native-background-timer';
import { FontAwesome } from '@expo/vector-icons';
import API from '../api';
import KDSOrdersView from './KDSOrdersView';
import Colors from '../constants/Colors';
import Clock from '../components/Clock';
import { Loader } from '../components';
import FlashingOverlay from '../components/FlashingOverlay';
import KDSFilteredOrdersView from '../components/KDSFilteredOrdersView';
import NotificationToggle from '../components/TerminalNotifications/NotificationToggle';
import Fontello from '../assets/fonts/Fontello';
import RecallButton from '../components/KDS/RecallButton';
import FolderTabs from '../components/FolderTabs';
import FolderTab from '../components/FolderTab';
import HeaderIconButton from '../components/HeaderIconButton';
import KDSAllDayView from './KDSAllDayView';

const ONE_MINUTE = 60 * 1000; // In MS, used by setInterval Fn
const THREE_MINUTES = 60 * 3; // In Seconds, used by inaction listener

const setIntervalFn =
  Platform.OS === 'android' ? BackgroundTimer.setInterval.bind(BackgroundTimer) : setInterval;
const clearIntervalFn =
  Platform.OS === 'android' ? BackgroundTimer.clearInterval.bind(BackgroundTimer) : clearInterval;

export default class KDSView extends React.Component {
  static navigationOptions = {};

  state = {
    loading: true,
    newCurrentOrders: 0,
    newFutureOrders: 0,
    show_future_orders: false,
    isFlashing: false,
    kds_idle_screen: API.handheldDevice.getPreference('kds_idle_screen'),
    tickerEnabled: true,
    activeTabHeading: 'Current Orders',
  };

  componentDidMount() {
    this._mounted = true;
    API.on('orders', this._updateCounts, 'KDSView');
    API.on('menu', this._updateCounts);
    API.on('reset', this._setLoading);
    API.once('poll_complete', () => {
      if (this._mounted) this.setState({ loading: false });
    });
    API.handheldDevice.on('update', this._setIdleScreen);
    if (API.hasPolled) this._updateCounts();

    this._inactionListener = API.onInaction(THREE_MINUTES, this.focusMe);
    this._flashPoller = setIntervalFn(this._shouldIFlash, ONE_MINUTE);
    API.on('touch', this._stopFlashing);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('orders', this._updateCounts);
    API.off('touch', this._stopFlashing);
    API.off('menu', this._updateCounts);
    API.off('reset', this._setLoading);
    API.handheldDevice.off('update', this._setIdleScreen);
    this._inactionListener.remove();
    if (this._flashPoller) {
      clearIntervalFn(this._flashPoller);
      this._flashPoller = null;
    }
  }

  _setLoading = () => this.setState({ loading: true });

  focusMe = () => {
    if (!this._mounted) return;
    const { navigation } = this.props;
    // Todo: Only focus KDSView if there's something to see (new current or future orders)
    if (
      API.handheldDevice.getPreference('kds_autofocus') &&
      !navigation.isFocused() &&
      this.state.newCurrentOrders
    ) {
      navigation.navigate('KDSViewStack');
      this._folderTabs.setActive(1);
    }
  };

  _onTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const config = API.getConfig();
    if (!API || !config) return null;

    const futureTabColor = '#ffffff';
    const {
      kds_idle_screen,
      show_future_orders,
      newCurrentOrders,
      newFutureOrders,
      activeTab,
      isFlashing,
      loading,
    } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <Loader shown={loading} />
        <FlashingOverlay shown={isFlashing} />
        <FolderTabs
          ref={me => (this._folderTabs = me)}
          onTabChange={this._onTabChange}
          activeTab={activeTab}
        >
          <View
            style={{
              width: 50,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fontello
              name="bbot-logo"
              style={{ color: 'white', fontSize: 28 }}
            />
          </View>
          <FolderTab
            heading="Current Orders"
            count={newCurrentOrders}
          >
            <KDSOrdersView
              filterFn={order => order.kds_open}
              sortField={['desired_time']}
              sortDir={['asc']}
              showCutePicsWhenEmpty={kds_idle_screen}
            />
          </FolderTab>
          {show_future_orders && (
            <FolderTab
              heading="Future Orders"
              count={newFutureOrders}
              activeColor={futureTabColor}
            >
              <KDSOrdersView
                filterFn={order => order.kds_future}
                sortField={['desired_time']}
                sortDir={['asc']}
                style={{ backgroundColor: futureTabColor }}
                emptyMsg="No snoozed orders"
              />
            </FolderTab>
          )}
          <FolderTab heading="Recently Fulfilled">
            <KDSOrdersView
              filterFn={order => order.kds_closed}
              sortField="last_modified"
              sortDir="desc"
              emptyMsg="Past orders will appear here"
            />
          </FolderTab>
          <FolderTab heading="Filtered Orders">
            <KDSFilteredOrdersView />
          </FolderTab>
          <FolderTab heading="All Day View">
            <KDSAllDayView />
          </FolderTab>
          <TabRight>
            <HeaderIconButton
              iconStyle={{ fontSize: 16, height: 'auto' }}
              testID="KDSUserButton"
              textPath="username"
              formatData={{ username: API?.currUser ? API.currUser.getName() : 'User' }}
              name={API?.currUser ? API.currUser.getName() : 'User'}
              icon="user"
              type={FontAwesome}
              size={4}
              accessibilityLabel="userBtn"
              onPress={() => {
                this._lock();
              }}
            />
            <RecallButton api={API} />
            <NotificationToggle />
            <Clock />
          </TabRight>
        </FolderTabs>
      </View>
    );
  }

  _updateCounts = async () => {
    const kds_station_orders = this._getKDSOrders();
    let newCurrentOrders = 0;
    let newFutureOrders = 0;
    let show_future_orders = API.menuData?.allow_order_ahead;
    const now = moment();

    kds_station_orders.forEach(order => {
      if (order.kds_future) show_future_orders = true;

      if (order.kds_open && order.status === 'waiting') newCurrentOrders++;
      else if (!order.time_closed && order.snooze_till.isAfter(now)) newFutureOrders++;
    });

    if (Platform.OS === 'web') {
      if (newCurrentOrders > 0) document.title = `(${newCurrentOrders}) Bbot Terminal`;
      else document.title = 'Bbot Terminal';
    }

    this.setState({
      loading: !API.menuLoaded,
      newCurrentOrders,
      newFutureOrders,
      show_future_orders,
    });
  };

  _getKDSOrders = () => {
    const config = API.getConfig();
    if (!config) return [];

    return config.kds_stations.reduce((orders, station) => {
      if (station) return orders.concat(station.orders);
      return orders;
    }, []);
  };

  _shouldIFlash = () => {
    const { isFlashing } = this.state;
    const { navigation } = this.props;
    const kdsOrders = this._getKDSOrders();

    const openOrders = kdsOrders.filter(order => order.kds_open);
    const waitingOrders = kdsOrders.filter(order => order.kds_open && order.status === 'waiting');

    const allOrdersWaiting = openOrders.length === waitingOrders.length;
    const oldestWaitingOrder = _.minBy(waitingOrders, o => o.time);

    const isAllDayView = this._folderTabs?.getActiveTabName() === 'All Day View';
    const flashOnAllDayView = API.handheldDevice.getPreference('kds_all_day_flash_screen');

    if (!oldestWaitingOrder) {
      if (isFlashing) this.setState({ isFlashing: false });
      return;
    }

    if (
      // We are not on the All Day View screen OR screen set to flash on All Day View screen
      (!isAllDayView || flashOnAllDayView) &&
      // All orders are in a waiting state,
      // and the oldest waiting order is over 30 seconds old,
      // and the last interaction with the tablet was over 30 seconds ago:
      ((allOrdersWaiting &&
        oldestWaitingOrder.time < moment().subtract(30, 'second') &&
        API.lastTouch < moment().subtract(30, 'second')) ||
        // There are some orders that are not waiting, but the oldest 'waiting' order is over 5 minutes old,
        // and no one has touched the tablet in 5 minutes:
        (!allOrdersWaiting &&
          oldestWaitingOrder.time < moment().subtract(5, 'minutes') &&
          API.lastTouch < moment().subtract(5, 'minutes')))
    ) {
      // We should flash:
      if (!isFlashing) {
        if (!navigation.isFocused()) {
          this.focusMe();
        }
        this.setState({
          isFlashing: true,
        });
      }
    } else if (isFlashing) {
      this.setState({
        isFlashing: false,
      });
    }
  };

  _stopFlashing = () => {
    const { isFlashing } = this.state;
    if (isFlashing) {
      this.setState({ isFlashing: false });
    }
  };

  _setIdleScreen = async () => {
    if (this._mounted) {
      this.setState({
        kds_idle_screen: API.handheldDevice.getPreference('kds_idle_screen'),
      });
    }
  };

  _lock = () => {
    API.clearUser();
    const { navigation } = this.props;
    navigation.navigate('Pin', {
      forwardTo: 'KDSView',
    });
  };
}

// Right hand side of the tab bar
function TabRight({ children }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: Colors.primary,
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginLeft: 5,
        flex: 1,
      }}
    >
      {children}
    </View>
  );
}
TabRight.displayName = 'TabRight';
