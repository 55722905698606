import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import Stripe from 'react-native-stripe-terminal';
import { withNavigation } from 'react-navigation';
import { showMessage } from 'react-native-flash-message';
import moment from 'moment';
import API from '../api';
import HeaderIconButton from './HeaderIconButton';
import { checkAccess } from '../helpers/Access';
import Fontello from '../assets/fonts/Fontello';

class ReaderStatus extends React.Component {
  static lowBatteryShown = false;

  _listeners = [];

  constructor(props) {
    super(props);

    this.state = {
      status: Stripe.readerConnected ? 'CONNECTED' : 'NOT_CONNECTED',
      discovering: false,
    };
  }

  async componentDidMount() {
    this._mounted = true;
    this._listeners = [
      Stripe.on('UnexpectedDisconnect', this._unexpectedDisconnect),
      Stripe.on('ConnectionStatusChange', this.statusChanged),
      Stripe.on('LowBatteryWarning', this.lowBattery),
      Stripe.on('StartInstallingUpdate', this.updateStarted),
      Stripe.on('FinishedInstallingUpdate', this.updateFinished),
      Stripe.on('ReaderSoftwareUpdateProgress', this.readerSoftwareUpdateProgress),
      Stripe.on('Discovering', this._discoveryChange),
      API.on('config_updated', this._refresh),
    ];
    this.setState({
      status: Stripe.readerStatus,
      discovering: await Stripe.isDiscovering(),
    });
  }

  componentWillUnmount() {
    this._mounted = false;
    this._listeners.forEach(l => l.remove());
  }

  _refresh = () => {
    if (this._mounted) this.forceUpdate();
  };

  _discoveryChange = discovering => {
    this.setState({
      discovering,
    });
  };

  _unexpectedDisconnect = async () => {
    if (this._mounted) {
      this.setState({
        status: 'NOT_CONNECTED',
        discovering: await Stripe.isDiscovering(),
      });
    }
  };

  statusChanged = status => {
    if (!this._mounted) return;
    this.setState({
      status,
    });
  };

  /** Low battery message. Shows at most once every 10 minutes */
  lowBattery = () => {
    // show at most once every 10 minutes:
    if (
      ReaderStatus.lowBatteryShown &&
      ReaderStatus.lowBatteryShown.isAfter(moment().subtract(10, 'minutes'))
    )
      return;

    ReaderStatus.lowBatteryShown = moment();
    showMessage({
      floating: true,
      position: 'top',
      type: 'warning',
      message: 'Card Reader has low battery. Please charge.',
    });
  };

  updateStarted = () => {
    this.setState({ updating: true });
  };

  updateFinished = () => {
    this.setState({ updating: false });
  };

  readerSoftwareUpdateProgress = progress => {
    if (!this._mounted) return;
    this.setState({
      updating: true,
      readerSoftwareUpdateProgress: progress,
    });
  };

  getText = () => {
    const { status, discovering, updating, readerSoftwareUpdateProgress } = this.state;

    if (updating) {
      return `Updating (${Math.round(readerSoftwareUpdateProgress * 100)}%)`;
    }
    if (discovering) {
      return { path: 'cardReader__discovering', rawFailover: 'Discovering' };
    }
    switch (status) {
      case 'CONNECTED':
        return { path: 'cardReader__connected', rawFailover: 'Connected' };
      case 'DISCOVERING':
        return { path: 'cardReader__discovering', rawFailover: 'Discovering' };
      case 'NOT_CONNECTED':
        return { path: 'cardReader__notConnected', rawFailover: 'Not Connected' };
      case 'CONNECTING':
        return { path: 'cardReader__connecting', rawFailover: 'Connecting' };
      default:
        return { path: 'cardReader__unknown', rawFailover: 'Unknown' };
    }
  };

  render() {
    const config = API.getConfig();
    if (!config.use_stripe_chip_reader) return null;
    const { status, discovering } = this.state;

    let style = styles[status];
    if (!style) {
      style = { color: '#878787' };
    }
    if (discovering) style = { color: '#0d939f' };

    const { path, data, rawFailover } = this.getText();

    return (
      <HeaderIconButton
        onPress={this.onPress}
        iconStyle={[style]}
        type={Fontello}
        icon="card-reader"
        name={rawFailover}
        textPath={path}
        formatData={data}
      />
    );
  }

  onPress = async () => {
    if (await checkAccess('manage_reader', true, this.props.requirePin)) {
      this._goToStripe();
    }
  };

  _goToStripe = () => {
    const props = {
      autoload: this.state.status === 'NOT_CONNECTED', // If we're not connected, we probably want to auto-connect to the saved reader
    };
    this.props.navigation.navigate('StripeConfig', props);
  };
}

export default withNavigation(ReaderStatus);

let styles = EStyleSheet.create({
  CONNECTED: {
    color: '#25c500',
  },
  NOT_CONNECTED: {
    color: 'red',
  },
  CONNECTING: {
    color: 'yellow',
  },
});
