import React from 'react';
import { Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Colors } from '../../styles';

function OrderStaffNotes({ blockStyle, notes }) {
  return (
    <View style={[styles.staffNotes, { ...blockStyle }]}>
      <Text style={styles.staffNotes__Label}>Staff Notes:</Text>
      <Text style={styles.staffNotes__Text}>{notes}</Text>
    </View>
  );
}

const styles = EStyleSheet.create({
  staffNotes: {
    padding: 10,
    backgroundColor: Colors.lightTheme.warning.light,
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8',
    borderTopWidth: 1,
    borderTopColor: '#c8c8c8',
  },
  staffNotes__Label: {
    color: 'black',
    fontWeight: 'bold',
  },
  staffNotes__Text: {
    color: 'black',
    fontStyle: 'italic',
  },
});

export default OrderStaffNotes;
