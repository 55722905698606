import React from 'react';
import { FormattedMessage } from 'react-native-globalize';
import PickerModal from './Modals/PickerModal';
import LANGUAGES from '../i18n/languages';

function LocalePicker({ selectedValue, visible, onHideCallback, title, onItemPress, ...rest }) {
  return (
    <PickerModal
      title={
        <FormattedMessage
          id="locale__select"
          defaultMessage="Select Locale"
        />
      }
      data={Object.entries(LANGUAGES).map(([locale, languageFile]) => ({
        label: languageFile.locale__name,
        value: locale,
      }))}
      selectedValue={selectedValue}
      visible={visible}
      onHideCallback={onHideCallback}
      onItemPress={onItemPress}
      width={300}
      {...rest}
    />
  );
}

export default LocalePicker;
