import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, Icon, Row } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { Pressable } from 'react-native';
import { LeftColStyles as styles } from '../../../styles/POSStyles';

import POSContext from '../../../contexts/POSContext';
import { POS_REDUCER_ACTIONS, POS_SCREEN } from '../../../screens/POS';
import { PAYMENT_TYPE } from '../../../screens/POS/Checkout/Checkout';

function PaymentTypeCol() {
  const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD);

  const { POSDispatch } = useContext(POSContext);
  const {
    HeadingContainerSelected,
    HeadingContainer,
    Col,
    TitleContainer,
    HeadingTitle,
    MenuTitle,
  } = styles;

  useEffect(() => {
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_PAYMENT_TYPE,
      paymentType: selectedPaymentType,
    });
  }, [selectedPaymentType]);

  const headingStyle = paymentType =>
    PAYMENT_TYPE[paymentType] === selectedPaymentType ? HeadingContainerSelected : HeadingContainer;

  /**
   * When a menu heading is pressed, we want to update the middle column view and update the variable that tracks the currently selected menu heading
   * @param menuHeading
   */
  const paymentTypePressed = paymentType => {
    setSelectedPaymentType(PAYMENT_TYPE[paymentType]);
  };

  const returnToMenuView = () => {
    POSDispatch({
      type: POS_REDUCER_ACTIONS.SET_POS_SCREEN,
      POSScreen: POS_SCREEN.MENU,
    });
  };

  return (
    <View style={Col}>
      <View style={TitleContainer}>
        <Pressable onPress={returnToMenuView}>
          <Row
            height="100%"
            alignItems="center"
            maxH={40}
          >
            <Icon
              size={6}
              marginRight={1}
              as={Ionicons}
              name="arrow-back-outline"
              color="white"
            />
            <Text style={MenuTitle}>Back</Text>
          </Row>
        </Pressable>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        {Object.entries(PAYMENT_TYPE).map(([paymentType, paymentTypeName]) => (
          <Pressable
            key={paymentType}
            style={headingStyle(paymentType)}
            onPress={() => paymentTypePressed(paymentType)}
          >
            <Text style={HeadingTitle}>{paymentTypeName}</Text>
          </Pressable>
        ))}
      </ScrollView>
    </View>
  );
}

export default PaymentTypeCol;
