import React from 'react';
import { View, ActivityIndicator, Platform } from 'react-native';
import axios from 'axios';
import { Button, Text } from 'native-base';
import * as IntentLauncher from 'expo-intent-launcher';
import Colors from '../constants/Colors';
import API from '../api';
import { IS_ELO, SHOW_SERVER_CHOOSER } from '../constants/Config';

export default class CheckConnectionScreen extends React.PureComponent {
  static navigationOptions = {
    title: 'Check Internet Connection',
  };

  state = {
    tested: false,
    bbot: false,
    net: false,
    status: null,
  };

  componentDidMount() {
    this._mounted = true;
    this._testConnection();
  }

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this._tcTimer);
  }

  _testConnection = async () => {
    if (!this._mounted) return;

    const bbotConnected = await API.checkConnection();
    let netConnected;

    this.setState({
      status: 'Attempting to connect',
    });

    if (!bbotConnected) {
      try {
        const ip = await axios.get('http://httpbin.org/ip');
        netConnected = true;
      } catch (err) {
        netConnected = false;
      }

      this._tcTimer = setTimeout(this._testConnection, 10000);
    } else {
      setTimeout(() => {
        this.props.navigation.navigate('AuthLoading');
      }, 1000);
    }

    this.setState({
      tested: true,
      bbot: bbotConnected,
      net: netConnected,
      status: bbotConnected ? 'Success!' : 'Failed. Trying again shortly...',
    });
  };

  render() {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <Text style={{ fontSize: 16, textAlign: 'center' }}>{this._getMessage()}</Text>
        <ActivityIndicator
          size="large"
          style={{ marginVertical: 20 }}
          color={Colors.primary}
        />
        <Text style={{ fontSize: 16 }}>{this.state.status}</Text>
        {Platform.OS === 'android' && !IS_ELO && (
          <View style={{ marginTop: 20 }}>
            <Button
              variant="ghost"
              onPress={this._goToWifiSettings}
            >
              <Text>WiFi Settings</Text>
            </Button>
          </View>
        )}
        {Platform.OS === 'android' && SHOW_SERVER_CHOOSER && (
          <View style={{ marginTop: 20 }}>
            <Button
              variant="ghost"
              onPress={this._goToServerChooser}
            >
              <Text>Server Chooser</Text>
            </Button>
          </View>
        )}
      </View>
    );
  }

  _getMessage() {
    const { bbot, net, tested } = this.state;
    if (!tested) return 'Checking connection status...';
    if (bbot) return 'Connected!';
    if (net) return `Unable to connect to ${axios.defaults.baseURL}. Please contact support`;
    return 'Unable to connect to the Internet. Please check your wifi connection and try again.';
  }

  _goToWifiSettings = () => {
    IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.WIFI_SETTINGS);
  };

  _goToServerChooser = () => {
    this.props.navigation.navigate('ServerChooser');
  };
}
