import React from 'react';
import { View, Text } from 'react-native';
import { FormattedCurrency } from 'react-native-globalize';
import { TotalSectionStyles as styles } from '../../../styles/POSStyles';

function TotalSection({ centsDue }) {
  return (
    <View style={styles.TotalDueContainer}>
      <Text style={styles.TotalDueText}>Total Due</Text>
      <FormattedCurrency
        value={centsDue * 0.01}
        style={styles.TotalDueText}
      />
    </View>
  );
}

export default TotalSection;
