const tintColor = '#2f95dc';

// BBOT Palette:
const primary = '#090934';
const primaryLight = '#A5E3F6';
const secondary = '#20409a';
const ternary = '#9665aa';
const quaternary = '#48A9A6';
const special = '#11711a';
const primary_analagous = '#04a87c';
const secondary_analagous = '#207D9A';

const lightGray = '#e5e7e9';
const gray = '#bcbec0';
const darkGray = '#7f8183';
const light = '#FFF';
// const light = '#000';

const success = '#5CB85C';

const dark = '#000';
// const dark = '#FFF';
const accent = '#ed1651';
const error = '#ff0000';
const warning = '#F0AD4E';
const danger = '#D9534F';

export default {
  accent,
  background: '#fff',
  buttonSelected: '#c7c7c7',
  counter_nofeedback: '#880808',
  counter_service: '#5D3FD3',
  counter_service_with_expo: '#DAA520',
  danger,
  dark,
  darkGray,
  disabled: darkGray,
  error,
  errorBackground: error,
  errorText: light,
  gray,
  highlight: '#17b5e9',
  isPolling: success,
  light,
  lightGray,
  linkColor: primary,
  noticeBackground: tintColor,
  noticeText: '#fff',
  pickupNoFeedback: '#e3652a',
  primary,
  primary_analagous,
  primaryLight,
  quaternary,
  robotButton: '#ff6a0e',
  secondary,
  secondary_analagous,
  special,
  statusIcon: '#1D388F',
  success,
  tabBar: '#fefefe',
  tabIconDefault: light,
  tabIconSelected: primary,
  ternary,
  tintColor,
  unreadNotification: secondary,
  warning,
  warningBackground: '#EAEB5E',
  warningText: '#666804',
};
