import React from 'react';
// import { Text } from 'native-base';
import { Pressable, Text } from 'react-native';
import { MenuSelectionStyles as styles } from '../../styles/POSStyles';

function MenuSelection(props) {
  const { name, onPress, selected, available = true } = props;
  const { SelectedSelection, Selection } = styles;

  return (
    <Pressable
      style={selected ? SelectedSelection : Selection}
      onPress={onPress}
      disabled={!available}
    >
      <Text
        style={styles.SelectionText}
        numberOfLines={3}
        ellipsizeMode="tail"
      >
        {name}
      </Text>
    </Pressable>
  );
}

export default MenuSelection;
