import React, { Component } from 'react';
import {
  View,
  ScrollView,
  Dimensions,
  Animated,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types';
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../api';

let width;

export default class IndicatorScrollView extends React.PureComponent {
  state = {
    scrollX: new Animated.Value(0),
  };

  componentDidMount() {
    API.on('orientation', this._orientationChange);
  }

  componentWillUnmount() {
    API.off('orientation', this._orientationChange);
  }

  _orientationChange = o => {
    this.forceUpdate();
  };

  render() {
    width = Dimensions.get('window').width;
    // position will be a value between 0 and photos.length - 1 assuming you don't scroll pass the ends of the ScrollView
    this.position = Animated.divide(this.state.scrollX, width);

    this.columns = this.props.columns;
    this.items = this.props.children || [];
    this.pages = Math.ceil(this.items.length / this.columns);

    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <View style={{ width, flex: 1 }}>
          <ScrollView
            ref={sv => (this._sv = sv)}
            horizontal
            pagingEnabled // animates ScrollView to nearest multiple of it's own width
            showsHorizontalScrollIndicator={false}
            onContentSizeChange={this._onContentSizeChange}
            decelerationRate={0.9}
            // the onScroll prop will pass a nativeEvent object to a function
            onScroll={Animated.event(
              // Animated.event returns a function that takes an array where the first element...
              [{ nativeEvent: { contentOffset: { x: this.state.scrollX } } }], // ... is an object that maps any nativeEvent prop to a variable
              { useNativeDriver: false },
            )} // in this case we are mapping the value of nativeEvent.contentOffset.x to this.state.scrollX
            scrollEventThrottle={16} // this will ensure that this ScrollView's onScroll prop is called no faster than 16ms between each function call
          >
            {this._getPages()}
          </ScrollView>
        </View>
        {this._getIndicator()}
      </View>
    );
  }

  _getIndicator() {
    const { indicatorStyle } = this.props;
    return (
      this.pages > 1 && (
        <View style={{ flexDirection: 'row' }}>
          {_.times(this.pages, i => {
            // the _ just means we won't use that parameter
            const opacity = this.position.interpolate({
              inputRange: [i - 1, i, i + 1], // each dot will need to have an opacity of 1 when position is equal to their index (i)
              outputRange: [0.3, 1, 0.3], // when position is not i, the opacity of the dot will animate to 0.3
              // inputRange: [i - 0.50000000001, i - 0.5, i, i + 0.5, i + 0.50000000001], // only when position is ever so slightly more than +/- 0.5 of a dot's index
              // outputRange: [0.3, 1, 1, 1, 0.3], // is when the opacity changes from 1 to 0.3
              extrapolate: 'clamp', // this will prevent the opacity of the dots from going outside of the outputRange (i.e. opacity will not be less than 0.3)
            });
            return (
              <TouchableWithoutFeedback
                testID={`indicatorScrollViewPage${i}`}
                key={i} // we will use i for the key because no two (or more) elements in an array will have the same index
                onPress={e => {
                  this._goToPage(i, e);
                }}
              >
                <Animated.View // we will animate the opacity of the dots so use Animated.View instead of View here
                  style={[{ opacity }, styles.indicator, indicatorStyle]}
                />
              </TouchableWithoutFeedback>
            );
          })}
        </View>
      )
    );
  }

  _getPages() {
    const pages = [];
    for (let i = 0; i < this.pages; i++) {
      pages.push(
        <View
          key={i}
          style={{ width, flexDirection: 'row', flex: 1 }}
        >
          {this._getPageItems(i)}
        </View>,
      );
    }
    return pages;
  }

  _getPageItems(page) {
    const start = page * this.columns;
    const items = [];

    for (let i = start; i < start + this.columns; i++) {
      items.push(this.items[i]);
    }
    return items;
  }

  _onContentSizeChange = (contentWidth, contentHeight) => {
    this._contentWidth = contentWidth;
  };

  _goToPage = (page, e) => {
    if (this._sv && this._contentWidth) {
      const xPos = (this._contentWidth / this.pages) * page;
      this._sv?.scrollTo({ x: xPos, y: 0 });
    }
  };
}

IndicatorScrollView.propTypes = {
  columns: PropTypes.number.isRequired,
};

const styles = EStyleSheet.create({
  indicator: {
    height: 5,
    width: 15,
    backgroundColor: '#595959',
    marginLeft: 8,
    marginRight: 8,
  },
});
