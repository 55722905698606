import { Text, TouchableWithoutFeedback, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import React from 'react';
import Colors from '../constants/Colors';

function FolderTab({
  active,
  style,
  heading,
  count,
  showRightBorder,
  onLayout,
  onPress,
  activeColor,
}) {
  const renderStyle = active ? styles.activeTab : null;
  const extraStyle = active && activeColor ? { backgroundColor: activeColor } : null;

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={{ position: 'relative', bottom: 0 }}
        onLayout={onLayout}
      >
        <View style={[styles.tab, renderStyle, style, extraStyle]}>
          <Text
            numberOfLines={1}
            style={active ? styles.activeTabText : styles.tabText}
          >
            {heading}
          </Text>
          {count > 0 && (
            <View style={styles.tabCount}>
              <Text style={styles.tabCountText}>{count}</Text>
            </View>
          )}
        </View>
        {showRightBorder && <View style={styles.tabRightBorder} />}
        {active && (
          <View style={[styles.tabFlairLeft, extraStyle]}>
            <View style={styles.tabFlairLeftInner} />
          </View>
        )}
        {active && (
          <View style={[styles.tabFlairRight, extraStyle]}>
            <View style={styles.tabFlairRightInner} />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}
FolderTab.displayName = 'FolderTab';

const styles = EStyleSheet.create({
  tab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    minWidth: 150,
    minHeight: 40,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tabCount: {
    position: 'absolute',
    right: 8,
    top: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
    borderRadius: 20,
    height: 20,
    minWidth: 20,
    marginLeft: 20,
  },
  tabCountText: {
    fontSize: 13,
    color: 'white',
  },
  tabText: {
    color: 'white',
  },
  activeTabText: {
    color: 'black',
  },
  tabFlairLeft: {
    position: 'absolute',
    width: 8,
    height: 8,
    left: -8,
    bottom: 0,
    backgroundColor: 'white',
  },
  tabFlairLeftInner: {
    width: 8,
    height: 8,
    borderBottomRightRadius: 8,
    backgroundColor: Colors.primary,
  },
  tabFlairRight: {
    zIndex: 100,
    position: 'absolute',
    width: 8,
    height: 8,
    right: -8,
    bottom: 0,
    backgroundColor: 'white',
  },
  tabFlairRightInner: {
    zIndex: 101,
    width: 8,
    height: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: Colors.primary,
  },
  tabRightBorder: {
    position: 'absolute',
    height: '50%',
    top: '25%',
    right: 0,
    borderRightWidth: 1,
    borderRightColor: 'rgba(255,255,255,0.62)',
  },
  activeTab: {
    position: 'relative',
    zIndex: 0,
    backgroundColor: '#fff',
    // marginBottom: -1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomWidth: 1,
    borderColor: 'white',
  },
});

export default FolderTab;
