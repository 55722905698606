import React, { Component } from 'react';
import { Platform, StyleSheet, Modal } from 'react-native';
import PropTypes from 'prop-types';
import { View, Button, FormControl, Input, Stack, Text } from 'native-base';

/**
 *
 */
export default class Prompt extends Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    onCancel: PropTypes.func,
    cancelText: PropTypes.string,
    onSubmit: PropTypes.func,
    validator: PropTypes.func,
    submitText: PropTypes.string,
    onChangeText: PropTypes.func,
    borderColor: PropTypes.string,
    promptStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    buttonTextStyle: PropTypes.object,
    submitButtonStyle: PropTypes.object,
    submitButtonTextStyle: PropTypes.object,
    cancelButtonStyle: PropTypes.object,
    cancelButtonTextStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    textInputProps: PropTypes.object,
  };

  static defaultProps = {
    visible: false,
    title: 'Input Required',
    onCancel: () => {},
    onSubmit: () => {},
    defaultValue: '',
    cancelText: 'CANCEL',
    submitText: 'OK',
    borderColor: '#ccc',
    promptStyle: {},
    placeholder: '',
    headerStyle: {},
    titleStyle: {},
    buttonStyle: {},
    buttonTextStyle: {},
    submitButtonStyle: {},
    submitButtonTextStyle: {},
    cancelButtonStyle: {},
    cancelButtonTextStyle: {},
    inputStyle: {},
    onChangeText: () => {},
    suffix: '',
  };

  state = {
    value: '',
    error: '',
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.inputRef = React.createRef();
    this.state.value = props.defaultValue;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        value: this.props.defaultValue,
      });
    }
  }

  _onChangeText = value => {
    this.setState({ value, error: '' });
    this.props.onChangeText(value);
  };

  _onSubmitPress = () => {
    const { validator, onSubmit } = this.props;
    const { value } = this.state;
    if (validator) {
      const res = validator(value);
      if (res !== true) {
        this.setState({ error: res });
        return;
      }
    }
    onSubmit(value);
  };

  _onCancelPress = () => {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  };

  _renderDialog = () => {
    const {
      title,
      placeholder,
      cancelText,
      submitText,
      borderColor,
      promptStyle,
      headerStyle,
      titleStyle,
      buttonStyle,
      buttonTextStyle,
      submitButtonStyle,
      submitButtonTextStyle,
      cancelButtonStyle,
      cancelButtonTextStyle,
      inputStyle,
      textInputProps,
      inputCmp,
      suffix,
    } = this.props;

    return (
      <View
        style={styles.dialog}
        key="prompt"
      >
        <View style={styles.dialogOverlay} />
        <View style={[styles.dialogContent, { borderColor }, promptStyle]}>
          <View style={[styles.dialogTitle, { borderColor }, headerStyle]}>
            <Text style={[styles.dialogTitleText, titleStyle]}>{title}</Text>
          </View>
          <FormControl>
            <View style={styles.dialogBody}>
              <View style={{ flex: 1 }}>
                <Stack>
                  {!!inputCmp && inputCmp}
                  {!inputCmp && (
                    <Input
                      style={[styles.dialogInput, inputStyle]}
                      ref={this.inputRef}
                      variant="underlined"
                      onChangeText={this._onChangeText}
                      value={this.state.value}
                      placeholder={placeholder}
                      autoFocus
                      underlineColorAndroid="white"
                      {...textInputProps}
                    />
                  )}
                </Stack>
              </View>
              {!!suffix && <Text style={{ marginLeft: 5 }}>{suffix}</Text>}
            </View>
            {typeof this.state.error === 'string' && (
              <Text
                note
                style={{ color: 'red', marginLeft: 15 }}
              >
                {this.state.error}
              </Text>
            )}
            <View style={[styles.dialogFooter, { borderColor }]}>
              <Button
                onPress={this._onCancelPress}
                variant="ghost"
                style={[{ minWidth: 75 }, styles.dialogAction, buttonStyle, cancelButtonStyle]}
              >
                <Text style={[styles.dialogActionText, buttonTextStyle, cancelButtonTextStyle]}>
                  {cancelText}
                </Text>
              </Button>

              <Button
                onPress={this._onSubmitPress}
                style={[{ minWidth: 75 }, styles.dialogAction, buttonStyle, submitButtonStyle]}
              >
                <Text style={[styles.dialogActionText, buttonTextStyle, submitButtonTextStyle]}>
                  {submitText}
                </Text>
              </Button>
            </View>
          </FormControl>
        </View>
      </View>
    );
  };

  /* show = (props) => {
    this.setState({
      ...props,
      value: props.defaultValue || '',
      visible: true
    }, () => {
      if(Platform.OS === 'web') {
        // Hack to make sure modal shows on top of other modals:
        setTimeout(() => {
          const element = this.modalRef.current.parentNode.parentNode;
          const style = getComputedStyle(element);
          element.style.zIndex = style.zIndex + 1;
          this.inputRef.current._root?.focus()
        })
      }
    })
  } */

  render() {
    return (
      <Modal
        ref={this.modalRef}
        onRequestClose={() => this._onCancelPress}
        transparent
        visible={this.props.visible}
        statusBarTranslucent
      >
        {this._renderDialog()}
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  dialog: {
    flex: 1,
    alignItems: 'center',
  },
  dialogOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  dialogContent: {
    elevation: 5,
    top: 150,
    width: 300,
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
  },
  dialogTitle: {
    borderBottomWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  dialogTitleText: {
    fontSize: 18,
    fontWeight: '600',
  },
  dialogBody: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  dialogInput: {
    height: 50,
    fontSize: 18,
  },
  dialogFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 10,
  },
  dialogAction: {},
  dialogActionText: {
    fontSize: 18,
    textAlign: 'center',
    color: '#006dbf',
  },
});
