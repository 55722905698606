import React from 'react';
import TextField from './TextField';
import { formatPhoneNumber, normalizePhoneInput } from '../../../helpers/HelperFunctions';

function PhoneNumberField(props) {
  const { required } = props;

  const validator = number => {
    if (!required && !number) return true;
    return formatPhoneNumber(number) !== null;
  };

  return (
    <TextField
      {...props}
      validator={validator}
      keyboardType="phone-pad"
      placeholder="(xxx) xxx-xxxx"
      formatter={normalizePhoneInput}
    />
  );
}

export default PhoneNumberField;
