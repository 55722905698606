import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import * as DeviceInfo from 'expo-device';
import SystemSetting from 'react-native-system-setting';
import { showMessage } from 'react-native-flash-message';
import { Button, Text } from 'native-base';
import Stripe from 'react-native-stripe-terminal';
import axios from 'axios';
import moment from 'moment';
import AsyncStorage from '@react-native-community/async-storage';
import API from '../api';
import ErrorBanner from '../components/ErrorBanner';
import { requestLocationPermission } from '../helpers/HelperFunctions';
import FullStory from '../services/FullStory';
import Sentry from '../services/Sentry';
import Colors from '../constants/Colors';
import { FULLSTORY_ENABLED, SHOW_SERVER_CHOOSER, IS_IOS } from '../constants/Config';
import DB, { resetDB } from '../services/PouchDB';

export default class AuthLoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      progress: '',
      allowSkip: false,
      showTryAgain: false,
      poll_status: '',
      currStep: 0,
      showEnableLocationButton: false,
      showContinueButton: false,
      locationSkipped: false,
      noBluetooth: false,
    };

    this._steps = [
      this._enableWIFI,
      this._testInternet,
      this._checkLoginState,
      this._checkCustomer,
      this._checkDevice,
      this._getConfig,
      this._checkDB,
      this._getMenuData,
      this._enableBluetooth,
      this._enableLocation,
      this._connectReader,
    ];

    this._currStep = 0;
    this.navigate = props.navigation.navigate;
  }

  componentDidMount() {
    this._mounted = true;

    try {
      this._currStep = this.props.navigation.getParam('authStep', 0);
      this._bootstrapAsync();
    } catch (err) {
      this.setState({
        error: err,
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  _enableWIFI = async next => {
    if (Platform.OS === 'web') return true;

    const hasWifi = await DeviceInfo.hasPlatformFeatureAsync('android.hardware.wifi');
    if (hasWifi) {
      const wifi = await SystemSetting.isWifiEnabled();
      if (!wifi) {
        this.setState({
          progress: 'Turning on Wifi',
          allowSkip: true,
        });
        return new Promise(resolve => {
          SystemSetting.switchWifiSilence(() => {
            resolve(true);
          });
        });
      }
    }
    return true;
  };

  _testInternet = async () => {
    if (Platform.OS === 'web') return true;

    this.setState({
      progress: 'Checking internet connection...',
      allowSkip: false,
    });

    if (SHOW_SERVER_CHOOSER && !axios.defaults.baseURL) {
      this.navigate('ServerChooser');
      return false;
    }

    let online = false;
    try {
      online = await API.checkConnection();
    } catch (err) {
      // During dev mode, occasionally API is not defined on Hot Reload, so need to try again:
      console.log("Can't find API for some stupid reason during hot reload, reloading");
      return;
    }

    if (!online) {
      this.navigate('CheckConnectionScreen');
      return false;
    }
    return true;
  };

  _checkLoginState = async () => {
    this.setState({
      progress: 'Checking login state',
      allowSkip: false,
    });
    const loggedIn = await API.isLoggedIn();
    if (!loggedIn) {
      this.navigate('Login');
      return false;
    }
    await API.loadUserSettings();

    return true;
  };

  _checkCustomer = async () => {
    if (!API.customer_id) {
      this.navigate('CustomerChooser');
      return false;
    }
    if (!API.main_customer) await API.selectCustomer(API.customer_id);

    const email = await API.getAccount();

    if (FULLSTORY_ENABLED) {
      if (API.main_customer.fullstory) {
        FullStory.restart();
        FullStory.identify(API.customer_id, {
          email,
          displayName: email,
        });
      } else {
        FullStory.shutdown();
      }
    }
    return true;
  };

  _checkDevice = async () => {
    this.setState({
      progress: 'Checking Device Registration',
    });

    const response = await API.getDeviceInfo();

    if (!response.device) {
      this.navigate('DeviceRegistration', { authStep: this._currStep });
      return false;
    }

    return true;
  };

  _getConfig = async () => {
    // _getConfig
    this.setState({
      progress: 'Getting Config',
    });

    if (!API.config) {
      this.navigate('ConfigChooser');
      return false;
    }
    return true;
  };

  /**
   * Test to make sure we can write to our PouchDB. If it fails, reset the DB
   * @returns {Promise<Promise<unknown> | Promise.Promise>}
   * @private
   */
  _checkDB = async () =>
    new Promise(resolve => {
      DB()
        .upsert('writeTest', doc => {
          doc.timestamp = moment().format('LLL');
          return doc;
        })
        .then(res => {
          resolve(true);
        })
        .catch(async err => {
          await resetDB();
          resolve(true);
        });
    });

  _getMenuData = async () => {
    this.setState({ progress: 'Loading cache...' });
    try {
      await API.cache.load();
    } catch (err) {
      await resetDB();
    }

    if (API.hasPolled) return true; // This causes the pin screen loop bug

    this.setState({
      progress: 'Fetching Data...',
      showTryAgain: false,
    });

    clearTimeout(this._fetchDataTimeout);
    clearTimeout(this._countdownInterval);

    // Prevent app from remaining stuck on "Fetching Data..."
    const failureListener = API.once('request_failure', () => {
      Sentry.captureMessage('Request Failure, triggering poll_complete');
      API.trigger('poll_complete', false);
    });

    const pollCompleteTimeout = setTimeout(() => {
      Sentry.captureMessage('Timeout, manually triggering poll_complete');
      API.trigger('poll_complete', false);
    }, 45000);

    const statusListener = API.on('poll_status', message => {
      if (this._mounted) this.setState({ poll_status: message });
    });

    return new Promise((resolve, reject) => {
      API.once('poll_complete', success => {
        try {
          clearTimeout(pollCompleteTimeout);
          failureListener.remove();
          statusListener.remove();
        } catch (err) {
          Sentry.captureException(err);
        }

        if (success) {
          this.setState({ poll_status: 'Poll success!' });
          resolve(true);
        } else {
          API.stopPolling();
          let timer = 120;

          // Countdown till next poll:
          this._countdownInterval = setInterval(() => {
            this.setState({
              progress: `Fetching Data Failed. Will try again in ${timer} seconds.`,
            });
            timer--;
            if (!timer) {
              // When timer reaches zero, clear the interval, and re-start bootstrap
              clearInterval(this._countdownInterval);
              this._bootstrapAsync();
            }
          }, 1000);

          this._fetchDataTimeout = setTimeout(() => {
            this.setState({ showTryAgain: true });
          }, 30000);

          resolve(false);
        }
      });

      API.startPolling();
    });
  };

  _enableBluetooth = async () => {
    if (Platform.OS === 'web') return true;

    const config = API.getConfig();

    try {
      if (config.use_stripe_chip_reader) {
        // This sometimes lies:
        const hasBluetooth = await DeviceInfo.hasPlatformFeatureAsync('android.hardware.bluetooth');
        const isEmulator = DeviceInfo.productName.includes('emulator') || !DeviceInfo.isDevice;

        if (!isEmulator && hasBluetooth) {
          const btEnabled = await SystemSetting.isBluetoothEnabled();
          if (!btEnabled) {
            this.setState({
              progress: 'Enabling Bluetooth',
              poll_status: '',
            });

            return new Promise(resolve => {
              const timeout = setTimeout(() => {
                this.setState({
                  progress: 'Failed to enable Bluetooth. Chip reader will not work.',
                  showContinueButton: true,
                });
                resolve(false);
              }, 15000);

              SystemSetting.switchBluetoothSilence(() => {
                clearTimeout(timeout);
                resolve(true);
              });
            });
          }
          return true;
        }
        if (isEmulator) {
          this.setState({ noBluetooth: true });
          await AsyncStorage.setItem('bbpos_simulator', 'true');
          return true;
        }
        this.setState({
          progress: 'No bluetooth detected. Card Reader will not work.',
          noBluetooth: true,
          showContinueButton: true,
        });
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    return true;
  };

  _enableLocation = async () => {
    if (Platform.OS === 'web') return true;

    const { config } = API;
    if (!config.use_stripe_chip_reader) return true;
    try {
      // If Location is not enabled, enable it:
      const loc = await SystemSetting.isLocationEnabled();
      if (!loc) {
        this.setState({
          progress: 'Please enable Location...',
          poll_status: '',
          showEnableLocationButton: true,
        });
        return false;
      }

      this.setState({ progress: 'Checking for location permissions', poll_status: '' });
      // Now we need to check if location permission is granted:
      const hasPermission = await requestLocationPermission();

      if (hasPermission) {
        this.setState({ progress: 'Location permissions granted!' });
        return true;
      }
      this.setState({
        progress: 'Unable to access location. Stripe chipper will not be available.',
        locationSkipped: true,
        showContinueButton: true,
      });
      return false;
    } catch (error) {
      Sentry.captureException(error);
    }
    return true;
  };

  _switchLocation = async () => {
    SystemSetting.switchLocation(this._bootstrapAsync);

    this.setState({
      showEnableLocationButton: false,
    });
  };

  _continue = async () => {
    this._currStep++;
    this.setState({
      progress: '',
      showContinueButton: false,
      currStep: this._currStep,
    });
    this._bootstrapAsync();
  };

  _connectReader = async () => {
    const { config } = API;
    const { locationSkipped, noBluetooth } = this.state;

    if (!config.use_stripe_chip_reader || locationSkipped || noBluetooth) return true;

    try {
      this.setState({
        progress: 'Checking Stripe Terminal',
        allowSkip: true,
      });

      /**
       * TODO
       * We need to extract this Stripe.init and put it in a common Stripe Lib (StripeService?)
       */
      const defaultReader = await API.getReader();

      try {
        await Stripe.init({
          fetchConnectionToken: API.getStripeConnectionToken,
          createPaymentIntent: API.createStripePaymentIntent,
          locationId: API.customer.stripe_terminal_location_id,
          defaultReader, // if defaultReader is set, Stripe lib will try to auto-connect
        });
        if (Platform.OS === 'android' && defaultReader) {
          const listener = Stripe.on('DiscoverFinished', connected => {
            listener.remove();
            if (!Stripe.readerConnecting && !connected)
              showMessage({ message: 'Failed connecting reader. Please be sure it is turned on' });
          });
        }
      } catch (err) {
        this.setState({
          progress: `Error initializing Stripe: ${err}`,
          showContinueButton: true,
        });
        return false;
      }

      const connectedReader = await Stripe.getConnectedReader();

      if (connectedReader || defaultReader) {
        return true;
      }
      this.navigate('StripeConfigInit', { goTo: 'Pin' });
      return false;
    } catch (error) {
      Sentry.captureException(error, {});
    }
    return true;
  };

  // Fetch the token from storage then navigate to our appropriate place
  _bootstrapAsync = async () => {
    const { navigation } = this.props;
    const forwardTo = navigation.getParam('forwardTo');

    for (let i = this._currStep; i < this._steps.length; i++) {
      const success = await this._steps[i]();
      if (success) {
        this._currStep++;
        if (this._mounted) {
          this.setState({
            progress: '',
            currStep: this._currStep,
          });
        }
      } else return;
    }

    const config = API.getConfig();

    if (!config) {
      // something went wrong, likely a hot reload in dev mode. Try this again.
      Sentry.captureMessage('Config not found for some reason');
      this._currStep = 0;
      this._bootstrapAsync();
      return;
    }

    try {
      Sentry.setExtra('customer', API.main_customer?.customer_name);
      Sentry.setExtra('device', API.handheldDevice?.name);
      Sentry.setExtra('config', API.config?.config_name);
      const email = await API.getAccount();
      if (email) Sentry.setUser({ email });

      Sentry.setTag('customer', API.main_customer?.customer_name);
    } catch (err) {
      Sentry.captureException(err);
    }
    let screen = forwardTo || (IS_IOS ? 'Landing' : 'Pin');

    // Todo: Show notice that pin screen was skipped in dev? Show pin screen on first load? need to make sure pin screen isn't broke
    if (__DEV__ && API.currUser) {
      screen = config.default_screen;
    }
    navigation.navigate(screen, { forwardTo: config.default_screen });
  };

  _clearCache = () => {
    this._currStep = 0;

    API.clearCache();
    API.clearHashes();

    showMessage({
      duration: 3000,
      message: 'Cache has been cleared.',
      position: 'top',
      type: 'success',
    });
  };

  _logout = () => {
    API.logout();
    const { navigation } = this.props;
    navigation.navigate('Login');
  };

  // Render any loading content that you like here
  render() {
    const {
      allowSkip,
      showTryAgain,
      error,
      progress,
      poll_status,
      showEnableLocationButton,
      showContinueButton,
    } = this.state;
    return (
      <View
        style={styles.container}
        testID="authLoadingScreen"
      >
        <View style={styles.container}>
          <ErrorBanner text={error} />
          <ActivityIndicator
            size="large"
            color={Colors.primary}
          />
          <Text style={styles.statusText}>{progress}</Text>
          <Text style={styles.statusText}>{poll_status}</Text>
          {allowSkip && (
            <Button
              onPress={this._skipStep}
              variant="ghost"
            >
              <Text>Skip</Text>
            </Button>
          )}
          {showTryAgain && (
            <View style={styles.options}>
              <Button
                variant="ghost"
                onPress={this._bootstrapAsync}
              >
                <Text>Try Again</Text>
              </Button>
              <Text>|</Text>
              <Button
                variant="ghost"
                onPress={this._clearCache}
              >
                <Text>Clear Cache</Text>
              </Button>
              <Text>|</Text>
              <Button
                variant="ghost"
                onPress={this._logout}
              >
                <Text>Logout</Text>
              </Button>
            </View>
          )}
          {showEnableLocationButton && (
            <Button
              variant="ghost"
              onPress={this._switchLocation}
            >
              <Text>Open Location Settings</Text>
            </Button>
          )}
          {showContinueButton && (
            <Button
              variant="ghost"
              onPress={this._continue}
            >
              <Text>Continue...</Text>
            </Button>
          )}
        </View>
        <Text style={styles.versionText}>v{API.version}</Text>
      </View>
    );
  }

  _skipStep = () => {
    this._currStep++;
    this._bootstrapAsync();
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    alignItems: 'baseline',
    flexDirection: 'row',
  },
  statusText: {
    marginTop: 15,
    fontSize: 16,
    textAlign: 'center',
  },
  versionText: {
    color: '#ddd',
    paddingBottom: 60,
  },
});
