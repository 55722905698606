import React from 'react';
import { View } from 'react-native';
import { Button, Icon, IconButton } from 'native-base';
import { Feather } from '@expo/vector-icons';
import Segment, { SegmentButton } from '../../components/Segment';

export default function MenuFilters({
  selected,
  onChange,
  hasVendors,
  showVendorItems,
  onToggleVendorItems,
}) {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Segment
        testID="segment"
        darkMode
      >
        <SegmentButton
          darkMode
          display="All Items"
          selected={selected === 'all'}
          value="all"
          onPress={onChange}
        />
        <SegmentButton
          darkMode
          display="Menu Items"
          selected={selected === 'menuitems'}
          value="menuitems"
          onPress={onChange}
        />
        <SegmentButton
          darkMode
          display="Modifiers"
          selected={selected === 'modifiers'}
          value="modifiers"
          onPress={onChange}
        />
      </Segment>
      {hasVendors && (
        <Button
          colorScheme="black"
          leftIcon={
            <Icon
              as={Feather}
              name={showVendorItems ? 'check-square' : 'square'}
            />
          }
          onPress={() => onToggleVendorItems(!showVendorItems)}
        >
          Vendors
        </Button>
      )}
    </View>
  );
}
