import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';
import Colors from '../constants/Colors';

const styles = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSmall: {
    fontSize: '1rem',
  },
  textMed: {
    fontSize: '1.25rem',
  },
  textLg: {
    fontSize: '1.5rem',
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});

const modalStyles = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modal: {},
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    backgroundColor: Colors.primary,
  },
  headerText: {
    color: Colors.light,
    flex: 1,
  },
  body: {
    backgroundColor: 'white',
    maxHeight: '80%',
  },
  cancelBtn: {
    backgroundColor: Colors.error,
  },
  submitBtn: {
    backgroundColor: Colors.primary,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 10,
    backgroundColor: Colors.light,
  },
  errorMsg: {
    padding: 5,
    paddingLeft: 10,
    backgroundColor: Colors.errorBackground,
  },
  errorMsgText: {
    color: Colors.errorText,
  },
});

// Customizations to the default Native Base 3 theme
const CUSTOMIZED_THEME = {
  colors: {
    light: {
      600: '#B6B5B5',
    },
    primary: {
      600: '#090934',
    },
  },

  components: {
    Container: {
      baseStyle: {
        flex: 1,
        maxWidth: '100%',
        backgroundColor: 'white',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    Icon: {
      baseStyle: {
        color: '#000',
      },
      variants: {
        lg: {
          alignSelf: 'center',
          fontSize: 100,
          color: 'emerald.500',
        },
        arrow: {
          fontSize: 22,
        },
      },
    },

    Input: {
      baseStyle: {
        backgroundColor: '#FFF',
      },
    },

    InputLeftAddon: {
      baseStyle: {
        backgroundColor: '#FFF',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        alignItems: 'flex-start',
      },
    },

    InputRightAddon: {
      baseStyle: {
        backgroundColor: '#FFF',
        borderTopWidth: 0,
        borderRightWidth: 0,
        alignItems: 'flex-start',
      },
    },

    Switch: {
      defaultProps: {
        _hover: {
          onTrackColor: '#ADD2CF',
        },
        onTrackColor: '#ADD2CF',
        onThumbColor: '#429488',
      },
    },

    Checkbox: {
      defaultProps: {
        colorScheme: 'info',
      },
      sizes: {
        menu: { _icon: { size: 2.5 }, _text: { fontSize: 15 } },
      },
    },

    Radio: {
      defaultProps: {
        colorScheme: 'info',
      },
      sizes: {
        menu: { _icon: { size: 2.5 }, _text: { fontSize: 15 } },
      },
    },

    Text: {
      variants: {
        note: {
          color: '#a7a7a7',
          fontSize: 14,
          marginRight: 10,
        },
        divider: {
          fontSize: 18,
          fontWeight: 'bold',
        },
      },
    },

    Select: {
      variants: {
        underlined: {
          borderRightWidth: 0,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRadius: 0,
        },
      },
    },
  },
};

export { styles, modalStyles, CUSTOMIZED_THEME };
