import moment from 'moment';
import Model from './Model';

export default class TerminalNotification extends Model {
  id = null;

  customer_id = null;

  handheld_config_id = null;

  location_id = null;

  time_created = null;

  time_read = null;

  last_modified = null;

  type = '';

  status = ''; // #'active', 'read', or 'dismissed'. If 'dismissed' it won't be loaded at app startup.

  title = ''; // models.TextField(null=False)

  body = ''; // models.TextField(null=False)

  extra_properties = {}; //  JSONField(null=False, default=dict) #for future formatting or experimental behavior

  _field_map = {
    time_created: moment,
    time_read: tr => tr && moment(tr),
    last_modified: moment,
  };

  constructor(data) {
    super();
    this.update(data);
  }
}
